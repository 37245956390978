import { Box, Button, Grid, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSites, filterSite, getSites } from '../../actions/organization/SiteApiCalls';
import EditIcon from '@material-ui/icons/Edit';
import AddSite from './AddSite';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FilterSite from './FilterSite';
import CommonUtil from '../../components/Util/CommonUtils';
import Pagination from '../../components/Pagination';
import ConfirmModal from '../../components/ConfirmModal';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip, Fab } from '@material-ui/core';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Breadcrumb from '../../components/Breadcrumb';
// import { ReactComponent as Add } from '../../assets/icons/add.svg';
// import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
// import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
// import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import {ReactComponent as ViewIcon} from '../../assets/icons/ViewIcon.svg';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import RegisterSite from './RegisterSite';
import { useHistory } from 'react-router-dom';

export default function Site() {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const zlink=localStorage.getItem('redirectFrom');
  // eslint-disable-next-line
  const [state, setState] = React.useState({
    id: '',
    name: '',
    code: '',
    description: '',
    parentId: '',
    siteTypeId: '',
    address: {
      id: '',
      addressLine1: '',
      addressLine2: '',
      country: '',
      state: '',
      city: '',
      areaCode: '',
      latitude: '',
      longitude: '',
      radius: '',
      timeZone: '',
      dayLightSaving: true,
      isDefault: true,
      addressTypeId: '',
      employeeId: '',
      userId: '',
      siteId: '',
    },
  });

  const [filter, setFilter] = useState({
    name: '',
    parentId: '',
  });

  const defaultFilter = {
    name: '',
    parentId: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      name: filterData.name,
      parentId: filterData.parentId,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setPage(0);
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadSites();
    // eslint-disable-next-line
  }, [filter]);

  // useEffect(() => {
  //   loadSites();
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.site.siteList)) {
      setTotalRecords(store.site.siteList.totalCount);
      setPage(store.site.siteList.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.site.siteList.site);
    console.log(store.site.siteList.site);
  }, [store.site.siteList]);

  const loadSites = () => {
    dispatch(filterSite(filter.name, filter.parentId, page + 1, rowsPerPage));
    onSelectionChange([]);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(filterSite(filter.name, filter.parentId, newPage + 1, rowsPerPage));
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    dispatch(filterSite(filter.name, filter.parentId, page + 1, pageSize));
  };

  const openAddSiteDialog = () => {
    setUpdateObject(state);
    history.push({ pathname: '/acc/site-form', state: state });
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];
    // eslint-disable-next-line
    data.map((record, index) => {
      idsToDelete[index] = record.id;
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteSites(idsToDelete, (data) => {
                  handlError(data);
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };
  const findSiteById=(parentId)=>{
    var parentname;
      data.map((site)=>{
        if(site.id===parentId){
           parentname = site.name; 
        }
      });
      return parentname;
  }
  const handlError = (data) => {
    switch (data.code) {
      case 'LMSI6012':
        toast.success(data.message);
        loadSites();
        break;
      case 'LMSI0015':
        toast.success(data.message);
        loadSites();
        break;
      case 'LMSW0001':
        if (data.data.successCount !== 0) {
          toast.success(data.data.successCount + ' ' + t('COMMON048'));
        }
        data = data.data.error;
        data.map(function(name, index) {
          toast.error(data[index].message);
        });
        loadSites();
        break;
      default:
        toast.error(data.message);
        loadSites();
        break;
    }
  };

  const options = {
    rowStyle: {
      overflowWrap: 'break-word',
    },
    actionsColumnIndex: -1,
    search: false,
    selection: false,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage
  };
  const breadcrumb = [{ path: '', name: t('SITE001') }];

  const columns = [
    {
      field: 'name',
      title: t('COMMON044'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
      cellStyle: {
        minWidth: 180,
        maxWidth: 200,
      },
    },
    {
      field: 'code',
      title: t('COMMON045'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
      cellStyle: {
        minWidth: 180,
        maxWidth: 200,
      },
    },
    { 
      render: (client) => {
        return CommonUtil.isEmptyString(client.parentId) ? '' : findSiteById(client.parentId);
       },
     field: 'parentId',
     title: t('COMMON050'),
     editable: 'never'
     },
    {
      field: 'description',
      title: t('SITE009'),
      editable: 'never',
      cellStyle: {
        minWidth: 180,
        maxWidth: 200,
      },
    },
  ];

  const actions = [
    !zlink?
    {
      icon: () => <EditOutlinedIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
       
        history.push({ pathname: '/acc/site-form', state: rowData });
        
      },
    }:{
      icon: () => <ViewIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON464'),
      position: 'row',
      onClick: (event, rowData) => {
        let siteData={
          ...rowData,
          disable:true,
        }
       history.push({pathname:'/acc/site-form',state: siteData});
      },
    },
    !zlink&&
    {
      icon: () => <DeleteOutlineIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
          <>
          {!zlink&&(
        <Tooltip title={t('COMMON009')}>
          <Fab color='secondary' size='small' aria-label={t('COMMON009')} onClick={openAddSiteDialog}>
            <AddIcon />
          </Fab>
        </Tooltip>
         )}
         </>
      </Breadcrumb>

      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={resetFilter}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {/* <Box p={1} flexGrow={1}>
          <>
          {!zlink&&(
          <Tooltip title={t('COMMON004')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => deleteRecord(selectedData)}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
           )}
           </>
        </Box> */}
        <Box p={1}>
          <FilterSite page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Box>
      </Toolbar>

      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
    </Grid>
  );
}
