import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { Avatar, Box, Grid, h3, Typography } from '@material-ui/core';
import SiteDashboardCards from '../../pages/SiteDashboardCards/SiteDashboardCards';
import SiteDoorPathWay from '../SiteDetailGraph/SiteDoorGraph';
import SiteEventsTable from '../SiteDashboardCards/SiteEventsTable';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Card } from '@mui/material';
import door from '../../assets/images/visitor-icon/Access Level.svg';
import { useTranslation } from 'react-i18next';
import CommonUtil from '../../components/Util/CommonUtils';
import { useDispatch } from 'react-redux';
import { getPersonByEmployeeCode } from '../../actions/organization/PersonApiCalls';

export default function SiteDashboard(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  var stompClient = null;
  // alert(JSON.stringify(props.history.location.state.selected.siteName))

  const connect = () => {
    const Stomp = require('stompjs');
    var SockJS = require('sockjs-client');
    SockJS = new SockJS('/cloudaccess');
    stompClient = Stomp.over(SockJS);
    stompClient.connect({}, onConnected, onError);
  };

  const onConnected = () => {
    console.log('connected');
    stompClient.subscribe('/site_events/' + location.state.siteId, onMessageReceived);
  };

  const onMessageReceived = (msg) => {
    var event = JSON.parse(msg.body);
    dispatch({
      type: 'SITE_DASHBOARD_ACTIVE_DOOR',
      payload: event,
    });
    // enqueueSnackbar(event.devAlias + ' ' + event.verifyModeName, {
    //   variant: 'success',
    //   content: () => AlertWindow(event),
    // });
  };

  const onError = (err) => {
    console.log('error' + err);
    setTimeout(function() {
      connect();
    }, 5000);
  };

  const AlertWindow = (event) => {
    return (
      <Paper style={{ border: '2px solid green' }} elevation={3}>
        <Card>
          {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '8px' }}>
            {CommonUtil.isEmptyString(event.profilePhoto) ? <Avatar alt='' /> : <Avatar alt='' src={'data:image/jpeg;base64,' + event.profilePhoto} />}
          </div> */}

          <div>
            <Box display='flex' justifyContent='center'>
              <Box p={1}>{CommonUtil.isEmptyString(event.profilePhoto) ? <Avatar alt='' /> : <Avatar alt='' src={'data:image/jpeg;base64,' + event.profilePhoto} />}</Box>
            </Box>
            {event.name && (
              <Box display='flex' justifyContent='center'>
                <Box p={1}>{event.name}</Box>
              </Box>
            )}
            <Box display='flex' flexDirection='row' p={1} m={1}>
              <Box minWidth={'30%'}>
                <h3 style={{ fontSize: '12px' }}>{t('Device ')}</h3>
              </Box>
              <Box flexShrink={0}>
                <h3 style={{ fontSize: '12px' }}>
                  : <span style={{ color: 'gray' }}>{event.devSn}</span>
                </h3>
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' p={1} m={1}>
              <Box minWidth={'30%'}>
                <h3 style={{ fontSize: '12px' }}>{t('Verify Mode')}</h3>
              </Box>
              <Box flexShrink={0}>
                <h3 style={{ fontSize: '12px' }}>
                  : <span style={{ color: 'gray' }}>{event.verifyModeName}</span>
                </h3>
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' p={1} m={1}>
              <Box minWidth={'30%'}>
                <h3 style={{ fontSize: '12px' }}>{t('Event Time')}</h3>
              </Box>
              <Box flexShrink={0}>
                <h3 style={{ fontSize: '12px' }}>
                  : <span style={{ color: 'gray' }}>{event.eventTime ? new Date(event.eventTime).getHours() + ':' + new Date(event.eventTime).getMinutes() : '-'}</span>
                </h3>
              </Box>
            </Box>
          </div>
        </Card>
      </Paper>
    );
  };

  useEffect(() => {
    connect();
  }, []);
  return (
    <Grid container spacing={3}>
      <h3 variant='h3' component='h2' color='secondary' style={{ margin: '18px', color: '#3d4977' }}>
        {location.state.selected ? location.state.selected.siteName : ''}
      </h3>
      <Grid item xs={12} sm={12}>
        <SiteDashboardCards id={props.history.location.state ? props.history.location.state.siteId : ''} />
      </Grid>
      {/* <Grid item xs={12} sm={12}>s
                <SiteDetailGraph/>
            </Grid> */}
      <Grid item xs={12} sm={12}>
        <SiteDoorPathWay siteId={location.state.siteId} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <SiteEventsTable siteId={props.history.location.state.siteId} />
      </Grid>
    </Grid>
  );
}
