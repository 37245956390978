import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getAuxouts } from '../../actions/AuxoutApiCalls';
import EditIcon from '@material-ui/icons/Edit';
import { REACT_URL_AUXOUT } from '../../actions/EndPoints';
import Headerbar from '../../components/HeaderBar';
import EditAuxout from './EditAuxout';
import FilterAuxout from './FilterAuxout';
import CommonUtil from '../../components/Util/CommonUtils';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export default function Auxout() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setupdateObject] = useState({});

  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: 5,
    name: '',
    deviceAlias: '',
  });

  const [filter, setFilter] = useState({
    name: '',
    deviceAlias: '',
  });

  const defaultFilter = {
    name: '',
    deviceAlias: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      name: filterData.name,
      deviceAlias: filterData.deviceAlias,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadData(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    onSelectionChange([]);
    if (!CommonUtil.isEmpty(store.auxout.auxouts)) {
      setTotalRecords(store.auxout.auxouts.totalConunt);
      setPage(store.auxout.auxouts.curPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.auxout.auxouts.auxOuts);
    // eslint-disable-next-line
  }, [store.auxout.auxouts]);

  useEffect(() => {
    setData(store.auxout.auxouts.auxOuts);
  }, [store.auxout.auxouts]);

  const loadData = (page, rowsPerPage) => {
    dispatch(getAuxouts(filter.name, filter.deviceAlias, page + 1, rowsPerPage));
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage
  };

  const breadcrumb = [
    //{ path: REACT_URL_AUXOUT, name: t("DEVI0013") },
    { path: REACT_URL_AUXOUT, name: t('AUXOUT001') },
  ];

  const columns = [
    {
      field: 'name',
      title: t('COMMON014'),
      tooltip: t('COMMON068'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'accDeviceDO.alias', title: t('DEVI0001'), tooltip: t('DEVI0002'), editable: 'never' },
    { field: 'auxNo', title: t('COMMON011'), tooltip: t('COMMON069'), editable: 'never' },
    { field: 'printerNumber', title: t('COMMON067'), tooltip: t('COMMON070'), editable: 'never' },
    { field: 'remark', title: t('COMMON016'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <EditIcon color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setupdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
  ];

  // eslint-disable-next-line
  const onPageChange = (page) => {
    setQuery({
      ...query,
      page: page,
    });
    loadData();
  };

  // eslint-disable-next-line
  const onPageSizeChange = (pageSize) => {
    setQuery({
      ...query,
      page: 0,
      pageSize: pageSize,
    });
    loadData();
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb} />
      {/* <Headerbar title={t("AUXOUT001")}/> */}
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon/>
            </IconButton>
          </Tooltip>
        </Box>

        <Grid container justify='flex-end' style={{ padding: '12px' }}>
          <FilterAuxout page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Grid>
      </Toolbar>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <EditAuxout
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadData(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
