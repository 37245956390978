import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getAuxins } from '../../actions/AuxinApiCalls';
import EditIcon from '@material-ui/icons/Edit';
import { REACT_URL_AUXIN } from '../../actions/EndPoints';
import Headerbar from '../../components/HeaderBar';
import EditAuxin from './EditAuxin';
import FilterAuxin from './FilterAuxin';
import CommonUtil from '../../components/Util/CommonUtils';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export default function Auxin() {
  // dispatch used to call action methods
  // store holds the redux state data
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  // internationalization
  const { t } = useTranslation();

  //data to display on the table
  const [data, setData] = useState([]);

  // holds the selected record from the table
  // eslint-disable-next-line
  const [selectedData, onSelectionChange] = useState([]);

  // open/close the edit/create page(dialog)
  const [openEditDialog, setOpenEditDialog] = useState(false);

  // object passed to the edit page(dialog)
  const [updateObject, setupdateObject] = useState({});

  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // to handle pagination and filter
  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: 5,
    name: '',
    deviceAlias: '',
  });

  // Called only once at the time of loading the page, call action method to load the initial data for the table
  // useEffect(() => {
  //   loadData();
  //   // eslint-disable-next-line
  // }, []);

  const [filter, setFilter] = useState({
    name: '',
    deviceAlias: '',
  });

  const defaultFilter = {
    name: '',
    deviceAlias: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      name: filterData.name,
      deviceAlias: filterData.deviceAlias,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadData(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    onSelectionChange([]);
    if (!CommonUtil.isEmpty(store.auxin.auxins)) {
      setTotalRecords(store.auxin.auxins.totalConunt);
      setPage(store.auxin.auxins.curPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.auxin.auxins.auxIns);
    // eslint-disable-next-line
  }, [store.auxin.auxins]);

  // Called every time there is change on "store.auxin.auxins" to keep the table updated
  useEffect(() => {
    setData(store.auxin.auxins.auxIns);
  }, [store.auxin.auxins]);

  // call action method to load the data
  const loadData = (page, rowsPerPage) => {
    dispatch(getAuxins(filter.name, filter.deviceAlias, page + 1, rowsPerPage));
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  // passed to the breadcrumb component
  const breadcrumb = [
    // { path: REACT_URL_AUXIN, name: t("DEVI0013") },
    { path: REACT_URL_AUXIN, name: t('AUXIN001') },
  ];

  // passed to the DataTable component
  const columns = [
    {
      field: 'name',
      title: t('COMMON014'),
      tooltip: t('COMMON064'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'accDeviceDO.alias', title: t('DEVI0001'), tooltip: t('DEVI0002'), editable: 'never' },
    { field: 'auxNo', title: t('COMMON011'), tooltip: t('COMMON065'), editable: 'never' },
    { field: 'printerNumber', title: t('COMMON067'), tooltip: t('COMMON066'), editable: 'never' },
    { field: 'remark', title: t('COMMON016'), editable: 'never' },
  ];

  // passed to the DataTable component to create action buttons
  const actions = [
    {
      icon: () => <EditOutlinedIcon color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setupdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
  ];

  // Pagination is not implemented yet
  // eslint-disable-next-line
  const onPageChange = (page) => {
    setQuery({
      ...query,
      page: page,
    });
    loadData();
  };

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage
  };

  // eslint-disable-next-line
  const onPageSizeChange = (pageSize) => {
    setQuery({
      ...query,
      page: 0,
      pageSize: pageSize,
    });
    loadData();
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb} />
      {/* <Headerbar title={t("AUXIN001")}/> */}
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid container justify='flex-end' style={{ padding: '12px' }}>
          <FilterAuxin page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Grid>
      </Toolbar>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <EditAuxin
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadData(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
