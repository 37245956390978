import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import {
  REACT_URL_DOOR,
  REACT_URL_READER_LIST,
  REACT_URL_DEVICE_LIST,
  REACT_URL_OPERATION_LOG,
  REACT_URL_ACCESS_LEVEL,
  REACT_URL_INTER_LOCK,
  REACT_URL_ANTIPASSBOOK,
  REACT_URL_TIMEPERIOD,
  REACT_URL_AUXIN,
  REACT_URL_AUXOUT,
  REACT_URL_TRANSACTIONS,
  REACT_URL_TRANSACTIONSFORDAY,
  REACT_URL_EXCEPTIONTRANSACTIONS,
  REACT_URL_DASHBOARD,
  REACT_URL_DEPARTMENT,
  REACT_URL_DESIGNATION,
} from '../../actions/EndPoints';

import {
  REACT_URI_PERSON,
  REACT_URI_DEPARTMENT,
  REACT_URI_DESIGNATION,
  REACT_URI_ADDRESS_TYPE,
  REACT_URI_SITE_TYPE,
  REACT_URI_SITE,
  REACT_URI_ZONE,
  REACT_URI_APPLICATIONS,
} from '../../actions/organization/EndPoints';


import EqualizerIcon from '@material-ui/icons/Equalizer';
import { ReactComponent as Dashboard_Icon } from '../../assets/images/dashboard-icons/dashboard_icon.svg';
import { ReactComponent as Site_Icon } from '../../assets/images/dashboard-icons/site_icon.svg';
import { ReactComponent as Device_Icon } from '../../assets/images/dashboard-icons/device_icon.svg';
import { ReactComponent as Access_Icon } from '../../assets/images/dashboard-icons/access_icon.svg';
import { ReactComponent as Report_Icon } from '../../assets/images/dashboard-icons/report_icon.svg';
import { ReactComponent as Organization_Icon } from '../../assets/images/dashboard-icons/organization_icon.svg';
import { ReactComponent as AccessControl } from '../../assets/cloudaccess-svg/AccessControl.svg';
import { ReactComponent as Reports } from '../../assets/cloudaccess-svg/Reports.svg';
import { ReactComponent as SiteManagement } from '../../assets/cloudaccess-svg/SiteManagement.svg';
import { ReactComponent as PersonalManagement } from '../../assets/cloudaccess-svg/Personnel Icon.svg';
var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  AccessControlIcon: AccessControl,
  ReportsIcon: Reports,
  SiteManagementIcon: SiteManagement,
  PersonalManagementIcon: PersonalManagement,
  BusinessIcon: DashboardTwoToneIcon,
  EqualizerIcon: EqualizerIcon,
};

let language = BrowserLanguage.getDefaultLanguage();
const dataEn = [
  {
    id: '0',
    name: 'Dashboard',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
  {
    id: '1',
    name: 'Organization',
    Icon: Organization_Icon,
    items: [
      {
        id: '6',
        name: 'Person',
        link: `${REACT_URI_PERSON}`,
      },
      {
        id: '7',
        name: 'Department',
        link: `${REACT_URL_DEPARTMENT}`,
      },
      {
        id: '8',
        name: 'Designation',
        link: `${REACT_URL_DESIGNATION}`,
      },
    ],
  },
  {
    id: '2',
    name: 'Site Management',
    Icon: Site_Icon,
    items: [
      {
        id: '10',
        name: 'Address Type',
        link: `${REACT_URI_ADDRESS_TYPE}`,
      },
      {
        id: '11',
        name: 'Site Type',
        link: `${REACT_URI_SITE_TYPE}`,
      },
      {
        id: '12',
        name: 'Site',
        link: `${REACT_URI_SITE}`,
      },

      {
        id:'13',
        name:'Zone',
        link:`${REACT_URI_ZONE}`
      }
    ],
  },
  {
    id: '3',
    name: 'Device Management',
    Icon: Device_Icon,
    items: [
      {
        id: '13',
        name: 'Device',
        link: `${REACT_URL_DEVICE_LIST}`,
      },
      {
        id: '14',
        name: 'Doors',
        link: `${REACT_URL_DOOR}`,
      },
      {
        id: '15',
        name: 'Reader',
        link: `${REACT_URL_READER_LIST}`,
      },
      {
        id: '16',
        name: 'Auxiliary Input',
        link: `${REACT_URL_AUXIN}`,
      },
      {
        id: '17',
        name: 'Auxiliary Output',
        link: `${REACT_URL_AUXOUT}`,
      },
    ],
  },
  {
    id: '4',
    name: 'Access Control',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: 'Time Period',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: 'Access Level',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: 'Reports',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: 'Transactions',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: 'Transactions for day',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: 'Exception Transactions',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];

const dataEs = [
  {
    id: '0',
    name: 'Tablero',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
  {
    id: '1',
    name: 'Organización',
    Icon: Organization_Icon,
    items: [
      {
        id: '6',
        name: 'Persona',
        link: `${REACT_URI_PERSON}`,
      },
      {
        id: '7',
        name: 'Departamento',
        link: `${REACT_URL_DEPARTMENT}`,
      },
      {
        id: '8',
        name: 'Designacion',
        link: `${REACT_URL_DESIGNATION}`,
      },
    ],
  },
  {
    id: '2',
    name: 'Manejo de sitio',
    Icon: Site_Icon,
    items: [
      {
        id: '10',
        name: 'Tipo de dirección',
        link: `${REACT_URI_ADDRESS_TYPE}`,
      },
      {
        id: '11',
        name: 'Tipo de sitio',
        link: `${REACT_URI_SITE_TYPE}`,
      },
      {
        id: '12',
        name: 'Sitio',
        link: `${REACT_URI_SITE}`,
      },
    ],
  },
  {
    id: '3',
    name: 'Gestión de dispositivos',
    Icon: Device_Icon,
    items: [
      {
        id: '13',
        name: 'Dispositivo',
        link: `${REACT_URL_DEVICE_LIST}`,
      },
      {
        id: '14',
        name: 'Puerta',
        link: `${REACT_URL_DOOR}`,
      },
      {
        id: '15',
        name: 'Lectora',
        link: `${REACT_URL_READER_LIST}`,
      },
      {
        id: '16',
        name: 'Entrada auxiliar',
        link: `${REACT_URL_AUXIN}`,
      },
      {
        id: '17',
        name: 'Salida auxiliar',
        link: `${REACT_URL_AUXOUT}`,
      },
    ],
  },
  {
    id: '4',
    name: 'Control de acceso',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: 'Periodo de tiempo',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: 'Niveles de acceso',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: 'Informes',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: 'Actas',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: 'Transacciones por día',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: 'Transacciones de excepción',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];

const dataZh = [
  {
    id: '0',
    name: '仪表盘',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
  {
    id: '1',
    name: '组织',
    Icon: Organization_Icon,
    items: [
      {
        id: '6',
        name: '人员',
        link: `${REACT_URI_PERSON}`,
      },
      {
        id: '7',
        name: '部门',
        link: `${REACT_URL_DEPARTMENT}`,
      },
      {
        id: '8',
        name: '职位',
        link: `${REACT_URL_DESIGNATION}`,
      },
    ],
  },
  {
    id: '2',
    name: 'Site管理',
    Icon: Site_Icon,
    items: [
      {
        id: '10',
        name: '地址类型',
        link: `${REACT_URI_ADDRESS_TYPE}`,
      },
      {
        id: '11',
        name: 'Site类型',
        link: `${REACT_URI_SITE_TYPE}`,
      },
      {
        id: '12',
        name: 'Site',
        link: `${REACT_URI_SITE}`,
      },
    ],
  },
  {
    id: '3',
    name: '设备管理',
    Icon: Device_Icon,
    items: [
      {
        id: '13',
        name: '设备',
        link: `${REACT_URL_DEVICE_LIST}`,
      },
      {
        id: '14',
        name: '门',
        link: `${REACT_URL_DOOR}`,
      },
      {
        id: '15',
        name: '读头',
        link: `${REACT_URL_READER_LIST}`,
      },
      {
        id: '16',
        name: '辅助输入',
        link: `${REACT_URL_AUXIN}`,
      },
      {
        id: '17',
        name: '辅助输出',
        link: `${REACT_URL_AUXOUT}`,
      },
    ],
  },
  {
    id: '4',
    name: '门禁控制',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: '时间段',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: '门禁权限组',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: '报表',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: '交易',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: '交易日',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: '异常交易',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];
const dataRu = [
  {
    id: '0',
    name: 'Приборная доска',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
  {
    id: '1',
    name: 'Организация',
    Icon: Organization_Icon,
    items: [
      {
        id: '6',
        name: 'Человек',
        link: `${REACT_URI_PERSON}`,
      },
      {
        id: '7',
        name: 'отделение',
        link: `${REACT_URL_DEPARTMENT}`,
      },
      {
        id: '8',
        name: 'Обозначение',
        link: `${REACT_URL_DESIGNATION}`,
      },
    ],
  },
  {
    id: '2',
    name: 'Управление сайтом',
    Icon: Site_Icon,
    items: [
      {
        id: '10',
        name: 'тип адреса',
        link: `${REACT_URI_ADDRESS_TYPE}`,
      },
      {
        id: '11',
        name: 'Тип сайта',
        link: `${REACT_URI_SITE_TYPE}`,
      },
      {
        id: '12',
        name: 'Сайт',
        link: `${REACT_URI_SITE}`,
      },
    ],
  },
  {
    id: '3',
    name: 'Управление устройствами',
    Icon: Device_Icon,
    items: [
      {
        id: '13',
        name: 'Устройство',
        link: `${REACT_URL_DEVICE_LIST}`,
      },
      {
        id: '14',
        name: 'Двери',
        link: `${REACT_URL_DOOR}`,
      },
      {
        id: '15',
        name: 'Читатель',
        link: `${REACT_URL_READER_LIST}`,
      },
      {
        id: '16',
        name: 'Дополнительный вход',
        link: `${REACT_URL_AUXIN}`,
      },
      {
        id: '17',
        name: 'Дополнительный выход',
        link: `${REACT_URL_AUXOUT}`,
      },
    ],
  },
  {
    id: '4',
    name: 'Контроль доступа',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: 'Временной период',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: 'Уровень доступа',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: 'отчеты',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: 'Транзакции',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: 'Сделки за день',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: 'Транзакции-исключения',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];

const dataId = [
  {
    id: '0',
    name: 'Dasbor',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
  {
    id: '1',
    name: 'Organisasi',
    Icon: Organization_Icon,
    items: [
      {
        id: '6',
        name: 'Orang',
        link: `${REACT_URI_PERSON}`,
      },
      {
        id: '7',
        name: 'Departemen',
        link: `${REACT_URL_DEPARTMENT}`,
      },
      {
        id: '8',
        name: 'Penamaan',
        link: `${REACT_URL_DESIGNATION}`,
      },
    ],
  },
  {
    id: '2',
    name: 'Manajemen situs',
    Icon: Site_Icon,
    items: [
      {
        id: '10',
        name: 'tipe alamat',
        link: `${REACT_URI_ADDRESS_TYPE}`,
      },
      {
        id: '11',
        name: 'Jenis Situs',
        link: `${REACT_URI_SITE_TYPE}`,
      },
      {
        id: '12',
        name: 'Lokasi',
        link: `${REACT_URI_SITE}`,
      },
    ],
  },
  {
    id: '3',
    name: 'Manajemen perangkat',
    Icon: Device_Icon,
    items: [
      {
        id: '13',
        name: 'Perangkat',
        link: `${REACT_URL_DEVICE_LIST}`,
      },
      {
        id: '14',
        name: 'pintu',
        link: `${REACT_URL_DOOR}`,
      },
      {
        id: '15',
        name: 'Pembaca',
        link: `${REACT_URL_READER_LIST}`,
      },
      {
        id: '16',
        name: 'Masukan Tambahan',
        link: `${REACT_URL_AUXIN}`,
      },
      {
        id: '17',
        name: 'Keluaran Tambahan',
        link: `${REACT_URL_AUXOUT}`,
      },
    ],
  },
  {
    id: '4',
    name: 'Kontrol akses',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: 'Jangka waktu',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: 'Tingkat Akses',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: 'Laporan',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: 'Transaksi',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: 'Transaksi hari ini',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: 'Transaksi Pengecualian',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];

const dataTh = [
  {
    id: '0',
    name: 'แผงควบคุม',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
  {
    id: '1',
    name: 'องค์กร',
    Icon: Organization_Icon,
    items: [
      {
        id: '6',
        name: 'บุคคล',
        link: `${REACT_URI_PERSON}`,
      },
      {
        id: '7',
        name: 'แผนก',
        link: `${REACT_URL_DEPARTMENT}`,
      },
      {
        id: '8',
        name: 'การกำหนด',
        link: `${REACT_URL_DESIGNATION}`,
      },
    ],
  },
  {
    id: '2',
    name: 'การจัดการเว็บไซต์',
    Icon: Site_Icon,
    items: [
      {
        id: '10',
        name: 'ประเภทที่อยู่',
        link: `${REACT_URI_ADDRESS_TYPE}`,
      },
      {
        id: '11',
        name: 'ประเภทไซต์',
        link: `${REACT_URI_SITE_TYPE}`,
      },
      {
        id: '12',
        name: 'งาน',
        link: `${REACT_URI_SITE}`,
      },
    ],
  },
  {
    id: '3',
    name: 'การจัดการอุปกรณ์',
    Icon: Device_Icon,
    items: [
      {
        id: '13',
        name: 'อุปกรณ์',
        link: `${REACT_URL_DEVICE_LIST}`,
      },
      {
        id: '14',
        name: 'ประตู',
        link: `${REACT_URL_DOOR}`,
      },
      {
        id: '15',
        name: 'ผู้อ่าน',
        link: `${REACT_URL_READER_LIST}`,
      },
      {
        id: '16',
        name: 'อินพุตเสริม',
        link: `${REACT_URL_AUXIN}`,
      },
      {
        id: '17',
        name: 'เอาต์พุตเสริม',
        link: `${REACT_URL_AUXOUT}`,
      },
    ],
  },
  {
    id: '4',
    name: 'การควบคุมการเข้าถึง',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: 'ระยะเวลา',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: 'ระดับการเข้าถึง',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: 'รายงาน',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: 'ธุรกรรม',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: 'ธุรกรรมรายวัน',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: 'ธุรกรรมข้อยกเว้น',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];

const dataJa = [
  {
    id: '0',
    name: 'ダッシュボード',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
  {
    id: '1',
    name: '組織',
    Icon: Organization_Icon,
    items: [
      {
        id: '6',
        name: '人',
        link: `${REACT_URI_PERSON}`,
      },
      {
        id: '7',
        name: 'デパートメント',
        link: `${REACT_URL_DEPARTMENT}`,
      },
      {
        id: '8',
        name: '指定',
        link: `${REACT_URL_DESIGNATION}`,
      },
    ],
  },
  {
    id: '2',
    name: 'サイト管理',
    Icon: Site_Icon,
    items: [
      {
        id: '10',
        name: 'アドレスタイプ',
        link: `${REACT_URI_ADDRESS_TYPE}`,
      },
      {
        id: '11',
        name: 'サイトタイプ',
        link: `${REACT_URI_SITE_TYPE}`,
      },
      {
        id: '12',
        name: 'サイト',
        link: `${REACT_URI_SITE}`,
      },
    ],
  },
  {
    id: '3',
    name: '端末管理',
    Icon: Device_Icon,
    items: [
      {
        id: '13',
        name: '端末',
        link: `${REACT_URL_DEVICE_LIST}`,
      },
      {
        id: '14',
        name: 'ドア',
        link: `${REACT_URL_DOOR}`,
      },
      {
        id: '15',
        name: '読者',
        link: `${REACT_URL_READER_LIST}`,
      },
      {
        id: '16',
        name: '補助入力',
        link: `${REACT_URL_AUXIN}`,
      },
      {
        id: '17',
        name: '補助出力',
        link: `${REACT_URL_AUXOUT}`,
      },
    ],
  },
  {
    id: '4',
    name: 'アクセス制御',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: '期間',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: 'アクセスレベル',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: 'レポート',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: 'トランザクション',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: '日のトランザクション',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: '例外トランザクション',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];

export default language === 'en'
  ? dataEn
  : language === 'es'
  ? dataEs
  : language === 'zh'
  ? dataZh
  : language === 'ru'
  ? dataRu
  : language === 'idn'
  ? dataId
  : language === 'th'
  ? dataTh
  : dataJa;
