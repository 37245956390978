import { EXCEPTION_TRANSACTION_LIST, GET_REPORTS_EXCEPTION_TRANSACTIONS, SUCCESS } from "../actions/types";

const initialState = {
  exceptionTransactionsList: [],
  exceptionTransactions: [],
  isSuccess: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case EXCEPTION_TRANSACTION_LIST:
      return {
        ...state,
        exceptionTransactions: action.payload,
      };
    case GET_REPORTS_EXCEPTION_TRANSACTIONS:
      return {
        ...state,
        exceptionTransactionsList: action.payload,
      };
    case SUCCESS:
      return {
        ...state,
        isSuccess: true,
      };

    default:
      return state;
  }
}
