import { GET_ERRORS,EMPLOYEE_ERRORS,DEPARTMENT_ERRORS,DESIGNATION_ERRORS,RESIGNED_EMPLOYEE_ERRORS,ADDRESS_TYPE_ERRORS,SITE_TYPE_ERRORS,SITE_ERRORS,ZONE_ERRORS, DEVICE_ERRORS, DOOR_ERRORS, READER_ERRORS, LEVEL_ERROR, TIMESEG_ERROR, AUXIN_ERRORS, AUXOUT_ERRORS } from "../actions/types";

const initialState = {
  employeeError:{},
  departmentError:{},
  designationError:{},
  resignedEmployeeError:{},
  addressTypeError:{},
  siteTypeError:{},
  siteError:{},
  zoneError:{},
  deviceError: {},
  doorError: {},
  readerError: {},
  levelError: {},
  timeSegError: {},
  auxinError: {},
  auxoutError: {},
};
 
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return action.payload;

    case EMPLOYEE_ERRORS:
      return {
        ...state,
        employeeError: action.payload
      }
    case DEPARTMENT_ERRORS:
      return {
          ...state,
          departmentError: action.payload
        }
    case DESIGNATION_ERRORS:
        return {
            ...state,
            designationError: action.payload
      }
    case RESIGNED_EMPLOYEE_ERRORS:
      return {
            ...state,
            resignedEmployeeError: action.payload
      }
    case ADDRESS_TYPE_ERRORS:
        return {
              ...state,
              addressTypeError: action.payload
      }
      case SITE_TYPE_ERRORS:
        return {
              ...state,
              siteTypeError: action.payload
      }
      case SITE_ERRORS:
        return {
              ...state,
              siteError: action.payload
      }
      case ZONE_ERRORS:
        return {
              ...state,
              zoneError: action.payload
      }
    case DEVICE_ERRORS:
      return {
        ...state,
        deviceError: action.payload
      }
    case DOOR_ERRORS:
      return {
        ...state,
        doorError: action.payload
      }
    case READER_ERRORS:
      return {
        ...state,
        readerError: action.payload
      }
    case LEVEL_ERROR:
      return {
        ...state,
        levelError: action.payload
      }
    case TIMESEG_ERROR:
      return {
        ...state,
        timeSegError: action.payload
      }
    case AUXIN_ERRORS:
      return {
        ...state,
        auxinError: action.payload
      }
    case AUXOUT_ERRORS:
      return {
        ...state,
        auxoutError: action.payload
      }
    default:
      return {
        ...initialState
      };
  }
}
