import React from 'react';
import { Grid} from "@material-ui/core";
import { compose } from 'redux'
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Button  } from '@material-ui/core';
import  { forwardRef } from 'react';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from "material-table";
import {adddLinkage} from "../../actions/LinkageListActions";
import { toast } from 'react-toastify';
import{Link}from'react-router-dom';
import { Fragment } from 'react';

class EditOutputLinkage extends React.Component {

	constructor(props) {
		super(props);
		 
		this.state = {
			 
		}
    }
    handleDialogOK=(e)=>{
     var accLinkageDTO={};
     accLinkageDTO.name=this.props.name;
     accLinkageDTO.deviceId=this.props.deviceId;
     if(this.props.name!=null && this.props.deviceId!=null)
     {
     this.props.adddLinkage(accLinkageDTO);
     }
     else{
      toast.error("Please Enter Name and Select Device "); 
     }
    }
    render() {
        const linkageData = [
			{ linkageName: 'linkage1', deviceName: 'device1', linkageTriggerConditions: 'Condition1' },
			{ linkageName: 'linkage2', deviceName: 'device2', linkageTriggerConditions: 'Condition2' },
			{ linkageName: 'linkage3', deviceName: 'device3', linkageTriggerConditions: 'Condition3' },
			{ linkageName: 'linkage4', deviceName: 'device4', linkageTriggerConditions: 'Condition4' },
			{ linkageName: 'linkage5', deviceName: 'device5', linkageTriggerConditions: 'Condition5' },
		];
        const tableIcons = {
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }}/>),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }}/>),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }}/>),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }}/>),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }}/>),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }}/>),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }}/>),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }}/>),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }}/>),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }}/>),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }}/>),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }}/>), 
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }}/>),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }}/>),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }}/>)
          };
        return (
            <Fragment>
                  <Grid container spacing={2}>
                  <Grid item xs={6}>
                         Door
                        </Grid>
                        <Grid item xs={6}>
                         Auxulary Output
                </Grid>
                  <Grid item xs={12} sm={6}>
                <MaterialTable
                style={{maxHeight:"220px",minHeight:"250px",overflowY: "scroll"}}
                                                 autoHeight={true}
                                                      icons={tableIcons}
                                                    columns={[
                                                        {   field: 'text' },
                                                        
                                                          
                                                    ]}
                                                    data={linkageData}       
                                                    options={{
                                                        minBodyHeight:'100%',
                                                        actionsColumnIndex: -1,
                                                        addRowPosition: 'first',
                                                        showTitle: false,
                                                        toolbarButtonAlignment: "left",
                                                        toolbar: false,
                                                        selection: true,
                                                        pageSize: 5,
                                                        paging: false
                                                      }}
                                                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                <MaterialTable
                style={{maxHeight:"220px",minHeight:"250px",overflowY: "scroll"}}
                                                 autoHeight={true}
                                                      icons={tableIcons}
                                                    columns={[
                                                        { title: 'Name', field: 'name' },
                                                        { title: 'Surname', field: 'surname' },
                                                          
                                                    ]}
                                                    data={linkageData}
                                                           
                                                    options={{
                                                        minBodyHeight:'100%',
                                                        actionsColumnIndex: -1,
                                                        addRowPosition: 'first',
                                                        showTitle: false,
                                                        toolbarButtonAlignment: "left",
                                                        toolbar: false,
                                                        selection: true,
                                                        pageSize: 5,
                                                        paging: false
                                                      }}
                                                    />
                </Grid>
                
                {/* <Grid item xs={6} sm={3}>
                <FormControl variant="outlined" style={{width:"150px"}}>
									<InputLabel id="demo-simple-select-outlined-label">Action Type</InputLabel>
									<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									label="Action Type"
									>
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
									 
									</Select>
								</FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                <TextField id="outlined-basic" label="Action Time Delay" variant="outlined" />
                </Grid>
                <Grid item xs={6} sm={3}>
                <FormControl variant="outlined" style={{width:"150px"}}>
									<InputLabel id="demo-simple-select-outlined-label">Action Type</InputLabel>
									<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									label="Action Type"
									>
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
								 
									</Select>
								</FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                <TextField id="outlined-basic" label="Action Time Delay" variant="outlined"  />
                </Grid>

                            
                    <Grid item xs={4}>
                     
                    </Grid> */}




<Grid
  container
  direction="row"
  justify="center"
  alignItems="center"
  style={{paddingTop: "20px"}}>
        
                      <Button color="primary" onClick={this.handleDialogOK} variant="contained" style={{margin: "2px"}}>  
									            Save 
                          </Button> 
                   <Button color="primary" component={Link} to={{
						                	pathname:"/acc/linkage"
						                }} onClick={this.handleDialogClose}  variant="contained"  >
                                    Cancel
                  </Button>
                    </Grid>
                    <Grid item xs={4}>
                    
                    </Grid>
                  </Grid>
                  
                            
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
	  
});

export default compose(withTranslation(), withStyles(  { withTheme: true }), connect(mapStateToProps,{adddLinkage}))(EditOutputLinkage);