import axios from 'axios';
import { JAVA_API_READER } from './ApiEndPoints';
import { toast } from 'react-toastify';
import { READER_ERRORS, READER_LIST } from './types';

export const getReaders = (name, doorName, page, size) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_READER + '?name=' + name + '&doorName=' + doorName + '&page=' + page + '&size=' + size);
    if (res.status === 200 && res.data.code === 'CASI0001') {
      dispatch({
        type: READER_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: READER_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const updateReaders = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_READER, payload);
    if (res.status === 200 && res.data.code === 'CASI0059') {
      toast.success(res.data.message);
      callBack(res.data);
      dispatch({
        type: READER_ERRORS,
        payload: {},
      });
    } else {
      toast.error(res.data.message);
      dispatch({
        type: READER_ERRORS,
        payload: res.data,
      });
    }
  } catch {}
};
