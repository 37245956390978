import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Divider, Hidden, Card, Tooltip, ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { Avatar, Menu, Typography, TextField } from '@material-ui/core';
import Button from '@mui/material/Button';
import PathWay from './PathWay';
import { useHistory } from 'react-router-dom';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useDispatch, useSelector } from 'react-redux';
import { getDoorEvents, setRemarksOnTransactions } from '../../actions/DashBoardActions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CommonUtil from '../../components/Util/CommonUtils';
import { ReactComponent as Device_Icon } from '../../assets/images/dashboard-icons/device_icon.svg';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { filterPerson } from '../../actions/organization/PersonApiCalls';
import axios from 'axios';
import { JAVA_API_PERSON } from '../../actions/organization/ApiEndPoints';
// import { ReactComponent as NoEventsFound } from '../../assets/images/NoEventsFound.svg';
import NoEventsFound from '../../assets/images/NoEventsFound';
import PersonTrace from './PersonTrace';

import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    border: '1px solid transparent',
    color: '#36C96D',
    backgroundColor: '#EBF9F0',
  },
  buttonCancel: {
    border: '1px solid transparent',
    color: '#3D4977',
    backgroundColor: '#EBECF1',
  },
  textarea: {
    fontSize: '12px',
    width: 180,
    border: '1px solid #cdcdcd',
    overflow: 'auto',
    borderRadius: '3px',
    padding: '5px',
    resize: 'none',
  },
}));

export default function SiteDoorPathWay(props) {
  const { t } = useTranslation();
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(true);
  const [traceOpen, setTraceOpen] = React.useState(false);
  const [remarkOpen, setRemarkOpen] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [yIndex, setY] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(0);

  const [remarks, setRemarks] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = useState();

 

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const remarkChange = (event) => {
    setRemarks(event.target.value);
  };

  const handleRemark = () => {
    debugger;
    setAnchorEl(null);
    setRemarkOpen();

    setShow(true);
  };

  const handleSave = () => {
    setRemarkOpen('');
  };

  const submitRemark = (id, remarks) => {
    var payload = {
      id: id,
      remark: remarks,
    };

    dispatch(
      setRemarksOnTransactions(payload, (data) => {
        setRemarkOpen('');
        events.map((data) => {
          if (data.id === id) {
            data.remark = remarks;
          }
        });
      })
    );
  };

  const history = useHistory();

  const handleProfile = (data) => {
    if (CommonUtil.isEmptyString(data.pin)) {
      return;
    }
    axios.get(JAVA_API_PERSON + '/' + 'search' + '?page=' + 1 + '&size=' + 1 + '&employeeCode=' + data.pin).then((res) => {
      if (res.status === 200 && res.data.code === 'OMSI0000') {
        var payload = {
          ...res.data.data.employee[0],
          disable: true,
        };
        history.push({
          pathname: '/acc/update-person',
          state: { selectedObject: payload, open: true },
        });
      }
    });
  };

  const classes = useStyles();

  const [eventInterval, setEventInterval] = useState(2);
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    setTotalRecords(store.siteDashBoard.siteEventsData.totalCount);
    setPage(store.siteDashBoard.siteEventsData.currentPage);
    setTotalPages(store.siteDashBoard.siteEventsData.totalPages);
    setEvents(store.siteDashBoard.siteEventsData.event);
  }, [store.siteDashBoard.siteEventsData.event]);

  useEffect(() => {
    var selectedDoor = '';
    if (!CommonUtil.isEmpty(store.dashBoard.selectedDoor)) {
      selectedDoor = store.dashBoard.selectedDoor.doorId;
    }
    if (page !== undefined) dispatch(getDoorEvents(props.siteId, eventInterval, selectedDoor, page, rowsPerPage, store.siteDashBoard.siteEventsData, (data) => {}));
  }, [page]);

  useEffect(() => {
    var selectedDoor = '';
    if (!CommonUtil.isEmpty(store.dashBoard.selectedDoor)) {
      selectedDoor = store.dashBoard.selectedDoor.doorId;
    }
    if (page !== undefined) dispatch(getDoorEvents(props.siteId, eventInterval, selectedDoor, page, rowsPerPage, {}, (data) => {}));
  }, [eventInterval, store.dashBoard.selectedDoor]);

  useEffect(() => {
    var siteEventsData = store.siteDashBoard.siteEventsData;
    if (siteEventsData.totalCount < 1) {
      var selectedDoor = '';
      if (!CommonUtil.isEmpty(store.dashBoard.selectedDoor)) {
        selectedDoor = store.dashBoard.selectedDoor.doorId;
      }
      setTimeout(() => {
        dispatch(getDoorEvents(props.siteId, eventInterval, selectedDoor, 1, 10, store.siteDashBoard.siteEventsData, (data) => {}));
      }, 5000);
    } else {
      if (store.dashBoard.activeDoors instanceof Array && store.dashBoard.activeDoors.length > 0) {
        var eventList = [store.dashBoard.activeDoors[0]];
        if (siteEventsData.event[0].uniqueKey !== store.dashBoard.activeDoors[0].uniqueKey) {
          eventList = eventList.concat(siteEventsData.event);
          siteEventsData.event = eventList;
          setTimeout(() => {
            dispatch({
              type: 'SITE_EVENTS_DATA',
              payload: siteEventsData,
            });
          }, 5000);
        }
      }
    }
  }, [store.dashBoard.activeDoors]);

  const handleChangePage = (index) => {
    if (index !== yIndex) {
      setY(index);
      var newPage = page + 1;
      if (totalPages >= newPage) {
        setPage(newPage);
      }
    }
  };

  // useEffect(() => {
  //   dispatch(getDoorEvents(props.siteId, eventInterval, selectedDoor, page, (data) => {}));
  // }, [page]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={9}>
        <Typography gutterBottom variant='h3' component='h2' color='secondary' style={{ margin: '18px' }}>
          {t('siteDetails')}
        </Typography>
        <Paper elevation={3} style={{ minHeight: '550px', overflow: 'auto' }}>
          <Box sx={{ flexGrow: 1 }} style={{ padding: '40px' }}>
            <Hidden xsDown smDown mdDown>
              <Grid container justifyContent={'center'}>
                <PathWay doorsPerPath={12} siteId={props.siteId} />
              </Grid>
            </Hidden>
            <Hidden mdUp lgUp xlUp>
              <Grid container justifyContent={'center'}>
                <PathWay doorsPerPath={6} siteId={props.siteId} />
              </Grid>
            </Hidden>
          </Box>
        </Paper>
      </Grid>
      {/* -------------------------Events--------------- */}
      <Grid item xs={12} sm={12} md={3}>
        <Box display='flex' flexDirection='row' style={{ marginBottom: '27px' }} p={0} m={0}>
          <Box flexGrow={1}>
            <Typography gutterBottom variant='h3' component='h2' color='secondary'>
              {t('OPE027')}
            </Typography>
          </Box>
          <Box>
            {/* <Autocomplete
              disableClearable={true}
              fullWidth
              style={{ width: 200 }}
              id='combo-box-demo'
              size='small'
              options={hours}
              getOptionLabel={(option) => option.title}
              style={{ width: 150 }}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {
                  setEventInterval(newValue.value);
                }
              }}
              renderInput={(params) => <TextField {...params} label='Time range' variant='outlined' />}
            /> */}
            <FormControl variant='outlined' size='small' fullWidth style={{ width: 150 }}>
              <InputLabel id='demo-simple-select-outlined-label'>{t('COMMON207')}</InputLabel>
              <Select
                value={eventInterval}
                labelId='demo-simple-select-outlined-label'
                id='demo-simple-select-outlined'
                label={t('COMMON207')}
                onChange={(e) => setEventInterval(e.target.value)}
              >
                <MenuItem value={1}>{t('COMMON208')}</MenuItem>
                <MenuItem value={2}>{t('COMMON194')}</MenuItem>
                <MenuItem value={3}>{t('COMMON209')}</MenuItem>
                <MenuItem value={4}>{t('COMMON195')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Paper elevation={3} style={{ minHeight: '550px', background: 'white' }}>
          {!CommonUtil.isEmpty(store.dashBoard.selectedDoor) && (
            <Grid item xs={12}>
              <Card className='card-box'>
                <div className='card-header-alt d-flex justify-content-between p-2'>
                  <div className='pt-2'>
                    <Typography variant='h5' component='h2' color='secondary' gutterBottom>
                      {store.dashBoard.selectedDoor.doorName}
                    </Typography>
                  </div>
                  <Box className='d-flex align-items-center'>
                    <IconButton
                      size='small'
                      color='secondary'
                      onClick={() => {
                        dispatch({
                          type: 'SITE_DASHBOARD_DOOR',
                          payload: {},
                        });
                      }}
                    >
                      <CancelRoundedIcon color='error' />
                    </IconButton>
                  </Box>
                </div>
              </Card>
              <Divider />
            </Grid>
          )}

          {events ? (
            events.length === 0 && (
              <Paper
                elevation={3}
                style={{
                  width: '100%',
                  minHeight: '550px',
                  overflow: 'auto',
                  backgroundColor: 'white',
                  padding: '40px',
                }}
              >
                <Grid container direction='row' justifyContent='center' alignItems='center' height={'450px'}>
                  <Grid item>
                    <NoEventsFound  name = {t('COMMON462')}/>
                  </Grid>
                </Grid>
              </Paper>
            )
          ) : (
            <></>
          )}

          <PerfectScrollbar options={{ wheelPropagation: false }} onYReachEnd={(container) => handleChangePage(container.scrollTop)}>
            <div style={{ maxHeight: '480px' }}>
              {events ? (
                events.map((data, index) => (
                  <List>
                    {data.pin && (
                      <ListItem
                        secondaryAction={
                          // !CommonUtil.isEmptyString(data.pin) && (
                          // <IconButton onClick={handleMenu} edge='end'>
                          //   <MoreVertIcon />
                          // </IconButton>

                          // )
                          <PopupState variant='popover' popupId='demo-popup-popover'>
                            {(popupState) => (
                              <div>
                                <IconButton {...bindTrigger(popupState)} edge='end'>
                                  <MoreVertIcon />
                                </IconButton>
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <MenuItem style={{ zIndex: '9999', fontSize: '12px' }} onClick={() => handleProfile(data)}>
                                    {t('COMMON092')}
                                  </MenuItem>
                                  {/* )} */}
                                  <MenuItem
                                    onClick={() => {
                                      setTraceOpen(true);
                                      setSelectedEvent(data);
                                      popupState.close();
                                    }}
                                    style={{ fontSize: '12px' }}
                                  >
                                    {t('COMMON218')}
                                  </MenuItem>
                                  {data.id && (
                                    <MenuItem
                                      onClick={() => {
                                        setRemarkOpen(data.uniqueKey);
                                        setRemarks(data.remark);
                                        popupState.close();
                                      }}
                                      style={{ fontSize: '12px' }}
                                    >
                                    {t('COMMON219')}
                                    </MenuItem>
                                  )}
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        }
                      >
                        {!CommonUtil.isEmptyString(data.pin) && (
                          <>
                            <ListItemAvatar>
                              {CommonUtil.isEmptyString(data.profilePhoto) ? <Avatar alt='' /> : <Avatar alt='' src={'data:image/jpeg;base64,' + data.profilePhoto} />}
                            </ListItemAvatar>
                            <Typography>
                              <b>{data.name}</b>
                            </Typography>
                          </>
                        )}
                      </ListItem>
                    )}

                    <ListItemText
                      secondary={
                        <div style={{ marginLeft: '20px', paddingBottom: '5px' }}>
                          <Box display='flex' flexDirection='row'>
                            <Box minWidth={'25%'}>
                              <Typography gutterBottom style={{ fontSize: '12px' }}>
                                {t('COMMON223')}
                              </Typography>
                            </Box>
                            <Box flexShrink={0}>
                              <Tooltip title={data.eventName}>
                                <Typography gutterBottom style={{ fontSize: '12px' }}>
                                  : <span style={{ color: 'gray' }}>{data.eventName}</span>
                                </Typography>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box display='flex' flexDirection='row'>
                            <Box minWidth={'25%'}>
                              <Typography gutterBottom style={{ fontSize: '12px' }}>
                                {t('COMMON222')}
                              </Typography>
                            </Box>
                            <Box flexShrink={0}>
                              <Tooltip title={data.doorName}>
                                <Typography gutterBottom style={{ fontSize: '12px' }}>
                                  : <span style={{ color: 'gray' }}>{data.doorName}</span>
                                </Typography>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box display='flex' flexDirection='row'>
                            <Box minWidth={'25%'}>
                              <Typography gutterBottom style={{ fontSize: '12px' }}>
                                {t('COMMON221')}
                              </Typography>
                            </Box>
                            <Box flexShrink={0}>
                              <Tooltip title={data.verifyModeName}>
                                <Typography gutterBottom style={{ fontSize: '12px', display: 'flex' }}>
                                  :{' '}
                                  <span
                                    style={{
                                      color: 'gray',
                                      width: '170px',
                                      overflow: 'auto',
                                    }}
                                  >
                                    {' '}
                                    &nbsp;{data.verifyModeName}
                                  </span>
                                </Typography>
                              </Tooltip>
                            </Box>
                          </Box>

                          <div className='pt-2'>
                            {/* <Button size='small' className={classes.button}>
                              {data.status}
                            </Button> */}
                            {data.status === 'critical' && (
                              <Button size='small' variant='outlined' color='error'>
                                {/* {data.status} */}
                                {t('COMMON086')}
                              </Button>
                            )}
                            {data.status === 'warning' && (
                              <Button size='small' variant='outlined' color='warning'>
                                {/* {data.status} */}
                                {t('COMMON085')}
                              </Button>
                            )}
                            {data.status === 'normal' && (
                              <Button size='small' variant='outlined' color='success'>
                                {/* {data.status} */}
                                {t('COMMON087')}
                              </Button>
                            )}
                            <Button size='small' style={{ color: '#3d4977' }}>
                              {CommonUtil.getLocalDate1(data.eventTime).split(' ')[1]}
                            </Button>
                          </div>

                          {remarkOpen === data.uniqueKey ? (
                            <Box open tabIndex={-1} sx={{ mt: 1 }}>
                              <TextareaAutosize
                                aria-label='minimum height'
                                minRows={2}
                                value={remarks}
                                placeholder= {t('COMMON225')}
                                className={classes.textarea}
                                onChange={(e) => setRemarks(e.target.value)}
                              />

                              {!data.remark && (
                                <div className='pt-2'>
                                  <Button
                                    size='small'
                                    onClick={() => setRemarkOpen('')}
                                    style={{
                                      color: '#959595',
                                      backgroundColor: '#ebecf1',
                                    }}
                                  >
                                   {t('cancel')}
                                  </Button>{' '}
                                  &nbsp;
                                  <Button
                                    style={{
                                      color: '#fff',
                                      backgroundColor: '#36C96D',
                                    }}
                                    size='small'
                                    onClick={() => submitRemark(data.id, remarks)}
                                  >
                                   {t('COMMON224')}
                                  </Button>
                                </div>
                              )}
                              {data.remark && (
                                <div className='pt-2'>
                                  <Button
                                    size='small'
                                    onClick={() => submitRemark(data.id, null)}
                                    style={{
                                      color: 'white',
                                      backgroundColor: 'red',
                                    }}
                                  >
                                    Delete
                                  </Button>{' '}
                                  &nbsp;
                                  <Button
                                    style={{
                                      color: '#fff',
                                      backgroundColor: '#36C96D',
                                    }}
                                    size='small'
                                    onClick={() => submitRemark(data.id, remarks)}
                                  >
                                    Edit
                                  </Button>
                                </div>
                              )}
                            </Box>
                          ) : (
                            <></>
                          )}
                        </div>
                      }
                    ></ListItemText>
                    <Divider />
                  </List>
                ))
              ) : (
                <></>
              )}
            </div>
          </PerfectScrollbar>
        </Paper>
      </Grid>
      {traceOpen && <PersonTrace open={traceOpen} selectedObject={selectedEvent} setOpen={(value) => setTraceOpen(value)} />}
    </Grid>
  );
}
const hours = [
  { title: 'Last 1 hour', value: 1 },
  { title: 'Last 2 hour', value: 2 },
  { title: 'Last 3 hour', value: 3 },
  { title: 'Last 4 hour', value: 4 },
  { title: 'Last 8 hour', value: 8 },
];
