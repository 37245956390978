import React from 'react';
import { Box, IconButton, Tooltip, Tab, Tabs, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { REACT_URI_PERSON, REACT_URL_PERSON_UPDATE } from '../../actions/organization/EndPoints';
import Breadcrumb from '../../components/Breadcrumb';
import EditPerson from './EditPerson';
import { Address } from './Address';
import { ReactComponent as Setting } from '../../assets/images/visitor-icon/Setting.svg';
import { ReactComponent as Location } from '../../assets/images/visitor-icon/Location.svg';
import { ReactComponent as Basicinfo } from '../../assets/images/visitor-icon/Basicinfo-gray.svg';
import { useLocation } from 'react-router-dom';

function TabPanel(props) {
  const { selectedObject, onClose, children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function UpdatePerson(props) {

  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();

  const breadcrumb = [
    { path: REACT_URI_PERSON, name: t('PERSON001') },
    { path: REACT_URL_PERSON_UPDATE,name:props.history.location.state.selectedObject.disable? t("PERSON001"):t("COMMON005")}
    // { path: REACT_URL_PERSON_UPDATE, name:location.pathname === "/acc/update-person" ? t("COMMON005"): t("PERSON001")}
    //{ path: '', name: t('PERSON013') },
  ];

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Breadcrumb links={breadcrumb}>
          <Tooltip title={t('PERSON013')}>
            <IconButton color='secondary' aria-label={t('PERSON013')}></IconButton>
          </Tooltip>
        </Breadcrumb>
      </div>
      <div className='custom-container'>
        <Tabs
          value={value}
          inkBarStyle={{ background: '#5383FF' }}
          variant='scrollable'
          onChange={handleChange}
          style={{ paddingLeft: '22px', borderBottom: '1px solid #a1a1a124' }}
        >
          <Tab
            style={{ maxWidth: '272px'}}
            label={
              <div>
                <Basicinfo style={{ verticalAlign: 'middle', margin: '14px' }} /> {t('PERSON040')}{' '}
              </div>
            }
          />
          <Tab
            label={
              <div>
                <Location style={{ verticalAlign: 'middle', margin: '14px' }} /> {t('PERSON041')}{' '}
              </div>
            }
          />
          {/* <Tab  label={<div><Setting style={{verticalAlign: 'middle',margin:'14px'}} />  {t('PERSON042')}  </div>}/> */}
        </Tabs>

        {value === 0 && (
          <TabPanel value={value} index={value}>
            <EditPerson onClose={props.location.state.onClose} selectedObject={props.location.state.selectedObject} />
          </TabPanel>
        )}
        {value === 1 && (
          <TabPanel value={value} index={value}>
            <Address onClose={props.location.state.onClose} selectedObject={props.location.state.selectedObject} />
          </TabPanel>
        )}
        {value === 2 && <TabPanel value={value} index={value}></TabPanel>}
      </div>
    </>
  );
}
