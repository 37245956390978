import React, { Fragment } from 'react';
import { AppBar, Box, Typography, Tabs, Tab } from '@material-ui/core';
import { Card } from '@material-ui/core';
import DoorDetailsList from './DoorDetailsList';
import EmployeeDetailsList from './EmployeeDetailsList';
import DepartmentDetailsList from './DepartmentDetailsList';

import { compose } from 'redux'
import { withTranslation } from 'react-i18next';

const TabPanel = (props => {
	const { children, value, index, ...other } = props;
	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			{...other}>
			{value === index && <Box className="card-header-box" p={6}>{children}</Box>}
		</Typography>
	);
});

class ViewDetailsList extends React.Component {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.state = {
			activeTabIndex: 0,
		}
	}

	componentDidMount() {
	}

	handleChange = (event, value) => {
		console.log(event.target.value);
		this.setState({ activeTabIndex: value });
	};

	render() {
		const { t } = this.props;
		const { activeTabIndex } = this.state;

		return (
			<Fragment>
				<div>
					<Card className="card-box mb-4-spacing overflow-visible">
						<div className="card-header--title">
							<AppBar position="static" color="transparent">
								<Tabs value={activeTabIndex} onChange={this.handleChange} aria-label="reports-tabs">
									<Tab label={t("labelViewDetailsDoorsList")} />
									<Tab label={t("labelViewDetailsEmployeesList")} />
									<Tab label={t("labelViewDetailsDepartmentList")} />
								</Tabs>
							</AppBar>

							{activeTabIndex === 0 &&
								<div>
									<TabPanel>
										<DoorDetailsList/>
									</TabPanel>
								</div>
							}
							{activeTabIndex === 1 &&
								<div>
									<TabPanel >
										<EmployeeDetailsList/>
									</TabPanel>
								</div>
							}
							{activeTabIndex === 2 &&
								<div>
									<TabPanel >
										<DepartmentDetailsList/>
									</TabPanel>
								</div>
							}
						</div>
					</Card>
				</div>
			</Fragment>
		);
	}
}

//export default ViewDetailsList;

export default compose(withTranslation())(ViewDetailsList);