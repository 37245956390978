import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, IconButton, Typography, Button } from '@material-ui/core';
import { filterSiteType } from '../../actions/organization/SiteTypeApiCalls';
import { toast } from 'react-toastify';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';
import CancelIcon from '@material-ui/icons/Cancel';

export default function FilterAddressType(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const store = useSelector((state) => state);
  const [open, setOpen] = React.useState(false);

  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const useStyles = makeStyles((theme) => ({
    absolute: {
      margin: theme.spacing(),
      position: 'absolute',
      right: theme.spacing(5),
    },
  }));
  const classes = useStyles();
  const [state, setState] = React.useState({
    name: '',
    code: '',
  });

  const handleClose = () => {
    setState({
      name: '',
      code: '',
    });
    setOpen(false);
  };

  const reset = () => {
    if (state.name.trim() === '' && state.code.trim() === '') {
      toast.error(t('COMMON027'));
      return;
    }
    setState({
      name: '',
      code: '',
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const loadData = () => {
    dispatch(filterSiteType(state.name, state.code, 1, props.rowsPerPage));
  };

  const handleSubmit = () => {
    if (state.name.trim() === '' && state.code.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
    loadData();
    setState({
      name: '',
      code: '',
    });
    handleClose();
  };

  return (
    <div>
      <Tooltip title={t('COMMON006')}>
        <IconButton color='secondary' aria-label='Reset filter' onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        maxWidth='sm'
        open={open}
        onClose={handleClose}
        className='
filter-dialog'
      >
        <DialogTitle>
          <Box display='flex'>
            <Box flexGrow={1}>
              <Typography variant='h4' color='secondary'>
                {t('COMMON006')}
              </Typography>
            </Box>
            <CancelIcon onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth name='name' label={t('COMMON044')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.name} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth name='code' label={t('COMMON045')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.code} />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className='mr-4' style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleSubmit} color='secondary' variant='contained' className='m-2' size='small'>
            {t('COMMON024')}
          </Button>
          <Button onClick={reset} color='secondary' variant='contained' className='m-2' size='small'>
            {t('COMMON025')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
