import React, { forwardRef } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button"; 
import { connect } from "react-redux";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { compose } from 'redux' 
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import ArrowDownward from '@material-ui/icons/ArrowDownward'; 
import TreeItem from '@material-ui/lab/TreeItem'; 
import InputLabel from '@material-ui/core/InputLabel';
import { TreeSelect } from 'antd';
import { Grid } from '@material-ui/core';
import { JAVA_URL_ACCESS_DEPARTMENT_LIST } from '../../actions/EndPoints';
import CommonUtil from '../Util/CommonUtils';
import axios from "axios";
import { withTranslation } from 'react-i18next';
import { adddepartmentDetailsToAccLevel } from '../../actions/AccessLevelActions';
import { getDepartmentList } from '../../actions/DepartmentListActions';
const styles = theme => ({
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 250
    }
  },
  formcontrol: {
    margin: theme.spacing(1),
    width: 250
  },
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="dilog-form-header" {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

class AddDepartment extends React.Component {

  constructor(props) {
    super(props);
    this.isChecked = true;
    this.onTreeDropdownChange = this.onTreeDropdownChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleDialogOK = this.handleDialogOK.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onTimeZoneChange = this.onTimeZoneChange.bind(this);
    this.state = {
      dialogText: '',
      isDialogOpen: false,
      isChecked: false,
      expanded: ['1', '1', '5'],
      selected: [4],
      name: '',
      id: '',
      description: '',
      parentId: [],
      deptHierarchy: '',
      saveType: '',
      ids: []
    }
  }

  onTreeDropdownChange = value => {
    let parentIdVal = {};
    parentIdVal["id"] = value;
    this.setState({ parentId: parentIdVal });
  };
  onTimeZoneChange(e) {

    this.setState({ id: e });
  }
  handleDialogOK() {

    //let idsArr = [];
    //let deptNames = [];
    const levelId = this.props.id;
    //let ids = '';

    let deptdata = [];
    let deptObj = {};
    // eslint-disable-next-line
    this.state.parentId.id.map(id => {
      deptObj.deptId = id.value;
      deptObj.deptName = id.label;
      deptdata.push(deptObj);
    })
    const commonDTO = {
      levelId: levelId,
      deptList: deptdata

    }
    this.props.adddepartmentDetailsToAccLevel(commonDTO);
    this.setState({
      isDialogOpen: false
    });
  }

  handleDialogClose() {
    console.log('Clicked Close');
    this.setState({
      isDialogOpen: false
    });
  }

  handleChange(e) {
    // eslint-disable-next-line
    const target = e.target;
    this.setState({
      isDialogOpen: true
    }, () => { console.log('Open Dialog') });
  }
  handleSelect(event, nodeIds) {
    this.setState({ selected: nodeIds });
  };

  componentDidMount() {
    axios.get(JAVA_URL_ACCESS_DEPARTMENT_LIST).then((response) => {
      if (response.data.data != null) {
        const treeData = CommonUtil.TreeDataFormat(response.data.data);
        this.setState({
          deptHierarchy: treeData,
        });
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.departmentsList !== prevProps.departmentsList) {
      this.setState({ departmentsList: this.props.departmentsList })
    }
  }

  render() {
    const { t } = this.props;

    // eslint-disable-next-line
    const tableIcons = {
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
    };
    // eslint-disable-next-line
    const renderTree = (nodes) => (
      <TreeItem key={nodes.id} nodeId={nodes.name} label={nodes.name}>
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );

    return (
      <div>
        <Button
          className="button-common-primary"
          variant="contained"
          color="primary"
          size="small"
          startIcon={<AddCircleOutlineRoundedIcon />}
          onClick={this.handleChange} checked={this.state.isChecked}
        >
          {t("buttonCommonAdd")}
        </Button>

        <Dialog
          className="dialog-timeperiod"
          open={this.state.isDialogOpen}
          onClose={this.handleDialogClose}
          fullWidth={true}
          maxWidth={'sm'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle onClose={this.handleDialogClose}>{t("commonsTableActionDepartment")}</DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <div className="field-container field-container-tree">
                <InputLabel>
                {t("labelAccessLevelDeptTitle")}
                </InputLabel>
                <div className="field-label field-label-tree">
                  <TreeSelect
                    style={{ width: '100%' }}
                    // value={this.state.value}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={this.state.deptHierarchy}
                    placeholder={t("selectParent")}  
                    treeDefaultExpandAll={false}
                    //  value={this.state.parentId == null ? '' : this.state.parentId.id}
                    onChange={this.onTreeDropdownChange}
                    treeCheckable={true}
                    treeCheckStrictly={true}
                  />
                </div>
              </div>
            </Grid>
            <DialogActions className="dilog-form-adddoor-action">
              <Button color="primary" onClick={this.handleDialogOK} variant="contained">
              {t("ok")}
                  </Button>
              <Button color="primary" onClick={this.handleDialogClose} variant="contained">
              {t("cancel")}
                  </Button>
            </DialogActions>

          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  departmentsList: state.DepartmentList.departmentList
});

export default compose(withTranslation(), withStyles(styles, { withTheme: true }), connect(mapStateToProps, { getDepartmentList, adddepartmentDetailsToAccLevel }))(AddDepartment);
