import React, { Fragment, forwardRef, useState, useEffect } from 'react';
import MaterialTable, { MTableHeader } from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Box, TablePagination, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CommonUtil from '../../components/Util/CommonUtils';
import { useDispatch, useSelector } from 'react-redux';
import { getDoorEvents } from '../../actions/DashBoardActions';

export default function SiteEventsTable(props) {
  const [doorEvents, setDoorEvents] = useState([]);
  const { t } = useTranslation();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(0);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columns = [
    { title: t('name'), field: 'name', render: (rowData) => (CommonUtil.isEmptyString(rowData.pin) ? '' : rowData.name)},
    { title: t('event'), field: 'eventName' },
    { title: t('time'), field: 'eventTime', render: (rowData) => <>{CommonUtil.getLocalDate1(rowData.eventTime)}</> },
    { title: t('location'), field: 'siteName' },
    { title: t('sNumber'), field: 'devSn' },
    {
      title: t('action'),
      field: 'status',
      render: (rowData) => (
        <span style={{ color: rowData.status === 'normal' ? '#69f0ae' : rowData.status === 'warning' ? '#ed6c02' : '#ef5350' }}>
          <Typography style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{rowData.status=='warning'?t('COMMON085'):rowData.status=='normal'?t('COMMON087'):t('COMMON086')}</Typography>
        </span>
      ),
    },
  ];
  useEffect(() => {
    // axios.get('/api/v2.0/dashboard/door/events?siteId=' + props.siteId).then((response) => {
    //   if (response.data) {
    //     setDoorEvents(response.data.data);
    //   }
    // });
    var selectedDoor = '';
    if (!CommonUtil.isEmpty(store.dashBoard.selectedDoor)) {
      selectedDoor = store.dashBoard.selectedDoor.doorId;
    }
    dispatch(
      getDoorEvents(props.siteId, 24, selectedDoor, page + 1, rowsPerPage, store.siteDashBoard.siteEventsTableData, (data) => {
        if (data.code === 'CASI0001') {
          //setDoorEvents(data.data);
        }
      })
    );
  }, [store.dashBoard.selectedDoor]);

  // useEffect(() => {
  //   var siteEventsData = store.siteDashBoard.siteEventsTableData.event;
  //   if (!CommonUtil.isEmpty(store.dashBoard.activeDoors)) {
  //     var eventList = [store.dashBoard.activeDoors[0]];
  //     eventList = eventList.concat(siteEventsData);
  //     setTimeout(() => {
  //       dispatch({
  //         type: 'SITE_EVENTS_TABLE_DATA',
  //         payload: eventList,
  //       });
  //     }, 5000);
  //   }
  // }, [store.dashBoard.activeDoors]);

  // useEffect(() => {
  //   var siteEventsData = store.siteDashBoard.siteEventsTableData;
  //   if (siteEventsData.totalCount < 1) {
  //     var selectedDoor = '';
  //     if (!CommonUtil.isEmpty(store.dashBoard.selectedDoor)) {
  //       selectedDoor = store.dashBoard.selectedDoor.doorId;
  //     }
  //     setTimeout(() => {
  //       dispatch(
  //         getDoorEvents(props.siteId, 24, selectedDoor, 1, 10, store.siteDashBoard.siteEventsTableData, (data) => {
  //           if (data.code === 'CASI0001') {
  //             //setDoorEvents(data.data);
  //           }
  //         })
  //       );
  //     }, 5000);
  //   } else {
  //     if (store.dashBoard.activeDoors instanceof Array && store.dashBoard.activeDoors.length > 0) {
  //       var eventList = [store.dashBoard.activeDoors[0]];
  //       eventList = eventList.concat(siteEventsData.event);
  //       siteEventsData.event = eventList;
  //       setTimeout(() => {
  //         dispatch({
  //           type: 'SITE_EVENTS_TABLE_DATA',
  //           payload: siteEventsData,
  //         });
  //       }, 5000);
  //     }
  //   }
  // }, [store.dashBoard.activeDoors]);

  useEffect(() => {
    setTotalRecords(store.siteDashBoard.siteEventsTableData.totalCount);
    setPage(store.siteDashBoard.siteEventsTableData.currentPage - 1);
    setTotalPages(store.siteDashBoard.siteEventsTableData.totalPages);
    setDoorEvents(store.siteDashBoard.siteEventsTableData.event);
  }, [store.siteDashBoard.siteEventsTableData.event]);

  // useEffect(() => {
  //   var siteEventsData = store.siteDashBoard.siteEventsTableData.event;
  //   if (!CommonUtil.isEmpty(store.dashBoard.selectedDoor)) {
  //     setDoorEvents.push(store.dashBoard.activeDoors);
  //     setTimeout(() => {
  //       dispatch({
  //         type: 'SITE_EVENTS_TABLE_DATA',
  //         payload: siteEventsData,
  //       });
  //     }, 5000);
  //   }
  // }, [store.dashBoard.activeDoors]);

  const loadData = (pageNo, pageSize) => {
    var selectedDoor = '';
    if (!CommonUtil.isEmpty(store.dashBoard.selectedDoor)) {
      selectedDoor = store.dashBoard.selectedDoor.doorId;
    }
    dispatch(
      getDoorEvents(props.siteId, 24, selectedDoor, pageNo + 1, pageSize, store.siteDashBoard.siteEventsTableData, (data) => {
        if (data.code === 'CASI0001') {
          //setDoorEvents(data.data);
        }
      })
    );
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  return (
  
    <div style={{ width: '100%' }}>
      <MaterialTable
        title={
          <Typography gutterBottom variant='h3' component='h2' color='secondary' style={{ margin: '18px' }}>
            {t('OPE027')}
          </Typography>
        }
        columns={columns}
        data={doorEvents}
        icons={tableIcons}
        options={{
          sorting: false,
          maxBodyHeight: 650,
          paging: true,
          draggable: false,
          headerStyle: {
            backgroundColor: '#3d4977',
            color: '#fff',
            position: 'sticky',
          },
          cellStyle: {
            borderLeft: '1px solid #D3D3D3',
          },
          pageSize: rowsPerPage,
        }}
        components={{
          Pagination: (tableProps) => (
            <TablePagination {...tableProps} count={totalRecords} page={page} rowsPerPage={rowsPerPage} onChangePage={(event, page) => handleChangePage(page)} />
          ),
        }}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
        localization={{
          body: {
            emptyDataSourceMessage: t('dataTableEmptyList'),
          },
          toolbar: {
            searchTooltip: t('search'),
            searchPlaceholder: t('search'),
          },
          pagination: {
            labelDisplayedRows: '{from}-{to} ' + t('dataTableOf') + ' {count}',
            labelRowsSelect: t('dataTableRows'),
            firstAriaLabel: t('dataTableFirstPage'),
            firstTooltip: t('dataTableFirstPage'),
            previousAriaLabel: t('dataTablePreviousPage'),
            previousTooltip: t('dataTablePreviousPage'),
            nextAriaLabel: t('dataTableNextPage'),
            nextTooltip: t('dataTableNextPage'),
            lastAriaLabel: t('dataTableLastPage'),
            lastTooltip: t('dataTableLastPage'),
          }
        }}
      />
    </div>
  );
}
