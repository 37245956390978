import React from 'react';
import {  IconButton, Tooltip,  Menu} from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@material-ui/icons/Menu';
import HandleDoorOperations from './HandleDoorOperations';
import { ReactComponent as More } from '../../assets/icons/More.svg';

export default function DoorOperations(props) {
  const { t } = useTranslation();
  const zlink=localStorage.getItem('redirectFrom');
  return (
    <>
      <PopupState variant='popover' popupId='demo-popup-menu'>
        {(popupState) => (
          <React.Fragment>
            <Tooltip title={t('COMMON030')}>
              <IconButton color='secondary' aria-label={t('COMMON030')} {...bindTrigger(popupState)}>
                <More style={{ height: 30, width: 30 }} />
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)}>
             
                
              <HandleDoorOperations doors={props.doors} operation={'normalOpenDoor'} title={t('DOORI025')} {...props} popupState={popupState} />
              {!zlink&&(
              <>
              <HandleDoorOperations doors={props.doors} operation={'lockDoor'} title={t('DOORI026')} {...props} popupState={popupState} />
              <HandleDoorOperations doors={props.doors} operation={'unLockDoor'} title={t('DOORI027')} {...props} popupState={popupState} />
              <HandleDoorOperations doors={props.doors} operation={'enableNormalOpenDoor'} title={t('DOORI028')} {...props} popupState={popupState} />
              <HandleDoorOperations doors={props.doors} operation={'disableNormalOpenDoor'} title={t('DOORI029')} {...props} popupState={popupState} />
              </>)}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </>
  );
}
