import axios from 'axios';
import { JAVA_API_MEMBERSHIP, JAVA_API_PERSON } from './ApiEndPoints';
import { USER_FILTER_LIST, USER_ROLE_LIST } from './types';

export const getApplications = (callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_MEMBERSHIP);
    callBack(res.data.data);
  } catch {}
};

export const getMemberList = (applicationId, page, rowsPerPage) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_MEMBERSHIP + '/' + applicationId + '?page=' + page + '&size=' + rowsPerPage);
    if (res.status === 200) {
      dispatch({
        type: USER_ROLE_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: USER_ROLE_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const getAllRoles = (applicationId, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_MEMBERSHIP + '/' + applicationId + '/role');
    callBack(res.data.data);
  } catch {}
};

export const perosnWithUser = (firstName, page, size, callBack) => async (dispatch) => {
  try {
    let query = '&withUser=true';
    if (firstName !== '') {
      query += '&firstName=' + firstName;
    }

    const res = await axios.get(JAVA_API_PERSON + '/' + 'search' + '?page=' + page + '&size=' + size + query);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      callBack(res.data);
    }
  } catch {}
};

export const personWithUserRole = (firstName, lastName, phone, roleId, applicationId, page, rowsPerPage) => async (dispatch) => {
  try {
    let query = '';
    if (firstName !== '') {
      query += '&firstName=' + firstName;
    }
    if (lastName !== '') {
      query += '&lastName=' + lastName;
    }
    if (phone !== '') {
      query += '&phone=' + phone;
    }
    if (roleId !== '') {
      query += '&roleId=' + roleId;
    }
    const res = await axios.get(JAVA_API_MEMBERSHIP + '/' + applicationId + '?page=' + page + '&size=' + rowsPerPage + query);
    if (res.status === 200) {
      dispatch({
        type: USER_ROLE_LIST,
        type: USER_FILTER_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: USER_ROLE_LIST,
        type: USER_FILTER_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const addMembership = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.post(JAVA_API_MEMBERSHIP, payload);
    if (res.status === 200) {
      callBack(res.data);
    }
  } catch {}
};

export const updateMembership = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_MEMBERSHIP, payload);
    if (res.status === 200) {
      callBack(res.data);
    }
  } catch {}
};

export const deleteMembership = (applicationId, userIds, callBack) => async (dispatch) => {
  try {
    const query = '?applicationId=' + applicationId + '&userId=' + userIds;
    const res = await axios.delete(JAVA_API_MEMBERSHIP + query);
    if (res.status === 200) {
      callBack(res.data);
    }
  } catch {}
};
