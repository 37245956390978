import React, { useEffect } from 'react';
import CommonUtil from '../../components/Util/CommonUtils';
import { ReactComponent as ExitDoor } from '../../assets/icons/ExitDoor.svg';
import { ReactComponent as EntranceDoor } from '../../assets/icons/EntranceDoor.svg';

function DoorIcon(props) {
  const [color, setColor] = React.useState('gray');

  useEffect(() => {
    CommonUtil.isEmptyString(props.color) ? setColor('gray') : setColor(props.color);
  }, [props.color]);

  const entryExitDoor = (doorType) => {
    if (doorType === 'In') {
      return <ExitDoor height={30} />;
    } else {
      return <EntranceDoor height={30} />;
    }
  };
  const doorIcon = () => {
    if (props.active) {
      return (
        <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 88 88'>
          <g id='Group_1' data-name='Group 1' transform='translate(-161 -181)'>
            <circle id='Circle_4' data-name='Circle 4' cx='44' cy='44' r='44' transform='translate(161 181)' fill={color} opacity='10%' />
            <circle id='Cirlce_3' data-name='Cirlce 3' cx='33' cy='33' r='33' transform='translate(172 192)' fill={color} opacity='8%' />
            <circle id='Circle_2' data-name='Circle 2' cx='22' cy='22' r='22' transform='translate(183 203)' fill={color} opacity='6%' />
            <circle id='Circle_1' data-name='Circle 1' cx='9' cy='9' r='9' transform='translate(196 216)' fill={color} />
          </g>
        </svg>
      );
    } else {
      return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
          <g id='Group_1' data-name='Group 1' transform='translate(-225 -354)'>
            <path id='Cicle_3' data-name='Cicle 3' d='M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z' transform='translate(225 354)' fill={color} opacity='10%' />
            <path
              id='Circle_2'
              data-name='Circle 2'
              d='M10,.227A9.77,9.77,0,1,0,13.8,1,9.775,9.775,0,0,0,10,.227M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z'
              transform='translate(225 354)'
              fill={color}
            />
            <circle id='Cicle_1' data-name='Cicle 1' cx='4' cy='4' r='4' transform='translate(231 360)' fill={color} />
          </g>
        </svg>
      );
    }
  };

  return CommonUtil.isEmptyString(props.type) ? doorIcon() : entryExitDoor(props.type);
}
export default DoorIcon;
