 import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import OutputLinkage from './OutputLinkage';
import EmailLinkage from './EmailLinkage';
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from 'redux';
import {getAccDevices} from "../../actions/DeviceActions";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }));
  
 function LinkageTabs(props)   {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    //let [responseData, setResponseData] = React.useState('');
    const handleChange = (event, newValue) => {
      setValue(newValue);
    }; 
        return (
            <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="OUTPUT POINT" {...a11yProps(0)} />
                 {/* <Tab label="VIDEO LINKAGE" {...a11yProps(1)} />  */}
                 <Tab label="EMAIL" {...a11yProps(1)} /> 
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
               <OutputLinkage data={props.data} name={props.name} deviceId={props.deviceId}></OutputLinkage>
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
               <VideoLinkage></VideoLinkage>
            </TabPanel> */}
            <TabPanel value={value} index={1}>
               <EmailLinkage></EmailLinkage>
            </TabPanel>
          </div>
        );
     
}
const mapStateToProps = (state) => ({
	accDevices: state.AccDevice.accDeviceList,
});
 
export default compose(withTranslation(), withStyles(  { withTheme: true }), connect(mapStateToProps,{ getAccDevices}))(LinkageTabs);
 
 