import axios from "axios";
import { GET_ACCESS_DEPARTMENT_LIST, GET_ACCESS_DEPARTMENT_LIST_BY_DEPARTMENTID,GET_ACCESS_LEVEL_DEPT_LIST ,GET_ACCESS_DEPT_LIST} from "./types";
import { JAVA_URL_ACCESS_DEPARTMENT_LIST_BY_DEPARTMENTID,JAVA_URL_ACc_DEPT_LIST,JAVA_URL_ACCESS_LEVEL_DEPARTMENT_PAGE} from "./EndPoints";

export const getDepartmentList = (depts) => async dispatch => {
 
	const res = await axios.get(JAVA_URL_ACCESS_LEVEL_DEPARTMENT_PAGE);
	dispatch({
		type: GET_ACCESS_DEPARTMENT_LIST,
		payload: res.data.data
	});
};
export const getDeptList = (query) => async dispatch => {
 
	const res = await axios.get(JAVA_URL_ACCESS_LEVEL_DEPARTMENT_PAGE+'?page=' + query.page + '&size=' + query.pageSize);
	dispatch({
		type: GET_ACCESS_DEPT_LIST,
		payload: res.data.data
	});
};
export const getAccLevelDepartmentList = () => async dispatch => {
 
	const res = await axios.get(JAVA_URL_ACc_DEPT_LIST);
	dispatch({
		type: GET_ACCESS_LEVEL_DEPT_LIST,
		payload: res.data.data
	});
};

export const getDepartmentListByDepartmentId = (departmentId) => async dispatch => {
 
	const res = await axios.get(JAVA_URL_ACCESS_DEPARTMENT_LIST_BY_DEPARTMENTID+departmentId);
	dispatch({
		type: GET_ACCESS_DEPARTMENT_LIST_BY_DEPARTMENTID,
		payload: res.data.data
	});
};
