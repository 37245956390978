import React, { Fragment, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { filterSite, getSites } from '../../actions/organization/SiteApiCalls';
import { toast } from 'react-toastify';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography, IconButton, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';
import CancelIcon from '@material-ui/icons/Cancel';

export default function FilterSite(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [open, setOpen] = React.useState(false);
  const store = useSelector((state) => state);
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const useStyles = makeStyles((theme) => ({
    absolute: {
      margin: theme.spacing(),
      position: 'absolute',
      right: theme.spacing(5),
    },
  }));

  const ITEM_HEIGHT = 30;

  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        margin:'50px  0px 0px 0px'
      },
    },
  };

  const classes = useStyles();
 // eslint-disable-next-line
  const [parentSites, setParentSites] = React.useState([]);
  const [state, setState] = React.useState({
    name: '',
    parentId: '',
  });

  useEffect(() => {
    loadData();
  }, []);

  const reset = () => {
    if (state.name.trim() === '' && state.parentId.trim() === '') {
      toast.error(t('COMMON027'));
      return;
    }

    setState({
      name: '',
      parentId: '',
    });
    //setOpen(false);
  };

  const handleClose = () => {
    setState({
      name: '',
      parentId: '',
    });
    setOpen(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const loadData = () => {
    dispatch(
      filterSite(state.name, state.parentId, 1, props.rowsPerPage,(data) => {
        setParentSites(data.site);
      })
    );
  };

  const handleSubmit = () => {
    if (state.name.trim() === '' && state.parentId.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
    props.applyFilter(state);
    setState({
      name: '',
      parentId: '',
    });
    handleClose();
  };

  return (
    <div>
      <Tooltip title={t('COMMON006')}>
        <IconButton color='secondary' aria-label='Reset filter' onClick={() => setOpen(true)}>
          <FilterListIcon onClick={loadData} />
        </IconButton>
      </Tooltip>
      <Dialog maxWidth='md' open={open} onClose={handleClose} className='filter-dialog'>
        <DialogTitle>
          <Box display='flex'>
            <Box flexGrow={1}>
              <Typography variant='h4' color='secondary'>
                {t('COMMON006')}
              </Typography>
            </Box>
            <CancelIcon onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent justifyContent='center'>
          <form noValidate autoComplete='off'>
              <Fragment>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth name='name' label={t('COMMON044')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.name} style={{width:'350px'}} />
                </Box>
              </Fragment>
              <Fragment>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('COMMON050')}</InputLabel>
                    <Select label={t('COMMON050')} name='parentId' onChange={handleChange} value={state.parentId} MenuProps={MenuProps} style={{width:'350px'}}>
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {parentSites ? (
                        parentSites.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Fragment>
          </form>
        </DialogContent>
        <DialogActions className='mr-4' style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleSubmit} color='secondary' variant='contained' className='m-2' size='small'>
            {t('COMMON024')}
          </Button>
          <Button onClick={reset} color='secondary' variant='contained' className='m-2' size='small'>
            {t('COMMON025')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
