import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import biozlink from '../../assets/images/onboard/access-cloud.png';
import cloudAccess from '../../assets/images/onboard/cloudaccess.png';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './custom.css';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import CommonUtil from '../../components/Util/CommonUtils';
import { validateActivationLink } from '../../actions/organization/PersonApiCalls';
import LoginLayout from './LoginLayout';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

function ActivateAccount(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
    confirmPassword: '',
    confirmShowPassword: false,
  });

  const [error, setError] = useState({
    password: false,
    confirmPassword: false,
  });

  const [code, setCode] = useState('');

  const query = new URLSearchParams(props.location.search);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setError({ ...error, [prop]: false });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickConfirmShowPassword = () => {
    setValues({ ...values, confirmShowPassword: !values.confirmShowPassword });
  };

  useEffect(() => {
    var urlValue = window.location.href;
    var url = new URL(urlValue);
    var code = url.searchParams.get('code');
    if (code != null) {
      setCode(code);
    }
    var lang = url.searchParams.get('lang');
    if (lang != null) {
      BrowserLanguage.setLanguage(lang);
      i18n.changeLanguage(lang);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const user = {
      password: values.password,
      confirmPassword: values.confirmPassword,
    };

    dispatch(
      validateActivationLink(
        code,
        user,
        (successData) => {
          history.push({
            pathname: '/acc/result-page',
            state: { message: t('RPW003'), success: true, redirectLogin: true },
          });
        },
        (errorData) => {
          handleError(errorData);
          toast.error(errorData.message);
        }
      )
    );
  };

  const handleError = (error) => {
    switch (error.code) {
      case 'CAAE0605':
        setError({ ...error, confirmPassword: true });
        break;
      case 'CAAE0603':
        setError({ ...error, password: true });
        break;
      case 'CAAE0606':
        setError({ ...error, password: true });
        break;
      case 'CAAE0607':
        setError({ ...error, confirmPassword: true });
        break;
    }
  };

  return CommonUtil.isEmptyString(code) ? (
    <></>
  ) : (
    <>
      <LoginLayout>
        <Grid container spacing={5} alignItems='center'>
          <Grid item></Grid>
          <Grid item xs>
            <Typography className='signin_title' variant='h5' color='textPrimary' align='center'>
              {t('RPW001')}
            </Typography>
          </Grid>
        </Grid>
        <form className={classes.form} noValidate onSubmit={handleSubmit} autoComplete='off'>
          <Grid container alignItems='center'>
            <Grid item>
              <PersonIcon className='login_icons' />
            </Grid>
            <Grid item xs>
              <TextField
                className='text_input'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                label={t('FPW002')}
                id='password'
                autoComplete='off'
                name='password'
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                error={error.password}
                onChange={handleChange('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                        {values.showPassword ? <Visibility className='visibilityIcons' /> : <VisibilityOff className='visibilityIcons' />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems='center'>
            <Grid item>
              <LockIcon className='login_icons' />
            </Grid>
            <Grid item xs>
              <TextField
                className='text_input'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                label={t('FPW003')}
                id='confirmpassword'
                autoComplete='off'
                name='password'
                type={values.confirmShowPassword ? 'text' : 'password'}
                value={values.confirmPassword}
                onChange={handleChange('confirmPassword')}
                error={error.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleClickConfirmShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                        {values.confirmShowPassword ? <Visibility className='visibilityIcons' /> : <VisibilityOff className='visibilityIcons' />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={5} alignItems='center'>
            <Grid item></Grid>
            <Grid item xs>
              <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
                {t('RPW002')}
              </Button>
            </Grid>
          </Grid>
        </form>

        <Grid container spacing={5} alignItems='center'>
          <Grid item></Grid>
          <Grid item xs>
            <Box align='center'>
              <Link href='/acc/login' className='link'>
                {t('FPW005')}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </LoginLayout>
    </>
  );
}
export default withRouter(ActivateAccount);
