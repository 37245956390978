import { Box, Grid, Dialog, DialogTitle, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { modifyIpaddress } from '../../actions/DeviceOperationsApiCalls';
import { getDevices } from '../../actions/DeviceApiCalls';
import CommonUtil from '../../components/Util/CommonUtils';

export default function ModifyIpaddress(props) {
  const isIp = require('is-ip');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [popupState, setPopupState] = React.useState(props.popupState);
  const [selectedDevices, setSelectedDevice] = React.useState();
  const [state, setState] = React.useState({
    ipAddress: '',
    subnetMask: '',
    gatewayAddress: '',
  });
  const [error, setError] = React.useState({
    ipAddress: false,
    subnetMask: false,
    gatewayAddress: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    setError({
      ...error,
      [event.target.name]: false,
    });
  };

  const loadDevices = () => {
    dispatch(getDevices('', '', '', '',1, 1000));
  };

  const deviceOperations = () => {
    if (props.devices.length < 1) {
      toast.warning(t('DEVI0014'));
      handleClose();
      return;
    }

    if (props.devices.length > 1) {
      toast.warning(t('DEVI0039'));
      handleClose();
      return;
    }

    setOpen(true);

    var ids = '';
    const { devices } = props;
    var dev = devices[0];
    setState({
      ipAddress: dev.ipAddress,
      subnetMask: dev.subnetMask,
      gatewayAddress: dev.gateway,
    });
    // eslint-disable-next-line
    devices.map((device, index) => {
      if (index === devices.length - 1) ids = ids + device.id;
      else ids = ids + device.id + ',';
    });

    setSelectedDevice(ids);

    // confirmAlert({
    //     customUI: ({ onClose }) => {
    //         return (
    //             <ConfirmModal open={true} text={confirmMessage} onConfirm={() => { dispatch(performDeviceOperations(ids, operation)); onClose() }} onClose={() => onClose()} />
    //         );
    //     }
    // });
  };

  const handleClose = () => {
    setOpen(false);
    props.onSuccess();
    popupState.close();
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.ipAddress)) {
      setError({
        ipAddress: true,
      });
      toast.error(t('DEVI0053'));
      return;
    } else if (CommonUtil.isEmptyString(state.subnetMask)) {
      setError({
        subnetMask: true,
      });
      toast.error(t('DEVI0055'));
      return;
    } else if (CommonUtil.isEmptyString(state.gatewayAddress)) {
      setError({
        gatewayAddress: true,
      });
      toast.error(t('DEVI0056'));
      return;
    }

    if (!isIp.v4(state.ipAddress)) {
      setError({
        ipAddress: true,
      });
      toast.error(t('DEVI0056'));
      return;
    } else if (!isIp.v4(state.subnetMask)) {
      setError({
        subnetMask: true,
      });
      toast.error(t('DEVI0056'));
      return;
    } else if (!isIp.v4(state.gatewayAddress)) {
      setError({
        gatewayAddress: true,
      });
      toast.error(t('DEVI0056'));
      return;
    }

    dispatch(
      modifyIpaddress(selectedDevices, state.ipAddress, () => {
        popupState.close();
        loadDevices();
        setState({ ipAddress: '', subnetMask: '' });
      })
    );
  };

  return (
    <>
      <MenuItem onClick={() => deviceOperations()}>{t('DEVI0050')}</MenuItem>

      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-filter-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('DEVI0050')}</Box>
            <CancelIcon onClick={handleClose} style={{ color: 'red', background: 'white', borderRadius: '50%' }} fontSize='small' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.ipAddress}
                    name='ipAddress'
                    label={t('DEVI0004') + ' *'}
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.ipAddress}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.subnetMask}
                    name='subnetMask'
                    label={t('DEVI0027') + ' *'}
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.subnetMask}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.gatewayAddress}
                    name='gatewayAddress'
                    label={t('DEVI0052') + ' *'}
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.gatewayAddress}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
