import { Box, Button, Grid, IconButton, Tooltip, Fab } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getLevels, removeLevels, resetLevelDetails } from '../../actions/LevelApiCalls';
import EditIcon from '@material-ui/icons/Edit';
import { REACT_URL_LEVEL, REACT_URL_LEVEL_DETAILS } from '../../actions/EndPoints';
import Headerbar from '../../components/HeaderBar';
import { useHistory } from 'react-router-dom';
import AddLevel from './AddLevel';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ConfirmModal from '../../components/ConfirmModal';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { ReactComponent as RemoteNormallyOpen } from '../../assets/cloudaccess-svg/Remote Open.svg';
import FilterLevel from './FilterLevel';
import SubmitButton from '../../components/SubmitButton';
import CommonUtil from '../../components/Util/CommonUtils';
import { ReactComponent as Add } from '../../assets/icons/add.svg';
import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export default function Level() {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setDate] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});

  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: 1000,
    name: '',
    timeZoneName: '',
  });
  //const [state, setState] = React.useState({
  const [state] = React.useState({
    id: '',
    name: '',
    timeSegLevelDTO: '',
    zoneId: '',
  });

  useEffect(() => {
    //loadLevels();
    dispatch(resetLevelDetails());
    // eslint-disable-next-line
  }, []);

  const [filter, setFilter] = useState({
    name: '',
    timeZoneName: '',
  });

  const defaultFilter = {
    name: '',
    timeZoneName: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      name: filterData.name,
      timeZoneName: filterData.timeZoneName,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadLevels(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    onSelectionChange([]);
    if (!CommonUtil.isEmpty(store.level.levels)) {
      setTotalRecords(store.level.levels.totalConunt);
      setPage(store.level.levels.curPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setDate(store.level.levels.levels);
    // eslint-disable-next-line
  }, [store.level.levels]);

  useEffect(() => {
    setDate(store.level.levels.levels);
  }, [store.level.levels]);

  const loadLevels = (page, rowsPerPage) => {
    dispatch(getLevels(filter.name, filter.timeZoneName, page + 1, rowsPerPage));
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadLevels(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadLevels(0, pageSize);
  };

  const breadcrumb = [
    //{ path: REACT_URL_LEVEL, name: t("COMMON017") },
    { path: REACT_URL_LEVEL, name: t('LEVEL001') },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage
  };

  const columns = [
    {
      field: 'name',
      title: t('COMMON014'),
      tooltip: t('COMMON071'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'timeSegName', title: t('LEVEL007'), editable: 'never' },
    { field: 'doorCount', title: t('LEVEL016'), editable: 'never' },
    { field: 'personCount', title: t('LEVEL017'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <RemoteNormallyOpen color='secondary' />,
      tooltip: t('LEVEL015'),
      position: 'row',
      onClick: (event, rowData) => {
        openLevelDetailsPage(rowData);
      },
    },
    {
      icon: () => <EditOutlinedIcon  color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <DeleteOutlineIcon  color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  const openLevelDetailsPage = (data) => {
    history.push({
      pathname: REACT_URL_LEVEL_DETAILS,
      state: { data: data },
    });
  };

  const openAddLevelDialog = () => {
    setUpdateObject(state);
    setOpenEditDialog(true);
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var ids = '';
    // eslint-disable-next-line
    data.map((record, index) => {
      if (index === data.length - 1) ids = ids + record.id;
      else ids = ids + record.id + ',';
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(removeLevels(ids,()=> loadLevels(page, rowsPerPage)));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Tooltip title={t('COMMON009')}>
            <Fab color='secondary' size='small' aria-label={t('COMMON009')} onClick={openAddLevelDialog}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Breadcrumb>
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1}>
          <Tooltip title={t('COMMON004')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => deleteRecord(selectedData)}>
              <DeleteOutlineIcon  />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid container justify='flex-end' style={{ padding: '18px' }}>
          <FilterLevel page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Grid>
      </Toolbar>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
      {openEditDialog && (
        <AddLevel
          level={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadLevels(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
