import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import {getAccDevices} from "../../actions/DeviceActions";
import { Grid} from '@material-ui/core';
import { compose } from 'redux'
import HomeIcon from '@material-ui/icons/Home';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import {  Card } from "@material-ui/core";
import { NavLink } from 'react-router-dom';
import LinkageTrigger from './LinkageTrigger';
import LinkageTabs from './LinkageTabs';
import axios from "axios";
import { JAVA_URL_LINKAGE_INOUT } from '../../actions/EndPoints';
const styles = theme => ({
	form: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: 250
		}
	},
	formcontrol: {
		width: 150
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	}
});


 

class AddLinkage extends React.Component {

	constructor(props) {
		super(props);
		 
		this.state = {
			message: "" ,
			inOutData: [],
			devices: [],
			triggerIds: '',
			deviceId: '',
			AuxInOutData: [],
			name: ''
		}
	}
	componentDidMount() {
		 
		
	  }
	  callbackFunction = (deviceId,arr,name) => {  
		setTimeout(() => {
			axios.get(JAVA_URL_LINKAGE_INOUT, {
			   params: {
				  devId: deviceId, 
				  triggerOpt: arr 
				}
			  }).then((response) => {
				this.setState({AuxInOutData:response.data.data})
			  })
			  .catch((error) => {
				console.log(error)
			  })
	 
	 
		   }, 1000);

		this.setState({triggerIds: arr,deviceId:deviceId,name:name})
  }

    
	 
	render() {
		 
		 //const { t } = this.props;
		 console.log("idsListdatra  === "+JSON.stringify(this.state.triggerIds))
		 
		return (
			
			<div className="root">
				<Grid
					container
					direction="row"
					justify="flex-start"
					alignItems="flex-start">
					<Grid item xs={12}>
						<NavLink to="/acc/linkage" style={{ textDecoration: 'none', color: 'unset' }} >
							<HomeIcon color="primary" fontSize="small" />
							<span style={{ paddingLeft: "10px", fontSize: "15px", color: "#858585" }}>&#8250;</span>
							<label className="timeLine" style={{ paddingLeft: "5px", color: "#858585" }}>
								{this.props.path || 'Linkage'}
							</label>
							<span style={{ paddingLeft: "10px", fontSize: "15px", color: "#858585" }}>&#8250;</span>
							<label className="timeLine" style={{ paddingLeft: "5px", color: "#858585" }}>
								{"Add Linkage"}
					   </label>
						</NavLink >
					</Grid>
				</Grid>
				<div>
					<Card  >
						<div className=""> 
                             <LinkageTrigger parentCallback = { this.callbackFunction}   deviceData={this.props.accDevices} auxInData={this.state.AuxInOutData}></LinkageTrigger>
						</div>
                        <div className="">
						<LinkageTabs data={this.state.AuxInOutData} name={this.state.name} deviceId={this.state.deviceId}></LinkageTabs>
						</div>
					</Card>
				</div>

			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	transactions: state.LinkageList.linkageList,
	accDevices: state.AccDevice.accDeviceList,
});

export default compose(withTranslation(), withStyles(styles, { withTheme: true }), connect(mapStateToProps,{ getAccDevices}))(AddLinkage);