import React from "react"

const NoEventsFound = (props) =>{

  return ( <svg xmlns="http://www.w3.org/2000/svg" width="303.926" height="278.98" viewBox="0 0 203.926 278.98">
  <g id="Group_55" data-name="Group 55" transform="translate(-457.179 -407.874)" >
    <text id="No_Events_found" data-name="No Events found" transform="translate(538.497 682.854)" fill="#a4a4a4" font-size="14" font-family="Roboto-Regular, Roboto"><tspan x="-34" y="0">{props.name}</tspan></text>
    <g id="Group_53" data-name="Group 53" transform="translate(522.063 407.874)" opacity="0.4">
      <rect id="Rectangle_1" data-name="Rectangle 1" width="135.87" height="191.155" rx="9" transform="translate(0 0)" fill="#f6f6f6"/>
      <rect id="Rectangle_2" data-name="Rectangle 2" width="113.85" height="9.839" transform="translate(11.244 23.426)" fill="#dedede"/>
      <rect id="Rectangle_3" data-name="Rectangle 3" width="113.85" height="9.839" transform="translate(11.244 43.104)" fill="#dedede"/>
      <rect id="Rectangle_4" data-name="Rectangle 4" width="57.159" height="9.37" transform="translate(11.244 72.152)" fill="#dedede"/>
      <rect id="Rectangle_5" data-name="Rectangle 5" width="113.85" height="2.811" transform="translate(11.244 124.157)" fill="#dedede"/>
      <rect id="Rectangle_6" data-name="Rectangle 6" width="113.85" height="2.811" transform="translate(11.244 136.807)" fill="#dedede"/>
      <rect id="Rectangle_7" data-name="Rectangle 7" width="113.85" height="2.343" transform="translate(11.244 149.457)" fill="#dedede"/>
    </g>
    <g id="Group_54" data-name="Group 54" transform="translate(457.179 468.497) rotate(-25)">
      <rect id="Rectangle_1-2" data-name="Rectangle 1" width="135.87" height="191.155" rx="9" transform="translate(0 0)" fill="#f6f6f6"/>
      <rect id="Rectangle_2-2" data-name="Rectangle 2" width="113.85" height="9.839" transform="translate(11.244 23.426)" fill="#dedede"/>
      <rect id="Rectangle_3-2" data-name="Rectangle 3" width="113.85" height="9.839" transform="translate(11.244 43.104)" fill="#dedede"/>
      <rect id="Rectangle_4-2" data-name="Rectangle 4" width="57.159" height="9.37" transform="translate(11.244 72.152)" fill="#dedede"/>
      <rect id="Rectangle_5-2" data-name="Rectangle 5" width="113.85" height="2.811" transform="translate(11.244 124.157)" fill="#dedede"/>
      <rect id="Rectangle_6-2" data-name="Rectangle 6" width="113.85" height="2.811" transform="translate(11.244 136.807)" fill="#dedede"/>
      <rect id="Rectangle_7-2" data-name="Rectangle 7" width="113.85" height="2.343" transform="translate(11.244 149.457)" fill="#dedede"/>
    </g>
    <g id="Group_52" data-name="Group 52" transform="translate(581.997 647.953)">
      <path id="Path_34" data-name="Path 34" d="M9.3,2.045a2.068,2.068,0,0,0-3.6,0l-5.445,9.7a1.987,1.987,0,0,0,.018,1.993,2.04,2.04,0,0,0,1.78,1.013H12.946a2.04,2.04,0,0,0,1.78-1.013,1.987,1.987,0,0,0,.018-1.993ZM7.5,12.252a.625.625,0,1,1,.625-.625A.625.625,0,0,1,7.5,12.252Zm.625-3.125a.625.625,0,0,1-1.25,0V5.375a.625.625,0,0,1,1.25,0Z" transform="translate(0.001 -0.265)" fill="#d9d9d9"/>
      <path id="Subtraction_1" data-name="Subtraction 1" d="M7.688,13.614h0a2.043,2.043,0,0,0,.29-.385A1.991,1.991,0,0,0,8,11.236l-5.445-9.7A2.069,2.069,0,0,0,0,.632a2.068,2.068,0,0,1,3.286.414l5.445,9.7a1.986,1.986,0,0,1-.018,1.993A2.048,2.048,0,0,1,7.688,13.614ZM.753,11.742A.625.625,0,1,1,.875,10.5a.626.626,0,0,0,.491.737A.626.626,0,0,1,.753,11.742Zm0-2.5a.626.626,0,0,1-.625-.625V4.866a.626.626,0,0,1,.747-.613.629.629,0,0,0-.012.123V8.127a.626.626,0,0,0,.5.613A.626.626,0,0,1,.753,9.242Z" transform="translate(7.012 0)" fill="#f3f3f3"/>
    </g>
  </g>
</svg>
)

 

}

export default NoEventsFound


