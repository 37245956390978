import { Box, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import LineTo, { Line } from 'react-lineto';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import CustomInput from './CustomInput';

export default function Demo() {
  var list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18];
  var aryList = [];
  var temp = [];

  list.map((item, index) => {
    if (index > 0 && index % 6 === 0) {
      aryList.push(temp);
      temp = [];
      temp.push(item);
    } else {
      temp.push(item);
    }

    if (index === list.length - 1 && temp.length > 0) {
      aryList.push(temp);
    }
  });

  console.log(aryList);
  var direction = false;

  const com = aryList.map((parent, index) => {
    direction = !direction;
    return (
      <>
        <Box display='flex' flexDirection={direction ? 'row' : 'row-reverse'}>
          {parent.map((child, cIndex) => {
            return cIndex % 2 === 0 ? (
              <>
                <Box height={'50px'}>
                  <AcUnitIcon />
                </Box>
                {cIndex !== parent.length - 1 ? <Box className={direction ? 'downLine' : 'upLine'}></Box> : <></>}
              </>
            ) : (
              <>
                <Box display='flex' alignItems='flex-end' height={'50px'}>
                  <AcUnitIcon />
                </Box>
                {cIndex !== parent.length - 1 ? <Box className={direction ? 'upLine' : 'downLine'}></Box> : <></>}
              </>
            );
          })}
        </Box>

        {direction && aryList.length - 1 !== index ? <Box display='flex' height={'50px'} borderRight={'1px solid black'} marginRight={1}></Box> : ''}

        {!direction && aryList.length - 1 !== index ? <Box display='flex' height={'50px'} borderLeft={'1px solid black'} marginLeft={1}></Box> : ''}
      </>
    );
  });

  const [value, setvalue] = useState('');
  return (
    <div style={{ width: '50%' }}>
      <Box display='flex' p={1} m={1}>
        <CustomInput label='First name' helperText='Please enter first name' validation='alpha-numeric' value={value} handleChange={(value) => setvalue(value)} fullWidth />
      </Box>
      {/* {com} */}
      {/* <Box display='flex'>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
      </Box>
      <Box display='flex' height={'50px'} borderRight={'1px solid black'} marginRight={1}></Box>
      <Box display='flex'>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
      </Box>
      <Box display='flex' height={'50px'} borderLeft={'1px solid black'} marginLeft={1}></Box>
      <Box display='flex'>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
      </Box>
      <Box display='flex' height={'50px'} borderRight={'1px solid black'} marginRight={1}></Box>
      <Box display='flex'>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='downLine'></Box>
        <Box display='flex' alignItems='flex-end' height={'50px'}>
          <AcUnitIcon />
        </Box>
        <Box className='upLine'></Box>
        <Box height={'50px'}>
          <AcUnitIcon />
        </Box>
      </Box> */}
    </div>
  );
}
