import { Box, Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import { useTranslation } from "react-i18next";
import { ReactComponent as Refresh } from "../../assets/cloudaccess-svg/Refresh.svg";
import Breadcrumb from "../../components/Breadcrumb";
import Toolbar from "../../components/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import { getAntipassbacks, removeAntipassback } from "../../actions/AntipassbackApiCalls";
import EditIcon from "@material-ui/icons/Edit";
import { REACT_URL_ANTIPASSBACK } from "../../actions/EndPoints";
import Headerbar from "../../components/HeaderBar";
import FilterAntipassback from "./FilterAntipassback";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import ConfirmModal from "../../components/ConfirmModal";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { ReactComponent as Delete } from "../../assets/cloudaccess-svg/Delete.svg";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddAntipassback from "./AddAntipassback";
import SubmitButton from "../../components/SubmitButton";

export default function Antipassback() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [antipassbacks, setAntipassbacks] = useState([]);
  const [selectedAntipassbacks, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});
  // eslint-disable-next-line
  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: 5,
    deviceName: "",
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAntipassbacks(store.antipassback.antipassbacks.antipassbacks);
  }, [store.antipassback.antipassbacks]);

  const loadData = () => {
    dispatch(getAntipassbacks(query.deviceName, query.page, query.pageSize));
  };

  const deleteAntipassback = (data) => {
    if (data.length < 1) {
      toast.warning(t("ANTIPASSBACKI003"));
      return;
    }
    var ids = "";
    // eslint-disable-next-line
    data.map((antipassback, index) => {
      if (index === data.length - 1) ids = ids + antipassback.id;
      else ids = ids + antipassback.id + ",";
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t("ANTIPASSBACKI0015")}
            onConfirm={() => {
              dispatch(removeAntipassback(ids, loadData));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const breadcrumb = [
    { path: REACT_URL_ANTIPASSBACK, name: t("LEVEL001") },
    { path: REACT_URL_ANTIPASSBACK, name: t("Antipassback001") },
  ];

  const columns = [
    { field: "deviceName", title: t("DEVI0002"), editable: "never" },
    { field: "apbRule", title: "AntipassbackRule", editable: "never" },
  ];

  // eslint-disable-next-line
  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t("COMMON019"));
      return;
    }
    var ids = "";
    // eslint-disable-next-line
    data.map((record, index) => {
      if (index === data.length - 1) ids = ids + record.id;
      else ids = ids + record.id + ",";
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t("COMMON018")}
            onConfirm={() => {
              dispatch(removeAntipassback(ids));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const actions = [
    {
      icon: () => <EditIcon color="secondary" />,
      tooltip: t("COMMON005"),
      position: "row",
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <DeleteOutlineIcon color="secondary" />,
      tooltip: t("COMMON004"),
      position: "row",
      onClick: (event, rowData) => {
        deleteAntipassback([rowData]);
      },
    },
  ];

  //const [state, setState] = React.useState({
  const [state] = React.useState({
    id: "",
    antipassbackRule: "",
    deviceId: "",
  });

  const openAddAntipassbackDialog = () => {
    setUpdateObject(state);
    setOpenEditDialog(true);
  };

  // const onPageChange = (page) => {
  // 	setQuery({
  // 		...query,
  // 		page: page
  // 	})
  // 	loadData();
  // }

  // const onPageSizeChange = (pageSize) => {
  // 	setQuery({
  // 		...query,
  // 		page: 0,
  // 		pageSize: pageSize
  // 	})
  // 	loadData();
  // }

  return (
    <Grid>
      <Breadcrumb links={breadcrumb} />
      <Headerbar title={t("ANTIPASSBACKI004")}>
        <Box paddingRight={1}>
          <SubmitButton variant="contained" color="primary" size="small" onClick={() => openAddAntipassbackDialog()} startIcon={<AddCircleOutlineRoundedIcon />}>
            {t("COMMON009")}
          </SubmitButton>
        </Box>
      </Headerbar>
      <Toolbar>
        <Box p={1}>
          <Button
            onClick={() => deleteAntipassback(selectedAntipassbacks)}
            color="secondary"
            size="small"
            startIcon={<Delete className="delete-btn" />}
            className="acc-list-action-btn"
          >
            {t("COMMON004")}
          </Button>
        </Box>
        <Box p={1} flexGrow={1}>
          <Button onClick={() => loadData()} color="secondary" size="small" startIcon={<Refresh className="refresh-btn" />} className="acc-list-action-btn">
            {t("COMMON002")}
          </Button>
        </Box>

        <Box p={1}>
          <FilterAntipassback />
        </Box>
      </Toolbar>
      <DataTable columns={columns} data={antipassbacks} actions={actions} onSelectedRoles={(rows) => onSelectionChange(rows)} />

      {openEditDialog && (
        <AddAntipassback
          antipassback={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadData();
          }}
        />
      )}
    </Grid>
  );
}
