import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Box ,IconButton} from '@material-ui/core';
import { REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import projectLogo from '../../assets/images/ZKBio-cloud-access.svg';
import cloudAcc from '../../assets/images/cloud-ac.svg';

const zlink=localStorage.getItem('redirectFrom');
const HeaderLogo = () => {
  
  return (
    <Fragment>
        <span></span>
      <div className={clsx('app-header-logo', {})}>
          <Link to={REACT_URL_DASHBOARD} className="header-logo-wrapper-link">
                  <img className="header-logo"  alt='Cloud Access' src={!zlink?projectLogo:cloudAcc} />
          </Link>
      </div>
    </Fragment>
  );
};

export default HeaderLogo;
