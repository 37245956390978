import { Box } from '@material-ui/core';
import React from 'react';

export default function Headerbar(props) {
  return (
    <div style={{ width: '100%' }}>
      <Box p={0} display='flex' paddingTop={1} paddingLeft={3} bgcolor='background.paper'>
        <Box flexGrow={1}>
          <p>
            <b className='acc-head'>{props.title}</b>
          </p>
        </Box>
        {props.children}
      </Box>
    </div>
  );
}
