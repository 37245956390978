import React from 'react';
import { Grid } from '@material-ui/core';
import Breadcrumb from '../../components/Breadcrumb';
import { useTranslation } from 'react-i18next';
import DoorDetails from '../../pages/level/DoorDetails';
import PersonDetails from './PersonDetails';
import DepartmentDetails from './DepartmentDetails';
import { REACT_URL_LEVEL, REACT_URL_LEVEL_DETAILS } from '../../actions/EndPoints';
import { useLocation } from 'react-router-dom';

export default function LevelDetails() {
  const location = useLocation();
  const { t } = useTranslation();

  const breadcrumb = [
    { path: REACT_URL_LEVEL, name: t('LEVEL001') },
    { path: REACT_URL_LEVEL_DETAILS, name: t('LEVEL015') },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb} />
      <Grid container>
        <Grid item xs={12} style={{ paddingBottom: '16px' }}>
          <DoorDetails levelId={location.state.data.id} />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: '16px' }}>
          <PersonDetails levelId={location.state.data.id} />
        </Grid>
        <Grid item xs={12}>
          <DepartmentDetails levelId={location.state.data.id} />
        </Grid>
      </Grid>
    </Grid>
  );
}
