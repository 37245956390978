import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import biozlink from '../../assets/images/onboard/access-cloud.png';
import cloudAccess from '../../assets/images/onboard/cloudaccess.png';
import email from '../../assets/images/onboard/email_icon.png';
import './custom.css';
import { useHistory, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router';
import LoginLayout from './LoginLayout';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  label: {
    color: '#999',
    fontSize: '12px',
  },
  labelActive: {
    color: '#4C6A9F',
    fontSize: '12px',
  },
  email_icon: {
    height: '10%',
    width: '15%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
}));

function CommonResultPage(props) {
  const classes = useStyles();
 const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  return (
    <LoginLayout>
      <Grid container alignItems='center'>
        <Grid item xs>
          <Typography align='center'>
            <img src={email} alt='logo' className={classes.email_icon} />
          </Typography>
          {/* <Typography className='signin_title' color='textPrimary' align='center'>
              {location.state.success ? 'Success' : 'Error'}
            </Typography> */}
        </Grid>
      </Grid>

      <Grid container spacing={2} align='center'>
        <Grid item xs>
          <Typography variant='h5' className={classes.labelActive}>
            {location.state.message}
          </Typography>
        </Grid>
      </Grid>
      {location.state.redirectLogin ? (
        <Grid container alignItems='flex-end' justify='center'>
          <Grid item alignItems='flex-end' justify='center'>
            <Button color='primary' className='forgot_password' href='/acc/login' size={'small'}>
             {t('FPW005')}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </LoginLayout>
  );
}
export default withRouter(CommonResultPage);
