import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { I18nextProvider } from 'react-i18next';
import i18n from './components/Util/i18n';
import BrowserLanguage from './components/Util/BrowserLanguage';
import { toast } from 'react-toastify';
import { hideLoader } from './actions/LoaderActions';
import store from './config/configureStore';

serviceWorker.unregister();

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
      window.location = '/acc/login';
    }
    if (403 === error.response.status) {
      store.dispatch(hideLoader());
      toast.error(error.response.data.message);
    } else {
      store.dispatch(hideLoader());
      return Promise.reject(error);
    }
  }
);

function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

axios.interceptors.request.use(
  (config) => {
    const language = BrowserLanguage.getDefaultLanguage();
    //axios.defaults.headers.common['accept-Language'] = language;

    config.headers.common['time-zone'] = getTimeZone();

    //config.url = config.url+language;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App props={i18n} />
  </I18nextProvider>,
  document.getElementById('root')
);
