import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box,  Grid,  Typography,FormControl,InputLabel,MenuItem,Select} from '@mui/material';
import CancelIcon from '@material-ui/icons/Cancel';
import CommonUtil from '../../components/Util/CommonUtils';
import { getDevicesForAntipassback } from '../../actions/DeviceApiCalls';
import { addAntipassback, getAntipassbackRules, updateAntipassback,resetAntipassbackRule } from '../../actions/AntipassbackApiCalls';
import CancelButton from '../../components/CancelButton';
import SubmitButton from '../../components/SubmitButton'; 

export default function AddAntipassback(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const store = useSelector((state) => state);
    const [open, setOpen] = React.useState(props.open);
    // eslint-disable-next-line
    const [data, setData] = React.useState();
    const [devices, setDevices] = React.useState([]);
    const [antipassbacks, setAntipassbackRules] = React.useState([]);
    const [state, setState] = React.useState({
		id: "",
        deviceId: "",
        apbRule: ""
    })

    const [error, setError] = React.useState({
        id: false,
        deviceId: false,
        apbRule: false
    })

	useEffect(() => {
        loadData();
        dispatch(resetAntipassbackRule())
        // eslint-disable-next-line
    }, [])

	const loadData = () => {
		dispatch(getAntipassbackRules());
	}

    useEffect(() => {
        setOpen(props.open);
        setData(props.antipassback);
        setState({
            id: props.antipassback.id,
            deviceId: props.antipassback.deviceId,
            apbRule: props.antipassback.apbRule
        })
    }, [props.open, props.antipassback])

    useEffect(() => {
        dispatch(getDevicesForAntipassback('', "0", "1000"))
        // eslint-disable-next-line
    }, [])
		
	 useEffect(() => {
		 if(state.deviceId !== undefined && state.deviceId !=="" ){
			dispatch(getAntipassbackRules(state.deviceId))			
		}
		else{
			setAntipassbackRules([])
		}
        // eslint-disable-next-line
    }, [state.deviceId])

    useEffect(() => {
        setDevices(store.antipassback.antipassbackDevices)
    }, [store.antipassback.antipassbackDevices])

	useEffect(() => {
        setAntipassbackRules(store.antipassback.antipassbackDeviceRule)
    }, [store.antipassback.antipassbackDeviceRule])

    useEffect(() => {
        if (store.errors && !CommonUtil.isEmpty(store.errors.antipassbackError)) {
            const { antipassbackError } = store.errors;
            switch(antipassbackError.code){
                case "CASE0011" : setError({deviceId: true});break;
                case "CASE0201" : setError({apbRule: true});break;
                default:
            }
        }
        // eslint-disable-next-line
    }, [store.errors.antipassbackError])

    const handleClose = () => {
        props.onClose(false);
    };

	const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });

        setError({
            [name]: false
        })
    };
	
	
	
    const handleSubmit = () => {
        if(CommonUtil.isEmptyString(state.id)){
            dispatch(addAntipassback(state, (data) => {
                handleClose();
            }))
        } else {
            dispatch(updateAntipassback(state, (data) => {
                handleClose();
            }))
        }
    }
	

    return (
        <div>

            <Dialog maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className="custom-modal">
                <DialogTitle id="form-dialog-title">
                    <Box display="flex">
                        <Box flexGrow={1}>
                            {CommonUtil.isEmptyString(state.id) ? t("Antipassback002") : t("ANTIPASSBACK005")}
                        </Box>
                        <CancelIcon onClick={handleClose} style={{ color: "red", background: "white", borderRadius: "50%" }} fontSize="small" />
                    </Box>
                </DialogTitle>
                <DialogContent >
                    <form noValidate autoComplete="off">
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
							style={{minWidth:"300px"}}
                        >
                            <Grid item xs={12} >
                                <Box display="flex" justifyContent="center" m={1} p={1} style={{minWidth:"300px"}}>
									{CommonUtil.isEmptyString(state.id) &&
										<FormControl variant="outlined" size="small" fullWidth>
                                        <InputLabel>{t("DEVI0002")}</InputLabel>
                                        <Select
											disabled={!CommonUtil.isEmptyString(state.id)}
                                            label={t("DEVI0002")}
                                            error={error.deviceId}
                                            onChange={handleChange}
                                            value={state.deviceId}
                                            defaultValue={state.deviceId}
                                        >
                                            <MenuItem value="" selected>
                                                <em>None</em>
                                            </MenuItem>
                                            {
                                                devices ? devices.map((data, index) =>
                                                    <MenuItem key={index} value={data.id}  >
                                                        <Typography variant="inherit" noWrap>{data.deviceName}</Typography>
                                                    </MenuItem>
                                                ) : <></>
                                            }

                                        </Select>
                                    </FormControl>
									} 
                                    
								{!CommonUtil.isEmptyString(state.id) &&
									<TextField
										disabled
                                        fullWidth
                                        name="deviceName"
                                        label={t("COMMON014")}
                                        helperText=""
                                        variant="outlined"
                                        size="small"
                                        defaultValue={props.antipassback.deviceName}
                                        onChange={handleChange}
                                    />
									} 
                                </Box>

                            </Grid>
                            <Grid item xs={12} >
                                <Box display="flex" justifyContent="center" m={1} p={1}>

                                    <FormControl variant="outlined" size="small" fullWidth>
                                        <InputLabel>{t("AntipassbackRule")}</InputLabel>
                                        <Select
                                            label={t("AntipassbackRule")}
                                            error={error.AntipassbackRule}
                                            onChange={handleChange}
                                            value={state.apbRule}
                                            defaultValue={state.apbRule}
                                        >
                                            <MenuItem value="" selected>
                                                <em>None</em>
                                            </MenuItem>
                                            {
                                                antipassbacks ? antipassbacks.map((data, index) =>
                                                    <MenuItem key={index} value={data.value}  >
                                                        <Typography variant="inherit" noWrap>{data.text}</Typography>
                                                    </MenuItem>
                                                ) : <></>
                                            }

                                        </Select>
                                    </FormControl>

                                </Box>

                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <SubmitButton onClick={handleSubmit} color="primary" variant="contained" size="small">
                        {t("COMMON035")}
                    </SubmitButton>
                    <CancelButton onClick={handleClose} color="primary" variant="contained" size="small">
                        {t("COMMON008")}
                    </CancelButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
