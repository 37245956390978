import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import CommonUtil from '../../components/Util/CommonUtils';
import { getTimesegs } from '../../actions/TimesegApiCalls';
import { addLevel, updateLevel } from '../../actions/LevelApiCalls';
import CancelButton from '../../components/CancelButton';
import SubmitButton from '../../components/SubmitButton';
import { JAVA_URL_SITE_LIST } from '../../actions/EndPoints';
import { Box, Grid, Typography } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';
import { employeeList } from '../../actions/EmployeeApiCalls';
import { Autocomplete } from '@mui/material';
import axios from 'axios';

export default function SetDevicePersonRole(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [open, setOpen] = React.useState(props.open);
  // eslint-disable-next-line
  const [employees, setEmployees] = React.useState();
  const [timesegs, setTimesegs] = React.useState([]);
  const [sites, setSites] = React.useState([]);
  const [selectedPersons, setSelectedPersons] = React.useState([]);

  const [state, setState] = React.useState({
    role: '',
    personId: '',
  });

  const [error, setError] = React.useState({
    role: false,
    personId: false,
  });

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (open) loadPersons();
  }, [open]);

  const loadPersons = () => {
    dispatch(
      employeeList((data) => {
        setEmployees(data.data.employee);
      })
    );
  };

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };
  const isValid = (data) => {
    return true;
  };
  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.role)) {
      setError({
        role: true,
      });
      toast.error('Role is required');
      return;
    }
    if (selectedPersons.length < 1) {
      setError({
        personId: true,
      });
      toast.error('Select at least one person');
      return;
    }

    let personIds = [];
    selectedPersons.map((e) => {
      personIds.push(e.id);
    });

    const payload = {
      deviceId: props.data.id,

      sn: props.data.sn,

      personIds: personIds,

      deviceRole: state.role,
    };

    axios.post('/api/v2.0/device/setDeviceRole', payload).then((response) => {
      if (response.data.code === 'CASI7035') {
        toast.success('Person role added successfully');
        handleClose();
      }
      if(response.data.code==='CASW0005'){
        toast.error(response.data.message);
      }
    });
  };

  return (
    <div>
      <Dialog maxWidth='sm' fullWidth open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('DEVI0075')}</Box>
            <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <Autocomplete
                    multiple
                    clearText={t('COMMON216')}
                    openText={t('COMMON169')}
                    closeText={t('COMMON170')}
                    noOptionsText={t('COMMON214')}
                    fullWidth
                    name='country'
                    options={employees ? employees : []}
                    getOptionLabel={(option) => option.firstName + ' - ' + option.employeeCode}
                    onChange={(event, newValue) => {
                      setSelectedPersons(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='personId'
                        className='login_input'
                        variant='outlined'
                        fullWidth
                        label={t('Select persons') + ' *'}
                        error={error.personId}
                        size='small'
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('Role') + ' *'}</InputLabel>
                    <Select
                      labelId='demo-simple-select-outlined-label'
                      id='demo-simple-select-outlined'
                      label={t('Role') + ' *'}
                      error={error.role}
                      name='role'
                      onChange={handleChange}
                      value={state.role}
                      defaultValue={state.role}
                    >
                      <MenuItem value='14' selected>
                        <em>{t('Administrator')}</em>
                      </MenuItem>
                      <MenuItem value='2' selected>
                        <em>{t('Enroller')}</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
