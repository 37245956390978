import { Box, Button, Grid } from '@material-ui/core';
import React, { Fragment, forwardRef } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import { TablePagination } from '@material-ui/core';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import FilterListIcon from '@material-ui/icons/FilterList';
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { ReactComponent as Delete } from "../../assets/cloudaccess-svg/Delete.svg";
import { getLinkageList, deleteLinkage, getNorulesForDevId } from '../../actions/LinkageListActions';
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
const styles = theme => ({
	form: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: 250
		}
	},
	formcontrol: {
		width: 150
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	}
});
const DialogTitle = withStyles(styles)(props => {
	const { children, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className="dilog-filter-head" {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className="dilog-form-closeButton" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});
class LinkageList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			interLocks: [],
			linkageList: [],
			selectedIds: [],
			filter: false,
			name: '',
			doorName: '',
			query: {
				page: 0,
				pageSize: 5,
			},
		}
		//this.getLinkageList = this.getLinkageList.bind(this);
		this.deleteSelectedIds = this.deleteSelectedIds.bind(this);
	}
	filterOpenDialog = () => {
		this.setState({ filter: true })
	}

	filterClose = () => {
		const { query } = this.state;
		let name = '';
		let doorName = '';
		this.props.getLinkageList(name, doorName, query, this.state.history);
		this.setState({
			filter: false, name: '',
			doorName: '',
		})
	}

	filterApply = () => {
		const { query } = this.state;
		this.props.getLinkageList(this.state.name, this.state.doorName, query, this.state.history)
			.then(() => {
				this.setState({ linkageList: this.props.linkageList });
			})
		this.setState({
			filter: false,
			name: '',
			doorName: '',
		});
	}
	componentDidMount() {
		this.fetchData();
	}
	componentDidUpdate(prevProps) {
		if (this.props.linkageList !== undefined) {
			if (this.props.linkageList !== prevProps.linkageList) {
				this.setState({ linkageList: this.props.linkageList.item })
			}
		}
		if (this.props.linkageList !== undefined) {
			if (this.props.linkageList !== prevProps.linkageList) {
				if (this.props.linkageList.totalCount !== undefined) {
					this.setState({ totalCount: this.props.linkageList.totalCount });
				} else {
					this.setState({ totalCount: 0 });
				} 
			}
		}
	}
	fetchData = (additionalState) => {
		const { query } = this.state;
		this.props.getLinkageList(this.state.name, this.state.doorName, query, this.state.history).then(() => {
			this.setState({ ...additionalState });
		})
	}
	deleteMultipleRows = () => { 
		let ids = ''; 
		this.state.selectedIds.map((row) => (ids += row.id + ','))
		var idsArr = ids.split(',');
		idsArr.pop(); 
		this.props.deleteLinkage(idsArr);
		this.setState({ selectedIds: [] })
	}

	deleteSelectedIds(oldData) {
		this.props.deleteLinkage(oldData.id);
	} 
	onChange=(e)=> { 
		this.setState({ [e.target.name]: e.target.value }); 
	  }
	render() {

		const { t } = this.props;
		const tableIcons = {
			Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
			Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
			DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
			Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
			Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
			FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
			LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
			NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
			ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
			SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
			ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
			ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
		};
		const { totalCount, query, pageChanged } = this.state;
		const linkageColumns = [
			{ field: 'name', title: t("tableHeaderLinkageName") },
			{ field: 'deviceName', title: t("tableHeaderCommonDeviceName") },

		];
 
		// const linkageData = [
		// 	{ linkageName: 'linkage1', deviceName: 'device1', linkageTriggerConditions: 'Condition1' },
		// 	{ linkageName: 'linkage2', deviceName: 'device2', linkageTriggerConditions: 'Condition2' },
		// 	{ linkageName: 'linkage3', deviceName: 'device3', linkageTriggerConditions: 'Condition3' },
		// 	{ linkageName: 'linkage4', deviceName: 'device4', linkageTriggerConditions: 'Condition4' },
		// 	{ linkageName: 'linkage5', deviceName: 'device5', linkageTriggerConditions: 'Condition5' },
		// ];

		return (
			<Fragment>
				<div>
					<div style={{ width: '100%' }}>
						<Dialog open={this.state.filter} onClose={this.filterClose} aria-labelledby="form-dialog-title" fullWidth={true}
							maxWidth={'xs'} >


							<DialogTitle id="form-dialog-title">Filter</DialogTitle>
							<DialogContent>
								<Grid item xs={12}>
									<TextField className="text-fields"
										name="name"
										onChange={this.onChange}
										label={"Linkage Name"} type="text"
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField className="text-fields"
										name="doorName"
										onChange={this.onChange}
										label={"Device Name"} type="text"
									/>
								</Grid>

							</DialogContent>
							<DialogActions>
								<Button onClick={this.filterApply} color="primary">
									Apply
						</Button>
								<Button onClick={this.filterClose} color="primary">
									Reset
						</Button>
							</DialogActions>
						</Dialog>
						<Box display="flex" p={1} bgcolor="background.paper">
							<Box p={1} flexGrow={1}>
								<p><b className="acc-head">{"Linkage"}</b></p>
							</Box>
							{/* <Box p={1}>
								<Paper className="search-root">
									<InputBase
										className="search-input"
										placeholder={t("commonsTableToolBarSearch")}
										inputProps={{ "aria-label": "search" }}
										ref={this.input}
										type="text" name="search" onChange={this.handleInputChange} checked={this.state.isChecked} />
									<IconButton
										type="submit"
										className="search-iconButton"
										aria-label="search">
										<SearchIcon />
									</IconButton>
								</Paper>
							</Box> */}
							<Box p={1}>
								<Button
									variant="contained"
									color="primary"
									size="small"
									startIcon={<FilterListIcon />}
									onClick={this.filterOpenDialog}
								>
									Filter
							</Button>
							</Box>
						</Box>
						<Box display="flex" p={1} bgcolor="background.paper">
							<Box p={1}>
								{/* <Button
									color="secondary"
									size="small"
									startIcon={<Refresh className="refresh-btn" />}
									className="acc-list-action-btn">
									{t("buttonCommonRefresh")}
								</Button> */}
							</Box>
							<Box p={1} flexGrow={1}>
								<Button
									color="secondary"
									size="small"
									startIcon={<Delete className="delete-btn" />}
									className="acc-list-action-btn"
									onClick={this.deleteMultipleRows}
								>
									{t("buttonCommonDelete")}
								</Button>
							</Box>
							<Box p={1}>
								<Box p={1}>
									<Button
										variant="contained"
										color="primary"
										size="small"
										component={Link}
										to={{
											pathname: "/acc/addlinkage"
										}}
										startIcon={<AddCircleOutlineRoundedIcon />}
									>
										{t('AddLinkage')}
									</Button>
								</Box>
							</Box>
						</Box>
					</div>
					<MaterialTable
						columns={linkageColumns}
						data={this.state.linkageList?this.state.linkageList : []}
						icons={tableIcons}
						options={{
							minBodyHeight: '100%',
							actionsColumnIndex: -1,
							addRowPosition: 'first',
							showTitle: false,
							toolbarButtonAlignment: "left",
							toolbar: false,
							selection: true,
							pageSize: 5
						}}
						editable={{
							// onRowUpdate: (newData, oldData) =>
							// 	new Promise((resolve) => {
							// 		setTimeout(() => {

							// 			resolve();
							// 		}, 1000)
							// 	}),
							onRowDelete: oldData =>
								new Promise((resolve) => {
									setTimeout(() => {
										this.deleteSelectedIds(oldData);
										resolve()
									}, 1000)
								}),
						}}
						onSelectionChange={(rows) => this.setState({ selectedIds: rows })}
						actions={[
							{

								icon: () =>
									<IconButton aria-label="vehicles" component={Link} to={"/acc/editLinkage"}>
										<EditIcon color="secondary" />
									</IconButton>,
								tooltip: "Edit device",
								position: 'row',
								onClick: (event, rowData) => {
									//this.handleChangeData(event, rowData);

								}

							}

						]}
						localization={{
							pagination: {
								// eslint-disable-next-line 
								labelDisplayedRows: '{from}-{to}' + "  " + t("commonsTablePaginationOf") + "  " + '{count}',
								labelRowsSelect: t("commonsTablePaginationRow"),
								firstAriaLabel: t("firstPage"),
								firstTooltip: t("commonsTablePaginationFirstPage"),
								previousAriaLabel: t("previousPage"),
								previousTooltip: t("commonsTablePaginationPreviousPage"),
								nextAriaLabel: t("nextPage"),
								nextTooltip: t("commonsTablePaginationNextPage"),
								lastAriaLabel: t("lastPage"),
								lastTooltip: t("commonsTablePaginationLastPage"),
							},

							header: {
								actions: t("buttonCommonActions")
							},
							body: {
								emptyDataSourceMessage: t("tableCommonNoRecordDisplay"),
								editTooltip: t("commonsTableToolTipEdit"),
								deleteTooltip: t("commonsTableToolDelete"),
								filterRow: {
									filterTooltip: 'Filter'
								}
							},
							toolbar: {
								searchPlaceholder: t("commonsTableToolBarSearch"),
								searchTooltip: t("commonsTableToolBarSearch")
							}
						}}

						components={{
							Pagination: (props) => (
							  <TablePagination {...props} count={totalCount} page={query.page} rowsPerPage={query.pageSize} />
							),
						  }}
						  onPageChange={(page) => {
							if (pageChanged) {
							  this.setState(
								{ query: { ...query, page: page }, pageChanged: false }, () => this.fetchData({ pageChanged: true })
							  );
							}
						  }}
						   
						  onRowsPerPageChange={(pageSize) => {
							this.setState({ query: { ...query, pageSize } }, this.fetchData);
						  }}
					/>
				</div>
			</Fragment>
		);
	}
}


const mapStateToProps = (state) => ({
	linkageList: state.LinkageList.linkageList
});


export default compose(withTranslation(), connect(mapStateToProps, { getLinkageList, deleteLinkage, getNorulesForDevId }))(LinkageList);