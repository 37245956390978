import axios from 'axios';
import { JAVA_API_CREATE_ZONE_V3, JAVA_API_DELETE_ZONE_V3, JAVA_API_UPDATE_ZONE_V3, JAVA_API_ZONE, JAVA_API_ZONE_SEARCH, JAVA_API_ZONE_V3 } from './ApiEndPoints';
import { ZONE_LIST } from './types';

export const getZones = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_ZONE_V3 + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize);
    if (res.status === 200 && res.data.code === 'LMSI6000') {
      if (isPageable) {
        dispatch({
          type: ZONE_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: ZONE_LIST,
          payload: [],
        });
      }
    }
  } catch {}
};

export const createZone = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === '' ? true : false;
    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_CREATE_ZONE_V3, payload);
    } else {
      res = await axios.put(JAVA_API_UPDATE_ZONE_V3 + payload.id + "/update", payload);
    }
    callBack(res.data);
  } catch{}
}

// export const updateZone = (payload, callBack) => async (dispatch) => {
//   try {
//     let res = await axios.put(JAVA_API_UPDATE_ZONE_V3 + payload.id + "/update", payload);
//     callBack(res.data);
//   } catch {}
// };

export const deleteZone = (ids, callBack) => async (dispatch) => {
  try {
    const deleteObj = {};
    deleteObj.ids = ids;

    const res = await axios.delete(JAVA_API_DELETE_ZONE_V3 + ids + "/delete");
    callBack(res.data);
  } catch {}
};

export const filterZone = (name, parentId, page, size) => async (dispatch) => {
  try {
    let query = '';
    if(name !== '') {
      query += "&name=" + name; 
    }
    if(parentId !== '') {
      query += "&parentId=" + parentId;
    }
    const res = await axios.get(JAVA_API_ZONE_V3 + '?pageNumber=' + page + '&pageSize=' + size + query);
    console.log(res.data);
    if (res.status === 200 && res.data.code === 'LMSI6000') {
      dispatch({
        type: ZONE_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: ZONE_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const loadZoneBySite = (siteId, callback) => async (dispatch) => {
  try {
    const query = '&siteId=' + siteId;
    const res = await axios.get(JAVA_API_ZONE_V3 + '?pageNumber=' + 1 + '&pageSize=' + 50 + query);
    if (res.status === 200 && res.data.code === 'LMSI6000') {
      console.log(res.data.data);
      callback(res.data.data);
    }
  } catch {}
};
