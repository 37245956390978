import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch} from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { getInterlocks } from '../../actions/InterlockApiCalls';
import FilterListIcon from '@material-ui/icons/FilterList';
import { toast } from 'react-toastify';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';

export default function FilterInterlock() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //const store = useSelector((state) => state);
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({
        page: 0,
        pageSize: 5,
        deviceName: ''
    })

    const handleClose = () => {
        setState({
            deviceName: ''
        })
        setOpen(false);
    };

    const reset = () => {
        if (state.deviceName.trim() === '') {
            toast.error(t("COMMON027"))
            return;
        }
        setState({
            deviceName: ''
        })
    };

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    const loadDoors = () => {
        dispatch(getInterlocks(state.deviceName, state.page, state.pageSize));
    }

    const handleSubmit = () => {
        if (state.deviceName.trim() === '') {
            toast.error(t("COMMON015"))
            return;
        }
        loadDoors();
        setState({
            deviceName: ''
        })
        handleClose();
    }


    return (
        <div>
            <Button
                color="secondary"
                className="acc-list-action-btn"
                size="small"
                startIcon={<FilterListIcon />}
                onClick={() => setOpen(true)}
            >
                {t("COMMON006")}
            </Button>

            <Dialog maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className="custom-filter-modal">
                <DialogTitle id="form-dialog-title">
                    <Box display="flex">
                        <Box flexGrow={1}>
                            {t("COMMON006")}
                        </Box>
                        <CancelIcon onClick={handleClose} style={{ color: "red", background: "white", borderRadius: "50%" }} fontSize="small" />
                    </Box>
                </DialogTitle>
                <DialogContent >
                    <form noValidate autoComplete="off">
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} >
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        fullWidth
                                        name="deviceName"
                                        label={t("DEVI0002")}
                                        helperText=""
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        value={state.deviceName}
                                    />
                                </Box>

                            </Grid>

                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <SubmitButton onClick={handleSubmit} variant="contained" size="small">
                        {t("COMMON024")}
                    </SubmitButton>
                    <CancelButton onClick={reset} variant="contained" size="small">
                        {t("COMMON025")}
                    </CancelButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
