import React, { Fragment, forwardRef } from 'react';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Search from '@material-ui/icons/Search';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { getNotificationData } from '../../actions/DashBoardActions';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import CommonUtil from '../../components/Util/CommonUtils';

class NotificationlTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allCheck: true,
      wornCheck: false,
      criticalCheck: false,
      normalCheck: false,
      eventTypeData: [],
      transaction: [],
      transactions: [
        { companyName: 'ZKTeco', siteName: 'Site A', deviceName: 'First Floor Door', eventType: 'Remote Open', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site B', deviceName: 'Second Floor Door', eventType: 'Anti-Passback', time: '17:48:26', location: 'Bangalore' },
      ],
      notificationlData: [
        { companyName: 'ZKTeco', siteName: 'Site A', deviceName: 'First Floor Door', eventType: 'normal', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site B', deviceName: 'Second Floor Door', eventType: 'normal', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site C', deviceName: 'Third Floor Door', eventType: 'worning', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site D', deviceName: 'Fourth Floor Door', eventType: 'critical', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site E', deviceName: 'First Floor Door', eventType: 'Remote Open', time: '17:48:26', location: 'Bangalore' },
      ],

      notificationlData1: [
        { companyName: 'ZKTeco', siteName: 'Site F', deviceName: 'First Floor Door', eventType: 'Remote Open', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site G', deviceName: 'Second Floor Door', eventType: 'Anti-Passback', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site H', deviceName: 'Third Floor Door', eventType: 'Remote Open', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site I', deviceName: 'Fourth Floor Door', eventType: 'Anti-Passback', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site J', deviceName: 'First Floor Door', eventType: 'Remote Open', time: '17:48:26', location: 'Bangalore' },
      ],
      notificationlData2: [
        { companyName: 'ZKTeco', siteName: 'Site K', deviceName: 'First Floor Door', eventType: 'Remote Open', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site L', deviceName: 'Second Floor Door', eventType: 'Anti-Passback', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site M', deviceName: 'Third Floor Door', eventType: 'Remote Open', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site N', deviceName: 'Fourth Floor Door', eventType: 'Anti-Passback', time: '17:48:26', location: 'Bangalore' },
        { companyName: 'ZKTeco', siteName: 'Site O', deviceName: 'First Floor Door', eventType: 'Remote Open', time: '17:48:26', location: 'Bangalore' },
      ],
    };
  }
  
  componentDidMount() {
    this.props.getNotificationData();
    this.setState({ eventTypeData: this.props.notificationList }, () => {
      this.allEventchange();
    });
    // this.allEventchange();
  }

  componentDidUpdate(prevProps) {
    if (this.props.notificationList !== prevProps.notificationList) {
      this.setState({ eventTypeData: this.props.notificationList }, () => {
        this.allEventchange();
      });
    }
  }
  normalEventchange = () => {
    var normalEventTypeData = [];
    const { t } = this.props;
    // eslint-disable-next-line
    if (!CommonUtil.isEmpty(this.state.eventTypeData)) {
      this.state.eventTypeData.map((x) => {
        if (x.status === t('COMMON467')) {
          normalEventTypeData.push(x);
        }
      });
    }
    this.setState({ transaction: normalEventTypeData, normalCheck: true, wornCheck: false, criticalCheck: false, allCheck: false });
  };
  criticalEventchange = () => {
    const { t } = this.props;
    var criticalEventTypeData = [];
    // eslint-disable-next-line
    if (!CommonUtil.isEmpty(this.state.eventTypeData)) {
      this.state.eventTypeData.map((x) => {
        if (x.status === t('COMMON466')) {
          criticalEventTypeData.push(x);
        }
      });
    }
    this.setState({ transaction: criticalEventTypeData, criticalCheck: true, wornCheck: false, normalCheck: false, allCheck: false });
  };

  worningEventchange = () => {
    var worningEventTypeData = [];
    const { t } = this.props;
    // eslint-disable-next-line
    if (!CommonUtil.isEmpty(this.state.eventTypeData)) {
      this.state.eventTypeData.map((x) => {
        if (x.status === t('COMMON465')) {
          worningEventTypeData.push(x);
        }
      });
    }
    this.setState({ transaction: worningEventTypeData, wornCheck: true, criticalCheck: false, normalCheck: false, allCheck: false });
  };

  allEventchange = () => {
    //var allEventTypeData=[];

    // this.state.eventTypeData.map(x => {

    // 		allEventTypeData.push(x)

    // })
    this.setState({ transaction: this.props.notificationList, allCheck: true, wornCheck: false, criticalCheck: false, normalCheck: false });
  };

  render() {
    //alert(JSON.stringify(this.state.transaction))

    const tableIcons = {
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />),
    };
    const { t } = this.props;

    const notificationsColumns = [
      { field: 'eventPointName', title: t('COMMON073'), tooltip: t('OPE011'), editable: 'never' },

      { field: 'eventName', title: t('tableHeaderEventName') },

      { field: 'pin', title: t('tableHeaderPersonnelId'), editable: 'never' },

      { field: 'status', title: t('COMMON078') },

      { field: 'eventTime', title: t('OPE021'), editable: 'never', render: (rowData) => <>{CommonUtil.getLocalDate1(rowData.eventTime)}</> },
    ];

    // const notificationlData = [
    // 	{ companyName: 'ZKTeco', siteName: 'Site A', deviceName: 'First Floor Door', eventType: 'Remote Open', time: '17:48:26', location: 'Bangalore' },
    // 	{ companyName: 'ZKTeco', siteName: 'Site B', deviceName: 'Second Floor Door', eventType: 'Anti-Passback', time: '17:48:26', location: 'Bangalore' },
    // 	{ companyName: 'ZKTeco', siteName: 'Site C', deviceName: 'Third Floor Door', eventType: 'Remote Open', time: '17:48:26', location: 'Bangalore' },
    // ];

    // const BlueTextTypography = withStyles({
    // 	root: {
    // 		color: "blue"
    // 	}
    // })(Typography);

    return (
      <Fragment>
        <Box p={1} flexGrow={1} color='#3d4977' fontWeight='700' style={{ padding: '18px 0px 0px 18px' }}>
          {t('COMMON080')}
        </Box>
        <div style={{ width: '100%' }}>
          <Box display='flex' p={1} bgcolor='background.paper' flexDirection='row'>
            <Box p={1} alignContent='flex-end' style={{ color: '#808000' }}>
              <Checkbox checked={this.state.wornCheck} onChange={this.worningEventchange}></Checkbox>
              {t('COMMON085')}
            </Box>
            <Box p={1} alignContent='flex-end' style={{ color: 'red' }}>
              <Checkbox checked={this.state.criticalCheck} onChange={this.criticalEventchange}></Checkbox>
              {t('COMMON086')}
            </Box>
            <Box p={1} alignContent='flex-end' style={{ color: 'green' }}>
              <Checkbox checked={this.state.normalCheck} onChange={this.normalEventchange}></Checkbox>
              {t('COMMON087')}
            </Box>
            <Box p={1} alignContent='flex-end' style={{ color: 'black' }}>
              <Checkbox checked={this.state.allCheck} onChange={this.allEventchange}></Checkbox>
              {t('COMMON088')}
            </Box>
          </Box>
        </div>
        <MaterialTable
          columns={notificationsColumns}
          data={this.state.transaction}
          icons={tableIcons}
          options={{
            minBodyHeight: '100%',
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            showTitle: false,
            toolbar: false,
            search: false,
            //selection: true,
            sorting: true,
            pageSize: 5,
            headerStyle: {
              position: 'sticky',
              top: 0,
              height: 20,
              maxHeight: 20,
              width: 10,
              maxWidth: 10,
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t('dataTableEmptyList'),
            },
            pagination: {
              labelDisplayedRows: '{from}-{to} ' + t('dataTableOf') + ' {count}',
              labelRowsSelect: t('dataTableRows'),
              firstAriaLabel: t('dataTableFirstPage'),
              firstTooltip: t('dataTableFirstPage'),
              previousAriaLabel: t('dataTablePreviousPage'),
              previousTooltip: t('dataTablePreviousPage'),
              nextAriaLabel: t('dataTableNextPage'),
              nextTooltip: t('dataTableNextPage'),
              lastAriaLabel: t('dataTableLastPage'),
              lastTooltip: t('dataTableLastPage'),
            },
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  notificationList: state.dashBoard.notificationList,
});
export default compose(withTranslation(), connect(mapStateToProps, { getNotificationData }))(NotificationlTable);

//export default compose(withTranslation())(NotificationlTable);
