import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Grid, Button, Menu, Typography } from "@mui/material";
import CloseIcon from "@material-ui/icons/Cancel";
import { useTranslation } from "react-i18next";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.8em",
      backgroundColor: "lightgray",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#ffff",
      outline: "2px solid slategrey",
    },
    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#ffff",
    },
  },
}));

export default function RightSlidebar(props) {
  const [contextMenu] = React.useState(true);
  const [opne, setopen] = React.useState(props.open);



  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const style = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handletoggle = (event) => {
    setopen(false);
    props.close(event, props.AggrementType);
  
  };
  const muiSlideBarMenuItem = {
    ".MuiPopover-paper": {
      right: "14px",
      top: "52px",
      overflow: "initial",
      boxShadow:
        "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset !important",
    },
  };

  
  return (
    <Menu
      sx={muiSlideBarMenuItem}
      open={opne}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          maxWidth: "400px",
        }}
      >
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          margin="12px"
        >
          <Typography variant="h6" style={{ color: "black" }}>
            
            {props.AggrementType === 'User Agreement' ? t('COMMON227') : t('COMMON228')}
          </Typography>

          <CloseIcon
            size="small"
            style={{ color: "#194084" }}
            onClick={() => handletoggle("close")}
          />
        </Grid>
        <Grid
          sx={{
            height: `calc(${windowDimensions.height + "px"} - 265px)`,
            overflow: "scroll",
            padding: "8px",
            overflowX: "hidden",
          }}
        >
          {props.AggrementType === 'User Agreement' ? (
            <Grid>
              <Typography>{t("COMMON231")}</Typography>
              <Typography>{t("COMMON232")}</Typography>
              <Typography>{t("COMMON233")}</Typography>
              <Typography>{t("COMMON234")}</Typography>
              <Typography>{t("COMMON235")}</Typography>
              <Typography>{t("COMMON236")}</Typography>
              <Typography>{t("COMMON237")}</Typography>
              <Typography>{t("COMMON238")}</Typography>
              <Typography>{t("COMMON239")}</Typography>
              <Typography>{t("COMMON240")}</Typography>
              <Typography>{t("COMMON241")}</Typography>
              <Typography>{t("COMMON242")}</Typography>
              <Typography>{t("COMMON243")} </Typography>
              <Typography>{t("COMMON244")}</Typography>
              <Typography>{t("COMMON245")}</Typography>
              <Typography>{t("COMMON246")}</Typography>
              <Typography>{t("COMMON247")}</Typography>
              <Typography>{t("COMMON248")}</Typography>
              <Typography>{t("COMMON250")}</Typography>
              <Typography>{t("COMMON251")}</Typography>
              <Typography>{t("COMMON252")}</Typography>
              <Typography>{t("COMMON253")}</Typography>
              <Typography>{t("COMMON254")}</Typography>
              <Typography>{t("COMMON255")}</Typography>
              <Typography>{t("COMMON256")}</Typography>
              <Typography>{t("COMMON257")}</Typography>
              <Typography>{t("COMMON258")}</Typography>
              <Typography>{t("COMMON259")}</Typography>
              <Typography>{t("COMMON260")}</Typography>
              <Typography>{t("COMMON261")}</Typography>
              {t("COMMON262")}
              <Typography>{t("COMMON263")}</Typography>
              <Typography>{t("COMMON264")}</Typography>
              <Typography>{t("COMMON265")}</Typography>
              <Typography>{t("COMMON266")}</Typography>
              <Typography>{t("COMMON267")}</Typography>
              <Typography>{t("COMMON268")}</Typography>
              <Typography>{t("COMMON269")}</Typography>
              <Typography>{t("COMMON270")}.</Typography>
              <Typography>{t("COMMON271")}.</Typography>
              <Typography>{t("COMMON272")}</Typography>
              <Typography>{t("COMMON273")}</Typography>
              <Typography>{t("COMMON274")}</Typography>
              <Typography>{t("COMMON275")}</Typography>
              <Typography>{t("COMMON276")}</Typography>
              <Typography>{t("COMMON277")}</Typography>
              <Typography>{t("COMMON278")}</Typography>
              <Typography>{t("COMMON279")}</Typography>
              <Typography>{t("COMMON280")}</Typography>
              <Typography>{t("COMMON281")}</Typography>
              <Typography>{t("COMMON282")}</Typography>
              <Typography>{t("COMMON283")}</Typography>
              <Typography>{t("COMMON289")}</Typography>
              <Typography>{t("COMMON290")}</Typography>
              <Typography>{t("COMMON291")}</Typography>
              <Typography>{t("COMMON292")}</Typography>
              <Typography>{t("COMMON293")}</Typography>
              <Typography>{t("COMMON294")}</Typography>
              <Typography>{t("COMMON295")}</Typography>
              <Typography>{t("COMMON296")}</Typography>
              <Typography>{t("COMMON297")}</Typography>
              <Typography>{t("COMMON298")}</Typography>
              <Typography>{t("COMMON299")}</Typography>
              <Typography>{t("COMMON300")}</Typography>
              <Typography>{t("COMMON301")}</Typography>
              <Typography>{t("COMMON302")}</Typography>
              <Typography>{t("COMMON303")}</Typography>
              <Typography>{t("COMMON304")}</Typography>
              <Typography>{t("COMMON305")}</Typography>
              <Typography>{t("COMMON306")}</Typography>
              <Typography>{t("COMMON307")}</Typography>
              <Typography>{t("COMMON308")}</Typography>
              <Typography>{t("COMMON309")}</Typography>
              <Typography>{t("COMMON310")}</Typography>
              {t("COMMON311")}
              <Typography>{t("COMMON312")}</Typography>
              <Typography>{t("COMMON313")}</Typography>
              <Typography>{t("COMMON314")}</Typography>
              <Typography>{t("COMMON315")}</Typography>
              <Typography>{t("COMMON316")}</Typography>
              <Typography>{t("COMMON317")}</Typography>
              <Typography>{t("COMMON318")}</Typography>
              <Typography>{t("COMMON319")}</Typography>
              <Typography>{t("COMMON320")}</Typography>
              <Typography>{t("COMMON321")}</Typography>
              <Typography>{t("COMMON322")}</Typography>
              <Typography>{t("COMMON323")}</Typography>
              <Typography>{t("COMMON324")}</Typography>
              <Typography>{t("COMMON325")}.</Typography>
              <Typography>{t("COMMON326")}</Typography>
              <Typography>{t("COMMON327")}</Typography>
              <Typography>{t("COMMON328")}</Typography>
              <Typography>{t("COMMON329")}</Typography>
              <Typography>{t("COMMON330")}</Typography>
              <Typography>{t("COMMON331")}</Typography>
              <Typography>{t("COMMON332")}</Typography>
              <Typography>{t("COMMON333")}</Typography>
              <Typography>{t("COMMON334")}</Typography>
              {t("COMMON335")}
              <Typography> {t("COMMON336")}</Typography>
              <Typography>{t("COMMON337")}</Typography>
              <Typography>{t("COMMON338")}</Typography>
              <Typography>{t("COMMON339")}</Typography>
              <Typography>{t("COMMON340")}</Typography>
              <Typography>{t("COMMON341")}</Typography>
              <Typography>{t("COMMON342")}</Typography>
              <Typography>{t("COMMON343")}</Typography>
              <Typography>{t("COMMON344")}</Typography>
              <Typography>{t("COMMON345")}</Typography>
              <Typography>{t("COMMON346")}</Typography>
              {t("COMMON347")}
              <Typography> {t("COMMON348")}</Typography>
              <Typography>{t("COMMON349")}</Typography>
              <Typography> {t("COMMON350")}</Typography>
              <Typography>{t("COMMON351")}</Typography>
              <Typography>{t("COMMON351")}</Typography>
              <Typography>{t("COMMON352")}</Typography>
              <Typography>{t("COMMON353")}</Typography>
              <Typography>{t("COMMON354")}</Typography>
              <Typography>{t("COMMON355")}</Typography>
              <Typography>{t("COMMON356")}</Typography>
            </Grid>
          ) : (
            <Grid>
              <Typography>{t("COMMON357")}</Typography>
              <Typography>{t("COMMON358")}</Typography>
              <br />
              <Typography>{t("COMMON359")}</Typography>
              <br />
              <Typography>{t("COMMON360")}</Typography>
              <br />
              <Typography>{t("COMMON361")}</Typography>
              <br />
              <Typography>{t("COMMON362")}</Typography>
              <br />
              <Typography>{t("COMMON363")}</Typography>
              <br />
              <ul>
                <li>{t("COMMON364")}</li>
                <li>{t("COMMON365")}</li>
                <li>{t("COMMON366")}</li>
                <li>{t("COMMON367")}</li>
                <li>{t("COMMON368")}</li>
                <li>{t("COMMON369")}</li>
              </ul>
              <br />
              <Typography>{t("COMMON370")}</Typography>
              <br />
              <Typography>{t("COMMON371")}</Typography>
              <br />
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON372")}
              </Typography>
              <Typography>{t("COMMON373")}</Typography>
              <Typography>{t("COMMON374")}</Typography>
              <Typography>{t("COMMON375")}</Typography>
              <Typography>{t("COMMON376")}</Typography>
              <Typography>{t("COMMON377")}</Typography>
              <br />
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON378")}
              </Typography>
              <Typography>{t("COMMON379")}</Typography>
              <Typography>{t("COMMON380")}</Typography>
              <Typography>{t("COMMON381")}</Typography>
              <Typography>{t("COMMON382")}</Typography>
              <Typography>{t("COMMON383")}</Typography>
              <Typography>{t("COMMON384")}</Typography>
              <Typography>{t("COMMON385")}</Typography>
              <Typography>{t("COMMON386")}</Typography>
              <Typography>{t("COMMON387")}</Typography>
              <br />
              <Typography>{t("COMMON388")}</Typography>
              <br />
              <Typography>{t("COMMON389")}</Typography>
              <br />
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON390")}
              </Typography>
              <br />
              <Typography>{t("COMMON391")}</Typography>
              <br />
              <Typography>{t("COMMON392")}</Typography>
              <br />
              <Typography>{t("COMMON393")}</Typography>
              <br />
              <Typography>{t("COMMON394")}</Typography>
              <Typography>{t("COMMON395")}</Typography>
              <Typography>{t("COMMON396")}</Typography>
              <br />
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON397")}
              </Typography>
              <Typography>{t("COMMON398")}</Typography>{" "}
              <Typography>{t("COMMON399")}</Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON400")}
              </Typography>
              <Typography> {t("COMMON401")}</Typography>
              <Typography>{t("COMMON402")}</Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON403")}
              </Typography>
              <br />
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON404")}
              </Typography>{" "}
              <Typography>{t("COMMON405")}</Typography>{" "}
              <Typography>{t("COMMON406")}</Typography>{" "}
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON407")}
              </Typography>{" "}
              <Typography>{t("COMMON408")}</Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON409")}
              </Typography>
              <Typography>{t("COMMON410")}</Typography>
              <Typography>{t("COMMON411")}</Typography>
              <ul>
                {t("COMMON412")}
                <li>{t("COMMON413")}</li>
                <li>{t("COMMON414")}</li>
                <li>{t("COMMON415")}</li>
                <li>{t("COMMON416")}</li>
                <li>{t("COMMON417")}</li>
                <li>{t("COMMON418")}</li>
                <li>{t("COMMON419")}</li>
                <li>{t("COMMON420")}</li>
                <li>{t("COMMON421")}</li>
                <li>{t("COMMON422")}</li>
              </ul>
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON423")}
              </Typography>
              <br />
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON424")}
              </Typography>
              <Typography>{t("COMMON425")}</Typography>
              <Typography>{t("COMMON426")}</Typography>
              <Typography>{t("COMMON427")}</Typography>
              <Typography>{t("COMMON428")}</Typography>
              <Typography>{t("COMMON429")}</Typography>
              <br />
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON430")}
              </Typography>
              <br />
              <Typography>{t("COMMON431")}</Typography>
              <br />
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON432")}
              </Typography>
              <Typography>{t("COMMON433")}</Typography>
              <Typography>{t("COMMON434")}</Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON435")}
              </Typography>
              {t("COMMON436")}
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON437")}
              </Typography>
              {t("COMMON438")}
              <Typography>{t("COMMON439")}</Typography>
              <Typography>{t("COMMON440")}</Typography>
              <Typography>{t("COMMON441")}</Typography>
              <Typography>{t("COMMON442")}</Typography>
              <Typography>{t("COMMON443")}</Typography>
              <Typography>{t("COMMON444")}</Typography>
              <Typography>{t("COMMON445")}</Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON446")}
              </Typography>
              {t("COMMON447")}
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON448")}
              </Typography>
              {t("COMMON449")}
              <Typography variant="subtitle2" gutterBottom component="div">
                {t("COMMON450")}
              </Typography>
              <Typography>{t("COMMON451")}</Typography>
              <Typography>{t("COMMON452")}</Typography>
              <Typography>{t("COMMON453")}</Typography>
              <Typography>{t("COMMON454")}</Typography>
              <Typography>{t("COMMON455")}</Typography>
              <Typography>{t("COMMON456")}</Typography>
              <Typography>{t("COMMON457")}</Typography>
              <Typography>{t("COMMON458")}</Typography>
              <Typography>{t("COMMON459")}</Typography>
              <Typography>{t("COMMON460")}</Typography>
            </Grid>
          )}
        </Grid>

       
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="end"
          margin="12px"
        >
    <Button
            color={props.variant}
            style={{
              width: "100%",
              textTransform: "none",
              fontWeight: "400",
              backgroundColor: "#1090E5",
              contrastText: "#fff",
     
            }}
            variant="contained"
          
            onClick={() => handletoggle("agree")}
          >
            {t("COMMON229")}
          </Button> 

        </Grid>

        <Grid
          display="flex"
          alignItems="center"
          justifyContent="end"
          margin="12px"
        >
          <Button
            color={props.variant}
            style={{
              width: "100%",
              textTransform: "none",
              fontWeight: "400",
              backgroundColor: "#ffff",
              color: "#1090E5",
              border: "1px solid #1090E5",
             
            }}
            variant="contained"
          
            onClick={() => handletoggle("disagree")}
          >
            {t("COMMON230")}
          </Button>
        </Grid>
      </Grid>
    </Menu>
  );
}
