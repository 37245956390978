import axios from "axios";
import { JAVA_API_ANTIPASSBACK } from "./ApiEndPoints";
import { toast } from 'react-toastify';
import { ANTIPASSBACK_ERRORS, ANTIPASSBACK_LIST,ANTIPASSBACK_DEVICE_RULE } from "./types";

export const getAntipassbacks = (deviceName, page, size) => async dispatch => {
	try {
		const res = await axios.get(JAVA_API_ANTIPASSBACK + "?deviceName=" + deviceName + "&page=" + page + "&size=10000");
		if (res.status === 200 && res.data.code === "CASI0001") {
			dispatch({
				type: ANTIPASSBACK_LIST,
				payload: res.data.data
			});
		} else {
			dispatch({
				type: ANTIPASSBACK_LIST,
				payload: []
			});
		}
	} catch {

	}

};


export const updateAntipassback = (payload, callBack) => async dispatch => {
	try {
		const res = await axios.put(JAVA_API_ANTIPASSBACK, payload);
		if (res.status === 200 && res.data.code === "CASI0201") {
			toast.success(res.data.message);
			callBack(res.data);
			dispatch({
				type: ANTIPASSBACK_ERRORS,
				payload: {}
			});
		} else {
			toast.error(res.data.message);
			dispatch({
				type: ANTIPASSBACK_ERRORS,
				payload: res.data
			});
		}
	} catch {

	}
}

export const addAntipassback = (payload, callBack) => async dispatch => {
	try {
		const res = await axios.post(JAVA_API_ANTIPASSBACK, payload);
		if (res.status === 200 && res.data.code === "CASI0203") {
			toast.success(res.data.message);
			callBack();
			dispatch({
				type: ANTIPASSBACK_ERRORS,
				payload: {}
			});
		} else {
			toast.error(res.data.message);
			dispatch({
				type: ANTIPASSBACK_ERRORS,
				payload: res.data
			});
		}
	} catch (error) {

	}

}

export const removeAntipassback = (ids, callBack) => async dispatch => {
	try {
		const res = await axios.delete(JAVA_API_ANTIPASSBACK + "?ids=" + ids)
		if (res.status === 200 && res.data.code === "CASI0204") {
			toast.success(res.data.message);
			callBack();
		} else {
			toast.error(res.data.message);
		}
	} catch {

	}
}

export const getAntipassbackRules = (deviceId) => async dispatch => {

	try {
		const res = await axios.get(JAVA_API_ANTIPASSBACK  + "/"  +deviceId + "/rules");
		if (res.status === 200 && res.data.code === "CASI0202") {
			dispatch({
				type: ANTIPASSBACK_DEVICE_RULE,
				payload: res.data.data
			});
		} else {
			dispatch({
				type: ANTIPASSBACK_DEVICE_RULE,
				payload: []
			});
		}
	} catch {

	}
}


export const resetAntipassbackRule = () => async dispatch => {
    dispatch({
        type: ANTIPASSBACK_DEVICE_RULE,
        payload: []
    });

}


