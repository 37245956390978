import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { JAVA_URL_SITE_LIST, JAVA_URL_TIME_ZONE_LIST } from '../../actions/EndPoints';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addDevice } from '../../actions/DeviceApiCalls';
import { useHistory } from 'react-router';

import TextField from '@material-ui/core/TextField';

import { Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import { IconButton } from '@material-ui/core';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { getZones } from '../../actions/ZoneApiCalls';
import CommonUtil from '../Util/CommonUtils';
import SubmitButton from '../SubmitButton';
import CancelButton from '../CancelButton';

const SiteZoneList = (props) => {
  const [sites, setSites] = React.useState([]);
  const [zones, setZones] = React.useState([]);
  const [state, setState] = React.useState({
    siteId: '',
    Zone: '',
  });

  const [rowPerPage, setRowPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selDevice, setSelDevice] = useState({});
  const [open, setOpen] = React.useState(false);
  const store = useSelector((state) => state);
  const [error, setError] = React.useState({
    siteId: false,
    timezone: false,
  });

  const [SerialNumber, setSerialNumber] = React.useState({
    sn: props.data.sn,
  });

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.zone.zoneList)) {
      setTotalRecords(store.zone.zoneList.totalCount);
      setPage(store.zone.zoneList.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setZones(store.zone.zoneList.zone);
  }, [store.zone.zoneList]);

  useEffect(() => {
    setSelDevice(props.data);
  }, [props.data]);

  useEffect(() => {
    if (open) loadSites();
  }, [open]);

  // useEffect(() => {
  //   loadZones(page, rowPerPage);
  // }, []);

  const loadSites = () => {
    axios.get(JAVA_URL_SITE_LIST + '?isPageable=false').then((response) => {
      if (response.data.code === 'LMSI0000') setSites(response.data.data.site);
    });
  };

  const loadZones = (page, rowsPerPage) => {
    dispatch(getZones(page + 1, rowsPerPage, true));
    setZones(store.zone.zoneList.zone);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const handleClose = (data) => {
    setOpen(false);
  };
  const addSdmsDevice = (sn) => {
  
    const payload = {
      deviceName: null,
      iconType: null,
      siteId: state.siteId,
      clearData: false,
      timeZone: state.Zone,
    };
    if (CommonUtil.isEmpty(payload.siteId)) {
      setError({ siteId: true });
      toast.error(t('DEVI0074'));
    } else {
      dispatch(
        addDevice(sn, payload, history, (data) => {
          if (data != 'CASI0002') {
            setOpen(false);
          }
        })
      );
    }
  };
  const handleSubmit = () => {
    addSdmsDevice(selDevice.sn);
  };
  return (
    <>
      <Tooltip title={t('COMMON009')}>
        <IconButton color='secondary' aria-label='Reset filter' onClick={() => setOpen(true)}>
          <AddToPhotosIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal' fullWidth>
        <DialogTitle id='form-dialog-title'>{'Add Device'}</DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' m={2}>
              <Grid item xs={12} sm={6} lg={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    name='SerialNumber'
                    label={t('DEVI0003')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={props.data.sn}
                    //value ={SerialNumber.sn}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth required>
                    <InputLabel>{t('DEVI0025')}</InputLabel>
                    <Select
                      required
                      labelId='demo-simple-select-outlined-label-siteId'
                      id='demo-simple-select-outlined-siteId'
                      label={t('DEVI0025')}
                      name='siteId'
                      error={error.siteId}
                      onChange={handleChange}
                      fullWidth
                    >
        
                      {sites ? (
                        sites.map((site, index) => (
                          <MenuItem key={index} value={site.id}>
                            <Typography variant='inherit' noWrap>
                              {site.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={6} lg={12} >
              <Box display='flex' justifyContent='center' m={1} p={1}> 
              <FormControl variant='outlined' size='small' fullWidth >
                    <InputLabel>{t('ZONE001')}</InputLabel>
                    <Select
                    required
                      labelId='demo-simple-select-outlined-label-siteId'
                      id='demo-simple-select-outlined-siteId'
                      label={t('ZONE001')}
                      name='Zone'
                      onChange={handleChange}
                      // value={state.siteId}
                      // defaultValue={state.siteId}
                      onClick={() =>loadZones(0,rowPerPage)}
                      fullWidth
                    >
                      <MenuItem value={'none'}>
                        <em>{t('COMMON288')}</em>
                      </MenuItem>
                      {zones ? (
                        zones.map((zones, index) => (
                          <MenuItem key={index} value={zones.id} >
                            <Typography variant='inherit' noWrap>
                              {zones.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid> */}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={() => handleSubmit()} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SiteZoneList;
