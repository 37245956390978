import { DOOR_LIST, DOOR_VERIFYMODES } from "../actions/types";

const initialState = {
    doors: [],
    verifyModes: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DOOR_LIST:
            return {
                ...state,
                doors: action.payload
            }
        case DOOR_VERIFYMODES:
            return {
                ...state,
                verifyModes: action.payload
            }
        default:
            return state;
    }
}
