export const GET_ERRORS = 'GET_ERRORS';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

//OAuth-client
export const GET_COMPANIES = 'GET_COMPANIES';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REFRESH_TOKEN_TIMER = 'REFRESH_TOKEN_TIMER';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';

export const GET_ACCDEVICES = 'GET_ACCDEVICES';
export const GET_FILTER_ACCDEVICES = 'GET_FILTER_ACCDEVICES';
export const GET_ADMSACCDEVICES = 'GET_ADMSACCDEVICES';
export const GET_ACCDEVICE = 'GET_ACCDEVICE';
export const GET_ACCRESPONSE = 'GET_ACCRESPONSE';
export const UPDATE_ACCDEVICES = 'UPDATE_ACCDEVICES';
export const GET_DEVICE_STATUS_LIST = 'GET_DEVICE_STATUS_LIST';
export const DEVICE_SYNC_TIME = 'DEVICE_SYNC_TIME';
export const SET_DEVICE_TIME_ZONE = 'SET_DEVICE_TIME_ZONE';
export const DEVICE_SYNC_All_DATA = 'DEVICE_SYNC_All_DATA';

export const GET_TIMEPERIODS = 'GET_TIMEPERIODS';
export const SUCCESS = 'SUCCESS';

export const GET_ACCESS_LEVEL_LIST = 'GET_ACCESS_LEVEL_LIST';
export const GET_ACCESS_LEVEL_LIST_ID = 'GET_ACCESS_LEVEL_LIST_ID';
export const ACCESS_LEVEL_DOOR_LIST = 'ACCESS_LEVEL_DOOR_LIST';

export const GET_ACCAUXIN_LIST = 'GET_AUXIN_LIST';
export const UPDATE_ACCAUXIN = 'GET_AUXIN_UPDATE';

export const GET_ACCAUXOUT_LIST = 'GET_AUXOUT_LIST';
export const UPDATE_ACCAUXOUT = 'GET_AUXOUT_UPDATE';
export const GET_AUXOUT_STATUS = 'GET_AUXOUT_STATUS';

export const GET_ACCREADER_LIST = 'GET_READER_LIST';
export const UPDATE_ACCREADER = 'GET_READER_UPDATE';

export const GET_REPORTS_EXCEPTION_TRANSACTIONS = 'GET_REPORTS_EXCEPTION_TRANSACTIONS';
export const GET_REPORTS_FILTER_TRANSACTIONS = 'GET_REPORTS_FILTER_TRANSACTIONS';
export const GET_REPORTS_LAST_KNOWN_POSITION = 'GET_REPORTS_LAST_KNOWN_POSITION';
export const GET_REPORTS_TRANSACTIONS = 'GET_REPORTS_TRANSACTIONS';
export const GET_REPORTS_TRANSACTIONS_FOR_DAY = 'GET_REPORTS_TRANSACTIONS_FOR_DAY';

export const GET_ACCESS_DOOR_LIST = 'GET_ACCESS_DOOR_LIST';
export const GET_ACCESS_DOOR_DETAILS_LIST = 'GET_ACCESS_DOOR_DETAILS_LIST';
export const UPDATE_ACCRESPONSE = 'UPDATE_ACCRESPONSE';

export const GET_ACCESS_EMPLOYEE_LIST = 'GET_ACCESS_EMPLOYEE_LIST';
export const GET_PERSON_PRIMARY_LIST = 'GET_PERSON_PRIMARY_LIST';
export const GET_ACCESS_DEPARTMENT_LIST = 'GET_ACCESS_DEPARTMENT_LIST';
export const GET_ACCESS_LEVEL_DEPT_LIST = 'GET_ACCESS_LEVEL_DEPT_LIST';
export const GET_ACCESS_DEPARTMENT_LIST_BY_DEPARTMENTID = 'GET_ACCESS_DEPARTMENT_LIST_BY_DEPARTMENTID';
export const GET_ACCESS_LEVEL_DEPARTMENT_LIST = 'GET_ACCESS_LEVL_DEPARTMENT_LIST';
export const GET_ACCESS_DEPARTMENT_REMOVET = 'GET_ACCESS_DEPARTMENT_REMOVET';
export const GET_INTER_LOCK_LIST = 'GET_INTER_LOCK_LIST';
export const GET_INTER_LOCK_LIST_DOOR_COUNT = 'GET_INTER_LOCK_LIST_DOOR_COUNT';
export const GET_INTER_LOCK_RULES = 'GET_INTER_LOCK_RULES';
export const GET_INTER_LOCK_ROLES_LIST = 'GET_INTER_LOCK_ROLES_LIST';
export const GET_LINKAGE_LIST = 'GET_LINKAGE_LIST';
export const GET_ANTIPASSBOOK_LIST = 'GET_ANTIPASSBOOK_LIST';
export const GET_ACCESS_DEPT_LIST = 'GET_ACCESS_DEPT_LIST';
export const GET_LINKAGE_NORULES = 'GET_LINKAGE_NORULES';

export const GET_DASHBOARD_SITES_LIST = 'GET_DASHBOARD_SITES_LIST';
export const GET_DASHBOARD_DEVICE_COUNT = 'GET_DASHBOARD_DEVICE_COUNT';
export const GET_DASHBOARD_EMPLOYEE_COUNT = 'GET_DASHBOARD_EMPLOYEE_COUNT';
export const GET_DASHBOARD_NOTIFICATION_LIST = 'GET_DASHBOARD_NOTIFICATION_LIST';
export const GET_DASHBOARD_COUNT = 'GET_DASHBOARD_COUNT';


export const GET_SITEDEVICE_COUNT = 'GET_SITEDEVICE_COUNT';
export const GET_SITEDOOR_COUNT = 'GET_SITEDOOR_COUNT';
export const GET_SITE_EMPLOYEE_COUNT = 'GET_SITE_EMPLOYEE_COUNT';
export const GET_EVENT_COUNT = 'GET_EVENT_COUNT';
export const GET_SITE_DOOR_EVENT = 'GET_SITE_DOOR_EVENT';





//--------------organization-----------------//

export const GET_DEPARTMENT_LIST = 'GET_DEPARTMENT_LIST';
export const GET_SITETYPE_LIST = 'GET_SITETYPE_LIST';
export const GET_DESIGNATION_LIST = 'GET_DESIGNATION_LIST';

export const GET_RESIGNED_EMPLOYEE_LIST = 'GET_RESIGNED_EMPLOYEE_LIST';

export const GET_TEMPORARY_EMPLOYEE_LIST = 'GET_TEMPORARY_EMPLOYEE_LIST';

export const GET_ADDRESSTYPE_LIST = 'GET_ADDRESSTYPE_LIST';

export const GET_SITE_LIST = 'GET_SITE_LIST';

export const GET_ZONE_LIST = 'GET_ZONE_LIST';

export const GET_PARENTID_NOT_NULL_LIST = 'GET_PARENTID_NOT_NULL_LIST';

export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';

export const SAVE_EMPLOYEE = 'SAVE_EMPLOYEE';
export const SAVE_DEPARTMENT = 'SAVE_DEPARTMENT';
export const SAVE_DESIGNATION = 'SAVE_DESIGNATION';

export const DELETE_DESIGNATION = 'DELETE_DESIGNATION';
export const UPDATE_DESIGNATION = 'UPDATE_DESIGNATION';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';

export const DELETE_ADDRESSTYPE = 'DELETE_ADDRESSTYPE';
export const DELETE_SITE = 'DELETE_SITE';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const DELETE_SITETYPE = 'DELETE_SITETYPE';
export const SAVE_SITETYPE = 'SAVE_SITETYPE';
export const UPDATE_SITETYPE = 'UPDATE_SITETYPE';
export const DELETE_ZONE = 'DELETE_ZONE';
export const SAVE_SITE = 'SAVE_SITE';
export const SAVE_ZONE = 'SAVE_ZONE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const UPDATE_ZONE = 'UPDATE_ZONE';
export const SAVE_ADDRESSTYPE = 'SAVE_ADDRESSTYPE';

export const GET_EMPLOYEE = 'GET_EMPLOYEE';

export const CREATE_COMPANY = 'CREATE_COMPANY';

//V2

export const DEVICE_ERRORS = 'DEVICE_ERRORS';

export const DOOR_ERRORS = 'DOOR_ERRORS';
export const DOOR_LIST = 'DOOR_LIST';
export const DOOR_VERIFYMODES = 'DOOR_VERIFYMODES';

export const READER_ERRORS = 'READER_ERRORS';
export const READER_LIST = 'READER_LIST';

export const AUXIN_ERRORS = 'AUXIN_ERRORS';
export const AUXIN_LIST = 'AUXIN_LIST';

export const AUXOUT_ERRORS = 'AUXOUT_ERRORS';
export const AUXOUT_LIST = 'AUXOUT_LIST';

export const TIMESEG_ERROR = 'TIMESEG_ERROR';
export const TIMESEG_LIST = 'TIMESEG_LIST';

export const LEVEL_ERROR = 'LEVEL_ERROR';
export const LEVEL_LIST = 'LEVEL_LIST';
export const LEVEL_DOOR_LIST = 'LEVEL_DOOR_LIST';
export const LEVEL_DOOR_SELECT_LIST = 'LEVEL_DOOR_SELECT_LIST';
export const LEVEL_PERSON_LIST = 'LEVEL_PERSON_LIST';
export const LEVEL_PERSON_SELECT_LIST = 'LEVEL_PERSON_SELECT_LIST';
export const LEVEL_DEPT_LIST = 'LEVEL_DEPT_LIST';
export const LEVEL_DEPT_SELECT_LIST = 'LEVEL_DEPT_SELECT_LIST';

export const INTERLOCK_ERRORS = 'INTERLOCK_ERRORS';
export const INTERLOCK_LIST = 'INTERLOCK_LIST';
export const INTERLOCK_Device_LIST = 'NTERLOCK_Device_LIST';
export const INTERLOCK_DEVICE_RULE = 'INTERLOCK_DEVICE_RULE';

export const LINKAGE_ERRORS = 'LINKAGE_ERRORS';
export const LINKAGE_DEVICE_ERRORS = 'LINKAGE_DEVICE_ERRORS';
export const LINKAGE_LIST = 'LINKAGE_LIST';
export const LINKAGE_DEVICE_LIST = 'LINKAGE_DEVICE_LIST';
export const LINKAGE_DEVICE_RULE = 'LINKAGE_DEVICE_RULE';

export const ANTIPASSBACK_ERRORS = 'IANTIPASSBACK_ERRORS';
export const ANTIPASSBACK_LIST = 'ANTIPASSBACK_LIST';
export const ANTIPASSBACK_Device_LIST = 'ANTIPASSBACK_Device_LIST';
export const ANTIPASSBACK_DEVICE_RULE = 'ANTIPASSBACK_DEVICE_RULE';

export const TRANSACTION_ERRORS = 'TRANSACTION_ERRORS';
export const TRANSACTION_LIST = 'TRANSACTION_LIST';
export const TRANSACTION_TODAY_LIST = 'TRANSACTION_TODAY_LIST';
export const EXCEPTION_TRANSACTION_LIST = 'EXCEPTION_TRANSACTION_LIST';

// new organization - employee
export const EMPLOYEE_LIST = 'EMPLOYEE_LIST';
export const EMPLOYEE_ERRORS = 'EMPLOYEE_ERRORS';

// new organization - department
export const DEPARTMENT_LIST = 'DEPARTMENT_LIST';
export const DEPARTMENT_ERRORS = 'DEPARTMENT_ERRORS';

// new organization - designation
export const DESIGNATION_LIST = 'DESIGNATION_LIST';
export const DESIGNATION_ERRORS = 'DESIGNATION_ERRORS';

// new organization - resigned employee
export const RESIGNED_EMPLOYEE_LIST = 'RESIGNED_EMPLOYEE_LIST';
export const RESIGNED_EMPLOYEE_ERRORS = 'RESIGNED_EMPLOYEE_ERRORS';

// new organization - address type
export const ADDRESS_TYPE_LIST = 'ADDRESS_TYPE_LIST';
export const ADDRESS_TYPE_ERRORS = 'ADDRESS_TYPE_ERRORS';

// new organization - site type
export const SITE_TYPE_LIST = 'SITE_TYPE_LIST';
export const SITE_TYPE_ERRORS = 'SITE_TYPE_ERRORS';

// new organization - site
export const SITE_LIST = 'SITE_LIST';
export const SITE_ERRORS = 'SITE_ERRORS';

// new organization - zone
export const ZONE_LIST = 'ZONE_LIST';
export const ZONE_ERRORS = 'ZONE_ERRORS';

export const GET_ACCDEVICE_READERSTATE = 'GET_ACCDEVICE_READERSTATE';

export const SET_COMPANY = 'SET_COMPANY';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_LOADER = 'SHOW_LOADER';
