import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import Headerbar from '../../components/HeaderBar';
import { useTranslation } from 'react-i18next';
import DataTable from '../../components/DataTable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import { getLevelDoor, loadLevelDoors, removeLevelDoor } from '../../actions/LevelApiCalls';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import AddLevelDoor from '../../pages/level/AddLevelDoor';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import CommonUtil from '../../components/Util/CommonUtils';
import FilterLevelDoor from '../level/FilterLevelDoor';
import CachedIcon from '@material-ui/icons/Cached';

export default function DoorDetails(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [selectedData, onSelectionChange] = useState([]);
  const [open, setOpenDialog] = useState(false);
  const [data, setData] = useState([]);

  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    //if (!open) loadData(0, rowsPerPage);
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    onSelectionChange([]);
    if (!CommonUtil.isEmpty(store.level.levelDoors)) {
      setTotalRecords(store.level.levelDoors.totalCount);
      setPage(store.level.levelDoors.curPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.level.levelDoors.levelDoors);
    // eslint-disable-next-line
  }, [store.level.levelDoors]);

  useEffect(() => {
    setData(store.level.levelDoors.levelDoors);
  }, [store.level.levelDoors]);

  const loadData = (page, rowsPerPage) => {
    dispatch(loadLevelDoors(props.levelId, filter, false, page + 1, rowsPerPage));
  };

  const [filter, setFilter] = useState({
    alias: '',
    name: '',
  });

  const defaultFilter = {
    alias: '',
    name: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      alias: filterData.alias,
      name: filterData.doorName,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadData(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '30vh',
    initialPage: 1,
    pageSize: rowsPerPage,
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var ids = '';
    // eslint-disable-next-line
    data.map((record, index) => {
      if (index === data.length - 1) ids = ids + record.id;
      else ids = ids + record.id + ',';
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(removeLevelDoor(props.levelId, ids, () => loadData(page, rowsPerPage)));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const columns = [
    {
      field: 'name',
      title: t('DOORI001'),
      tooltip: t('DOORI003'),
    },
    { field: 'deviceAlias', title: t('DEVI0001'), tooltip: t('DEVI0002'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <DeleteOutlineIcon color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  return (
    <div>
      <Box display='flex' className='custom-container-header' alignItems='center'>
        <Headerbar title={t('LEVEL010')} className='custom-container-header'>
          <Box paddingRight={1}>
            <Tooltip title={t('COMMON009')}>
              <IconButton size='small' onClick={() => setOpenDialog(true)}>
                <LibraryAddIcon color='secondary' />
              </IconButton>
            </Tooltip>
          </Box>
          <Box paddingRight={1}>
            <Tooltip title={t('COMMON004')}>
              <IconButton aria-label='delete' size='small' onClick={() => deleteRecord(selectedData)}>
                <DeleteOutlineIcon color='secondary' />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <FilterLevelDoor applyFilter={(data) => applyFilter(data)} />
          </Box>
          <Box>
            <Tooltip title={t('COMMON002')}>
              <IconButton color='secondary' size='small' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
                <CachedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Headerbar>
      </Box>

      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
      <AddLevelDoor
        open={open}
        levelId={props.levelId}
        onClose={() => {
          setOpenDialog(false);
          resetFilter();
        }}
      />
    </div>
  );
}
