import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import TextField from "@material-ui/core/TextField";
import { Grid } from '@material-ui/core';
import { transactionFilters } from '../../actions/RTransactionsActions';
import { compose } from 'redux'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { JAVA_URL_TRANSACTIONS, JAVA_URL_ZONE_LIST,JAVA_URL_TIME_PERIOD } from '../../actions/EndPoints';
import axios from "axios";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { getTimePeriods } from '../../actions/TimePeriodActions';
import { createAccLevel,getAccessLevels } from '../../actions/AccessLevelActions';
import 'antd/dist/antd.css';
import { TreeSelect } from 'antd';
import {  FormHelperText } from '@material-ui/core';
import CommonUtil from '../Util/CommonUtils';
import './Dailog.css'
const styles = theme => ({
	form: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: 250
		}
	},
	formcontrol: {
		width: 150
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
		 
	}
});


const DialogTitle = withStyles(styles)(props => {
	const { children, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className="dilog-filter-head" {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className="dilog-form-closeButton" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

class AddAccessLevel extends React.Component {

	constructor(props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleApply = this.handleApply.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.transactionFilters = this.transactionFilters.bind(this);
		this.state = {
			open: true,
			dialogText: '',
			isDialogOpen: false,
			isChecked: false,
			filter: {
				fromDate: '',
				toDate: '',
				cardNo: '',
				eventNo: '',
				deptName: '',
				devAlias: '',
			},
			errors: {},
			submitted: false,
			parentZone:'',
			  zoneHierarchy:'',
			  name: '',
			  zoneId: '',
			  authAreaName: '',
			  timePeriodsdata: [],
			  timeSegDTO: {
				  id: ""
			  },
			  id: '',
			  timeSegLevelDTO: ''
         
 
		}
		//this.getTimeZoneData=this.getTimeZoneData.bind(this);
		this.onChange=this.onChange.bind(this);
		this.onTimeZoneChange=this.onTimeZoneChange.bind(this);
	}
	/*getTimeZoneData(){
		this.props.getTimePeriods(this.state.history);
		this.setState({
			timePeriodsdata: this.props.timePeriods
		})
	  }
	   */
	componentDidMount(){
		axios.get(JAVA_URL_ZONE_LIST).then((response) => {
			console.log("response"+JSON.stringify(response.data.data));
			const treeData = CommonUtil.TreeDataFormat(response.data.data?response.data.data: '');
			console.log("treeData"+JSON.stringify(treeData))
			this.setState({
				zoneHierarchy:treeData,
			});
		  });
		  var  name= '';
		  var  remark= '';
		  //this.getTimeZoneData();
		  axios.get(JAVA_URL_TIME_PERIOD+'?name=' + name +'&remark=' + remark).then((response) => {
			console.log("response"+JSON.stringify(response.data.data));
		//	const treeData = CommonUtil.TreeDataFormat(response.data.data);
			//console.log("treeData"+JSON.stringify(treeData)) 
			 
			this.setState({
				timePeriodsdata:response.data.data ? response.data.data: '',
			});
		  });
		   
		   
	}

	 
	transactionFilters(filter) {
		this.props.transactionFilters(filter);
		axios.get(JAVA_URL_TRANSACTIONS)
			.then(response => {
				this.setState({ transactions: response.data.data })
			});

	}
	componentDidUpdate(prevProps) {
		if(this.props.errors !== prevProps.errors){
			JSON.stringify(this.props.errors)
			if(Object.keys(this.props.errors).length === 0 && this.props.errors.constructor === Object){
				this.setState({
					isDialogOpen: false,errors: {}
				});
			}
		}
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
			 
		}
	}
	handleChange = (event) => {
		const target = event.target;
		const value = target.checked;
		JSON.stringify(event.target.value)
		this.setState({
			isChecked: value,
			isDialogOpen: true,
			timeSegLevelDTO: '',
			
			[event.target.name]: event.target.value
		}, () => { console.log('Open Dialog') });
	}
	onChange(e) {
	  this.setState({errors:{}})
	    this.setState({ [e.target.name]: e.target.value });
	  
	  }
	  onNameChange=(e)=>
	  {
       this.setState({name :e.target.value});
	  } 
	  onTimeZoneChange(e)
	  {
		  this.setState({zoneId: e});
	  }
	  
	// Apply filter and Reset filter
	handleApply(e) {
		//JSON.stringify(e.target.value)
		console.log('Clicked Apply');
		e.preventDefault();
		const addacclevel = {
			name: this.state.name,
			zoneId: this.state.zoneId,
			timeSegLevelDTO: this.state.timeSegLevelDTO
			 
		};
		
		 this.props.createAccLevel(addacclevel,this.props.history);
		   
		 if(this.state.errors)
		{
		 
		  
		}
		else{
			this.setState({timeSegLevelDTO: ''})
			this.handleReset();
		}
		//this.props.getAccessLevels();
	}


	handleReset() {
		console.log('Clicked Reset');
		this.setState({
			isDialogOpen: false
		});
	}

	handleClose() {
		console.log('Clicked Close');
		this.setState({
			isDialogOpen: false,errors:{}
		});
	}

	render() {
		const { t } = this.props;
		const { classes } = this.props;
		//console.log("props..."+this.props.timePeriods)
		console.log("state..."+JSON.stringify(this.state.timePeriodsdata));
		let accTimeZoneDropDownData;
		 
       accTimeZoneDropDownData = this.state.timePeriodsdata.item ? this.state.timePeriodsdata.item.map(level => (
		      <MenuItem key={level} value={level.id} >{level.name}</MenuItem>
			)) : '';
	  
			 
			  console.log("asflasdflasdfhksad"+JSON.stringify(this.state.errors))
			  
			console.log("TimePeriod Data"+ accTimeZoneDropDownData)
			
		return (
			<div>
				<Button
					variant="contained"
					color="primary"
					size="small"
					startIcon={<AddCircleOutlineRoundedIcon />}
					onClick={this.handleChange} checked={this.state.isChecked}
				>
					{t("buttonAddAccessLevel")}
				</Button>

				<Dialog
					className="dialog-timeperiod"
					open={this.state.isDialogOpen}
					onClose={this.handleDialogClose}
					fullWidth={true}
					maxWidth={'sm'}
				>

					<DialogTitle id="customized-dialog-title" onClose={this.handleDialogClose}  >{t("commonAccessLevelTitle")}</DialogTitle>

					<DialogContent>
						<Grid container>
							<Grid item xs = {12}>
								<TextField className="text-fields" name="name" onChange={this.onChange} label={t("tableColoumAccessLevelName")} type="text" 
								error={this.state.errors.name ? true : false} 
								helperText={this.state.errors.name}
								/>
							</Grid>
							<Grid item xs = {12}>
								<FormControl className={classes.formcontrol}>
									<InputLabel>
										{"Time Period"}
									</InputLabel>
									<Select labelId="demo-simple-select-helper-label"
									id="timeSegLevelDTO"
									label={ "timeSegLevelDTO"}
									name="timeSegLevelDTO"
									value={this.state.timeSegLevelDTO}
									onChange={this.onChange}
									error={this.state.errors.timeSegLevelDTO ? true : false} 
									>

							<MenuItem value={this.state.timeSegLevelDTO}></MenuItem>
							<MenuItem value=""><em>{t('none')}</em></MenuItem>
								 
                                 {accTimeZoneDropDownData}
						         </Select>
						 <FormHelperText error>
						 {this.state.errors.timeSegLevelDTO}
                         </FormHelperText>	  
								</FormControl>
							</Grid>
							<Grid item xs = {12}>
								<div className="field-container field-container-tree">
									<InputLabel>
										{ t("tableColoumAccessLevelTimeZone")}
									</InputLabel>
										<div className="field-label field-label-tree">
										<TreeSelect
											style={{ width: '100%' }}
											value={this.state.value}
											dropdownStyle={{ maxHeight: 400, overflow: 'auto'  }}
											treeData={this.state.zoneHierarchy}
											placeholder={ t("selectParent")} 
											treeDefaultExpandAll={true}
											// eslint-disable-next-line
											value={this.state.parentZone == null ?'':this.state.parentZone.id}
											onChange={this.onTimeZoneChange}
											name="zoneId"
											error={this.state.errors.zoneId ? true : false} 
								          helperText={this.state.errors.zoneId}
										/>
									</div>

								</div>
							</Grid>
						</Grid>

						 

						<DialogActions className="dilog-form-buttons">
							<Button color="primary" onClick={this.handleApply} variant="contained">
								{t("buttonCommonSubmit")}
                  			</Button>
							<Button color="primary" onClick={this.handleClose} variant="contained">
								{t("buttonCommonClose")}
                  			</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	transactions: state.RTransactions.transactionsList,
	timePeriods: state.TimePeriod.timePeriodList,
	errors: state.errors
});

export default compose(withTranslation(), withStyles(styles, { withTheme: true }), connect(mapStateToProps, { transactionFilters,getTimePeriods,getAccessLevels ,createAccLevel}))(AddAccessLevel);
 