import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getTodayTransactions, removeTransactions, handleToDayTransactionExport, getTodayFilterTransactions } from '../../actions/ReportsApiCalls';
import ConfirmModal from '../../components/ConfirmModal';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import TodayFilterTransactions from './TodayFilterTransactions';
import Pagination from '../../components/Pagination';
import CommonUtil from '../../components/Util/CommonUtils';
import { ReactComponent as Export } from '../../assets/cloudaccess-svg/Export.svg';
import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
import { Box, Button, Grid, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import Breadcrumb from '../../components/Breadcrumb';
import { REACT_URL_TRANSACTIONSFORDAY } from '../../actions/EndPoints';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#1976d2',
  },
}));

export default function TodayTransactions() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState(store.RTrasactionsForDay.toDayTransactions);
  const [selectedData, onSelectionChange] = useState([]);
  // eslint-disable-next-line
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const today = new Date(),
    // eslint-disable-next-line
    date = today.getFullYear() + '-'  + (today.getMonth() + 1) + '-' + today.getDate()+" "+'00:00';
  // eslint-disable-next-line
  const [query, setQuery] = React.useState({
    fromDate: date,
  });

  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [filter, setFilter] = useState({
    personalId: '',
    deviceAlias: '',
    deptCode: '',
    deptName: '',
    eventDescription: '',
    readerName: '',
    name: '',
    cardNo: '',
    eventPointName: '',
    eventNo: '',
  });

  const defaultFilter = {
    personalId: '',
    deviceAlias: '',
    deptCode: '',
    deptName: '',
    eventDescription: '',
    readerName: '',
    name: '',
    cardNo: '',
    eventPointName: '',
    eventNo: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      personalId: filterData.personalId,
      deviceAlias: filterData.deviceName,
      deptCode: filterData.deptCode,
      deptName: filterData.deptName,
      eventDescription: filterData.eventDescription,
      readerName: filterData.readerName,
      name: filterData.name,
      cardNo: filterData.cardNumber,
      eventPointName: filterData.eventPointName,
      eventNo: filterData.eventNo,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadTodayTransactions(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    onSelectionChange([]);
    if (!CommonUtil.isEmpty(store.RTrasactionsForDay.toDayTransactions)) {
      setTotalRecords(store.RTrasactionsForDay.toDayTransactions.totalCount);
      setPage(store.RTrasactionsForDay.toDayTransactions.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setTransactions(store.RTrasactionsForDay.toDayTransactions.transactions);
    // eslint-disable-next-line
  }, [store.RTrasactionsForDay.toDayTransactions]);

  const loadTodayTransactions = (page, rowsPerPage) => {
    dispatch(getTodayFilterTransactions(date, page + 1, rowsPerPage, filter));
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadTodayTransactions(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadTodayTransactions(0, pageSize);
  };

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage,
  };

  const columns = [
    { field: 'cardNo', title: t('COMMON074'), tooltip: t('OPE004'), editable: 'never' },
    { field: 'pin', title: t('tableHeaderPersonnelId'), editable: 'never' },
    // { field: 'name', title: t("OPE005"), editable: 'never' },
    // { field: 'deptName', title: t("OPE006"), editable: 'never' },
    { field: 'devAlias', title: t('OPE007'), editable: 'never' },
    { field: 'devSn', title: t('COMMON055'), tooltip: t('OPE008'), editable: 'never' },
    { field: 'eventTime', title: t('OPE021'), editable: 'never', render: (rowData) => <>{CommonUtil.getLocalDate1(rowData.eventTime)}</> },
    { field: 'eventAddr', title: t('OPE009'), editable: 'never' },
    { field: 'eventName', title: t('OPE010'), editable: 'never' },
    { field: 'eventPointName', title: t('COMMON073'), tooltip: t('OPE011'), editable: 'never' },
    { field: 'verifyModeName', title: t('COMMON072'), tooltip: t('OPE012'), editable: 'never', render: (rowData) => <>{t(rowData.verifyModeName.split('<')[0])}</> },
  ];

  const actions = [
    (rowData) => ({
      isFreeAction: true,
    }),
  ];

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var ids = '';
    // eslint-disable-next-line
    data.map((record, index) => {
      if (index === data.length - 1) ids = ids + record.id;
      else ids = ids + record.id + ',';
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(removeTransactions(ids, ()=>loadTodayTransactions(page, rowsPerPage)));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handleTransactionExport = () => {
	const success=t('OPE024')
    const failure=t('OPE026')
    dispatch(handleToDayTransactionExport(query.fromDate,success,failure));
  };
  const breadcrumb = [{ path: REACT_URL_TRANSACTIONSFORDAY, name: t('COMMON076') }];

  return (
    <Grid>
      <div style={{ paddingBottom: '26px' }}>
        <Breadcrumb links={breadcrumb} />
      </div>
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon/>
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON004')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => deleteRecord(selectedData)}>
              <DeleteOutlineIcon  />
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1}>
          <Button className={classes.button} color='primary' size='small' onClick={() => handleTransactionExport()} 
          startIcon={<Export style={{height:30, width:30}}/>}>
            {t('EMP014')}
          </Button>
        </Box>
        <Box p={1}>
          <TodayFilterTransactions applyFilter={(data) => applyFilter(data)} />
        </Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={transactions}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
    </Grid>
  );
}
