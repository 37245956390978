import React from 'react';
import { Box, Button, Grid, IconButton, Tooltip,  Typography,FormControl,InputLabel,MenuItem,Select,Menu} from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useTranslation } from 'react-i18next';
import SetCommunicationPassword from './SetCommunicationPassword';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import ModifyIpaddress from './ModifyIpaddress';
import { ReactComponent as Communication } from '../../assets/icons/SoftwareCommunication.svg';

export default function DeviceCommunicationOperations(props) {
  const { t } = useTranslation();

  return (
    <>
      <PopupState variant='popover' popupId='demo-popup-menu'>
        {(popupState) => (
          <React.Fragment>
            <Tooltip title={t('DEVI0048')}>
              <IconButton color='secondary' aria-label={t('COMMON002')} {...bindTrigger(popupState)}>
                <Communication style={{ height: 30, width: 30 }} />
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)}>
              <ModifyIpaddress {...props} popupState={popupState} />
              <SetCommunicationPassword {...props} popupState={popupState} />
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </>
  );
}
