import axios from "axios";
import { JAVA_API_AUXOUT } from "./ApiEndPoints";
import { toast } from 'react-toastify';
import { AUXOUT_ERRORS, AUXOUT_LIST } from "./types";

export const getAuxouts = (name, deviceAlias, page, size) => async dispatch => {
    try {
        const res = await axios.get(JAVA_API_AUXOUT + "?name=" + name + "&deviceAlias=" + deviceAlias + "&page=" + page + "&size="+size);
        if (res.status === 200 && res.data.code === "CASI0001") {
            dispatch({
                type: AUXOUT_LIST,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: AUXOUT_LIST,
                payload: []
            });
        }
    } catch {

    }

};

export const updateAuxout = (payload, callBack) => async dispatch => {
    try {
        const res = await axios.put(JAVA_API_AUXOUT, payload);
        if (res.status === 200 && res.data.code === "CASI0056") {
            toast.success(res.data.message);
            callBack(res.data);
            dispatch({
                type: AUXOUT_ERRORS,
                payload: {}
            });
        } else {
            toast.error(res.data.message);
            dispatch({
                type: AUXOUT_ERRORS,
                payload: res.data
            });
        }
    } catch {

    }
}


