import axios from "axios";
import { toast } from 'react-toastify';
import { GET_ACCDEVICES, GET_ERRORS, GET_ACCRESPONSE,GET_ADMSACCDEVICES,GET_FILTER_ACCDEVICES } from "./types";
import { JAVA_URL_DEVICE } from "./EndPoints";


export const getAccDevices = (alias,sn,deviceName,ipAddress,query) => async dispatch => {
   
   
  const res = await axios.get(JAVA_URL_DEVICE+"?alias="+alias+"&sn="+sn+"&deviceName="+deviceName+"&ipAddress="+ipAddress+"&page=" + query.page + '&size=' + query.pageSize); 
  dispatch({
    type: GET_ACCDEVICES,
    payload: res.data.data
  });
  
  
};
export const addDevice = (sn,history) => async dispatch => { 
    const res = await axios.post(JAVA_URL_DEVICE+"/"+sn);
    toast.success("device added successfully"); 
	  history.push("/acc/device")
	dispatch({
	    type: GET_ADMSACCDEVICES,
	    payload: res.data.data
	  });
	};
export const createAccDevice = (newAccDevice, history) => async dispatch => {
    try {
      const res = await axios.post(JAVA_URL_DEVICE, newAccDevice); 
      if(res.status === 201){
        
        dispatch({
            type: GET_ACCRESPONSE,
            payload: true
          });
      } 
      
      dispatch({
        type: GET_ERRORS,
        payload: {}
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.data
      });
    }
  };
  export const updateDeviceList = (updateDeviceList, history) => async dispatch => {
    
	    try {
        const res = await axios.put(JAVA_URL_DEVICE, updateDeviceList);
         
        if(res.status === 200){
          toast.success("device updated successfully"); 
          let alias= '';
          let sn= '';
          let deviceName= '';
          let ipAddress= '';
          var query = {
            page: '',
            pageSize: ''
          }
           const res = await axios.get(JAVA_URL_DEVICE+"?alias="+alias+"&sn="+sn+"&deviceName="+deviceName+"&ipAddress="+ipAddress+"&page=" + query.page + '&size=' + query.pageSize);
               dispatch({
                 type: GET_ACCDEVICES,
                 payload: res.data.data
               });
          // dispatch({
          //   type: GET_ERRORS,
          //   payload: {}
          // });
        }  
         
	    } catch (err) {
	      dispatch({
	        type: GET_ERRORS,
	        payload: err.response.data.data
	      });
	    }
	  };
export const deleteDevice = (id,query, history) => async dispatch => {
  const res = await axios.delete(JAVA_URL_DEVICE +'?ids='+ id); 
  if(res.status === 200){
    toast.success("device deleted successfully");  
   let alias= '';
   let sn= '';
   let deviceName= '';
   let ipAddress= '';
    const res = await axios.get(JAVA_URL_DEVICE+"?alias="+alias+"&sn="+sn+"&deviceName="+deviceName+"&ipAddress="+ipAddress+"&page=" + query.page + '&size=' + query.pageSize);
        dispatch({
          type: GET_ACCDEVICES,
          payload: res.data.data
        });
  } else {
    dispatch({
      type: GET_ACCRESPONSE,
      payload: false
    });
  }
    
};

export const resetAlert = (id) => async dispatch => {
    dispatch({
      type: GET_ACCRESPONSE,
      payload: false
    });
};

export const handleFilterApply = (alias,sn,ipAddress,query) => async dispatch => {
   
  const res = await axios.get(JAVA_URL_DEVICE+"?alias="+alias+"&sn="+sn+"&ipAddress="+ipAddress+'&page=' + query.page + '&size=' + query.pageSize);
    
  dispatch({
    type: GET_FILTER_ACCDEVICES,
    payload: res.data.data
  });
};