import { Box, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DeviceOperationDialog from './DeviceOperationDialog';
import { useDispatch } from 'react-redux';
import { getDevices, getSyncType, obtailPersonnelBioDetails, obtailPersonnelDetails, syncData } from '../../actions/DeviceApiCalls';
import { Radio, RadioGroup } from '@mui/material';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import CommonUtil from '../../components/Util/CommonUtils';
import axios from 'axios';

export default function GetPersonnelInformation(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  // eslint-disable-next-line
  const [popupState, setPopupState] = React.useState(props.popupState);
  const [selectedDevices, setSelectedDevice] = React.useState();
  //const [selectedTimezone, setSelectedTimeZone] = React.useState("")
  const [syncTypes, setSyncTypes] = React.useState([]);
  const [types, setTypes] = React.useState();

  const [param,setParam]=useState({});
  

  
  useEffect(() => {
    
    loadTypes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {  
    
    if(props.devices[0]!=undefined){
      axios.get('/api/v2.0/person/upload/info?sn=' + props.devices[0].sn).then((response) => { 
          setParam(response.data.data.supportDevFun);
      }); 
    }
  }, [props.devices[0]]);

  const loadTypes = () => {
    dispatch(getSyncType(selectedDevices, setTypes));
  };

  const [type, setType] = React.useState('0');

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const deviceOperations = () => {
    if (props.devices.length < 1) {
      toast.warning(t('DEVI0014'));
      handleClose();
      return;
    }

    if (props.devices.length > 1) {
      toast.warning(t('DEVI0039'));
      handleClose();
      return;
    }

    setOpenDialog(true);

    var ids = '';
    const { devices } = props;
    // eslint-disable-next-line
    devices.map((device, index) => {
      if (index === devices.length - 1) ids = ids + device.id;
      else ids = ids + device.id + ',';
    });

    setSelectedDevice(ids);
  };

  const loadDevices = () => {
    dispatch(getDevices('', '', '', '', 1, 1000));
  };

  const handleClose = () => {
    popupState.close();
    setOpenDialog(false);
    loadDevices();
  };

  const handleSubmit = () => {
    if (type !== '0') {
      dispatch(
        obtailPersonnelBioDetails(props.devices[0].sn, type, () => {
          handleClose();
        })
      );
    } else {
      dispatch(
        obtailPersonnelDetails(props.devices[0].sn, () => {
          handleClose();
        })
      );
    }
  };

  return (
    <>
      <MenuItem onClick={() => deviceOperations()}>{t('DEVI0064')}</MenuItem>

      {openDialog && (
        <DeviceOperationDialog devices={props.devices} header={t('DEVI0064')} open={openDialog} onClose={(e) => handleClose(e, '')} handleSubmit={() => handleSubmit()}>
          <Box display='flex' justifyContent='flex-start' paddingTop={1}>
            <p>
              <b className='acc-head'>{t('DEVI0064')}</b>
            </p>
          </Box>
          <Box display='flex' justifyContent='flex-start'>
            <FormGroup row>
              <Grid container>
                <RadioGroup row aria-label='gender' name='gender1' value={type} onChange={handleChange}>
                <FormControlLabel value='0' control={<Radio />} label={t('DEVI0065')} />
                  { param.fingerprint  &&
                    <FormControlLabel value='1' control={<Radio />} label={t('DEVI0066')} />
                  }
                  { param.face  &&
                    <FormControlLabel value='2' control={<Radio />} label={t('DEVI0067')} />
                  }
                  { param.palmVein  &&
                    <FormControlLabel value='8' control={<Radio/>} label={t('COMMON471')} />
                  }
                  { param.visibleLightFace  &&
                    <FormControlLabel value='9' control={<Radio/>} label={t('DEVI0068')} />
                  }              
                </RadioGroup>
              </Grid>
            </FormGroup>
          </Box>
          <Box display='flex' justifyContent='flex-start'>
            <p>
              <b className='acc-error'>{t('DEVI0070')}</b>
            </p>
          </Box>
        </DeviceOperationDialog>
      )}
    </>
  );
}
