import axios from "axios";
import { toast } from 'react-toastify';
import { JAVA_URL_DOOR, JAVA_URL_ENABLE_DOOR,JAVA_URL_DISABLE_DOOR,JAVA_URL_DOOR_STATUS ,JAVA_URL_DOOR_OPERATIONS} from "./EndPoints";
import { GET_ACCESS_DOOR_LIST , GET_ACCESS_DOOR_DETAILS_LIST } from "./types";
export const handleEnableDoor = (doorListID,DOORI031) => async dispatch => {

	const res = await axios.post(JAVA_URL_ENABLE_DOOR, doorListID);
	try {
		if (res.status === 201) {
			var alias = '';
			var doorName = '';
			var sn = '';
			var query = {
				page: '',
				pageSize: '',
			}
			toast.success(DOORI031)
			const res = await axios.get(JAVA_URL_DOOR + '?alias=' + alias + '&doorName=' + doorName + '&sn=' + sn + '&page=' + query.page + '&size=' + query.pageSize);
			dispatch({
				type: GET_ACCESS_DOOR_LIST,
				payload: res.data.data
			});
		}
	} catch (err) {
		toast.error("Something went wrong")
	}
};

export const handleDisableDoor = (doorListID,DOORI031) => async dispatch => {
	const res = await axios.post(JAVA_URL_DISABLE_DOOR, doorListID);
	try {
		if (res.status === 201) {
			var alias = '';
			var doorName = '';
			var sn = '';
			var query = {
				page: '',
				pageSize: '',
			}
			toast.success(DOORI031)
			const res = await axios.get(JAVA_URL_DOOR + '?alias=' + alias + '&doorName=' + doorName + '&sn=' + sn + '&page=' + query.page + '&size=' + query.pageSize);
			dispatch({
				type: GET_ACCESS_DOOR_LIST,
				payload: res.data.data
			});
		}
	} catch (err) {
		toast.error("Something went wrong")
	}
};

export const getDoorDetails = (doorIds,type) => async dispatch => {   
		const res = await axios.get(JAVA_URL_DOOR_STATUS.replace("{doorIds}", doorIds)+"?type="+type);
		dispatch({
			type: GET_ACCESS_DOOR_DETAILS_LIST,
			payload: res.data.data
		}); 
};
export const handleRemoteOpen = (openInterval,operationType,ids) => async dispatch => {
	const res = await axios.post(JAVA_URL_DOOR_OPERATIONS.replace("{ids}", ids)+"?operationType="+operationType+"&openInterval="+openInterval); 
	try{
		if(res.status === 200){
			toast.success("Remote Open operation successful")
		}
	}catch(err){
		toast.error("Something went wrong")
	}
	 
};
export const handleDoorOperations = (openInterval,operationType,ids) => async dispatch => {
	const res = await axios.post(JAVA_URL_DOOR_OPERATIONS.replace("{ids}", ids)+"?operationType="+operationType+operationType+"&openInterval="+openInterval); 
	try{
		if(res.status === 200){
			toast.success("Door operation successful")
		}
	}catch(err){
		toast.error("Something went wrong")
	}
	 
};
