import React, { Fragment } from 'react';
import { useEffect, useState } from 'react';
import { Grid, TextField, Typography, MenuItem, Card, Button, Box } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { toast } from 'react-toastify';
import { updateCompanyProfile } from '../../actions/ProfileApiCalls';
import { useDispatch, useSelector } from 'react-redux';
import CommonUtil from '../../components/Util/CommonUtils';
import { useTranslation } from 'react-i18next';
import { getCompanyProfile } from '../../actions/ProfileApiCalls';
import { countryList } from '../../components/Util/CountryList';
import MobileInput from '../../components/MobileInput';
import 'react-phone-input-2/lib/material.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import SelectCountery from 'react-select';
import zIndex from '@material-ui/core/styles/zIndex';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
  paper: {
    height: "115px"
  }
});

const countries = countryList;

const topStates = [
  {
    label: 'Karnataka',
    value: '1',
  },
  {
    label: 'Kerala',
    value: '2',
  },
  {
    label: 'Gujarat',
    value: '3',
  },
];

const cities = [
  {
    label: 'Bangalore',
    value: '1',
  },
  {
    label: 'Hubli',
    value: '2',
  },
  {
    label: 'Mysore',
    value: '3',
  },
];

export default function CompanyProfileScreen(props) {
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState(
    CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : ''
  );
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [responceCity, setResponceCity] = useState('');
  const [responceState, setResponceState] = useState('');
  const [responceCountry, setResponceCountry] = useState('');
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [payload, setPayload] = useState({
    id: '',
    name: '',
    industry: '',
    companySize: '',
    code: '',
    registrationNumber: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  });

  const [error, setError] = useState({
    name: false,
    industry: false,
    companySize: false,
    code: false,
    registrationNumber: false,
    email: false,
    phone: false,
    address: false,
    city: false,
    state: false,
    country: false,
    pincode: false,
  });

  useEffect(() => {
    loadCompanyData();
  }, []);

  React.useEffect(() => {
    if (!CommonUtil.isEmpty(store.security.companyData)) {
      setPayload({
        id: store.security.companyData.id,
        name: store.security.companyData.name,
        industry: store.security.companyData.industry,
        companySize: store.security.companyData.companySize,
        code: store.security.companyData.code,
        registrationNumber: store.security.companyData.registrationNumber,
        email: store.security.companyData.email,
        phone: store.security.companyData.phone,
        address: store.security.companyData.address,
        city: store.security.companyData.city,
        state: store.security.companyData.state,
        country: store.security.companyData.country,
        pincode: store.security.companyData.pincode,
      });
    }
    setResponceCity(store.security.companyData.city);
    setResponceState(store.security.companyData.state);
    setResponceCountry(store.security.companyData.country);
  }, [store.security]);

  useEffect(() => {
    if (!CommonUtil.isEmptyString(payload.phone)) {
      if (payload.phone.includes('-')) {
        setCountryCode(payload.phone.split('-')[0]);
        setPayload({ ...payload, phone: payload.phone.split('-')[1] });
      }
    }
  }, [payload.phone]);

  const loadCompanyData = () => {
    dispatch(
      getCompanyProfile((data) => { }),
      () => { }
    );
  };

  const handleChange = (event) => {
    
    var val = event.target.value; 
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    if (!CommonUtil.isEmptyString(event.target.value) && name === 'phone') {
      if (event.target.value.match(/^[0-9 ]+$/)) {
        setPayload({
          ...payload,
          [name]: event.target.value,
        });
      }
    } else {
      setPayload({
        ...payload,
        [name]: event.target.value,
      });
    }
    setError({
      ...error,
      [name]: false,
    });
  };

  const handleChangeCountry=(event)=>{

    if(event === null){
      setPayload({
        ...payload,
        country:''
      });
    }else{
      setPayload({
        ...payload,
        country: event.country,
      });
    }
    setError({
        country: false,
      });
  }
  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({ ...error, name: true });
      toast.error(t('COMPPROFSCREEN001'));
      return;
    } else if (CommonUtil.isEmptyString(payload.code)) {
      setError({ ...error, code: true });
      toast.error(t('COMPPROFSCREEN002'));
      return;
    }
    if (CommonUtil.isEmptyString(payload.country)) {
      setError({ ...error, country: true });
      toast.error(t('COMMON162'));
      return;
    }
    if (!CommonUtil.isEmptyString(payload.phone)) {
      var phone = payload.phone + countryCode;
          if (phone.length < 8 || payload.phone.length > 23) {
          setError({ ...error, phone: true });
          toast.error(t('COMPPROFSCREEN003'));
         return;
        }
      }

    if (!CommonUtil.isEmptyString(payload.email) && !payload.email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)) {
      setError({ ...error, email: true });
      toast.error(t('COMP0007'));
      return;
    }
    if (!CommonUtil.isEmptyString(payload.pincode) && !CommonUtil.isAlphaNumericSlash(payload.pincode)) {
      setError({ pincode: true });
      toast.error(t('COMPPROFSCREEN004'));
      return false;
    }
    if (!CommonUtil.isAlphaNumericSlash(payload.pincode)) {
      setError({ pincode: true });
      toast.error(t('COMPPROFSCREEN004'));
      return false;
    }

    if (!CommonUtil.isEmptyString(payload.industry) && !CommonUtil.isAlphaNumericSpace(payload.industry)) {
      setError({ industry: true });
      toast.error(t('COMPPROFSCREEN006'));
      return false;
    }

    if (!CommonUtil.isEmptyString(payload.state) && !CommonUtil.isAlphaNumericSpace(payload.state)) {
      setError({ state: true });
      toast.error(t('ADD026'));
      return false;
    }

    if (!CommonUtil.isEmptyString(payload.city) && !CommonUtil.isAlphaNumericSpace(payload.city)) {
      setError({ city: true });
      toast.error(t('ADD025'));
      return false;
    }

    if (!CommonUtil.isAlphaNumericSpace(payload.registrationNumber)) {
      setError({ registrationNumber: true });
      toast.error(t('COMPPROFSCREEN007'));
      return false;
    }

      var data = {};
    if (!CommonUtil.isEmptyString(payload.phone)) {
      var phone = payload.phone + countryCode;
      if (phone.length < 8 || phone.length > 23) {
        setError({ ...error, phone: true });
        toast.error(t('COMPPROFSCREEN003'));
        return;
      } else {
        data = { ...payload, phone: countryCode + '-' + payload.phone };
      }
    } else {
      data = { ...payload };
      setCountryCode(CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : '');
    }
    dispatch(
      updateCompanyProfile(
        data,
        (data) => {
          setEditMode(!editMode);
          toast.success(data.message);
        },
        (data) => {
          handleError(data);
        }
      )
    );
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'UASE0059':
        setError({ ...error, registrationNumber: true });
        break;
      case 'UASE0060':
        setError({ ...error, name: true });
        break;
    }
  };

  const returnValue = (key) => {
    if (CommonUtil.isEmptyString(key)) {
      return '';
    } else {
      return key;
    }
  };

  const handleCancel = () => {
    setPayload({
      id: store.security.companyData.id,
      name: store.security.companyData.name,
      industry: returnValue(store.security.companyData.industry),
      companySize: returnValue(store.security.companyData.companySize),
      code: store.security.companyData.code,
      registrationNumber: returnValue(store.security.companyData.registrationNumber),
      email: returnValue(store.security.companyData.email),
      phone: returnValue(store.security.companyData.phone),
      address: returnValue(store.security.companyData.address),
      city: returnValue(store.security.companyData.city),
      state: returnValue(store.security.companyData.state),
      country: returnValue(store.security.companyData.country),
      pincode: returnValue(store.security.companyData.pincode),
    });
    setEditMode(!editMode);
    setError({
      name: false,
      industry: false,
      companySize: false,
      code: false,
      registrationNumber: false,
      email: false,
      phone: false,
      address: false,
      city: false,
      state: false,
      country: false,
      pincode: false,
    });
  };

  return (
    <Fragment>
      <div className=' mb-4 text-center'>
        <div className=' px-3'>
          <Typography variant='h3' color='secondary'>
            {t('COMMON125')}
          </Typography>
        </div>
      </div>
      <Grid container spacing={4} direction='column' justify='center' alignItems='center'>
        <Grid item xs={12} lg={9}>
          <Card className='mb-4 card-body text-center'>
            {(store.security.user.roleCode === 'OWNER' || store.security.user.roleCode === 'admin') && (
              <div className='d-flex align-items-center justify-content-center px-3 py-3'>
                <div className='card-tr-actions'>
                  <Tooltip arrow title={t('COMMON129')}>
                    <IconButton onClick={() => handleCancel()} size='small' variant='contained' color='secondary'>
                      <span className='btn-wrapper--icon rounded'>
                        <EditOutlinedIcon />
                      </span>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}
            <Grid container>
              <Grid item xs={12} lg={6}>
                <div className='p-3'>
                  <TextField
                    autoComplete='off'
                    className='m-0 mb-2'
                    size='small'
                    label={t('COMMON106')}
                    variant='outlined'
                    placeholder={t('COMMON107')}
                    helperText=''
                    type='text'
                    name='name'
                    fullWidth
                    value={payload.name}
                    onChange={handleChange}
                    error={error.name}
                    disabled={!editMode}
                    inputProps={{
                      maxlength: 100,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className='p-3'>
                  <TextField
                    autoComplete='off'
                    className='m-0 mb-2'
                    size='small'
                    label={t('COMMON108')}
                    variant='outlined'
                    placeholder={t('COMMON109')}
                    helperText=''
                    type='text'
                    name='industry'
                    fullWidth
                    value={payload.industry}
                    onChange={handleChange}
                    error={error.industry}
                    disabled={!editMode}
                    inputProps={{
                      maxlength: 50,
                    }}
                  />
                </div>
              </Grid>
              {/* <Grid item xs={12} lg={6}>
                <div className='p-3'>
                  <TextField
                    autoComplete='off'
                    className='m-0 mb-2'
                    size='small'
                    label={t('COMMON110')}
                    variant='outlined'
                    placeholder={t('COMMON111')}
                    helperText=''
                    type='text'
                    name='companySize'
                    fullWidth
                    value={payload.companySize}
                    onChange={handleChange}
                    error={error.companySize}
                    disabled={!editMode}
                    type='number'
                  />
                </div>
              </Grid> */}
              <Grid item xs={12} lg={6}>
                <div className='p-3'>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('COMMON110')}</InputLabel>
                    <Select
                      disabled={!editMode}
                      fullWidth
                      className='m-0 mb-2 text-left'
                      helperText=''
                      label={t('COMMON110')}
                      name='companySize'
                      value={payload.companySize}
                      onChange={handleChange}
                      error={error.companySize}
                    >
                      <MenuItem value='0' defaultChecked>
                        <em>{t('none')}</em>
                      </MenuItem>
                      <MenuItem value='1-50' selected>
                        1-50
                      </MenuItem>
                      <MenuItem value='50-100' selected>
                        50-100
                      </MenuItem>
                      <MenuItem value='100-250' selected>
                        100-250
                      </MenuItem>
                      <MenuItem value='250-500' selected>
                        250-500
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className='p-3'>
                  <TextField
                    autoComplete='off'
                    className='m-0 mb-2'
                    size='small'
                    label={t('COMMON112')}
                    variant='outlined'
                    type='text'
                    placeholder={t('COMMON113')}
                    helperText=''
                    name='code'
                    fullWidth
                    value={payload.code}
                    error={error.code}
                    disabled={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className='p-3'>
                  <TextField
                    autoComplete='off'
                    className='m-0 mb-2'
                    size='small'
                    label={t('COMMON114') + ' *'}
                    variant='outlined'
                    type='text'
                    placeholder={t('COMMON115')}
                    helperText=''
                    name='registrationNumber'
                    fullWidth
                    value={payload.registrationNumber}
                    onChange={handleChange}
                    error={error.registrationNumber}
                    disabled={!editMode}
                    inputProps={{
                      maxlength: 50,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className='p-3'>
                  <TextField
                    autoComplete='off'
                    className='m-0 mb-2'
                    size='small'
                    label={t('COMMON042')}
                    variant='outlined'
                    type='email'
                    placeholder={t('COMMON102')}
                    helperText=''
                    name='email'
                    fullWidth
                    value={payload.email}
                    onChange={handleChange}
                    error={error.email}
                    disabled={!editMode}
                  />
                </div>
              </Grid>

              {/* <TextField
                    autoComplete='off'
                    className='m-0 mb-2'
                    size='small'
                    label={t('COMMON116')}
                    variant='outlined'
                    type='text'
                    placeholder={t('COMMON117')}
                    helperText=''
                    name='phone'
                    fullWidth
                    value={payload.phone}
                    onChange={handleChange}
                    error={error.phone}
                    disabled={!editMode}
                  /> */}
              <MobileInput
                error={error.phone}
                countryCode={countryCode}
                phone={payload.phone}
                editMode={editMode}
                onMobileNoChange={(phone) => {
                  setError({ phone: false });
                  setPayload({ ...payload, phone: phone });
                }}
                onCounryCodeChange={(code) => setCountryCode(code)}
              />

              <Grid item xs={12} lg={6}>
                <div className='p-3'>
                  <TextField
                    autoComplete='off'
                    className='m-0 mb-2'
                    size='small'
                    label={t('COMMON118')}
                    variant='outlined'
                    type='text'
                    placeholder={t('COMMON119')}
                    helperText=''
                    name='address'
                    fullWidth
                    value={payload.address}
                    onChange={handleChange}
                    error={error.address}
                    disabled={!editMode}
                    inputProps={{
                      maxlength: 255,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className='p-3'>
                <Autocomplete
                  clearText={t('COMMON216')}
                  openText={t('COMMON169')}
                  closeText={t('COMMON170')}
                  noOptionsText={t('COMMON214')}
                  classes={{
                    paper: classes.paper
                  }}
                  fullWidth
                  name='country'
                  options={countries}
                  getOptionLabel={(option) => option.country}
                  onChange={(event, newValue)=>{
                    handleChangeCountry(newValue);
                  }}
                  value={{country:payload.country}}
                  disabled={!editMode}
                  renderInput={(params) => <TextField {...params}
                    name='country'
                    className='login_input'
                    variant='outlined'
                    fullWidth 
                    label={t('ADD003')+' *'}
                    error={error.country}
                    size='small'/>}
               />
               </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className='p-3'>
                  <TextField
                    autoComplete='off'
                    className='m-0 mb-2'
                    size='small'
                    label={t('COMMON121')}
                    variant='outlined'
                    type='text'
                    placeholder={t('COMMON121')}
                    helperText=''
                    name='state'
                    fullWidth
                    value={payload.state}
                    onChange={handleChange}
                    error={error.state}
                    disabled={!editMode}
                    inputProps={{
                      maxlength: 50,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className='p-3'>
                  <TextField
                    autoComplete='off'
                    className='m-0 mb-2'
                    size='small'
                    label={t('COMMON120')}
                    variant='outlined'
                    type='text'
                    placeholder={t('COMMON120')}
                    helperText=''
                    name='city'
                    fullWidth
                    value={payload.city}
                    onChange={handleChange}
                    error={error.city}
                    disabled={!editMode}
                    style={{zIndex:0}}
                    inputProps={{
                      maxlength: 50,
                    }}
                  />
                </div>
              </Grid>
              {/* <Grid item xs={12} lg={6}>
                <div className='p-3'>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{'Select a City'}</InputLabel>
                    <Select
                      disabled={!editMode}
                      fullWidth
                      className='m-0 mb-2 text-left'
                      helperText=''
                      label={t('COMMON120')}
                      name='city'
                      value={payload.city}
                      onChange={handleChange}
                    >
                      <MenuItem value=''>None</MenuItem>
                      {cities ? (
                        cities.map((city, index) => (
                          <MenuItem key={index} value={city.label}>
                            <Typography variant='inherit' noWrap>
                              {city.label}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className='p-3' justifyContent='center'>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{'Select a State'}</InputLabel>
                    <Select
                      disabled={!editMode}
                      fullWidth
                      className='m-0 mb-2 text-left'
                      helperText=''
                      label={t('COMMON121')}
                      name='state'
                      value={payload.state}
                      onChange={handleChange}
                    >
                      <MenuItem value=''>None</MenuItem>
                      {topStates ? (
                        topStates.map((states, index) => (
                          <MenuItem key={index} value={states.label}>
                            <Typography variant='inherit' noWrap>
                              {states.label}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </div>
                 </Box> 
              </Grid> */}
              <Grid item xs={12} lg={6}>
                <div className='p-3'>
                  <TextField
                    autoComplete='off'
                    className='m-0 mb-2'
                    size='small'
                    label={t('COMMON123')}
                    variant='outlined'
                    type='text'
                    placeholder={t('COMMON124')}
                    helperText=''
                    name='pincode'
                    fullWidth
                    value={payload.pincode}
                    onChange={handleChange}
                    error={error.pincode}
                    disabled={!editMode}
                    inputProps={{
                      maxlength: 50,
                    }}
                  ></TextField>
                </div>
              </Grid>
              {editMode ? (
                <Grid container direction='column' justify='center' alignItems='center'>
                  <div className='p-1'>
                    <Button onClick={handleSubmit} className='pl-6 pr-6 m-3' variant='contained' color='primary' size='large' style={{ borderRadius: '50px' }}>
                      {t('tableBodySaveTooltip')}
                    </Button>
                    <Button onClick={() => handleCancel()} className='pl-6 pr-6 m-3' variant='contained' size='large' color='default' style={{ borderRadius: '50px' }}>
                      {t('cancel')}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
    
  );
}

