import * as React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Basicinfo } from '../../assets/images/visitor-icon/Basicinfo-gray.svg';
import { ReactComponent as AccessIcon } from '../../assets/images/dashboard-icons/access_icon.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Location } from '../../assets/images/visitor-icon/Location.svg';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, IconButton, Tooltip, Tab, Tabs, Typography, Grid, MenuItem, InputAdornment, TextField } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FileBase64 from 'react-file-base64';
import Avatar from '@material-ui/core/Avatar';
import defaultProfile from '../../assets/images/defaultProfile.png';
import { makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import { DialogActions } from '@material-ui/core';
import { filterSite, getSites } from '../../actions/organization/SiteApiCalls';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MobileInput from '../../components/MobileInput';
import CommonUtil from '../../components/Util/CommonUtils';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import AddressFromMap from '../../components/AddressFromMap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory, useLocation } from 'react-router-dom';
import { updatePerson } from '../../actions/organization/PersonApiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_URI_PERSON } from '../../actions/organization/EndPoints';
import { getDepartments } from '../../actions/organization/DepartmentApiCalls';
import { getDesignations } from '../../actions/organization/DesignationApiCalls';
import { getPersonAddress } from '../../actions/organization/PersonApiCalls';
import { updateAddress } from '../../actions/organization/PersonApiCalls';
import { cityData, countryData, stateData, timeZoneData } from '../Site/AddressFormData';
import { getAddressTypes } from '../../actions/organization/AddressTypeApiCalls';
import { getCompanyProfile } from '../../actions/ProfileApiCalls';
import { countryList } from '../../components/Util/CountryList';
import Breadcrumb from '../../components/Breadcrumb';
import { addPersonAccessControls, getPersonAccessControls } from '../../actions/AccessLevelActions';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UpdatePersons() {
  const [value, setValue] = React.useState(0);
  const [profile, setProfile] = React.useState(defaultProfile);
  const [site, setSite] = React.useState([]);
  const [file, setFile] = React.useState({});
  const [departments, setDepartments] = React.useState([]);
  const [designations, setDesignations] = React.useState([]);
  const [timeZones, setTimeZones] = React.useState([]);
  const [addressTypes, setAddressTypes] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const store = useSelector((state) => state);
  const [cities, setCities] = React.useState([]);
  const [selectStates, setSelectStates] = React.useState([]);

  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = React.useState({
    addressLine1: false,
    country: false,
    state: false,
    city: false,
    areaCode: false,
    latitude: false,
    longitude: false,
    radius: false,
    timezone: false,
    addressTypeId: false,
    notValid: false,
    gender:false
  });

  const [personState, setPersonState] = React.useState({
    id: location.state.selectedObject.id,
    firstName: location.state.selectedObject.firstName,
    lastName: location.state.selectedObject.lastName,
    email: location.state.selectedObject.email,
    phone: location.state.selectedObject.phone,
    employeeCode: location.state.selectedObject.employeeCode,
    joinDate: location.state.selectedObject.joinDate,
    gender: location.state.selectedObject.gender,
    departmentId: location.state.selectedObject.departmentId ? location.state.selectedObject.departmentId : '',
    designationId: location.state.selectedObject.designationId ? location.state.selectedObject.designationId : '',
    siteId: location.state.selectedObject.siteId,
    profilePhoto: location.state.selectedObject.profilePhoto,
    createUser: false,
  });
  const [personDefaultState, setPersonDefaultState] = React.useState({
    id: location.state.selectedObject.id,
    firstName: location.state.selectedObject.firstName,
    lastName: location.state.selectedObject.lastName,
    email: location.state.selectedObject.email,
    phone: location.state.selectedObject.phone,
    employeeCode: location.state.selectedObject.employeeCode,
    joinDate: location.state.selectedObject.joinDate,
    gender: location.state.selectedObject.gender,
    departmentId: location.state.selectedObject.departmentId,
    designationId: location.state.selectedObject.designationId,
    siteId: location.state.selectedObject.siteId,
    profilePhoto: location.state.selectedObject.profilePhoto,
    createUser: false,
  });

  const breadcrumb = [
    { path: REACT_URI_PERSON, name: t('PERSON001') },
    {
      path: '',
      name: CommonUtil.isEmptyString(location.state.selectedObject.id) ? t('PERSON001') : t('COMMON005'),
    },
  ];

  {
    /**person useEffect */
  }
  React.useEffect(() => {
    if (!CommonUtil.isEmptyString(location.state.selectedObject)) {
      if (!CommonUtil.isEmptyString(location.state.selectedObject.profilePhoto)) {
        setPersonState({
          ...personState,
          profilePhoto: 'data:image/jpeg;base64,' + location.state.selectedObject.profilePhoto,
        });
      }
    }
  }, [location.state.selectedObject.profilePhoto]);

  React.useEffect(() => {
    if (!CommonUtil.isEmptyString(personState.phone)) {
      if (personState.phone.includes('-')) {
        setCountryCode(personState.phone.split('-')[0]);
        setPersonState({
          ...personState,
          phone: personState.phone.split('-')[1],
        });
      }
    }
  }, [personState.phone]);

  const [addressState, setAddressState] = React.useState({
    employeeId: location.state.selectedObject.id,
    id: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    state: '',
    city: '',
    areaCode: '',
    latitude: '',
    longitude: '',
    radius: '',
    timeZone: '',
    dayLightSaving: true,
    isDefault: true,
    addressTypeId: '',
    userId: '',
    siteId: '',
  });

  const [addressDefaultState, setAddressDefaultState] = React.useState({
    employeeId: location.state.selectedObject.id,
    id: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    state: '',
    city: '',
    areaCode: '',
    latitude: '',
    longitude: '',
    radius: '',
    timeZone: '',
    dayLightSaving: true,
    isDefault: true,
    addressTypeId: '',
    userId: '',
    siteId: '',
  });

  const setEmployeeAddress = (data) => {
    
    if (!CommonUtil.isEmpty(data)) {

      if (!CommonUtil.isEmpty(data.address[0])) {
        var address = data.address[0];
        if (CommonUtil.isEmptyString(address.country)) {
          address.country = CommonUtil.getLocationDetails('country_name');
        }
        setAddressState({
          ...addressState,
          ...address,
        });

        setAddressDefaultState({
          ...addressDefaultState,
          ...address,
        });
      }
    } else {
      dispatch(
        getCompanyProfile((data) => {
          if (!CommonUtil.isEmptyString(data.country)) {
            setAddressState({ ...addressState, country: data.country });
            setAddressDefaultState({
              ...addressDefaultState,
              country: data.country,
            });
          } else {
            setAddressState({
              ...addressState,
              country: CommonUtil.getLocationDetails('country_name'),
            });
            setAddressDefaultState({
              ...addressDefaultState,
              country: CommonUtil.getLocationDetails('country_name'),
            });
          }
        })
      );
    }
  }

  React.useEffect(() => {
    dispatch(getPersonAddress(location.state.selectedObject.id, (data) => setEmployeeAddress(data)));
  }, []);

  React.useEffect(() => {

    // if (!CommonUtil.isEmpty(store.address.addressList)) {

    //   if (!CommonUtil.isEmpty(store.address.addressList.address[0])) {
    //     var address = store.address.addressList.address[0];
    //     if (CommonUtil.isEmptyString(address.country)) {
    //       address.country = CommonUtil.getLocationDetails('country_name');
    //     }
    //     setAddressState({
    //       ...addressState,
    //       ...address,
    //     });

    //     setAddressDefaultState({
    //       ...addressDefaultState,
    //       ...address,
    //     });
    //   }
    // } else {
    //   dispatch(
    //     getCompanyProfile((data) => {
    //       if (!CommonUtil.isEmptyString(data.country)) {
    //         setAddressState({ ...addressState, country: data.country });
    //         setAddressDefaultState({
    //           ...addressDefaultState,
    //           country: data.country,
    //         });
    //       } else {
    //         setAddressState({
    //           ...addressState,
    //           country: CommonUtil.getLocationDetails('country_name'),
    //         });
    //         setAddressDefaultState({
    //           ...addressDefaultState,
    //           country: CommonUtil.getLocationDetails('country_name'),
    //         });
    //       }
    //     })
    //   );
    // }
    setCities(cityData);
    setCountries(countryList);
    setSelectStates(stateData);
    setTimeZones(timeZoneData);
  }, []);

  React.useEffect(() => {
    dispatch(
      getAddressTypes(0, 0, false, (data) => {
        setAddressTypes(data.addressTypes);
      })
    );
  }, []);

  const handleChange = (event, newValue) => {
    if (value === 0) {
      var data = {};
      if (!CommonUtil.isEmptyString(personState.phone)) {
        var phone = personState.phone + countryCode.replace('+', '');
        if (phone.length < 8 || phone.length > 23) {
          setError({ ...error, phone: true });
          toast.error(t('COMPPROFSCREEN003'));
          return;
        } else {
          let finalcountryCode = CommonUtil.isEmptyString(countryCode) ? CommonUtil.getDefaultCallingCountryCode() : countryCode;
          data = {
            ...personState,
            phone: CommonUtil.isEmptyString(personState.phone) ? '' : '+' + finalcountryCode.replace('+', '') + '-' + personState.phone,
          };
        }
      } else {
        data = { ...personState };
        setCountryCode(CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : '');
      }
      if (!CommonUtil.isEmptyString(personState.profilePhoto)) {
        let photo=personState.profilePhoto.replace("data:image/jpeg;base64,","");
        data = {
          ...personState,
          profilePhoto:photo
        }
      }
      if (JSON.stringify(personDefaultState) === JSON.stringify(data)) {
        setValue(newValue);
        return;
      }

      event.preventDefault();
      if (isPersonFormValid(personState)) {
        dispatch(
          updatePerson(data, (res) => {
            if (res.code === 'OMSI0007') {
              setPersonDefaultState({
                ...personDefaultState,
                ...data,
              });
              toast.success(res.message);
              setValue(newValue);
            } else {
              handlePersonError(res);
            }
          })
        );
      }
    }

    if (value === 1) {
      if (JSON.stringify(addressState) === JSON.stringify(addressDefaultState)) {
        setValue(newValue);
        return;
      }
      if (isAddressFormValid()) {
        dispatch(
          updateAddress(addressState, (res) => {
            if (res.code === 'LMSI0009' || res.code === 'LMSI0010') {
              toast.success(res.message);
              setValue(newValue);
              setAddressDefaultState({ ...addressState });
            } else {
              handleAddressError(res);
            }
          })
        );
      }
    }
     if(value==2){
      setValue(newValue);
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      marginTop: '16px',
    },
  }));

  const classes = useStyles();

  React.useEffect(() => {
    if (!CommonUtil.isEmpty(location.state.selectedObject)) {
      if (!CommonUtil.isEmptyString(location.state.selectedObject.profilePhoto)) {
        setProfile('data:image/jpeg;base64,' + location.state.selectedObject.profilePhoto);
      }
    }
  }, [location.state.selectedObject]);

  const handleProfile = (file) => {
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      let fileSize = file.size.split(' ')[0];
      let size = parseInt(fileSize, 10);
      if (size <= 2000) {
        setFile(file);
        setPersonState({
          ...personState,
          profilePhoto: file.base64.split(',')[1],
        });
        setProfile(file.base64);
      } else {
        toast.error(t('REGISTRATIONFORM001'));
      }
    } else {
      toast.error(t('REGISTRATIONFORM002'));
    }
  };

  const [countryCode, setCountryCode] = React.useState(
    CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : ''
  );

  const handleChangeCountry = (event) => {
    if (event === null) {
      setAddressState({
        ...addressState,
        country: '',
      });
    } else {
      setAddressState({
        ...addressState,
        country: event.country,
      });
    }
    setError({
      country: false,
    });
  };

  const handleAddressSubmit = (event) => {
    event.preventDefault();
    if (isAddressFormValid()) {
      dispatch(
        updateAddress(addressState, (data) => {
          if (data.code === 'LMSI6007' || data.code === 'OMSI0007' || data.code === 'LMSI6008') {
            toast.success(data.message);
            handleAddressClose();
          } else {
            handleAddressError(data);
          }
        })
      );
    }
  };

  const isAddressFormValid = () => {
    if (CommonUtil.isEmptyString(addressState.addressLine1)) {
      setError({ addressLine1: true });
      toast.error(t('ADD014'));
      return false;
    }
    if (CommonUtil.isEmptyString(addressState.country)) {
      setError({ country: true });
      toast.error(t('ADD021'));
      return false;
    }
    if (!CommonUtil.isEmptyString(addressState.city)) {
      if (!CommonUtil.isAlphaNumericSpace(addressState.city)) {
        setError({ city: true });
        toast.error(t('ADD028'));
        return false;
      }
    } else {
      setError({ city: true });
      toast.error(t('ADD015'));
      return false;
    }

    if (!CommonUtil.isEmptyString(addressState.state)) {
      if (!CommonUtil.isAlphaNumericSpace(addressState.state)) {
        setError({ state: true });
        toast.error(t('ADD028'));
        return false;
      }
    }
    if (!CommonUtil.isEmptyString(addressState.areaCode) && !CommonUtil.isAlphaNumericSlash(addressState.areaCode)) {
      setError({ areaCode: true });
      toast.error(t('ADD028'));
      return false;
    }

    if (CommonUtil.isEmptyString(addressState.addressTypeId)) {
      setError({ addressTypeId: true });
      toast.error(t('ADD016'));
      return false;
    }

    if (!CommonUtil.isEmptyString(addressState.radius)) {
      if (CommonUtil.isEmptyString(addressState.radius.trim()) || !CommonUtil.isValidNumeric(addressState.radius)) {
        setError({ radius: true });
        toast.error(t('ADD020'));
        return false;
      }
    }

    return true;
  };

  const handleAddressError = (data) => {
    switch (data.code) {
      case 'LMSE0037':
        setError({ city: true });
        break;
      case 'LMSE0036':
        setError({ addressLine1: true });
        break;
    }

    // toast.error(data.message);
  };

  const handleAddressChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    if (event.target.name === 'latitude' || event.target.name === 'longitude') {
      if (!CommonUtil.isValidDoubleValue(event.target.value)) {
        return;
      }
    }
    setAddressState({
      ...addressState,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };
  const handleAddressClose = () => {
    history.push({
      pathname: '/acc/person',
    });
  };

  const handlePersonChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    setPersonState({
      ...personState,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };
  const handlePersonSubmit = (event) => {
    var data = {};
    if (!CommonUtil.isEmptyString(personState.phone)) {
      var phone = personState.phone + countryCode.replace('+', '');
      if (phone.length < 8 || phone.length > 23) {
        setError({ ...error, phone: true });
        toast.error(t('COMPPROFSCREEN003'));
        return;
      } else {
        let finalcountryCode = CommonUtil.isEmptyString(countryCode) ? CommonUtil.getDefaultCallingCountryCode() : countryCode;
        data = {
          ...personState,
          phone: CommonUtil.isEmptyString(personState.phone) ? '' : '+' + finalcountryCode.replace('+', '') + '-' + personState.phone,
        };
      }
    } else {
      data = { ...personState };
      setCountryCode(CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : '');
    }

    if (!CommonUtil.isEmptyString(personState.profilePhoto)) {
      let photo=personState.profilePhoto.replace("data:image/jpeg;base64,","");
      
    }
    
    event.preventDefault();
    if (isPersonFormValid(personState)) {
      dispatch(
        updatePerson(data, (data) => {
          if (data.code === 'OMSI0006' || data.code === 'OMSI0007') {
            toast.success(data.message);
            history.push(REACT_URI_PERSON);
          } else {
            handlePersonError(data);
          }
        })
      );
    }
  };

  const isPersonFormValid = (event) => {
    if (CommonUtil.isEmptyString(personState.firstName)) {
      setError({ firstName: true });
      toast.error(t('PERSON016'));
      return false;
    }
    if (!CommonUtil.isValidName(personState.firstName)) {
      setError({ firstName: true });
      toast.error(t('PERSON017'));
      return false;
    }

    if (!CommonUtil.isValidName(personState.lastName)) {
      setError({ lastName: true });
      toast.error(t('PERSON018'));
      return false;
    }

    if (!CommonUtil.isValidEmail(personState.email)) {
      setError({ email: true });
      toast.error(t('PERSON022'));
      return false;
    }

    if (CommonUtil.isEmptyString(personState.employeeCode)) {
      setError({ code: true });
      toast.error(t('PERSON019'));
      return false;
    }

    if (!CommonUtil.isValidCode(personState.employeeCode)) {
      setError({ code: true });
      toast.error(t('PERSON020'));
      return false;
    }

    // if (CommonUtil.isEmptyString(personState.gender)) {
    //   setError({ gender: true });
    //   toast.error(t('PERSON025'));
    //   return false;
    // }

    return true;
  };

  const handlePersonError = (data) => {

    switch (data.code) {
      case 'OMSE0097':
        setError({ code: true });
        break;
      case 'OMSE0184':
        setError({ email: true });
        break;
      case 'OMSE0213':
        setError({ phone: true });
        break;
      case 'OMSE0222':
        setError({ phone: true });
        break;
      case 'OMSE0214':
        setError({ phone: true });
        break;
      case 'OMSE0160':
        setError({ email: true });
        break;
      case 'OMSE0161':
        setError({ email: true });
        break;
      default:
        break;
    }

    if (data.code === 'OMSI0006' || data.code === 'OMSI0007') {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };

  React.useEffect(() => {
    dispatch(
      getDepartments(0, 0, false, (data) => {
        setDepartments(data.department);
      })
    );
  }, []);

  React.useEffect(() => {
    dispatch(
      getDesignations(0, 0, false, (data) => {
        setDesignations(data.designation);
      })
    );
  }, []);

  React.useEffect(() => {
    dispatch(
      filterSite('', '', 1, 0, (data) => {
        setSite(data.site);
      })
    );
  }, []);

  const handlePersonClose = () => {
    history.push({
      pathname: '/acc/person',
    });
  };

  const onSelectLocation = (address) => {
    setAddressState({
      ...addressState,
      timeZone: address.timeZone,
      city: CommonUtil.isAlphaNumericSlash(address.city.trim()) ? address.city.trim() : '',
      addressLine1: address.addressLine1,
      state: CommonUtil.isAlphaNumericSlash(address.state.trim()) ? address.state.trim() : '',
      country: CommonUtil.isAlphaNumericSlash(address.country.trim()) ? address.country.trim() : '',
      areaCode: CommonUtil.isAlphaNumericSlash(address.pincode) ? address.pincode : '',
      latitude: address.lat,
      longitude: address.lng,
    });
  };

  React.useEffect(() => {
    if (departments instanceof Array && (personState.departmentId === undefined || CommonUtil.isEmptyString(personState.departmentId))) {
      departments.map((dept) => {
        if (dept.code === 'Default') {
          setPersonState({ ...personState, departmentId: dept.id });
          //setPersonDefaultState({ ...personDefaultState, departmentId: dept.id });
        }
      });
    }
    if (designations instanceof Array && (personState.designationId === undefined || CommonUtil.isEmptyString(personState.designationId))) {
      designations.map((desg) => {
        if (desg.code === 'Default') {
          setPersonState({ ...personState, designationId: desg.id });
          //setPersonDefaultState({ ...personDefaultState, designationId: desg.id });
        }
      });
    }
  }, [departments, designations]);

  return (
    <>
      <div>
        <Breadcrumb links={breadcrumb}></Breadcrumb>
        <Tooltip title={t('PERSON013')}>
          <IconButton color='secondary' aria-label={t('PERSON013')}></IconButton>
        </Tooltip>
      </div>

      <div className='custom-container'>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            inkBarStyle={{ background: '#5383FF' }}
            variant='scrollable'
            onChange={handleChange}
            style={{ paddingLeft: '22px', borderBottom: '1px solid #a1a1a124' }}
          >
            <Tab
              style={{ maxWidth: '272px' }}
              label={
                <div>
                  <Basicinfo style={{ verticalAlign: 'middle', margin: '14px' }} /> {t('PERSON040')}{' '}
                </div>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <div>
                  <Location style={{ verticalAlign: 'middle', margin: '14px' }} /> {t('PERSON041')}{' '}
                </div>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid container>
                <Grid container justify='center'>
                  <Grid item xs={12}>
                    <div className='d-flex align-items-center justify-content-center'>
                      <Avatar alt='' src={profile} className={classes.large} />
                    </div>
                  </Grid>
                  {!location.state.disable && (
                    <Grid item xs={12}>
                      <div className='d-flex align-items-center justify-content-center'>
                        <IconButton variant='contained' component='label' color='secondary'>
                          <PhotoCameraIcon />
                          <div style={{ display: 'none' }}>
                            <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
                          </div>
                        </IconButton>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} spacing={4} lg={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    required
                    error={error.firstName}
                    name='firstName'
                    label={t('PERSON003')}
                    variant='outlined'
                    size='small'
                    defaultValue={personState.firstName}
                    value={personState.firstName}
                    onChange={handlePersonChange}
                    inputProps={{
                      maxlength: 100,
                      disabled: location.state.selectedObject.disable,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    error={error.lastName}
                    name='lastName'
                    label={t('PERSON004')}
                    variant='outlined'
                    size='small'
                    defaultValue={personState.lastName}
                    value={personState.lastName}
                    onChange={handlePersonChange}
                    inputProps={{
                      maxlength: 25,
                      disabled: location.state.selectedObject.disable,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    error={error.email}
                    name='email'
                    label={t('PERSON005')}
                    variant='outlined'
                    size='small'
                    defaultValue={personState.email}
                    value={personState.email}
                    onChange={handlePersonChange}
                    inputProps={{
                      disabled: location.state.selectedObject.disable,
                    }}
                  />
                </Box>
              </Grid>
              <MobileInput
                sm={12}
                lg={6}
                error={error.phone}
                countryCode={countryCode}
                phone={personState.phone}
                editMode={location.state.selectedObject.disable ? false : true}
                onMobileNoChange={(phone) => {
                  setError({ phone: false });
                  setPersonState({ ...personState, phone: phone });
                }}
                onCounryCodeChange={(code) => setCountryCode(code)}
              />
              <Grid item xs={12} sm={12} lg={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    required
                    error={error.code}
                    name='employeeCode'
                    label={t('PERSON046')}
                    variant='outlined'
                    size='small'
                    defaultValue={personState.employeeCode}
                    value={personState.employeeCode}
                    onChange={handlePersonChange}
                    inputProps={{
                      maxlength: 20,
                      disabled: location.state.selectedObject.disable,
                    }}
                  />
                </Box>
              </Grid>


              <Grid item xs={12} sm={12} lg={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('PERSON007')}</InputLabel>
                    <Select
                      fullWidth
                      error={error.gender}
                      name='gender'
                      label={t('PERSON007')}
                      variant='outlined'
                      size='small'
                      value={personState.gender}
                      defaultValue={personState.gender}
                      onChange={handlePersonChange}
                    >
          <MenuItem value={'m'}>Male</MenuItem>
          <MenuItem value={'f'}>Female</MenuItem>
          <MenuItem value={'o'}>Others</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} lg={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel >{t('PERSON010')}</InputLabel>
                    <Select
                      fullWidth
                      error={error.departmentId}
                      name='departmentId'
                      label={t('PERSON010')}
                      variant='outlined'
                      size='small'
                      value={personState.departmentId}
                      defaultValue={personState.departmentId}
                      onChange={handlePersonChange}
                      MenuProps={MenuProps}
                      inputProps={{
                        disabled: location.state.selectedObject.disable,
                      }}
                    >
                      {departments ? (
                        departments.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('PERSON011')}</InputLabel>
                    <Select
                      fullWidth
                      error={error.designationId}
                      name='designationId'
                      label={t('PERSON011') + ' *'}
                      variant='outlined'
                      size='small'
                      value={personState.designationId}
                      defaultValue={personState.designationId}
                      onChange={handlePersonChange}
                      MenuProps={MenuProps}
                      inputProps={{
                        disabled: location.state.selectedObject.disable,
                      }}
                    >
                      {designations ? (
                        designations.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('SITE001')}</InputLabel>
                    <Select
                      fullWidth
                      error={error.siteId}
                      name='siteId'
                      label={t('SITE001')}
                      variant='outlined'
                      size='small'
                      value={personState.siteId}
                      defaultValue={personState.siteId}
                      onChange={handlePersonChange}
                      MenuProps={MenuProps}
                      inputProps={{
                        disabled: location.state.selectedObject.disable,
                      }}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {site ? (
                        site.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DialogActions>
                  <SubmitButton
                    onClick={handlePersonSubmit}
                    style={{
                      display: location.state.selectedObject.disable ? 'none' : '',
                    }}
                    color='primary'
                    variant='contained'
                    size='small'
                    className='m-2'
                  >
                    {t('COMMON035')}
                  </SubmitButton>
                  <CancelButton color='primary' onClick={handlePersonClose} variant='contained' size='small' className='m-2'>
                    {t('COMMON008')}
                  </CancelButton>
                </DialogActions>
              </Grid>
            </Grid>
          </>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <form noValidate autoComplete='off'>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    required
                    fullWidth
                    error={error.addressLine1}
                    name='addressLine1'
                    label={t('ADD001')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={addressState.addressLine1}
                    defaultValue={addressState.addressLine1}
                    onChange={handleAddressChange}
                    inputProps={{
                      maxlength: 255,
                    }}
                    InputProps={{
                      maxlength: 255,
                      disabled: location.state.selectedObject.disable,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <AddressFromMap onSelectLocation={(address) => onSelectLocation(address)} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1} fullWidth>
                  <Box width={'800px'}>
                    <Autocomplete
                      clearText={t('COMMON216')}
                      openText={t('COMMON169')}
                      closeText={t('COMMON170')}
                      noOptionsText={t('COMMON214')}
                      fullWidth
                      name='country'
                      options={countries}
                      getOptionLabel={(option) => option.country}
                      onChange={(event, newValue) => {
                        handleChangeCountry(newValue);
                      }}
                      value={{ country: addressState.country }}
                      disabled={location.state.selectedObject.disable}
                      renderInput={(params) => (
                        <TextField {...params} name='country' className='login_input' variant='outlined' fullWidth label={t('ADD003') + ' *'} error={error.country} size='small' />
                      )}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    error={error.state}
                    name='state'
                    label={t('ADD004')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={addressState.state}
                    defaultValue={addressState.state}
                    onChange={handleAddressChange}
                    inputProps={{
                      maxlength: 50,
                      disabled: location.state.selectedObject.disable,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    required
                    fullWidth
                    error={error.city}
                    name='city'
                    label={t('ADD005')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={addressState.city}
                    defaultValue={addressState.city}
                    onChange={handleAddressChange}
                    inputProps={{
                      maxlength: 50,
                      disabled: location.state.selectedObject.disable,
                    }}
                    style={{ zIndex: 0 }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    error={error.areaCode}
                    name='areaCode'
                    label={t('ADD006')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={addressState.areaCode}
                    defaultValue={addressState.areaCode}
                    onChange={handleAddressChange}
                    inputProps={{
                      maxlength: 50,
                      disabled: location.state.selectedObject.disable,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    error={error.latitude}
                    name='latitude'
                    label={t('ADD007')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={addressState.latitude == '0.0' ? '' : addressState.latitude}
                    onChange={handleAddressChange}
                    type='text'
                    inputProps={{
                      disabled: location.state.selectedObject.disable,
                    }}
                    InputLabelProps={{ shrink: true }}
                    style={{ zIndex: 0 }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    error={error.longitude}
                    name='longitude'
                    label={t('ADD008')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={addressState.longitude == '0.0' ? '' : addressState.longitude}
                    onChange={handleAddressChange}
                    InputLabelProps={{ shrink: true }}
                    type='text'
                    inputProps={{
                      disabled: location.state.selectedObject.disable,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    error={error.radius}
                    name='radius'
                    label={t('ADD009')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    value={addressState.radius == '0.0' ? '' : addressState.radius}
                    onChange={handleAddressChange}
                    type='text'
                    inputProps={{
                      disabled: location.state.selectedObject.disable,
                    }}
                    style={{ zIndex: 0 }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('ADD010')}</InputLabel>
                    <Select
                      label={t('ADD010')}
                      error={error.timeZone}
                      name='timeZone'
                      value={addressState.timeZone}
                      defaultValue={addressState.timeZone}
                      onChange={handleAddressChange}
                      MenuProps={MenuProps}
                      inputProps={{
                        disabled: location.state.selectedObject.disable,
                      }}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {timeZones ? (
                        timeZones.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth required>
                    <InputLabel style={{ zIndex: 0 }}>{t('ATYPE001')}</InputLabel>
                    <Select
                      label={t('ATYPE001')}
                      error={error.addressTypeId}
                      name='addressTypeId'
                      value={addressState.addressTypeId}
                      defaultValue={addressState.addressTypeId}
                      onChange={handleAddressChange}
                      MenuProps={MenuProps}
                      inputProps={{
                        disabled: location.state.selectedObject.disable,
                      }}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {addressTypes ? (
                        addressTypes.map((data, index) => (
                          <MenuItem key={data.id} value={data.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Grid container justify='center'>
              <DialogActions>
                {!location.state.selectedObject.disable && (
                  <SubmitButton onClick={handleAddressSubmit} color='primary' variant='contained' size='small' className='m-2'>
                    {t('COMMON035')}
                  </SubmitButton>
                )}
                <CancelButton color='primary' onClick={handleAddressClose} variant='contained' size='small' className='m-2'>
                  {t('COMMON008')}
                </CancelButton>
              </DialogActions>
            </Grid>
          </form>
        </TabPanel>
      </div>
    </>
  );
}
