import React from 'react';
import { Box, Button, Grid, IconButton, Tooltip, Typography, FormControl, InputLabel, MenuItem, Select, Menu } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { clearAdministrator, performDeviceOperations } from '../../actions/DeviceApiCalls';
import { toast } from 'react-toastify';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import SyncData from './SyncData';
import GetPersonnelInformation from './GetPersonnelInformation';
import { ReactComponent as DeviceControl } from '../../assets/icons/DevControl.svg';
import { JAVA_URL_DEVICE_ROLE } from '../../actions/EndPoints';
import { useHistory } from 'react-router-dom';

export default function DeviceControlOperations(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const deviceOperations = (popupState, operation, confirmMessage) => {
    popupState.close();
    if (props.devices.length < 1) {
      toast.warning(t('DEVI0014'));
      return;
    }

    var ids = '';
    const { devices } = props;
    // eslint-disable-next-line
    devices.map((device, index) => {
      if (index === devices.length - 1) ids = ids + device.id;
      else ids = ids + device.id + ',';
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={confirmMessage}
            onConfirm={() => {
              dispatch(performDeviceOperations(ids, operation, () => props.onSuccess()));
              onClose();
            }}
            onClose={() => {
              onClose();
            }}
          />
        );
      },
    });
  };

  const clearAdmin = (popupState) => {
    popupState.close();

    if (props.devices.length < 1) {
      toast.warning(t('DEVI0014'));
      return;
    }

    var ids = '';
    const { devices } = props;
    devices.map((device, index) => {
      if (index === devices.length - 1) ids = ids + device.id;
      else ids = ids + device.id + ',';
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('DEVI0073')}
            onConfirm={() => {
              dispatch(clearAdministrator(ids));
              onClose();
            }}
            onClose={() => {
              onClose();
            }}
          />
        );
      },
    });
  };
  // const rebootDevice = (popupState) => {

  // }

  // const enableDevice = (popupState) => {

  // }

  // const disableDevice = (popupState) => {

  // }

  // const syncTime = (popupState) => {

  // }

  // const syncData = (popupState) => {

  // }

  return (
    <>
      <PopupState variant='popover' popupId='demo-popup-menu'>
        {(popupState) => (
          <React.Fragment>
            {/* <Button color='secondary' size='small' startIcon={<SystemUpdateAltIcon className='delete-btn' />} className='acc-list-action-btn' {...bindTrigger(popupState)}>
              {t('DEVI0021')}
                      </Button> */}
            <Tooltip title={t('DEVI0021')}>
              <IconButton color='secondary' aria-label={t('DEVI0021')} {...bindTrigger(popupState)}>
                <DeviceControl style={{ height: 30, width: 30 }} />
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => deviceOperations(popupState, 'rebootDev', t('DEVI0028'))}>{t('DEVI0016')}</MenuItem>
              <MenuItem onClick={() => deviceOperations(popupState, 'syncTime', t('DEVI0031'))}>{t('DEVI0017')}</MenuItem>
              <MenuItem onClick={() => deviceOperations(popupState, 'ENABLE', t('DEVI0029'))}>{t('DEVI0018')}</MenuItem>
              <MenuItem onClick={() => deviceOperations(popupState, 'DISABLE', t('DEVI0030'))}>{t('DEVI0019')}</MenuItem>
              <MenuItem onClick={() => clearAdmin(popupState)}>{t('DEVI0072')} </MenuItem>
              <SyncData {...props} popupState={popupState} />
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </>
  );
}
