import axios from "axios";
import { JAVA_API_AUXIN } from "./ApiEndPoints";
import { toast } from 'react-toastify';
import { AUXIN_ERRORS, AUXIN_LIST } from "./types";

export const getAuxins = (name, deviceAlias, page, size) => async dispatch => {
    try {
        const res = await axios.get(JAVA_API_AUXIN + "?name=" + name + "&deviceAlias=" + deviceAlias + "&page=" + page + "&size="+size);
        if (res.status === 200 && res.data.code === "CASI0001") {
            dispatch({
                type: AUXIN_LIST,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: AUXIN_LIST,
                payload: []
            });
        }
    } catch {

    }

};

export const updateAuxin = (payload, callBack) => async dispatch => {
    try {
        const res = await axios.put(JAVA_API_AUXIN, payload);
        if (res.status === 200 && res.data.code === "CASI0054") {
            toast.success(res.data.message);
            callBack(res.data);
            dispatch({
                type: AUXIN_ERRORS,
                payload: {}
            });
        } else {
            toast.error(res.data.message);
            dispatch({
                type: AUXIN_ERRORS,
                payload: res.data
            });
        }
    } catch {

    }
}


