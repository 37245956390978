import React, { useEffect, useState } from 'react';
import { Avatar, Box, IconButton, MenuItem, Card, CardMedia, Typography, CardContent, Popover, MenuList, Paper } from '@material-ui/core';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import Profile from '../../assets/images/pratap.jpg';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import setJWTToken from '../../oauth-client/setJWTToken';
import { REACT_LOGOUT, REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import jwt_decode from 'jwt-decode';
import { makeStyles } from '@material-ui/core/styles';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import BrowserLanguage from '../Util/BrowserLanguage';
import { useTranslation } from 'react-i18next';
import { IconFlagUS, IconFlagES, IconFlagCN, IconFlagRU, IconFlagTH } from 'material-ui-flags';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import ArrowLeftOutlinedIcon from '@material-ui/icons/ArrowLeftOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { SET_CURRENT_USER } from '../../actions/types';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import CommonUtil from '../Util/CommonUtils';
import { listCompanies } from '../../oauth-client/Onboard';
import japanLogo from '../../assets/images/flags/japan.svg';
import indonesia from '../../assets/images/flags/indonesia.svg';
import thailand from '../../assets/images/flags/thailand.svg';

export default function UserProfileBox() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [userProfile, setUserProfile] = useState(null);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [company, setCompany] = useState(null);
  const [cmpProfileIcon, setCmpProfileIcon] = useState(null);
  const [language, setLanguage] = useState(null);
  const [langIcon, setLangIcon] = useState(null);
  // eslint-disable-next-line
  const [selectedLang, setSelectedLang] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const store = useSelector((state) => state.security);
  // eslint-disable-next-line
  const [search, setSearch] = React.useState(null);
  // eslint-disable-next-line
  const [anchorLanguage, setAnchorLanguage] = React.useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const zlink=localStorage.getItem('redirectFrom');
  useEffect(() => {
    loadCompanies();
  }, []);

  const handleOnclickLogout = () => {
    localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
    localStorage.removeItem('companyCode');
    localStorage.removeItem('companyId');
    axios.get(process.env.REACT_APP_AUTH_SERVICE_URI + '/api/v1.0/oauth/revoke');
    setInterval(function() {
      setJWTToken(false);
      window.location.href = REACT_LOGOUT;
    }, 1000);
  };

  const handleAddCompany = () => {
    history.push('/acc/register-company');
  };

  const loadCompanies = () => {
    dispatch(
      listCompanies(
        (successData) => {
          setCompanyList(CommonUtil.isEmpty(successData.data) ? [] : successData.data.company);
        },
        (error) => {}
      )
    );
  };

  const onChange = (e) => {
    const payload = {
      companyCode: e.code,
      companyId: '',
    };
    axios
      .put(`/api/v2.0/company`, payload)
      .then(function(res) {
        if (res.data.code === 'UASI0011') {
          setJWTToken('Bearer ' + res.data.data.access_token);
          localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, 'Bearer ' + res.data.data.access_token);
          localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, 'Bearer ' + res.data.data.refresh_token);
          const decoded = jwt_decode(res.data.data.access_token);
          localStorage.setItem('companyCode', e.code);
          dispatch({
            type: SET_CURRENT_USER,
            payload: decoded,
          });
          window.location.reload();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
    handleCompanyClose();
    handleClose();
  };

  // eslint-disable-next-line
  const getCompanyListData = () => {
    return companyList.map((company) => (
      <MenuItem value={company} name={company}>
        {company.name}
      </MenuItem>
    ));
  };

  // eslint-disable-next-line
  const getCurrentCompany = () => {
    const found = companyList.find((element) => element.id === localStorage.getItem('companyId'));
    let currentCompany = found ? found.name : 'Switch company';
    return currentCompany;
  };

  // User Profile
  const handlePopoverOpen1 = (event) => {
    setUserProfile(event.currentTarget);
  };

  const handlePopoverClose1 = () => {
    setUserProfile(null);
  };

  const open1 = Boolean(userProfile);

  // Company Profile
  const handleClick = (event) => {
    setCompanyProfile(event.currentTarget);
  };

  const handleClose = () => {
    setCompanyProfile(null);
  };

  const open2 = Boolean(companyProfile);
  const id = open2 ? 'simple-popover' : undefined;

  //Company List
  const handleCompanyClick = (event) => {
    setCompany(event.currentTarget);
    setCmpProfileIcon(!cmpProfileIcon);
  };

  const handleCompanyClose = () => {
    setCompany(null);
  };

  const open3 = Boolean(company);
  const id1 = open3 ? 'simple-popover' : undefined;

  //Language List
  const handleLanguageClick = (event) => {
    setLanguage(event.currentTarget);
    setLangIcon(!langIcon);
  };

  const handleLanguageClose = () => {
    setLanguage(null);
  };

  const open4 = Boolean(language);
  const id2 = open4 ? 'simple-popover' : undefined;

  const handleCloseLangMenu = (event) => {
    if (event[0] === undefined) {
      BrowserLanguage.getDefaultLanguage();
      setSearch(null);
    } else {
      BrowserLanguage.setLanguage(event);
      i18n.changeLanguage(event);
      setSearch(event);
      setSelectedLang(event);
    }
    setAnchorLanguage(null);
    window.location.reload(false);
  };

  return (
    <>
      <Box component='span' m={1}>
        <IconButton aria-describedby={id} onClick={handleClick}>
          <MoreVertRoundedIcon style={{ color: '#FFFFFF', borderColor: '#FFFFFF' }} />
        </IconButton>
      </Box>

      <Popover
        id={id}
        open={open2}
        anchorEl={companyProfile}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <Paper>
          <MenuList>
          {!zlink&&
            (<>
            <MenuItem aria-describedby={id1} onClick={handleCompanyClick} style={{ color: '#263055' }}>
              <BusinessOutlinedIcon />
              &nbsp; {t('COMP0009')}
              {cmpProfileIcon ? <ArrowLeftOutlinedIcon /> : <ArrowRightOutlinedIcon />}
            </MenuItem>

            <Popover
              id={id1}
              open={open3}
              anchorEl={company}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleCompanyClose}
            >
            
                <MenuList>
                  {companyList.map((company) => (
                    <MenuItem value={company} key={company} name={company} onClick={() => onChange(company)} style={{ color: '#263055' }}>
                      {company.name}
                    </MenuItem>
                  ))}
                </MenuList>
            </Popover>
            </>)}

            <MenuItem aria-describedby={id2} onClick={handleLanguageClick} style={{ color: '#263055' }}>
              <LanguageOutlinedIcon />
              &nbsp; {t('COMMON126')}
              {langIcon ? <ArrowLeftOutlinedIcon /> : <ArrowRightOutlinedIcon />}
            </MenuItem>

            <Popover
              id={id2}
              open={open4}
              anchorEl={language}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleLanguageClose}
            >
              <MenuList>
                <MenuItem
                  value={'en'}
                  key={'en'}
                  onClick={() => {
                    handleCloseLangMenu('en');
                  }}
                  style={{ color: '#263055' }}
                >
                  <IconFlagUS className='lang-flag' />
                  &nbsp; {t('english')} &nbsp;
                  {//eslint-disable-next-line
                  selectedLang === 'en' ? <CheckOutlinedIcon /> : <></>}
                </MenuItem>
                <MenuItem
                  value={'es'}
                  key={'es'}
                  onClick={() => {
                    handleCloseLangMenu('es');
                  }}
                  style={{ color: '#263055' }}
                >
                  <IconFlagES className='lang-flag' />
                  &nbsp; {t('spanish')} &nbsp;
                  {//eslint-disable-next-line
                  selectedLang === 'es' ? <CheckOutlinedIcon /> : <></>}
                </MenuItem>
                <MenuItem
                  value={'zh'}
                  key={'zh'}
                  onClick={() => {
                    handleCloseLangMenu('zh');
                  }}
                  style={{ color: '#263055' }}
                >
                  <IconFlagCN className='lang-flag' />
                  &nbsp; {t('chinese')} &nbsp;
                  {//eslint-disable-next-line
                  selectedLang === 'zh' ? <CheckOutlinedIcon /> : <></>}
                </MenuItem>
                <MenuItem
                  value={'ru'}
                  key={'ru'}
                  onClick={() => {
                    handleCloseLangMenu('ru');
                  }}
                  style={{ color: '#263055' }}
                >
                  <IconFlagRU class='lang-flag' style={{ width: '25px', height: '25px' }} />
                  &nbsp; {t('COMMON201')} &nbsp;
                  {//eslint-disable-next-line
                  selectedLang === 'ru' ? <CheckOutlinedIcon /> : <></>}
                </MenuItem>

                <MenuItem
                  value={'th'}
                  key={'th'}
                  onClick={() => {
                    handleCloseLangMenu('th');
                  }}
                  style={{ color: '#263055' }}
                >
                  <img src={thailand} style={{ height: '20px' }} />
                  &nbsp; {t('COMMON202')} &nbsp;
                  {selectedLang === 'th' ? <CheckOutlinedIcon /> : <></>}
                </MenuItem>

                <MenuItem
                  value={'ja'}
                  key={'ja'}
                  onClick={() => {
                    handleCloseLangMenu('ja');
                  }}
                  style={{ color: '#263055' }}
                >
                  <img src={japanLogo} style={{ height: '20px' }} />
                  &nbsp; {t('COMMON203')} &nbsp;
                  {//eslint-disable-next-line
                  selectedLang === 'ja' ? <CheckOutlinedIcon /> : <></>}
                </MenuItem>

                <MenuItem
                  value={'idn'}
                  key={'idn'}
                  onClick={() => {
                    handleCloseLangMenu('idn');
                  }}
                  style={{ color: '#263055' }}
                >
                  <img src={indonesia} style={{ width: '25px', height: '25px' }} />
                  &nbsp; {t('COMMON204')} &nbsp;
                  {//eslint-disable-next-line
                  selectedLang === 'idn' ? <CheckOutlinedIcon /> : <></>}
                </MenuItem>
              </MenuList>
            </Popover>
          {!zlink&&
            (<>
            <MenuItem onClick={handleAddCompany} style={{ color: '#263055' }}>
              <LibraryAddIcon />
              &nbsp; {t('COMMON205')}
            </MenuItem>
          </>)}
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 400,
    marginTop: 250,
  },
  media: {
    height: 100,
    backgroundColor: '#cdcdcd',
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  subtitle: {
    textAlign: 'center',
    color: '#aeafb3',
  },
  actionButton: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    fontSize: '100px',
    width: '70px',
    height: '70px',
    marginBottom: '-80px',
    marginTop: '10px',
  },
  avatar1: {
    fontSize: '30px',
    width: '70px',
    height: '70px',
    marginBottom: '-80px',
    marginTop: '10px',
  },
  card_media: {
    backgroundColor: '#dedede',
  },
  icon_button: {
    border: '1px solid gray',
  },

  popover: {
    pointerEvents: 'none',
  },
}));
