import axios from "axios";
import { toast } from 'react-toastify';
import { GET_ERRORS, GET_ACCAUXIN_LIST, UPDATE_ACCAUXIN } from "./types";
import { JAVA_URL_AUXIN } from "./EndPoints";

export const getAccAuxIns = (name,deviceAlias,query) => async dispatch => {
	const res = await axios.get(JAVA_URL_AUXIN+'?name='+ name +'&deviceAlias='+deviceAlias +'&page=' + query.page + '&size=' + query.pageSize);
	dispatch({
		type: GET_ACCAUXIN_LIST,
		payload: res.data.data
	});
};

export const updateAccAuxIn = (accAuxIn) => async dispatch => {
	try {
		const res = await axios.put(JAVA_URL_AUXIN, accAuxIn);
		toast.success("AuxIn updated successfully");  
		dispatch({
			type: UPDATE_ACCAUXIN,
			payload: res.data.data
		});

	} catch (err) {
		dispatch({
			type: GET_ERRORS,
			payload: err.response.data.data
		});
	}
};