import axios from 'axios';

const setJWTToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = token.replace(/"/g, '');
    axios.defaults.headers.common['companyId'] = localStorage.getItem('companyId');
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export default setJWTToken;
