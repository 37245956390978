import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, TextareaAutosize, Typography } from '@material-ui/core';
import CommonUtil from '../../components/Util/CommonUtils';
import { getDepartments, updateDepartment } from '../../actions/organization/DepartmentApiCalls';
import CancelButton from '../../components/CancelButton';
import SubmitButton from '../../components/SubmitButton';
import { makeStyles } from '@material-ui/core/styles';
import { REACT_URI_DEPARTMENT } from '../../actions/organization/EndPoints';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextArea from 'antd/lib/input/TextArea';




const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
   
  
  },
}));

export default function AddDepartment(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const store = useSelector((state) => state);
  const [open, setOpen] = React.useState(props.open);
  // eslint-disable-next-line
  const [saveType, setSaveType] = React.useState(false);
  // eslint-disable-next-line
  const [data, setData] = React.useState();
  // eslint-disable-next-line
  const [parentDepartments, setParentDepartments] = React.useState([]);
  const [state, setState] = React.useState({
    id: props.selectedObject.id,
    name: props.selectedObject.name,
    code: props.selectedObject.code,
    description: props.selectedObject.description,
    parentDepartment: props.selectedObject.parentId,
  });
  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [error, setError] = React.useState({
    name: false,
    code: false,
    description: false,
    parentDepartment: false,
  });
 

  useEffect(() => {
    setOpen(props.open);
    setState({
      id: props.selectedObject.id,
      name: props.selectedObject.name,
      code: props.selectedObject.code,
      parentDepartment: props.selectedObject.parentId ? props.selectedObject.parentId : '',
      description: props.selectedObject.description,
    });
  }, [props.open, props.selectedObject]);

  useEffect(() => {
    dispatch(
      getDepartments(0, 0, false, (data) => {
        setParentDepartments(data.department);
      })
    );
  }, []);

  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.departmentError)) {
      const { departmentError } = store.errors;
      switch (departmentError.code) {
        case 'OMSE0002':
          setError({ name: true });
          break;
        case 'OMSE0001':
          setError({ code: true });
          break;
        default:
      }
    }
    // eslint-disable-next-line
  }, [store.errors.departmentError]);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      let deptObj = {};
      let parentObj = {};
      deptObj.id = state.id;
      deptObj.name = state.name;
      deptObj.code = state.code;
      deptObj.description = state.description;
      // parentObj.id = state.parentDepartment;
      deptObj.parentIdOrCode = state.parentDepartment;
      dispatch(
        updateDepartment(deptObj, (data) => {
          handlError(data);
        })
      );
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.name) && CommonUtil.isEmptyString(state.code)) {
      setError({ name: true });
      toast.error(t('DEPT011'));
      return false;
    }
    if (!CommonUtil.isValidName(state.name)) {
      setError({ name: true });
      toast.error(t('DEPT013'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.code)) {
      setError({ code: true });
      toast.error(t('DEPT010'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.name)) {
      setError({ name: true });
      toast.error(t('DEPT011'));
      return false;
    }
    if (!CommonUtil.isValidCode(state.code)) {
      setError({ code: true });
      toast.error(t('DEPT012'));
      return false;
    }
    return true;
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'OMSI0001':
        history.push(REACT_URI_DEPARTMENT);
        break;
      case 'OMSW0001':
        data = data.data.error[0];
        break;
      case 'OMSI0002':
        history.push(REACT_URI_DEPARTMENT);
        break;
      default:
        break;
    }

    switch (data.code) {
      case 'OMSE0002':
        setError({ name: true });
        break;
      case 'OMSE0001':
        setError({ code: true });
        break;
      case 'OMSE0077':
        setError({ code: true });
        break;
      case 'OMSE0078':
        setError({ name: true });
        break;
      case 'OMSE0209':
        setError({ parentDepartment: true });
        break;
      default:
        break;
    }

    if (data.code === 'OMSI0001' || data.code === 'OMSI0002') {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
    }
  };



  return (
    <div>
      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex' m={1}>
            <Box flexGrow={1}>{CommonUtil.isEmptyString(state.id) ? t('DEPT002') : t('DEPT008')}</Box>
            <Cancel onClick={handleClose} className='close_icon' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    required
                    error={error.name}
                    name='name'
                    label={t('COMMON044')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    defaultValue={state.name}
                    value={state.name}
                    onChange={handleChange}
                    inputProps={{ maxLength: 49 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    required
                    error={error.code}
                    name='code'
                    label={t('COMMON045')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    defaultValue={state.code}
                    value={state.code}
                    onChange={handleChange}
                    inputProps={{ maxLength: 29 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={'20px'}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('COMMON050')}</InputLabel>
                    <Select
                   
                      label={t('COMMON050')}
                      error={error.parentDepartment}
                      name='parentDepartment'
                      onChange={handleChange}
                      value={state.parentDepartment}
                      defaultValue={state.parentDepartment}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {parentDepartments ? (
                        parentDepartments.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} >
                <Box display='flex' justifyContent='center' m={1} p={1}>
                   
                  

           <TextField id="outlined-basic"
           fullWidth
           autoComplete='off'
            label="Description"   
            multiline
            inputProps={{maxLength:255}}
             variant="outlined"
             name="description"
            value={state.description}
            onChange ={handleChange}
            helperText=''
              />



                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON035')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
