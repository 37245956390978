import ReactApexChart from 'react-apexcharts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { getdeviceCount } from '../../actions/DashBoardActions';
import { connect } from 'react-redux';

class DevicesGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceCount: 0,
      series: [],
      options: {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#e7e7e7',
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: '22px',
              },
            },
          },
        },
        grid: {
          padding: {
            top: 0,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91],
          },
        },
        labels: ['Average Results'],
      },
    };
  }

  componentDidMount() {
    this.props.getdeviceCount();

    this.setState(
      {
        deviceCount: this.props.deviceCount,
      },
      () => {
        this.afterSetStateFinished();
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.deviceCount !== prevProps.deviceCount) {
      this.setState({ deviceCount: this.props.deviceCount }, () => {
        this.afterSetStateFinished();
      });
    }
  }

  afterSetStateFinished = () => {
    var arr = [this.props.deviceCount ? this.props.deviceCount.deviceCount : []];

    this.setState({ series: arr });
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <h5 style={{ textAlign: 'center', padding: '14px' }}>{t('COMMON090')}</h5>
        <ReactApexChart options={this.state.options} series={this.state.series} type='radialBar' />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sitesList: state.dashBoard.sitesList,
  deviceCount: state.dashBoard.deviceCount,
});
export default compose(withTranslation(), connect(mapStateToProps, { getdeviceCount }))(DevicesGraph);
