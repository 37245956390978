import { Grid, Box, Typography, TextField, DialogActions, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButton from '../../components/SubmitButton';
import React, { useEffect } from 'react';
import CommonUtil from '../../components/Util/CommonUtils';
import { toast } from 'react-toastify';
import CancelButton from '../../components/CancelButton';
import { getPersonAddress } from '../../actions/organization/PersonApiCalls';
import { updateAddress } from '../../actions/organization/PersonApiCalls';
import { cityData, countryData, stateData, timeZoneData } from '../Site/AddressFormData';
import { getAddressTypes } from '../../actions/organization/AddressTypeApiCalls';
import { useHistory } from 'react-router';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import SelectCountery from 'react-select';
import { countryList } from '../../components/Util/CountryList';
import AddressFromMap from '../../components/AddressFromMap';
import Select from '@mui/material/Select';
import { getCompanyProfile } from '../../actions/ProfileApiCalls';
import Autocomplete from '@material-ui/lab/Autocomplete';



const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function Address(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [addressList, setAddressList] = React.useState([]);
  const [siteTypes, setSiteTypes] = React.useState([]);
  const [parentSites, setParentSites] = React.useState([]);
  const [addressTypes, setAddressTypes] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [selectStates, setSelectStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [timeZones, setTimeZones] = React.useState([]);
  let history = useHistory();


  const [state, setState] = React.useState({
    employeeId: props.selectedObject.id,
    
    id: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    state: '',
    city: '',
    areaCode: '',
    latitude: '',
    longitude: '',
    radius: '',
    timeZone: '',
    dayLightSaving: true,
    isDefault: true,
    addressTypeId: '',
    userId: '',
    siteId: '',
  });

  useEffect(() => {
    
    dispatch(getPersonAddress(state.employeeId));
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.address.addressList)) {
      if (!CommonUtil.isEmpty(store.address.addressList.address[0])) {
        var address = store.address.addressList.address[0];
        if (CommonUtil.isEmptyString(address.country)) {
          address.country = CommonUtil.getLocationDetails('country_name');
        }
        setState({
          ...state,
          ...address,
        });
      }
    } else {
      dispatch(
        getCompanyProfile((data) => {
          if (!CommonUtil.isEmptyString(data.country)) {
            setState({...state,
              country: data.country,
            });
          } else {
            setState({...state,
              country: CommonUtil.getLocationDetails('country_name'),
            });
          }
        })
      );
      
    }
    setCities(cityData);
    setCountries(countryList);
    setSelectStates(stateData);
    setTimeZones(timeZoneData);
  }, [store.address.addressList]);

  useEffect(() => {
    dispatch(
      getAddressTypes(0, 0, false, (data) => {
        setAddressTypes(data.addressType);
      })
    );
  }, []);
  const handleClose = () => {
    history.push({
      pathname: '/acc/person',
    });
  };
  const handleChange = (event) => {
    const name = event.target.name;
    if(event.target.name==='latitude' || event.target.name==='longitude'){
      if(!CommonUtil.isValidDoubleValue(event.target.value)){
        return;
      }
    }
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleChangeCountry=(event)=>{
    if(event === null){
      setState({
        ...state,
        country:'',
      });
    }else{
      setState({
        ...state,
        country:event.country,
      });
    }
    setError({
      country: false,
    });
  }
  const [error, setError] = React.useState({
    addressLine1: false,
    country: false,
    state: false,
    city: false,
    areaCode: false,
    latitude: false,
    longitude: false,
    radius: false,
    timezone: false,
    addressTypeId: false,
  });
  const [emails, SetEmails] = React.useState([]);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      dispatch(
        updateAddress(state, (data) => {
          handleError(data);
        })
      );
    }
  };
  const handleError = (data) => {
    
    switch (data.code) {
      case 'LMSE0037':
        setError({ city: true });
        break;
      case 'LMSE0036':
        setError({addressLine1:true});
        break;
    }

    if (data.code === 'LMSI0009' || data.code === 'LMSI0010') {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
      // handleClose();
    }
  };

  const isFormValid = () => {
    if (!CommonUtil.isEmptyString(state.addressLine1)) {
      if (CommonUtil.isEmptyString(state.addressLine1.trim())) {
        setError({ addressLine1: true });
        toast.error(t('ADD029'));
        return false;
      }
    }else{
      setError({ addressLine1: true });
        toast.error(t('ADD014'));
        return false;
    }
    if (CommonUtil.isEmptyString(state.country)) {
      setError({ country: true });
      toast.error(t('ADD021'));
      return false;
    }
    if (!CommonUtil.isEmptyString(state.city)) {
      if (CommonUtil.isEmptyString(state.city.trim())) {
        setError({ city: true });
        toast.error(t('ADD025'));
        return false;
      } else if (!CommonUtil.isAlphaNumericSpace(state.city)) {
        setError({ city: true });
        toast.error(t('ADD028'));
        return false;
      }
    }else{
      setError({ city: true });
        toast.error(t('ADD015'));
        return false;
    }

    if (!CommonUtil.isEmptyString(state.state)) {
      if (CommonUtil.isEmptyString(state.state.trim())) {
        setError({ state: true });
        toast.error(t('ADD026'));
        return false;
      } else if (!CommonUtil.isAlphaNumericSpace(state.state)) {
        setError({ state: true });
        toast.error(t('ADD028'));
        return false;
      }
    }
    if (!CommonUtil.isEmptyString(state.areaCode) && !CommonUtil.isAlphaNumericSlash(state.areaCode)) {
      setError({ areaCode: true });
      toast.error(t('ADD028'));
      return false;
    }

    if (CommonUtil.isEmptyString(state.addressTypeId)) {
      setError({ addressTypeId: true });
      toast.error(t('ADD016'));
      return false;
    }
   
    if (!CommonUtil.isEmptyString(state.radius)) {
      if (CommonUtil.isEmptyString(state.radius.trim()) || !CommonUtil.isValidNumeric(state.radius)) {
        setError({ radius: true });
        toast.error(t('ADD020'));
        return false;
      }
    }

    return true;
  };

  const onSelectLocation = (address) => {
    setState({
      ...state,
      timeZone: address.timeZone,
      city: CommonUtil.isAlphaNumericSlash(address.city.trim()) ? address.city.trim() : '',
      addressLine1: address.addressLine1,
      state: CommonUtil.isAlphaNumericSlash(address.state.trim()) ? address.state.trim() : '',
      country: CommonUtil.isAlphaNumericSlash(address.country.trim()) ? address.country.trim() : '',
      areaCode: CommonUtil.isAlphaNumericSlash(address.pincode) ? address.pincode : '',
      latitude: address.lat,
      longitude: address.lng,
    });
  };
  return (
    <>
      <form noValidate autoComplete='off'>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                required
                fullWidth
                error={error.addressLine1}
                name='addressLine1'
                label={t('ADD001')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.addressLine1}
                defaultValue={state.addressLine1}
                onChange={handleChange}
                inputProps={{
                  maxlength: 255,
                }}
                InputProps={{
                  maxlength: 255,
                  disabled: props.selectedObject.disable,
                  endAdornment: (
                    <InputAdornment position='end' onClick={props.selectedObject.disable ? 'false' : ''}>
                      <AddressFromMap onSelectLocation={(address) => onSelectLocation(address)} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                fullWidth
                error={error.addressLine2}
                name='addressLine2'
                label={t('ADD002')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.addressLine2}
                defaultValue={state.addressLine2}
                onChange={handleChange}
                inputProps={{
                  maxlength: 255,
                  disabled: props.selectedObject.disable,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                fullWidth
                error={error.addressLine3}
                name='addressLine3'
                label={t('ADD017')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.addressLine3}
                defaultValue={state.addressLine3}
                onChange={handleChange}
                inputProps={{
                  maxlength: 255,
                  disabled: props.selectedObject.disable,
                }}
              />
            </Box>
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1} fullWidth>
               
              <Box width={'800px'}>
               <Autocomplete
                  clearText={t('COMMON216')}
                  openText={t('COMMON169')}
                  closeText={t('COMMON170')}
                  noOptionsText={t('COMMON214')}
                  fullWidth
                  name='country'
                  options={countries}
                  getOptionLabel={(option) => option.country}
                  onChange={(event, newValue)=>{
                    handleChangeCountry(newValue);
                  }}
                  value={{country:state.country}}
                  disabled={props.selectedObject.disable}
                  renderInput={(params) => <TextField {...params}
                    name='country'
                    className='login_input'
                    variant='outlined'
                    fullWidth 
                    label={t('ADD003')+' *'}
                    error={error.country}
                    size='small'/>}
               />
                </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                fullWidth
                error={error.state}
                name='state'
                label={t('ADD004')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.state}
                defaultValue={state.state}
                onChange={handleChange}
                inputProps={{
                  maxlength: 50,
                  disabled: props.selectedObject.disable,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                required
                fullWidth
                error={error.city}
                name='city'
                label={t('ADD005')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.city}
                defaultValue={state.city}
                onChange={handleChange}
                inputProps={{
                  maxlength: 50,
                  disabled: props.selectedObject.disable,
                }}
                style={{ zIndex: 0 }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                fullWidth
                error={error.areaCode}
                name='areaCode'
                label={t('ADD006')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.areaCode}
                defaultValue={state.areaCode}
                onChange={handleChange}
                inputProps={{
                  maxlength: 50,
                  disabled: props.selectedObject.disable,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                fullWidth
                error={error.latitude}
                name='latitude'
                label={t('ADD007')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.latitude=='0.0'?'':state.latitude}
                onChange={handleChange}
                type='text'
                inputProps={{
                  disabled: props.selectedObject.disable,
                }}
                InputLabelProps={{ shrink: true }}
                style={{ zIndex: 0 }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                fullWidth
                error={error.longitude}
                name='longitude'
                label={t('ADD008')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.longitude=='0.0'?'':state.longitude}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                type='text'
                inputProps={{
                  disabled: props.selectedObject.disable,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                fullWidth
                error={error.radius}
                name='radius'
                label={t('ADD009')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.radius=='0.0'?'':state.radius}
                onChange={handleChange}
                type='text'
                inputProps={{
                  disabled: props.selectedObject.disable,
                }}
                style={{ zIndex: 0 }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>{t('ADD010')}</InputLabel>
                <Select
                  label={t('ADD010')}
                  error={error.timeZone}
                  name='timeZone'
                  value={state.timeZone}
                  defaultValue={state.timeZone}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  inputProps={{
                    disabled: props.selectedObject.disable,
                  }}
                >
                  <MenuItem value='' selected>
                    <em>{t('none')}</em>
                  </MenuItem>
                  {timeZones ? (
                    timeZones.map((data, index) => (
                      <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                        <Typography variant='inherit' noWrap>
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth required>
                <InputLabel style={{ zIndex: 0 }}>{t('ATYPE001')}</InputLabel>
                <Select
                  label={t('ATYPE001')}
                  error={error.addressTypeId}
                  name='addressTypeId'
                  value={state.addressTypeId}
                  defaultValue={state.addressTypeId}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  inputProps={{
                    disabled: props.selectedObject.disable,
                  }}
                >
                  <MenuItem value='' selected>
                    <em>{t('none')}</em>
                  </MenuItem>
                  {addressTypes ? (
                    addressTypes.map((data, index) => (
                      <MenuItem key={data.id} value={data.id} style={{ maxWidth: '100%' }}>
                        <Typography variant='inherit' noWrap>
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid container justify='center'>
          <DialogActions>
            {!props.selectedObject.disable && (
              <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small' className='m-2'>
                {t('COMMON035')}
              </SubmitButton>
            )}
            <CancelButton color='primary' onClick={handleClose} variant='contained' size='small' className='m-2'>
              {t('COMMON008')}
            </CancelButton>
          </DialogActions>
        </Grid>
      </form>
    </>
  );
}
