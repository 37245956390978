import axios from 'axios';
import { JAVA_API_ADDRESS_TYPE, JAVA_API_ADDRESS_TYPE_DELETE_V3, JAVA_API_ADDRESS_TYPE_SEARCH, JAVA_API_ADDRESS_TYPE_SEARCH_V3, JAVA_API_ADDRESS_TYPE_UPDATE_V3, JAVA_API_ADDRESS_TYPE_V3 } from './ApiEndPoints';
import { ADDRESS_TYPE_LIST } from './types';

export const getAddressTypes = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
 
  try {
    const res = await axios.get(JAVA_API_ADDRESS_TYPE_SEARCH_V3+'?pageNumber='+pageNumber+'&pageSize='+pageSize);
    if (res.status === 200 && res.data.code === 'LMSI6000') {
      if (isPageable) {
        dispatch({
          type: ADDRESS_TYPE_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: ADDRESS_TYPE_LIST,
          payload: [],
        });
      }
    }
  } catch {}
};

export const updateAddressType = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === '' ? true : false;
    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_ADDRESS_TYPE_V3, payload);
    } else {
      res = await axios.put(JAVA_API_ADDRESS_TYPE_UPDATE_V3 + payload.id + "/update", payload);
    }
    callBack(res.data);
  } catch {}
};

export const filterAddressType = (name, code, page, size) => async (dispatch) => {
  try {
    let query = '';
    if (name !== '') {
      query += '&name=' + name;
    }
    if (code !== '') {
      query += '&code=' + code;
    }
    const res = await axios.get(JAVA_API_ADDRESS_TYPE_SEARCH_V3 + '?pageNumber=' + page + '&pageSize=' + size + query);
    if (res.status === 200 && res.data.code === 'LMSI6000') {
      dispatch({
        type: ADDRESS_TYPE_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: ADDRESS_TYPE_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const deleteAddressTypes = (ids, callBack) => async (dispatch) => {
  try {
    const deleteObj = {};
    deleteObj.idsOrCodes = ids;
    deleteObj.deleteChild = false;

    const res = await axios.delete(JAVA_API_ADDRESS_TYPE_DELETE_V3 + ids);
    callBack(res.data);
  } catch {}
};
