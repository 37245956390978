import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import miot_logo_new from '../../assets/images/Miot-Logo.svg';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './custom.css';
import { useHistory, withRouter } from 'react-router-dom';
import setJWTToken from '../../oauth-client/setJWTToken';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken, redirectLogin } from '../../oauth-client/OauthClientActions';
import CommonUtil from '../../components/Util/CommonUtils';
import { REACT_URL_DASHBOARD, REACT_VERIFY_COMPANY } from '../../actions/EndPoints';
import { MenuItem, MenuList, Popover, Tooltip } from '@material-ui/core';
import LoginLayout from './LoginLayout';
import { authorize, switchCompany } from '../../oauth-client/Onboard';
import { useTranslation } from 'react-i18next';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import navItems from '../../layout-components/Sidebar/navItems';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

function LoginForm(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const [error, setError] = useState({
    email: false,
    password: false,
  });
  const store = useSelector((state) => state);
  const { t, i18n } = useTranslation();
  const query = new URLSearchParams(props.location.search);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setError({ ...error, [prop]: false });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    try {
      fetch('https://ipapi.co/json/')
        .then((response) => response.json())
        .then((data) => localStorage.setItem('locationInfo', JSON.stringify(data)));
    } catch (error) {}
    if (!CommonUtil.isEmptyString(query.get('code'))) {
      dispatch(getAccessToken(query.get('code'), history));
    } else if (store.security.validToken && !CommonUtil.isEmptyString(store.security.AccJwtToken)) {
      history.push('/acc/dashboard');
    } else {
      setJWTToken(false);
      localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
      localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const user = {
      userName: values.email,
      password: values.password,
    };
    dispatch(
      authorize(
        user,
        (successData) => {
          localStorage.setItem(process.env.REACT_APP_TEMP_TOKEN, 'Bearer ' + successData.data.access_token);
          setJWTToken('Bearer ' + successData.data.access_token);
          switchPreviousCompany();
        },
        (error) => {
          switch (error.code) {
            case 'CAAE0115':
              setError({ ...error, email: true });
              break;
            case 'CAAE0116':
              setError({ ...error, password: true });
              break;
            default:
              setError({ email: true, password: true });
          }
          toast.error(error.message);
        }
      )
    );

    const switchPreviousCompany = () => {
      const decoded = jwt_decode(localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN));
      const lastLoginCompanyId = decoded.lastLoginCompanyId;
      if (!CommonUtil.isEmptyString(lastLoginCompanyId)) {
        const payload = {
          companyCode: '',
          companyId: lastLoginCompanyId,
        };
        dispatch(
          switchCompany(
            payload,
            (successData) => {
              const decoded = jwt_decode('Bearer ' + successData.data.access_token);
              if (decoded.roleCode === 'employee') {
                localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN);
                localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN);
                localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
                setJWTToken(null);
                toast.error(t('COMMON199'));
              } else {
                history.push({
                  pathname: REACT_URL_DASHBOARD,
                });
              }
            },
            (error) => {
              history.push({
                pathname: REACT_VERIFY_COMPANY,
              });
            }
          )
        );
      } else {
        setJWTToken(process.env.REACT_APP_TEMP_TOKEN);
        history.push('/acc/create-company');
      }
    };
    // axios
    //   .post(`/api/v2.0/login`, user)
    //   .then(function(res) {
    //     if (res.data.code === 'CAAI0001') {
    //       setJWTToken('Bearer ' + res.data.data.access_token);
    //       //localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, 'Bearer ' + res.data.data.access_token);
    //       localStorage.setItem(process.env.REACT_APP_TEMP_TOKEN, 'Bearer ' + res.data.data.access_token);
    //       //localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, 'Bearer ' + res.data.data.refresh_token);
    //       history.push(REACT_VERIFY_COMPANY);
    //     } else {
    //       switch (res.data.code) {
    //         case 'CAAE0115':
    //           setError({ ...error, email: true });
    //           break;
    //         case 'CAAE0116':
    //           setError({ ...error, password: true });
    //           break;
    //         default:
    //           setError({ email: true, password: true });
    //       }
    //       toast.error(res.data.message);
    //     }
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //     alert('please check Email and Password');
    //   });
  };
  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handlePaste = (event) => {
    event.preventDefault();
  };
  return !CommonUtil.isEmptyString(query.get('code')) ? (
    <></>
  ) : (
    <>
      <LoginLayout>
        <Grid container spacing={5} alignItems='center'>
          <Grid item></Grid>
          <Grid item xs>
            <Typography className='signin_title' variant='h5' color='textPrimary' align='center'>
              {t('COMMON133')}
            </Typography>
          </Grid>
        </Grid>
        <form noValidate onSubmit={handleSubmit} autoComplete='off'>
          <Grid container alignItems='center'>
            <Grid item>
              <PersonIcon className='login_icons' />
            </Grid>
            <Grid item xs>
              <TextField
                error={error.email}
                className='login_input'
                size='small'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label={t('COMMON134')}
                autoComplete='off'
                autoFocus
                name='email'
                type='text'
                value={values.email}
                onChange={handleChange('email')}
              />
            </Grid>
          </Grid>
          <Grid container alignItems='center'>
            <Grid item>
              <LockIcon className='login_icons' />
            </Grid>
            <Grid item xs>
              <TextField
                error={error.password}
                className='login_input'
                size='small'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label={t('COMMON135')}
                id='password'
                autoComplete='off'
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                inputProps={{
                  onCopy: handleCopy,
                  onPaste: handlePaste,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                        {values.showPassword ? <Visibility className='visibilityIcons' /> : <VisibilityOff className='visibilityIcons' />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems='center'>
            <Grid item xs></Grid>
            <Grid item alignItems='flex-end'>
              <Button color='primary' className='forgot_password' href='/acc/forgot-password'>
                {t('COMMON137')}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={5} alignItems='center'>
            <Grid item></Grid>
            <Grid item xs>
              <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
                {t('COMMON136')}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid container spacing={5} alignItems='center'>
          <Grid item></Grid>
          <Grid item xs>
            <Box mt={3} className='signIn'>
              <hr className='hrline_Left' />
              <Typography variant='body2' color='textPrimary' align='center' style={{ fontSize: '12px' }}>
                {t('COMMON138')}
              </Typography>
              <hr className='hrline_Right' />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={5} alignItems='center'>
          <Grid item></Grid>
          <Grid item xs>
            <Box mt={1} align='center'>
              <Tooltip title={t('COMMON151')}>
                <IconButton className='icon_button' onClick={() => dispatch(redirectLogin())}>
                  <img src={miot_logo_new} alt='iotlogo' className='social_icons' />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={5} alignItems='center'>
          <Grid item></Grid>
          <Grid item xs>
            <Box mt={1} align='center'>
              <Typography variant='body2' color='textPrimary' align='center' style={{ fontSize: '12px' }}>
                {t('COMMON139')}
              </Typography>
              <Link href='/acc/sign-up' className='link'>
                {t('COMMON140')}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </LoginLayout>
    </>
  );
}
export default withRouter(LoginForm);
