import { Dialog, DialogTitle, Box, DialogActions, Button, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import { addLevelPerson } from '../../actions/LevelApiCalls';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DataTable from '../../components/DataTable';
import { toast } from 'react-toastify';
import CommonUtil from '../../components/Util/CommonUtils';
import { getPersons, listPersons } from '../../actions/organization/PersonApiCalls';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomMaterialTable from '../../components/CustomMaterialTable';
import { PERSON_LIST } from '../../actions/organization/types';
import FilterLevelPerson from './FilterLevelPerson';
import { IconButton, Tooltip } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import Headerbar from '../../components/HeaderBar';

const useStyles = makeStyles((theme) => ({
  deptTableStyle: {
    '& table.MuiTable-root': {
      '& thead.MuiTableHead-root': {
        position: 'relative !important',
      },
    },
  },
}));

export default function AddLevelPerson(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const store = useSelector((state) => state);
  const [selectedData, onSelectionChange] = useState([]);
  const [data, setData] = useState([]);

  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(0);

  // eslint-disable-next-line
  const [query, setQuery] = useState({
    page: 0,
    pageSize: 100,
    levelId: props.levelId,
    employeeIds: [],
    ids: [],
  });

  useEffect(() => {
    if (props.open) loadData(page, rowsPerPage, false);
    // eslint-disable-next-line
  }, [props.open]);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.person.personList)) {
      setTotalRecords(store.person.personList.totalCount);
      setPage(store.person.personList.currentPage - 1);
      setData(store.person.personList.employee);
      setTotalPages(store.person.personList.totalPages);
    } else {
      setTotalRecords(0);
      setPage(0);
      setData([]);
    }

    // eslint-disable-next-line
  }, [store.person.personList]);

  const loadData = (page, rowsPerPage, isFilter) => {
    dispatch(listPersons(props.levelId, page + 1, filter, rowsPerPage, true, isFilter ? [] : []));
  };

  const [filter, setFilter] = useState({
    firstName: '',
    lastName: '',
    employeeCode: '',
    email: '',
    phone: '',
  });

  const defaultFilter = {
    firstName: '',
    lastName: '',
    employeeCode: '',
    email: '',
    phone: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      employeeCode: filterData.employeeCode,
      email: filterData.email,
      phone: filterData.phone,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setPage(0);
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    dispatch({
      type: PERSON_LIST,
      payload: {},
    });
    if (props.open) loadData(0, rowsPerPage, true);
  }, [filter]);

  const handleClose = () => {
    onSelectionChange([]);
    setPage(0);
    dispatch({
      type: PERSON_LIST,
      payload: {},
    });
    resetFilter();
    props.onClose(false);
  };

  const handleSubmit = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var ids = '';
    var employeeIds = '';
    // eslint-disable-next-line
    data.map((record, index) => {
      if (index === data.length - 1) {
        ids = ids + record.id;
        employeeIds = employeeIds + record.id;
      } else {
        ids = ids + record.id + ',';
        employeeIds = employeeIds + record.id + ',';
      }
    });

    var payload = {
      personIds: ids,
      levelId: props.levelId,
      deptList: [],
    };

    dispatch(
      addLevelPerson(payload, (data) => {
        props.onSubmit();
        handleClose();
      })
    );
  };

  const columns = [
    {
      field: 'firstName',
      title: t('COMMON020'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'white',
      },
    },
    { field: 'lastName', title: t('COMMON021'), editable: 'never' },
    { field: 'employeeCode', title: t('COMMON045'), editable: 'never' },
    // { title: 'Action' },
  ];

  const actions = [
    {
      icon: () => <AddToPhotosIcon color='secondary' />,
      tooltip: t('COMMON009'),
      position: 'row',
      onClick: (event, rowData) => {
        handleSubmit([rowData]);
      },
    },
  ];

  const handleChangePage = (newPage) => {
    if (newPage < totalPages) {
      setPage(newPage);
      loadData(newPage, rowsPerPage, false);
    }
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize, false);
  };

  const options = {
    actionsColumnIndex: -1,
    search: true,
    selection: true,
    toolbar: true,
    paging: false,
    pageSize: rowsPerPage,
    minBodyHeight: '350',
    maxBodyHeight: '350',
    initialPage: 1,
    headerStyle: {
      backgroundColor: 'white',
      color: 'black',
    },
    actionsCellStyle: {
      backgroundColor: 'white',
      color: 'black',
    },
  };
  const classes = useStyles();
  return (
    <Dialog maxWidth='md' fullWidth open={props.open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
      <DialogTitle id='form-dialog-title'>
        <Box display='flex'>
          <Box flexGrow={1}>{t('LEVEL004')}</Box>
          <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* <Box display='flex' className='custom-container-header' alignItems='center'>
          <Headerbar className='custom-container-header'>
            <Box>
              <FilterLevelPerson applyFilter={(data) => applyFilter(data)} />
            </Box>
            <Box>
              <Tooltip title={t('COMMON002')}>
                <IconButton style={{ color: '#3d4977' }} size='small' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
                  <CachedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Headerbar>
        </Box> */}
        <div className={classes.deptTableStyle}>
          <InfiniteScroll dataLength={data.length} next={() => handleChangePage(page + 1)} hasMore={true} height={350}>
            <DataTable
              id='scrollableDiv'
              columns={columns}
              actions={actions}
              data={data}
              options={options}
              onSelectedRoles={(rows) => onSelectionChange(rows)}
              count={totalRecords}
              page={page}
              onChangePage={(newPage) => handleChangePage(newPage)}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
            />
          </InfiniteScroll>
          {/* <CustomMaterialTable totalPages={totalPages} columns={columns} data={data} onScrollEnd={(page) => handleChangePage(page)} page={page} /> */}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSubmit(selectedData)} color='primary' variant='contained' size='small'>
          {t('COMMON007')}
        </Button>
        <Button onClick={handleClose} color='primary' variant='contained' size='small'>
          {t('COMMON008')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
