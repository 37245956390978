import axios from "axios";
import { GET_LINKAGE_LIST, GET_ERRORS, GET_LINKAGE_NORULES } from "./types";
import { toast } from 'react-toastify';
import { JAVA_URL_LINKAGE, JAVA_URL_NORULES_BY_DEVID } from "./EndPoints";

export const getLinkageList = (name, doorName, query) => async dispatch => {
	const res = await axios.get(JAVA_URL_LINKAGE + '?name=' + name + '&doorName=' + doorName + '&page=' + query.page + '&size=' + query.pageSize);
	dispatch({
		type: GET_LINKAGE_LIST,
		payload: res.data.data
	});
};
export const getLinkageDoorListBydeviceId = (deviceId) => async dispatch => {
	const res = await axios.get("");
	dispatch({
		type: GET_LINKAGE_LIST,
		payload: res.data.data
	});
};

export const adddLinkage = (accLinkageDTO) => async dispatch => {

	try {
		const res = await axios.post(JAVA_URL_LINKAGE, accLinkageDTO);
		if (res.status === 200) {
			toast.success("lINKAGE added successfully");
			dispatch({
				type: GET_ERRORS,
				payload: {}
			});
		}
	} catch (err) {
		dispatch({
			type: GET_ERRORS,
			payload: err.response.data.data
		});
	}
};
export const deleteLinkage = (ids, history) => async dispatch => {
	const res = await axios.delete(JAVA_URL_LINKAGE + "/" + ids);
	if (res.status === 200) {
		toast.success("accLinkage deleted successfully");
		var name= '';
		var doorName = '';
		var query = {
			page: '',
			pageSize: ''
		}
		dispatch(getLinkageList(name, doorName, query))
		dispatch({
			type: GET_ERRORS,
			payload: {}
		});
	}

};
export const getNorulesForDevId = (devId) => async dispatch => {
	const res = await axios.get(JAVA_URL_NORULES_BY_DEVID + devId);
	dispatch({
		type: GET_LINKAGE_NORULES,
		payload: res.data.data
	});
};