import React, { forwardRef } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next'; 
import { connect } from "react-redux"; 
import MaterialTable from 'material-table';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { getSites }  from '../../actions/DashBoardActions';

const styles = theme => ({
	form: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: 250
		}
	},
	formcontrol: {
		margin: theme.spacing(1),
		width: 250
	},
});


// const DialogTitle = withStyles(styles)(props => {
// 	const { children, classes, onClose, ...other } = props;
// 	return (
// 		<MuiDialogTitle disableTypography className="dilog-form-header" {...other}>
// 			<Typography variant="h6">{children}</Typography>
// 		</MuiDialogTitle>
// 	);
// });

//const rightLinks = <TransferList></TransferList>

class ShowSite extends React.Component {

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
		this.handleDialogOK = this.handleDialogOK.bind(this);
		this.state = {
			dialogText: '',
			isDialogOpen: false,
			isChecked: false,
			sites: [],
		}
	}

componentDidMount(){
  this.props.getSites(); 
  this.setState({sites: this.props.sitesList})
}
componentDidUpdate(prevProps) 
{
	if(this.props.sitesList!==prevProps.sitesList){
		this.setState({sites:this.props.sitesList});
	}
}
	onChange(e) {
		alert(e.target.value)
	}

	handleDialogOK() {
		console.log('Clicked OK');
		this.setState({
			isDialogOpen: false
		});
	}

	handleDialogClose() {
		console.log('Clicked Close');
		this.setState({
			isDialogOpen: false
		});
	}

	handleChange(e) {
		const target = e.target;
		const value = target.checked;

		this.setState({
			isChecked: value,
			isDialogOpen: true
		}, () => { console.log('Open Dialog') });
	}



	render() {

 

		const tableIcons = {
			Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
			Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
			DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
			Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
			Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
			FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
			LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
			NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
			ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
			SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
			ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
			ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
		};
		const { t } = this.props;
		const siteColumns = [
			{ field: 'name', title: t("COMMON081") },
			{ field: 'addressDO.city', title: t("COMMON082") },
			{ field: 'addressDO.latitude', title: t("COMMON083") },
			{ field: 'addressDO.longitude', title: t("COMMON084") }
		];

		// const siteData = [
		// 	{ siteName: 'Site A', location: 'Bangalore' },
		// 	{ siteName: 'Site B', location: 'Bangalore' },
		// 	{ siteName: 'Site C', location: 'Bangalore' },
		// 	{ siteName: 'Site D', location: 'Bangalore' },
		// 	{ siteName: 'Site E', location: 'Bangalore' },
		// ];

		return (
			<MaterialTable
				columns={siteColumns}
				data={this.state.sites ? this.state.sites : []}
				icons={tableIcons}
				options={{
					showTitle: false,
					toolbar:false,
					paging:false,
				}}
				localization={{
						body: {
							emptyDataSourceMessage: t('dataTableEmptyList'),
						},
					}}
			/>
		);
	}
}

 const mapStateToProps=(state)=>({
  sitesList: state.dashBoard.sitesList
 })


export default compose(withTranslation(), withStyles(styles, { withTheme: true }),connect(mapStateToProps, 
	{  getSites}))(ShowSite);
 
	 