import axios from "axios";
import { toast } from 'react-toastify';
import { GET_ERRORS, GET_ACCREADER_LIST, UPDATE_ACCREADER} from "./types";
import {  JAVA_URL_READER } from "./EndPoints";

export const getAccReaders = (name,doorName,query) => async dispatch => {
	const res = await axios.get(JAVA_URL_READER+'?name='+ name +'&doorName='+doorName +'&page=' + query.page + '&size=' + query.pageSize);
	dispatch({
		type: GET_ACCREADER_LIST,
		payload: res.data.data?res.data.data: ''
	});
};

export const updateAccReader = (accReader) => async dispatch => {
	try {
		//alert(JSON.stringify(accReader))
		const res = await axios.put(JAVA_URL_READER, accReader);
		toast.success("accreader updated successfully"); 
		dispatch({
			type: UPDATE_ACCREADER,
			payload: res.data.data
		});

	} catch (err) {
		dispatch({
			type: GET_ERRORS,
			payload: err.response.data.data
		});
	}
};
