import { Box, Grid, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getSiteTypes, deleteSiteTypes, filterSiteType } from '../../actions/organization/SiteTypeApiCalls';
import EditIcon from '@material-ui/icons/Edit';
import AddSiteType from './AddSiteType';
import ConfirmModal from '../../components/ConfirmModal';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FilterSiteType from './FilterSiteType';
import CommonUtil from '../../components/Util/CommonUtils';
import Pagination from '../../components/Pagination';
import { Tooltip, Fab } from '@material-ui/core';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Breadcrumb from '../../components/Breadcrumb';
// import { ReactComponent as Add } from '../../assets/icons/add.svg';
// import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
// import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
// import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';


export default function SiteType() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});

  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [state, setState] = React.useState({
    id: '',
    name: '',
    code: '',
    description: '',
  });

  useEffect(() => {
    loadSiteTypes(page, rowsPerPage);
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.siteType.siteTypes)) {
      setTotalRecords(store.siteType.siteTypes.totalCount);
      setPage(store.siteType.siteTypes.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.siteType.siteTypes.siteTypes);
  }, [store.siteType.siteTypes]);

  const loadSiteTypes = (page, rowsPerPage) => {
    dispatch(filterSiteType('','',page + 1, rowsPerPage));
    onSelectionChange([]);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(getSiteTypes(newPage + 1, rowsPerPage));
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    dispatch(getSiteTypes(1, pageSize));
  };

  const openAddSiteTypeDialog = () => {
    setUpdateObject(state);
    setOpenEditDialog(true);
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];
    data.map((record, index) => {
      idsToDelete[index] = record.id;
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteSiteTypes(idsToDelete, (data) => {
                  handleError(data);
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handleError = (data) => {
    if(data.code=='LMSI6006'){
      toast.success(data.message);
      loadSiteTypes(page, rowsPerPage);
    }else{
      toast.warn(t('COMMON217'));
      loadSiteTypes(page, rowsPerPage);
    }
   
  };

  const breadcrumb = [{ path: '', name: t('STYPE001') }];

  const columns = [
    {
      field: 'name',
      title: t('COMMON044'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'code', title: t('COMMON045'), editable: 'never' },
    { field: 'description',
     title: t('STYPE009'),
      editable: 'never',
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
  ];

  const options = { actionsColumnIndex: -1, search: false, selection: false, toolbar: false, paging: true, minBodyHeight: '40vh', initialPage: 1, pageSize: rowsPerPage,rowStyle: {
    overflowWrap: 'break-word'}};
  const actions = [
    {
      icon: () => <EditOutlinedIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <DeleteOutlineIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Tooltip title={t('COMMON009')}>
          {/* <IconButton color='secondary' aria-label={t('COMMON009')} onClick={openAddSiteTypeDialog}>
            <Add style={{ height: 35, width: 35 }} />
          </IconButton> */}
          <Fab color='secondary' size='small' aria-label={t('COMMON009')} onClick={openAddSiteTypeDialog}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Breadcrumb>
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => loadSiteTypes(0, rowsPerPage)}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {/* <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON004')}>
            <IconButton color='secondary' aria-label={t('COMMON004')} onClick={() => deleteRecord(selectedData)}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box> */}
        <Box p={1}>
          <FilterSiteType rowsPerPage={rowsPerPage} />
        </Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
      {openEditDialog && (
        <AddSiteType
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadSiteTypes(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
