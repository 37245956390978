import { Box, Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getReaders } from '../../actions/ReaderApiCalls';
import EditIcon from '@material-ui/icons/Edit';
import { REACT_URL_READER_LIST } from '../../actions/EndPoints';
import Headerbar from '../../components/HeaderBar';
import EditReader from '../Reader/EditReader';
import FilterReader from './FilterReader';
import CommonUtil from '../../components/Util/CommonUtils';
// import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

export default function Reader() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [readers, setReaders] = useState([]);
  // eslint-disable-next-line
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateReader, setUpdateReader] = useState({});

  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: 5,
    name: '',
    doorName: '',
  });

  // useEffect(() => {
  //   loadReaders(page, rowsPerPage);
  //   // eslint-disable-next-line
  // }, []);

  const [filter, setFilter] = useState({
    name: '',
    doorName: '',
  });

  const defaultFilter = {
    name: '',
    doorName: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      name: filterData.name,
      doorName: filterData.doorName,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadReaders(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    onSelectionChange([]);
    if (!CommonUtil.isEmpty(store.reader.readers)) {
      setTotalRecords(store.reader.readers.totalConunt);
      setPage(store.reader.readers.curPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setReaders(store.reader.readers.readers);
    // eslint-disable-next-line
  }, [store.reader.readers]);

  const loadReaders = (page, rowsPerPage) => {
    dispatch(getReaders(filter.name, filter.doorName, page + 1, rowsPerPage));
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadReaders(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadReaders(0, pageSize);
  };

  const breadcrumb = [
    // { path: REACT_URL_READER_LIST, name: t('DEVI0013') },
    { path: REACT_URL_READER_LIST, name: t('READER001') },
  ];

  const columns = [
    {
      field: 'name',
      title: t('COMMON014'),
      tooltip: t('READER004'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'accDoorDO.name', title: t('DOORI001'), tooltip: t('DOORI003'), editable: 'never' },
    { field: 'readerNo', title: t('COMMON011'), tooltip: t('COMMON131'), editable: 'never' },
    { field: 'commType', title: t('COMMON061'), tooltip: t('COMMON012'), editable: 'never' },
    { field: 'commAddress', title: t('COMMON062'), tooltip: t('COMMON013'), editable: 'never' },
    //{ field: 'readerState', title: t('READER003'), editable: 'never' },
    { field: 'readerState', title: t('COMMON063'), tooltip: t('COMMON132'), editable: 'never' },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage
  };

  const actions = [
    {
      icon: () => <EditOutlinedIcon color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateReader(rowData);
        setOpenEditDialog(true);
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb} />
      {/* <Headerbar title={t('READER001')} /> */}
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Grid container justify='flex-end' style={{ padding: '12px' }}>
          <FilterReader page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Grid>
      </Toolbar>
      <DataTable
        columns={columns}
        data={readers}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <EditReader
          reader={updateReader}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadReaders(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
