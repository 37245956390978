import axios from 'axios';
import { JAVA_API_ACC_LEVEL_DEPT_DELETE, JAVA_API_DELETE_DEPARTMENT_V3, JAVA_API_DEPARTMENT_SEARCH_V3, JAVA_API_DEPARTMENT_V3 } from '../ApiEndPoints';

import { JAVA_API_DEPARTMENT, JAVA_API_DEPARTMENT_SEARCH, JAVA_API_DEPARTMENT_V31 } from './ApiEndPoints';
import { DEPARTMENT_LIST } from './types';

export const getDepartments = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_DEPARTMENT_V31 + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&isPageable=' + isPageable);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      if (isPageable) {
        dispatch({
          type: DEPARTMENT_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: DEPARTMENT_LIST,
          payload: [],
        });
      }
    }
  } catch { }
};

export const filterDepartment = (name, code, page, size) => async (dispatch) => {
  try {
    let query = '';
    if (name !== '') {
      query += '&name=' + name;
    }
    if (code !== '') {
      query += '&code=' + code;
    }
    const res = await axios.post(JAVA_API_DEPARTMENT_SEARCH_V3 + '?pageNumber=' + page + '&pageSize=' + size + query);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      dispatch({
        type: DEPARTMENT_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: DEPARTMENT_LIST,
        payload: [],
      });
    }
  } catch { }
};

export const updateDepartment = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === '' ? true : false;

    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_DEPARTMENT_V3, payload);
    } else {
      res = await axios.put(JAVA_API_DEPARTMENT_V3 + '/' + payload.id, payload);
    }
    callBack(res.data);
  } catch { }
};

export const deleteDepartment = (ids, callBack) => async (dispatch) => {
  try {
    const deleteObj = {};
    deleteObj.idsOrCodes = ids;
    deleteObj.deleteChild = false;
  //  alert(ids);
    const res = await axios.delete(JAVA_API_DELETE_DEPARTMENT_V3 + "/" + ids);
    callBack(res.data);
  } catch { }
};
