import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { REACT_URI_DASHBOARD, REACT_URI_LANDING, REACT_URI_SWITCH_COMPANY } from '../../actions/organization/EndPoints';
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import CommonUtil from '../../components/Util/CommonUtils';
import { toast } from 'react-toastify';
import './custom.css';
import { Link, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import { Link as RouterLink } from 'react-router-dom';
import { REACT_URL_DASHBOARD, REACT_VERIFY_COMPANY } from '../../actions/EndPoints';
import { JAVA_API_COMPANY } from '../../actions/organization/ApiEndPoints';
import axios from 'axios';
import setJWTToken from '../../oauth-client/setJWTToken';
import { SET_CURRENT_USER } from '../../actions/organization/types';
import jwt_decode from 'jwt-decode';
import LoginLayout from './LoginLayout';
import { countryList } from '../../components/Util/CountryList';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
}));

export default function CreateCompanyForm(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const store = useSelector((state) => state);

  useEffect(() => {
    // if (store.security.validToken && !CommonUtil.isEmptyString(store.security.AccJwtToken)) {
    //   history.push('/acc/dashboard');
    // }

    if (!localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN)) history.push('/acc/login');
  }, []);

  const countries = countryList;

  const [state, setState] = React.useState({
    name: '',
    code: '',
    email: '',
    phone: '',
    registrationNumber: '',
    country: '',
  });

  const [error, setError] = React.useState({
    name: false,
    code: false,
    email: false,
    phone: false,
    registrationNumber: false,
    country: '',
  });

  const handleCountryChange = (event) => {
    if(event === null){
      setState({
        ...state,
        country:''
      });
    }
    else{
      setState({
        ...state,
        country: event.country,
      });
    }
    setError({
      country: false,
    });
  };
  
  const handleChange = (event) => {

    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    
    const name = event.target.name;
    if (name === 'phone') {
      if (!CommonUtil.isEmptyString(event.target.value) && event.target.value.match(/^[0-9 ]+$/)) {
        setState({
          ...state,
          [name]: event.target.value,
        });
      } else if (CommonUtil.isEmptyString(event.target.value)) {
        setState({
          ...state,
          [name]: event.target.value,
        });
      }
    } else {
      setState({
        ...state,
        [name]: event.target.value,
      });
    }
    setError({
      ...error,
      [name]: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      let config = {
        headers: {
          Authorization: localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN),
        },
      };
      axios
        .post(JAVA_API_COMPANY, state, config)
        .then(function (res) {
          if (res.data.code === 'UASI0011') {
            localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN);
            const accessToken = res.data.data.access_token;
            // const refreshToken = res.data.data.refresh_token;
            setJWTToken('Bearer ' + res.data.data.access_token);
            localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, 'Bearer ' + res.data.data.access_token);
            localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, 'Bearer ' + res.data.data.refresh_token);
            const decoded = jwt_decode(accessToken);

            localStorage.setItem('companyId', decoded.companyId);
            localStorage.setItem('companyName', decoded.companyName);
            localStorage.setItem('companyCode', state.code);

            dispatch({
              type: SET_CURRENT_USER,
              payload: decoded,
            });
            history.push({
              pathname: REACT_URL_DASHBOARD,
            });
          } else {
            switch (res.data.code) {
              case 'CASE0708':
                setError({ ...error, code: true });
                break;
              case 'UASE0006':
                setError({ ...error, name: true });
                break;
              case 'UASE0012':
                setError({ ...error, registrationNumber: true });
                break;
              case 'UASE0025':
                setError({...error,code:true})
                break
              case 'UASE0060':
                setError({ ...error, name: true });
                break
              default:
                setError({ ...error });
            }
            toast.error(res.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          alert('Please check once');
        });

      //   dispatch(
      //     createNewCompany(state, (data) => {
      //       handlError(data);
      //     })
      //   );
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.country)) {
      setError({ ...error, country: true });
      toast.error(t('COMMON162'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.name)) {
      setError({ name: true });
      toast.error(t('COMMON107'));
      return false;
    }


    if (!CommonUtil.isValidCode(state.code)) {
      setError({ code: true });
      toast.error(t('COMP0005'));
      return false;
    }
    if (!CommonUtil.isAlphaNumeric(state.registrationNumber)) {
      setError({ registrationNumber: true });
      toast.error(t('COMP0006'));
      return false;
    }
    if (!CommonUtil.isValidEmail(state.email)) {
      setError({ email: true });
      toast.error(t('COMP0007'));
      return false;
    }
    if (!CommonUtil.isValidMobile(state.phone)) {
      setError({ phone: true });
      toast.error(t('COMP0008'));
      return false;
    }
    return true;
  };

  const handlError = (data) => {
    alert(data.code);
    switch (data.code) {
      case 'UASE0006':
        setError({ name: true });
        break;
      case 'UASE0011':
        setError({ code: true });
        break;
      case 'UASE0012':
        setError({ registrationNumber: true });
        break;
      case 'UASI0011':
        history.push(REACT_URI_DASHBOARD);
        break;
      default:
        break;
    }

    if (data.code !== 'UASI0011') {
      if (data.code !== 'UASI0006') {
        toast.error(data.message);
      } else {
        toast.success(t('COMP0010') + data.data.code + t('COMP0011'));
      }
    } else {
      alert('else' + data.code);
      history.push({
        pathname: REACT_URL_DASHBOARD,
      });
    }
  };

  return (
    <>
      <LoginLayout>
        <Grid container spacing={5} alignItems='center'>
          <Grid item></Grid>
          <Grid item xs>
            <Typography className='signin_title' variant='h5' color='textPrimary' align='center'>
              {t('COMMON150')}
            </Typography>
          </Grid>
        </Grid>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item>
              <BusinessIcon className='login_icons' />
            </Grid>

            <Grid item xs>
            <Autocomplete
                        clearText={t('COMMON216')}
                        openText={t('COMMON169')}
                        closeText={t('COMMON170')}
                        noOptionsText={t('COMMON214')}
                        fullWidth
                        name='country'
                        options={countries}
                        getOptionLabel={(option) => option.country}
                        onChange={(event, newValue)=>{
                          handleCountryChange(newValue);
                        }}
                        value={{country:state.country}}
                        renderInput={(params) => <TextField {...params}
                          name='country'
                          className='login_input'
                          variant='outlined'
                          fullWidth 
                          label={t('ADD003')+' *'}
                          error={error.country}
                          size='small'/>}
                    />
              {/* <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>{t('COMMON122') + ' *'}</InputLabel>
                <Select
                  fullWidth
                  className='m-0 mb-2 text-left'
                  helperText=''
                  label={t('COMMON122') + ' *'}
                  name='country'
                  value={state.country}
                  onChange={handleChange}
                  error={error.country}
                >
                  <MenuItem value=''>None</MenuItem>
                  {countries ? (
                    countries.map((country, index) => (
                      <MenuItem key={index} value={country.label}>
                        <Typography variant='inherit' noWrap>
                          {country.label}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl> */}
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems='center'>
            <Grid item>
              <BusinessIcon className='login_icons' />
            </Grid>
            <Grid item xs>
              <TextField
                error={error.name}
                className='login_input'
                size='small'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='companyName'
                label={t('COMMON044')}
                autoComplete='off'
                name='name'
                type='text'
                value={state.name}
                onChange={handleChange}
                inputProps ={{
                  maxlength:100
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems='center'>
            <Grid item>
              <BusinessIcon className='login_icons' />
            </Grid>
            <Grid item xs>
              <TextField
                error={error.code}
                className='login_input'
                size='small'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='companyCode'
                label={t('COMMON045')}
                autoComplete='off'
                name='code'
                type='text'
                value={state.code}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems='center'>
            <Grid item>
              <ApartmentIcon className='login_icons' />
            </Grid>
            <Grid item xs>
              <TextField
                error={error.registrationNumber}
                className='login_input'
                size='small'
                variant='outlined'
                margin='normal'
                fullWidth
                id='registerNumber'
                label={t('COMP0002')}
                autoComplete='off'
                name='registrationNumber'
                type='text'
                value={state.registrationNumber}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems='center'>
            <Grid item>
              <EmailIcon className='login_icons' />
            </Grid>
            <Grid item xs>
              <TextField
                error={error.email}
                className='login_input'
                size='small'
                variant='outlined'
                margin='normal'
                fullWidth
                id='email'
                label={t('COMMON042')}
                autoComplete='off'
                name='email'
                type='email'
                value={state.email}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems='center'>
            <Grid item>
              <LocalPhoneIcon className='login_icons' />
            </Grid>
            <Grid item xs>
              <TextField
                error={error.phone}
                className='login_input'
                size='small'
                variant='outlined'
                margin='normal'
                fullWidth
                id='phoneNumber'
                label={t('COMMON043')}
                autoComplete='off'
                name='phone'
                type='text'
                value={state.phone}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={5} alignItems='center'>
            <Grid item></Grid>
            <Grid item xs>
              <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
                {t('COMMON007')}
              </Button>
            </Grid>
          </Grid>
          <Grid container alignItems='center'>
            <Grid item></Grid>
            <Grid item xs>
              <Box align='right'>
                <Link component={RouterLink} to={'/acc/login'} className='link'>
                  {t('COMMON040')}
                </Link>
              </Box>
            </Grid>
          </Grid>
        </form>
      </LoginLayout>
    </>
  );
}
