import { GET_ACCDEVICE, GET_ACCDEVICES,UPDATE_ACCDEVICES,GET_FILTER_ACCDEVICES, GET_ACCRESPONSE,GET_ADMSACCDEVICES,GET_ACCDEVICE_READERSTATE } from "../actions/types";

const initialState = {
    accDeviceList : [],
    accDevice: {},
    response : false,
    admsAccdeviceData:[], 
    filterDeviceList: [],
};
 
export default function (state = initialState, action) {
	switch (action.type) {
        case GET_ACCDEVICE:
			return {
                ...state,
				accDevice: action.payload
            };
		case GET_ACCDEVICES:
			return {
                ...state,
				accDeviceList: action.payload
            };
		case UPDATE_ACCDEVICES:
			return {
                ...state 
				 
            };
        case GET_ACCRESPONSE:
            return {
                ...state,
                response: action.payload
            };
            
        
        case GET_ADMSACCDEVICES:
            return {
                ...state,
                admsAccdeviceData: action.payload
            };
        case GET_FILTER_ACCDEVICES:
            return {
                ...state,
                filterDeviceList: action.payload
            };
         case GET_ACCDEVICE_READERSTATE:
			return {
                ...state,
				accDevice: action.payload
            };
            
		default:
			return state;
	}
}