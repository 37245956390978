import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box,  Grid,  Typography,FormControl,InputLabel,MenuItem,Select} from '@mui/material';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import { JAVA_URL_TIME_ZONE_LIST, JAVA_URL_SITE_LIST } from '../../actions/EndPoints';
import Axios from 'axios';
import { resetErrors, updateDevice } from '../../actions/DeviceApiCalls';
import CommonUtil from '../../components/Util/CommonUtils';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';

export default function EditDevice(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [open, setOpen] = React.useState(props.open);
  const [device, setDevice] = React.useState(props.device);
  const [timezones, setTimezones] = React.useState([]);
  const [zones, setSites] = React.useState([]);
  const [state, setState] = React.useState({});

  const [error, setError] = React.useState({
    deviceAlias: false,
    timeZone: false,
    siteId: false,
    iconType: false,
  });

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 640,
      },
    },
  };

  useEffect(() => {
    setOpen(props.open);
    setDevice(props.device);
    setState({
      id: props.device.id,
      deviceAlias: props.device.alias,
      timeZone: props.device.timeZone,
      siteId: props.device.siteId,
      iconType: props.device.iconType,
    });
  }, [props.open, props.device]);

  useEffect(() => {
    loadTimeZones();
    loadSites();
  }, []);

  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.deviceError)) {
      const { deviceError } = store.errors;
      switch (deviceError.code) {
        case 'CASE0070':
          setError({ deviceAlias: true });
          break;
        case 'CASE0101':
          setError({ deviceAlias: true });
          break;
        default:
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.errors.deviceError]);

  const loadTimeZones = () => {
    Axios.get(JAVA_URL_TIME_ZONE_LIST).then((response) => {
      setTimezones(response.data.data);
    });
  };

  const loadSites = () => {
    Axios.get(JAVA_URL_SITE_LIST + '?isPageable=false').then((response) => {
      if (response.data.code === 'LMSI0000') setSites(response.data.data.site);
    });
  };

  const handleClose = () => {
    dispatch(resetErrors());
    props.onClose(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    dispatch(
      updateDevice(state, (data) => {
        handleClose();
      })
    );
  };

  return (
    <div>
      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('DEVI0022')}</Box>
            <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.deviceAlias}
                    name='deviceAlias'
                    label={t('DEVI0002')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    defaultValue={device.alias}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth disabled label={t('DEVI0003')} helperText='' variant='outlined' size='small' defaultValue={device.sn} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth disabled label={t('DEVI0004')} helperText='' variant='outlined' size='small' defaultValue={device.ipAddress} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('DEVI0023')}</InputLabel>
                    <Select
                      labelId='demo-simple-select-outlined-label-iconType'
                      id='demo-simple-select-outlined-iconType'
                       
                      label={t('DEVI0023')}
                      name='iconType'
                      onChange={handleChange}
                      value={state.iconType}
                      defaultValue={state.iconType}
                    >
                    <MenuItem value='1' selected>{t('DEVI0059')}</MenuItem>
                    <MenuItem value='2'>{t('DEVI0060')}</MenuItem>
                    <MenuItem value='3'>{t('DEVI0061')}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('DEVI0024')}</InputLabel>
                    <Select
                      labelId='demo-simple-select-outlined-label-timeZone'
                      id='demo-simple-select-outlined-timeZone'
                      label={t('DEVI0024')}
                      name='timeZone'
                      onChange={handleChange}
                      value={state.timeZone}
                      defaultValue={state.timeZone}
                      MenuProps={MenuProps}
                    >
                        <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {Object.entries(timezones).map(([key, val], index) => (
                        <MenuItem key={index} value={val} style={{ maxWidth: '100%' }}>
                          {key}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth required>
                    <InputLabel>{t('DEVI0025') }</InputLabel>
                    <Select
                      labelId='demo-simple-select-outlined-label-siteId'
                      id='demo-simple-select-outlined-siteId'
                      MenuProps={MenuProps}
                      label={t('DEVI0025')}
                      name='siteId'
                      onChange={handleChange}
                      value={state.siteId}
                      defaultValue={state.siteId}
                    >
                      {/* <MenuItem value={'none'}>
                        <em>{t('none')}</em>
                      </MenuItem> */}
                      {zones ? (
                        zones.map((zones, index) => (
                          <MenuItem key={index} value={zones.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {zones.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
