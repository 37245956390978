import { Button ,Grid} from '@material-ui/core';
import React, { Fragment, forwardRef } from 'react';
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import { toast } from 'react-toastify';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { compose } from 'redux'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FilterListIcon from '@material-ui/icons/FilterList';
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { getAccAuxOuts, updateAccAuxOut,remoteOpenControl,remoteNormallyOpenControl,getAuxOutStatus } from '../../actions/AccAuxOutActions';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as Refresh } from "../../assets/cloudaccess-svg/Refresh.svg";
import { ReactComponent as RemoteNormallyOpen } from "../../assets/cloudaccess-svg/Remote Open.svg";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import TextField from "@material-ui/core/TextField";
import { TablePagination } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
const styles = theme => ({
	form: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: 250
		}
	},
	formcontrol: {
		width: 150
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	}
});

const DialogTitle = withStyles(styles)(props => {
	const { children, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className="dilog-filter-head" {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className="dilog-form-closeButton" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});
class AccAuxOutList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			name: "",
			deviceAlias: '',
			remark: "",
			auxOuts: [],
			deviceName: this.props.deviceName,
			auxNo: this.props.auxNo,
			selectDeviceList: [],
			passageModeTimeZone: this.props.passageModeTimeZone,
			updateAccAuxOut: [],
			//remoteOpenControl: [],
			operationType: "",
			openInterval: "",
			errorMessages: [],
			selectedIds: [],
			errors: {},
			showError: '',
			showSuccessAlert: false,
			resetAlert: false,
			filter:  false,
			accAuxOutList: [],
			query: {
				page: 0,
				pageSize: 5,
			  },
			  totalCount: 0,
			  pageChanged: true,
			  remoteOpening: false,
			  remoteClosing: false,
			  remoteNormallyOpen: false
		};
		this.loadAuxOutList = this.loadAuxOutList.bind(this);
		this.handleRowUpdate = this.handleRowUpdate.bind(this);
		this.onCloseAlert = this.onCloseAlert.bind(this);
		//this.remoteOpenControl = this.remoteOpenControl.bind(this);
	}
   
	componentDidMount() {
		//this.loadAuxOutList();
		this.fetchData();
		//this.setState({ readers: this.props.accAuxOuts });
	}
	remoteOpeningDialog = () => {
	
		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		}
		else{
			var ids = []; 
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			this.props.getAuxOutStatus(ids,"openAuxOut"); 
			 
		this.setState({ remoteOpening: true })
		}
	}
	remoteOpeningClose = () => {
		this.setState({ remoteOpening: false })
	}
	enableClosingDialog = () => {
	
		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		}
		else{
			var ids = []; 
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			this.props.getAuxOutStatus(ids,"closeAuxOut"); 
		    this.setState({ remoteClosing: true })
		}
	}
	remoteNormallyOpenDialog = ( ) => { 

		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		}else {
			var ids = []; 
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			this.props.getAuxOutStatus(ids,"auxOutNormalOpen");
           this.setState({ remoteNormallyOpen: true})
		}
	}
	remoteNormallyClose = () => {
		this.setState({ remoteNormallyOpen: false})
	}
	filterOpenDialog=()=>{
		this.setState({filter: true})
	}
	closingAuxOutDialogClose=()=>
	{
		this.setState({ remoteClosing: false })
	}
	remoteOpenControl = () => {  

		this.props.remoteOpenControl(this.props.auxOutStatus.retIds,"openAuxOut",this.state.openInterval);
	}
	remoteNormallyOpenControl = () => { 
        
		this.props.remoteNormallyOpenControl(this.props.auxOutStatus.retIds,"auxOutNormalOpen");
	}
	remoteCloseControl = () => { 
        
		this.props.remoteNormallyOpenControl(this.props.auxOutStatus.retIds,"auxOutNormalOpen");
	}
	filterClose=()=>{
		const { query } = this.state;
		let name= '';
		let deviceAlias= '';
		this.props.getAccAuxOuts(name,deviceAlias,query,this.state.history)
	       this.setState({filter: false,name: '',
	                                deviceAlias : '',})
	}
	filterApply=()=>{
		const { query } = this.state;
			this.props.getAccAuxOuts(this.state.name,this.state.deviceAlias,query,this.state.history)
			.then(() => {
				this.setState({ accAuxInList: this.props.accAuxInList }); 
			}) 
			this.setState({filter: false,
				name: '',
				deviceAlias : '',
			   });
		}
	fetchData=(additionalState)=>
	{
	  const { query } = this.state;
	  this.props.getAccAuxOuts(this.state.name,this.state.deviceAlias,query,this.state.history).then(() => {
		this.setState({ ...additionalState }); 
	  })
	}
	componentDidUpdate(prevProps) {
		if (this.props.response !== prevProps.response) {
			this.setState({ showSuccessAlert: this.props.response })
		} 
		if(this.props.accAuxOutList!== undefined)
		{  
		if (this.props.accAuxOutList !== prevProps.accAuxOutList) { 
			this.setState({ accAuxOutList: this.props.accAuxOutList.item })
		}
	}
		if(this.props.accAuxOutList !== undefined)
       {
		if (this.props.accAuxOutList !== prevProps.accAuxOutList) { 
		if (this.props.accAuxOutList.totalCount !== undefined) {
			this.setState({ totalCount: this.props.accAuxOutList.totalCount });
		} else {
			this.setState({ totalCount: 0 });
		}
	
	
		}
    }
	}
	onChange=(event)=>{
		this.setState({[event.target.name]: event.target.value})
	}
	handleChange = (event, rowdata) => {

		//const target = event.target;
		//const value = target.checked;

		this.setState({
			name: rowdata.name,
			deviceAlias: rowdata.deviceAlias,
			id: rowdata.id,
			doorNo: rowdata.doorNo,
			lockDelay: rowdata.lockDelay,
			actionInterval: rowdata.actionInterval,
			doorSensorStatus: rowdata.doorSensorStatus,
			backLock: rowdata.backLock,
			isDisableAudio: rowdata.isDisableAudio,
			enabled: rowdata.enabled,
			activeTimeSegId: rowdata.activeTimeSegId,
			passModeTimeSegId: rowdata.passModeTimeSegId,
			passModeTimeSegName: rowdata.passModeTimeSegName,
			activeTimeSegName: rowdata.activeTimeSegName,
			verifyMode: rowdata.verifyMode,
			forcePwd: rowdata.forcePwd,
			supperPwd: rowdata.supperPwd,

			[event.target.name]: event.target.value
		}, () => { console.log('Open Dialog') });
	}
	loadAuxOutList() {
		this.props.getAccAuxOuts();
	}

	onCloseAlert() {
		this.props.resetAlert();
	}

	handleRowUpdate = (newData, oldData, resolve) => {
		let errorList = []
		if (newData.name === "" || newData.remark === "") {
			errorList.push("Please Enter Name And Remark")
		}
		if (errorList.length < 1) {
			oldData.name = newData.name;
			oldData.remark = newData.remark;
			this.props.updateAccAuxOut(oldData, this.props.deviceName, this.props.auxNo, this.props.passageModeTimeZone);
		} else {
			this.setState({ errorMessages: errorList })
			this.setState({ iserror: true, showError: '' })
			resolve()
		}
	}

	render() {
		const accAuxOutList=this.state.accAuxOutList;
		//alert(JSON.stringify(this.props.auxOutStatus)) 
	    const { t } = this.props;
		const auxOutsName=this.props.auxOutStatus.auxOutsName;
		const disabledAuxOutsName=this.props.auxOutStatus.disabledAuxOutsName;
		const offlineAuxOutsName=this.props.auxOutStatus.offlineAuxOutsName;
		const tableIcons = {
			Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
			Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
			DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
			Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
			Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
			FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
			LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
			NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
			ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
			SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
			ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
			ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
		};

		const columns = [
			{ field: 'name', title: t("tableHeaderCommonName"),validate: rowData => (rowData.name === "") ? { isValid: false, helperText: "PleaseEnterAccOutName"}: (rowData.name === undefined)?{   }:true, },
			{ field: 'accDeviceDO.deviceName', title: t("deviceName"), editable: 'never' },
			{ field: 'auxNo', title: t("tableHeaderCommonNumber") , editable: 'never' },
			{ field: 'accDeviceDO.timeZone', title: t("tableHeaderAccAuxOutPassageModeTimeZone") , editable: 'never' },
			{ field: 'remark', title: t("tableHeaderCommonRemark") },
		];

		// eslint-disable-next-line
		const auxOutdata = [
			{ name: 'Auxiliary Output-1', deviceName: '192.168.53.13', auxNo: '1', passageModeTimeZone: 'OUT1', remark: 'remark1'},
			{ name: 'Auxiliary Output-2', deviceName: '192.168.53.23', auxNo: '2', passageModeTimeZone: 'OUT2', remark: 'remark2'},
			{ name: 'Auxiliary Output-3', deviceName: '192.168.53.33', auxNo: '3', passageModeTimeZone: 'OUT1', remark: 'remark3'},
			{ name: 'Auxiliary Output-4', deviceName: '192.168.53.43', auxNo: '4', passageModeTimeZone: 'OUT1', remark: 'remark4'},
			{ name: 'Auxiliary Output', deviceName: '192.168.53.53', auxNo: '5', passageModeTimeZone: 'OUT2', remark: 'remark5'},
		];
		// eslint-disable-next-line
		let auxOutNames=this.state.selectedIds? this.state.selectedIds.map(name=>{
			return (
				<Fragment>
					{name.name},
				</Fragment>
			)
			}): '';
		const { totalCount, query, pageChanged } = this.state;
		return (
			<Fragment>
	<div style={{ width: '100%' }}>
	<Dialog open={this.state.filter} onClose={this.filterClose} aria-labelledby="form-dialog-title" fullWidth={true}
			maxWidth={'xs'} >


						<DialogTitle id="form-dialog-title">Filter</DialogTitle>
						<DialogContent>
						<Grid item xs = {12}>
						<TextField className="text-fields"
						value= {this.state.name} name="name" 
						onChange={this.onChange} 
						label={ t("tableHeaderCommonName") } type="text" 
						error={this.state.errors.name ? true : false} 
						helperText={this.state.errors.name}	
								
								/>
					</Grid>
					<Grid item xs = {12}>
						<TextField className="text-fields"
						value= {this.state.deviceAlias} name="name" 
						onChange={this.onChange} 
						label={ t("deviceName") } type="text" 
						error={this.state.errors.name ? true : false} 
						helperText={this.state.errors.name}	
								
								/>
					</Grid>
					{/* <Grid item xs = {12}>
						<TextField className="text-fields"
						value= {this.state.name} name="name" 
						onChange={this.onChange} 
						label={ "Printed Name" } type="text" 
						error={this.state.errors.name ? true : false} 
						helperText={this.state.errors.name}	
								
								/>
					</Grid> */}

						</DialogContent>
						<DialogActions>
						<Button onClick={this.filterApply} color="primary">
						{ t("apply") } 
						</Button>
						<Button onClick={this.filterClose} color="primary">
						{ t("reset") } 
						</Button>
						</DialogActions>
					</Dialog>

					{/* Remote Opening */}
					<Dialog open={this.state.remoteOpening} onClose={this.setDeviceTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">{ t("remoteOpening") }  </DialogTitle>
						<DialogContent style={{ color:"black" }}>
							<Card variant="outlined" style={{ height: "100px",color:"black" ,overflow: "auto" }}>
								<CardContent >
								{auxOutsName &&
										<Typography gutterBottom >
											{auxOutsName}
										</Typography>
									}
									{offlineAuxOutsName &&
										<Typography gutterBottom style={{ color: "red" }} >
											{ t("offlineDeviceCannotOperate") } <br></br>
											{offlineAuxOutsName}
										</Typography>
									}

									{disabledAuxOutsName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                            { t("disabledDeviceCannotOperate") } <br></br>
											{disabledAuxOutsName}
										</Typography>
									}
								</CardContent>

							</Card>
							<Card variant="outlined" style={{ height: "150px", marginTop: "5px" }}>
								<CardContent >
									<Typography color="black" gutterBottom>
										
										<Box display="flex" justifyContent="flex-start" bgcolor="background.paper">
											<Box p={3}>
											{ t("labelOpenInterval") } 
											</Box>
											<Box p={1} bgcolor="grey.300">

												<TextField margin="dense"
													variant="outlined"
													id="openInterval" name="openInterval" onChange={this.onChange} label="time Interval" size="small" type="text" fullWidth />
 
											</Box>
											<Box p={1} bgcolor="grey.300">

											</Box>
										</Box>
									</Typography>
								</CardContent>

							</Card>


							</DialogContent>
						<DialogActions>
							<Button onClick={this.remoteOpeningClose} color="primary">
							{ t("cancel") } 
						</Button>
							<Button onClick={this.remoteOpenControl} color="primary" disabled={!auxOutsName}>
							{ t("tableBodySaveTooltip") } 
						</Button>
						</DialogActions>
					</Dialog>
					
					{/* Remote Colosing */}
					<Dialog open={this.state.remoteClosing} onClose={this.setDeviceTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">Remote Closing</DialogTitle>
						<DialogContent style={{ color:"black" ,overflow: "auto"}}>
							<Card variant="outlined" style={{ height: "100px",color:"black" }}>
								<CardContent>
								{auxOutsName &&
										<Typography gutterBottom >
											{auxOutsName}
										</Typography>
									}
									{offlineAuxOutsName &&
										<Typography gutterBottom style={{ color: "red" }} >
											{ t("offlineDeviceCannotOperate") }  <br></br>
											{offlineAuxOutsName}
										</Typography>
									}

									{disabledAuxOutsName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                            { t("disabledDeviceCannotOperate") } <br></br>
											{disabledAuxOutsName}
										</Typography>
									}
								</CardContent>

							</Card> 
						</DialogContent>
						<DialogActions>
							<Button onClick={this.closingAuxOutDialogClose} color="primary">
								{ t("cancel") }
						</Button>
							<Button onClick={this.remoteCloseControl} color="primary" disabled={!auxOutsName}>                                                                                 
							{ t("tableBodySaveTooltip") } 
						</Button>
						</DialogActions>
					</Dialog>
					
					{/* Remote Normally Open */}
					<Dialog open={this.state.remoteNormallyOpen} onClose={this.setDeviceTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">{ t("dialogRemoteNormallyOpen") } </DialogTitle>
						<DialogContent style={{ color:"black" }}>
							<Card variant="outlined" style={{ height: "100px",color:"black" ,overflow: "auto" }}>
								<CardContent > 
								{auxOutsName &&
										<Typography gutterBottom >
											{auxOutsName}
										</Typography>
									}
									{offlineAuxOutsName &&
										<Typography gutterBottom style={{ color: "red" }} >
											{ t("offlineDeviceCannotOperate") }<br></br>
											{offlineAuxOutsName}
										</Typography>
									}

									{disabledAuxOutsName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                            { t("disabledDeviceCannotOperate") } <br></br>
											{disabledAuxOutsName}
										</Typography>
									}
								</CardContent>

							</Card>
							
							</DialogContent>
						<DialogActions>
							<Button onClick={this.remoteNormallyClose} color="primary">
							{ t("cancel") }
						</Button>
							<Button onClick={this.remoteNormallyOpenControl} color="primary" disabled={!auxOutsName}>
							{ t("tableBodySaveTooltip") }
						</Button>
						</DialogActions>
					</Dialog>
						
						<Box display="flex" p={1} bgcolor="background.paper">
							<Box p={1} flexGrow={1}>
								<p><b className="acc-head">{ t("auxiliaryOutput") } </b></p>
							</Box>
							{/* <Box p={1}>
								<Paper className="search-root">
									<InputBase
										className="search-input"
										placeholder="Search"
										inputProps={{ "aria-label": "search" }}
										ref={this.input}
										type="text" name="search" onChange={this.handleInputChange} checked={this.state.isChecked} />
									<IconButton
										type="submit"
										className="search-iconButton"
										aria-label="search">
										<SearchIcon />
									</IconButton>
								</Paper> 
							</Box> */}
							<Box p={1}>
								<Button
									variant="contained"
									color="primary"
									size="small"
									startIcon={<FilterListIcon />}
									onClick={this.filterOpenDialog}  
								>
								{t("filter")}
							</Button>  
								</Box> 
						</Box>
						<Box display="flex" p={1} bgcolor="background.paper">
							<Box p={1}>
								<Button
									onClick={this.fetchData}
									color="secondary"
									size="small"
									startIcon={<Refresh className="refresh-btn" />}
									className="acc-list-action-btn">
									{t("refresh")}
							</Button>
							</Box>
							<Box p={1}>
							<Button
								onClick={this.remoteOpeningDialog}
								color="secondary"
								size="small"
								startIcon={<OpenInNewIcon className="refresh-btn" />}
								className="acc-list-action-btn">
								{t("remoteOpening")} 
							</Button>
						</Box>
						<Box p={1}>
							<Button
								onClick={this.enableClosingDialog}
								color="secondary"
								size="small"
								startIcon={<CancelPresentationIcon className="refresh-btn" />}
								className="acc-list-action-btn">
								{t("remoteClosing")} 
							</Button>
						</Box>
						  <Box p={1} >
							<Button
								onClick={this.remoteNormallyOpenDialog}
								color="secondary"
								size="small"
								startIcon={<RemoteNormallyOpen className="remote-normallyOpen" />}
								className="acc-list-action-btn">
								{t("dialogRemoteNormallyOpen")} 
							</Button>
						  </Box>
						   

						</Box>
						
					</div>
					
						<MaterialTable
							columns={columns}
							data={accAuxOutList ? accAuxOutList : []}
							icons={tableIcons}
							options={{
								minBodyHeight: '100%',
								actionsColumnIndex: -1,
								addRowPosition: 'first',
								showTitle: false,
								toolbarButtonAlignment: "left",
								toolbar: false,
								selection: true,
								pageSize: 5
							}}

							editable={{
								onRowUpdate: (newData, oldData) =>
									new Promise((resolve) => {
										this.handleRowUpdate(newData, oldData, resolve);
									})
							}}
							  actions={[
								// {
								// 	icon: () => <SwitchCameraIcon color="secondary" />,
								// 	tooltip: "Binding/Unbinding camera",
								// 	position: 'row',
								// 	onClick: (event) => {
								// 		console.log(event.target.value);
								// 	}
								// }
							]}
							/*onSelectionChange={data => {
								this.setState({
									accAuxOutList: data
								})
								}}*/
								onSelectionChange={(rows) => this.setState({ selectedIds: rows })}
								components={{
								Pagination: (props) => (
								  <TablePagination {...props} count={totalCount} page={query.page} rowsPerPage={query.pageSize} />
								),
							  }}
							  onPageChange={(page) => {
								if (pageChanged) {
								  this.setState(
									{ query: { ...query, page: page }, pageChanged: false }, () => this.fetchData({ pageChanged: true })
								  );
								}
							  }}
							   
							  onRowsPerPageChange={(pageSize) => {
								this.setState({ query: { ...query, pageSize } }, this.fetchData);
							  }}
						/>
			</Fragment>
		);

	}
}

AccAuxOutList.propTypes = {
	updateAccAuxOut: PropTypes.func.isRequired,
	remoteOpenControl: PropTypes.func.isRequired,
	remoteNormallyOpenControl: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	accAuxOutList: state.AccAuxOutList.accAuxOutList,
	updateAccAuxOut: state.AccAuxOutList.updateAccAuxOut,
	remoteOpenControl: state.AccAuxOutList.remoteOpenControl,
	remoteNormallyOpenControl: state.AccAuxOutList.remoteNormallyOpenControl,
	auxOutStatus: state.AccAuxOutList.auxOutStatus
});

export default compose(withTranslation(),connect(mapStateToProps, { getAccAuxOuts, updateAccAuxOut,getAuxOutStatus, remoteOpenControl, remoteNormallyOpenControl }))(AccAuxOutList);

