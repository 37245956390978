import ReactApexChart from 'react-apexcharts';
import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { getemployeeCount } from '../../actions/DashBoardActions';
import { connect } from 'react-redux';
class EmployeesGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employeeCount: 0,
      series: [],
      options: {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#e7e7e7',
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: '22px',
              },
            },
          },
        },
        grid: {
          padding: {
            top: 0,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91],
          },
        },
        labels: ['Average Results'],
      },
    };
  }

  componentDidMount() {
    this.props.getemployeeCount();

    this.setState(
      {
        employeeCount: this.props.employeeCount,
      },
      () => {
        this.afterSetStateFinished();
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.employeeCount !== prevProps.employeeCount) {
      this.setState({ employeeCount: this.props.employeeCount }, () => {
        this.afterSetStateFinished();
      });
    }
  }

  afterSetStateFinished = () => {
    var arr = [this.props.employeeCount ? this.props.employeeCount.employeeCount : []];
    this.setState({ series: arr });
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <h5 style={{ textAlign: 'center', padding: '14px' }}>{t('COMMON091')}</h5>
        <ReactApexChart options={this.state.options} series={this.state.series} type='radialBar' />
      </div>
    );
  }
}

//const domContainer = document.querySelector('#app');
//ReactDOM.render(React.createElement(ApexChart), domContainer);

const mapStateToProps = (state) => ({
  employeeCount: state.dashBoard.employeeCount,
});
export default compose(withTranslation(), connect(mapStateToProps, { getemployeeCount }))(EmployeesGraph);

//export default compose(withTranslation())(EmployeesGraph);
