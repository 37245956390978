import React, { forwardRef } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { compose } from 'redux'
import { withTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { getDoorList } from '../../actions/DoorListActions';
import { connect } from "react-redux";
import { addDoorLevelId,getAccessLevelDoorsByLevelId } from '../../actions/AccessLevelActions';
import { getAccDoorDetails } from '../../actions/DoorListActions';
import './Dailog.css'

const styles = theme => ({
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 250
    }
  },
  formcontrol: {
    margin: theme.spacing(1),
    width: 250
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="dilog-form-header" {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

class AddDoor extends React.Component {

  constructor(props) {
    super(props); 
    this.handleChange = this.handleChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleDialogOK = this.handleDialogOK.bind(this);
    this.state = {
      dialogText: '',
      isDialogOpen: false,
      accLevelListByLevelId: [],
      isChecked: false,
      acclevelDoorList: [],
      selectedIds: [],
      query: {
        page: 0,
        pageSize: 5,
      },
      totalCount: 0,
      pageChanged: true,
    }
    this.handleAddDoor = this.handleAddDoor.bind(this);
    this.addDoorSelectedIds = this.addDoorSelectedIds.bind(this);
  }
  componentDidMount() {
    //this.loadDoorList();

  }
  addDoorSelectedIds() { 
    let ids = '';
    this.state.selectedIds.map((row) => (ids += row.id + ','))
    //var idsArr = ids.split(',');
    new Promise((resolve) => {

      this.handleAddDoor(ids, resolve)
    }) 
  }
  async handleAddDoor(id, resolve) {

    const levelId = this.props.id;
    let ids = '';
    this.state.selectedIds.map((row) => (ids += row.id + ','))

    var idsArr = ids.split(',');
    idsArr.pop();
    const doorIdListDTO = {
      doorListID: idsArr
    }
    if (id !== 0) {
      this.props.addDoorLevelId(levelId, doorIdListDTO) 
      this.setState({ isChecked: false, isDialogOpen: false })
    }

  }
  componentDidUpdate(prevProps) {
    if (this.props.acclevelDoorList !== prevProps.acclevelDoorList) {
      this.setState({ acclevelDoorList: this.props.acclevelDoorList.item })
    } 
  }
  loadDoorList = () => { 
    const levelId = this.props.id;
    const { query } = this.state;
    this.props.getAccDoorDetails(levelId,false, query); 
  }
   

  handleDialogOK() {
    console.log('Clicked OK');
    this.setState({
      isDialogOpen: false,
      isChecked: false
    });
  }

  handleDialogClose() {
    console.log('Clicked Close');
    this.setState({
      isDialogOpen: false,
      isChecked: false,
    });
  }

  handleChange(e) {
    const target = e.target;
    const value = target.checked;
    this.loadDoorList(); 
    this.setState({
      isChecked: value,
      isDialogOpen: true
    }, () => { console.log('Open Dialog') });
  }
 

  render() {
     const { t } = this.props; 
    const doorColumns = [
      { field: 'name', title: t("tableHeaderAccReaderDoorName") },
      { field: 'deviceAlias', title: t("tableHeaderCommonDeviceName") }
    ];
    
    const tableIcons = {
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
    };

 

    return (
      <div>
        <Button
          className="button-common-primary"
          variant="contained"
          color="primary"
          size="small"
          startIcon={<AddCircleOutlineRoundedIcon />}
          onClick={this.handleChange} checked={this.state.isChecked}
        >
          {t("buttonCommonAdd")}
        </Button>

        <Dialog
          className="dialog-timeperiod"
          open={this.state.isDialogOpen}
          onClose={this.handleDialogClose}
          fullWidth={true}
          maxWidth={'sm'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

          <DialogTitle onClose={this.handleDialogClose}>{t("commonsTableActionAddDoor")} </DialogTitle>
          <DialogContent>
            <MaterialTable
              columns={doorColumns}
              data={this.state.acclevelDoorList ? this.state.acclevelDoorList: []}
              icons={tableIcons}
              options={{
                search: true,
                selection: true,
                showTitle: false,

              }}
              onSelectionChange={(rows) => this.setState({ selectedIds: rows })}
              localization={{
                pagination: {
                  // eslint-disable-next-line 
                  labelDisplayedRows: '{from}-{to}' + "  " + t("commonsTablePaginationOf") + "  " + '{count}',
                  labelRowsSelect: t("commonsTablePaginationRow"),
                  firstAriaLabel: t("firstPage"),
                  firstTooltip: t("commonsTablePaginationFirstPage"),
                  previousAriaLabel: t("previousPage"),
                  previousTooltip: t("commonsTablePaginationPreviousPage"),
                  nextAriaLabel: t("nextPage"),
                  nextTooltip: t("commonsTablePaginationNextPage"),
                  lastAriaLabel: t("lastPage"),
                  lastTooltip: t("commonsTablePaginationLastPage"),
                },

                header: {
                  actions: t("buttonCommonActions")
                },
                body: {
                  emptyDataSourceMessage: t("tableCommonNoRecordDisplay"),
                  editTooltip: t("commonsTableToolTipEdit"),
                  deleteTooltip: t("commonsTableToolDelete"),
                  filterRow: {
                    filterTooltip: 'Filter'
                  },
                  editRow: {
                    cancelTooltip: t("tableBodyCancelTooltip"),
                    saveTooltip: t("tableBodySaveTooltip"),
                    deleteText: t("tableBodyDeleteRowWarningMsg")
                  }
                },
                toolbar: {
                  searchPlaceholder: t("commonsTableToolBarSearch"),
                  searchTooltip: t("commonsTableToolBarSearch"),
                  // eslint-disable-next-line 
                  nRowsSelected: '{0}' + " " + t("commonsTablePaginationRow") + " " + t("selected")
                }
              }}

            />

            <DialogActions className="dilog-form-adddoor-action">
              <Button color="primary" onClick={this.handleAddDoor} variant="contained">
              {t("ok")}
                  </Button>
              <Button color="primary" onClick={this.handleDialogClose} variant="contained">
              {t("cancel")}
                  </Button>
            </DialogActions>

          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ 
  acclevelDoorList: state.DoorList.acclevelDoorList, 
}); 
export default compose(withTranslation(), connect(mapStateToProps, { getDoorList,getAccessLevelDoorsByLevelId,getAccDoorDetails, addDoorLevelId }), withStyles(styles, { withTheme: true }))(AddDoor);

