import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Sidebar, Header } from '../../layout-components';
import RefreshToken from '../../oauth-client/RefreshToken';

const LeftSidebar = (props) => {
  const { children, sidebarToggle, sidebarFixed, contentBackground } = props;
  const [open, setOpen] = React.useState(true);

  const onChangeSidebar = (child) => {
    //  alert(child)
    setOpen(child)
  }
  return (
    <Fragment>
      <div className={clsx('app-wrapper', contentBackground)}>
        <Header />
        <RefreshToken />
        <div className={clsx('app-main', { 'app-main-sidebar-static': !sidebarFixed })}>
          <Sidebar onchange={onChangeSidebar} />
          <div
          // className={child?'style1':'style2'}
          // className={clsx('app-content', {
          //   'app-content-sidebar-collapsed': sidebarToggle,
          //   'app-content-sidebar-fixed': sidebarFixed,
          // })}
          >
            <div className='app-content--inner'>
              <div className={open ? 'app_container1' : 'app_container2'}>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
  contentBackground: state.ThemeOptions.contentBackground,
});

export default connect(mapStateToProps)(LeftSidebar);
