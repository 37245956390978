import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import CommonUtil from '../../components/Util/CommonUtils';
import { updateAddressType } from '../../actions/organization/AddressTypeApiCalls';
import CancelButton from '../../components/CancelButton';
import SubmitButton from '../../components/SubmitButton';
import { makeStyles } from '@material-ui/core/styles';
import { REACT_URI_ADDRESS_TYPE } from '../../actions/organization/EndPoints';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';

const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
  },
}));

export default function AddAddressType(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const store = useSelector((state) => state);
  const [open, setOpen] = React.useState(props.open);
  // eslint-disable-next-line
  const [data, setData] = React.useState();
  // eslint-disable-next-line
  const [state, setState] = React.useState({
    id: props.selectedObject.id,
    name: props.selectedObject.name,
    code: props.selectedObject.code,
    description: props.selectedObject.description,
  });

  const [error, setError] = React.useState({
    name: false,
    code: false,
    description: false,
  });

  useEffect(() => {
    setOpen(props.open);
    setData(props.selectedObject);
    setState({
      id: props.selectedObject.id,
      name: props.selectedObject.name,
      code: props.selectedObject.code,
      description: props.selectedObject.description,
    });
  }, [props.open, props.selectedObject]);

  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.addressTypeError)) {
      const { addressTypeError } = store.errors;
      switch (addressTypeError.code) {
        case 'LMSE0001':
          setError({ name: true });
          break;
        case 'LMSE0002':
          setError({ code: true });
          break;
        default:
      }
    }
    // eslint-disable-next-line
  }, [store.errors.addressTypeError]);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      let addTypeObj = {};
      addTypeObj.id = state.id;
      addTypeObj.name = state.name;
      addTypeObj.code = state.code;
      addTypeObj.description = state.description;
      dispatch(
        updateAddressType(addTypeObj, (data) => {
          handlError(data);
        })
      );
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.name) && CommonUtil.isEmptyString(state.code)) {
      setError({ name: true });
      toast.error(t('ATYPE010'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.code)) {
      setError({ code: true });
      toast.error(t('ATYPE011'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.name)) {
      setError({ name: true });
      toast.error(t('ATYPE010'));
      return false;
    }
    if (!CommonUtil.isValidNumericName(state.name)) {
      setError({ name: true });
      toast.error(t('ATYPE012'));
      return false;
    }
    if (!CommonUtil.isValidCode(state.code)) {
      setError({ code: true });
      toast.error(t('ATYPE013'));
      return false;
    }
    return true;
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'LMSI6001':
        toast.success(data.message);
        history.push(REACT_URI_ADDRESS_TYPE);
        handleClose();
        break;
      case 'LMSW0001':
        data = data.data.error[0];
        break;
      case 'LMSI6002':
        toast.success(data.message);
        history.push(REACT_URI_ADDRESS_TYPE);
        handleClose();
        break;
      case 'LMSI6003':
        toast.success(data.message);
        history.push(REACT_URI_ADDRESS_TYPE);
        break;
      default:
        break;
    }
    switch (data.code) {
      case 'LMSE0001':
        setError({ name: true });
        break;
      case 'LMSE0002':
        setError({ code: true });
        break;
      case 'LMSE0004':
        setError({ code: true });
        break;
      case 'LMSE0005':
        setError({ name: true });
        break;
      default:
        break;
    }

    // if (data.code === 'LMSI0001' || data.code === 'LMSI0002' || data.code === 'LMSI6001') {
    //   toast.success(data.message);
    //   handleClose();
    // } else {
    //   toast.error(data.message);
    // }
  };

  return (
    <div>
      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex' m={1}>
            <Box flexGrow={1}>
              <Typography variant='h5'>{CommonUtil.isEmptyString(state.id) ? t('ATYPE002') : t('ATYPE008')}</Typography>
            </Box>
            <Cancel onClick={handleClose} className='close_icon' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    required
                    error={error.name}
                    name='name'
                    label={t('COMMON044')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    defaultValue={state.name}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    required
                    error={error.code}
                    name='code'
                    label={t('COMMON045')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    defaultValue={state.code}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    size='small'
                    inputProps={{ className: classes.textarea }}
                    variant='outlined'
                    aria-label='maximum height'
                    placeholder={t('ATYPE009')}
                    value={state.description}
                    name='description'
                    onChange={handleChange}
                    multiline
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON035')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
