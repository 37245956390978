import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import CommonUtil from '../../components/Util/CommonUtils';
import { createZone, filterZone, loadZoneBySite, updateZone } from '../../actions/organization/ZoneApiCalls';
import CancelButton from '../../components/CancelButton';
import SubmitButton from '../../components/SubmitButton';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REACT_URI_ZONE } from '../../actions/organization/EndPoints';
import { getSites } from '../../actions/organization/SiteApiCalls';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';

const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
  },
}));

export default function AddZone(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const store = useSelector((state) => state);
  const [open, setOpen] = React.useState(props.open);
  // eslint-disable-next-line
  const [data, setData] = React.useState();
  // eslint-disable-next-line
  const [parentZones, setParentZones] = React.useState([]);
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  // eslint-disable-next-line
  const [sites, setSites] = React.useState([]);
  const [state, setState] = React.useState({
    id: props.selectedObject.id,
    name: props.selectedObject.name,
    siteId: props.selectedObject.siteId,
    parentId: props.selectedObject.parentId,
    description: props.selectedObject.description,
  });
  const [error, setError] = React.useState({
    name: false,
    siteId: false,
    parentId: false,
    description: false,
  });

  useEffect(() => {
    setOpen(props.open);
    setState({
      id: props.selectedObject.id,
      name: props.selectedObject.name,
      siteId: props.selectedObject.siteId,
      parentId: props.selectedObject.parentId ? props.selectedObject.parentId : '',
      description: props.selectedObject.description,
    });
  }, [props.open, props.selectedObject]);

  useEffect(() => {
    dispatch(
      getSites(1, 10, false, (data) => {
        setSites(data.sites);
      })
    );
    console.log(state.siteId);
    if (state.siteId === '' || state.siteId === 'none' || state.siteId === undefined) {
      setParentZones([]);
    } else {
      dispatch(
        loadZoneBySite(state.siteId, (data) => {
          console.log(data)
          setParentZones(data.zones);
        })
      );
    }
  }, []);

  const handleZones = (event) => {
    //  const siteId = event.target.getAttribute('data-value');
     const siteId = event.target.value;
    if (siteId === '' || siteId === 'none' || siteId === undefined) {
      setParentZones([]);
    } else {
      dispatch(
        loadZoneBySite(siteId, (data) => {
          setParentZones(data.zones);
        })
      );
    }
  };

  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.zoneError)) {
      const { zoneError } = store.errors;
      switch (zoneError.code) {
        case 'LMSE0015':
          setError({ name: true });
          break;
        default:
      }
    }
  }, [store.errors.zoneError]);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      let zoneObj = {};
      zoneObj.id = state.id;
      zoneObj.name = state.name;
      zoneObj.description = state.description;
      zoneObj.siteId = state.siteId;
      zoneObj.parentId = state.parentId;
      dispatch(
        createZone(zoneObj, (data) => {
          handleError(data);
        })
      );
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.name)) {
      setError({ name: true });
      toast.error(t('ZONE010'));
      return false;
    }
    if (!CommonUtil.isValidNumericName(state.name)) {
      setError({ name: true });
      toast.error(t('ZONE011'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.siteId)) {
      setError({ siteId: true });
      toast.error(t('ZONE012'));
      return false;
    }
    return true;
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'LMSI6016':
        handleClose();
        toast.success(data.message);
        history.push(REACT_URI_ZONE);
        break;
      case 'LMSW0001':
        data = data.data.error[0];
        break;
      case 'LMSI6017':
        toast.success(data.message)
        history.push(REACT_URI_ZONE);
        handleClose();
        break;
      default:
        break;
    }
    switch (data.code) {
      case 'LMSE0015':
        setError({ name: true });
        break;
      case 'LMSE0017':
        setError({ siteId: true });
        break;
      case 'LMSE0016':
        setError({ name: true });
        break;
      default:
        break;
    }

    // if (data.code === 'LMSI0017' || data.code === 'LMSI0018') {
    //   toast.success(data.message);
    //   handleClose();
    // } else {
    //   toast.error(data.message);
    // }
  };
  return (
    <div>
      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex' m={1}>
            <Box flexGrow={1}>
              <Typography variant='h5'>{CommonUtil.isEmptyString(state.id) ? t('ZONE002') : t('ZONE008')}</Typography>
            </Box>
            <Cancel onClick={handleClose} className='close_icon' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    required
                    fullWidth
                    error={error.name}
                    name='name'
                    label={t('COMMON044')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    defaultValue={state.name}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth required>
                    <InputLabel>{t('ZONE006')}</InputLabel>
                    <Select
                      required
                      label={t('ZONE006')}
                      error={error.siteId}
                      name='siteId'
                      onChange={handleChange}
                      onClick={handleZones}
                      value={state.siteId}
                      defaultValue={state.siteId}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {sites ? (
                        sites.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('COMMON050')}</InputLabel>
                    <Select label={t('COMMON050')} error={error.parentId} name='parentId' onChange={handleChange} value={state.parentId} defaultValue={state.parentId}>
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {parentZones ? (
                        parentZones.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  {/* <TextField
                    fullWidth
                    size='small'
                    inputProps={{ className: classes.textarea }}
                    variant='outlined'
                    aria-label='maximum height'
                    placeholder={t('ZONE009')}
                    value={state.description}
                    name='description'
                    onChange={handleChange}
                    multiline
                  /> */}

      

              <TextField id="outlined-basic"
              fullWidth
              autoComplete='off'
              label={t('ZONE009')} 
            //  placeholder={t('ZONE009')}
              variant="outlined"
              multiline
              inputProps={{maxLength:255}}
              name="description"
              value ={state.description}
              onChange={handleChange}
              helperText =''
              

              
              />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON035')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
