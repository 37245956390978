import { Box, Button, Grid } from '@material-ui/core';
import React, { Fragment, forwardRef } from 'react';
import TimeLine from '../../utils/TimeLine';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { getAccDevices, deleteDevice, updateDeviceList, resetAlert, handleFilterApply } from "../../actions/DeviceActions";
import {
	controlDeviceOperations, handleDeviceExport, modifyIpAddress, modifyPwd, setDeviceIOStatus, handleDeviceOption, handleSynchAllDataToDevice,
	setDeviceRulesOperation, enableDevice, handleDeviceTransaction, desableDevice, handleSetAsRegistartionDeviceOperations, setFingerPrintThreshold,
	handleDeviceTimeZoneStatus,controlDeviceOperationsSyncTime,
	setDaylightSavingTime,
	setDeviceTimeZoneOperation,
	handleDeviceStatus, handleSetDeviceTimeZone,handleSyncallDataToDevValidation
} from '../../actions/AccDeviceOperationActions'
import { JAVA_URL_TIME_ZONE_LIST, REACT_URL_DEVICE_LIST,JAVA_URL_DEVICE_CAPACITY,JAVA_URL_DEVICE_RULES,JAVA_URL_TIME_PERIOD } from "../../actions/EndPoints";
import { ReactComponent as Refresh } from "../../assets/cloudaccess-svg/Refresh.svg";
import { ReactComponent as Delete } from "../../assets/cloudaccess-svg/Delete.svg";
import { Link } from 'react-router-dom';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { TablePagination } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FilterListIcon from '@material-ui/icons/FilterList';
import Radio from '@material-ui/core/Radio';
import EditIcon from '@material-ui/icons/Edit';
import { JAVA_URL_ZONE_LIST } from '../../actions/EndPoints';
import axios from "axios";
import CommonUtil from '../Util/CommonUtils';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Menu from '@material-ui/core/Menu';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { toast } from 'react-toastify';
import SettingsIcon from '@material-ui/icons/Settings';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import PageviewIcon from '@material-ui/icons/Pageview';
import { default as palm } from '../../assets/images/personnel-svg/palm.svg';
import { default as Vein } from '../../assets/images/personnel-svg/Vein.svg';
import { default as UserFace } from '../../assets/images/personnel-svg/UserFace.svg';
import Divider from '@material-ui/core/Divider';
import { default as Face } from '../../assets/images/personnel-svg/Face.svg';
import { default as FingerPrint } from '../../assets/images/personnel-svg/FingerPrint.svg';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';


const styles = theme => ({
	form: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: 250
		}
	},
	formcontrol: {
		width: 150
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	}
});

const DialogTitle = withStyles(styles)(props => {
	const { children, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className="dilog-filter-head" {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className="dilog-form-closeButton" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});
class AccDeviceList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			error: false,
			hasError: false,
			devices: [],
			selectedIds: [],
			showSuccessAlert: false,
			resetAlert: false,
			open: true,
			dialogText: '',
			isDialogOpen: false,
			isChecked: false,
			accDSTimeId: '',
			accDSTimeName: '',
			acpanelType: 0,
			alias: '',
			areaIdIn: '',
			baudrate: '',
			comAddress: '',
			comPort: '',
			commPwd: '',
			commType: 0,
			companyId: '',
			connectState: '',
			createdAt: '',
			deviceName: '',
			enabled: false,
			fourToTwo: false,
			fwVersion: '',
			gateway: '',
			iconType: '',
			id: '',
			ids: '',
			ipAddress: '',
			ipPort: 0,
			isRegistrationDevice: false,
			deviceStatus: true,
			lastUpdateDate: '',
			machineType: 0,
			masterSlave: '',
			netConnectMode: '',
			parentDeviceId: '',
			readerName: '',
			rs485Param: '',
			sn: '',
			subnetMask: '',
			timeZone: '',
			updatedAt: '',
			wgReaderId: '',
			zoneCode: '',
			zoneId: '',
			passModeTimeSegName: '',
			zoneName: '',
			zonesData: [],
			openMenu: false,
			selectDeviceList: [],
			modifyIpDialog: false,
			deviceInOutdialog: false,
			modifiedIp: '',
			modifiedSubnet: '',
			modifiedGateway: '',
			newPassword: '',
			confirmPassword: '',
			modifyPwdDialog: false,
			devIOState: '',
			mThreshold: '',
			fingerPrintThresholdDialog: false,
			setDeviceTime: false,
			timeZoneData: [],
			enable: false,
			desable: false,
			deviceOption: false,
			transaction: false,
			selectedValue: '',
			progress: 0,
			rules: false,
			rulesData: [],
			capacity: false,
			synchronize: false,
			auxiliaryInputSetting: false,
			multiPersonOpenDoor: false,
			doorParameters: false,
			bgVerificationOptions: false,
			firstPersonOpenDoor: false,
			timeZoneholidays: false,
			verificationModeRule: false,
			Linkage: false,
			AntiPassback: false,
			accessAuthority: false,
			all: false,
			deviceCapacityData: [],
			query: {
				page: 0,
				pageSize: 5,
			},
			totalCount: 0,
			pageChanged: true,
			synchData: [],
			allData: [],
			filter: false,
			filterDeviceList: [],
			synchronizeTime: false,
			timePeriodsdata: [],
			timeSegDTO: {
				id: ""
			},
			timeSegLevelDTO: ''
		}
		//this.handleRowEnable = this.handleRowEnable.bind(this);
		this.loadDeviceList = this.loadDeviceList.bind(this);
		this.handleRowDelete = this.handleRowDelete.bind(this);
		this.deleteSelectedIds = this.deleteSelectedIds.bind(this);
		this.onCloseAlert = this.onCloseAlert.bind(this);
		this.onChange = this.onChange.bind(this);
		this.zonesDataDropDown = this.zonesDataDropDown.bind(this);
	}
	handleClick = (event) => {
		this.setState({ openMenu: true })
	};
	handleClose = () => {
		//setAnchorEl(null);
		this.setState({ openMenu: false })
	};

	componentDidMount() {
		this.loadDeviceList();
		this.zonesDataDropDown();
		axios.get(JAVA_URL_TIME_ZONE_LIST).then(response => {
			this.setState({
				timeZoneData: response.data.data
			});

		})

		var  name= '';
		var  remark= '';
		axios.get(JAVA_URL_TIME_PERIOD+'?name=' + name +'&remark=' + remark).then((response) => {
			console.log("response"+JSON.stringify(response.data.data));
		//	const treeData = CommonUtil.TreeDataFormat(response.data.data);
			//console.log("treeData"+JSON.stringify(treeData)) 
			 
			this.setState({
				timePeriodsdata:response.data.data ? response.data.data: '',
			});
		  });
	}
	onChange(e) {
		this.setState({ errors: {} })
		this.setState({ [e.target.name]: e.target.value });

	}
	fetchData = (additionalState) => {
		const { query } = this.state;
		this.props.getAccDevices(this.state.alias, this.state.sn, this.state.deviceName,this.state.ipAddress, query).then(() => {
			this.setState({ ...additionalState });
		})
	}
	componentDidUpdate(prevProps) {
		console.log("response :" + this.props.response)
		if (this.props.response !== prevProps.response) {
			console.log("is_response :" + this.props.response)
			this.setState({ showSuccessAlert: this.props.response })
		}
		if (this.props.accDevices !== undefined) {
			if (this.props.accDevices !== prevProps.accDevices) {
				this.setState({ devices: this.props.accDevices.item })
			}

		}

		if (this.props.accDevices !== undefined) {
			if (this.props.accDevices !== prevProps.accDevices) {
				if (this.props.accDevices.totalCount !== undefined) {
					this.setState({ totalCount: this.props.accDevices.totalCount });
				} else {
					this.setState({ totalCount: 0 });
				}
			}
		}
		if (this.props.errors !== prevProps.errors) {
			if (Object.keys(this.props.errors).length === 0 && this.props.errors.constructor === Object) {
				this.setState({
					isDialogOpen: false, errors: {}
				});
			}
		}
	}

	loadDeviceList(additionalState) {
		const { query } = this.state
		this.props.getAccDevices(this.state.alias, this.state.sn, this.state.deviceName, this.state.ipAddress, query).then(() => {
			this.setState({ ...additionalState });

		});;
	}
	handleSynchAllDataToDevice = () => {
		var allData = ["accLevel", "antiPassback", "linkage", "verifyModeRule", "timeZoneAndHoliday", "auxiliaryInputSetting", "multiPersonOpenDoor", "doorOpt", "backgroundVerifyParam", "accessAuthority"];
		var data = allData.join();
		var synchData = this.state.synchData.join();
		if (this.state.synchData.length === 0 && this.state.all === true) {
			this.props.handleSynchAllDataToDevice(data, this.props.syncAllDataToDevList.devIds);
			this.setState({ synchData: [] })
		}
		else if (this.state.synchData.length > 0 && this.state.all === false) {
			this.props.handleSynchAllDataToDevice(synchData, this.props.syncAllDataToDevList.devIds);
			this.setState({ synchData: [] })
		}
	}
	deleteSelectedIds() {
		let ids = '';
		this.state.selectDeviceList.map((row) => (ids += row.id + ','))
		new Promise((resolve) => {
			if (ids.length > 0)
				this.handleRowDelete(ids, resolve)
		})

	}

	onCloseAlert() {
		this.props.resetAlert();
	}

	async handleRowDelete(id) {
		const { query } = this.state;
		this.props.deleteDevice(id, query, this.props.history)

	}

	handleClose() {
		console.log('Clicked Close');
		this.setState({
			isDialogOpen: false,
			error: {}
		});
	}
	myChangeHandler = (event) => {
		this.setState({ deviceName: event.target.value });
	}


	handleSelectedChange = event => {

		this.setState({ selectedValue: event.target.value })
	};
	handleSyncChange = (event) => {
		// alert(event.target.value+"--"+event.target.checked)
		if (event.target.checked) {
			this.state.synchData.push(event.target.value)

		}
		this.setState({ [event.target.name]: event.target.checked });
	};
	handleSyncAllChange = (event) => {
		if (event.target.checked) {
			this.setState({

				auxiliaryInputSetting: true,
				multiPersonOpenDoor: true,
				doorParameters: true,
				bgVerificationOptions: true,
				firstPersonOpenDoor: true,
				timeZoneholidays: true,
				verificationModeRule: true,
				Linkage: true,
				AntiPassback: true,
				accessAuthority: true,
				[event.target.name]: event.target.checked

			});
		}
		else {
			this.setState({

				auxiliaryInputSetting: false,
				multiPersonOpenDoor: false,
				doorParameters: false,
				bgVerificationOptions: false,
				firstPersonOpenDoor: false,
				timeZoneholidays: false,
				verificationModeRule: false,
				Linkage: false,
				AntiPassback: false,
				accessAuthority: false,
				[event.target.name]: event.target.checked

			});
		}
	};

	handleChange = (event, rowdata) => {

		const target = event.target;
		const value = target.checked;

		this.setState({
			isChecked: value,
			isDialogOpen: true,
			id: rowdata.id,
			deviceName: rowdata.deviceName,
			ipAddress: rowdata.ipAddress,
			ipPort: rowdata.ipPort,
			isRegistrationDevice: rowdata.isRegistrationDevice,
			lastUpdateDate: rowdata.lastUpdateDate,
			machineType: rowdata.machineType,
			masterSlave: rowdata.masterSlave,
			netConnectMode: rowdata.netConnectMode,
			parentDeviceId: rowdata.parentDeviceId,
			readerName: rowdata.readerName,
			rs485Param: rowdata.rs485Param,
			sn: rowdata.sn,
			subnetMask: rowdata.subnetMask,
			timeZone: rowdata.timeZone,
			updatedAt: rowdata.updatedAt,
			iconType: rowdata.iconType,
			zoneId: rowdata.zoneId,
			[event.target.name]: event.target.value
		});
	}

	handleClose = () => {
		console.log('Clicked Close');
		this.setState({
			isDialogOpen: false, errors: {}
		});
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
		}
		else {
			this.setState({ errors: {} })
		}
	}
	zonesDataDropDown() {
		axios.get(JAVA_URL_ZONE_LIST).then(response => {
			const treeData = response.data.data ? CommonUtil.TreeDataFormat(response.data.data) : "";
			this.setState({
				zonesData: treeData
			});
		})

	}
	upDateDeviceList = (e, rowData) => {
		let errorList = []
		if (errorList.length < 1) {
			if (errorList.length < 1) {
		const updateDeviceList = {
			id: this.state.id,
			deviceName: this.state.deviceName,
			zoneName: this.state.zoneName,
			ipAddress: this.state.ipAddress,
			ipPort: this.state.ipPort,
			isRegistrationDevice: this.state.isRegistrationDevice,
			lastUpdateDate: this.state.lastUpdateDate,
			machineType: this.state.machineType,
			masterSlave: this.state.masterSlave,
			netConnectMode: this.state.netConnectMode,
			parentDeviceId: this.state.parentDeviceId,
			readerName: this.state.readerName,
			rs485Param: this.state.rs485Param,
			sn: this.state.sn,
			subnetMask: this.state.subnetMask,
			timeZone: this.state.timeZone,
			updatedAt: this.state.updatedAt,
			wgReaderId: this.state.wgReaderId,
			zoneCode: this.state.zoneCode,
			zoneId: this.state.zoneId,
			iconType: this.state.iconType,
			timeSegLevelDTO: this.state.timeSegLevelDTO
		};

		this.props.updateDeviceList(updateDeviceList, this.props.history);
		if (this.props.response) {
			this.setState({ isDialogOpen: false })
		} else {
			this.setState({ isDialogOpen: true })
		}
		if (this.props.errors) {
			this.setState({ isDialogOpen: true })
		}
		this.fetchData();
		//this.loadDeviceList();
	}
}

	}

	handleDeviceOperations(popupState, operation) {
		popupState.close();
		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		}
		else if (this.state.selectDeviceList.length > 1) {
			toast.warn("Please select only one device")
		}
		else {
			this.props.controlDeviceOperations(this.state.selectDeviceList[0].id, operation)
		}
	}
	handleDeviceTimeZoneStatus = (popupState) => {
		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		}
		else {
			this.props.controlDeviceOperationsSyncTime(this.props.deviceSyncTime.retIds, "syncTime");
		}
	}
	openSynchronizeDialog = (popupState, syncTime) => {
		popupState.close();
		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		} else{
		let ids = '';
		this.state.selectDeviceList.map((row) => (ids += row.id + ','))

		let type = "syncTime";
		var checkOffline = '';
		this.state.selectDeviceList.map((row) => (checkOffline += row.alias + ','))

		this.props.handleDeviceTimeZoneStatus(ids, type, checkOffline);
		this.setState({ synchronizeTime: true });
		}
	}
	closeSynchronizeDialog = (popupState, syncTime) => {

		this.setState({ synchronizeTime: false })
	}
	handleSynchronizeDialog = (popupState, syncTime) => {

	}

	handleSetAsRegistartionDeviceOperations(popupState, operation) {
		popupState.close();
		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		} else if (this.state.selectDeviceList.length > 1) {
			toast.warn("Please select only one device")
		} else {

			this.props.handleSetAsRegistartionDeviceOperations(this.state.selectDeviceList[0].id)
		}
	}

	handleDeviceOption = () => {


		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		} else if (this.state.selectDeviceList.length > 1) {
			toast.warn("Please select only one device")
		} else {

			this.props.handleDeviceOption(this.state.selectDeviceList[0].id)
		}
	}
	handleDeviceTransaction = () => {

		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		} else if (this.state.selectDeviceList.length > 1) {
			toast.warn("Please select only one device")
		} else {
			if (this.state.selectedValue === "New") {

				this.props.handleDeviceTransaction(this.state.selectDeviceList[0].id, true)
			}
			else {
				this.props.handleDeviceTransaction(this.state.selectDeviceList[0].id, false)
			}

		}
	}
	setDeviceTimeZoneOperation = () => {

		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		} else {

			this.props.setDeviceTimeZoneOperation(this.props.setdeviceTimeZone.devIds, "timeZone", this.state.timeZone)
		}
	}

	//   setDaylightSavingTime(popupState,operation){
	// 	popupState.close();
	// 	if(this.state.selectDeviceList.length <= 0){
	// 		toast.warn("Please select any one device")
	// 	} else if(this.state.selectDeviceList.length > 1){
	// 		toast.warn("Please select only one device")
	// 	} else {
	// 		alert(this.state.selectDeviceList[0].id+" "+operation)
	// 		this.props.setDaylightSavingTime(this.state.selectDeviceList[0].id)
	// 	}
	//   }
	handleModifyIpDialog = () => {
		this.setState({
			modifyIpDialog: !this.state.modifyIpDialog
		})
	}
	openEnableDeviceDialog = (popupState) => {
		popupState.close();
		this.setState({
			enable: !this.state.enable
		})
	}
	getDeviceCapacityDialogOpen = (popupState) => {
		popupState.close();
		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		} else if (this.state.selectDeviceList.length > 1) {
			toast.warn("Please select only one device")
		} else {
			this.setState({
				capacity: !this.state.capacity
			})
			axios.get(JAVA_URL_DEVICE_CAPACITY.replace("{devId}", this.state.selectDeviceList[0].id)).then(response => {
				this.setState({
					deviceCapacityData: response.data.data
				});

			})
		}

	}
	getDeviceRulesDialogOpen = (popupState) => {
		popupState.close();

		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		} else if (this.state.selectDeviceList.length > 1) {
			toast.warn("Please select only one device")
		} else {

			//this.props.setDeviceRulesOperation(this.state.selectDeviceList[0].id)
			axios.get(JAVA_URL_DEVICE_RULES.replace("{id}", this.state.selectDeviceList[0].id)).then(response => {
				this.setState({
					rulesData: response.data.data
				});

			})
			this.setState({
				rules: !this.state.rules
			})
		}

	}
	getTransactionDialogOpen = (popupState) => {
		popupState.close();
		this.setState({
			transaction: !this.state.transaction
		})
	}
	opensynchronizeDeviceDialog = (popupState) => {
		popupState.close();
		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		} else {
			let ids = '';
			this.state.selectDeviceList.map((row) => (ids += row.id + ','))
			this.props.handleSyncallDataToDevValidation(ids,"syncAllData")
			this.setState({
				synchronize: !this.state.synchronize
			})
		}
	}
	getDeviceOptionDialogOpen = (popupState) => {
		popupState.close();
		this.setState({
			deviceOption: !this.state.deviceOption
		})
	}
	closedeviceOption = () => {
		this.setState({
			deviceOption: !this.state.deviceOption
		})
	}
	closedeviceCapacity = () => {
		this.setState({
			capacity: !this.state.capacity
		})
	}
	closedeviceTransaction = () => {
		this.setState({
			transaction: !this.state.transaction
		})
	}
	closesynchronizeDialog = () => {
		this.setState({
			synchronize: !this.state.synchronize
		})
	}
	openDesableDeviceDialog = (popupState) => {
		popupState.close();
		this.setState({
			desable: !this.state.desable
		})
	}
	closeEnableDialog = () => {
		this.setState({
			enable: !this.state.enable
		})
	}
	closeDesableDialog = () => {
		this.setState({
			desable: !this.state.desable
		})
	}
	closedeviceRules = () => {
		this.setState({
			rules: !this.state.rules
		})
	}

	handleFingerPrintThresholdClose = () => {
		this.setState({
			fingerPrintThresholdDialog: !this.state.fingerPrintThresholdDialog
		})
	}
	setDeviceTimeColse = () => {
		this.setState({
			setDeviceTime: !this.state.setDeviceTime
		})
	}

	fingerPrintThresholdDialogOpen = (popupState) => {
		popupState.close();
		this.setState({
			fingerPrintThresholdDialog: !this.state.fingerPrintThresholdDialog
		})
	}
	setDeviceTimeZoneOpenDialog = (popupState) => {
		popupState.close();
		if (this.state.selectDeviceList.length >= 1) {
			let ids = '';
			this.state.selectDeviceList.map((row) => (ids += row.id + ','))
			this.props.handleSetDeviceTimeZone(ids)
			this.setState({
				setDeviceTime: !this.state.setDeviceTime
			})
		}
		else {
			toast.warn("Please select any one device")
		}
	}

	handledeviceInOutdialog = () => {
		this.setState({
			deviceInOutdialog: !this.state.deviceInOutdialog
		})
	}

	handleModifyPwdDialog = () => {
		this.setState({
			modifyPwdDialog: !this.state.modifyPwdDialog
		})
	}

	openModifyIpDialog = (popupState) => {
		popupState.close();

		this.setState({
			modifyIpDialog: !this.state.modifyIpDialog
		})
	}
	openDeviceInOutdialog = (popupState) => {
		popupState.close();
		this.setState({
			deviceInOutdialog: !this.state.deviceInOutdialog
		})
	}

	openModifyPwdDialog = (popupState) => {
		popupState.close();
		this.handleModifyPwdDialog()
	}
	handleDeviceExport = () => {
		this.props.handleDeviceExport();
	}
	modifyIpAddress = () => {
		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		} else if (this.state.selectDeviceList.length > 1) {
			toast.warn("Please select only one device")
		} else {

			this.setState({
				modifyIpDialog: !this.state.modifyIpDialog
			})
			this.props.modifyIpAddress(this.state.selectDeviceList[0].id, "IPAddress", this.state.modifiedIp)
		}

	}
	setdeviceInOut = () => {

		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		} else if (this.state.selectDeviceList.length > 1) {
			toast.warn("Please select only one device")
		} else {

			this.setState({
				deviceInOutdialog: !this.state.deviceInOutdialog
			})
			this.props.setDeviceIOStatus(this.state.selectDeviceList[0].id, "DeviceIOState", this.state.devIOState)
		}
	}
	fingerPrintThreshold = () => {

		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		} else if (this.state.selectDeviceList.length > 1) {
			toast.warn("Please select only one device")
		} else {

			this.setState({
				fingerPrintThresholdDialog: !this.state.fingerPrintThresholdDialog
			})

			this.props.setFingerPrintThreshold(this.state.selectDeviceList[0].id, "MThreshold", this.state.mThreshold)
		}
	}
	modifyPwd = () => {
		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		}
		else if (this.state.selectDeviceList.length > 1) {
			toast.warn("Please select only one device")
		}
		else {
			this.setState({
				modifyPwdDialog: !this.state.modifyPwdDialog
			})
			this.props.modifyPwd(this.state.selectDeviceList[0].id, "CommPassword", this.state.confirmPassword)
		}

	}
	enableDevice = () => {
		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		}
		// else if (this.state.selectDeviceList.length > 1) {
		// 	toast.warn("Please select only one device")
		// }
		else {

			this.setState({
				enable: !this.state.enable
			})
			let ids = '';
			this.state.selectDeviceList.map((row) => (ids += row.id + ','))
			this.props.enableDevice(ids)
		}

	}
	desableDevice = () => {
		if (this.state.selectDeviceList.length <= 0) {
			toast.warn("Please select any one device")
		}
		//  else if (this.state.selectDeviceList.length > 1) {
		// 	toast.warn("Please select only one device")
		// } 
		else {

			this.setState({
				desable: !this.state.desable
			})
			let ids = '';
			this.state.selectDeviceList.map((row) => (ids += row.id + ','))
			this.props.desableDevice(ids)
		}

	}
	filterOpenDialog = () => {
		this.setState({ filter: true })
	}
	filterClose = () => {
		const { query } = this.state;
		let alias = '';
		let sn = '';
		let deviceName = '';
		let ipAddress = '';
		this.setState({
			filter: false,
			alias: '',
			sn: '',
			deviceName: '',
			ipAddress: '',
		})
		this.props.getAccDevices(alias, sn, deviceName, ipAddress, query);
	}
	filterApply = () => {
		const { query } = this.state;
		this.props.getAccDevices(this.state.alias, this.state.sn, this.state.deviceName, this.state.ipAddress, query).then(() => {
			this.setState({ devices: this.props.accDevices });
		});
		this.setState({ filter: false });
	}
	render() {
		//var companyCode = localStorage.getItem("companyCode");
		const { face, faceBiodata, finger, user } = this.state.deviceCapacityData;
		var devices = this.state.devices;
		//var greeting=10;
		//const ITEM_HEIGHT = 48;
		const { totalCount, query, pageChanged } = this.state;
		const { t } = this.props;

		// let deviceDropDownData = this.state.zonesData ? this.state.zonesData.map(zones => (
		// 	<MenuItem key={zones} value={zones.value} >{zones.label}</MenuItem>
		// )) : "";
		let accTimeZoneDropDownData;
		 
       accTimeZoneDropDownData = this.state.timePeriodsdata.item? this.state.timePeriodsdata.item.map(level => (
		      <MenuItem key={level} value={level.id} >{level.name}</MenuItem>
			)) : '';
	  
			 
			  console.log("asflasdflasdfhksad"+JSON.stringify(this.state.errors))
			  
			console.log("TimePeriod Data"+ accTimeZoneDropDownData)

		const offlineDevicessName = this.props.deviceSyncTime.offlineDevName;
		const disabledDevName = this.props.deviceSyncTime.disabledDevName;
		const devicesName = this.props.deviceSyncTime.devicesName;
		const setDevicesName = this.props.setdeviceTimeZone.devicesName;
		const noSupportDevName = this.props.deviceSyncTime.noSupportDevName;
		const setDevicesDesabledDevName = this.props.setdeviceTimeZone.disabledDevName;
		const tableIcons = {
			Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
			Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
			DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
			Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
			Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
			FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
			LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
			NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
			ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
			SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
			ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
			ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
		};
 
		const columns = [
			{ field: 'deviceName', title: 'Device Name' },
			{ field: 'sn', title: 'Serial Number' },
			{ field: 'ipAddress', title: 'Ip Address' },
			{ field: 'deviceName', title: 'Device Model' },
			{ field: 'isRegistrationDevice', title: 'Registered Device' },
			{ field: 'fwVersion', title: 'Firmware Version' },
			{ field: 'enabled', title: 'Status', render: rowData => rowData.enabled ? "Online" : "Offline" },
		];
		const column = [
			{ field: '0', title: 'Rule Type' },
			{ field: '1', title: 'Rule Details' },
			{ field: '2', title: 'Description', },

		]; 
		 
		const syncAllDataToDevDeviceName=this.props.syncAllDataToDevList.devicesName;
		 
		const syncAllDataToDevDesableDeviceName=this.props.syncAllDataToDevList.disabledDevName;
		return (
			<Fragment>

				<div style={{ width: '100%' }}>
					<Dialog open={this.state.synchronizeTime} onClose={this.synchronizeTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title" onClose={this.synchronizeTime}>Synchronize Time</DialogTitle>
						<DialogContent style={{ color: "black" }}>
							<Card variant="outlined" style={{ height: "150px", color: "black", overflow: "auto" }}>
								<CardContent>
									{devicesName &&
										<Typography gutterBottom >
											{devicesName}
										</Typography>
									}
									{offlineDevicessName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                                   {t("offlineDeviceCannotOperate")}<br></br>
											{offlineDevicessName}
										</Typography>
									}
									{disabledDevName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                                 {t("disabledDeviceCannotOperate")}<br></br>
											{disabledDevName}
										</Typography>
									}
								</CardContent>
							</Card>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.closeSynchronizeDialog} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.handleDeviceTimeZoneStatus} color="primary" backgroundColor="red" disabled={!devicesName} >
							{t("ok")}
						</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						className="dialog-timeperiod"
						open={this.state.isDialogOpen}
						onClose={this.handleDialogClose}
						fullWidth={true}
						maxWidth={'sm'}
					>

						<DialogTitle id="customized-dialog-title">{"AccDeviceList"}</DialogTitle>

						<DialogContent>
						<form onSubmit={this.onSubmit} autoComplete="off">
							<Grid container>
								<Grid item xs={12}>
									<TextField className="text-fields"
										value={this.state.deviceName}
										name="deviceName" onChange={this.myChangeHandler}
										label={t("deviceName")} type="text"
										error={this.state.errors.deviceName ? true : false}
										helperText={this.state.errors.deviceName}
									/>

								</Grid>
								<Grid item xs={12}>
									<FormControl className="text-fields" >
										<InputLabel>
										{t("deviceType")}
										</InputLabel>
										<Select labelId="demo-simple-select-helper-label"
											id="iconType"
											label={t("deviceType")}
											name="iconType"
											value={this.state.iconType}
											onChange={this.onChange}
											error={this.state.errors.iconType ? true : false}

										>
											<MenuItem value={this.state.iconType}></MenuItem>
											<MenuItem value="">None</MenuItem>
											<MenuItem value="1">Door</MenuItem>
											<MenuItem value="2">Park Barrier</MenuItem>
											<MenuItem value="3">Flap Barrier</MenuItem>

										</Select>
										<FormHelperText error>{this.state.errors.iconType}</FormHelperText>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl className="text-fields" >
										<InputLabel>
										   {t("passModeTimeSegName")}
										</InputLabel>
										<Select labelId="demo-simple-select-helper-label"
											id="timeSegLevelDTO"
											label={ "timeSegLevelDTO"}
											name="timeSegLevelDTO"
											value={this.state.timeSegLevelDTO}
											onChange={this.onChange}
											error={this.state.errors.timeSegLevelDTO ? true : false}
										>

											<MenuItem value={this.state.timeSegLevelDTO}></MenuItem>
											<MenuItem value=""><em>None</em></MenuItem>
											{accTimeZoneDropDownData}
										</Select>
										<FormHelperText error>
										{this.state.errors.timeSegLevelDTO}
										</FormHelperText>
									</FormControl>
								</Grid>

								
							</Grid>
							<DialogActions className="dilog-form-buttons">
								<Button color="primary" onClick={this.upDateDeviceList} variant="contained">
									{t("commonsTableToolTipEdit")}
								</Button>
								<Button color="primary" onClick={this.handleClose} variant="contained">
								   {t("cancel")}
								</Button>
							</DialogActions>
                    </form>
						</DialogContent>
					</Dialog>


					<Grid item xs={12} sm={12}>

						{/* {this.state.showSuccessAlert  && (
						<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.showSuccessAlert} autoHideDuration={2000} onClose={this.onCloseAlert}>
							<Alert onClose={this.onCloseAlert} severity="success" >
							  success
							</Alert>
						</Snackbar>
					)} */}
					</Grid>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start">
						<Grid item xs={12}>
							<TimeLine menu="Device Management" page="Device" menuPath={REACT_URL_DEVICE_LIST} pagePath="" />
						</Grid>

					</Grid>


					<Box display="flex" p={1} bgcolor="background.paper">
						<Box p={1} flexGrow={1}>
							<p><b className="acc-head">{t('device')}</b></p>
						</Box>

						{/* <Box p={1}>
							<Paper className="search-root">
								<InputBase
									className="search-input"
									placeholder="Search"
									inputProps={{ "aria-label": "search" }}
									ref={this.input}
									type="text" name="search" onChange={this.handleInputChange} checked={this.state.isChecked} />
								<IconButton
									type="submit"
									className="search-iconButton"
									aria-label="search">
									<SearchIcon />
								</IconButton>
							</Paper>

						</Box> */}
						<Box p={1}>
							<Button
								variant="contained"
								color="primary"
								size="small"
								startIcon={<FilterListIcon />}
								onClick={this.filterOpenDialog}
							>
								{t("filter")}
							</Button>

						</Box>
					</Box>

					<Box display="flex" p={1} bgcolor="background.paper">
						<Box p={1}>
							<Button
								onClick={this.loadDeviceList}
								color="secondary"
								size="small"
								startIcon={<Refresh className="refresh-btn" />}
								className="acc-list-action-btn">
								{t("buttonCommonRefresh")}
							</Button>
						</Box>
						<Box p={1} >
							<Button
								onClick={this.deleteSelectedIds}
								color="secondary"
								size="small"
								startIcon={<Delete className="delete-btn" />}
								className="acc-list-action-btn">
								{t("buttonCommonDelete")}
							</Button>
						</Box>
						<Box p={1}>
							<PopupState variant="popover" popupId="demo-popup-menu">
								{(popupState) => (
									<React.Fragment>
										<Button
											color="secondary"
											size="small"
											startIcon={<SystemUpdateAltIcon className="delete-btn" />}
											className="acc-list-action-btn"
											{...bindTrigger(popupState)}>CheckIcon
											{t("buttonCommonControl")}
									</Button>
										<Menu {...bindMenu(popupState)}>
											<MenuItem onClick={() => this.handleDeviceOperations(popupState, 'rebootDev')} >Reboot</MenuItem>
											<MenuItem onClick={() => this.openSynchronizeDialog(popupState, 'syncTime')}>Synchronize Time</MenuItem>
											<MenuItem onClick={() => this.openEnableDeviceDialog(popupState)}>Enable</MenuItem>
											<MenuItem onClick={() => this.openDesableDeviceDialog(popupState)} >Disable</MenuItem>
											<MenuItem onClick={() => this.opensynchronizeDeviceDialog(popupState)} >Synchronize All Data to Device</MenuItem>
										</Menu>
									</React.Fragment>
								)}
							</PopupState>
						</Box>
						<Box p={1}>
							<PopupState variant="popover" popupId="demo-popup-menu">
								{(popupState) => (
									<React.Fragment>
										<Button
											color="secondary"
											size="small"
											startIcon={<SettingsIcon className="delete-btn" />}
											className="acc-list-action-btn"
											{...bindTrigger(popupState)}>
											{t("setUp")}
									</Button>
										<Menu {...bindMenu(popupState)}>

											<MenuItem onClick={() => this.openDeviceInOutdialog(popupState)}>Set Device IN/OUT Status</MenuItem>
											<MenuItem onClick={() => this.fingerPrintThresholdDialogOpen(popupState, 'SetInOut')}>Modify FingerPrintThreshold </MenuItem>
											<MenuItem onClick={() => this.setDeviceTimeZoneOpenDialog(popupState, 'DeviceTimeZone')}>Set Device Time Zone  </MenuItem>
											<MenuItem onClick={() => this.handleSetAsRegistartionDeviceOperations(popupState, 'IsRegistartionDevice')}>Set As Registration Device</MenuItem>

										</Menu>
									</React.Fragment>
								)}
							</PopupState>
						</Box>
						<Box p={1}>
							<PopupState variant="popover" popupId="demo-popup-menu">
								{(popupState) => (
									<React.Fragment>
										<Button
											color="secondary"
											size="small"
											startIcon={<PageviewIcon className="delete-btn" />}
											className="acc-list-action-btn"
											{...bindTrigger(popupState)}>
											{t("deviceViewOrGet")}
									</Button>
										<Menu {...bindMenu(popupState)}>

											<MenuItem onClick={() => this.getDeviceOptionDialogOpen(popupState)}>Get Device Option</MenuItem>
											<MenuItem onClick={() => this.getTransactionDialogOpen(popupState)}>Get Transaction</MenuItem>
											<MenuItem onClick={() => this.getDeviceRulesDialogOpen(popupState)}>View Rules Of Devices</MenuItem>
											<MenuItem onClick={() => this.getDeviceCapacityDialogOpen(popupState)}>View Device Capacity</MenuItem>
										</Menu>
									</React.Fragment>
								)}
							</PopupState>
						</Box>
						<Box p={1} flexGrow={1}>
							<PopupState variant="popover" popupId="demo-popup-menu">
								{(popupState) => (
									<React.Fragment>
										<Button
											color="secondary"
											size="small"
											startIcon={<PhonelinkSetupIcon className="delete-btn" />}
											className="acc-list-action-btn"
											{...bindTrigger(popupState)}>
											{t("communiCation")}
									</Button>
										<Menu {...bindMenu(popupState)}>
											<MenuItem onClick={() => this.openModifyIpDialog(popupState)}>Modify IpAddress</MenuItem>
											<MenuItem onClick={() => this.openModifyPwdDialog(popupState)}>Modify Communication Password</MenuItem>
											<MenuItem onClick={() => this.handleDeviceOperations(popupState, 'switchNetWorkTest')}>Switch Network Connection</MenuItem>
										</Menu>
									</React.Fragment>
								)}
							</PopupState>
						</Box>
						<Box p={1} >
							<Button
								onClick={this.handleDeviceExport}
								style={{ backgroundColor: "#5383ff", color: "white" }}
								variant="contained"
								size="small"
								startIcon={<SaveAlt />}
							>
								{t("export")}
							</Button>
						</Box>
						<Box p={1}>
							<Button
								variant="contained"
								color="primary"
								size="small"
								component={Link}
								to={{
									pathname: "/acc/accdevicedata"
								}}
								startIcon={<AddCircleOutlineRoundedIcon />}
							>
								{t("searchDevice")}
							</Button>
						</Box>
					</Box>
				</div>

				<div>
					{/* Modify IpAddress */}
					<Dialog open={this.state.modifyIpDialog} onClose={this.handleModifyIpDialog} aria-labelledby="form-dialog-title"  >
						<DialogTitle id="form-dialog-title">{t("modifyIpAddress")}</DialogTitle>
						<DialogContent>
							<TextField
								onChange={this.onChange}
								margin="dense"
								id="name"
								label= {t("ipAddress")}
								name="modifiedIp"
								type="text"
								fullWidth
							/>
							<TextField
								onChange={this.onChange}
								margin="dense"
								id="name"
								label={t("subnetMask")}
								name="modifiedSubnet"
								type="text"
								fullWidth
							/>
							<TextField
								onChange={this.onChange}
								margin="dense"
								id="name"
								label={t("gateWayAddress")}
								name="modifiedGateway"
								type="text"
								fullWidth
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleModifyIpDialog} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.modifyIpAddress} color="primary">
							{t("modify")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Filter Dialog */}
					<Dialog open={this.state.filter} onClose={this.filterClose} aria-labelledby="form-dialog-title"  >
						<DialogTitle id="form-dialog-title">Filter</DialogTitle>
						<DialogContent>
							<Box display="flex" p={1} m={2} >
								<Box p={1}>
									<TextField
										label="Device Name"
										id="outlined-size-small"
										//variant="outlined"
										size="small"
										onChange={this.onChange}
										name="alias"
										autoComplete="off"
									/>
								</Box>
								<Box p={1}>
									<TextField
										onChange={this.onChange}
										//variant="outlined"
										id="name"
										label="Serial Number"
										name="sn"
										type="text"
										size="small"
									/>
								</Box>
							</Box>
							<Box display="flex" p={1} m={2} >
								<Box p={1}>
									<TextField
										onChange={this.onChange}
										//variant="outlined"
										id="name"
										label="IP Address"
										name="ipAddress"
										type="text"
										size="small"
									/>
								</Box>
								<Box p={1}>
									<TextField
										onChange={this.onChange}
										//variant="outlined"
										id="name"
										label="Device Model"
										name="modifiedGateway"
										type="text"
										size="small"
									/>
								</Box>
							</Box>
						</DialogContent>

						<DialogActions>
							<Button onClick={this.filterApply} color="primary">
								{t("apply")}
						</Button>
							<Button onClick={this.filterClose} color="primary">
							{t("reset")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Set Device In/Out Status */}
					<Dialog open={this.state.deviceInOutdialog} onClose={this.handledeviceInOutdialog} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">Set Device In/Out Status</DialogTitle>
						<DialogContent>
							<FormControl className="text-fields" >
								<InputLabel>
									{"Set Device In/Out Status"}
								</InputLabel>
								<Select labelId="demo-simple-select-helper-label"
									id="devIOState"
									label={"Set Device In/Out Status"}
									name="devIOState"
									value={this.state.devIOState}
									onChange={this.onChange}
								//error={this.state.errors.devIOState ? true : false} 
								>
									<MenuItem value="0">{"IN"}</MenuItem>
									<MenuItem value="1">{"OUT"}</MenuItem>
								</Select>
								<FormHelperText error>{this.state.errors.devIOState}</FormHelperText>
							</FormControl>

						</DialogContent>
						<DialogActions>
							<Button onClick={this.handledeviceInOutdialog} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.setdeviceInOut} color="primary">
							{t("tableBodySaveTooltip")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Modify The fingerPrint Threshold */}
					<Dialog open={this.state.fingerPrintThresholdDialog} onClose={this.handleFingerPrintThresholdClose} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">{t("dialog.fingerPrintThreshold")}</DialogTitle>
						<DialogContent>
							<Grid container spacing={2}>


								<Grid item xs={12} sm={5} style={{ paddingTop: "20px", textAlign: "end" }}>
									The fingerPrint Threshold
						</Grid>
								<Grid item xs={12} sm={5} style={{ textAlign: "end" }}>
									<TextField
										onChange={this.onChange}
										margin="dense"
										id="name"
										label="Set Threshold Range"
										name="mThreshold"
										type="text"
										//fullWidth
										variant="outlined"
										value={this.state.mThreshold}
									/>
								</Grid>
								<Grid item xs={12} sm={2} style={{ paddingTop: "25px", justify: "flex-start" }}>
									(35-70)
						</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleFingerPrintThresholdClose} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.fingerPrintThreshold} color="primary">
							{t("modify")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Set Device Time Zone */}
					<Dialog open={this.state.setDeviceTime} onClose={this.setDeviceTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">Set Device Time Zone</DialogTitle>
						<DialogContent>
							<Card variant="outlined" style={{ height: "150px", color: "black", overflow: "auto" }}>
								<CardContent>
									{setDevicesName &&
										<Typography gutterBottom >
											{setDevicesName}
										</Typography>
									}
									{noSupportDevName &&
										<Typography gutterBottom style={{ color: "red" }} >
											{t("offlineDeviceCannotOperate")}<br></br>
											{noSupportDevName}
										</Typography>
									}

									{setDevicesDesabledDevName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                         {t("disabledDeviceCannotOperate")}<br></br>
											{setDevicesDesabledDevName}
										</Typography>
									}
								</CardContent>
							</Card>

							<FormControl className="text-fields" style={{ minWidth: "50%" }}>
								<InputLabel>
									{"Set Device Time Zone"}
								</InputLabel>
								<Select labelId="demo-simple-select-helper-label"
									id="timeZone"
									label={"Set Device In/Out Status"}
									name="timeZone"
									value={this.state.timeZone}
									onChange={this.onChange}
								>
									{
										Object.entries(this.state.timeZoneData).map(([key, val]) =>

											<MenuItem key={key} value={val} >{key}</MenuItem>
										)
									}

								</Select>
								<FormHelperText error>{this.state.errors.timeZone}</FormHelperText>
							</FormControl>

						</DialogContent>
						<DialogActions>
							<Button onClick={this.setDeviceTimeColse} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.setDeviceTimeZoneOperation} color="primary">
							{t("tableBodySaveTooltip")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Enable operation */}
					<Dialog open={this.state.enable} onClose={this.closeEnableDialog} aria-labelledby="form-dialog-title">
						<DialogTitle id="form-dialog-title">Promt</DialogTitle>
						<DialogContent>
						       {t("dialogenableOperation")}
						</DialogContent>
						<DialogActions>
							<Button onClick={this.closeEnableDialog} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.enableDevice} color="primary">
							{t("ok")}
						</Button>
						</DialogActions>
					</Dialog>

					<Dialog open={this.state.desable} onClose={this.closeDesableDialog} aria-labelledby="form-dialog-title">
						<DialogTitle id="form-dialog-title">Promt</DialogTitle>
						<DialogContent>
						{t("dialogDisableOperation")}
						</DialogContent>
						<DialogActions>
							<Button onClick={this.closeDesableDialog} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.desableDevice} color="primary">
							{t("ok")}
						</Button>
						</DialogActions>
					</Dialog>

					<Dialog open={this.state.deviceOption} onClose={this.deviceOption} aria-labelledby="form-dialog-title">
						<DialogTitle id="form-dialog-title">Promt</DialogTitle>
						<DialogContent>
						    {t("dialogGetDeviceOperation")}
						</DialogContent>
						<DialogActions>
							<Button onClick={this.closedeviceOption} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.handleDeviceOption} color="primary">
							{t("ok")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Device Capacity */}
					<Dialog open={this.state.capacity} onClose={this.capacity} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'md'} >
						<DialogTitle id="form-dialog-title">Device Capacity</DialogTitle>
						<DialogContent style={{ padding: "20px" }}>
							<Grid
								container
								direction="row"
								//justify="center"
								alignItems="center"
							>
								<Grid item xs={12}>
									DeviceName: {this.state.selectDeviceList[0] ? this.state.selectDeviceList[0].deviceName : ''}
								</Grid>
							</Grid>

							<Divider style={{ marginBottom: "20px", marginTop: "5px" }} />
							{t("software")}
						<Card variant="outlined" style={{ margin: "20px" }}>

								<CardContent>
									<Grid container spacing={3} alignItems="center">
										<Grid item xs={1}>
										</Grid>
										<Grid item xs={2} style={{ textAlign: "center" }}>
											<div className="CapacityNames">
											{t("faceQuantity")}
								</div>
											<div >
												<img src={Face} style={{ height: "30px", paddingLeft: "15px" }} alt="description of face"/>
											</div>
											<div style={{ paddingTop: "15px" }}>
												{faceBiodata}
											</div>
										</Grid>
										<Grid item xs={2} style={{ textAlign: "center" }}>
											<div className="CapacityNames">
											{t("fingerPrint")}
								</div>
											<div >
												<img src={FingerPrint} style={{ height: "30px", paddingLeft: "15px" }} alt="description of fingerprint"/>
											</div>
											<div style={{ paddingTop: "15px" }}>
												{finger}
											</div>
										</Grid>
										<Grid item xs={2} style={{ textAlign: "center" }}>
											<div className="CapacityNames">
											{t("vien")}
								</div>
											<div >
												<img src={Vein} style={{ height: "30px", paddingLeft: "15px" }} alt="description of vein"/>
											</div>
											<div style={{ paddingTop: "15px" }}>
												{user}
											</div>
										</Grid>
										<Grid item xs={2} style={{ textAlign: "center" }}>
											<div className="CapacityNames" >
											{t("face")}
								</div>
											<div >
												<img src={UserFace} style={{ height: "30px", paddingLeft: "15px" }} alt="description of userface"/>
											</div>
											<div style={{ paddingTop: "15px" }}>
												{face}
											</div>
										</Grid>
										<Grid item xs={2} style={{ textAlign: "center" }}>
											<div className="CapacityNames">
											{t("palm")}
								</div>
											<div >
												<img src={palm} style={{ height: "30px", paddingLeft: "15px" }} alt="description of palm" />
											</div>
											<div style={{ paddingTop: "15px" }} >
												{this.state.deviceCapacityData.palm}
											</div>
										</Grid>
									</Grid>

								</CardContent>

							</Card>
							<Box display="flex" p={0} bgcolor="background.paper">
								{"Firmware"}
							</Box>
							<Card variant="outlined" style={{ margin: "20px" }}>

								<CardContent>
									<Grid container spacing={3} alignItems="center">
										<Grid item xs={1}>
										</Grid>
										<Grid item xs={2} style={{ textAlign: "center" }}>
											<div className="CapacityNames">
											{t("faceQuantity")}
								</div>
											<div >
												<img src={Face} style={{ height: "30px", paddingLeft: "15px" }} alt="description of face"/>
											</div>
											<div style={{ paddingTop: "15px" }}>
												{faceBiodata}
											</div>
										</Grid>
										<Grid item xs={2} style={{ textAlign: "center" }}>
											<div className="CapacityNames">
											{t("fingerPrint")}
								</div>
											<div >
												<img src={FingerPrint} style={{ height: "30px", paddingLeft: "15px" }} alt="description of fingerprint"/>
											</div>
											<div style={{ paddingTop: "15px" }}>
												{finger}
											</div>
										</Grid>
										<Grid item xs={2} style={{ textAlign: "center" }}>
											<div className="CapacityNames">
											{t("vien")}
								</div>
											<div >
												<img src={Vein} style={{ height: "30px", paddingLeft: "15px" }} alt="description of vein"/>
											</div>
											<div style={{ paddingTop: "15px" }}>
												{user}
											</div>
										</Grid>
										<Grid item xs={2} style={{ textAlign: "center" }}>
											<div className="CapacityNames" >
											{t("face")}
								</div>
											<div >
												<img src={UserFace} style={{ height: "30px", paddingLeft: "15px" }} alt="description of userface"/>
											</div>
											<div style={{ paddingTop: "15px" }}>
												{face}
											</div>
										</Grid>
										<Grid item xs={2} style={{ textAlign: "center" }}>
											<div className="CapacityNames">
											{t("palm")}
								</div>
											<div >
												<img src={palm} style={{ height: "30px", paddingLeft: "15px" }} alt="description of palm"/>
											</div>
											<div style={{ paddingTop: "15px" }} >
												{this.state.deviceCapacityData.palm}
											</div>
										</Grid>
									</Grid>

								</CardContent>

							</Card>
						</DialogContent>
						<DialogActions  >
							<Button onClick={this.closedeviceCapacity} color="primary" fullWidth >
							{t("buttonCommonClose")}
						</Button>

						</DialogActions>
					</Dialog>
					{/* View Transactions */}
					<Dialog open={this.state.transaction} onClose={this.deviceOption} aria-labelledby="form-dialog-title" fullWidth>
						<DialogTitle id="form-dialog-title">View Transactions</DialogTitle>
						<DialogContent>
							<Card variant="outlined">
								<CardContent>
									<Typography color="textSecondary" gutterBottom>
									{t("chooseTransaction")}
							 </Typography>
							           {t("newTransactions")} <Radio
										checked={this.state.selectedValue === 'New'}
										onChange={this.handleSelectedChange}
										value="New"
										name="radio-button-demo"
									//inputProps={{ 'aria-label': 'A' }}

									/>
							 {t("allTransactions")}<Radio
										checked={this.state.selectedValue === 'All'}
										onChange={this.handleSelectedChange}
										value="All"

										name="radio-button-demo"
									//inputProps={{ 'aria-label': 'B' }}
									/>

								</CardContent>

							</Card>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.closedeviceTransaction} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.handleDeviceTransaction} color="primary">
							{t("ok")}
						</Button>
						</DialogActions>
					</Dialog>

					{/* Device Rules */}
					<Dialog open={this.state.rules} onClose={this.state.rules} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'md'}>
						<DialogTitle id="form-dialog-title">{t("dialogDeviceRules")}</DialogTitle>
						<DialogContent>
							<MaterialTable
								columns={column}
								data={this.state.rulesData}
								icons={tableIcons}
								options={{
									minBodyHeight: '100%',
									actionsColumnIndex: -1,
									addRowPosition: 'first',
									showTitle: false,
									toolbarButtonAlignment: "left",
									toolbar: false,
									// selection: true,
									pageSize: 5
								}}

							></MaterialTable>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.closedeviceRules} color="primary" style={{ paddingRight: "440px" }}>
							{t("buttonCommonClose")}
						</Button>

						</DialogActions>
					</Dialog>

					{/* Synchonize Data to device */}
					<Dialog open={this.state.synchronize} onClose={this.closeDesableDialog} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'md'}>
						<DialogTitle id="form-dialog-title">{t("dialogSyncDataToDevice")}</DialogTitle>
						<DialogContent>
							<Card variant="outlined" style={{ height: "150px", color: "black", overflow: "auto" }}>
								<CardContent> 
									{syncAllDataToDevDeviceName &&
										<Typography gutterBottom >
										 	{syncAllDataToDevDeviceName}
										</Typography>
									} 
									{syncAllDataToDevDesableDeviceName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                            {t("disabledDeviceCannotOperate")}<br></br>
											{syncAllDataToDevDesableDeviceName}
										</Typography>
									}
								</CardContent>
							</Card>

							<Box  >
								<FormControlLabel
									control={<Checkbox onChange={this.handleSyncAllChange} checked={this.state.all} name="all" />}
									label={t("optionSelectAll")}
								/>
							</Box>
							<Box display="flex" flexDirection="row" bgcolor="background.paper">

								<Box  >
									<FormControl component="fieldset"  >
										<FormGroup>
											<FormControlLabel
												control={<Checkbox onChange={this.handleSyncChange} checked={this.state.accessAuthority} value="accLevel" name="accessAuthority" />}
												label={t("optionAccessAuthority")}
											/>
											<FormControlLabel
												control={<Checkbox onChange={this.handleSyncChange} checked={this.state.AntiPassback} value="antiPassback" name="AntiPassback" />}
												label={t("optionAntiPassback")}
											/>
											<FormControlLabel
												control={<Checkbox onChange={this.handleSyncChange} checked={this.state.Linkage} value="linkage" name="Linkage" />}
												label={t("optionLinkage")}
											/>
											<FormControlLabel
												control={<Checkbox onChange={this.handleSyncChange} checked={this.state.verificationModeRule} value="verifyModeRule" name="verificationModeRule" />}
												label={t("optionVerificationModeRule")}
											/>
											<FormControlLabel
												control={<Checkbox onChange={this.handleSyncChange} checked={this.state.timeZoneholidays} value="timeZoneAndHoliday" name="timeZoneholidays" />}
												label={t("optionTimeZoneHolidays")}
											/>
										</FormGroup>

									</FormControl>
								</Box>
								<Box  >
									<FormControl component="fieldset"  >
										<FormGroup>
											<FormControlLabel
												control={<Checkbox onChange={this.handleSyncChange} checked={this.state.firstPersonOpenDoor} value="accessAuthority" name="firstPersonOpenDoor" />}
												label={t("optionFirstPersonOpenDoor")}
											/>
											<FormControlLabel
												control={<Checkbox onChange={this.handleSyncChange} checked={this.state.bgVerificationOptions} value="backgroundVerifyParam" name="bgVerificationOptions" />}
												label={t("optionBgVerificationOptions")}
											/>
											<FormControlLabel
												control={<Checkbox onChange={this.handleSyncChange} checked={this.state.doorParameters} value="doorOpt" name="doorParameters" />}
												label={t("optionDoorParameters")}
											/>
											<FormControlLabel
												control={<Checkbox onChange={this.handleSyncChange} checked={this.state.multiPersonOpenDoor} value="multiPersonOpenDoor" name="multiPersonOpenDoor" />}
												label={t("optionMultiPersonOpenDoor")}
											/>
											<FormControlLabel
												control={<Checkbox onChange={this.handleSyncChange} checked={this.state.auxiliaryInputSetting} value="auxiliaryInputSetting" name="auxiliaryInputSetting" />}
												label={t("optionAuxiliaryInputSetting")}
											/>
										</FormGroup>

									</FormControl>
								</Box>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.closesynchronizeDialog} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.handleSynchAllDataToDevice} color="primary">
							{t("syncNow")}
						</Button>
						</DialogActions>
					</Dialog>


				</div>

				<MaterialTable
					columns={columns}
					data={devices ? devices : []}
					icons={tableIcons}
					options={{
						minBodyHeight: '100%',
						actionsColumnIndex: -1,
						addRowPosition: 'first',
						showTitle: false,
						toolbarButtonAlignment: "left",
						toolbar: false,
						selection: true,
						pageSize: 5
					}}
					editable={{
						// onRowDelete: (oldData) =>
						//   new Promise((resolve) => {
						//     this.handleRowDelete(oldData.id, resolve)
						//   }),
					}}
					//onSelectionChange={(rows) => this.setState({ selectedIds: rows })}
					localization={{
						header: {
							actions: t("buttonCommonActions")
						}

					}}
					actions={[
						{
							icon: 'save',
							tooltip: t("saveUser"),
							onClick: (event, rowData) => alert("You saved " + rowData.name)
						}, {
							icon: () => <EditIcon color="secondary" />,
							tooltip: t("editDevice"),
							position: 'row',
							onClick: (event, rowData) => {
								this.handleChange(event, rowData);

							}

						},
						{
							icon: () => <DeleteOutlineIcon color="secondary" />,
							tooltip: t("deleteDevice"),
							position: 'row',
							onClick: (event, rowData) => {

								this.handleRowDelete(rowData.id)

							}

						}

					]}
					onSelectionChange={data => {
						this.setState({
							selectDeviceList: data
						})
					}}
					components={{
						Pagination: (props) => (
							<TablePagination {...props} count={totalCount} page={query.page} rowsPerPage={query.pageSize} />
						),
					}}
					onPageChange={(page) => {
						if (pageChanged) {
							this.setState(
								{ query: { ...query, page: page }, pageChanged: false }, () => this.loadDeviceList({ pageChanged: true })
							);
						}
					}}
					onRowsPerPageChange={(pageSize) => {
						this.setState({ query: { ...query, pageSize } }, this.loadDeviceList);
					}}
				/*components={{
				  Action: props => (
					<div>
					  <Box display="flex" flexDirection="row" bgcolor="background.paper">
						<Box p={1}>
							<AddNewDevice type="edit" data={props.data} source={this.props.history}/>  
						</Box>
						<Box p={1}>
						  <IconButton color="secondary"
							onClick={(event) => 
							  new Promise((resolve) => {
							  this.handleRowDelete(props.data.id, resolve)
							  })}
							>
							<DeleteIcon/>
						  </IconButton>
						</Box>
					  </Box>
					</div>
				  )
				}}*/
				></MaterialTable>

				<div>

					<Dialog open={this.state.modifyPwdDialog} onClose={this.handleModifyPwdDialog} aria-labelledby="form-dialog-title">
						<DialogTitle id="form-dialog-title">Modify IpAddress</DialogTitle>
						<DialogContent>
							<TextField
								onChange={this.onChange}
								margin="dense"
								id="name"
								label={t("labelNewPassword")}
								name="newPassword"
								type="password"
								fullWidth
							/>
							<TextField
								onChange={this.onChange}
								margin="dense"
								id="name"
								label={t("labelConfirmPassword")} 
								name="confirmPassword"
								type="password"
								fullWidth
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleModifyPwdDialog} color="primary">
							{t("cancel")}

						</Button>
							<Button onClick={this.modifyPwd} color="primary">
							{t("modify")}
						</Button>
						</DialogActions>
					</Dialog>
				</div>

			</Fragment>
		);
	}
}


const mapStateToProps = (state) => ({
	accDevices: state.AccDevice.accDeviceList,
	accFlag: state.AccDevice.response,
	response: state.AccDevice.response,
	admsAccDevices: state.AccDevice.admsAccdeviceData,
	filterDeviceList: state.AccDevice.filterDeviceList,
	errors: state.errors,
	deviceSyncTime: state.deviceSyncTimeList.deviceSyncTime,
	setdeviceTimeZone: state.deviceSyncTimeList.setdeviceTimeZone,
	updateDeviceList: state.AccDevice.updateDeviceList,
	syncAllDataToDevList: state.deviceSyncTimeList.syncAllDataToDevList
});

//export default AccDeviceList;
export default compose(withTranslation(), connect(mapStateToProps, { getAccDevices,controlDeviceOperationsSyncTime, handleSetDeviceTimeZone,handleSyncallDataToDevValidation, handleDeviceExport, handleDeviceStatus, handleDeviceTimeZoneStatus, updateDeviceList, handleFilterApply, handleSynchAllDataToDevice, handleDeviceOption, handleDeviceTransaction, handleSetAsRegistartionDeviceOperations, enableDevice, desableDevice, deleteDevice, setDeviceTimeZoneOperation, resetAlert, setFingerPrintThreshold, setDaylightSavingTime, controlDeviceOperations, setDeviceRulesOperation, setDeviceIOStatus, modifyIpAddress, modifyPwd }))(AccDeviceList);