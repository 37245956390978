import axios from 'axios';
import { JAVA_API_LEVEL, JAVA_API_LEVEL_DEPARTMENT, JAVA_API_LEVEL_DOOR, JAVA_API_LEVEL_PERSON } from './ApiEndPoints';
import { toast } from 'react-toastify';
import {
  DEPARTMENT_LIST,
  LEVEL_DEPT_LIST,
  LEVEL_DEPT_SELECT_LIST,
  LEVEL_DOOR_LIST,
  LEVEL_DOOR_SELECT_LIST,
  LEVEL_ERROR,
  LEVEL_LIST,
  LEVEL_PERSON_LIST,
  LEVEL_PERSON_SELECT_LIST,
} from '../actions/types';
import { JAVA_URL_ACc_DEPT_LIST, JAVA_URL_PERSON_PRIMARY_DETAILS, JAVA_URL_ZONE_LIST } from './EndPoints';
import { Alert } from 'antd';
import CommonUtil from '../components/Util/CommonUtils';

export const getLevelDoor = (levelId, flag, page, size) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_LEVEL_DOOR.replace('{levelId}', levelId) + '?flag=' + flag + '&page=' + page + '&size=' + size);
    if (res.status === 200 && res.data.code === 'CASI0020') {
      if (flag) {
        dispatch({
          type: LEVEL_DOOR_LIST,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: LEVEL_DOOR_SELECT_LIST,
          payload: res.data.data,
        });
      }
    } else {
      if (flag) {
        dispatch({
          type: LEVEL_DOOR_LIST,
          payload: [],
        });
      } else {
        dispatch({
          type: LEVEL_DOOR_SELECT_LIST,
          payload: [],
        });
      }
    }
  } catch {}
};

export const loadLevelDoors = (levelId, payload, addNewDoor, page, size, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/v2.0/access_level/' +
        levelId +
        '/doors?page=' +
        page +
        '&size=' +
        size +
        '&name=' +
        payload.name +
        '&deviceAlias=' +
        encodeURIComponent(payload.alias) +
        '&addNewDoor=' +
        addNewDoor
    );
    if (res.data.code === 'CASI0020') {
      if (!addNewDoor) {
        dispatch({
          type: LEVEL_DOOR_LIST,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: LEVEL_DOOR_SELECT_LIST,
          payload: res.data.data,
        });
      }
    } else {
      if (!addNewDoor) {
        dispatch({
          type: LEVEL_DOOR_LIST,
          payload: [],
        });
      } else {
        dispatch({
          type: LEVEL_DOOR_SELECT_LIST,
          payload: [],
        });
      }
    }
  } catch (error) {}
};

export const addLevelDoor = (levelId, payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.post(JAVA_API_LEVEL_DOOR.replace('{levelId}', levelId), payload);
    if (res.status === 200 && res.data.code === 'CASI0018') {
      toast.success(res.data.message);
      callBack();
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {}
};

export const removeLevelDoor = (levelId, ids, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete(JAVA_API_LEVEL_DOOR.replace('{levelId}', levelId) + '?doorIdList=' + ids);
    if (res.status === 200 && res.data.code === 'CASI0019') {
      toast.success(res.data.message);
      callBack();
    } else {
      toast.error(res.data.message);
    }
  } catch {}
};

export const getLevelPerson = (levelId, page, size) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_LEVEL_PERSON.replace('{levelId}', levelId) + '?page=' + page + '&size=' + size);
    if (res.status === 200 && res.data.code === 'CASI0001') {
      dispatch({
        type: LEVEL_PERSON_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: LEVEL_PERSON_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const addLevelPerson = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.post('/api/v2.0/access_level/person?cloudAccess=true', payload);
    if (res.status === 200 && (res.data.code === 'CASI0021' || res.data.code === 'CASI0100')) {
      toast.success(res.data.message);
      callBack();
    } else if (res.data.code === 'CASI0603') {
      toast.warning(res.data.message);
      callBack();
    } else if (res.data.code === 'CASW0100') {
      toast.warning(res.data.message);
      callBack();
    } else if (res.data.code === 'CASW0101') {
      toast.warning(res.data.message);
      callBack();
    } else if (res.data.code === 'CASE0263') {
      toast.warning(res.data.message);
      callBack();
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {}
};

export const removeLevelPerson = (levelId, employeeIds, ids, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete(JAVA_API_LEVEL_PERSON.replace('{levelId}', levelId) + '?employeePins=' + employeeIds + '&ids=' + ids);
    if (res.status === 200 && res.data.code === 'CASI0030') {
      toast.success(res.data.message);
      callBack();
    } else {
      toast.error(res.data.message);
    }
  } catch {}
};

export const getPersons = () => async (dispatch) => {
  const res = await axios.get(JAVA_URL_PERSON_PRIMARY_DETAILS);
  dispatch({
    type: LEVEL_PERSON_SELECT_LIST,
    payload: res.data.data,
  });
};

export const getDeptList = () => async (dispatch) => {
  const res = await axios.get(JAVA_URL_ACc_DEPT_LIST);
  dispatch({
    type: LEVEL_DEPT_SELECT_LIST,
    payload: res.data.data,
  });
};

export const getLevelDepartment = (levelId, page, size) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_LEVEL_DEPARTMENT.replace('{levelId}', levelId) + '?page=' + page + '&size=' + size);
    if (res.status === 200 && res.data.code === 'CASI0062') {
      dispatch({
        type: LEVEL_DEPT_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: LEVEL_DEPT_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const listDepartmentByLevel = (levelId, payload, page, size) => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/v2.0/access_level/' + levelId + '/departments' + '?page=' + page + '&size=' + size + '&name=' + payload.name + '&code=' + payload.code + '&notInLevel=false'
    );

    if (res.status === 200 && res.data.code === 'OMSI0000') {
      dispatch({
        type: LEVEL_DEPT_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: LEVEL_DEPT_LIST,
        payload: [],
      });
    }
  } catch (error) {}
};

export const listDepartmentNotInLevel = (levelId, payload, previousData, page, size) => async (dispatch) => {
  debugger;
  try {
    const res = await axios.get(
      '/api/v2.0/access_level/' + levelId + '/departments' + '?page=' + page + '&size=' + size + '&name=' + payload.name + '&code=' + payload.code + '&notInLevel=true'
    );

    if (res.status === 200 && res.data.code === 'OMSI0000') {
      if (!CommonUtil.isEmpty(previousData)) {
        var empList = previousData.department.concat(res.data.data.department);
        res.data.data.department = empList;
      }
      dispatch({
        type: DEPARTMENT_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: DEPARTMENT_LIST,
        payload: [],
      });
    }
  } catch (error) {}
};

export const removeLevelDepartment = (levelId, deptId, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete(JAVA_API_LEVEL_DEPARTMENT.replace('{levelId}', levelId) + '?departmentIds=' + deptId);
    if (res.status === 200 && res.data.code === 'CASI0064') {
      toast.success(res.data.message);
      callBack();
    } else {
      toast.error(res.data.message);
    }
  } catch {}
};

export const getLevels = (name, timeZoneName, page, size) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_LEVEL + '?isPageable=true&name=' + name + '&timeZoneName=' + timeZoneName + '&page=' + page + '&size=' + size);
    if (res.status === 200 && res.data.code === 'CASI0001') {
      dispatch({
        type: LEVEL_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: LEVEL_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const getZones = (callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_URL_ZONE_LIST);
    if (res.status === 200) {
      callBack(res.data.data);
    }
  } catch (error) {}
};

export const addLevel = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.post(JAVA_API_LEVEL, payload);
    if (res.status === 200 && res.data.code === 'CASI0015') {
      toast.success(res.data.message);
      callBack();
      dispatch({
        type: LEVEL_ERROR,
        payload: {},
      });
    } else {
      toast.error(res.data.message);
      dispatch({
        type: LEVEL_ERROR,
        payload: res.data,
      });
    }
  } catch (error) {}
};

export const updateLevel = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_LEVEL, payload);
    if (res.status === 200 && res.data.code === 'CASI0014') {
      toast.success(res.data.message);
      callBack();
      dispatch({
        type: LEVEL_ERROR,
        payload: {},
      });
    } else {
      toast.error(res.data.message);
      dispatch({
        type: LEVEL_ERROR,
        payload: res.data,
      });
    }
  } catch (error) {}
};

export const removeLevels = (ids, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete(JAVA_API_LEVEL + '?ids=' + ids);
    if (res.status === 200 && res.data.code === 'CASI0016') {
      toast.success(res.data.message);
      callBack();
    } else {
      toast.error(res.data.message);
    }
  } catch {}
};

export const resetLevelDetails = () => async (dispatch) => {
  dispatch({
    type: LEVEL_PERSON_LIST,
    payload: [],
  });

  dispatch({
    type: LEVEL_DOOR_LIST,
    payload: [],
  });

  dispatch({
    type: LEVEL_DEPT_LIST,
    payload: [],
  });
};

export const loadLevelPerson = (levelId, payload, addPerson, page, size) => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/v2.0/access_level/' +
        levelId +
        '/persons' +
        '?page=' +
        page +
        '&size=' +
        size +
        '&firstName=' +
        payload.firstName +
        '&lastName=' +
        payload.lastName +
        '&employeeCode=' +
        payload.employeeCode +
        '&addPerson=' +
        addPerson
    );
    if (res.status === 200 && res.data.code === 'CASI0001') {
      dispatch({
        type: LEVEL_PERSON_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: LEVEL_PERSON_LIST,
        payload: [],
      });
    }
  } catch {}
};
