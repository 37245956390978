import React, { forwardRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Box, Button, Grid } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as Refresh } from '../../assets/cloudaccess-svg/Refresh.svg';
import { ReactComponent as Delete } from '../../assets/cloudaccess-svg/Delete.svg';
import AddAccessLevel from './AddAccessLevel';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ViewDetailsList from './ViewDetailsList';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import QueueIcon from '@material-ui/icons/Queue';
import ViewListIcon from '@material-ui/icons/ViewList';
import { Link } from 'react-router-dom';
import { getAccessLevels, deleteAccLevel, updateAccLevel } from '../../actions/AccessLevelActions';
import { connect } from 'react-redux';
import { REACT_URL_ACCESS_LEVEL_DETAILS, JAVA_URL_TIME_PERIOD } from '../../actions/EndPoints';
import { withRouter } from 'react-router-dom';
import { getTimePeriods } from '../../actions/TimePeriodActions';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import { TablePagination } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TreeSelect } from 'antd';
import { JAVA_URL_ZONE_LIST } from '../../actions/EndPoints';
import { FormHelperText } from '@material-ui/core';
import CommonUtil from '../Util/CommonUtils';
import FilterListIcon from '@material-ui/icons/FilterList';

const styles = (theme) => ({
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 250,
    },
  },
  formcontrol: {
    margin: theme.spacing(1),
    width: 250,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className='dilog-form-header' {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className='dilog-form-closeButton' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class AccessLevelTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen1: false,
      isDialogOpen2: false,
      isDialogOpen3: false,
      isDialogOpen4: false,
      open: false,
      accesLevesList: [],
      errorMessages: [],
      errors: {},
      showError: '',
      showSuccessAlert: false,
      resetAlert: false,
      name: '',
      authAreaName: '',
      doorCount: 0,
      dialogText: '',
      isDialogOpen: false,
      isChecked: false,
      timeZone: '',
      id: '',
      accTimeSegId: '',
      accTimeSegName: '',
      timePeriodsdata: [],
      zoneHierarchy: '',
      zoneId: '',
      timeSegLevelDTO: '',
      timeZoneName: '',
      query: {
        page: 0,
        pageSize: 5,
      },
      totalCount: 0,
      pageChanged: true,
    };
    this.handleDialogClose1 = this.handleDialogClose1.bind(this);
    this.handleDialogClose2 = this.handleDialogClose2.bind(this);
    this.handleDialogClose3 = this.handleDialogClose3.bind(this);
    this.handleDialogClose4 = this.handleDialogClose4.bind(this);
    this.togglePanel = this.togglePanel.bind(this);
    this.onTimeZoneChange = this.onTimeZoneChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleRowDelete = this.handleRowDelete.bind(this);
  }

  handleDialogClose1() {
    console.log('Clicked Close');
    this.setState({
      isDialogOpen1: false,
    });
  }
  async handleRowDelete(id, resolve) {
    this.props.deleteAccLevel(id, this.props.history);
    // setTimeout(() => {
    // 	axios.get(JAVA_URL_ACCESS_LEVEL_LIST)
    // 	  .then(( response)=> {
    // 		  alert("axios")
    // 		  this.setState({
    // 			accesLevesList: response.data.data, isLoading: false
    // 		  });
    // 		  });
    // 		  if((Object.keys(this.state.errors).length !== 0)){
    // 			  this.setState({showError:''})
    // 		  }

    //   }, 500)
    resolve();
  }
  handleDialogClose2() {
    this.setState({ isDialogOpen2: false });
  }
  componentDidMount() {
    this.fetchData();
    axios.get(JAVA_URL_ZONE_LIST).then((response) => {
      console.log('response' + JSON.stringify(response.data.data));
      const treeData = CommonUtil.TreeDataFormat(response.data.data ? response.data.data : '');
      console.log('treeData' + JSON.stringify(treeData));
      this.setState({
        zoneHierarchy: treeData,
      });
    });

    var name = '';
    var remark = '';
    axios.get(JAVA_URL_TIME_PERIOD + '?name=' + name + '&remark=' + remark).then((response) => {
      this.setState({
        timePeriodsdata: response.data.data ? response.data.data : [],
      });
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.accLevelList) {
      if (this.props.accLevelList !== prevProps.accLevelList) {
        this.setState({ accesLevesList: this.props.accLevelList.item });
      }
    }
    if (this.props.errors !== prevProps.errors) {
      JSON.stringify(this.props.errors);
      if (Object.keys(this.props.errors).length === 0 && this.props.errors.constructor === Object) {
        this.setState({
          isDialogOpen: false,
          errors: {},
        });
      }
    }
    if (this.props.accLevelList) {
      if (this.props.accLevelList !== prevProps.accLevelList) {
        if (this.props.accLevelList.totalCount !== undefined) {
          this.setState({ totalCount: this.props.accLevelList.totalCount });
        } else {
          this.setState({ totalCount: 0 });
        }
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  fetchData = (additionalState) => {
    const { query } = this.state;

    this.props.getAccessLevels(this.state.name, this.state.timeZoneName, query, this.state.history).then(() => {
      this.setState({ ...additionalState });
    });
  };
  getTimeZoneData() {}
  handleDialogClose3() {
    this.setState({ isDialogOpen3: false });
  }

  handleDialogClose4() {
    this.setState({ isDialogOpen4: false });
  }
  filterOpenDialog = () => {
    this.setState({ filter: true });
  };
  filterClose = () => {
    const { query } = this.state;
    let name = '';
    let timeZoneName = '';
    this.props.getAccessLevels(name, timeZoneName, query, this.state.history);
    this.setState({
      filter: false,
      name: '',
      remark: '',
    });
  };
  filterApply = () => {
    const { query } = this.state;
    this.props.getAccessLevels(this.state.name, this.state.timeZoneName, query, this.state.history);
    // .then(() => {
    // 	this.setState({ accesLevesList: this.props.accLevelList });
    // })

    this.setState({
      filter: false,
      name: '',
      deviceAlias: '',
    });
  };
  togglePanel() {
    this.setState({
      open: !this.state.open,
    });
  }

  handleChange = (event, rowData) => {
    const target = event.target;
    const value = target.checked;

    this.setState({
      isChecked: value,
      isDialogOpen: true,
      id: rowData.id,
      name: rowData.name,
      authAreaName: rowData.authAreaName,
      timeSegLevelDTO: rowData.timeSegLevelDTO,

      zoneId: rowData.zoneId,
      [event.target.name]: event.target.value,
    });
  };
  updateAccLevel = () => {
    const { query } = this.state;
    const updateDeviceList = {
      id: this.state.id,
      name: this.state.name,
      authAreaName: this.state.authAreaName,
      zoneId: this.state.zoneId,
      timeSegLevelDTO: this.state.timeSegLevelDTO,
    };
    this.props.updateAccLevel(updateDeviceList, query);
    this.setState({ name: '' });
    //this.getAcceslevelsdata();
    if (this.state.errors) {
    } else {
      this.handleReset();
    }
    // setTimeout(() => {
    // 	axios.get(JAVA_URL_ACCESS_LEVEL_LIST)
    // 		.then((response) => {
    // 			this.setState({
    // 				accesLevesList: response.data.data.item, isLoading: false
    // 			});
    // 		});
    // 	if ((Object.keys(this.state.errors).length !== 0)) {
    // 		this.setState({ showError: '' })
    // 	}

    // }, 500)
    //this.setState({isDialogOpen:false})
  };
  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleClose = () => {
    console.log('Clicked Close');
    this.setState({
      isDialogOpen: false,
      errors: {},
    });
  };
  onTimeZoneChange(e) {
    this.setState({ zoneId: e });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    //alert(this.state.authAreaName)
    const { classes } = this.props;
    console.log('Access Level List : I was triggered during render');
    const { t } = this.props;

    let accTimeZoneDropDownData = this.state.timePeriodsdata.item
      ? this.state.timePeriodsdata.item.map((level) => (
          <MenuItem key={level} value={level.id}>
            {level.name}
          </MenuItem>
        ))
      : '';
    const tableIcons = {
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />),
    };
    const { totalCount, query, pageChanged } = this.state;

    const transactionsColumns = [
      { field: 'name', title: t('tableColoumAccessLevelName') },
      { field: 'timeSegLevelDT0', title: t('tableColoumAccessLevelTimeZone') },
      { field: 'doorCount', title: t('tableColoumAccessLevelDoorCount') },
      { field: 'personCount', title: t('tableColoumAccessLevelPersonCount') },
    ];

    const doorColumns = [
      { field: 'doorName', title: t('tableHeaderAccReaderDoorName') },
      { field: 'deviceName', title: t('tableHeaderCommonDeviceName') },
    ];

    const empColumns = [
      { field: 'empId', title: t('tableHeaderEmployeeID') },
      { field: 'firstName', title: t('tableHeaderFirstName') },
      { field: 'lastName', title: t('tableHeaderLastName') },
      { field: 'department', title: t('tableHeaderDepartment') },
    ];

    const empData = [
      { empId: 'emp1', firstName: 'First', lastName: 'First', department: 'Department1' },
      { empId: 'emp2', firstName: 'Second', lastName: 'Second', department: 'Department2' },
      { empId: 'emp3', firstName: 'Third', lastName: 'Third', department: 'Department3' },
      { empId: 'emp4', firstName: 'Fourth', lastName: 'Fourth', department: 'Department4' },
      { empId: 'emp5', firstName: 'Fifth', lastName: 'Fifth', department: 'Department5' },
    ];

    const deptColumns = [
      { field: 'departmentNo', title: t('tableHeaderDepartmentNumber') },
      { field: 'deviceName', title: t('tableHeaderCommonDeviceName') },
    ];

    const deptData = [
      { departmentNo: 'dept1', deviceName: 'device1' },
      { departmentNo: 'dept2', deviceName: 'device2' },
      { departmentNo: 'dept3', deviceName: 'device3' },
      { departmentNo: 'dept4', deviceName: 'device4' },
      { departmentNo: 'dept5', deviceName: 'device5' },
    ];

    return (
      <div>
        <div style={{ width: '100%' }}>
          <Dialog open={this.state.filter} onClose={this.filterClose} aria-labelledby='form-dialog-title' fullWidth={true} maxWidth={'xs'}>
            <DialogTitle id='form-dialog-title'>Filter</DialogTitle>
            <DialogContent>
              <Grid item xs={12}>
                <TextField className='text-fields' name='name' onChange={this.onChange} label={t('tableColoumAccessLevelName')} type='text' />
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formcontrol}>
                  <InputLabel>{'Time Period'}</InputLabel>
                  <Select
                    labelId='demo-simple-select-helper-label'
                    id='timeZoneName'
                    label={t('tableColoumAccessLevelTimeZone')}
                    name='timeZoneName'
                    value={this.state.timeZoneName}
                    onChange={this.onChange}
                    error={this.state.errors.timeZoneName ? true : false}
                  >
                    <MenuItem value={this.state.timeSegLevelDTO}></MenuItem>
                    <MenuItem value=''>
                      <em>{t('none')}</em>
                    </MenuItem>
                    {accTimeZoneDropDownData}
                  </Select>
                  <FormHelperText error>{this.state.errors.timeSegLevelDTO}</FormHelperText>
                </FormControl>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.filterApply} color='primary'>
                {t('apply')}
              </Button>
              <Button onClick={this.filterClose} color='primary'>
                {t('reset')}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog className='dialog-timeperiod' open={this.state.isDialogOpen} onClose={this.handleDialogClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle id='customized-dialog-title'>{'Edit'}</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    className='text-fields'
                    value={this.state.name}
                    name='name'
                    onChange={this.myChangeHandler}
                    label={t('tableColoumAccessLevelName')}
                    type='text'
                    error={this.state.errors.name ? true : false}
                    helperText={this.state.errors.name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl className={classes.formcontrol}>
                    <InputLabel>{'Time Period'}</InputLabel>
                    <Select
                      labelId='demo-simple-select-helper-label'
                      id='timeSegLevelDTO'
                      label={'timeSegLevelDTO'}
                      name='timeSegLevelDTO'
                      value={this.state.timeSegLevelDTO}
                      onChange={this.onChange}
                      error={this.state.errors.timeSegLevelDTO ? true : false}
                    >
                      <MenuItem value={this.state.timeSegLevelDTO}></MenuItem>
                      <MenuItem value=''>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {accTimeZoneDropDownData}
                    </Select>
                    <FormHelperText error>{this.state.errors.timeSegLevelDTO}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className='field-container field-container-tree'>
                    <InputLabel>{t('tableColoumAccessLevelTimeZone')}</InputLabel>
                    <div className='field-label field-label-tree'>
                      <TreeSelect
                        style={{ width: '100%' }}
                        //value={this.state.zoneId}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={this.state.zoneHierarchy}
                        placeholder='Select Parent'
                        treeDefaultExpandAll={true}
                        //value={this.state.parentZone == null ?'':this.state.parentZone.id}
                        onChange={this.onTimeZoneChange}
                        defaultValue={this.state.zoneId}
                        name='zoneId'
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <DialogActions className='dilog-form-buttons'>
                <Button color='primary' onClick={this.updateAccLevel} variant='contained'>
                  {t('bottomUpdate')}
                </Button>
                <Button color='primary' onClick={this.handleClose} variant='contained'>
                  {t('cancel')}
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>

          <Box display='flex' p={1} bgcolor='background.paper'>
            <Box p={1} flexGrow={1}>
              <p>
                <b className='acc-head'>{t('commonAccessLevelTitle')}</b>
              </p>
            </Box>
            {/* <Box p={1}>
							<Paper className="search-root">
								<InputBase
									className="search-input"
									placeholder={t("commonsTableToolBarSearch")}
									inputProps={{ "aria-label": "search" }}
									ref={this.input}
									type="text" name="search" onChange={this.handleInputChange} checked={this.state.isChecked} />
								<IconButton
									type="submit"
									className="search-iconButton"
									aria-label="search">
									<SearchIcon />
								</IconButton>
							</Paper>
						</Box> */}
            <Box p={1}>
              <Button variant='contained' color='primary' size='small' startIcon={<FilterListIcon />} onClick={this.filterOpenDialog}>
                {t('filter')}
              </Button>
            </Box>
          </Box>
          <Box display='flex' p={1} bgcolor='background.paper'>
            <Box p={1}>
              <Button onClick={this.fetchData} color='secondary' size='small' startIcon={<Refresh className='refresh-btn' />} className='acc-list-action-btn'>
                {t('buttonCommonRefresh')}
              </Button>
            </Box>
            <Box p={1} flexGrow={1}>
              <Button color='secondary' size='small' startIcon={<Delete className='delete-btn' />} className='acc-list-action-btn'>
                {t('buttonCommonDelete')}
              </Button>
            </Box>
            <Box p={1}>
              <AddAccessLevel />
            </Box>
          </Box>
        </div>
        <MaterialTable
          columns={transactionsColumns}
          data={this.state.accesLevesList ? this.state.accesLevesList : []}
          icons={tableIcons}
          options={{
            minBodyHeight: '100%',
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            showTitle: false,
            toolbarButtonAlignment: 'left',
            toolbar: false,
            //selection: true,
            pageSize: 5,
          }}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                this.handleRowDelete(oldData.id, resolve);
              }),
          }}
          actions={[
            {
              icon: () => (
                <IconButton aria-label='vehicles' component={Link} to={REACT_URL_ACCESS_LEVEL_DETAILS}>
                  <ViewListIcon color='secondary' />
                </IconButton>
              ),
              tooltip: t('commonsTableActionViewDetails'),
              onClick: (event, rowData) => {
                this.props.history.push(REACT_URL_ACCESS_LEVEL_DETAILS, rowData.id);
              },
            },
            {
              icon: () => <EditIcon color='secondary' />,
              tooltip: 'Edit device',
              position: 'row',
              onClick: (event, rowData) => {
                this.handleChange(event, rowData);
              },
            },
          ]}
          localization={{
            pagination: {
              // eslint-disable-next-line
              labelDisplayedRows: '{from}-{to}' + '  ' + t('commonsTablePaginationOf') + '  ' + '{count}',
              labelRowsSelect: t('commonsTablePaginationRow'),
              firstAriaLabel: t('firstPage'),
              firstTooltip: t('commonsTablePaginationFirstPage'),
              previousAriaLabel: t('previousPage'),
              previousTooltip: t('commonsTablePaginationPreviousPage'),
              nextAriaLabel: t('nextPage'),
              nextTooltip: t('commonsTablePaginationNextPage'),
              lastAriaLabel: t('lastPage'),
              lastTooltip: t('commonsTablePaginationLastPage'),
            },

            header: {
              actions: t('buttonCommonActions'),
            },
            body: {
              emptyDataSourceMessage: t('tableCommonNoRecordDisplay'),
              editTooltip: t('commonsTableToolTipEdit'),
              deleteTooltip: t('commonsTableToolDelete'),
              filterRow: {
                filterTooltip: 'Filter',
              },
              editRow: {
                cancelTooltip: t('tableBodyCancelTooltip'),
                saveTooltip: t('tableBodySaveTooltip'),
                deleteText: t('tableBodyDeleteRowWarningMsg'),
              },
            },
            toolbar: {
              searchPlaceholder: t('commonsTableToolBarSearch'),
              searchTooltip: t('commonsTableToolBarSearch'),
              // eslint-disable-next-line
              nRowsSelected: '{0}' + ' ' + t('commonsTablePaginationRow') + ' ' + t('selected'),
            },
          }}
          components={{
            Pagination: (props) => <TablePagination {...props} count={totalCount} page={query.page} rowsPerPage={query.pageSize} />,
          }}
          onPageChange={(page) => {
            if (pageChanged) {
              this.setState({ query: { ...query, page: page }, pageChanged: false }, () => this.fetchData({ pageChanged: true }));
            }
          }}
          onRowsPerPageChange={(pageSize) => {
            this.setState({ query: { ...query, pageSize } }, this.fetchData);
          }}
        />

        <div>
          <Dialog open={this.state.isDialogOpen1} className='dialog-timeperiod' onClose={this.handleDialogClose1} fullWidth={true} maxWidth={'md'}>
            <DialogTitle onClose={this.handleDialogClose1}>{t('commonsTableActionAddDoor')}</DialogTitle>
            <DialogContent>
              <MaterialTable
                columns={doorColumns}
                data={'doorData'}
                icons={tableIcons}
                options={{
                  search: true,
                  selection: true,
                  showTitle: false,
                }}
                actions={[
                  {
                    tooltip: t('commonsTableActionAddAllSelectDoors'),
                    icon: () => <QueueIcon color='secondary' />,
                    onClick: (event, data) => alert('You want to add ' + data.length + ' rows'),
                  },
                ]}
                localization={{
                  pagination: {
                    // eslint-disable-next-line
                    labelDisplayedRows: '{from}-{to}' + '  ' + t('commonsTablePaginationOf') + '  ' + '{count}',
                    labelRowsSelect: t('commonsTablePaginationRow'),
                    firstAriaLabel: t('firstPage'),
                    firstTooltip: t('commonsTablePaginationFirstPage'),
                    previousAriaLabel: t('previousPage'),
                    previousTooltip: t('commonsTablePaginationPreviousPage'),
                    nextAriaLabel: t('nextPage'),
                    nextTooltip: t('commonsTablePaginationNextPage'),
                    lastAriaLabel: t('lastPage'),
                    lastTooltip: t('commonsTablePaginationLastPage'),
                  },

                  header: {
                    actions: t('buttonCommonActions'),
                  },
                  body: {
                    emptyDataSourceMessage: t('tableCommonNoRecordDisplay'),
                    editTooltip: t('commonsTableToolTipEdit'),
                    deleteTooltip: t('commonsTableToolDelete'),
                    filterRow: {
                      filterTooltip: 'Filter',
                    },
                    editRow: {
                      cancelTooltip: t('tableBodyCancelTooltip'),
                      saveTooltip: t('tableBodySaveTooltip'),
                      deleteText: t('tableBodyDeleteRowWarningMsg'),
                    },
                  },
                  toolbar: {
                    searchPlaceholder: t('commonsTableToolBarSearch'),
                    searchTooltip: t('commonsTableToolBarSearch'),
                    // eslint-disable-next-line
                    nRowsSelected: '{0}' + ' ' + t('commonsTablePaginationRow') + ' ' + t('selected'),
                  },
                }}
              />
            </DialogContent>
          </Dialog>
        </div>

        <div>
          <Dialog open={this.state.isDialogOpen2} className='dialog-timeperiod' onClose={this.handleDialogClose2} fullWidth={true} maxWidth={'md'}>
            <DialogTitle onClose={this.handleDialogClose2}>{t('commonsTableActionAddEmployee')}</DialogTitle>
            <DialogContent>
              <MaterialTable
                columns={empColumns}
                data={empData}
                icons={tableIcons}
                options={{
                  search: true,
                  selection: true,
                  showTitle: false,
                  headerStyle: { backgroundColor: 'white' },
                }}
                actions={[
                  {
                    tooltip: t('commonsTableActionAddAllSelectEmployees'),
                    icon: () => <PersonAddIcon color='secondary' />,
                    onClick: (event, data) => alert('You want to add ' + data.length + ' rows'),
                  },
                ]}
                localization={{
                  pagination: {
                    // eslint-disable-next-line
                    labelDisplayedRows: '{from}-{to}' + '  ' + t('commonsTablePaginationOf') + '  ' + '{count}',
                    labelRowsSelect: t('commonsTablePaginationRow'),
                    firstAriaLabel: t('firstPage'),
                    firstTooltip: t('commonsTablePaginationFirstPage'),
                    previousAriaLabel: t('previousPage'),
                    previousTooltip: t('commonsTablePaginationPreviousPage'),
                    nextAriaLabel: t('nextPage'),
                    nextTooltip: t('commonsTablePaginationNextPage'),
                    lastAriaLabel: t('lastPage'),
                    lastTooltip: t('commonsTablePaginationLastPage'),
                  },

                  header: {
                    actions: t('buttonCommonActions'),
                  },
                  body: {
                    emptyDataSourceMessage: t('tableCommonNoRecordDisplay'),
                    editTooltip: t('commonsTableToolTipEdit'),
                    deleteTooltip: t('commonsTableToolDelete'),
                    filterRow: {
                      filterTooltip: 'Filter',
                    },
                    editRow: {
                      cancelTooltip: t('tableBodyCancelTooltip'),
                      saveTooltip: t('tableBodySaveTooltip'),
                      deleteText: t('tableBodyDeleteRowWarningMsg'),
                    },
                  },
                  toolbar: {
                    searchPlaceholder: t('commonsTableToolBarSearch'),
                    searchTooltip: t('commonsTableToolBarSearch'),
                    // eslint-disable-next-line
                    nRowsSelected: '{0}' + ' ' + t('commonsTablePaginationRow') + ' ' + t('selected'),
                  },
                }}
              />
            </DialogContent>
          </Dialog>
        </div>

        <div>
          <Dialog open={this.state.isDialogOpen3} className='dialog-timeperiod' onClose={this.handleDialogClose3} fullWidth={true} maxWidth={'md'}>
            <DialogTitle onClose={this.handleDialogClose3}>{t('commonsTableActionDepartment')}</DialogTitle>
            <DialogContent>
              <MaterialTable
                columns={deptColumns}
                data={deptData}
                icons={tableIcons}
                options={{
                  search: true,
                  selection: true,
                  showTitle: false,
                  headerStyle: { backgroundColor: 'white' },
                }}
                actions={[
                  {
                    tooltip: t('commonsTableActionAddAllSelectDepartments'),
                    icon: () => <GroupAddIcon color='secondary' />,
                    onClick: (event, data) => alert('adding ' + data.length + ' rows'),
                  },
                ]}
                localization={{
                  pagination: {
                    // eslint-disable-next-line
                    labelDisplayedRows: '{from}-{to}' + '  ' + t('commonsTablePaginationOf') + '  ' + '{count}',
                    labelRowsSelect: t('commonsTablePaginationRow'),
                    firstAriaLabel: t('firstPage'),
                    firstTooltip: t('commonsTablePaginationFirstPage'),
                    previousAriaLabel: t('previousPage'),
                    previousTooltip: t('commonsTablePaginationPreviousPage'),
                    nextAriaLabel: t('nextPage'),
                    nextTooltip: t('commonsTablePaginationNextPage'),
                    lastAriaLabel: t('lastPage'),
                    lastTooltip: t('commonsTablePaginationLastPage'),
                  },

                  header: {
                    actions: t('buttonCommonActions'),
                  },
                  body: {
                    emptyDataSourceMessage: t('tableCommonNoRecordDisplay'),
                    editTooltip: t('commonsTableToolTipEdit'),
                    deleteTooltip: t('commonsTableToolDelete'),
                    filterRow: {
                      filterTooltip: 'Filter',
                    },
                    editRow: {
                      cancelTooltip: t('tableBodyCancelTooltip'),
                      saveTooltip: t('tableBodySaveTooltip'),
                      deleteText: t('tableBodyDeleteRowWarningMsg'),
                    },
                  },
                  toolbar: {
                    searchPlaceholder: t('commonsTableToolBarSearch'),
                    searchTooltip: t('commonsTableToolBarSearch'),
                    // eslint-disable-next-line
                    nRowsSelected: '{0}' + ' ' + t('commonsTablePaginationRow') + ' ' + t('selected'),
                  },
                }}
              />
            </DialogContent>
          </Dialog>
        </div>

        <div>
          <Dialog open={this.state.isDialogOpen4} className='dilog-view-details' onClose={this.handleDialogClose4} fullScreen>
            <DialogTitle onClose={this.handleDialogClose4}>{t('commonsTableActionViewDetails')}</DialogTitle>
            <DialogContent>
              <ViewDetailsList />
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  transactions: state.RTransactions.transactionsList,
  accLevelList: state.AccLevelList.accLevelList,
  accLevelListLevelId: state.AccLevelList.accLevelListByLevelId,
  errors: state.errors,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, { getAccessLevels, deleteAccLevel, updateAccLevel, getTimePeriods }),
  withStyles(styles, { withTheme: true })
)(withRouter(AccessLevelTable));
