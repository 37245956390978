import { Button,Grid} from '@material-ui/core';
import React, { Fragment, forwardRef } from 'react';
import axios from "axios";
import { JAVA_URL_AUXIN } from "../../actions/EndPoints";
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { compose } from 'redux'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAccAuxIns, updateAccAuxIn } from '../../actions/AccAuxInActions';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ReactComponent as Refresh } from "../../assets/cloudaccess-svg/Refresh.svg";
import { TablePagination } from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from 'react-i18next';
const styles = theme => ({
	form: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: 250
		}
	},
	formcontrol: {
		width: 150
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	}
});

const DialogTitle = withStyles(styles)(props => {
	const { children, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className="dilog-filter-head" {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className="dilog-form-closeButton" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});
class AccAuxInList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			name: "",
			deviceAlias : '',
			auxIns: [],
			remark: "",
			deviceName: this.props.deviceName,
			auxNo: this.props.auxNo,
			printerNumber: this.props.printerNumber,
			updateAccAuxIn: [],
			errorMessages: [],
			errors: {},
			showError: '',
			showSuccessAlert: false,
			resetAlert: false,
			accAuxInList: [],
			query: {
				page: 0,
				pageSize: 5,
			  },
			  totalCount: 0,
			  pageChanged: true,
			  filter: false,
		}; 
		this.handleRowUpdate = this.handleRowUpdate.bind(this);
		this.onCloseAlert = this.onCloseAlert.bind(this);
	}

	componentDidMount() { 
		this.fetchData();
		//this.setState({ readers: this.props.accAuxIns });
	}

	componentDidUpdate(prevProps) {
		if (this.props.response !== prevProps.response) {
			this.setState({ showSuccessAlert: this.props.response })
		}
		if (this.props.accAuxIns !== prevProps.accAuxIns) {
			this.setState({ devices: this.props.accAuxIns })
		}
		if(this.props.accAuxInList!== undefined)
		{
		if (this.props.accAuxInList !== prevProps.accAuxInList) {
			this.setState({ accAuxInList: this.props.accAuxInList.item })
		}
	}
	if(this.props.accAuxInList !== undefined)
    {
		if (this.props.accAuxInList !== prevProps.accAuxInList) { 
		if (this.props.accAuxInList.totalCount !== undefined) {
			this.setState({ totalCount: this.props.accAuxInList.totalCount });
		} else {
			this.setState({ totalCount: 0 });
		} 
      }
    }
	} 
  fetchData=(additionalState)=>
    {
		const { query } = this.state;
		this.props.getAccAuxIns(this.state.name,this.state.deviceAlias,query,this.state.history).then(() => {
		this.setState({ ...additionalState }); 
		})
  }
	filterOpenDialog=()=>{
		this.setState({filter: true})
	}
	filterClose=()=>{
		const { query } = this.state;
		let name= '';
		let deviceAlias= '';
		this.props.getAccAuxIns(name,deviceAlias,query,this.state.history)
	       this.setState({filter: false,name: '',
	                                deviceAlias : '',})
	}
	filterApply=()=>{
	const { query } = this.state;
		this.props.getAccAuxIns(this.state.name,this.state.deviceAlias,query,this.state.history)
		.then(() => {
			this.setState({ accAuxInList: this.props.accAuxInList });

		})

		this.setState({filter: false,
			name: '',
			deviceAlias : '',
		   });
	}
	onCloseAlert() {
		this.props.resetAlert();
	}

	handleRowUpdate = (newData, oldData, resolve) => {
		let errorList = []
		if (newData.name === "" || newData.remark === "") {
			errorList.push("Please Enter Name And Remark")
		}
		if (errorList.length < 1) {
			oldData.name = newData.name;
			oldData.remark = newData.remark;
			this.props.updateAccAuxIn(oldData, this.props.deviceName, this.props.auxNo, this.props.printerNumber);
			setTimeout(() => {
				axios.get(JAVA_URL_AUXIN)
					.then((response) => {
						this.setState({
							updateAccAuxIn: response.data.data, isLoading: false
						});
					});
				if ((Object.keys(this.state.errors).length !== 0)) {
					this.setState({ showError: '' })
				}
				resolve();
			}, 500)
		} else {
			this.setState({ errorMessages: errorList })
			this.setState({ iserror: true, showError: '' })
			resolve()
		}
	}
	onChange=(e)=> { 
		this.setState({ [e.target.name]: e.target.value }); 
	  }
	render() { 
		const { t } = this.props;
		const tableIcons = {
			Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
			Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
			DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
			Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
			Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
			FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
			LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
			NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
			ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
			SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
			ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
			ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
		};

		const columns = [
			{ field: 'name', title: t("tableHeaderCommonName") ,validate: rowData => (rowData.name === "") ? { isValid: false, helperText: "PleaseEnterAccInName"}: (rowData.name === undefined)?{   }:true, },
			{ field: `accDeviceDO.deviceName` , title: t("deviceName"), editable: 'never' },
			{ field: 'auxNo', title: t("tableHeaderCommonNumber") , editable: 'never' },
			{ field: 'printerNumber', title: t("tableHeaderAccAuxInPrintedName"), editable: 'never' },
			{ field: 'remark', title: t("tableHeaderCommonRemark") },
		];

		const { totalCount, query, pageChanged } = this.state;
		 
              const accAuxInList=this.state.accAuxInList;
		return (
			<Fragment>
					<div style={{ width: '100%' }}>
					<Dialog open={this.state.filter} onClose={this.filterClose} aria-labelledby="form-dialog-title" fullWidth={true}
			maxWidth={'xs'} >


						<DialogTitle id="form-dialog-title">Filter</DialogTitle>
						<DialogContent>
						<Grid item xs = {12}>
						<TextField className="text-fields"
						value= {this.state.name} name="name" 
						onChange={this.onChange} 
						label={t("name")}  type="text" 
						error={this.state.errors.name ? true : false} 
						helperText={this.state.errors.name}	
						autoComplete="off"
								/>
					</Grid>
					<Grid item xs = {12}>
						<TextField className="text-fields"
						value= {this.state.deviceAlias} name="deviceAlias" 
						onChange={this.onChange} 
						label={t("deviceAlias")} type="text" 
						error={this.state.errors.deviceAlias ? true : false} 
						helperText={this.state.errors.deviceAlias}	
								autoComplete="off"
								/>
					</Grid>
				 

						</DialogContent>
						<DialogActions>
						<Button onClick={this.filterApply} color="primary">
						{t("apply")}
						</Button>
						<Button onClick={this.filterClose} color="primary">
						{t("reset")}
						</Button>
						</DialogActions>
					</Dialog>
						<Box display="flex" p={1} bgcolor="background.paper">
							<Box p={1} flexGrow={1}>
								<p><b className="acc-head">Auxiliary Input</b></p>
							</Box>
							{/* <Box p={1}>
								<Paper className="search-root">
									<InputBase
										className="search-input"
										placeholder="Search"
										inputProps={{ "aria-label": "search" }}
										ref={this.input}
										type="text" name="search" onChange={this.handleInputChange} checked={this.state.isChecked} />
									<IconButton
										type="submit"
										className="search-iconButton"
										aria-label="search">
										<SearchIcon />
									</IconButton>
								</Paper>
							</Box> */}
							<Box p={1}>
								<Button
									variant="contained"
									color="primary"
									size="small"
									startIcon={<FilterListIcon />}
									onClick={this.filterOpenDialog}  
								>
								{t("filter")} 
							</Button>  
								</Box> 
						</Box>
						<Box display="flex" p={1} bgcolor="background.paper">
							<Box p={1}>
								<Button
									onClick={this.fetchData}
									color="secondary"
									size="small"
									startIcon={<Refresh className="refresh-btn" />}
									className="acc-list-action-btn">
									{t("refresh")} 
							</Button>
							</Box>
						</Box>
					</div>
						<MaterialTable
							columns={columns}
							data={accAuxInList ? accAuxInList : []}
							icons={tableIcons}
							options={{
								minBodyHeight: '100%',
								actionsColumnIndex: -1,
								addRowPosition: 'first',
								showTitle: false,
								toolbarButtonAlignment: "left",
								toolbar: false,
								selection: true,
								pageSize: 5
							}}

							editable={{
								onRowUpdate: (newData, oldData) =>
									new Promise((resolve) => {
										this.handleRowUpdate(newData, oldData, resolve);
									})
							}}
							actions={[
								// {
								// 	icon: () => <SwitchCameraIcon color="secondary" />,
								// 	tooltip: "Binding/Unbinding camera",
								// 	position: 'row',
								// 	onClick: (event) => {
								// 		console.log(event.target.value);
								// 	}
								// }
							]}
							onSelectionChange={data => {
								this.setState({
								//	accAuxInList: data
								})
								}}
								components={{
								Pagination: (props) => (
								  <TablePagination {...props} count={totalCount} page={query.page} rowsPerPage={query.pageSize} />
								),
							  }}
							  onPageChange={(page) => {
								if (pageChanged) {
								  this.setState(
									{ query: { ...query, page: page }, pageChanged: false }, () => this.fetchData({ pageChanged: true })
								  );
								}
							  }}
							   
							  onRowsPerPageChange={(pageSize) => {
								this.setState({ query: { ...query, pageSize } }, this.fetchData);
							  }}
						/>
					
			</Fragment>
		);

	}
}


AccAuxInList.propTypes = {
	updateAccAuxIn: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	accAuxInList: state.AccAuxInList.accAuxInList,
	updateAccAuxIn: state.AccAuxInList.updateAccAuxIn
});

export default compose(withTranslation(),connect(mapStateToProps, { getAccAuxIns, updateAccAuxIn }))(AccAuxInList);
