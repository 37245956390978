import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import NotificationlTable from './NotificationsTable';
import ShowSite from './ShowSite';
import DevicesGraph from './DevicesGraph';
import EmployeesGraph from './EmployeesGraph';
import Divider from '@material-ui/core/Divider';
import MapContainers from './MapContainers';
import GlobalMapContainer from './GlobalMapContainer';
import { useDispatch, useSelector } from 'react-redux';
import CommonUtil from '../../components/Util/CommonUtils';
import setJWTToken from '../../oauth-client/setJWTToken';
import DeviceChart from './DeviceChart';
import EmployeeChart from './EmployeeChart';
import DashboardCards from './DashboardCards';
import { useTranslation } from 'react-i18next';
import MapConain from './MapConain';
import EventGraph from './EventGraph';
import { filterSite } from '../../actions/organization/SiteApiCalls';
import SiteDashboard from '../../pages/SiteDashboard/SiteDashboard';
import { toast } from 'react-toastify';
import SiteDashboardCards from '../../pages/SiteDashboardCards/SiteDashboardCards';

export default function Dashboard(props) {
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  var stompClient = null;

  useEffect(() => {
    if (store.security.validToken && !CommonUtil.isEmptyString(store.security.AccJwtToken)) {
      setJWTToken(store.security.AccJwtToken);
      dispatch(filterSite('', '', 1, 20));
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Typography gutterBottom variant='h3' component='h2' color='secondary' style={{ margin: '18px' }}>
        {t('COMMON180')}
      </Typography>
      <Grid item xs={12} sm={12}>
        {/* <DashboardCards /> */}
        <SiteDashboardCards id='' />
      </Grid>
      <Grid item xs={12} sm={12}>
        <GlobalMapContainer />
      </Grid>
      <Grid item xs={12} sm={12}>
        <EventGraph />
      </Grid>
      {/* 
      <Grid item xs={12} sm={12}>
        <MapConain />
      </Grid> */}

      {/* <Grid item xs={12} sm={12}>
        <MapContainers />
      </Grid> */}
      {/* <Grid item xs={12} sm={4} style={{ alignItems: 'center' }}>
        <Paper style={{ marginBottom: '16px' }}>
          <DeviceChart />
        </Paper>
        <Paper>
          <EmployeeChart />
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <Paper>
          <NotificationlTable />
        </Paper>
      </Grid>
    </Grid>
  );
}
