import axios from "axios";
import { GET_ACCESS_EMPLOYEE_LIST,GET_PERSON_PRIMARY_LIST} from "./types";
import { JAVA_URL_ACCESS_LEVEL_PERSON,JAVA_URL_PERSON_PRIMARY_DETAILS} from "./EndPoints";

/*export const getEmployeeList = (emps) => async dispatch => {
		const res = await axios.get(JAVA_URL_ACCESS_EMPLOYEE_LIST);
		dispatch({
			type: GET_ACCESS_EMPLOYEE_LIST,
			payload: res.data.data
		});
	
};*/
export const getEmployeeListByLevelId = (levelId,query) => async dispatch => {
		const res = await axios.get(JAVA_URL_ACCESS_LEVEL_PERSON.replace("{levelId}", levelId)+'?page=' + query.page + '&size=' + query.pageSize);
		dispatch({
			type: GET_ACCESS_EMPLOYEE_LIST,
			payload: res.data.data
		});
	
};


export const getemployeePrimaryDetails = () => async dispatch => {
 
		const res = await axios.get(JAVA_URL_PERSON_PRIMARY_DETAILS);
		dispatch({
			type: GET_PERSON_PRIMARY_LIST,
			payload: res.data.data
		});
  
};