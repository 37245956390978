import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import Headerbar from '../../components/HeaderBar';
import { useTranslation } from 'react-i18next';
import DataTable from '../../components/DataTable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import { getLevelDepartment, getLevelPerson, listDepartmentByLevel, loadLevelPerson, removeLevelDepartment } from '../../actions/LevelApiCalls';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import AddLevelDepartment from '../../pages/level/AddLevelDepartment';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';

import CommonUtil from '../../components/Util/CommonUtils';
import FilterLevelDepartment from './FilterLevelDepartment';
import CachedIcon from '@material-ui/icons/Cached';

export default function DepartmentDetails(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [selectedData, onSelectionChange] = useState([]);
  const [open, setOpenDialog] = useState(false);
  const [data, setData] = useState([]);

  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // eslint-disable-next-line
  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: 5,
    levelId: props.levelId,
    flag: true,
  });

  useEffect(() => {
    //loadData(page, rowsPerPage);
    // eslint-disable-next-line
  }, [props.levelId]);

  // useEffect(() => {
  //   if (!open) loadData();
  //   // eslint-disable-next-line
  // }, [open]);

  useEffect(() => {
    onSelectionChange([]);
    if (!CommonUtil.isEmpty(store.level.levelDepartments)) {
      setTotalRecords(store.level.levelDepartments.totalCount);
      setPage(store.level.levelDepartments.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.level.levelDepartments.department);
    // eslint-disable-next-line
  }, [store.level.levelDepartments]);

  useEffect(() => {
    setData(store.level.levelDepartments.department);
  }, [store.level.levelDepartments]);

  const loadData = (page, rowsPerPage) => {
    dispatch(listDepartmentByLevel(props.levelId, filter, page + 1, rowsPerPage));
    //dispatch(getLevelPerson(props.levelId, page + 1, rowsPerPage));
    //onSelectionChange([]);
  };

  const [filter, setFilter] = useState({
    code: '',
    name: '',
  });

  const defaultFilter = {
    code: '',
    name: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      code: filterData.code,
      name: filterData.name,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadData(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  // const handleChangePage = (newPage) => {
  //   setPage(newPage);
  //   dispatch(getLevelDepartment(props.levelId, page + 1, rowsPerPage));
  //   dispatch(getLevelPerson(props.levelId, page + 1, rowsPerPage));
  // };

  // const handleChangeRowsPerPage = (pageSize) => {
  //   setRowsPerPage(pageSize);
  //   setPage(0);
  //   dispatch(getLevelDepartment(props.levelId, 1, pageSize));
  //   dispatch(getLevelPerson(props.levelId, 1, pageSize));
  // };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '30vh',
    initialPage: 1,
    pageSize: rowsPerPage,
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var ids = '';
    // eslint-disable-next-line
    data.map((record, index) => {
      if (index === data.length - 1) {
        ids = ids + record.id;
      } else {
        ids = ids + record.id + ',';
      }
    });
    const personFilter = {
      firstName: '',
      lastName: '',
      employeeCode: '',
      email: '',
      phone: '',
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                removeLevelDepartment(props.levelId, ids, () => {
                  loadData(page, rowsPerPage);
                  dispatch(loadLevelPerson(props.levelId, personFilter, false, 1, rowsPerPage));
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const columns = [
    {
      field: 'name',
      title: t('LEVEL014'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'code', title: t('COMMON045'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <DeleteOutlineIcon color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  return (
    <div>
      <Box display='flex' className='custom-container-header' alignItems='center'>
        <Headerbar title={t('LEVEL012')} className='custom-container-header'>
          <Box paddingRight={1}>
          <Tooltip title={t('COMMON009')}>
            <IconButton size='small' aria-label='delete' onClick={() => setOpenDialog(true)}>
              <LibraryAddIcon color='secondary' />
            </IconButton>
            </Tooltip>
          </Box>
          <Box paddingRight={1}>
          <Tooltip title={t('COMMON004')}>
            <IconButton aria-label='delete' size='small' onClick={() => deleteRecord(selectedData)}>
              <DeleteOutlineIcon color='secondary' />
            </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <FilterLevelDepartment applyFilter={(data) => applyFilter(data)} />
          </Box>
          <Box>
            <Tooltip title={t('COMMON002')}>
              <IconButton color='secondary' size='small' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
                <CachedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Headerbar>
      </Box>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
      <AddLevelDepartment existingItems={data} open={open} levelId={props.levelId} onClose={() => setOpenDialog(false)} onSubmit={() => loadData(0, rowsPerPage)} />
    </div>
  );
}
