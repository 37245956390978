import React from 'react';
import { Grid} from "@material-ui/core";
import { compose } from 'redux'
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { connect } from "react-redux";
import { Box, Button } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import { forwardRef } from 'react';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from "material-table";
import { adddLinkage } from "../../actions/LinkageListActions";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
class OutputLinkage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }
  handleDialogOK = (e) => {
    var accLinkageDTO = {};
    accLinkageDTO.name = this.props.name;
    accLinkageDTO.deviceId = this.props.deviceId;
    if (this.props.name != null && this.props.deviceId != null) {
      this.props.adddLinkage(accLinkageDTO);
    }
    else {
      toast.error("Please Enter Name and Select Device ");
    }
  }
  render() {

    const tableIcons = {
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
    };
    return (
      <div>
        <Grid container spacing={2}>
          <Box display="flex" flexDirection="row" width={"100%"}>
            <Box width={"100%"} marginRight={1}>
              <div className="acc-level-details-table">
                <div className="acc-level-details" style={{ borderColor: "transparent" }}>
                  <Box display="flex" bgcolor="background.paper" justifyContent="flex-end">
                    <Box p={1} flexGrow={1}>
                      <p><b className="acc-head">{"Door"}</b></p>
                    </Box>
                    <Box p={0} marginRight={1}>
                      <FormControl style={{ width: "150px" }}>
                        <InputLabel id="demo-simple-select-outlined-label">Action Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Action Type"
                          size="small"
                        >
                          <MenuItem value="">
                            <em>Close</em>
                          </MenuItem>
                          <MenuItem value="">
                            <em>Open</em>
                          </MenuItem>
                          <MenuItem value="">
                            <em>Normally Open</em>
                          </MenuItem>
                          <MenuItem value="">
                            <em>Lock</em>
                          </MenuItem>
                          <MenuItem value="">
                            <em>Unlock</em>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box >
                      <TextField id="outlined-basic" label="Action Time Delay"  />
                    </Box>
                  </Box>
                </div>
                <MaterialTable
                  style={{ maxHeight: "230px", minHeight: "230px" }}
                  autoHeight={true}
                  icons={tableIcons}
                  columns={[
                    { field: 'text' },


                  ]}
                  data={this.props.data.doorOutputArray}
                  options={{
                    minBodyHeight: '100%',
                    actionsColumnIndex: -1,
                    addRowPosition: 'first',
                    showTitle: false,
                    toolbarButtonAlignment: "left",
                    toolbar: false,
                    selection: true,
                    pageSize: 5,
                    paging: false
                  }}
                />
              </div>
            </Box>
            <Box width={"100%"} marginRight={1}>
              <div className="acc-level-details-table">
                <div className="acc-level-details" style={{ borderColor: "transparent" }}>
                  <Box display="flex" bgcolor="background.paper" justifyContent="flex-end">
                    <Box p={1} flexGrow={1}>
                      <p><b className="acc-head">{"Auxulary Output"}</b></p>
                    </Box>
                    <Box p={0} marginRight={1}>
                    <FormControl style={{ width: "150px" }}>
              <InputLabel id="demo-simple-select-outlined-label">Action Type</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Action Type"
              >
                <MenuItem value="">
                  <em>Close</em>
                </MenuItem>
                <MenuItem value="">
                  <em>Open</em>
                </MenuItem>
                <MenuItem value="">
                  <em>Normally Open</em>
                </MenuItem>
              </Select>
            </FormControl>
                    </Box>
                    <Box >
                    <TextField id="outlined-basic" label="Action Time Delay" />
                    </Box>
                  </Box>
                </div>
                <MaterialTable
                  style={{ maxHeight: "230px", minHeight: "230px" }}
                  autoHeight={true}
                  icons={tableIcons}
                  columns={[
                    

                  ]}

                  options={{
                    minBodyHeight: '100%',
                    actionsColumnIndex: -1,
                    addRowPosition: 'first',
                    showTitle: false,
                    toolbarButtonAlignment: "left",
                    toolbar: false,
                    selection: true,
                    pageSize: 5,
                    paging: false
                  }}
                />
              </div>
            </Box>
          </Box>


          <Grid item xs={6} sm={3}>

          </Grid>
          <Grid item xs={6} sm={3}>

          </Grid>
          <Grid item xs={6} sm={3}>
            
          </Grid>
          <Grid item xs={6} sm={3}>
            
          </Grid>


          <Grid item xs={4}>

          </Grid>
          <Grid item xs={4} container
            direction="row"
            justify="center"
            alignItems="center"  >
            <Button color="primary" onClick={this.handleDialogOK} variant="contained">
              Save
                          </Button>
            <Button color="primary" component={Link} to={{
              pathname: "/acc/linkage"
            }} onClick={this.handleDialogClose} variant="contained" style={{ marginLeft: "4px" }}>
              Cancel
                  </Button>
          </Grid>
          <Grid item xs={4}>

          </Grid>
        </Grid>


      </div>
    );
  }
}
const mapStateToProps = (state) => ({

});

export default compose(withTranslation(), withStyles({ withTheme: true }), connect(mapStateToProps, { adddLinkage }))(OutputLinkage);