import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import FilterListIcon from '@material-ui/icons/FilterList';
import { toast } from 'react-toastify';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { getExceptionFilterTransactions } from '../../actions/ReportsApiCalls';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { Typography } from 'antd';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import zhLocale from 'date-fns/locale/zh-CN';
import ruLocale from 'date-fns/locale/ru';
import jaLocale from 'date-fns/locale/ja';
import idLocale from 'date-fns/locale/id';
import thLocale from 'date-fns/locale/th';

import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import BrowserLanguage from '../../components/Util/BrowserLanguage';
export default function ExceptionFilterTransactions(props) {
	let language = BrowserLanguage.getDefaultLanguage();
	const [value, setValue] = React.useState(new Date());
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);

	const [page, setPage] = React.useState(0);
	const [totalRecords, setTotalRecords] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const formatDayAndMonth = (numValue) => {
		if (numValue <= 0) {
			return '-' + (numValue + 12);
		}
		if (numValue < 10) {
			return '-0' + numValue;
		} else {
			return '-' + numValue;
		}
	};
	var today = new Date();
	var startDate = today.getFullYear() + formatDayAndMonth(today.getMonth() + 1) + formatDayAndMonth(today.getDate()) + 'T00:01';
	var endDate = today.getFullYear() + formatDayAndMonth(today.getMonth() + 1) + formatDayAndMonth(today.getDate()) + 'T23:59';

	const [state, setState] = React.useState({
		fromDate: null,
		toDate: null,
		personalId: '',
		deviceAlias: '',
		deptCode: '',
		deptName: '',
		eventDescription: '',
		readerName: '',
		name: '',
		cardNo: '',
		eventPointName: '',
		eventNo: '',
	});

	const handleClose = () => {
		setState({
			fromDate: null,
			toDate: null,
			personalId: '',
			deviceAlias: '',
			deptCode: '',
			deptName: '',
			eventDescription: '',
			readerName: '',
			name: '',
			cardNo: '',
			eventPointName: '',
			eventNo: '',
		});
		setOpen(false);
	};
	const localeMap = {
		en: enLocale,
		es: esLocale,
		zh: zhLocale,
		ru: ruLocale,
		ja: jaLocale,
		id: idLocale,
		th: thLocale,

	};
	const maskMap = {
		en: '__/__/____',
		es: '__/__/____',
		zh: '__/__/____',
		ru: '__/__/____',
		ja: '__/__/____',
		id: '__/__/____',
		th: '__/__/____',
	};
	const reset = () => {
		if (
			state.fromDate === null &&
			state.toDate === null &&
			state.personalId.trim() === '' &&
			state.deviceAlias.trim() === '' &&
			state.deptCode.trim() === '' &&
			state.deptName.trim() === '' &&
			state.eventDescription.trim() === '' &&
			state.readerName.trim() === '' &&
			state.name.trim() === '' &&
			state.cardNo.trim() === '' &&
			state.eventPointName.trim() === '' &&
			state.eventNo.trim() === ''
		) {
			toast.error(t('COMMON027'));
			return;
		}
		setState({
			fromDate: null,
			toDate: null,
			personalId: '',
			deviceAlias: '',
			deptCode: '',
			deptName: '',
			eventDescription: '',
			readerName: '',
			name: '',
			cardNo: '',
			eventPointName: '',
			eventNo: '',
		});
	};

	const handleChange = (event) => {
		const name = event.target.name;
		setState({
			...state,
			[name]: event.target.value,
		});
	};

	const handleSubmit = () => {
		if (
			state.fromDate === null &&
			state.toDate === null &&
			state.personalId.trim() === '' &&
			state.deviceAlias.trim() === '' &&
			state.deptCode.trim() === '' &&
			state.deptName.trim() === '' &&
			state.eventDescription.trim() === '' &&
			state.readerName.trim() === '' &&
			state.name.trim() === '' &&
			state.cardNo.trim() === '' &&
			state.eventPointName.trim() === '' &&
			state.eventNo.trim() === ''
		) {
			toast.error(t('COMMON015'));
			return;
		}

		if (new Date(state.fromDate) > state.toDate) {
			toast.error(t('OPE022'));
			return;
		}


		var today = new Date(state.fromDate);
		//var startDate = today.getFullYear() + formatDayAndMonth(today.getMonth() + 1) + formatDayAndMonth(today.getDate()) + 'T' + today.getHours() + ':' + today.getMinutes();
		var startDate = today.getFullYear() + formatDayAndMonth(today.getMonth() + 1) + formatDayAndMonth(today.getDate()) + 'T00:01';
		today = new Date(state.toDate);
		var endDate = today.getFullYear() + formatDayAndMonth(today.getMonth() + 1) + formatDayAndMonth(today.getDate()) + 'T23:59';

		if(startDate=== '1970-01-01T00:01'  && endDate==='1970-01-01T23:59'){
			startDate=null;
			endDate=null;
		}

		var dto = {
			...state,
			fromDate: startDate,
			toDate: endDate
		}



		props.applyFilter(dto);
		setState({
			fromDate: startDate,
			toDate: endDate,
			personalId: '',
			deviceAlias: '',
			deptCode: '',
			deptName: '',
			eventDescription: '',
			readerName: '',
			name: '',
			cardNo: '',
			eventPointName: '',
			eventNo: '',
		});
		handleClose();
	};

	return (
		<div>
			<Tooltip title={t('COMMON006')}>
			<IconButton color='secondary' aria-label='Reset filter' onClick={() => setOpen(true)}>
				<FilterListIcon/>
				</IconButton>
			</Tooltip>

			<Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} className='filter-dialog'>
				{/* <DialogTitle>
          <Box flexGrow={1} style={{ color: 'black', textAlign: 'left', fontSize: '20px', marginLeft: '18px' }}>
            {t('COMMON006')}
          </Box>
        </DialogTitle> */}
				<DialogTitle>
					<Box display='flex'>
						<Box flexGrow={1}>
							<Typography variant='h4' color='secondary'>
								{t('COMMON006')}
							</Typography>
						</Box>
						<CancelIcon onClick={handleClose} />
					</Box>
				</DialogTitle>
				<DialogContent>
					<form noValidate autoComplete='off'>
						<Grid container>
							<Grid item xs={6} sm={4}>
								<LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[language=='idn'?'id':language]}>
									<Stack>
										<DesktopDatePicker
										    mask={maskMap[language=='idn'?'id':language]}
											label={t('OPE018')}
										//	inputFormat='dd-MM-yyyy'
											value={state.fromDate}
											onChange={(fromDate) => setState({ ...state, fromDate: fromDate })}
											maxDate={today}
											minDateTime={state.fromDate}
											leftArrowButtonText={t('COMMON173')}
                                            rightArrowButtonText={t('COMMON174')}
											renderInput={(params) => <Box display='flex' justifyContent='center' m={1} p={1}>
												<TextField fullWidth size='small' variant='outlined' {...params} disabled={true} /></Box>}
										/>
									</Stack>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={6} sm={4}>

								<LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[language=='idn'?'id':language]}>
									<Stack>
										<DesktopDatePicker
											mask={maskMap[language=='idn'?'id':language]}
											label={t('OPE019')}
										//	inputFormat='dd-MM-yyyy'
											value={state.toDate}
											onChange={(toDate) => setState({ ...state, toDate: toDate })}
											maxDate={today}
											minDateTime={state.fromDate}
											leftArrowButtonText={t('COMMON173')}
                                            rightArrowButtonText={t('COMMON174')}
											renderInput={(params) => <Box display='flex' justifyContent='center' m={1} p={1}>
												<TextField fullWidth size='small' variant='outlined' {...params} disabled={true}/></Box>}
										/>
									</Stack>
								</LocalizationProvider>

							</Grid>
							<Grid item xs={6} sm={4}>
								<Box display='flex' justifyContent='center' m={1} p={1}>
									<TextField fullWidth name='personalId' label={t('OPE017')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.personalId} />
								</Box>
							</Grid>

							<Grid item xs={6} sm={4}>
								<Box display='flex' justifyContent='center' m={1} p={1}>
									<TextField fullWidth name='deviceAlias' label={t('OPE020')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.deviceAlias} />
								</Box>
							</Grid>
							{/* {/* <Grid item xs={6} sm={4}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        fullWidth
                                        name="deptCode"
                                        label={t("OPE015")}
                                        helperText=""
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        value={state.deptCode}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        fullWidth
                                        name="deptName"
                                        label={t("OPE006")}
                                        helperText=""
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        value={state.deptName}
                                    />
                                </Box>
                            </Grid> 

                            <Grid item xs={6} sm={4}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        fullWidth
                                        name="eventDescription"
                                        label={t("OPE013")}
                                        helperText=""
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        value={state.eventDescription}
                                    />
                                </Box>
                            </Grid> */}
							<Grid item xs={6} sm={4}>
								<Box display='flex' justifyContent='center' m={1} p={1}>
									<TextField fullWidth name='readerName' label={t('OPE014')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.readerName} />
								</Box>
							</Grid>
							{/* <Grid item xs={6} sm={4}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        label={t("OPE005")}
                                        helperText=""
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        value={state.name}
                                    />
                                </Box>
                            </Grid> */}

							<Grid item xs={6} sm={4}>
								<Box display='flex' justifyContent='center' m={1} p={1}>
									<TextField fullWidth name='cardNo' label={t('OPE004')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.cardNo} />
								</Box>
							</Grid>
							<Grid item xs={6} sm={4}>
								<Box display='flex' justifyContent='center' m={1} p={1}>
									<TextField
										fullWidth
										name='eventPointName'
										label={t('OPE011')}
										helperText=''
										variant='outlined'
										size='small'
										onChange={handleChange}
										value={state.eventPointName}
									/>
								</Box>
							</Grid>
							{/* <Grid item xs={6} sm={4}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth name='eventNo' label={t('OPE003')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.eventNo} />
                </Box>
              </Grid> */}
						</Grid>
					</form>
				</DialogContent>
				<DialogActions>
					<SubmitButton onClick={handleSubmit} variant='contained' size='small'>
						{t('COMMON024')}
					</SubmitButton>
					<CancelButton onClick={reset} variant='contained' size='small'>
						{t('COMMON025')}
					</CancelButton>
				</DialogActions>
			</Dialog>
		</div>
	);
}
