import React,{ Fragment } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {REACT_URL_TIME_PERIOD} from '../../actions/EndPoints'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Card, CardContent, Grid } from '@material-ui/core';
import {compose} from 'redux'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import TimeLine from '../../utils/TimeLine';
import { Link } from 'react-router-dom';
import Snackbar from'@material-ui/core/Snackbar';
import Alert from'@material-ui/lab/Alert'
import {updateNewTimeZone} from '../../actions/TimePeriodActions'
import { getTimePeriods } from '../../actions/TimePeriodActions'
import { Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
const styles = theme => ({
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 250
    }
  },
  formcontrol: {
    margin: theme.spacing(1),
    width: 250
  },
});
class EditTimePeriod extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    query: {
      page: 0,
      pageSize: 5,
    },
    totalCount: 0,
    pageChanged: true,
    errormessages: '',
    formIsValid: true,
    dialogText: '',
    isDialogOpen: false,
    isChecked: false,
    response: false,
    id: '',
     name: '' ,
     remark: '', 
     companyId:'', 
     errors: {},

     selectCheckBox: false,  

      sundayStart1: '',

      sundayEnd1: '',

      sundayStart2: '',

      sundayEnd2: '',

      sundayStart3: '',

      sundayEnd3: '',

      mondayStart1: '',
    
      mondayEnd1: '',

      mondayStart2: '',

      mondayEnd2: '',

      mondayStart3: '',

      mondayEnd3: '',

      tuesdayStart1: '',
      
      tuesdayEnd1: '',

      tuesdayStart2: '',

      tuesdayEnd2: '',

      tuesdayStart3: '',

      tuesdayEnd3: '',

      wednesdayStart1: '',

      wednesdayEnd1: '',

      wednesdayStart2: '',

      wednesdayEnd2: '',

      wednesdayStart3: '',

      wednesdayEnd3: '',

      thursdayStart1: '',

      thursdayEnd1: '',

      thursdayStart2: '',

      thursdayEnd2: '',

      thursdayStart3: '',

      thursdayEnd3: '',

      fridayStart1: '',

      fridayEnd1: '',

      fridayStart2: '',

      fridayEnd2: '',

      fridayStart3: '',

      fridayEnd3: '',

      saturdayStart1: '',

      saturdayEnd1: '',

      saturdayStart2: '',

      saturdayEnd2: '',

    saturdayStart3: '',

    saturdayEnd3: '',
    rowData: {}
  }
   this.onChange=this.onChange.bind(this);
   this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    //this.props.getTimePeriods();
//this.setState({rowData:this.props.location.state})

this.UpdateData(this.props.location.state)
        
  }
   UpdateData=(data)=>{
     this.setState({
       id: data.id,

    name: data.name ,

    remark: data.remark, 

    companyId:data.companyId,   

     sundayStart1: data.sundayStart1,

     sundayEnd1: data.sundayEnd1,

     sundayStart2: data.sundayStart2 ,

     sundayEnd2: data.sundayEnd2,

     sundayStart3: data.sundayStart3,

     sundayEnd3: data.sundayEnd3,

     mondayStart1: data.mondayStart1,
   
     mondayEnd1: data.mondayEnd1 ,

     mondayStart2:  data.mondayStart2,

     mondayEnd2: data.mondayEnd2,

     mondayStart3: data.mondayStart3,

     mondayEnd3: data.mondayEnd3,

     tuesdayStart1: data.tuesdayStart1,

     tuesdayEnd1: data.tuesdayEnd1,

     tuesdayStart2: data.tuesdayStart2,

     tuesdayEnd2: data.tuesdayEnd2,

     tuesdayStart3: data.tuesdayStart3,

     tuesdayEnd3: data.tuesdayEnd3,

     wednesdayStart1: data.wednesdayStart1 ,

     wednesdayEnd1: data.wednesdayEnd1,

     wednesdayStart2: data.wednesdayStart2,

     wednesdayEnd2: data.wednesdayEnd2,

     wednesdayStart3: data.wednesdayStart3,

     wednesdayEnd3: data.wednesdayEnd3,

     thursdayStart1: data.thursdayStart1,

     thursdayEnd1: data.thursdayEnd1,

     thursdayStart2: data.thursdayStart2,

     thursdayEnd2: data.thursdayEnd2,

     thursdayStart3: data.thursdayStart3,

     thursdayEnd3: data.thursdayEnd3,

     fridayStart1:  data.fridayStart1,

     fridayEnd1: data.fridayEnd1,

     fridayStart2: data.fridayStart2,

     fridayEnd2: data.fridayEnd2,

     fridayStart3: data.fridayStart3,

     fridayEnd3: data.fridayEnd3,

     saturdayStart1: data.saturdayStart1,

     saturdayEnd1: data.saturdayEnd1,

     saturdayStart2: data.saturdayStart2,

     saturdayEnd2: data.saturdayEnd2,

   saturdayStart3: data.saturdayStart3,

   saturdayEnd3: data.saturdayEnd3,
  })
   }
  onSubmit(e) {
    e.preventDefault();
    var mon=this.handleValidation(this.state.mondayStart1,this.state.mondayStart2,this.state.mondayStart3,this.state.mondayEnd1,this.state.mondayEnd2,this.state.mondayEnd3,"Monday");
    
     if(mon)
     {
     var tues=this.handleValidation(this.state.tuesdayStart1,this.state.tuesdayStart2,this.state.tuesdayStart3,this.state.tuesdayEnd1,this.state.tuesdayEnd2,this.state.tuesdayEnd3,"tuesday");
     }
     if(tues)
     {
       var wed=this.handleValidation(this.state.wednesdayStart1,this.state.wednesdayStart2,this.state.wednesdayStart3,this.state.wednesdayEnd1,this.state.wednesdayEnd2,this.state.wednesdayEnd3,"wednesday");
     }
     if(wed)
     {
     var thu=this.handleValidation(this.state.thursdayStart1,this.state.thursdayStart2,this.state.thursdayStart3,this.state.thursdayEnd1,this.state.thursdayEnd2,this.state.thursdayEnd3,"thursday");
     }
     if(thu)
     {
     var fri=this.handleValidation(this.state.fridayStart1,this.state.fridayStart2,this.state.fridayStart3,this.state.fridayEnd1,this.state.fridayEnd2,this.state.fridayEnd3,"friday");
     }
     if(fri)
     {
     var sat=this.handleValidation(this.state.saturdayStart1,this.state.saturdayStart2,this.state.saturdayStart3,this.state.saturdayEnd1,this.state.saturdayEnd2,this.state.saturdayEnd3,"saturday");
     }
     if(fri)
     {
       // eslint-disable-next-line
     var sun= this.handleValidation(this.state.sundayStart1,this.state.sundayStart2,this.state.sundayStart3,this.state.sundayEnd1,this.state.sundayEnd2,this.state.sundayEnd3,"sunday");
     }

    const UpdateTimeZone = {
      id: this.state.id,
      companyId: this.state.companyId,
      name: this.state.name ,

      remark: this.state.remark,        

      sundayStart1: this.state.sundayStart1,

      sundayEnd1: this.state.sundayEnd1,

      sundayStart2: this.state.sundayStart2,

      sundayEnd2: this.state.sundayEnd2,

      sundayStart3: this.state.sundayStart3,

      sundayEnd3: this.state.sundayEnd3,

      mondayStart1: this.state.mondayStart1,
    
      mondayEnd1: this.state.mondayEnd1,

      mondayStart2: this.state.mondayStart2,

      mondayEnd2: this.state.mondayEnd2,

      mondayStart3: this.state.mondayStart3,

      mondayEnd3: this.state.mondayEnd3,

      tuesdayStart1: this.state.tuesdayStart1,

      tuesdayEnd1: this.state.tuesdayEnd1,

      tuesdayStart2: this.state.tuesdayStart2,

      tuesdayEnd2: this.state.tuesdayEnd2,

      tuesdayStart3: this.state.tuesdayStart3,

      tuesdayEnd3: this.state.tuesdayEnd3,

      wednesdayStart1: this.state.wednesdayStart1,

      wednesdayEnd1: this.state.wednesdayEnd1,

      wednesdayStart2: this.state.wednesdayStart2,

      wednesdayEnd2: this.state.wednesdayEnd2,

      wednesdayStart3: this.state.wednesdayStart3,

      wednesdayEnd3: this.state.wednesdayEnd3,

      thursdayStart1: this.state.thursdayStart1,

      thursdayEnd1: this.state.thursdayEnd1,

      thursdayStart2: this.state.thursdayStart2,

      thursdayEnd2: this.state.thursdayEnd2,

      thursdayStart3: this.state.thursdayStart3,

      thursdayEnd3: this.state.thursdayEnd3,

      fridayStart1: this.state.fridayStart1,

      fridayEnd1: this.state.fridayEnd1,

      fridayStart2: this.state.fridayStart2,

      fridayEnd2: this.state.fridayEnd2,

      fridayStart3: this.state.fridayStart3,

      fridayEnd3: this.state.fridayEnd3,

      saturdayStart1: this.state.saturdayStart1,

      saturdayEnd1: this.state.saturdayEnd1,

      saturdayStart2: this.state.saturdayStart2,

      saturdayEnd2: this.state.saturdayEnd2,

      saturdayStart3: this.state.saturdayStart3,

      saturdayEnd3: this.state.saturdayEnd3



   };
   
   if(mon===true&&tues===true&&wed===true&&thu===true&&fri===true&&sat===true){  
    const { query } = this.state;
    this.props.updateNewTimeZone(UpdateTimeZone,query,this.props.history);
   }else{
    
   }
  }
  handleValidation = (s1,s2,s3,e1,e2,e3,day) => {

    //alert("from handleValidation "+s1 )       

      //let fields = this.state.fields;
      //let errors = '';
      let formIsValid = true;

      //alert("test "+s1 !== "00:00")
  
      const { t } = useTranslation();      
  if(s1 !== "" ){  
      if(!this.timeValidation(s1,e1)){
      formIsValid = false;
      //alert("Monday interval 1 start time must be smaller and not equal to End time of Monday interval 1 ");
      let errors= day+t('TIMEP023')+day+t('TIMEP015');
      
      this.setState({errormessages:errors,formIsValid:false})
      return formIsValid;
     }
  }

  if(s2 !== ""){
    if(!this.timeValidation(e1,s2)){
    formIsValid = false;
      //alert("Start time of Monday interval 2 must be greather and not equal to End time of Monday interval 1");
      let errors=t('TIMEP016')+day+t('TIMEP024')+day+t('TIMEP015');
      this.setState({errormessages:errors,formIsValid:false})
      return formIsValid;
    } 
            
    if(!this.timeValidation(s2,e2)) {
      formIsValid = false;
      //alert("Start time of Monday interval 2 must  be smaller and not equal to End time of Monday interval 2 ");
     let  errors=t('TIMEP016')+day+t('TIMEP025')+day+t('TIMEP019');
      this.setState({errormessages:errors,formIsValid:false})
      return formIsValid;
    }
  }
    if((s3 !== "")){
      if(!this.timeValidation(e2,s3)){
    formIsValid = false;
      //alert("Start time of Monday interval 3 must be greather and not equal to End time of Monday interval 2");
     let errors=t('TIMEP016')+day+t('TIMEP026')+day+t('TIMEP019');
      this.setState({errormessages:errors,formIsValid:false})
      return formIsValid;
    } 

    if(!this.timeValidation(s3,e3)){
      formIsValid = false;
      //alert("Start time of Monday interval 3 must  be smaller and not equal to End time of Monday interval 3 ");
     let errors=t('TIMEP016')+day+t('TIMEP027')+day+t('TIMEP022');
      this.setState({errormessages:errors,formIsValid:false})
      return formIsValid;
    }
  }
                    
  //this.setState({errors: errors});
  return formIsValid; 
}
timeValidation = (startTime,endTime) => {

  var startTime_hr = "";
  var startTime_min = "";
  var endTime_hr = "";
  var endTime_min = "";

     
      startTime_hr = startTime.split(":")[0];
      startTime_min = startTime.split(":")[1];
      endTime_hr = endTime.split(":")[0];
      endTime_min = endTime.split(":")[1];
         
    if((endTime_hr < startTime_hr) ) {
        return false;
    }
    if(endTime_hr === startTime_hr){
      if(endTime_min <= startTime_min ){
          return false;
      }
        return true;
    }
    
     return true;
   
 } 
  onChange(e) {
       this.setState({errors:{}})
       //this.setState({ [e.target.name]: e.target.value });
       if(!this.state.selectCheckBox){
         
        if(typeof e != 'undefined'){
      this.setState({ [e.target.name]: e.target.value });
      }
    }
      else if(this.state.selectCheckBox && typeof e == 'undefined'){
  
      this.setState({   
        sundayStart1:this.state.mondayStart1,

        sundayEnd1: this.state.mondayEnd1,
 
        sundayStart2: this.state.mondayStart2,
 
        sundayEnd2: this.state.mondayEnd2,
 
        sundayStart3: this.state.mondayStart3,
 
        sundayEnd3: this.state.mondayEnd3,
 
        mondayStart1: this.state.mondayStart1,
      
        mondayEnd1: this.state.mondayEnd1,
 
        mondayStart2: this.state.mondayStart2,
 
        mondayEnd2: this.state.mondayEnd2,
 
        mondayStart3: this.state.mondayStart3,
 
        mondayEnd3: this.state.mondayEnd3,
 
        tuesdayStart1: this.state.mondayStart1,
 
        tuesdayEnd1: this.state.mondayEnd1,
 
        tuesdayStart2: this.state.mondayStart2,
 
        tuesdayEnd2: this.state.mondayEnd2,
 
        tuesdayStart3: this.state.mondayStart3,
 
        tuesdayEnd3: this.state.mondayEnd3,
 
        wednesdayStart1: this.state.mondayStart1,
 
        wednesdayEnd1: this.state.mondayEnd1,
 
        wednesdayStart2: this.state.mondayStart2,
 
        wednesdayEnd2: this.state.mondayEnd2,
 
        wednesdayStart3: this.state.mondayStart3,
 
        wednesdayEnd3: this.state.mondayEnd3,
 
        thursdayStart1: this.state.mondayStart1,
 
        thursdayEnd1: this.state.mondayEnd1,
 
        thursdayStart2: this.state.mondayStart2,
 
        thursdayEnd2: this.state.mondayEnd2,
 
        thursdayStart3: this.state.mondayStart3,
 
        thursdayEnd3: this.state.mondayEnd3,
 
        fridayStart1: this.state.mondayStart1,
 
        fridayEnd1: this.state.mondayEnd1,
 
        fridayStart2: this.state.mondayStart2,
 
        fridayEnd2: this.state.mondayEnd2,
 
        fridayStart3: this.state.mondayStart3,
 
        fridayEnd3: this.state.mondayEnd3,
 
        saturdayStart1: this.state.mondayStart1,
 
        saturdayEnd1: this.state.mondayEnd1,
 
        saturdayStart2: this.state.mondayStart2,
 
        saturdayEnd2: this.state.mondayEnd2,
 
        saturdayStart3: this.state.mondayStart3,
 
        saturdayEnd3: this.state.mondayEnd3
        
       
           
       
      }
      );
     
   
}
else {
   
  this.setState({

      selectCheckBox: false,

     sundayStart1:'',

     sundayEnd1: '',

     sundayStart2: "",

     sundayEnd2: "",

     sundayStart3: "",

     sundayEnd3: "",

    mondayStart1: this.state.mondayStart1,
  
    mondayEnd1: this.state.mondayEnd1,

    mondayStart2: this.state.mondayStart2,

    mondayEnd2: this.state.mondayEnd2,

    mondayStart3: this.state.mondayStart3,

    mondayEnd3: this.state.mondayEnd3,

    tuesdayStart1: "",

    tuesdayEnd1: "",

    tuesdayStart2: "",

    tuesdayEnd2: "",

    tuesdayStart3: "",

    tuesdayEnd3: "",

    wednesdayStart1: " ",

    wednesdayEnd1: " ",

    wednesdayStart2: "",

    wednesdayEnd2: "",

    wednesdayStart3: "",

    wednesdayEnd3: "",

    thursdayStart1: "",

    thursdayEnd1: "",

    thursdayStart2: "",

    thursdayEnd2: "",

    thursdayStart3: "",

    thursdayEnd3: "",

    fridayStart1: "",

    fridayEnd1: "",

    fridayStart2: "",

    fridayEnd2: "",

    fridayStart3: "",

    fridayEnd3: "",

    saturdayStart1: "",

    saturdayEnd1: "",

    saturdayStart2: "",

    saturdayEnd2: "",

    saturdayStart3: "",

    saturdayEnd3: ""

  })

}
      
   }
   onCloseAlert=() =>{
		this.setState({ errormessages: false  });
  }
  componentDidUpdate(prevProps) {
    if(this.props.errors !== prevProps.errors){
      this.setState({errors:this.props.errors})
       
    }
  }

  componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
		}
  }
  handleDialogClose=()=> {
    console.log('Clicked Close');
    this.setState({
      isDialogOpen: false,errors:{}
    });
  }

  checkBoxChange=(e)=>{
    if(!this.state.selectCheckBox){
     
   this.setState({selectCheckBox: true},()=>{this.onChange()})
    } 
    if(this.state.selectCheckBox) {
    
     //this.setState({selectCheckBox: false},()=>{this.onChange()})
     this.onChange(e);

     
      
    
    }
   //this.onChange(e);
 //    this.setState({selectCheckBox: true},()=>{
     
 //     alert(this.state.mondayStart1)
 //  this.setState({
 // tuesdayStart1:this.state.mondayStart1

 // })



    
    
 }
  render() {
    const { t, classes } = this.props;
    //console.log(this.props.location.state)
    //alert(JSON.stringify(this.state.rowData.name))  
    
               
    const rows = [ "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    return (
      <Fragment>
      <Grid 
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start">
          <Grid item xs={12}>
            <TimeLine menu="Access control" page="Time period" menuPath={REACT_URL_TIME_PERIOD} pagePath=""/>
          </Grid>
      </Grid>
      <Card className="card-box mb-4 device-list-card device-list-container">
              <div className="card-header py-3  device-list-actions">
                  <div className="card-header--title font-size-lg">
                      {t('timePeriodTitle')}
                  </div>
              
              </div>
              
              <CardContent className="device-list-card">
                <div>

                {/* <Button variant="contained" color="primary" size="small" startIcon={<AddCircleOutlineRoundedIcon />} onClick={this.handleChange} checked={this.state.isChecked}>
                {t('buttonTimePeriodTitle')}
                </Button> */}

                {/* <Dialog
                    className="dialog-timeperiod"
                    open={this.state.isDialogOpen}
                    onClose={this.handleDialogClose}
                    fullWidth={true}
                    maxWidth = {'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                  <DialogTitle id="customized-dialog-title" onClose={this.handleDialogClose}>{"Add New Time Period"}</DialogTitle> */}

                  {/* <DialogContent className="dialog-content-timeperiod"> */}
                  <form onSubmit={this.onSubmit} noValidate autoComplete="off" className="form-timeperiod">
                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper" >
                        <Box p={1} bgcolor="grey.300">
                            <TextField  margin="dense" name="name" label="Time Zone Name" type="text" value={this.state.name}  
                                                onChange={this.onChange}
                                                error={this.state.errors.name ? true : false} 
		                                                  	helperText={this.state.errors.name}
                                                />
                        </Box>
                        <Box p={1} bgcolor="grey.300">
                            <TextField  margin="dense" name="remark" label="Remarks" type="text" 
                                                onChange={this.onChange} value={this.state.remark}
                                                error={this.state.errors.remark ? true : false} 
		                                                  	helperText={this.state.errors.remark}
                                                />
                        </Box>
                    </Box>
                    


                  <TableContainer component={Paper} className="table-timeperiod-container" >
                    <Table aria-label="simple table" size="small" >
                        <TableHead>
                            <TableRow>
                                <TableCell className="table-timeperiod-border table-timeperiod-header" rowSpan={2}>Time / Day</TableCell>
                                <TableCell className="table-timeperiod-border table-timeperiod-header" align="center" colSpan={2}>Interval 1</TableCell>
                                <TableCell className="table-timeperiod-border table-timeperiod-header" align="center" colSpan={2}>Interval 2</TableCell>
                                <TableCell className="table-timeperiod-border table-timeperiod-header" align="center" colSpan={2}>Interval 3</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="table-timeperiod-border table-timeperiod-header" align="right">Start Time</TableCell>
                                <TableCell className="table-timeperiod-border table-timeperiod-header" align="right">End Time</TableCell>
                                <TableCell className="table-timeperiod-border table-timeperiod-header" align="right">Start Time</TableCell>
                                <TableCell className="table-timeperiod-border table-timeperiod-header" align="right">End Time</TableCell>
                                <TableCell className="table-timeperiod-border table-timeperiod-header" align="right">Start Time</TableCell>
                                <TableCell className="table-timeperiod-border table-timeperiod-header" align="right">End Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            
                                {rows.map((row) => (
                                    <TableRow key={row}>
                                        <TableCell className="table-timeperiod-border table-timeperiod-header" align="center">{row}</TableCell>
                                        <TableCell className="table-timeperiod-border" align="center">
                                          
                                            <TextField
                                                name={row+"Start1"}
                                                type="time"
                                                value={this.state[row+"Start1"]} 
                                               // defaultValue="00:00"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                shrink: true,
                                                }}
                                                inputProps={{
                                                step: 300, // 5 min
                                                }}
                                                onChange={this.onChange}
                                            />
                                        </TableCell>
                                        <TableCell className="table-timeperiod-border" align="center">
                                            <TextField
                                                name={row+"End1"}
                                                type="time"           
                                                value={this.state[row+"End1"]}
                                                //defaultValue="00:00"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                shrink: true,
                                                }}
                                                inputProps={{
                                                step: 300, // 5 min
                                                }}
                                                onChange={this.onChange}
                                            />
                                        </TableCell>
                                        <TableCell className="table-timeperiod-border" align="center">
                                            <TextField
                                                name={row+"Start2"}
                                                type="time"
                                                value={this.state[row+"Start2"]}
                                                //defaultValue="00:00"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                shrink: true,
                                                }}
                                                inputProps={{
                                                step: 300, // 5 min
                                                }}
                                                onChange={this.onChange}
                                            />
                                        </TableCell>
                                        <TableCell className="table-timeperiod-border" align="center">
                                            <TextField
                                                name={row+"End2"}
                                                type="time"
                                                value={this.state[row+"End2"]}
                                                //defaultValue="00:00"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                shrink: true,
                                                }}
                                                inputProps={{
                                                step: 300, // 5 min
                                                }}
                                                onChange={this.onChange}
                                            />
                                        </TableCell>
                                        <TableCell className="table-timeperiod-border" align="center">
                                            <TextField
                                                name={row+"Start3"}
                                                type="time"
                                                value={this.state[row+"Start3"]}
                                                //defaultValue="00:00"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                shrink: true,
                                                }}
                                                inputProps={{
                                                step: 300, // 5 min
                                                }}
                                                onChange={this.onChange}
                                            />
                                        </TableCell>
                                        <TableCell className="table-timeperiod-border" align="center">
                                            <TextField
                                                name={row+"End3"}
                                                type="time"
                                                value={this.state[row+"End3"]}
                                                //defaultValue="00:00"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                shrink: true,
                                                }}
                                                inputProps={{
                                                step: 300, // 5 min
                                                }}
                                                onChange={this.onChange}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                                
                            
                            
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper" >
                            <Box p={1} alignContent="flex-end" style={{color:"black"}}>
                              <Checkbox checked={this.state.selectCheckBox} onChange={this.checkBoxChange} ></Checkbox>Copy Manday Settings to other weekdays
                            </Box>
                                 
                            </Box>
                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper" >
                        <Box p={1} bgcolor="grey.300">
                          <Button className="btn-default" color="primary" type="submit"   variant="contained">
                            Update 
                          </Button>
                        </Box>
                        <Box p={1} bgcolor="grey.300">
                          <Button color="primary" onClick={this.handleDialogClose} component={Link}  variant="contained"  to={{
               pathname: "/acc/time-zone",
              }}>
                            Cancel
                          </Button>
                        </Box>
                    </Box>
 
                  </form>                                 
                  {/* </DialogContent>
                </Dialog> */}
                <Grid item xs={12} sm={2}>
    {(this.state.errormessages) && (
      <Snackbar anchorOrigin={{ vertical: "Top", horizontal: "center" }} open={this.state.errormessages} autoHideDuration={5000} onClose={this.onCloseAlert}>
      <Alert onClose={this.onCloseAlert} severity="error">
        {this.state.errormessages}
        </Alert>
    </Snackbar>
    )}
  </Grid>
                </div>
              </CardContent>
        </Card>

    </Fragment>


    )
   
}
}
const mapStateToProps = (state) => ({
  isSuccess: state.TimePeriod.isSuccess,
  timePeriods: state.TimePeriod.timePeriodList,
  errors: state.errors
});
export default compose(withTranslation(),connect(mapStateToProps,{getTimePeriods,updateNewTimeZone}),withStyles(styles, { withTheme: true })) (EditTimePeriod);
//export default compose(withTranslation(),connect(mapStateToProps,{createNewTimeZone}),withStyles(styles, { withTheme: true }))(AddTimePeriod);
//export default compose(withTranslation(), connect(mapStateToProps, { getTimePeriods }))(TimePeriods);