import { GET_REPORTS_LAST_KNOWN_POSITION, SUCCESS } from "../actions/types";

const initialState = {
    lastKnownPositionsList : [],
    isSuccess : false
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_REPORTS_LAST_KNOWN_POSITION:
			return {
                ...state,
				transactionsForDayList: action.payload
            };
        case SUCCESS:
            return {
                ...state,
                isSuccess: true
            };

		default:
			return state;
	}
}