import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip, Tab, Tabs, Typography, Grid, MenuItem, InputAdornment, TextareaAutosize } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { REACT_URI_SITE } from '../../actions/organization/EndPoints';
import Breadcrumb from '../../components/Breadcrumb';
import { ReactComponent as Location } from '../../assets/images/visitor-icon/Location.svg';
import { ReactComponent as Basicinfo } from '../../assets/images/visitor-icon/Basicinfo-gray.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getSites, updateSite } from '../../actions/organization/SiteApiCalls';
import { useHistory, useLocation } from 'react-router-dom';
import CancelButton from '../../components/CancelButton';
import SubmitButton from '../../components/SubmitButton';
import DialogActions from '@material-ui/core/DialogActions';
import CommonUtil from '../../components/Util/CommonUtils';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import AddressFromMap from '../../components/AddressFromMap';
import { getSiteTypes } from '../../actions/organization/SiteTypeApiCalls';
import { getAddressTypes } from '../../actions/organization/AddressTypeApiCalls';
import { countryList } from '../../components/Util/CountryList';
import { cityData, stateData, timeZoneData } from './AddressFormData';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { getCompanyProfile } from '../../actions/ProfileApiCalls';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { maxHeight, style } from '@mui/system';

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function TabPanel(props) {
  const { selectedObject, onClose, children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    background: '#3d4977',
    width: '100%',
    padding: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    color: '#FFFFFF',
  },
}));

export default function RegisterSite(props) {
  const store = useSelector((state) => state);
  const location = useLocation();
  const classes = useStyles();
  let history = useHistory();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [siteTypes, setSiteTypes] = React.useState([]);
  const [parentSites, setParentSites] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [cities, setCities] = React.useState(cityVal);
  const [timeZones, setTimeZones] = React.useState([]);
  const [selectStates, setSelectStates] = React.useState([]);
  const [addressType, setAddressTypes] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const zlink=localStorage.getItem('redirectFrom');

  const cityVal = [
    { id: 1, name: 'Bangalore' },
    { id: 2, name: 'Mysore' },
  ];
  const breadcrumb = [
    { path: REACT_URI_SITE, name: t('SITE001') },
    { path: '/acc/site-form', name: location.state.disable ? t('SITE001') : t(location.state.id!==''?'SITE008':'SITE002') },
  ];

  const [sitePayload, setSitePayload] = useState({
    id: '',
    name: '',
    code: '',
    description: '',
    parentId: '',
    siteTypeId: '',
  });
  const [addressPayload, setAddressPayload] = useState({
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    state: '',
    city: '',
    areaCode: '',
    latitude: '',
    longitude: '',
    radius: '',
    timeZone: '',
    dayLightSaving: true,
    isDefault: true,
    addressTypeId: '',
    employeeId: '',
    userId: '',
    siteId: '',
  });

  const [error, setError] = React.useState({
    name: false,
    description: false,
    parentId: false,
    siteTypeId: false,
    addressLine1: false,
    country: false,
    state: false,
    city: false,
    zipCode: false,
    latitude: false,
    longitude: false,
    radius: false,
    timezone: false,
    addressTypeId: false,
  });

  useEffect(() => {
    if (!CommonUtil.isEmpty(location.state)) {
      setSitePayload({
        ...sitePayload,
        id: location.state.id,
        name: location.state.name,
        code: location.state.code,
        description: location.state.description,
        parentId: location.state.parentId,
        siteTypeId: location.state.siteTypeId,
      });
      if (!CommonUtil.isEmpty(location.state.address)) {
        var address = location.state.address;
        if (CommonUtil.isEmptyString(address.country)) {
          dispatch(
            getCompanyProfile((data) => {
              if (!CommonUtil.isEmptyString(data.country)) {
                setAddressPayload({
                  ...address,
                  country: data.country,
                });
              } else {
                address.country = CommonUtil.getLocationDetails('country_name');
              }
            })
          );
        }
        setAddressPayload({
          ...address,
        });
      }
    }
  }, [location.state]);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      if (CommonUtil.isEmptyString(sitePayload.name) || CommonUtil.isEmptyString(sitePayload.siteTypeId)) {
        toast.error(t('COMMON200'));
        return false;
      } 
    }
    setValue(newValue);
  };
  const handleError = (data) => {
    switch (data.code) {
      case 'LMSI0013': {
        toast.success(data.message);
        history.push(REACT_URI_SITE);
        break;
      }
      case 'LMSI6011': {
        toast.success(data.message);
        history.push(REACT_URI_SITE);
        break;
      }
      case 'LMSE0037': {
        toast.error(data.message);
        setError({ city: true });
        break;
      }
      case 'LMSW0001': {
        if (data.data.error[0].code === 'LMSE0023') {
          toast.error(data.data.error[0].message);
          setError({ name: true });
        }
        break;
      }
      case 'LMSI0014': {
        toast.success(data.message);
        history.push(REACT_URI_SITE);
        break;
      }
      case 'LMSE0042': {
        toast.error(data.message);
        setError({ parentId: true });
        break;
      }
      default:
        break;
    }
  };
  const handleAddressSubmit = () => {
    if (CommonUtil.isEmptyString(addressPayload)) {
      let siteObj = {};
      siteObj.id = sitePayload.id;
      siteObj.name = sitePayload.name;
      siteObj.code = sitePayload.code;
      siteObj.description = sitePayload.description;
      siteObj.siteTypeId = sitePayload.siteTypeId;
      siteObj.parentId = sitePayload.parentId;
      dispatch(
        updateSite(siteObj, (data) => {
          handleError(data);
        })
      );
    } else {
      if (isFormValid()) {
        let siteObj = {};
        siteObj.id = sitePayload.id;
        siteObj.name = sitePayload.name;
        siteObj.code = sitePayload.code;
        siteObj.description = sitePayload.description;
        siteObj.siteTypeId = sitePayload.siteTypeId;
        siteObj.parentId = sitePayload.parentId;
        siteObj.address = addressPayload;
        dispatch(
          updateSite(siteObj, (data) => {
            handleError(data);
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(
      getSites(1, 10, false, (data) => {
        setParentSites(data.sites);
      })
    );

    dispatch(
      getSiteTypes(1, 10, false, (data) => {
        setSiteTypes(data.siteTypes);
      })
    );

    dispatch(
      getAddressTypes(1, 10, false, (data) => {
        setAddressTypes(data.addressTypes);
      })
    );

    setCountries(countryList);
    setSelectStates(stateData);
    setCities(cityData);
    setTimeZones(timeZoneData);
  }, []);

  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.siteError)) {
      const { siteError } = store.errors;
      switch (siteError.code) {
        case 'LMSE0025':
          setError({ name: true });
          break;
        case 'LMSE0026':
          setError({ siteTypeId: true });
          break;
        case 'LMSE0037':
          setError({ city: true });
          break;
        default:
      }
    }
  }, [store.errors.siteError]);

  const handleAddressChange = (event) => {

    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }

    if(event.target.name==='latitude' || event.target.name==='longitude'){
      if(!CommonUtil.isValidDoubleValue(event.target.value)){
        return;
      }
    }
    const name = event.target.name;

    setAddressPayload({
      ...addressPayload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };
  
  const handleChangeCountry=(event)=>{
    if(event === null){
      setAddressPayload({
        ...addressPayload,
        country:''
      });
    }else{
      setAddressPayload({
        ...addressPayload,
        country: event.country,
      });
    }
    setError({
      country: false,
    });
  }

  const isFormValid = () => {

    if (!CommonUtil.isEmptyString(addressPayload.addressLine1)) {
     if (CommonUtil.isEmptyString(addressPayload.addressLine1)) {
        setError({ addressLine1: true });
        toast.error(t('ADD029'));
        return false;
      }
    } else {
      setError({ addressLine1: true });
      toast.error(t('ADD014'));
      return false;
    }
    if (CommonUtil.isEmptyString(addressPayload.country)) {
      setError({ country: true });
      toast.error(t('ADD021'));
      return false;
    }

    if (!CommonUtil.isEmptyString(addressPayload.city)) {
      if (!CommonUtil.isAlphaNumericSpace(addressPayload.city)) {
        setError({ city: true });
        toast.error(t('ADD028'));
        return false;
      }
    }else{
      setError({ city: true });
        toast.error(t('ADD015'));
        return false;
    }
    if (CommonUtil.isEmptyString(addressPayload.addressTypeId)) {
      setError({ addressTypeId: true });
      toast.error(t('ADD016'));
      return false;
    }

    if (!CommonUtil.isEmptyString(addressPayload.state)) {
     if (!CommonUtil.isAlphaNumericSpace(addressPayload.state)) {
        setError({ state: true });
        toast.error(t('ADD028'));
        return false;
      }
    }

    if (!CommonUtil.isEmptyString(addressPayload.areaCode) && !CommonUtil.isAlphaNumericSlash(addressPayload.areaCode)) {
      setError({ zipCode: true });
      toast.error(t('ADD028'));
      return false;
    }

    if (!CommonUtil.isEmptyString(addressPayload.radius)) {
      if (CommonUtil.isEmptyString(addressPayload.radius.trim()) || !CommonUtil.isValidNumeric(addressPayload.radius)) {
        setError({ radius: true });
        toast.error(t('ADD020'));
        return false;
      }
    }

    if (!CommonUtil.isAlphaNumericSlash(addressPayload.areaCode)) {
      setError({ areaCode: true });
      toast.error(t('ADD022'));
      return false;
    }

    if (CommonUtil.isEmptyString(sitePayload.name)) {
      setError({ name: true });
      toast.error(t('SITE010'));
      setValue(0);
      return false;
    }
    if (!CommonUtil.isValidNumericName(sitePayload.name)) {
      setError({ name: true });
      toast.error(t('SITE012'));
      setValue(0);
      return false;
    }
    if (CommonUtil.isEmptyString(sitePayload.siteTypeId)) {
      setError({ siteTypeId: true });
      toast.error(t('SITE011'));
      setValue(0);
      return false;
    }
    return true;
  };
  const handleClose = () => {
    history.push({
      pathname: '/acc/site',
    });
  };

  const handleSiteChange = (e) => {
    var val = e.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (e.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    setSitePayload({
      ...sitePayload,
      [e.target.name]: e.target.value,
    });
  };

  const handleSiteSubmit = () => {
    if (CommonUtil.isEmptyString(sitePayload.name)) {
      setError({ name: true });
      toast.error(t('SITE010'));
      return false;
    }
    if (!CommonUtil.isValidNumericName(sitePayload.name)) {
      setError({ name: true });
      toast.error(t('SITE012'));
      return false;
    }
    if (CommonUtil.isEmptyString(sitePayload.siteTypeId)) {
      setError({ siteTypeId: true });
      toast.error(t('SITE011'));
      return false;
    }

    if (CommonUtil.isEmptyString(sitePayload.id)||CommonUtil.isEmptyString(addressPayload.id)) {
      let siteObj = {};
      siteObj.id = sitePayload.id;
      siteObj.name = sitePayload.name;
      siteObj.code = sitePayload.code;
      siteObj.description = sitePayload.description;
      siteObj.siteTypeId = sitePayload.siteTypeId;
      siteObj.parentId = sitePayload.parentId;
      dispatch(
        updateSite(siteObj, (data) => {
          handleError(data);
        })
      );
    } else {
      handleAddressSubmit();
    }
  };

  const onSelectLocation = (address) => {
    console.log('address from map :' + JSON.stringify(address));
    setAddressPayload({
      ...addressPayload,
      timeZone: address.timeZone,
      city: CommonUtil.isAlphaNumericSlash(address.city.trim()) ? address.city.trim() : '',
      addressLine1: address.addressLine1,
      state: CommonUtil.isAlphaNumericSlash(address.state.trim()) ? address.state.trim() : '',
      country: CommonUtil.isAlphaNumericSlash(address.country.trim()) ? address.country.trim() : '',
      areaCode: CommonUtil.isAlphaNumericSlash(address.pincode) ? address.pincode : '',
      latitude: address.lat,
      longitude: address.lng,
      siteId:sitePayload.id
    });
  };
  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Breadcrumb links={breadcrumb}>
          {/* <Tooltip title={t('PERSON013')}>
            <IconButton color='secondary' aria-label={t('PERSON013')}></IconButton>
          </Tooltip> */}
        </Breadcrumb>
      </div>
      <div className='custom-container'>
        <Tabs
          value={value}
          inkBarStyle={{ background: '#5383FF' }}
          variant='scrollable'
          onChange={handleChange}
          style={{ paddingLeft: '22px', borderBottom: '1px solid #a1a1a124' }}
        >
          <Tab
            style={{maxWidth:'272px'}}
            label={
              <div>
                <Basicinfo style={{ verticalAlign: 'middle', margin: '14px' }} /> {t('PERSON040')}{' '}
              </div>
            }
          />
          <Tab
            label={
              <div>
                <Location style={{ verticalAlign: 'middle', margin: '14px' }} /> {t('PERSON041')}{' '}
              </div>
            }
          />
        </Tabs>

        {value === 0 && (
          <TabPanel value={value} index={value}>
            {/* <AddSite/> */}
            <DialogContent>
              <>
                <Grid container justifyContent='center' alignItems='center'>
                  <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' m={1} p={1}>
                      <TextField
                        fullWidth
                        required
                        error={error.name}
                        name='name'
                        label={t('COMMON044')}
                        helperText=''
                        variant='outlined'
                        size='small'
                        defaultValue={sitePayload.name}
                        onChange={handleSiteChange}
                        autoComplete='off'
                        inputProps={{
                          maxlength: 100,
                          disabled:location.state.disable,
                        }}
                        value={sitePayload.name}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' m={1} p={1}>
                      <TextField
                        fullWidth
                        required
                        error={error.code}
                        name='code'
                        label={t('COMMON045')}
                        helperText=''
                        variant='outlined'
                        size='small'
                        defaultValue={sitePayload.code}
                        onChange={handleSiteChange}
                        autoComplete='off'
                        inputProps={{
                          maxlength: 100,
                          disabled:location.state.disable,
                        }}
                        value={sitePayload.code}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' m={1} p={1}>
                      <FormControl variant='outlined' size='small' fullWidth required>
                        <InputLabel>{t('STYPE001')}</InputLabel>
                        <Select
                          style={{letterSpacing:'-0.99062px'}}
                          required
                          label={t('STYPE001')}
                          error={error.siteTypeId}
                          name='siteTypeId'
                          onChange={handleSiteChange}
                          value={sitePayload.siteTypeId}
                          defaultValue={sitePayload.siteTypeId}
                          MenuProps={MenuProps}
                          inputProps={{
                            disabled:location.state.disable,
                          }}
                        >
                          <MenuItem value='' selected>
                            <em>{t('none')}</em>
                          </MenuItem>
                          {siteTypes ? (
                            siteTypes.map((data, index) => (
                              <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                                <Typography variant='inherit' noWrap>
                                  {data.name}
                                </Typography>
                              </MenuItem>
                            ))
                          ) : (
                            <></>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' m={1} p={1}>
                      <FormControl variant='outlined' size='small' fullWidth>
                        <InputLabel>{t('COMMON050')}</InputLabel>
                        <Select
                          label={t('COMMON050')}
                          error={error.parentId}
                          name='parentId'
                          onChange={handleSiteChange}
                          value={sitePayload.parentId}
                          defaultValue={sitePayload.parentId}
                          MenuProps={MenuProps}
                          inputProps={{
                            disabled:location.state.disable,
                          }}
                        >
                          <MenuItem value='' selected>
                            <em>{t('none')}</em>
                          </MenuItem>
                          {parentSites ? (
                            parentSites.map((data, index) => (
                              <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                                <Typography variant='inherit' noWrap>
                                  {data.name}
                                </Typography>
                              </MenuItem>
                            ))
                          ) : (
                            <></>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box display='flex' justifyContent='center' m={1} p={1}>
                     
                      <TextField id="outlined-basic"
                      fullWidth
                      label='Description'
                      variant='outlined'
                      multiline
                      name ='description'
                      value={sitePayload.description}
                      onChange={handleSiteChange}
                      inputProps={{
                        disabled:location.state.disable,
                        maxLength:255
                      }}
                      />
                      </Box>
                  </Grid>

                  <Grid container justify='center'>
                    <DialogActions justify='center'>
                    {!zlink&& <SubmitButton onClick={handleSiteSubmit} color='primary' variant='contained' size='small' className='m-2'>
                        {t('COMMON035')}
                      </SubmitButton>}
                      <CancelButton color='primary' onClick={handleClose} variant='contained' size='small' className='m-2'>
                        {t('COMMON008')}
                      </CancelButton>
                    </DialogActions>
                  </Grid>
                </Grid>
              </>
            </DialogContent>
          </TabPanel>
        )}
        {value === 1 && (
          <TabPanel value={value} index={value}>
            {/* <Paper variant='outlined'> */}
            <form onSubmit={handleAddressSubmit} autoComplete='off'>
              <br />
              <Grid container justify='center' alignItems='center'>
                <Grid item xs={12} sm={6}>
                  <Box display='flex' justifyContent='center' m={1} p={1}>
                    <TextField
                      fullWidth
                      error={error.addressLine1}
                      name='addressLine1'
                      label={t('ADD001') + ' *'}
                      helperText=''
                      variant='outlined'
                      size='small'
                      value={addressPayload.addressLine1}
                      defaultValue={addressPayload.addressLine1}
                      onChange={handleAddressChange}
                      inputProps={{
                        maxlength: 255,
                      }}
                      InputProps={{
                        maxlength: 255,
                        disabled:location.state.disable,
                        endAdornment: (
                          <InputAdornment position='end'>
                            <AddressFromMap onSelectLocation={(address) => onSelectLocation(address)} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display='flex' justifyContent='center' m={1} p={1}>
                    <FormControl variant='outlined' size='small' fullWidth required>
                       <Autocomplete
                        clearText={t('COMMON216')}
                        openText={t('COMMON169')}
                        closeText={t('COMMON170')}
                        noOptionsText={t('COMMON214')}
                        fullWidth
                        name='country'
                        disabled={location.state.disable}
                        options={countries}
                        getOptionLabel={(option) => option.country}
                        onChange={(event, newValue)=>{
                          handleChangeCountry(newValue);
                        }}
                        value={{country:addressPayload.country}}
                        renderInput={(params) => <TextField {...params}
                          name='country'
                          className='login_input'
                          variant='outlined'
                          fullWidth 
                          label={t('ADD003')+' *'}
                          error={error.country}
                          size='small'/>}
                    />
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box display='flex' justifyContent='center' m={1} p={1}>
                    <TextField
                      fullWidth
                      required
                      error={error.state}
                      name='state'
                      label={t('ADD004')}
                      helperText=''
                      InputLabelProps={{ required: false }}
                      variant='outlined'
                      size='small'
                      defaultValue={addressPayload.state}
                      onChange={handleAddressChange}
                      autoComplete='off'
                      inputProps={{
                        maxlength: 100,
                        disabled:location.state.disable,
                      }}
                      value={addressPayload.state}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box display='flex' justifyContent='center' m={1} p={1}>
                    <TextField
                      fullWidth
                      required
                      helperText=''
                      variant='outlined'
                      size='small'
                      onChange={handleAddressChange}
                      autoComplete='off'
                      inputProps={{
                        maxlength: 100,
                        disabled:location.state.disable,
                      }}
                      label={t('ADD005')}
                      error={error.city}
                      name='city'
                      value={addressPayload.city}
                      defaultValue={addressPayload.city}
                      style={{ zIndex: 0 }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box display='flex' justifyContent='center' m={1} p={1}>
                    <TextField
                      fullWidth
                      error={error.zipCode}
                      name='areaCode'
                      label={t('ADD006')}
                      helperText=''
                      variant='outlined'
                      size='small'
                      value={addressPayload.areaCode}
                      defaultValue={addressPayload.areaCode}
                      onChange={handleAddressChange}
                      inputProps={{
                        disabled:location.state.disable,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box display='flex' justifyContent='center' m={1} p={1}>
                    <TextField
                      fullWidth
                      error={error.latitude}
                      name='latitude'
                      label={t('ADD007')}
                      helperText=''
                      variant='outlined'
                      size='small'
                      onChange={handleAddressChange}
                      value={addressPayload.latitude=='0.0'?'':addressPayload.latitude}
                      type='text'
                      InputLabelProps={{ shrink: true }}
                      style={{ zIndex: 0 }}
                      inputProps={{
                        disabled:location.state.disable,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box display='flex' justifyContent='center' m={1} p={1}>
                    <TextField
                      fullWidth
                      error={error.longitude}
                      name='longitude'
                      label={t('ADD008')}
                      helperText=''
                      variant='outlined'
                      size='small'
                      value={addressPayload.longitude=='0.0'?'':addressPayload.longitude}
                      onChange={handleAddressChange}
                      type='text'
                      InputLabelProps={{ shrink: true }}
                       inputProps={{
                        disabled:location.state.disable,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box display='flex' justifyContent='center' m={1} p={1}>
                    <TextField
                      fullWidth
                      error={error.radius}
                      name='radius'
                      label={t('ADD009')}
                      helperText=''
                      variant='outlined'
                      size='small'
                      value={addressPayload.radius=='0.0'?'':addressPayload.radius}
                      onChange={handleAddressChange}
                      style={{ zIndex: 0 }}
                      inputProps={{
                        disabled:location.state.disable,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box display='flex' justifyContent='center' m={1} p={1}>
                    <FormControl variant='outlined' size='small' fullWidth>
                      <InputLabel>{t('ADD010')}</InputLabel>
                      <Select
                        label={t('ADD010')}
                        error={error.timezone}
                        name='timeZone'
                        onChange={handleAddressChange}
                        value={addressPayload.timeZone}
                        defaultValue={addressPayload.timeZone}
                        MenuProps={MenuProps}
                        inputProps={{
                          disabled:location.state.disable,
                        }}
                      >
                        <MenuItem value='' selected>
                          <em>{(t('COMMON288'))}</em>
                        </MenuItem>
                        {timeZones ? (
                          timeZones.map((data, index) => (
                            <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                              <Typography variant='inherit' noWrap>
                                {data.name}
                              </Typography>
                            </MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display='flex' justifyContent='center' m={1} p={1}>
                    <FormControl variant='outlined' size='small' fullWidth>
                      <InputLabel style={{ zIndex: 0 }}>{t('ATYPE001') + '*'}</InputLabel>
                      <Select
                        label={t('ATYPE001') + '*'}
                        error={error.addressTypeId}
                        name='addressTypeId'
                        onChange={handleAddressChange}
                        value={addressPayload.addressTypeId}
                        defaultValue={addressPayload.addressTypeId}
                        MenuProps={MenuProps}
                        inputProps={{
                          disabled:location.state.disable,
                        }}
                      >
                        <MenuItem value='' selected>
                          <em>{(t('COMMON288'))}</em>
                        </MenuItem>
                        {addressType ? (
                          addressType.map((data, index) => (
                            <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                              <Typography variant='inherit' noWrap>
                                {data.name}
                              </Typography>
                            </MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid container justify='center'>
                  <DialogActions>
                  {!zlink&& <SubmitButton onClick={handleAddressSubmit} color='primary' variant='contained' size='small' className='m-2'>
                      {t('COMMON035')}
                    </SubmitButton>} 
                    <CancelButton color='primary' onClick={handleClose} variant='contained' size='small' className='m-2'>
                      {t('COMMON008')}
                    </CancelButton>
                  </DialogActions>
                </Grid>
              </Grid>
            </form>
            {/* </Paper> */}
          </TabPanel>
        )}
        {value === 2 && <TabPanel value={value} index={value}></TabPanel>}
      </div>
    </>
  );
}
