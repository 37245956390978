import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { DataGrid } from '@material-ui/data-grid';
import { Chip } from '@mui/material';

export default function DeviceOperationDialog(props) {
  const { t } = useTranslation();

  const handleClose = () => {
    props.onClose(false);
  };

  const handleSubmit = () => {
    props.handleSubmit();
  };

  const columns = [
    { field: 'alias', headerName: t('DEVI0001'), width: 160 },
    { field: 'name', headerName: t('COMMON026') },
    { field: 'deviceStatus', headerName: t('COMMON001') },
  ];

  return (
    <div>
      <Dialog maxWidth='sm' fullWidth open={props.open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-filter-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{props.header}</Box>
            <CancelIcon onClick={handleClose} style={{ color: 'red', background: 'white', borderRadius: '50%' }} fontSize='small' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} fullWidth>
              <p>
                <b className='acc-head'>{t('DEVI0037')}</b>
              </p>
            </Grid>
            <Grid item xs={12} fullWidth style={{ border: '1px solid #aaa', borderRadius: '5px', padding: '8px' }}>
              <Box display='flex' flexDirection='row' m={1} bgcolor='background.paper' flexWrap='wrap'>
                {props.devices.map((device) => {
                  return (
                    <Box item p={1}>
                      <Chip label={device.sn} style={{ color: device.deviceStatus !== 'Online' ? 'red' : 'green' }} />
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} fullWidth>
              {props.children}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
