import { GET_ACCAUXOUT_LIST, UPDATE_ACCAUXOUT ,GET_AUXOUT_STATUS} from "../actions/types";

const initialState = {
	accAuxOutList: [],
	updateAccAuxOut: [],
	auxOutStatus: [],
};

export default function (state = initialState, action) {
	switch (action.type) {

		case GET_ACCAUXOUT_LIST:
			return {
				...state,
				accAuxOutList: action.payload
			};
		case UPDATE_ACCAUXOUT:
			return {
				...state,
				updateAccAuxOut: action.payload
			};
        case GET_AUXOUT_STATUS:
			return {
				...state,
				auxOutStatus: action.payload
			}

		default:
			return state;
	}
}