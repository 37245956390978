import { Button, Grid } from '@material-ui/core';
import React, { Fragment, forwardRef } from 'react';
import axios from "axios";
import { REACT_URL_DASHBOARD, JAVA_URL_TIME_PERIOD, JAVA_URL_DOOR } from "../../actions/EndPoints";
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { connect } from "react-redux";
import Menu from '@material-ui/core/Menu';
import PropTypes from "prop-types";
import { getAccReaders, updateAccReader } from '../../actions/AccReaderActions';
import { getDoorList, updateDoorList } from '../../actions/DoorListActions';
import { handleEnableDoor, handleDisableDoor, getDoorDetails, handleRemoteOpen,handleDoorOperations } from '../../actions/DoorOperationsActions'; 
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { TablePagination } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ReactComponent as Refresh } from "../../assets/cloudaccess-svg/Refresh.svg";
import TimeLine from '../../utils/TimeLine';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import EditIcon from '@material-ui/icons/Edit';
import { FormHelperText } from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';
import FilterListIcon from '@material-ui/icons/FilterList';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import MenuIcon from '@material-ui/icons/Menu';
const styles = theme => ({
	form: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: 250
		}
	},
	formcontrol: {
		width: 150
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	}
});

const DialogTitle = withStyles(styles)(props => {
	const { children, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className="dilog-filter-head" {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className="dilog-form-closeButton" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});
class AccDoorList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			id: '',
			name: "",
			readers: [],
			deviceAlias: this.props.deviceAlias,
			readerNo: this.props.readerNo,
			commType: this.props.commType,
			commAddress: this.props.commAddress,
			readerState: this.props.readerState,
			iserror: false,
			updateAccReader: [],
			errorMessages: [],
			errors: {},
			showError: '',
			showSuccessAlert: false,
			resetAlert: false,
			verificationMode: [],
			timeZoneData: [],
			open: true,
			dialogText: '',
			isDialogOpen: false,
			isChecked: false,
			verifyMode: '',
			activeTimeSegName: '',
			passModeTimeSegName: '',
			activeTimeSegId: '',
			passModeTimeSegId: '',
			doorSensorStatus: '',
			doorNo: '',
			lockDelay: '',
			actionInterval: '',
			backLock: '',
			isDisableAudio: '',
			enabled: '',
			doorlist: [],
			response: false,
			selectedIds: [],
			enable: false,
			desable: false,
			doorName: '',
			sn: '',
			alias: '',
			openInterval: '',
			query: {
				page: 0,
				pageSize: 5,
			},
			totalCount: 0,
			pageChanged: true,
			filter: false,
			remoteOpening: false,
			remoteClosing: false,
			cancelAlarm: false,
			remoteNormallyOpen: false,
			activeLockdown: false,
			deactiveLockdown: false,
			doorOperationDetails: {},
			desableIntraday: false, 
			enableIntraday: false,


		};
		this.loadReaderList = this.loadReaderList.bind(this);
		this.handleRowUpdate = this.handleRowUpdate.bind(this);
		this.onCloseAlert = this.onCloseAlert.bind(this);
		this.verifiacationModeDropDown = this.verifiacationModeDropDown.bind(this);
		this.onChange = this.onChange.bind(this);
	}
	remoteOpeningDialog = () => {

		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		}
		else {
			var ids = [];
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			this.props.getDoorDetails(ids, "openDoor");
			this.setState({ remoteOpening: true })
		}

	}
	cancelAlarmOpenDialog = (popupState) => {
		popupState.close();
		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		}
		else {
			var ids = [];
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			this.props.getDoorDetails(ids, "cancelAlarm"); 
			this.setState({ cancelAlarm: true })
		}
	}
	cancelAlarmCloseDialog = (popupState) => {

		this.setState({ cancelAlarm: false })
	}

	remoteOpeningClose = () => {
		this.setState({ remoteOpening: false })

	}
	remoteClosingDialog = () => {
		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		}
		else {
			var ids = [];
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			this.props.getDoorDetails(ids, "closeDoor");
			this.setState({ remoteClosing: true })
		}
	}
	closingDoorDialogClose = () => {
		this.setState({ remoteClosing: false })
	}
	remoteNormallyOpenDialog = (popupState) => {
		popupState.close();
		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		}
		else {
			var ids = [];
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			this.props.getDoorDetails(ids, "normalOpenDoor"); 
			this.setState({ remoteNormallyOpen: true })
		}
	}
	remoteNormallyCloseDialog = () => {
		this.setState({ remoteNormallyOpen: false })
	}
	activeLockdownOpenDialog = (popupState) => {
		popupState.close();
		if (this.state.selectedIds.length <= 0) { 
			toast.warn("Please select any one device")
		}
		else {
			var ids = [];
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			this.props.getDoorDetails(ids, "lockDoor"); 
			this.setState({ activeLockdown: true })
		}
	}
	activeLockdownCloseDialog = () => {
		this.setState({ activeLockdown: false })
	}
	deactiveLockdownOpenDialog = (popupState) => {
		popupState.close();
		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		}
		else {
			var ids = [];
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			this.props.getDoorDetails(ids, "unLockDoor"); 
			this.setState({ deactiveLockdown: true })
		}
	}

	deactiveLockdownCloseDialog = () => {
		this.setState({ deactiveLockdown: false })

	}
	enableIntradayOpenDialog=(popupState)=>{
		popupState.close();
		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		}else {
			var ids = [];
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			this.props.getDoorDetails(ids, "enableNormalOpenDoor"); 
			this.setState({ enableIntraday: true })
		}

	}
	disableIntradayOpenDialog=(popupState)=>{
		popupState.close();
		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		}else {
			var ids = [];
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			this.props.getDoorDetails(ids, "disableNormalOpenDoor"); 
			this.setState({ desableIntraday: true })
		}

	}
	enableIntradayCloseDialog=()=>{
		this.setState({ enableIntraday: false })
	}
	desableIntradayCloseDialog=()=>{
		this.setState({ desableIntraday: false })
	}
	//this.props.getDoorDetails();retIds,offlineDoorsName

	handleRemoteOpen = () => {
		var ids = this.state.doorOperationDetails.retIds;
		this.props.handleRemoteOpen(this.state.openInterval, "openDoor", ids);
	}
	handleRemoteClose = () => {
		var ids = this.state.doorOperationDetails.retIds;
		this.props.handleDoorOperations("closeDoor", ids);
	}
	handleCancelAlarm=()=>{
		var ids = this.state.doorOperationDetails.retIds;
		this.props.handleDoorOperations("cancelAlarm", ids);
	}
	handleRemoteNormalOpen=()=>{
		var ids = this.state.doorOperationDetails.retIds;
		this.props.handleDoorOperations("normalOpenDoor", ids);
	}
	handleActiveLockDown=()=>{
		var ids = this.state.doorOperationDetails.retIds;
		this.props.handleDoorOperations("lockDoor", ids);
	}
	handleDeactiveLockDown=()=>{
		var ids = this.state.doorOperationDetails.retIds;
		this.props.handleDoorOperations("unLockDoor", ids);
	}
	handleEnableIntraday=()=>{
		var ids = this.state.doorOperationDetails.retIds;
		this.props.handleDoorOperations("enableNormalOpenDoor", ids);
	}
	handleDesableIntraday=()=>{
		var ids = this.state.doorOperationDetails.retIds;
		this.props.handleDoorOperations("disableNormalOpenDoor", ids);
	}
	componentDidMount() {

		this.fetchData();
		this.verifiacationModeDropDown();
		this.TimeZoneDropDown();

	}
	filterOpenDialog = () => {
		this.setState({ filter: true })
	}
	filterClose = () => {
		this.setState({
			filter: false,
			doorName: '',
			sn: '',
			alias: '',


		})
	}

	fetchData = (additionalState) => {
		const { query } = this.state;
		this.props.getDoorList(this.state.alias, this.state.doorName, this.state.sn, query).then(() => {
			this.setState({ ...additionalState });
		})
	}
	componentDidUpdate(prevProps) {

		if (this.props.response !== prevProps.response) {
			this.setState({ showSuccessAlert: this.props.response })
		}
		if (this.props.accReaders !== prevProps.accReaders) {
			this.setState({ devices: this.props.accReaders })
		}
		if (this.props.doorslist !== prevProps.doorslist) {
			this.setState({ doorlist: this.props.doorslist ? this.props.doorslist.item : [] })
		}
		if (this.props.doorOperationDetails) {
			if (this.props.doorOperationDetails !== prevProps.doorOperationDetails) {
				this.setState({ doorOperationDetails: this.props.doorOperationDetails })
			}
		}
		if (this.props.errors !== prevProps.errors) {
			if (Object.keys(this.props.errors).length === 0 && this.props.errors.constructor === Object) {
				this.setState({
					isDialogOpen: false, errors: this.props.errors
				});
			}
		}
		if (this.props.response !== prevProps.response) {
			this.setState({ response: this.props.response })
		}
		if (this.props.doorslist !== undefined) {
			if (this.props.doorslist !== prevProps.doorslist) {
				if (this.props.doorslist.totalCount !== undefined) {
					this.setState({ totalCount: this.props.doorslist.totalCount });
				} else {
					this.setState({ totalCount: 0 });
				}


			}
		}
	}
	loadReaderList() {
		this.props.getAccReaders();
	}
	onCloseAlert() {
		this.props.resetAlert();
	}
	handleRowUpdate = (newData, oldData, resolve) => {


		resolve()
	}
	verifiacationModeDropDown() {
		const { query } = this.state;
		const alias = '';
		const doorName = '';
		const sn = '';
		axios.get(JAVA_URL_DOOR + '?alias=' + alias + '&doorName=' + doorName + '&sn=' + sn + '&page=' + query.page + '&size=' + query.pageSize).then(response => {

			this.setState({
				verificationMode: response.data.data ? response.data.data : []
			});
		})
	}
	TimeZoneDropDown = () => {
		var name = '';
		var remark = '';
		axios.get(JAVA_URL_TIME_PERIOD + '?name=' + name + '&remark=' + remark).then(response => {
			this.setState({
				timeZoneData: response.data.data ? response.data.data : ''
			});
		})
	}
	handleChange = (event, rowdata) => {

		const target = event.target;
		const value = target.checked;

		this.setState({
			isChecked: value,
			isDialogOpen: true,
			name: rowdata.name,
			id: rowdata.id,
			doorNo: rowdata.doorNo,
			lockDelay: rowdata.lockDelay,
			actionInterval: rowdata.actionInterval,
			doorSensorStatus: rowdata.doorSensorStatus,
			backLock: rowdata.backLock,
			isDisableAudio: rowdata.isDisableAudio,
			enabled: rowdata.enabled,
			activeTimeSegId: rowdata.activeTimeSegId,
			passModeTimeSegId: rowdata.passModeTimeSegId,
			passModeTimeSegName: rowdata.passModeTimeSegName,
			activeTimeSegName: rowdata.activeTimeSegName,
			verifyMode: rowdata.verifyMode,
			forcePwd: rowdata.forcePwd,
			supperPwd: rowdata.supperPwd,

			[event.target.name]: event.target.value
		}, () => { console.log('Open Dialog') });
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
		}
	}
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });

	}

	handleClose = () => {
		this.setState({
			isDialogOpen: false, errors: {}
		});
	}
	enableOpenDialog = () => {
		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		} else {
			this.setState({ enable: !this.state.enable })
		}
	}
	enableClose = () => {
		this.setState({ enable: !this.state.enable })
	}
	disableOpenDialog = () => {
		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		} else {
			this.setState({ desable: !this.state.desable })
		} 
	}
	disableClose = () => {
		this.setState({ desable: !this.state.desable })
	}
	handleEnable = () => {
		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		} else {
			let ids = '';
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			ids = ids.replace(/,\s*$/, "");
			var doorListID = ids.split(",");
			const accDoorListDTO = {
				doorListID: doorListID,
			};
			this.props.handleEnableDoor(accDoorListDTO);
			this.setState({ enable: false, selectedIds: [] })
		}
	}
	handleDisable = () => {
		alert("desable")
		if (this.state.selectedIds.length <= 0) {
			toast.warn("Please select any one device")
		} else {
			let ids = '';
			this.state.selectedIds.map((row) => (ids += row.id + ','))
			ids = ids.replace(/,\s*$/, "");
			var doorListID = ids.split(",");
			const accDoorListDTO = {
				doorListID: doorListID,
			};
			this.props.handleDisableDoor(accDoorListDTO);
			this.setState({ desable: false, selectedIds: [] })
		}
	}
	filterApply = () => {
		const { query } = this.state;
		this.props.getDoorList(this.state.alias, this.state.doorName, this.state.sn, query);
		this.setState({
			filter: false, doorName: '',
			sn: '',
			alias: '',
		})
	}
	upDateDoorList = (e, rowData) => {
		let errorList = []
		if (errorList.length < 1) {
			if (errorList.length < 1) {
				const updateDoorList = {
					id: this.state.id,
					name: this.state.name,
					verifyMode: this.state.verifyMode,
					activeTimeSegId: this.state.activeTimeSegId,
					passModeTimeSegId: this.state.passModeTimeSegId,
					doorSensorStatus: this.state.doorSensorStatus,
					passModeTimeSegName: this.state.passModeTimeSegName,
					forcePwd: this.state.forcePwd,
					supperPwd: this.state.supperPwd,
				};

				this.props.updateDoorList(updateDoorList, this.props.history);

				if (this.props.response) {
					this.setState({ isDialogOpen: false })
				} else {
					this.setState({ isDialogOpen: true })
				}
				if (this.props.errors) {
					this.setState({ isDialogOpen: true })
				}
				//this.loadDoorList(); 
				this.fetchData();




			}


		}
	}

	myChangeHandler = (event) => {
		this.setState({ name: event.target.value });
	}


	render() {
		//const { classes } = this.props;
		console.log("door details" + JSON.stringify(this.props.doorOperationDetails))
		const { totalCount, query, pageChanged } = this.state;
		const { t } = this.props;
		//const doorlist = this.state.doorlist;
		let verifiacationModeDropDownData = this.state.verificationMode.item ? this.state.verificationMode.item.map(mode => (
			<MenuItem key={mode} value={mode.verifyMode} >{mode.verifyMode}</MenuItem>
		)) : '';

		let timeZoneDropDownData = this.state.timeZoneData.item ? this.state.timeZoneData.item.map(zone => (
			<MenuItem key={zone} value={zone.id} >{zone.name}</MenuItem>
		)) : '';

		// let doorNames = this.state.selectedIds ? this.state.selectedIds.map(name => {
		// 	return (
		// 		<Fragment>
		// 			{name.name},
		// 		</Fragment>
		// 	)
		// }) : '';
		const offlineDoorsName = this.state.doorOperationDetails.offlineDoorsName;
		const disabledDoorsName = this.state.doorOperationDetails.disabledDoorsName;
		const doorsName = this.state.doorOperationDetails.doorsName;
		
		const tableIcons = {
			Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
			Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
			DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
			Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
			Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
			FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
			LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
			NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
			ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
			SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
			ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
			ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
		};
		const columns = [
			{
				field: 'name', title: t("tableHeaderDoorName"),
				headerStyle: {
					color: 'black',
					backgroundColor: 'transparent',
				}
			},
			{ field: 'zoneName', title: t("zoneName"), editable: 'never' },
			{ field: 'deviceAlias', title: t("tableHeaderOwnedDevice"), editable: 'never' },
			{ field: 'deviceSn', title:  t("serialNumber"), editable: 'never' },
			{ field: 'doorNo', title:  t("tableHeadreDoorNumber"), editable: 'never' },
			{ field: 'enabled', title: t("tableHeaderEnable"), editable: 'never', render: rowData => rowData.enabled ? "Online" : "Offline" },
			{ field: 'activeTimeSegName', title: t("tableHeaderActiveTimeZone"), editable: 'never', hide: true },
			{ field: 'verifyMode', title: t("tableHeaderVerificationMode"), editable: 'never' },
		];

		//const {deviceName}=this.props.doorslist;

		return (
			<Fragment>
				<div style={{ width: '100%' }}>

					<Dialog
						className="dialog-timeperiod"
						open={this.state.isDialogOpen}
						onClose={this.handleDialogClose}
						fullWidth={true}
						maxWidth={'sm'}
					>

						<DialogTitle id="customized-dialog-title" onClose={this.handleDialogClose} >{"AccDoorListt"}</DialogTitle>

						<DialogContent>
							<form onSubmit={this.onSubmit} autoComplete="off">
								<Grid container>

									<Grid item xs={12}>
										<TextField className="text-fields"
											value={this.state.name} name="name"
											onChange={this.onChange}
											label={ t("tableHeaderDoorName")} type="text"
											error={this.state.errors.name ? true : false}
											helperText={this.state.errors.name}

										/>
									</Grid>
									<Grid item xs={12}>
										<FormControl className="text-fields" >
											<InputLabel>
												{t("tableHeaderVerificationMode")}
											</InputLabel>
											<Select labelId="demo-simple-select-helper-label"
												id="verifyMode"
												label="verifyMode"
												name="verifyMode"
												value={this.state.verifyMode}
												onChange={this.onChange}
												error={this.state.errors.verifyMode ? true : false}
												helperText={this.state.errors.verifyMode}
											>

												<MenuItem value={this.state.verifyMode}></MenuItem>
												<MenuItem value=""><em>None</em></MenuItem>
												{verifiacationModeDropDownData}
											</Select>

											<FormHelperText error>{this.state.errors.verifyMode}</FormHelperText>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<TextField className="text-fields"
											value={this.state.supperPwd}
											name="supperPwd" onChange={this.onChange}
											label={t("labelPassword")} type="text" required
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField className="text-fields" required value={this.state.forcePwd} name="forcePwd" onChange={this.onChange} label={"Emergency Password"} type="text" />
									</Grid>
									<Grid item xs={12}>
										<FormControl className="text-fields" >
											<InputLabel>
												{t("labelActiveTimeSeg")}
											</InputLabel>
											<Select labelId="demo-simple-select-helper-label"
												id="activeTimeSegId"
												label={"activeTimeSegId"}
												name="activeTimeSegId"
												value={this.state.activeTimeSegId}
												onChange={this.onChange}
												error={this.state.errors.activeTimeSegId ? true : false}
												helperText={this.state.errors.activeTimeSegId}
											>

												<MenuItem value={this.state.activeTimeSegId}> </MenuItem>
												<MenuItem value=""><em>None</em></MenuItem>
												{timeZoneDropDownData}
											</Select>
											<FormHelperText error>{this.state.errors.activeTimeSegId}</FormHelperText>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<TextField className="text-fields" required value={this.state.lockDelay} name="lockDelay" onChange={this.onChange} label={t("labelLockOpenDuration")} type="text" />
									</Grid>
									<Grid item xs={12}>
										<FormControl className="text-fields" >
											<InputLabel>
											{t("labelDoorSensorType")}
											</InputLabel>
				
											<InputLabel>
												{t("labelDoorSesorStatus")}
											</InputLabel>
											<Select labelId="demo-simple-select-helper-label"
												id="doorSensorStatus"
												label={t("labelDoorSesorStatus")}
												name="doorSensorStatus"
												value={this.state.doorSensorStatus}
												onChange={this.onChange}
												error={this.state.errors.doorSensorStatus ? true : false}

											>
												<MenuItem value={this.state.doorSensorStatus}></MenuItem>
												<MenuItem value=""><em>{"None"}</em></MenuItem>
												<MenuItem value="1">{"Normally Open"}</MenuItem>
												<MenuItem value="2">{"Normally Closed"}</MenuItem>

											</Select>
											<FormHelperText error>{this.state.errors.doorSensorStatus}</FormHelperText>
										</FormControl>
									</Grid>

									<Grid item xs={12}>
										<FormControl className="text-fields" >
											<InputLabel>
											{t("labelPassModeTimeSeg")} 
											</InputLabel>
											<Select labelId="demo-simple-select-helper-label"
												id="passModeTimeSegId"
												label={"passModeTimeSegId"}
												name="passModeTimeSegId"
												value={this.state.passModeTimeSegId}
												onChange={this.onChange}
												error={this.state.errors.passModeTimeSegId ? true : false}
											//helperText={this.state.errors.passModeTimeSegId}
											>

												<MenuItem value={this.state.passModeTimeSegId}><em></em></MenuItem>
												<MenuItem value=""><em>None</em></MenuItem>
												{timeZoneDropDownData}
											</Select>
											<FormHelperText error>{this.state.errors.passModeTimeSegId}</FormHelperText>
										</FormControl>
									</Grid>
								</Grid>

								<DialogActions className="dilog-form-buttons">
									<Button color="primary" onClick={this.upDateDoorList} variant="contained">
									{t("update")} 
									</Button>
									<Button color="primary" onClick={this.handleClose} variant="contained">
									{t("cancel")}
									</Button>
								</DialogActions>
							</form>
						</DialogContent>
					</Dialog>
					{/* Enable Door */}
					<Dialog open={this.state.enable} onClose={this.filterClose} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'xs'} >
						<DialogTitle id="form-dialog-title" >Filter</DialogTitle>
						<DialogContent >

							<div style={{ paddingTop: "20px" }}>
							{t("enableDoorOperation")} 
						</div>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleEnable} color="primary">
							{t("ok")} 
						</Button>
							<Button onClick={this.enableClose} color="primary">
							{t("cancel")} 
						</Button>
						</DialogActions>
					</Dialog>
					{/* Remote Opening */}
					<Dialog open={this.state.remoteOpening} onClose={this.setDeviceTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">Remote Opening</DialogTitle>
						<DialogContent style={{ color: "black" }}>
							<Card variant="outlined" style={{ height: "100px", color: "black", overflow: "auto" }}>
								<CardContent >
									{doorsName &&
										<Typography gutterBottom >
											{doorsName}
										</Typography>
									} 

									{offlineDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >
											{t("offlineDoorNotOperate")} <br></br>
											{offlineDoorsName}
										</Typography>
									}
									{disabledDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                        {t("disableDoorNotOperate")} <br></br>
											{disabledDoorsName}
										</Typography>
									}
								</CardContent>
							</Card>
							<Card variant="outlined" style={{ height: "150px", marginTop: "5px" }}>
								<CardContent >
									<Typography color="black" gutterBottom>
										<Box display="flex" justifyContent="flex-start" bgcolor="background.paper">
											<Box p={3}>
											{t("labelOpenInterval")} 
											</Box>
											<Box p={1} bgcolor="grey.300">

												<TextField margin="dense"
													variant="outlined"
													id="openInterval" name="openInterval"
													onChange={this.onChange}
													label="OpenInterval" size="small" type="text" fullWidth />

											</Box>
											<Box p={3} bgcolor="grey.300">
											{t("secondInterval")} 
											</Box>
										</Box>
									</Typography>
								</CardContent>
							</Card>

						</DialogContent>
						<DialogActions>
							<Button onClick={this.remoteOpeningClose} color="primary">
							{t("cancel")} 
						</Button>
							<Button onClick={this.handleRemoteOpen} disabled={!doorsName} color="primary">
							{t("ok")} 
						</Button>
						</DialogActions>
					</Dialog>
					{/* Remote Colosing */}
					<Dialog open={this.state.remoteClosing} onClose={this.setDeviceTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">Remote Closing</DialogTitle>
						<DialogContent style={{ color: "black" }}>
							<Card variant="outlined" style={{ height: "150px", color: "black", overflow: "auto" }}>
								<CardContent>
									{doorsName &&
										<Typography gutterBottom  >
											{doorsName}
										</Typography>
									}

									{offlineDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >
											{t("offlineDoorNotOperate")} <br></br>
											{offlineDoorsName}
										</Typography>
									}
									{disabledDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                          {t("disableDoorNotOperate")}<br></br>
											{disabledDoorsName}
										</Typography>
									}
								</CardContent>
							</Card>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.closingDoorDialogClose} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.handleRemoteClose} disabled={!doorsName} color="primary">
							{t("ok")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Cancel Alarm */}
					<Dialog open={this.state.cancelAlarm} onClose={this.setDeviceTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">{t("dialogCancelAlaram")} </DialogTitle>
						<DialogContent style={{ color: "black" }}>
							<Card variant="outlined" style={{ height: "150px", color: "black", overflow: "auto" }}>
								<CardContent>
								{doorsName &&
										<Typography gutterBottom >
											{doorsName}
										</Typography>
									}

									{offlineDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >
											{t("offlineDoorNotOperate")}<br></br>
											{offlineDoorsName}
										</Typography>
									}
									{disabledDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                            {t("disableDoorNotOperate")}<br></br>
											{disabledDoorsName}
										</Typography>
									}
								</CardContent> 
							</Card> 
						</DialogContent>
						<DialogActions>
							<Button onClick={this.cancelAlarmCloseDialog} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.handleCancelAlarm} color="primary" disabled={!doorsName}>
							{t("ok")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Remote Noramally Open */}
					<Dialog open={this.state.remoteNormallyOpen} onClose={this.setDeviceTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">{t("dialogRemoteNormallyOpen")} </DialogTitle>
						<DialogContent style={{ color: "black" }}>
							<Card variant="outlined" style={{ height: "150px", color: "black", overflow: "auto" }}>
								<CardContent>
								{doorsName &&
										<Typography gutterBottom >
											{doorsName}
										</Typography>
									}

									{offlineDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >
											{t("offlineDoorNotOperate")}<br></br>
											{offlineDoorsName}
										</Typography>
									}
									{disabledDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                            {t("disableDoorNotOperate")}<br></br>
											{disabledDoorsName}
										</Typography>
									}
								</CardContent>

							</Card> 
						</DialogContent>
						<DialogActions>
							<Button onClick={this.remoteNormallyCloseDialog} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.handleRemoteNormalOpen} color="primary" disabled={!doorsName}>
							{t("ok")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Active LockDown */}
					<Dialog open={this.state.activeLockdown} onClose={this.setDeviceTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">{t("dialogActiveLockDown")} </DialogTitle>
						<DialogContent style={{ color: "black" }}>
							<Card variant="outlined" style={{ height: "150px", color: "black", overflow: "auto" }}>
								<CardContent>
								{doorsName &&
										<Typography gutterBottom >
											{doorsName}
										</Typography>
									}

									{offlineDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >
											{t("offlineDoorNotOperate")}<br></br>
											{offlineDoorsName}
										</Typography>
									}
									{disabledDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                            {t("disableDoorNotOperate")}<br></br>
											{disabledDoorsName}
										</Typography>
									}
								</CardContent> 
							</Card> 
						</DialogContent>
						<DialogActions>
							<Button onClick={this.activeLockdownCloseDialog} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.handleActiveLockDown} color="primary" disabled={!doorsName} >
							{t("ok")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Deactive LockDown */}
					<Dialog open={this.state.deactiveLockdown} onClose={this.setDeviceTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">{t("dialogDeacticeLockDown")} </DialogTitle>
						<DialogContent style={{ color: "black" }}>
							<Card variant="outlined" style={{ height: "150px", color: "black", overflow: "auto" }}>
								<CardContent>
								{doorsName &&
										<Typography gutterBottom >
											{doorsName}
										</Typography>
									}

									{offlineDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >
											{t("offlineDoorNotOperate")}<br></br>
											{offlineDoorsName}
										</Typography>
									}
									{disabledDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                            {t("disableDoorNotOperate")} <br></br>
											{disabledDoorsName}
										</Typography>
									}
								</CardContent> 
							</Card> 
						</DialogContent>
						<DialogActions>
							<Button onClick={this.deactiveLockdownCloseDialog} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.handleDeactiveLockDown} color="primary" backgroundColor="red"  disabled={!doorsName} >
							{t("ok")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Enable IntraDay */}
					<Dialog open={this.state.enableIntraday} onClose={this.setDeviceTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">{t("dialogEnablentraday")} </DialogTitle>
						<DialogContent style={{ color: "black" }}>
							<Card variant="outlined" style={{ height: "150px", color: "black", overflow: "auto" }}>
								<CardContent>
								{doorsName &&
										<Typography gutterBottom >
											{doorsName}
										</Typography>
									}

									{offlineDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >
											{t("offlineDoorNotOperate")}<br></br>
											{offlineDoorsName}
										</Typography>
									}
									{disabledDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                            {t("disableDoorNotOperate")}<br></br>
											{disabledDoorsName}
										</Typography>
									}
								</CardContent> 
							</Card> 
						</DialogContent>
						<DialogActions>
							<Button onClick={this.enableIntradayCloseDialog} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.handleEnableIntraday} color="primary" backgroundColor="red"  disabled={!doorsName} >
							{t("ok")}
						</Button>
						</DialogActions>
					</Dialog> 
			 
					{/* Desable IntraDay */}
					<Dialog open={this.state.desableIntraday} onClose={this.setDeviceTime} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'sm'}>
						<DialogTitle id="form-dialog-title">{t("dialogDesableIntraday")} </DialogTitle>
						<DialogContent style={{ color: "black" }}>
							<Card variant="outlined" style={{ height: "150px", color: "black", overflow: "auto" }}>
								<CardContent>
								{doorsName &&
										<Typography gutterBottom >
											{doorsName}
										</Typography>
									}

									{offlineDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >
											{t("offlineDoorNotOperate")}<br></br>
											{offlineDoorsName}
										</Typography>
									}
									{disabledDoorsName &&
										<Typography gutterBottom style={{ color: "red" }} >

                                            {t("disableDoorNotOperate")}<br></br>
											{disabledDoorsName}
										</Typography>
									}
								</CardContent> 
							</Card> 
						</DialogContent>
						<DialogActions>
							<Button onClick={this.desableIntradayCloseDialog} color="primary">
							{t("cancel")}
						</Button>
							<Button onClick={this.handleDesableIntraday} color="primary" backgroundColor="red"  disabled={!doorsName} >
							{t("ok")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Disable Door */}
					<Dialog open={this.state.desable} onClose={this.filterClose} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'xs'} >
						<DialogTitle id="form-dialog-title" >{t("dialogDisableDoor")} </DialogTitle>
						<DialogContent>

							 <div style={{ paddingTop: "20px" }}>
							 {t("disableOperation")} 
						     </div>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleDisable} color="primary">
							{t("ok")}
						</Button>
							<Button onClick={this.disableClose} color="primary">
							{t("cancel")}
						</Button>
						</DialogActions>
					</Dialog>
					{/* Door Filter */}
					<Dialog open={this.state.filter} aria-labelledby="form-dialog-title" fullWidth={true}
						maxWidth={'xs'} >
						<DialogTitle id="form-dialog-title" onClose={this.filterClose}>Filter</DialogTitle>
						<DialogContent>
							<Grid item xs={12}>
								<TextField className="text-fields"
									value={this.state.doorName} name="doorName"
									onChange={this.onChange}
									label={t("tableHeaderDoorName")} type="text"
									error={this.state.errors.name ? true : false}
									helperText={this.state.errors.name}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField className="text-fields"
									value={this.state.alias} name="alias"
									onChange={this.onChange}
									label={t("deviceName")} type="text"
									error={this.state.errors.name ? true : false}
									helperText={this.state.errors.name}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField className="text-fields"
									value={this.state.sn} name="sn"
									onChange={this.onChange}
									label={t("serialNumber")} type="text"
									error={this.state.errors.name ? true : false}
									helperText={this.state.errors.name}

								/>
							</Grid>



						</DialogContent>
						<DialogActions>
							<Button onClick={this.filterApply} color="primary">
							{t("apply")}
						</Button>
							<Button onClick={this.filterClose} color="primary">
							{t("reset")}
						</Button>
						</DialogActions>
					</Dialog>


					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start">
						<Grid item xs={12}>
							<TimeLine menu="Device Management" page="Door" menuPath={REACT_URL_DASHBOARD} pagePath="" />
						</Grid>

					</Grid>
					<Box display="flex" p={1} bgcolor="background.paper">
						<Box p={1} flexGrow={1}>
							<p><b className="acc-head">{t("menuSiteDoorTitle")} </b></p>
						</Box>
						{/* <Box p={1}>
								<Paper className="search-root">
									<InputBase
										className="search-input"
										placeholder="Search"
										inputProps={{ "aria-label": "search" }}
										ref={this.input}
										type="text" name="search" onChange={this.handleInputChange} checked={this.state.isChecked} />
									<IconButton
										type="submit"
										className="search-iconButton"
										aria-label="search" onClick={this.loadDoorList}>
										<SearchIcon />
									</IconButton>
								</Paper>
							</Box> */}
						<Box p={1}>
							<Button
								variant="contained"
								color="primary"
								size="small"
								startIcon={<FilterListIcon />}
								onClick={this.filterOpenDialog}
							>
								{t("filter")} 
							</Button>

						</Box>
					</Box>
					<Box display="flex" p={1} bgcolor="background.paper">
						<Box p={1}>
							<Button
								onClick={this.fetchData}
								color="secondary"
								size="small"
								startIcon={<Refresh className="refresh-btn" />}
								className="acc-list-action-btn">
								{t("buttonCommonRefresh")}
							</Button>
						</Box>
						<Box p={1}>
							<Button
								onClick={this.remoteOpeningDialog}
								color="secondary"
								size="small"
								startIcon={<OpenInNewIcon className="refresh-btn" />}
								className="acc-list-action-btn">
								{t("remoteOpening")} 
							</Button>
						</Box>
						<Box p={1}>
							<Button
								onClick={this.remoteClosingDialog}
								color="secondary"
								size="small"
								startIcon={<CancelPresentationIcon className="refresh-btn" />}
								className="acc-list-action-btn">
								{t("remoteClosing")}  
							</Button>
						</Box>
						<Box p={1}>
							<Button
								onClick={this.enableOpenDialog}
								color="secondary"
								size="small"
								startIcon={<DoneIcon className="refresh-btn" />}
								className="acc-list-action-btn">
								{t("tableHeaderEnable")}  
							</Button>
						</Box>
						<Box p={1}>
							<Button
								onClick={this.disableOpenDialog}
								color="secondary"
								size="small"
								startIcon={<NotInterestedIcon className="refresh-btn" />}
								className="acc-list-action-btn">
								 {t("menuDisable")} 
							</Button>
						</Box>
						<Box p={1}>
							<PopupState variant="popover" popupId="demo-popup-menu">
								{(popupState) => (
									<React.Fragment>
										<Button
											color="secondary"
											size="small"
											startIcon={<MenuIcon className="delete-btn" />}
											className="acc-list-action-btn"
											{...bindTrigger(popupState)}>
											{t("more")}   <ExpandMoreIcon className="delete-btn" />
										</Button>
										<Menu {...bindMenu(popupState)}>
											<MenuItem onClick={() => this.cancelAlarmOpenDialog(popupState)} >{t("dialogCancelAlaram")}</MenuItem>
											<MenuItem onClick={() => this.remoteNormallyOpenDialog(popupState)}>{t("dialogRemoteNormallyOpen")} </MenuItem>
											<MenuItem onClick={() => this.activeLockdownOpenDialog(popupState)}> {t("dialogActiveLockDown")}</MenuItem>
											<MenuItem onClick={() => this.deactiveLockdownOpenDialog(popupState)} >{t("dialogDeactiveLockDown")} </MenuItem>
											<MenuItem onClick={() => this.enableIntradayOpenDialog(popupState)} >{t("dialogEnablentraday")}</MenuItem>
											<MenuItem onClick={() => this.disableIntradayOpenDialog(popupState)} >{t("dialogDesableIntraday")}</MenuItem>

										</Menu>
									</React.Fragment>
								)}
							</PopupState>
						</Box>
					</Box>
				</div>
				<MaterialTable
					columns={columns}
					data={this.state.doorlist ? this.state.doorlist  : []}
					icons={tableIcons}
					options={{
						minBodyHeight: '100%',
						actionsColumnIndex: -1,
						addRowPosition: 'first',
						showTitle: false,
						toolbarButtonAlignment: "left",
						toolbar: false,
						selection: true,
						pageSize: 5,
						//exportButton: true

					}}
					onSelectionChange={(rows) => this.setState({ selectedIds: rows })}
					actions={[
						// {
						// 	icon: () => <SwitchCameraIcon color="secondary" />,
						// 	tooltip: "Binding/Unbinding camera",
						// 	position: 'row',
						// 	onClick: (event) => {
						// 		console.log(event.target.value);
						// 	}
						// },
						{
							icon: () => <EditIcon color="secondary" />,
							tooltip: "Edit Door",
							position: 'row',
							onClick: (event, rowData) => {
								this.handleChange(event, rowData);

							}
						}


					]}


					// onSelectionChange={data => {
					// 	this.setState({
					// 	  timePeriods: data
					// 	})
					// 	}}
					components={{
						Pagination: (props) => (
							<TablePagination {...props} count={totalCount} page={query.page} rowsPerPage={query.pageSize} />
						),
					}}
					onPageChange={(page) => {
						if (pageChanged) {
							this.setState(
								{ query: { ...query, page: page }, pageChanged: false }, () => this.fetchData({ pageChanged: true })
							);
						}
					}}

					onRowsPerPageChange={(pageSize) => {
						this.setState({ query: { ...query, pageSize } }, this.fetchData);
					}}
				/>

			</Fragment>
		);
	}
}
AccDoorList.propTypes = {
	updateAccReader: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
	accReaderList: state.AccRedaerList.accReaderList,
	doorslist: state.DoorList.doorsList,
	updateAccReader: state.AccRedaerList.updateAccReader,
	updateDoorList: state.DoorList.updateDoorList,
	doorOperationDetails: state.doorOperationDetailsList.doorOperationDetails,
	errors: state.errors,
	response: state.DoorList.response,

});

export default compose(withTranslation(),connect(mapStateToProps, { getDoorList,handleDoorOperations, handleRemoteOpen, updateDoorList, handleEnableDoor, handleDisableDoor, getDoorDetails, getAccReaders, updateAccReader }))(AccDoorList);
