import React from'react';
import MaterialTable from"material-table";
import{connect}from"react-redux";
import{forwardRef}from'react';
import ArrowDownward from'@material-ui/icons/ArrowDownward';
import Check from'@material-ui/icons/Check';
import ChevronLeft from'@material-ui/icons/ChevronLeft';
import { JAVA_URL_SEARCH_DEVICE } from "../../actions/EndPoints";
import ChevronRight from'@material-ui/icons/ChevronRight';
import Clear from'@material-ui/icons/Clear';
import DeleteOutline from'@material-ui/icons/DeleteOutline';
import Edit from'@material-ui/icons/Edit';
import FilterList from'@material-ui/icons/FilterList';
import FirstPage from'@material-ui/icons/FirstPage';
import LastPage from'@material-ui/icons/LastPage';
import Remove from'@material-ui/icons/Remove';
import SaveAlt from'@material-ui/icons/SaveAlt';
import Search from'@material-ui/icons/Search';
import ViewColumn from'@material-ui/icons/ViewColumn';
import {compose} from 'redux';
import { withTranslation } from 'react-i18next';
 import {addDevice} from "../../actions/DeviceActions";
import axios from 'axios'; 
import {  Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Box,  Grid } from '@material-ui/core';
import TimeLine from '../../utils/TimeLine';

class AccdeviceData extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
     data:[]
    }
	 
  }
	addDevice=(sn)=>{
		 this.props.addDevice(sn,this.props.history)
			
		}
	componentDidMount() {  
	    var companyCode = localStorage.getItem("companyCode"); 
		axios.get(JAVA_URL_SEARCH_DEVICE+'?companyCode='+companyCode).then(response => {   
		  this.setState({   
			data: response.data.data  
	
		  });  
	
		});  
	
	  }  


	render() {
		const { t } = this.props;
	  
	  const columns = [
	                   { field: 'ip', title: t("ipAddress") },
	                   { field: 'macAddress', title: t("macAddress") },
	                   { field: 'subnetMask', title: t("subnetMask") },
	                   { field: 'gateway', title: t("gateWayAddress") },
	                   { field: 'sn', title:  t("serialNumber") },
	                   { field: 'deviceType', title:  t("deviceType") },
	                   { field: 'serverUrl', title:  t("setServer")},
	                 
	                   ];

	                 
	                 
	                 const tableIcons = {
	                		 Add: forwardRef((props, ref) => (<Button {...props} ref={ref} className="createButton" variant="contained" color="primary">{"create"}</Button>
	                		            )),
	                	        Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }}/>),
	                	        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }}/>)
	                	      };
	                 
      return (
    		  <div>
    		  <Grid item xs={12} sm={12}>
    		     
				 
			</Grid>
        <Grid 
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start">
            <Grid item xs={12}>
                <TimeLine menu="Device Management" page="AddDevice" menuPath="/acc/accdevicedata" pagePath=""/>
            </Grid>
                
        </Grid>
            <Box display="flex" p={1} bgcolor="background.paper">
	            <Box p={1} flexGrow={1}>
				<p><b className="acc-head">{t('addDevice')}</b></p>
			   </Box> 
            </Box>
    		  <MaterialTable
              columns={columns}
              data={this.state.data}
              icons={tableIcons}
              options={{
                minBodyHeight:'100%',
                actionsColumnIndex: -1,
                addRowPosition: 'first',
                showTitle: false,
                toolbarButtonAlignment: "left",
                toolbar: false,
                selection: false,
                pageSize: 5
              }}
              editable={{
            	  onRowAdd: (newData) =>
				new Promise((resolve) => {
					this.handleRowAdd(newData, resolve)
				}),
              }}
			          
              localization={{
                header: {
                  actions:  "Actions"
                }

              }}
    		  actions={[
                        {
                            icon: () => <AddCircleOutlineIcon />,
                            tooltip: t("addDevice"),
                            position: 'row',
                            onClick: (event, rowData) => {
                                console.log(rowData.sn)
                                this.addDevice(rowData.sn);
                            }
                        }
                    ]} 
            />
    		  </div>
    		  );
  }
}
const mapStateToProps = (state) => ({
	  admsAccDevices: state.AccDevice.admsAccdeviceData,
	   
	});
export default compose(withTranslation(),connect(mapStateToProps, {addDevice})) (AccdeviceData);