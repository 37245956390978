import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getDevices, getSdmsDevices } from '../../actions/DeviceApiCalls';
import { setActiveMenu } from '../../reducers/ThemeOptions';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { addDevice } from '../../actions/DeviceApiCalls';
import { REACT_URL_DEVICE_LIST, REACT_URL_DEVICE_ADD } from '../../actions/EndPoints';
import Headerbar from '../../components/HeaderBar';
import { SDMS_SERVICE_URL } from '../../constants/RestConfig';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
//import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import Axios from 'axios';
import { JAVA_API_DEVICE } from '../../actions/ApiEndPoints';
import { toast } from 'react-toastify';
import CustomDefaultDataTable from '../../components/CustomDefaultDataTable';
import { ReactComponent as Add } from '../../assets/icons/add.svg';
// import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import CachedIcon from '@material-ui/icons/Cached';
import SiteZoneList from '../../components/device-management/SiteZoneList';

export default function SearchDevice() {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [devices, setDevices] = useState(store.AccDevice.admsAccdeviceData);
  //const [companyCode, setCompanyCode] = useState(localStorage.getItem("companyCode").toLowerCase())
  const [companyCode] = useState(localStorage.getItem('companyCode').toLowerCase());
  const [selectedDevices, onSelectionChange] = useState([]);
  const [selDevice, setSelDevice] = useState({});


  useEffect(() => {
    dispatch(setActiveMenu('/acc/door'));
    loadSdmsDevice();
    // eslint-disable-next-line
  }, []);

  // alert(JSON.stringify(store.AccDevice.admsAccdeviceData))

  useEffect(() => {
    var devices = [];
    // eslint-disable-next-line
    // store.AccDevice.admsAccdeviceData.map((device) => {
    //   if (!isDeviceExist(device.sn)) {
    //     devices.push(device);
    //   }
    // });
    setDevices(store.AccDevice.admsAccdeviceData);
    // eslint-disable-next-line
  }, [store.AccDevice.admsAccdeviceData]);

  const loadSdmsDevice = () => {
    dispatch(getDevices('', '', '', '', 0, 10000));
    dispatch(getSdmsDevices());
  };
  
  const addSdmsDevice = (sn) => {
    let ress={};
    const payload = {
      deviceName: null,
      iconType: null,
      siteId: null,
      clearData: false,
      timeZone: null,
    };
        dispatch(addDevice(sn, payload, history));  
    };
    

  const addMultipleDevice = (devices) => {
    if (selectedDevices.length < 1) {
      toast.warning(t('DEVI0014'));
      return;
    }
    devices.map((device) => {
      addSdmsDevice(device.sn);
    });
  };

  // eslint-disable-next-line
  const addAllSdmsDevice = () => {
    // eslint-disable-next-line

    selectedDevices.map((device, index) => {
      Axios.post(JAVA_API_DEVICE + '/' + device.sn);
      if (index === selectedDevices.length - 1) {
        toast.success('Devices added successfully');
        history.push(REACT_URL_DEVICE_LIST);
      }
    });
  };

  const breadcrumb = [
    { path: REACT_URL_DEVICE_LIST, name: t('DEVI0013') },
    { path: REACT_URL_DEVICE_LIST, name: t('DEVI0001') },
    { path: REACT_URL_DEVICE_ADD + localStorage.getItem('companyCode'), name: t('DEVI0012') },
  ];

  const columns = [
    { field: 'ip', title: t('DEVI0002') },
    { field: 'sn', title: t('DEVI0003') },
    { field: 'ip', title: t('DEVI0004') },
    { field: 'deviceName', title: t('DEVI0005') },
    { field: 'macAddress', title: t('DEVI0026') },
    { field: 'subnetMask', title: t('DEVI0027') },
  ];

  // const actions = [
  //   {
  //     icon: () =>
  //      <SiteZoneList data={selDevice} options={options}  data={devices} />,
      
  //     // tooltip: t('COMMON009'),
  //     position: 'row',
  //     onClick: (event, rowData) => {
  //       setSelDevice(rowData)
  //     },
  //   },


  //   // (rowData) => ({
  //   //   icon: () => (isDeviceExist(rowData.sn) ? <CheckBoxIcon color='disabled' /> : <AddToPhotosIcon color='secondary' />),
  //   //   tooltip: isDeviceExist(rowData.sn) ? t('COMMON023') : t('COMMON009'),
  //   //   onClick: (event, rowData) => {
  //   //     if (isDeviceExist(rowData.sn)) return;
  //   //     addSdmsDevice(rowData.sn);
  //   //   },
  //   //   disabled: isDeviceExist(rowData.sn),
  //   // }),
  // ];

  // const options = {
  //     actionsColumnIndex: -1,
  //     search: false,
  //     selection: true,
  //     toolbar: false
  // }

  const options = {
    actionsColumnIndex: -1,
    search: true,
    selection: false,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    pageSize: 10,
  };

  const isDeviceExist = (sn) => {
    var isExist = false;
    if (store.AccDevice.accDeviceList && store.AccDevice.accDeviceList.devices) {
      // eslint-disable-next-line
      store.AccDevice.accDeviceList.devices.map((e) => {
        if (e.sn === sn) {
          isExist = true;
        }
      });
    }
    return isExist;
  };

  const actions = [
    {
      icon: () =>
       <SiteZoneList data={selDevice} />,
      
      // tooltip: t('COMMON009'),
      position: 'row',
      onClick: (event, rowData) => {
        setSelDevice(rowData)
      },
    },


    // (rowData) => ({
    //   icon: () => (isDeviceExist(rowData.sn) ? <CheckBoxIcon color='disabled' /> : <AddToPhotosIcon color='secondary' />),
    //   tooltip: isDeviceExist(rowData.sn) ? t('COMMON023') : t('COMMON009'),
    //   onClick: (event, rowData) => {
    //     if (isDeviceExist(rowData.sn)) return;
    //     addSdmsDevice(rowData.sn);
    //   },
    //   disabled: isDeviceExist(rowData.sn),
    // }),
  ];




  return (
    <Grid>
      <Breadcrumb links={breadcrumb}></Breadcrumb>
      <Headerbar title={t('DEVI0012')}>
        <Box paddingRight={1} color='primary'>
          <b className='acc-head'>{t('DEVI0058') + SDMS_SERVICE_URL.replace('companyCode', companyCode)}</b>
        </Box>
      </Headerbar>
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => loadSdmsDevice()}>
              <CachedIcon style={{ height: 30, width: 30 }} />
            </IconButton>
          </Tooltip>
        </Box>
        {/* <Box p={1}>
          <Tooltip title={t('COMMON009')}>
            <IconButton color='secondary' aria-label={t('COMMON009')} onClick={() => addMultipleDevice(selectedDevices)}>
              <Add style={{ height: 30, width: 30 }} />
            </IconButton>
          </Tooltip>
        </Box> */}
        {/* <Box p={1}>
                    <Button
                        onClick={()=>addAllSdmsDevice()}
                        color="secondary"
                        size="small"
                        startIcon={<AddCircleOutlineRoundedIcon className="refresh-btn" />}
                        className="acc-list-action-btn">
                        {t("Add all devices")}
                    </Button>
                </Box> */}
      </Toolbar>
      <CustomDefaultDataTable columns={columns} data={devices} actions={actions} options={options} onSelectedRoles={(rows) => onSelectionChange(rows)} />
    
     
    
    </Grid>
  );
}
