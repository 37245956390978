import axios from "axios";
import { toast } from 'react-toastify';
import { GET_ACCESS_DOOR_LIST, GET_ERRORS, GET_ACCRESPONSE,ACCESS_LEVEL_DOOR_LIST } from "./types";
import { JAVA_URL_DOOR, JAVA_URL_ACCESS_LEVEL_DOOR} from "./EndPoints";

export const getDoorList = (alias, doorName, sn, query) => async dispatch => { 
  const res = await axios.get(JAVA_URL_DOOR + '?alias=' + alias + '&doorName=' + doorName + '&sn=' + sn + '&page=' + query.page + '&size=' + query.pageSize);
  dispatch({
    type: GET_ACCESS_DOOR_LIST,
    payload: res.data.data
  });
};
export const getAccDoorDetails = (levelId,flag,query) => async dispatch => { 
  const res = await axios.get(JAVA_URL_ACCESS_LEVEL_DOOR.replace("{levelId}", levelId)+"?flag="+ flag +'&page=' + query.page + '&size=' + query.pageSize);
  dispatch({
    type: ACCESS_LEVEL_DOOR_LIST,
    payload: res.data.data
  });
};

export const updateDoorList = (updateDoorList, history) => async dispatch => {
  try {
    const res = await axios.put(JAVA_URL_DOOR, updateDoorList);
    if (res.status === 200) {
      toast.success("door updated successfully");
      var alias='';
      var doorName='';
      var sn='';
      var query = {
        page: '',
        pageSize: ''
      } 
      const res = await axios.get(JAVA_URL_DOOR + '?alias=' + alias + '&doorName=' + doorName + '&sn=' + sn + '&page=' + query.page + '&size=' + query.pageSize);
      dispatch({
        type: GET_ACCESS_DOOR_LIST,
        payload: res.data.data
      });
      dispatch({
        type: GET_ERRORS,
        payload: {}
      });
      dispatch({
        type: GET_ACCRESPONSE,
        payload: true
      });
    }

  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data
    });
  }
};

