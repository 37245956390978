import { GET_TIMEPERIODS, SUCCESS } from "../actions/types";

const initialState = {
    timePeriodList : [],
    isSuccess : false
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_TIMEPERIODS:
			return {
                ...state,
				timePeriodList: action.payload
            };
        case SUCCESS:
            return {
                ...state,
                isSuccess: true
            };

		default:
			return state;
	}
}