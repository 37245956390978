import axios from "axios";
import { GET_ERRORS, GET_REPORTS_TRANSACTIONS, GET_REPORTS_FILTER_TRANSACTIONS } from "./types";
import { 
	JAVA_URL_TRANSACTIONS, 
	JAVA_URL_ALL_TRANSACTION_EXPORT_EXCEL } from "./EndPoints";
import { toast } from 'react-toastify';
export const getTransactions = (filter, query) => async dispatch => {
	try {
		const res = await axios.get(JAVA_URL_TRANSACTIONS + "?personPin=" + filter.personPin + "&deviceAlias=" + filter.deviceAlias + "&deptCode=" + filter.deptCode
			+ "&deptName=" + filter.deptName + "&cardNo=" + filter.cardNo + "&readerName=" + filter.readerName + "&name=" + filter.name + "&eventPointName=" + filter.eventPointName
			+ "&eventPointId=" + filter.eventPointId + "&eventDescription=" + filter.eventDescription
			+ "&fromDate=" + filter.fromDate + "&toDate=" + filter.toDate + '&page=' + query.page + '&size=' + query.pageSize);
		dispatch({
			type: GET_REPORTS_TRANSACTIONS,
			payload: res.data.data
		});
	}
	catch (err) {
		dispatch({
			type: GET_ERRORS,
			payload: null
		});
	}

};

export const transactionFilters = (filter) => async dispatch => {
	try {
		const res = await axios.post(JAVA_URL_TRANSACTIONS, filter);
		dispatch({
			type: GET_REPORTS_FILTER_TRANSACTIONS,
			payload: res.data.data
		});
	} catch (err) {
		dispatch({
			type: GET_ERRORS,
			payload: err.response.data.data
		});
	}
};

export const handleAllTransactionExport = () => async dispatch => { 
	try {
		axios({
			url: JAVA_URL_ALL_TRANSACTION_EXPORT_EXCEL,
			method: 'get',
			params: {

			},
			responseType: "blob"
		}).then(function (response) {
			const blob = new Blob(
				[response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
			const aEle = document.createElement('a');     // Create a label
			const href = window.URL.createObjectURL(blob);       // Create downloaded link
			aEle.href = href;
			aEle.download = 'AllTransaction.xlsx';  // File name after download
			document.body.appendChild(aEle);
			aEle.click();     // Click to download
			document.body.removeChild(aEle); // Download complete remove element
			window.URL.revokeObjectURL(href) // Release blob object
		})
		toast.success("Export Device Data successful")

	} catch (err) {
		toast.error("Something went wrong")
	}
};