import axios from "axios";
import { JAVA_API_INTERLOCK } from "./ApiEndPoints";
import { toast } from 'react-toastify';
import { INTERLOCK_ERRORS, INTERLOCK_LIST,INTERLOCK_DEVICE_RULE } from "./types";

export const getInterlocks = (deviceName, page, size) => async dispatch => {
	try {
		const res = await axios.get(JAVA_API_INTERLOCK + "?deviceName=" + deviceName + "&page=" + page + "&size=10000");
		if (res.status === 200 && res.data.code === "CASI0001") {
			dispatch({
				type: INTERLOCK_LIST,
				payload: res.data.data
			});
		} else {
			dispatch({
				type: INTERLOCK_LIST,
				payload: []
			});
		}
	} catch {

	}

};


export const updateInterlock = (payload, callBack) => async dispatch => {
	try {
		const res = await axios.put(JAVA_API_INTERLOCK, payload);
		if (res.status === 200 && res.data.code === "CASI0028") {
			toast.success(res.data.message);
			callBack(res.data);
			dispatch({
				type: INTERLOCK_ERRORS,
				payload: {}
			});
		} else {
			toast.error(res.data.message);
			dispatch({
				type: INTERLOCK_ERRORS,
				payload: res.data
			});
		}
	} catch {

	}
}

export const addInterlock = (payload, callBack) => async dispatch => {
	try {
		const res = await axios.post(JAVA_API_INTERLOCK, payload);
		if (res.status === 200 && res.data.code === "CASI0027") {
			toast.success(res.data.message);
			callBack();
			dispatch({
				type: INTERLOCK_ERRORS,
				payload: {}
			});
		} else {
			toast.error(res.data.message);
			dispatch({
				type: INTERLOCK_ERRORS,
				payload: res.data
			});
		}
	} catch (error) {

	}

}

export const removeInterlock = (ids, callBack) => async dispatch => {
	try {
		const res = await axios.delete(JAVA_API_INTERLOCK + "?ids=" + ids)
		if (res.status === 200 && res.data.code === "CASI0023") {
			toast.success(res.data.message);
			callBack();
		} else {
			toast.error(res.data.message);
		}
	} catch {

	}
}

export const getInterlockRules = (deviceId) => async dispatch => {

	try {
		const res = await axios.get(JAVA_API_INTERLOCK  + "/"  +deviceId + "/rules");
		if (res.status === 200 && res.data.code === "CASI0022") {
			dispatch({
				type: INTERLOCK_DEVICE_RULE,
				payload: res.data.data
			});
		} else {
			dispatch({
				type: INTERLOCK_DEVICE_RULE,
				payload: []
			});
		}
	} catch {

	}
}


export const resetInterlockRule = () => async dispatch => {
    dispatch({
        type: INTERLOCK_DEVICE_RULE,
        payload: []
    });

}





