import axios from 'axios';
import { toast } from 'react-toastify';
import { JAVA_API_DEVICE_OPERATIONS } from './ApiEndPoints';

export const setTimeZones = (deviceIds, timeZone, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_DEVICE_OPERATIONS + '/timezone?timeZone=' + timeZone + '&deviceIdList=' + deviceIds);
    if (res.status === 200 && res.data.code === 'CASI0007') {
      toast.success(res.data.message);
      callBack();
    } else if (res.status === 200) {
      toast.error(res.data.message);
    }
  } catch (error) {}
};

export const setRegistrationDevice = (deviceId, isRegDevice, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_DEVICE_OPERATIONS + '/' + deviceId + '/registration?isRegDevice=' + isRegDevice);
    if (res.status === 200 && res.data.code === 'CASI0007') {
      toast.success(res.data.message);
      callBack();
    } else {
      toast.error(res.data.message);
      callBack();
    }
  } catch (error) {}
};

export const setInOutState = (deviceId, inOut, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_DEVICE_OPERATIONS + '/' + deviceId + '/in_out_state?inOutState=' + inOut);
    if (res.status === 200 && res.data.code === 'CASI0007') {
      toast.success(res.data.message);
      callBack();
    } else if (res.status === 200) {
      toast.error(res.data.message);
    }
  } catch (error) {}
};

export const modifyFingerPrintThreshold = (deviceId, threshold, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_DEVICE_OPERATIONS + '/' + deviceId + '/fingerprint_threshold?threshold=' + threshold);
    if (res.status === 200 && res.data.code === 'CASI0007') {
      toast.success(res.data.message);
      callBack();
    } else if (res.status === 200) {
      toast.error(res.data.message);
    }
  } catch (error) {}
};

export const modifyFingerPrintThresholdGet = (deviceId, callBack) => async (dispatch) => {
  
  try {
    const res = await axios.get(JAVA_API_DEVICE_OPERATIONS + '/' + deviceId + '/fingerprint_threshold');
    if (res.status === 200 && res.data.code === 'CASI0001') {
      callBack(res.data.data);
    }
  } catch (error) {}
};

export const setCommPwd = (deviceId, password, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_DEVICE_OPERATIONS + '/' + deviceId + '/communication_password?password=' + password);
    if (res.status === 200 && res.data.code === 'CASI0007') {
      toast.success(res.data.message);
      callBack();
    } else if (res.status === 200) {
      toast.error(res.data.message);
    }
  } catch (error) {}
};

export const modifyIpaddress = (deviceId, ipAddress, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_DEVICE_OPERATIONS + '/' + deviceId + '/ip_address?ipAddress=' + ipAddress);
    if (res.status === 200 && res.data.code === 'CASI0007') {
      toast.success(res.data.message);
      callBack();
    } else if (res.status === 200) {
      toast.error(res.data.message);
    }
  } catch (error) {}
};
