import React, { useState } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import deepOrange from '@material-ui/core/colors/deepOrange';
import blueGrey from '@material-ui/core/colors/blueGrey';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Color, ColorPicker, createColor } from 'material-ui-color';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setDoorIconColor } from '../../actions/DashBoardActions';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: deepOrange,
    secondary: blueGrey,
    background: { default: '#080808', paper: '#121212' },
  },
});

export default function CustomColorPicker(props) {
  const dispatch = useDispatch();
  const [color, setColor] = useState(createColor(props.color));
  const handleChange = (newValue) => {
    setColor(newValue);
  };

  const handleColorChange = () => {
    props.setColor(color.css.backgroundColor);
    dispatch(setDoorIconColor(props.data.doorId, color.css.backgroundColor, (data) => {}));
    props.handleClose();
  };

  return (
    <ThemeProvider>
      <CssBaseline />
      <Container maxWidth='sm'>
        <Box display='flex' flexDirection='row' bgcolor='background.paper'>
          <Box>
            <ColorPicker openAtStart={true} hideTextfield={true} value={color} onChange={(data) => handleChange(data)} />
          </Box>
          <Box bgcolor='grey.300'>
            <Button onClick={() => handleColorChange()}>Submit</Button>
          </Box>
          <Box bgcolor='grey.300'>
            <Button
              onClick={() => {
                props.setColor(props.color);
                props.handleClose();
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
