import { GET_REPORTS_TRANSACTIONS_FOR_DAY, SUCCESS, TRANSACTION_TODAY_LIST } from "../actions/types";

const initialState = {
  transactionsForDayList: [],
  toDayTransactions: [],
  isSuccess: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TRANSACTION_TODAY_LIST:
      return {
        ...state,
        toDayTransactions: action.payload,
      };
    case GET_REPORTS_TRANSACTIONS_FOR_DAY:
      return {
        ...state,
        transactionsForDayList: action.payload,
      };
    case SUCCESS:
      return {
        ...state,
        isSuccess: true,
      };

    default:
      return state;
  }
}
