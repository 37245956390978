import React, { useState, useEffect } from 'react';
import { deletePerson, getPersons } from '../../actions/organization/PersonApiCalls';
import { useDispatch } from 'react-redux';
import Headerbar from '../../components/HeaderBar';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmModal from '../../components/ConfirmModal';
import DataTable from '../../components/DataTable';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Pagination from '../../components/Pagination';
import CommonUtil from '../../components/Util/CommonUtils';
import { useSelector } from 'react-redux';
import { Box, Grid, IconButton } from '@material-ui/core';
import Toolbar from '../../components/Toolbar';
import { Tooltip, Fab } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import Breadcrumb from '../../components/Breadcrumb';
import { ReactComponent as Add } from '../../assets/icons/add.svg';
import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import { useLocation } from 'react-router';
import { getMemberList, deleteMembership, personWithUserRole } from '../../actions/organization/PersonRoleActions';
import AddMembership from '../Membership/AddMembership';
import FilterUserRole from './FilterUserRole';

export default function Membership() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [membership, setMembership] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});

  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const breadcrumb = [{ path: '', name: t('MEMBER001') }];

  const location = useLocation();
  //const applicationId = location.state.data;
  const [applicationId, setApplicationId] = useState('');

  const [state, setState] = React.useState({});

  useEffect(() => {
    if (!CommonUtil.isEmptyString(store.security)) {
      setApplicationId(store.security.user.client_id);
      loadData(page, rowsPerPage);
    }
  }, [store.security.user.client_id]);

  const loadData = (page, rowsPerPage) => {
    dispatch(getMemberList(store.security.user.client_id, page + 1, rowsPerPage));
  };

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.userRole.userRoleList)) {
      setTotalRecords(store.userRole.userRoleList.totalCount);
      setPage(store.userRole.userRoleList.currentPage - 1);
      setData(store.userRole.userRoleList.employee);
    } else {
      setTotalRecords(0);
      setPage(0);
      setData([]);
    }
  }, [store.userRole.userRoleList]);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.userRole.userFilterList)) {
      setTotalRecords(store.userRole.userFilterList.totalCount);
      setPage(store.userRole.userFilterList.currentPage - 1);
      setData(store.userRole.userFilterList.employee);
    } else {
      setTotalRecords(0);
      setPage(0);
      setData([]);
    }
  }, [store.userRole.userFilterList]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  function processData(data) {
    setMembership(data);
  }

  const openAddEmployeeDialog = () => {
    const obj = {};
    obj.applicationId = applicationId;
    obj.roleId = '';
    setUpdateObject(obj);
    setOpenEditDialog(true);
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];
    data.map((record, index) => {
      idsToDelete[index] = record.userId;
    });
    var str = idsToDelete.join(',');
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteMembership(applicationId, str, (data) => {
                  handlError(data);
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'UASW0001':
        toast.warning(data.message);
        loadData();
        break;
      default:
        toast.success(data.message);
        loadData();
        break;
    }
  };

  const columns = [
    {
      render: (client) => {
        const firstName = client.firstName === undefined ? '' : client.firstName;
        const lastName = client.lastName === undefined ? '' : client.lastName;
        const employeeCode = client.employeeCode === undefined ? '' : '(' + client.employeeCode + ')';
        return firstName + '\n' + lastName + employeeCode;
      },
      title: t('MEMBER002'),
    },
    {
      render: (client) => {
        const email = client.email === undefined ? '' : client.email;
        const phone = client.phone === undefined ? '' : client.phone;
        return email + '\n' + phone;
      },
      title: t('MEMBER003'),
      editable: 'never',
    },
    {
      field: 'roleName',
      title: t('MEMBER004'),
    },
  ];

  const options = { actionsColumnIndex: -1, search: false, selection: false, toolbar: false, paging: true, minBodyHeight: '40vh', initialPage: 1, pageSize: rowsPerPage };

  const actions = [
    {
      icon: () => <Edit color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        rowData.applicationId = applicationId;
        setUpdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <Delete color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        {/* <Tooltip title={t('COMMON009')}>
          <IconButton color='secondary' aria-label={t('COMMON009')} onClick={openAddEmployeeDialog}>
            <Add style={{ height: 35, width: 35 }} />
          </IconButton>
        </Tooltip> */}
      </Breadcrumb>
      <Toolbar>
        {/* <Grid> */}
        <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON009')} onClick={() => loadData(0, rowsPerPage)}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>
        {/* </Grid> */}
        {/* <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON004')}>
            <IconButton color='secondary' aria-label={t('COMMON009')} onClick={() => deleteRecord(selectedData)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box> */}
        <Box p={1}>
          <FilterUserRole />
        </Box>
      </Toolbar>

      <DataTable
        columns={columns}
        data={data}
        actions={[]}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <AddMembership
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadData(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
