import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import { Box, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import Headerbar from '../../components/HeaderBar';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { useState } from 'react';
import CommonUtil from '../../components/Util/CommonUtils';
import { REACT_URL_TIMEPERIOD, REACT_URL_TIMEPERIOD_ADD } from '../../actions/EndPoints';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addTimeseg, updateTimeseg } from '../../actions/TimesegApiCalls';
import SubmitButton from '../../components/SubmitButton';
import localeES from 'antd/es/date-picker/locale/es_ES';
import localeZH from 'antd/es/date-picker/locale/zh_CN';
import localeEN from 'antd/es/date-picker/locale/en_US';
import localeRU from 'antd/es/date-picker/locale/ru_RU';
import localeJA from 'antd/es/date-picker/locale/ja_JP';
import localeTH from 'antd/es/date-picker/locale/th_TH';
import localeID from 'antd/es/date-picker/locale/id_ID';
import CancelButton from '../../components/CancelButton';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import { toast } from 'react-toastify';

export default function CreateTimePeriod(props) {
  let language = BrowserLanguage.getDefaultLanguage();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const history = useHistory();
  const location = useLocation();
  const [copyMonday, setCopyMonday] = useState(false);

  const [monday1, setMonday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [monday2, setMonday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [monday3, setMonday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [tuesday1, setTuesday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [tuesday2, setTuesday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [tuesday3, setTuesday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [wednesday1, setWednesday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [wednesday2, setWednesday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [wednesday3, setWednesday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [thursday1, setThursday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [thursday2, setThursday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [thursday3, setThursday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [friday1, setFriday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [friday2, setFriday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [friday3, setFriday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [saturday1, setSaturday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [saturday2, setSaturday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [saturday3, setSaturday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [sunday1, setSunday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [sunday2, setSunday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [sunday3, setSunday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);

  const [error, setError] = useState({
    name: false,
    remark:false,
  });
  const localeMap = {
    en: localeEN,
    es: localeES,
    zh: localeZH,
    ru: localeRU,
    ja: localeJA,
    th: localeTH,
    idn: localeID,
  };
  const [payload, setPayload] = React.useState({
    id: '',
    companyId: '',
    name: '',
    remark: '',
    sundayStart1: '',
    sundayEnd1: '',
    sundayStart2: '',
    sundayEnd2: '',
    sundayStart3: '',
    sundayEnd3: '',
    mondayStart1: '',
    mondayEnd1: '',
    mondayStart2: '',
    mondayEnd2: '',
    mondayStart3: '',
    mondayEnd3: '',
    tuesdayStart1: '',
    tuesdayEnd1: '',
    tuesdayStart2: '',
    tuesdayEnd2: '',
    tuesdayStart3: '',
    tuesdayEnd3: '',
    wednesdayStart1: '',
    wednesdayEnd1: '',
    wednesdayStart2: '',
    wednesdayEnd2: '',
    wednesdayStart3: '',
    wednesdayEnd3: '',
    thursdayStart1: '',
    thursdayEnd1: '',
    thursdayStart2: '',
    thursdayEnd2: '',
    thursdayStart3: '',
    thursdayEnd3: '',
    fridayStart1: '',
    fridayEnd1: '',
    fridayStart2: '',
    fridayEnd2: '',
    fridayStart3: '',
    fridayEnd3: '',
    saturdayStart1: '',
    saturdayEnd1: '',
    saturdayStart2: '',
    saturdayEnd2: '',
    saturdayStart3: '',
    saturdayEnd3: '',
  });
  
  useEffect(() => {
    if (copyMonday) handleCopyMonday(monday1, monday2, monday3);
  }, [monday1, monday2, monday3]);

  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.timeSegError)) {
      const { timeSegError } = store.errors;
      switch (timeSegError.code) {
        default:
          case "CASE0039" :
             setError({ name: true });
          break;
          case "CASE0041" :
             setError({ remark: true });
          break;
      }
    }
    // eslint-disable-next-line
  }, [store.errors.timeSegError]);

  useEffect(() => {
    const timePeriod = location.state.data;
    if (location.state.mode === 'Edit') {
      setPayload({
        ...timePeriod,
      });
     
      setMonday1([moment(formatTime(timePeriod.mondayStart1), 'HH:mm'), moment(formatTime(timePeriod.mondayEnd1), 'HH:mm')]);
      setMonday2([moment(formatTime(timePeriod.mondayStart2), 'HH:mm'), moment(formatTime(timePeriod.mondayEnd2), 'HH:mm')]);
      setMonday3([moment(formatTime(timePeriod.mondayStart3), 'HH:mm'), moment(formatTime(timePeriod.mondayEnd3), 'HH:mm')]);

      setTuesday1([moment(formatTime(timePeriod.tuesdayStart1), 'HH:mm'), moment(formatTime(timePeriod.tuesdayEnd1), 'HH:mm')]);
      setTuesday2([moment(formatTime(timePeriod.tuesdayStart2), 'HH:mm'), moment(formatTime(timePeriod.tuesdayEnd2), 'HH:mm')]);
      setTuesday3([moment(formatTime(timePeriod.tuesdayStart3), 'HH:mm'), moment(formatTime(timePeriod.tuesdayEnd3), 'HH:mm')]);

      setWednesday1([moment(formatTime(timePeriod.wednesdayStart1), 'HH:mm'), moment(formatTime(timePeriod.wednesdayEnd1), 'HH:mm')]);
      setWednesday2([moment(formatTime(timePeriod.wednesdayStart2), 'HH:mm'), moment(formatTime(timePeriod.wednesdayEnd2), 'HH:mm')]);
      setWednesday3([moment(formatTime(timePeriod.wednesdayStart3), 'HH:mm'), moment(formatTime(timePeriod.wednesdayEnd3), 'HH:mm')]);

      setThursday1([moment(formatTime(timePeriod.thursdayStart1), 'HH:mm'), moment(formatTime(timePeriod.thursdayEnd1), 'HH:mm')]);
      setThursday2([moment(formatTime(timePeriod.thursdayStart2), 'HH:mm'), moment(formatTime(timePeriod.thursdayEnd2), 'HH:mm')]);
      setThursday3([moment(formatTime(timePeriod.thursdayStart3), 'HH:mm'), moment(formatTime(timePeriod.thursdayEnd3), 'HH:mm')]);

      setFriday1([moment(formatTime(timePeriod.fridayStart1), 'HH:mm'), moment(formatTime(timePeriod.fridayEnd1), 'HH:mm')]);
      setFriday2([moment(formatTime(timePeriod.fridayStart2), 'HH:mm'), moment(formatTime(timePeriod.fridayEnd2), 'HH:mm')]);
      setFriday3([moment(formatTime(timePeriod.fridayStart3), 'HH:mm'), moment(formatTime(timePeriod.fridayEnd3), 'HH:mm')]);

      setSaturday1([moment(formatTime(timePeriod.saturdayStart1), 'HH:mm'), moment(formatTime(timePeriod.saturdayEnd1), 'HH:mm')]);
      setSaturday2([moment(formatTime(timePeriod.saturdayStart2), 'HH:mm'), moment(formatTime(timePeriod.saturdayEnd2), 'HH:mm')]);
      setSaturday3([moment(formatTime(timePeriod.saturdayStart3), 'HH:mm'), moment(formatTime(timePeriod.saturdayEnd3), 'HH:mm')]);

      setSunday1([moment(formatTime(timePeriod.sundayStart1), 'HH:mm'), moment(formatTime(timePeriod.sundayEnd1), 'HH:mm')]);
      setSunday2([moment(formatTime(timePeriod.sundayStart2), 'HH:mm'), moment(formatTime(timePeriod.sundayEnd2), 'HH:mm')]);
      setSunday3([moment(formatTime(timePeriod.sundayStart3), 'HH:mm'), moment(formatTime(timePeriod.sundayEnd3), 'HH:mm')]);
    }
    // eslint-disable-next-line
  }, []);
 
  const breadcrumb = [
    //{ path: REACT_URL_TIMEPERIOD, name: t("COMMON017") },
    // { path: REACT_URL_TIMEPERIOD, name: t('TIMEP012')},
    {path:REACT_URL_TIMEPERIOD,name:t('TIMEP001')},
    { path: REACT_URL_TIMEPERIOD_ADD, name: location.state.mode === "Edit" ? t("COMMON005") : t("COMMON009") }
  ];

  const timeFormat = 'HH:mm';

  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    setCopyMonday(event.target.checked);
    var defaultTime = [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')];
    if (event.target.checked) handleCopyMonday(monday1, monday2, monday3);
    else handleCopyMonday(defaultTime, defaultTime, defaultTime);
  };

  const handleSubmit = () => {
    // if(CommonUtil.isEmptyString(payload.name)){
    //     setError({
    //         ...error,
    //         name:true
    //     })
    //     return;
    // }

    const state = {
      id: payload.id,
      name: payload.name,
      remark: payload.remark,
      companyId: payload.companyId,
      mondayStart1: monday1[0].format('HH:mm'),
      mondayStart2: monday2[0].format('HH:mm'),
      mondayStart3: monday3[0].format('HH:mm'),
      mondayEnd1: monday1[1].format('HH:mm'),
      mondayEnd2: monday2[1].format('HH:mm'),
      mondayEnd3: monday3[1].format('HH:mm'),

      tuesdayStart1: tuesday1[0].format('HH:mm'),
      tuesdayStart2: tuesday2[0].format('HH:mm'),
      tuesdayStart3: tuesday3[0].format('HH:mm'),
      tuesdayEnd1: tuesday1[1].format('HH:mm'),
      tuesdayEnd2: tuesday2[1].format('HH:mm'),
      tuesdayEnd3: tuesday3[1].format('HH:mm'),

      wednesdayStart1: wednesday1[0].format('HH:mm'),
      wednesdayStart2: wednesday2[0].format('HH:mm'),
      wednesdayStart3: wednesday3[0].format('HH:mm'),
      wednesdayEnd1: wednesday1[1].format('HH:mm'),
      wednesdayEnd2: wednesday2[1].format('HH:mm'),
      wednesdayEnd3: wednesday3[1].format('HH:mm'),

      thursdayStart1: thursday1[0].format('HH:mm'),
      thursdayStart2: thursday2[0].format('HH:mm'),
      thursdayStart3: thursday3[0].format('HH:mm'),
      thursdayEnd1: thursday1[1].format('HH:mm'),
      thursdayEnd2: thursday2[1].format('HH:mm'),
      thursdayEnd3: thursday3[1].format('HH:mm'),

      fridayStart1: friday1[0].format('HH:mm'),
      fridayStart2: friday2[0].format('HH:mm'),
      fridayStart3: friday3[0].format('HH:mm'),
      fridayEnd1: friday1[1].format('HH:mm'),
      fridayEnd2: friday2[1].format('HH:mm'),
      fridayEnd3: friday3[1].format('HH:mm'),

      saturdayStart1: saturday1[0].format('HH:mm'),
      saturdayStart2: saturday2[0].format('HH:mm'),
      saturdayStart3: saturday3[0].format('HH:mm'),
      saturdayEnd1: saturday1[1].format('HH:mm'),
      saturdayEnd2: saturday2[1].format('HH:mm'),
      saturdayEnd3: saturday3[1].format('HH:mm'),

      sundayStart1: sunday1[0].format('HH:mm'),
      sundayStart2: sunday2[0].format('HH:mm'),
      sundayStart3: sunday3[0].format('HH:mm'),
      sundayEnd1: sunday1[1].format('HH:mm'),
      sundayEnd2: sunday2[1].format('HH:mm'),
      sundayEnd3: sunday3[1].format('HH:mm'),
    };

    if (payload.name.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)) {
      setError({ ...error, name: true });
      toast.error(t('TIMEP0113'));
      return;
    }

    if (location.state.mode === 'Edit') {
      dispatch(updateTimeseg(state, history));
    } else {
      dispatch(addTimeseg(state, history));
    }
  };

  const handleClose = () => {
    history.push({
      pathname: REACT_URL_TIMEPERIOD,
    });
  };

  const handleCopyMonday = (monday1, monday2, monday3) => {
    setThursday1(monday1);
    setWednesday1(monday1);
    setTuesday1(monday1);
    setFriday1(monday1);
    setSunday1(monday1);
    setSaturday1(monday1);

    setSunday2(monday2);
    setTuesday2(monday2);
    setWednesday2(monday2);
    setThursday2(monday2);
    setFriday2(monday2);
    setSaturday2(monday2);

    setTuesday3(monday3);
    setWednesday3(monday3);
    setThursday3(monday3);
    setFriday3(monday3);
    setSaturday3(monday3);
    setSunday3(monday3);
  };

  const formatTime = (time) => {
    if (CommonUtil.isEmptyString(time)) return '00:00';
    else return time;
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb} />
      <div className='custom-container'>
        <Box display='flex' className='custom-container-header' alignItems='center'>
          {/* <Headerbar title={t("TIMEP001")} className="custom-container-header" /> */}
        </Box>
        <Grid container>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                fullWidth
                label={t('TIMEP013') + ' *'}
                helperText=''
                variant='outlined'
                name='name'
                size='small'
                value={payload.name}
                onChange={(event) => {
                  setPayload({ ...payload, name: event.target.value });
                  setError({ ...error, name: false });
                }}
                error={error.name}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                fullWidth
                label={t('COMMON016')}
                helperText=''
                variant='outlined'
                size='small'
                value={payload.remark}
                onChange={(event) => {
                setPayload({ ...payload, remark: event.target.value });
                setError({ ...error, remark: false });
              }}
                error={error.remark}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container style={{ paddingBottom: '8px' }}>
          <Grid item xs={12} sm={2}>
            <Box display='flex' justifyContent='center'>
              <span style={{ verticalAlign: 'center' }}>{t('TIMEP004')}</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={monday1}
                onChange={(time, timeString) => setMonday1(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={monday2}
                onChange={(time, timeString) => setMonday2(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={monday3}
                onChange={(time, timeString) => setMonday3(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container style={{ paddingBottom: '8px' }}>
          <Grid item xs={12} sm={2}>
            <Box display='flex' justifyContent='center'>
              <span style={{ verticalAlign: 'center' }}>{t('TIMEP005')}</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={tuesday1}
                onChange={(time, timeString) => setTuesday1(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={tuesday2}
                onChange={(time, timeString) => setTuesday2(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={tuesday3}
                onChange={(time, timeString) => setTuesday3(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container style={{ paddingBottom: '8px' }}>
          <Grid item xs={12} sm={2}>
            <Box display='flex' justifyContent='center'>
              <span style={{ verticalAlign: 'center' }}>{t('TIMEP006')}</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={wednesday1}
                onChange={(time, timeString) => setWednesday1(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={wednesday2}
                onChange={(time, timeString) => setWednesday2(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={wednesday3}
                onChange={(time, timeString) => setWednesday3(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container style={{ paddingBottom: '8px' }}>
          <Grid item xs={12} sm={2}>
            <Box display='flex' justifyContent='center'>
              <span style={{ verticalAlign: 'center' }}>{t('TIMEP007')}</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={thursday1}
                onChange={(time, timeString) => setThursday1(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={thursday2}
                onChange={(time, timeString) => setThursday2(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={thursday3}
                onChange={(time, timeString) => setThursday3(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container style={{ paddingBottom: '8px' }}>
          <Grid item xs={12} sm={2}>
            <Box display='flex' justifyContent='center'>
              <span style={{ verticalAlign: 'center' }}>{t('TIMEP008')}</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={friday1}
                onChange={(time, timeString) => setFriday1(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={friday2}
                onChange={(time, timeString) => setFriday2(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={friday3}
                onChange={(time, timeString) => setFriday3(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container style={{ paddingBottom: '8px' }}>
          <Grid item xs={12} sm={2}>
            <Box display='flex' justifyContent='center'>
              <span style={{ verticalAlign: 'center' }}>{t('TIMEP009')}</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={saturday1}
                onChange={(time, timeString) => setSaturday1(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={saturday2}
                onChange={(time, timeString) => setSaturday2(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={saturday3}
                onChange={(time, timeString) => setSaturday3(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container style={{ paddingBottom: '8px' }}>
          <Grid item xs={12} sm={2}>
            <Box display='flex' justifyContent='center'>
              <span style={{ verticalAlign: 'center' }}>{t('TIMEP010')}</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={sunday1}
                onChange={(time, timeString) => setSunday1(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={sunday2}
                onChange={(time, timeString) => setSunday2(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display='flex' justifyContent='center'>
              <TimePicker.RangePicker
                allowClear={false}
                locale={localeMap[language]}
                format={timeFormat}
                value={sunday3}
                onChange={(time, timeString) => setSunday3(time)}
                order={false}
                placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid display='flex' p={1}>
          <Box p={1} flexGrow={1}>
            <FormControlLabel control={<Checkbox checked={copyMonday} onChange={handleChange} name='copyMonday' />} label={t('TIMEP011')} />
          </Box>
          <Box display='flex' justifyContent='center'>
            <Box p={1}>
              <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
                {t('COMMON007')}
              </SubmitButton>
            </Box>
            <Box p={1}>
              <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
                {t('COMMON008')}
              </CancelButton>
            </Box>
          </Box>
        </Grid>
      </div>
    </Grid>
  );
}
