import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
// import { getLattitudeAndLantutude } from '../../actions/GoogleMapAction';
const mapStyles = {
  width: '95%',
  height: '472px',
  margin: '14px',
};
const containerStyle = {
  width: '600px',
  height: '400px',
};

let geocoder;
var center = { lat: 20.593684, lng: 78.8718 };
let language = BrowserLanguage.getDefaultLanguage();
class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: -1.2884,
      lng: 36.8233,
      showingInfoWindow: false, // Hides or shows the InfoWindow
      activeMarker: {}, // Shows the active marker upon click
      selectedPlace: {},
      locations: {
        lat: 0,
        lng: 0,
      },
      countryName: '',
    };
  }

  async componentDidMount() {
    const rusultData = await axios.get('/api/v2.0/company/' + localStorage.getItem('companyCode'));
    if (rusultData && rusultData.status === 200) {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${
          rusultData.data.data.country ? rusultData.data.data.country : 'India'
        }&key=${'AIzaSyAzPgExNy5JvZAyHer0H01gBEdARUCPh6w'}`
      );
      const data = await response.json();

      const result = data.results[0].geometry.location;

      if (result.lat !== null && result.lng !== null) {
      }
      this.setState({
        locations: {
          lat: result.lat,
          lng: result.lng,
        },
      });
    }
  }
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };
  onMarkerClick = (center) => {
    return center;
  };

  render() {
    const pos = this.onMarkerClick(this.state.locations);

    return (
      <Paper
        style={{
          width: '100%',
          height: '500px',
        }}
      >
        <Map google={this.props.google} zoom={2} style={mapStyles} initialCenter={center}>
          <Marker position={pos} onClick={this.onMarkerClick} />
        </Map>
      </Paper>
    );
  }
}
// const mapStateToProps = (state) => ({
//   position: state.googleMap.position,
// });

export default compose(
  withTranslation(),
  withStyles({ withTheme: true }),
  GoogleApiWrapper({
    apiKey: 'AIzaSyAzPgExNy5JvZAyHer0H01gBEdARUCPh6w',
    language: language,
  })
)(MapContainer);
