import { Box, Button } from '@material-ui/core';
import React, { Fragment, forwardRef } from 'react';
import { Grid } from "@material-ui/core";
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ViewColumn from '@material-ui/icons/ViewColumn';
import EditLinkageTabs from './EditLinkageTabs';

let EditLinkage=(props)=>{
    const tableIcons = {
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }}/>),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }}/>),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }}/>),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }}/>),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }}/>),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }}/>),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }}/>),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }}/>),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }}/>),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }}/>),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }}/>),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }}/>),
         
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }}/>),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }}/>),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }}/>)
      };
      
		const linkageData = [
			{ linkageName: 'linkage1', deviceName: 'device1', linkageTriggerConditions: 'Condition1' },
			{ linkageName: 'linkage2', deviceName: 'device2', linkageTriggerConditions: 'Condition2' },
			{ linkageName: 'linkage3', deviceName: 'device3', linkageTriggerConditions: 'Condition3' },
			{ linkageName: 'linkage4', deviceName: 'device4', linkageTriggerConditions: 'Condition4' },
			{ linkageName: 'linkage5', deviceName: 'device5', linkageTriggerConditions: 'Condition5' },
		];

return(
 <Fragment>

          <Grid item xs={12}>
						<NavLink to={props.page || "/acc/linkage"} style={{ textDecoration: 'none', color: 'unset' }} >
							<HomeIcon color="primary" fontSize="small" />
							<span style={{ paddingLeft: "2px", fontSize: "15px", color: "#858585" }}>&#8250;</span>
							<label className="timeLine" style={{ paddingLeft: "5px", color: "#858585" }}>
								{props.path || 'Access Control'}
							</label>
							<span style={{ paddingLeft: "10px", fontSize: "15px", color: "#858585" }}>&#8250;</span>
							<label className="timeLine" style={{ paddingLeft: "5px", color: "#858585" }}>
								{"commonAntiPassBackTitle"}
					   </label>
						</NavLink >
					</Grid>
				 

        <Box display="flex"   >
        <Box p={1} order={1}  >
        <TextField
          label="Size"
          id="outlined-size-small"
          defaultValue="Small"
          variant="outlined"
          size="small"
        />
        </Box>
        <Box   order={2}  >
          
        </Box>
        <Box p={1} order={3}  >
        <TextField
          label="Size"
          id="outlined-size-small"
          defaultValue="Small"
          variant="outlined"
          size="small"
        />
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs>
         LinkageTriggerActions
        </Grid>
        <Grid item xs={4}>
        <Button style={{backgroundColor:"#c0bfbf"}}
								color="secondary"
								size="small"
							 //onClick={this.addTrigger}
								className="acc-list-action-btn">
								{"ADD TRIGGER"}
							</Button>
        </Grid>
        <Grid item xs>
          InputPoint
        </Grid>
      </Grid>
      <Grid container spacing={3}>
      <Grid item xs={6}>
                <MaterialTable
                style={{ maxHeight:"220px",minHeight:"250px",overflowY: "scroll"}} 
                                                      icons={tableIcons}
                                                    columns={[
                                                        {   field: 'title' },
                                                        
                                                          
                                                    ]}
                                                data={linkageData}
                                                onPageChange={(event, rowData) => {
                                                    console.log(event.target, rowData);
                                                    console.log(
                                                      "Row Selection State Before: " + rowData.tableData.checked
                                                    );
                                                    rowData.tableData.checked = !rowData.tableData.checked;
                                                    console.log(
                                                      "Row Section State After: " + rowData.tableData.checked
                                                    );
                                                  }} 
                                                    options={{
                                                        minBodyHeight:'100%',
                                                        actionsColumnIndex: -1,
                                                        addRowPosition: 'first',
                                                        showTitle: false,
                                                        toolbarButtonAlignment: "left",
                                                        toolbar: false,
                                                        selection: true,
                                                        pageSize: 5,
                                                        paging: false, 
                                                    
                                                        selectionProps: rowData => ({
                                                          checked: true,
                                                        })
                                                      }}
                                                       
                                                    />
                </Grid>
                <Grid item xs={6} >
                <MaterialTable
                style={{ maxHeight:"220px",minHeight:"250px",overflowY: "scroll"}} 
                                                      icons={tableIcons}
                                                    columns={[
                                                        {   field: 'title' },
                                                        
                                                          
                                                    ]}
                                                data={linkageData}
                                                onPageChange={(event, rowData) => {
                                                    console.log(event.target, rowData);
                                                    console.log(
                                                      "Row Selection State Before: " + rowData.tableData.checked
                                                    );
                                                    rowData.tableData.checked = !rowData.tableData.checked;
                                                    console.log(
                                                      "Row Section State After: " + rowData.tableData.checked
                                                    );
                                                  }} 
                                                    options={{
                                                        minBodyHeight:'100%',
                                                        actionsColumnIndex: -1,
                                                        addRowPosition: 'first',
                                                        showTitle: false,
                                                        toolbarButtonAlignment: "left",
                                                        toolbar: false,
                                                        selection: true,
                                                        pageSize: 5,
                                                        paging: false, 
                                                    
                                                        selectionProps: rowData => ({
                                                          checked: true,
                                                        })
                                                      }}
                                                       
                                                    />
                </Grid>
                </Grid>
                <EditLinkageTabs></EditLinkageTabs>

 </Fragment>

)
}

export default EditLinkage;