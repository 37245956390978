import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import SidebarHeader from '../SidebarHeader';
import SidebarMenu from '../SidebarMenu';
import navItems from './navItems';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route } from 'react-router-dom';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Switch from '@material-ui/core/Switch';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import navItemZlink from './navItemZlink';

const Sidebar = (props) => {
  const { setSidebarToggleMobile, sidebarToggleMobile, sidebarFixed, sidebarShadow } = props;
  const history = useHistory();

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  const [expand, setExpand] = useState(true);

  const [icon, setIcon] = React.useState(false);

  const toggleIcon = (parent) => {
    if (!parent.items) {
      history.push({
        pathname: parent.link,
      });
    } else {
      setIcon(!icon);
    }
  };
  const handlenav = (child) => {
    history.push({
      pathname: child.link,
    });
  };
  // const expandOver = (child) => {
  //   setExpand(child)
  //   props.onchange(child)
  // }
  // const expandOut = (child) => {
  //   setExpand(child)
  //   props.onchange(child)
  // }
  const zlink=localStorage.getItem('redirectFrom');
  const handleToggle = () => {
    setExpand(true);
    props.onchange(true);
  };
  const onClickMenu = () => {
    if (expand == true) {
      setExpand(false);
      props.onchange(false);
    } else {
      setExpand(true);
      props.onchange(true);
    }
  };

  const sidebarMenuContent = (
    <div>
      <Router>
        <Route
          render={({ location, history }) => (
            <React.Fragment>
              <SideNav
                className='test-css'
                onSelect={(selected) => {
                  const to = selected;
                  if (location.pathname !== to) {
                    history.push(to);
                  }
                }}
                expanded={expand}
                // onMouseOver={() => expandOver(true)}
                // onMouseOut={() => expandOut(false)}
                onClick={() => handleToggle}
              >
                {/* <SideNav.Toggle/> */}
                <SideNav.Nav>
                {(zlink?navItemZlink:navItems).map((parent, index) => {
                    var eventKey = parent.items && parent.items.length > 0 ? parent.id : parent.link;
                    return (
                      <NavItem eventKey={eventKey} key={index}>
                        <NavIcon style={{ width: '85px', marginRight: '-20px', display: 'block', justifyContent: 'center' }}>
                          <parent.Icon />
                        </NavIcon>
                        {!expand && (
                          <NavText onClick={() => toggleIcon(parent)}>
                            <Box className='navTextTitle' component='span' display='flex' alignItems='center' style={{ color: '#3D4977', fontWeight: 400 }}>
                              {parent.name} {!parent.items ? null : <ArrowDropDownIcon />}
                            </Box>
                          </NavText>
                        )}
                        {expand && (
                          <Box display='flex' flexDirection='row' onClick={() => toggleIcon(parent)}>
                            <Box flexGrow={1} className='navTextTitle' component='span' display='flex' alignItems='center' style={{ color: '#3D4977', fontWeight: 400 }}>
                              {parent.name}
                            </Box>
                            <Box className='navTextTitle' component='span' display='flex' alignItems='center' style={{ color: '#3D4977', fontWeight: 400 }}>
                              {!parent.items ? null : <ArrowDropDownIcon />}
                            </Box>
                          </Box>
                        )}
                        {parent.items && parent.items.length > 0
                          ? parent.items.map((child, index) => {
                              return (
                                <NavItem key={index} eventKey={`${child.link}`} onClick={() => handlenav(child)}>
                                  <NavText>
                                    <Box
                                      className='navTextSubTitle'
                                      component='span'
                                      display='flex'
                                      alignItems='center'
                                      style={{ color: '#3D4977', display: 'flex', marginLeft: '50px', fontSize: '14px', padding: '5px' }}
                                    >
                                      {child.name}
                                    </Box>
                                  </NavText>
                                </NavItem>
                              );
                            })
                          : null}
                      </NavItem>
                    );
                  })}
                </SideNav.Nav>
                <Box
                  component='div'
                  style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', position: 'absolute', bottom: '0', marginLeft: '22px' }}
                >
                  {/* <SideNav.Toggle/> */}
                  {/* <div className={open ? 'app_container1' : 'app_container2'}>
                  sdfjsd
                </div>
                <button onClick={() => setToggle(!toggle)}>Toggle State</button> */}
                  <IconButton aria-label='delete' onClick={() => onClickMenu()}>
                    <MenuIcon />
                  </IconButton>
                </Box>
              </SideNav>
            </React.Fragment>
          )}
        />
      </Router>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer anchor='left' open={sidebarToggleMobile} onClose={closeDrawer} variant='temporary' elevation={4} className='app-sidebar-wrapper-lg'>
          <SidebarHeader />
          <PerfectScrollbar>{sidebarMenuContent}</PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-fixed': '',
          })}
          square
          elevation={sidebarShadow ? 11 : 3}
        >
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': '',
            })}
          >
            <PerfectScrollbar options={{ wheelPropagation: false }}>{sidebarMenuContent}</PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
