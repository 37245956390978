import React from 'react';
import { IconButton, Tooltip, Menu } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ViewGet } from '../../assets/icons/ViewGet.svg';
import GetPersonnelInformation from './GetPersonnelInformation';

export default function DeviceViewAndGetOperations(props) {
  const { t } = useTranslation();

  return (
    <>
      <PopupState variant='popover' popupId='demo-popup-menu'>
        {(popupState) => (
          <React.Fragment>
            <Tooltip title={t('DEVI0069')}>
              <IconButton color='secondary' aria-label={t('DEVI0069')} {...bindTrigger(popupState)}>
                <ViewGet style={{ height: 30, width: 30 }} />
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)}>
              <GetPersonnelInformation {...props} popupState={popupState} />
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </>
  );
}
