import React from "react";
import { Grid, Card } from "@material-ui/core";
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import TimePeriods from "./TimePeriods";

class TimeZone extends React.Component {

	constructor(props) {
		super(props)
		this.state = {

		}
	}

	render() {
		return (
			<div className="root">
				<Grid
					container
					direction="row"
					justify="flex-start"
					alignItems="flex-start">
					<Grid item xs={12}>
						<NavLink to={this.props.page || "/dev/apps/"} style={{ textDecoration: 'none', color: 'unset' }} >
							<HomeIcon color="primary" fontSize="small" />
							<span style={{ paddingLeft: "10px", fontSize: "15px", color: "#858585" }}>&#8250;</span>
							<label className="timeLine" style={{ paddingLeft: "5px", color: "#858585" }}>
								{this.props.path || 'Access Control'}
							</label>
							<span style={{ paddingLeft: "10px", fontSize: "15px", color: "#858585" }}>&#8250;</span>
							<label className="timeLine" style={{ paddingLeft: "5px", color: "#858585" }}>
								Time Period
					   </label>
						</NavLink >
					</Grid>
				</Grid>
				<div>
					<Card className="card-box mb-4-spacing overflow-visible">
						<div className="card-header--title">
						<TimePeriods/>
						</div>
					</Card>
				</div>

			</div>
		);
	}
}

export default TimeZone;