import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_ACCDEVICES, GET_DEVICE_STATUS_LIST, DEVICE_SYNC_TIME, SET_DEVICE_TIME_ZONE, DEVICE_SYNC_All_DATA } from './types';
import {
  JAVA_URL_DEVICE,
  JAVA_URL_DEVICE_EXPORT_EXCEL,
  JAVA_URL_DEVICE_STATUS,
  JAVA_URL_DEVICE_OPERATION,
  JAVA_URL_DEVICE_IS_SYNC_TIME,
  JAVA_URL_DEVICE_IS_SYNC_DATA,
  JAVA_URL_DEVICE_DSTIME,
  JAVA_URL_DEVICE_ENABLE,
  JAVA_URL_DEVICE_DESABLE,
  JAVA_URL_DEVICE_TRANSACTION,
  JAVA_URL_DEVICE_SETAS_REGISTARTION,
  JAVA_URL_DEVICE_OPTION,
  JAVA_URL_DEVICE_VIEW_RULES,
  JAVA_URL_DEVICE_SET_DEVICE_TIMEZONE,
  JAVA_URL_DEVICE_SYNC_ALL_DATA_DEV,
  JAVA_URL_DEVICE_SETUP,
  JAVA_URL_DEVICE_STATUS_V2,
} from './EndPoints';

export const controlDeviceOperations = (deviceId, operation) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_STATUS.replace('{ids}', deviceId));
  var obj = res.data.data;
  var status = obj[Object.keys(obj)[0]];
  var connectState = status.connectState;
  if (connectState === '1') {
    const res = await axios.get(JAVA_URL_DEVICE_OPERATION.replace('{accDeviceId}', deviceId).replace('{deviceOperation}', operation));
    //console.log(res);
    toast.success('Device operation successful');
  } else if (connectState === '0') {
    toast.error('Device is Offline Please Try again');
  } else {
    toast.error('Device is Offline Please Try again');
  }
};
export const controlDeviceOperationsSyncTime = (deviceId, operation) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_OPERATION.replace('{accDeviceId}', deviceId).replace('{deviceOperation}', operation));
  //console.log(res);
  toast.success('Device operation successful');
};

export const handleDeviceTimeZoneStatus = (ids, type, checkOffline) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_IS_SYNC_TIME.replace('{ids}', ids) + '?type=' + type + '&checkOffline=' + checkOffline);
  //console.log(res);
  dispatch({
    type: DEVICE_SYNC_TIME,
    payload: res.data.data,
  });
};
export const handleSyncallDataToDevValidation = (ids, type) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_IS_SYNC_DATA.replace('{ids}', ids) + '?type=' + type);
  //console.log(res);
  dispatch({
    type: DEVICE_SYNC_All_DATA,
    payload: res.data.data,
  });
};
export const modifyIpAddress = (deviceId, operation, ipAddress) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_STATUS.replace('{ids}', deviceId));
  var obj = res.data.data;
  var status = obj[Object.keys(obj)[0]];
  var connectState = status.connectState;
  if (connectState === '1') {
    const res = await axios.get(JAVA_URL_DEVICE_SETUP.replace('{deviceId}', deviceId).replace('{operation}', operation) + '?ipAddress=' + ipAddress);
    //console.log(res);
    toast.success('Device operation successful');
  } else if (connectState === '0') {
    toast.error('Device is Offline Please Try again');
  } else {
    toast.error('Device is Offline Please Try again');
  }
};

export const modifyPwd = (deviceId, operation, commPwd) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_STATUS.replace('{ids}', deviceId));
  var obj = res.data.data;
  var status = obj[Object.keys(obj)[0]];
  var connectState = status.connectState;
  if (connectState === '1') {
    const res = await axios.get(JAVA_URL_DEVICE_SETUP.replace('{deviceId}', deviceId).replace('{operation}', operation) + '?commPwd=' + commPwd);
    //console.log(res);
    toast.success('Device operation successful');
  } else if (connectState === '0') {
    toast.error('Device is Offline Please Try again');
  } else {
    toast.error('Device is Offline Please Try again');
  }
};

export const setTimeZone = (deviceId, operation, timeZone) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_STATUS.replace('{ids}', deviceId));
  var obj = res.data.data;
  var status = obj[Object.keys(obj)[0]];
  var connectState = status.connectState;
  if (connectState === '1') {
    const res = await axios.get(JAVA_URL_DEVICE_SETUP.replace('{deviceId}', deviceId).replace('{operation}', operation) + '?timeZone=' + timeZone);
    //console.log(res);
    toast.success('Device operation successful');
  } else if (connectState === '0') {
    toast.error('Device is Offline Please Try again');
  } else {
    toast.error('Device is Offline Please Try again');
  }
};

export const setDeviceIOStatus = (deviceId, operation, devIOState) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_STATUS.replace('{ids}', deviceId));
  var obj = res.data.data;
  var status = obj[Object.keys(obj)[0]];
  var connectState = status.connectState;
  if (connectState === '1') {
    const res = await axios.get(JAVA_URL_DEVICE_SETUP.replace('{deviceId}', deviceId).replace('{operation}', operation) + '?devIOState=' + devIOState);
    //console.log(res);
    toast.success('Device operation successful');
  } else if (connectState === '0') {
    toast.error('Device is Offline Please Try again');
  } else {
    toast.error('Device is Offline Please Try again');
  }
};
export const setFingerPrintThreshold = (deviceId, operation, mThreshold) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_STATUS.replace('{ids}', deviceId));
  var obj = res.data.data;
  var status = obj[Object.keys(obj)[0]];
  var connectState = status.connectState;
  if (connectState === '1') {
    const res = await axios.get(JAVA_URL_DEVICE_SETUP.replace('{deviceId}', deviceId).replace('{operation}', operation) + '?mThreshold=' + mThreshold);
    //console.log(res);
    toast.success('Device operation successful');
  } else if (connectState === '0') {
    toast.error('Device is Offline Please Try again');
  } else {
    toast.error('Device is Offline Please Try again');
  }
};
export const setDaylightSavingTime = (accDeviceId) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_STATUS.replace('{ids}', accDeviceId));
  var obj = res.data.data;
  var status = obj[Object.keys(obj)[0]];
  var connectState = status.connectState;
  if (connectState === '1') {
    const res = await axios.get(JAVA_URL_DEVICE_DSTIME + accDeviceId);
    //console.log(res);
    toast.success('Device operation successful');
  } else if (connectState === '0') {
    toast.error('Device is Offline Please Try again');
  } else {
    toast.error('Device is Offline Please Try again');
  }
};
export const setDeviceTimeZoneOperation = (accDeviceId, operation, timeZone) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_STATUS.replace('{ids}', accDeviceId));
  var obj = res.data.data;
  var status = obj[Object.keys(obj)[0]];
  var connectState = status.connectState;
  if (connectState === '1') {
    const res = await axios.get(JAVA_URL_DEVICE_SETUP.replace('{deviceId}', accDeviceId).replace('{operation}', operation) + '?timeZone=' + timeZone);
    //console.log(res);
    toast.success('Device operation successful');
  } else if (connectState === '0') {
    toast.error('Device is Offline Please Try again');
  } else {
    toast.error('Device is Offline Please Try again');
  }
};
export const enableDevice = (ids) => async (dispatch, getState) => {
  const res = await axios.post(JAVA_URL_DEVICE_ENABLE.replace('{ids}', ids));
  //console.log(res);
  toast.success('Device operation successful');
  try {
    let alias = '';
    let sn = '';
    let deviceName = '';
    let ipAddress = '';
    var query = {
      page: '',
      pageSize: '',
    };
    const res = await axios.get(
      JAVA_URL_DEVICE + '?alias=' + alias + '&sn=' + sn + '&deviceName=' + deviceName + '&ipAddress=' + ipAddress + '&page=' + query.page + '&size=' + query.pageSize
    );
    dispatch({
      type: GET_ACCDEVICES,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error('Something went wrong');
  }
};
export const desableDevice = (ids) => async (dispatch) => {
  const res = await axios.post(JAVA_URL_DEVICE_DESABLE.replace('{ids}', ids));
  //console.log(res);
  toast.success('Device operation successful');
  try {
    let alias = '';
    let sn = '';
    let deviceName = '';
    let ipAddress = '';
    var query = {
      page: '',
      pageSize: '',
    };
    const res = await axios.get(
      JAVA_URL_DEVICE + '?alias=' + alias + '&sn=' + sn + '&deviceName=' + deviceName + '&ipAddress=' + ipAddress + '&page=' + query.page + '&size=' + query.pageSize
    );
    dispatch({
      type: GET_ACCDEVICES,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error('Something went wrong');
  }
};
export const handleSetAsRegistartionDeviceOperations = (accDeviceId) => async (dispatch) => {
  const res = await axios.post(JAVA_URL_DEVICE_SETAS_REGISTARTION.replace('{ids}', accDeviceId));
  try {
    if (res.data.code !== '') {
      toast.success('Device operation successful');
    } else {
      toast.error(res.data.data);
    }
  } catch (err) {
    toast.error('Something went wrong');
  }
};
export const handleDeviceOption = (accDeviceId) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_OPTION.replace('{deviceId}', accDeviceId));
  //console.log(res);
  toast.success('Device operation successful');
};
export const handleDeviceTransaction = (accDeviceId, flag) => async (dispatch) => {
  if (flag) {
    const res = await axios.post(JAVA_URL_DEVICE_TRANSACTION + accDeviceId + '?isGetNewLog=' + true);
    //console.log(res);
    toast.success('Device operation successful');
  } else {
    const res = await axios.post(JAVA_URL_DEVICE_TRANSACTION + accDeviceId + '?isGetNewLog=' + false);
    //console.log(res);
    toast.success('Device operation successful');
  }
};
export const setDeviceRulesOperation = (accDeviceId) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_VIEW_RULES.replace('{id}', accDeviceId));
  try {
    if (res.status === 200) {
      toast.success('Device operation successful');
    }
  } catch (err) {
    toast.error('Something went wrong');
  }
};
export const handleSetDeviceTimeZone = (ids) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_SET_DEVICE_TIMEZONE.replace('{ids}', ids));
  dispatch({
    type: SET_DEVICE_TIME_ZONE,
    payload: res.data.data,
  });
};
export const handleSynchAllDataToDevice = (devType, devId) => async (dispatch) => {
  const res = await axios.get(JAVA_URL_DEVICE_SYNC_ALL_DATA_DEV + '?devType=' + devType + '&devId=' + devId);
  try {
    if (res.status === 200) {
      toast.success('Device operation successful');
    }
  } catch (err) {
    toast.error('Something went wrong');
  }
};

export const handleDeviceExport = () => async (dispatch) => {
  axios({
    url: JAVA_URL_DEVICE_EXPORT_EXCEL, //your url
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'DeviceData.xlsx'); //or any other extension
    link.click();
  });
};
export const handleDeviceStatus = (deviceId) => async (dispatch) => {
  const res =  axios.get(JAVA_URL_DEVICE_STATUS.replace('{ids}', deviceId));
  if (res.status === 200) {
    dispatch({
      type: GET_DEVICE_STATUS_LIST,
      payload: res.data.data,
    });
  }
};


