import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch } from 'react-redux';
import { getDoorPathDetailsOnSite } from '../../actions/DashBoardActions';
import PathWayItem from './PathWayItem';
import { Paper, Typography } from '@mui/material';
import { ReactComponent as Device_Icon } from '../../assets/images/dashboard-icons/device_icon.svg';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { Box, Button } from '@material-ui/core';
import PathWay from './PathWay';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#36C96D !important',
    color: '#fff',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #36C96D',
    padding: '10px',
  },
}))(Tooltip);

const RightDivider = () => {
  return <Grid container alignItems='center' style={{ borderRight: '1px solid #ccc' }} width='100%' minHeight='30px' marginRight='7%'></Grid>;
};

const LeftDivider = () => {
  return <Grid container item alignItems='center' style={{ borderLeft: '1px solid #ccc' }} width='100%' minHeight='30px' marginLeft='2%'></Grid>;
};

const Entrance = () => {
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(true);
  return (
    <Grid>
      <HtmlTooltip arrow disableHoverListener={false} open={tooltipIsOpen} onOpen={() => setTooltipIsOpen(true)} onClose={() => setTooltipIsOpen(true)} title='Entrance'>
        <span></span>
      </HtmlTooltip>
    </Grid>
  );
};

export default function PathWayDialog(props) {
  const dispatch = useDispatch();
  const [fullScreen, toggleFullScreen] =  React.useState(false);
  const [doors, setDoors] = useState([]);
  useEffect(() => {
        dispatch(
            getDoorPathDetailsOnSite(props.siteId, (data) => {
              setDoors(data.data);
            })
          ); 
  }, []);

  var doorGroup = [];
  var pathDirection = true;
  return (
    
    <React.Fragment>
        <Box display='flex' flexDirection='row-reverse' p={1} m={1}>
          <Box p={1} bgcolor='grey.300'>
            <Button variant='contained' color='default' size='medium' startIcon={<FullscreenExitIcon />} onClick={() => props.toggleFullScreen(false)}></Button>
          </Box>
        </Box>
      {doors.length === 0 && (
        <Paper elevation={1} style={{ width: '100%', minHeight: '450px', overflow: 'auto', backgroundColor: 'white' }}>
          <Grid container direction='row' justifyContent='center' alignItems='center' height={'100%'}>
            <Grid item>
              <Device_Icon />
            </Grid>
            <Grid item>
              <Typography> No Device on this Site</Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      {doors.map((door, index) => {
        if ((index + 1) % props.doorsPerPath !== 0) {
          // create a group of 12(props.doorsPerPath) doors
          doorGroup.push(door);
        } else {
          // create a path way for every door
          doorGroup.push(door);
          var tempList = doorGroup;
          var tempDirection = pathDirection;
          pathDirection = !pathDirection;
          doorGroup = [];
          return (
            <>
              <Grid container direction={tempDirection ? 'row' : 'row-reverse'} justifyContent={'center'}>
                {tempList.map((item, i) => {
                  if (tempDirection) {
                    return <PathWayItem data={item} path={tempList.length === i + 1 ? false : true} end={doors.length === index + 1 ? true : false} />;
                  } else {
                    return <PathWayItem data={item} path={i == 0 ? false : true} end={doors.length === index + 1 ? true : false} />;
                  }
                })}
              </Grid>
              {doors.length !== index + 1 && (tempDirection ? <RightDivider /> : <LeftDivider />)}
              {/* {doors.length === index + 1 && <Entrance />} */}
            </>
          );
        }

        if (doors.length === index + 1 && doorGroup.length > 0) {
          // if the door count is below 12
          var tempList = doorGroup;
          var tempDirection = pathDirection;
          pathDirection = !pathDirection;
          doorGroup = [];
          return (
            <>
              <Grid container direction={tempDirection ? 'row' : 'row-reverse'}>
                {tempList.map((item, i) => {
                  if (tempDirection) {
                    return <PathWayItem data={item} path={tempList.length === i + 1 ? false : true} />;
                  } else {
                    return <PathWayItem data={item} path={i == 0 ? false : true} />;
                  }
                })}
                
              </Grid>
              {doors.length !== index + 1 && (tempDirection ? <RightDivider /> : <LeftDivider />)}
              {/* {doors.length === index + 1 && (
                <HtmlTooltip arrow disableHoverListener={false} open={true} title='Entrance'>
                  <span></span>
                </HtmlTooltip>
              )} */}
             
            </>
          );
        }
      })}
    </React.Fragment>
  );
}
