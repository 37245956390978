import axios from 'axios';
import { toast } from 'react-toastify';
import { SET_COMPANY } from './types';

export const getUser = (email, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/api/v2.0/user/' + email);
    if (res.status === 200 && res.data.code === 'UASI0000') {
      dispatch({
        type: 'SET_USER',
        payload: res.data.data,
      });
      onSuccess(res.data.data);
    } else {
      dispatch({
        type: 'SET_USER',
        payload: {},
      });
      onFailure(res.data);
    }
  } catch {}
};

export const updateUser = (payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.put('/api/v2.0/user/' + payload.email, payload);
    if (res.status === 200 && res.data.code === 'UASI0002') {
      toast.success(res.data.message);
      dispatch({
        type: 'SET_USER',
        payload: payload,
      });
      onSuccess(res.data);
    } else {
      toast.error(res.data.message);
      onFailure(res.data);
    }
  } catch {}
};

export const getCompanyList = (onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/api/v2.0/company?pageNumber=0&pageSize=100');
    if (res.status === 200 && res.data.code === 'UASI0000') {
      onSuccess(res.data.data);
    } else {
      onFailure(res.data);
    }
  } catch {}
};

export const getCompany = (onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/api/v2.0/company');
    if (res.status === 200 && res.data.code == 'UASI0000') {
      dispatch({
        type: SET_COMPANY,
        payload: res.data.data,
      });
      onSuccess(res.data.data);
    } else {
      dispatch({
        type: SET_COMPANY,
        payload: {},
      });
      onFailure(res.data);
    }
  } catch {}
};

export const getCompanyProfile = (onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/api/v2.0/company/' + localStorage.getItem('companyCode'));
    if (res.status === 200 && res.data.code == 'UASI0000') {
      dispatch({
        type: SET_COMPANY,
        payload: res.data.data,
      });
      onSuccess(res.data.data);
    } else {
      dispatch({
        type: SET_COMPANY,
        payload: {},
      });
      onFailure(res.data);
    }
  } catch {}
};

export const updateCompanyProfile = (payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.put('/api/v2.0/company/' + payload.id, payload);
    if (res.status === 200 && res.data.code === 'UASI0007') {
      onSuccess(res.data);
      dispatch({
        type: SET_COMPANY,
        payload: payload,
      });
    } else {
      toast.error(res.data.message);
      onFailure(res.data);
    }
  } catch {}
};

export const sendResetPwdLink = (emailId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.get('/api/v2.0/forgot_password?emailId=' + emailId);
    if (res.status === 200 && res.data.code === 'CASI0001') {
      onSuccess(res.data.data);
    } else {
      toast.error(res.data.message);
      onFailure(res.data);
    }
  } catch {}
};

export const resetPassword = (code, payload, onSuccess, onFailure) => async (dispatch) => {
  try {
    const res = await axios.put('/api/v2.0/reset_password/' + code, payload);
    if (res.status === 200 && res.data.code === 'CASI0601') {
      onSuccess(res.data.data);
    } else {
      toast.error(res.data.message);
      onFailure(res.data);
    }
  } catch {}
};
