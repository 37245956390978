import { Button, Grid } from '@material-ui/core';
import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { verifyCompany } from "./OauthClientActions"
import CardMedia from '@material-ui/core/CardMedia';
import projectLogo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';

class Logout extends React.Component {

	render(){
		return(
			<Fragment>
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
					>
					<Grid container item className="box-company-validate" display="flex" bgcolor="background.paper">
						<Grid container item xs={12} sm={12} md={6}
							className="box-company-logo"
							direction="row"
							justify="center"
							alignItems="center">
								<CardMedia style={{textAlign:"center"}}>
									<img
										style={{height:"70%",width:"70%"}}
										src={projectLogo}
										alt="projectlogo"
									/>
								</CardMedia>
						</Grid>
						<Grid container item xs={12} sm={12} md={6} className="box-company-form">
							<Grid container item xs={12} direction="row" justify="center" alignItems="center">
								<h4 style={{color:"#3D4977"}}>Successfully logged out!</h4>
							</Grid>
							<Grid container item xs={12} direction="row" justify="center" alignItems="center">
								<Button 
									component={Link}
									to={"/"}
									className="button-common-primary" 
									variant="contained" 
									color="primary" >
									Login
								</Button>
							</Grid>
						</Grid>
					</Grid>

				</Grid>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	security: state.security
});

export default connect(
	mapStateToProps,
	{ verifyCompany }
)(Logout);