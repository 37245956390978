import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import setJWTToken from './setJWTToken';
import jwt_decode from 'jwt-decode';
import { REFRESH_TOKEN_TIMER, SET_ACCESS_TOKEN, SET_CURRENT_USER, SET_REFRESH_TOKEN } from '../actions/types';
import { REFRESH_TOKEN_INTERVAL } from '../constants/RestConfig';

export default function RefreshToken() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  useEffect(() => {
    console.log('Start Init Refreshed token @' + store.security.tokenRefreshTimer);

    if (store.security.tokenRefreshTimer > 60000) {
      console.log('Initiated Refreshed token @' + store.security.tokenRefreshTimer);
      console.log('Initiated Refreshed token @' + new Date());
      setInterval(() => {
        refreshToken();
      }, store.security.tokenRefreshTimer);
    }
  }, [store.security.tokenRefreshTimer]);

  const refreshToken = async () => {
    var decoded_jwtToken = jwt_decode(localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN));
    var payload = {
      refreshToken: localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN).replace('Bearer ', ''),
      companyCode: decoded_jwtToken.companyCode,
    };
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
      },
      body: JSON.stringify(payload),
    };
    try {
      const fetchResponse = await fetch(`/api/v2.0/refresh_token`, settings);
      const res = await fetchResponse.json();
      if (res.code === 'CAAI0117') {
        console.log('Refreshed token @' + new Date());
        setJWTToken(res.data.access_token);
        localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, res.data.access_token);
        localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, res.data.refresh_token);
        const decoded = jwt_decode(res.data.access_token);
        localStorage.setItem('companyCode', decoded.companyCode);
        console.log('Refreshed token');
        var currentSeconds = Math.floor(Date.now() / 1000);
        var secondsLeft = decoded.exp - currentSeconds;
        var timer = Math.floor(secondsLeft / 60) - 5;
        timer = timer * 60 * 1000;
        console.log('Set new refresh token timer', timer);

        dispatch({
          type: REFRESH_TOKEN_TIMER,
          payload: 0,
        });
        dispatch({
          type: SET_CURRENT_USER,
          payload: decoded,
        });
        dispatch({
          type: SET_REFRESH_TOKEN,
          payload: res.data.refresh_token,
        });
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: res.data.access_token,
        });
        dispatch({
          type: REFRESH_TOKEN_TIMER,
          payload: timer,
        });
      }
    } catch (e) {
      return e;
    }
  };
  return <></>;
}
