import { Box, Grid, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getDesignations, deleteDesignation } from '../../actions/organization/DesignationApiCalls';
import AddDesignation from './AddDesignation';
import ConfirmModal from '../../components/ConfirmModal';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import FilterDesignation from './FilterDesignation';
import Pagination from '../../components/Pagination';
import CommonUtil from '../../components/Util/CommonUtils';
import { Tooltip, Fab } from '@material-ui/core';
import Breadcrumb from '../../components/Breadcrumb';
// import { ReactComponent as Add } from '../../assets/icons/add.svg';
// import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
// import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
// import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export default function Designation() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [state, setState] = React.useState({
    id: '',
    name: '',
    code: '',
    parentDesignation: '',
    description: '',
  });

  useEffect(() => {
    loadDesignations(page, rowsPerPage);
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.designation.designationList)) {
      setTotalRecords(store.designation.designationList.totalCount);
      setPage(store.designation.designationList.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.designation.designationList.designation);
  }, [store.designation.designationList]);

  const loadDesignations = (page, rowsPerPage) => {
    dispatch(getDesignations(page + 1, rowsPerPage, true));
    onSelectionChange([]);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(getDesignations(newPage + 1, rowsPerPage, true));
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    dispatch(getDesignations(1, pageSize, true));
  };

  const openAddDesignationDialog = () => {
    setUpdateObject(state);
    setOpenEditDialog(true);
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];
    data.map((record, index) => {
      idsToDelete[index] = record.id;
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteDesignation(idsToDelete, (data) => {
                  handlError(data);
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handlError = (data) => {
    if(data.code=='OMSI0005'){
          toast.success(data.message);
          loadDesignations(page, rowsPerPage);
    }else{
          toast.error(t('COMMON211'));
          loadDesignations(page, rowsPerPage);
    }
  };


  const findDesignationById=(parentId)=>{
    var parentName;
      data.map((desn)=>{
        if(desn.id===parentId){
           parentName = desn.name; 
        }
      });
      return parentName;
  }



  const breadcrumb = [{ path: '', name: t('DESI001') }];

  const columns = [
    {
      field: 'name',
      title: t('COMMON044'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'code', title: t('COMMON045'), editable: 'never' },
    {
      
      render: (client) => {
        return CommonUtil.isEmptyString(client.parentId) ? '' : findDesignationById(client.parentId);
       },

      title: t('COMMON050'),
      editable: 'never',
    },
    { field: 'description',
     title: t('DESI009'),
     editable: 'never',
     cellStyle: {
      minWidth: 200,
      maxWidth: 200,
    },
     },
  ];

  const options = { actionsColumnIndex: -1, search: false, selection: false, toolbar: false, paging: true, minBodyHeight: '40vh', initialPage: 1, pageSize: rowsPerPage,rowStyle: {
    overflowWrap: 'break-word'}};

  const actions = [
    {
      icon: () => <EditOutlinedIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <DeleteOutlineIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Tooltip title={t('COMMON009')}>
           <Fab color='secondary' size='small' aria-label={t('COMMON009')} onClick={openAddDesignationDialog}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Breadcrumb>

      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => loadDesignations(0, rowsPerPage)}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {/* <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON004')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => deleteRecord(selectedData)}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box> */}
        <Box p={1}>
          <FilterDesignation props={state} rowsPerPage={rowsPerPage} />
        </Box>
      </Toolbar>

      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <AddDesignation
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadDesignations(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
