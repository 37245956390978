// OAuth-client
export const REACT_LOGIN = '/acc/login';
export const REACT_VERIFY_COMPANY = '/acc/verify-company';
export const REACT_LOGOUT = '/acc/login';

// Dashboard
export const REACT_URL_DASHBOARD = '/acc/dashboard';

// Device Management - React Router
export const REACT_URL_DEVICE_LIST = '/acc/device';
export const REACT_URL_READER_UPDATE = '/acc/reader';
export const REACT_URL_AUXIN_UPDATE = '/acc/auxIn';
export const REACT_URL_AUXOUT_UPDATE = '/acc/v1/auxOut/';
export const REACT_URL_DEVICE_ADD = '/acc/device-search/';
export const REACT_URL_DOOR = '/acc/door';
export const REACT_URL_ADD_DOOR = '/acc/add-door';
export const REACT_URL_READER_LIST = '/acc/reader';
export const REACT_URL_AUXIN_LIST = '/acc/auxIns/';
export const REACT_URL_AUXOUT_LIST = '/acc/auxOuts/';

// Access Control - React Router
export const REACT_URL_TIME_PERIOD = '/acc/time-zone';
export const REACT_URL_ADD_TIME_PERIOD = '/acc/add-time-period';
export const REACT_URL_UPDATE_TIME_PERIOD = '/acc/edit-time-period';
export const REACT_URL_ACCESS_LEVEL = '/acc/levels';

export const REACT_URL_ACCESS_LEVEL_DETAILS = '/acc/level-details';
export const REACT_URL_INTER_LOCK = '/acc/interlock';
export const REACT_URL_LINKAGE = '/acc/linkage';
export const REACT_URL_ANTIPASSBOOK = '/acc/anti-passback';

//dept Details
export const JAVA_URL_DEPT_DETAILS = '/api/v1.0/deptDetails';
//Acc level
export const JAVA_URL_ACc_DEPT_LIST = '/api/v1.0/organization/department';

export const JAVA_URL_ACCESS_LEVEL_DOOR_LIST = '/api/v1.0/accLevel/';

//Personal details

// Access Control - Rest API

export const JAVA_URL_ACCESS_EMPLOYEE_LIST = '/api/v1.0/organization/personnel';
export const JAVA_URL_ACCESS_EMPLOYEE_LIST_LEVELID = '/api/v1.0/organization/personnel';
export const JAVA_URL_ACCESS_DEPARTMENT_LIST = '/api/v1.0/organization/department';
export const JAVA_URL_ACCESS_DEPARTMENT_LIST_BY_DEPARTMENTID = '/api/v1.0/level/departmentlistbydeptId/';
export const JAVA_URL_INTER_LOCK_LIST = '/api/v1.0/acc/interlocks';

export const JAVA_URL_ZONE_LIST = '/api/v1.0/location/zone';
export const JAVA_URL_SITE_LIST = '/api/v2.0/site';

export const JAVA_URL_NORULES_BY_DEVID = '/api/v1.0/accInterlockNoRule/';
export const JAVA_URL_UPDATE_DEVICE_OPERATION = '/api/v1.0/ipConfig';
export const JAVA_URL_GET_DEVICE_OPTIONS = '/api/v1.0/adms/device';
export const JAVA_URL_UPDATE_MUL_DEVICE_OPTIONS = '/api/v1.0/acc/device/operations';

// Reports - React Router
export const REACT_URL_OPERATION_LOG = '/acc/operation-log';
export const REACT_URL_REPORTS_FILTER = '/acc/report-filters';

//------------organization-------------------//
export const PERSONNEL_MANAGEMENT_SERVICE_URL = 'https://organizationdev.minervaiot.com';
export const REACT_URL_ADD_EMPLOYEE = '/pers/AddEmployee';
export const REACT_URL_PERS_STATISTICS = '/pers/statistics';
export const REACT_URL_PERS_EMPLOYEELIST = '/acc/Employee';
export const REACT_URL_PERS_DEPARTMENTLIST = '/acc/Department';
export const REACT_URL_UPDATEAUTHENTICATION = '/acc/UpdateAuthentication';
export const REACT_URL_PERS_DESIGNATIONLIST = '/acc/Designation';
export const REACT_URL_PERS_TemporaryEmployee = '/acc/TemporaryEmployee';
export const REACT_URL_PERS_ResignedEmployee = '/acc/ResignedEmployee';
export const REACT_URL_SITE_AddressType = '/acc/AddressTypeList';
export const REACT_URL_SITE_SiteType = '/acc/SiteTypeList';
export const REACT_URL_SITE_Site = '/acc/Site';
export const REACT_URL_SITE_Zone = '/acc/Zone';

//------------Need to check-------------//
export const JAVA_URL_DEVICE_SYNC_ALL_DATA_DEV = '/api/v1.0/device/sync';
export const JAVA_URL_DEVICE_EXPORT_EXCEL = '/api/v1.0/device/export/excel';
export const JAVA_URL_PERSON_PRIMARY_DETAILS = '/api/v1.0/organization/person';

//-----------------updated-urls----------------//
//time segment
export const JAVA_URL_TIME_PERIOD = '/api/v1.0/timeseg';

//door
export const JAVA_URL_DOOR = '/api/v1.0/door';
export const JAVA_URL_ENABLE_DOOR = '/api/v1.0/door/enable';
export const JAVA_URL_DISABLE_DOOR = '/api/v1.0/door/disable';
export const JAVA_URL_DOOR_STATUS = '/api/v2.0/door/{doorIds}/operation';
export const JAVA_URL_DOOR_STATUSS = '/api/v2.0/door/{doorIds}/operation';
export const JAVA_URL_DOOR_OPERATIONS = '/api/v1.0/door/{ids}/operation';
export const JAVA_URL_DOOR_OPERATIONSS = '/api/v2.0/door/{ids}/operation';

//reader
export const JAVA_URL_READER = '/api/v1.0/reader';

//aux-in
export const JAVA_URL_AUXIN = '/api/v1.0/auxiliary-input';

//aux-out
export const JAVA_URL_AUXOUT = '/api/v1.0/auxiliary-output';
export const JAVA_URL_AUXOUT_OPERATIONS = '/api/v1.0/auxiliary-out/{ids}/operation';

//device
export const JAVA_URL_TIME_ZONE_LIST = '/api/v1.0/timezone';
export const JAVA_URL_DEVICE = '/api/v1.0/device';
export const JAVA_URL_DEVICE_STATUS = '/api/v1.0/device/{ids}/status';
export const JAVA_URL_SEARCH_DEVICE = '/api/v1.0/device/adms';

export const JAVA_URL_DEVICE_ROLE = '/api/v1.0/device-role';

//Device Operations
export const JAVA_URL_DEVICE_CAPACITY = '/api/v1.0/device/{devId}/usage';
export const JAVA_URL_DEVICE_RULES = '/api/v1.0/device/{id}/rules';
export const JAVA_URL_DEVICE_OPERATION = '/api/v1.0/device/{accDeviceId}/operation/{deviceOperation}';
export const JAVA_URL_DEVICE_SETUP = '/api/v1.0/device/{deviceId}/setup/{operation}';
export const JAVA_URL_DEVICE_IS_SYNC_TIME = '/api/v1.0/device/{ids}/support-synctime';
export const JAVA_URL_DEVICE_IS_SYNC_DATA = '/api/v1.0/device/{ids}/support-syncdata';
export const JAVA_URL_DEVICE_DSTIME = '/api/v1.0/acc/dsTime/';
export const JAVA_URL_DEVICE_ENABLE = '/api/v1.0/device/{ids}/enable';
export const JAVA_URL_DEVICE_DESABLE = '/api/v1.0/device/{ids}/disable';
export const JAVA_URL_DEVICE_TRANSACTION = '/api/v1.0/transaction/';
export const JAVA_URL_DEVICE_SETAS_REGISTARTION = '/api/v1.0/device/{ids}/registration';
export const JAVA_URL_DEVICE_OPTION = '/api/v1.0/device/{deviceId}/options';
export const JAVA_URL_DEVICE_VIEW_RULES = '/api/v1.0/device/{id}/rules';
export const JAVA_URL_DEVICE_SET_DEVICE_TIMEZONE = '/api/v1.0/device/{ids}/support-settimezone';
export const JAVA_URL_DEVICE_COUNT = '/api/v2.0/dashboard/device_count';
export const JAVA_URL_DASHBOARD_COUNT = '/api/v2.0/dashboard_count';

//Access level
export const JAVA_URL_ACCESS_LEVEL = '/api/v1.0/access-level/';
export const JAVA_URL_ACCESS_LEVEL_DOOR = '/api/v1.0/access-level/{levelId}/door';
export const JAVA_URL_ACCESS_LEVEL_PERSON = '/api/v1.0/access-level/{levelId}/person';
export const JAVA_URL_ACCESS_LEVEL_ADD_PERSON = '/api/v1.0/access-level/person';
export const JAVA_URL_ACCESS_LEVEL_DEPARTMENT = '/api/v1.0/access-level/{levelId}/department/{ids}';
export const JAVA_URL_ACCESS_LEVEL_DEPARTMENT_PAGE = '/api/v1.0/access-level/department';
export const JAVA_URL_ACCESS_LEVEL_PERSON_OPT='/api/v2.0/access_level/setdev_vrfy_pwd_update_role';
export const JAVA_URL_ACCESS_LEVEL_PERSON_OPT_GET='/api/v2.0/access_level/getdev_vrfy_pwd_update_role';
//InterLock Details
export const JAVA_URL_INTERLOCK = '/api/v1.0/interlock';

//location information
export const JAVA_URL_SITES_LIST = '/api/v1.0/location/site';

//dashboard/site
export const JAVA_URL_DASHBOARD_EMP_COUNT = '/api/v2.0/dashboard/employee_count';

// Transactions
export const JAVA_URL_TRANSACTIONS_LATEST_EVENTS = '/api/v2.0/dashboard/notification/evnt_data';
export const JAVA_URL_TRANSACTIONS = '/api/v1.0/transaction';
export const JAVA_URL_ALL_TRANSACTION_EXPORT_EXCEL = '/api/v1.0/transaction/export/excel';
export const JAVA_URL_EXCEPTION_TRANSACTION_EXPORT_EXCEL = '/api/v1.0/transaction/export/exception/excel';
export const JAVA_URL_CURRENT_DAY_TRANSACTION_EXPORT_EXCEL = '/api/v1.0/transaction/export/today/excel';
export const JAVA_URL_TRANSACTIONS_TODAY = '/api/v1.0/transaction/today';
export const JAVA_URL_REPORTS_LAST_KNOWN_POSITION_LIST = '/api/v1.0/lastknowntransactions';
export const JAVA_URL_TRANSACTIONS_EXCEPTION = '/api/v1.0/transaction/exceptions';

//Anti passback
export const JAVA_URL_ANTIPASSBACK = '/api/v1.0/anti-passback';
export const JAVA_URL_ANTIPASSBACK_RULES = '/api/v1.0/anti-passback/{deviceId}/rules';

// Linkage
export const JAVA_URL_LINKAGE = '/api/v1.0/linkage';
export const JAVA_URL_LINKAGE_TRIGGER = '/api/v1.0/linkage/trigger-option';
export const JAVA_URL_LINKAGE_INOUT = '/api/v1.0/linkage/in-out-info';

//V2
//AUXIN
export const REACT_URL_AUXIN = '/acc/auxin';

//AUXOUT
export const REACT_URL_AUXOUT = '/acc/auxout';

//Time period
export const REACT_URL_TIMEPERIOD = '/acc/time-period';
export const REACT_URL_TIMEPERIOD_ADD = '/acc/time-period-add';

//Level
export const REACT_URL_LEVEL = '/acc/levels';
export const REACT_URL_LEVEL_DETAILS = '/acc/level-details';

//V2
//INTERLOCK
export const REACT_URL_INTERLOCK = '/acc/interlock';

//V2
//ANTIPASSBACK
export const REACT_URL_ANTIPASSBACK = '/acc/antipassback';

//Site Management
export const REACT_URL_ADDRESS_TYPE = '/acc/Addresstype';
export const REACT_URL_ADD_ADDRESS_TYPE = '/acc/AddAddresstype';
export const REACT_URL_ADDRESS_TYPE_DETAILS = '/acc/addresstype-details';
export const REACT_URL_SITE_TYPE = '/acc/Sitetype';
export const REACT_URL_ADD_SITE_TYPE = '/acc/AddSitetype';
export const REACT_URL_SITE_TYPE_DETAILS = '/acc/sitetype-details';
export const REACT_URL_SITE = '/acc/Site';
export const REACT_URL_ADD_SITE = '/acc/AddSite';
export const REACT_URL_SITE_DETAILS = '/acc/site-details';
export const REACT_URL_ZONE = '/acc/Zone';
export const REACT_URL_ADD_ZONE = '/acc/AddZone';
export const REACT_URL_ZONE_DETAILS = '/acc/zone-details';

export const REACT_URL_SITE_DASHBOARD = '/acc/site-dashboard';

export const REACT_URL_DEPARTMENT = '/acc/Department';
export const REACT_URL_DEPARTMENT_DETAILS = '/acc/department-details';
export const REACT_URL_DESIGNATION = '/acc/Designation';
export const REACT_URL_DESIGNATION_DETAILS = '/acc/designation-details';
export const REACT_URL_EMPLOYEE = '/acc/Employee';
export const REACT_URL_EMPLOYEE_DETAILS = '/acc/employee-details';
export const REACT_URL_RESIGNED_EMPLOYEE = '/acc/ResignedEmployee';
export const REACT_URL_RESIGNED_EMPLOYEE_DETAILS = '/acc/resignedemployee-details';

//Reports
export const REACT_URL_TRANSACTIONS = '/acc/Transactions';
export const REACT_URL_TRANSACTIONSFORDAY = '/acc/Transactions-for-day';
export const REACT_URL_EXCEPTIONTRANSACTIONS = '/acc/Exception-transactions';
export const REACT_URL_PERSON_UPDATE = '/acc/update-person';

//
export const REACT_SITE_DEVICE_COUNT = '/api/v2.0/dashboard/count/device?siteId=';
export const REACT_SITE_DOOR_COUNT = '/api/v2.0/dashboard/count/door?siteId=';
export const REACT_SITE_EMPLOYEE_COUNT = '/api/v2.0/dashboard/count/employee?siteId=';
export const REACT_SITE_EVENT_COUNT = '/api/v2.0/dashboard/count/event?siteId=';
export const REACT_SITE_DOOR_EVENT = '/api/v2.0/dashboard/door/events?siteId=';

export const JAVA_URL_DEVICE_STATUS_V2 = '/api/v2.0/device/{sn}/status';
