import { GET_SITEDEVICE_COUNT, GET_SITEDOOR_COUNT, GET_SITE_EMPLOYEE_COUNT, GET_EVENT_COUNT, GET_SITE_DOOR_EVENT } from '../../actions/types';

const initialState = {
  sitedeviceCount: 0,
  sitedoorCount: 0,
  siteemployeeCount: 0,
  siteeventCount: 0,
  siteDoorEvent: 0,
  siteEventsTableData: { event: [], currentPage: 1, totalCount: 0, totalPages: 0 },
  siteEventsData: { event: [], currentPage: 1, totalCount: 0, totalPages: 0 },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SITEDEVICE_COUNT:
      return {
        ...state,
        sitedeviceCount: action.payload,
      };
    case GET_SITEDOOR_COUNT:
      return {
        ...state,
        sitedoorCount: action.payload,
      };
    case GET_SITE_EMPLOYEE_COUNT:
      return {
        ...state,
        siteemployeeCount: action.payload,
      };
    case GET_EVENT_COUNT:
      return {
        ...state,
        siteeventCount: action.payload,
      };
    case GET_SITE_DOOR_EVENT:
      return {
        ...state,
        siteDoorEvent: action.payload,
      };

    case 'SITE_EVENTS_TABLE_DATA':
      return {
        ...state,
        siteEventsTableData: action.payload,
      };

    case 'SITE_EVENTS_DATA':
      return {
        ...state,
        siteEventsData: action.payload,
      };
    default:
      return state;
  }
}
