import React from 'react';
import { useEffect } from 'react';
import CommonUtil from '../../components/Util/CommonUtils';
import jwt_decode from 'jwt-decode';
import setJWTToken from '../../oauth-client/setJWTToken';
import { REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import { useHistory } from 'react-router-dom';
import { REFRESH_TOKEN_TIMER, SET_ACCESS_TOKEN, SET_CURRENT_USER, SET_REFRESH_TOKEN } from '../../actions/types';
import { useDispatch } from 'react-redux';

export default function Zlink() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    var token = CommonUtil.getCookie('zlink_token');
    if (!CommonUtil.isEmptyString(token)) {
      setJWTToken(token);
      const decoded = jwt_decode(token);
      localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, token);
      localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, CommonUtil.getCookie('zlink_refresh_token'));
      localStorage.setItem('companyId', decoded.companyId);
      localStorage.setItem('companyName', decoded.companyName);
      localStorage.setItem('companyCode', decoded.companyCode);
      localStorage.setItem('redirectFrom', 'zlink');
      var currentSeconds = Math.floor(Date.now() / 1000);
      var secondsLeft = decoded.exp - currentSeconds;
      var timer = Math.floor(secondsLeft / 60) - 5;
      timer = timer * 60 * 1000;
      console.log('Set refresh token timer', timer);
      console.log('Time left to call refresh token', secondsLeft / 60);
      //alert((new Date(decoded.expiryAt) - new Date()) / 60000);
      //console.log('Verified token :' + JSON.stringify(decoded));
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });
      dispatch({
        type: SET_REFRESH_TOKEN,
        payload: CommonUtil.getCookie('zlink_refresh_token'),
      });
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: token,
      });
      dispatch({
        type: REFRESH_TOKEN_TIMER,
        payload: timer,
      });
      history.push({
        pathname: REACT_URL_DASHBOARD,
      });
    }
  }, []);
  return <></>;
}
