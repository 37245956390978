import { Box, Grid, Dialog, DialogTitle, Button, Tooltip, IconButton } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { performDeviceOperations } from '../../actions/DeviceApiCalls';
import { JAVA_URL_DOOR_STATUSS } from '../../actions/EndPoints';
import Axios from 'axios';

import { handleRemoteOpen, handleDoorOperations } from '../../actions/DoorApiCalls';

export default function CancelAlarmDialog(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [doorsStatus, setDoorsStatus] = React.useState(props.popupState);
  // eslint-disable-next-line
  const [selecteddoors, setSelecteddoor] = React.useState();
  const [selectedDevices, setSelectedDevices] = React.useState();
  // eslint-disable-next-line
  const [state, setState] = React.useState({
    password: '',
    confirmPassword: '',
  });
  // eslint-disable-next-line
  const [error, setError] = React.useState({
    password: false,
    confirmPassword: false,
  });

  // const handleChange = (event) => {
  //     setState({
  //         ...state,
  //         [event.target.name]: event.target.value
  //     })
  //     setError({
  //         ...error,
  //         [event.target.name]: false
  //     })
  // };

  const doorOperations = () => {
    if (props.doors.length < 1) {
      toast.warning(t('DEVI0014'));
      handleClose();
      return;
    }

    setOpen(true);

    var ids = '';
    var devices = '';
    const { doors } = props;
    // eslint-disable-next-line
    doors.map((door, index) => {
      if (index === doors.length - 1) {
        ids = ids + door.id;
        devices = devices + door.deviceId;
      } else {
        devices = devices + door.deviceId + ',';
        ids = ids + door.id + ',';
      }
    });

    setSelecteddoor(ids);
    setSelectedDevices(devices);

    try {
      Axios.get(JAVA_URL_DOOR_STATUSS.replace('{doorIds}', ids) + '?type=' + props.operation).then((res) => {
        setDoorsStatus(res.data.data);
      });
    } catch (error) {
      toast.error(t('COMMON028'));
      setOpen(false);
    }

    // confirmAlert({
    //     customUI: ({ onClose }) => {
    //         return (
    //             <ConfirmModal open={true} text={confirmMessage} onConfirm={() => { dispatch(performdoorOperations(ids, operation)); onClose() }} onClose={() => onClose()} />
    //         );
    //     }
    // });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    // dispatch(performDeviceOperations(selectedDevices, props.operation)).then(res => {
    // setOpen(false)
    //})

    dispatch(handleRemoteOpen(null, props.operation, selecteddoors)).then((res) => {
      setOpen(false);
    });
  };

  return (
    <>
      {/* <MenuItem onClick={() => doorOperations()} >{t("DEVI0049")}</MenuItem> */}

      {/* <Button
                onClick={() => doorOperations()}
                color="secondary"
                size="small"
                startIcon={props.icon}
                className="acc-list-action-btn">
                {props.title}
            </Button> */}

      <Tooltip title={props.title}>
        <IconButton color='secondary' aria-label={props.title} onClick={() => doorOperations()}>
          {props.icon}
        </IconButton>
      </Tooltip>

      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-filter-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{props.title}</Box>
            <CancelIcon onClick={handleClose} style={{ color: 'red', background: 'white', borderRadius: '50%' }} fontSize='small' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container justify='center' alignItems='center'>
            <Grid item xs={12}>
              <Box display='flex'>
                <p>
                  <b className='acc-head'>{t('COMMON166')}</b>
                </p>
              </Box>
              {doorsStatus && doorsStatus.doorsName && (
                <>
                  <Box display='flex' flexDirection='row'>
                    <Box bgcolor='grey.300'>
                      <Box display='flex'>
                        <p>
                          <b className='acc-head' style={{ color: 'green' }}>
                            {doorsStatus.doorsName}
                          </b>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              {doorsStatus && doorsStatus.disabledDoorsName && (
                <>
                  <Box display='flex' flexDirection='row'>
                    <Box bgcolor='grey.300'>
                      <Box display='flex'>
                        <p>
                          <b className='acc-head' style={{ color: 'red' }}>
                            {t('DOORI019')}
                          </b>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                  <Box display='flex' flexDirection='row'>
                    <Box bgcolor='grey.300'>
                      <Box display='flex'>
                        <p>
                          <b style={{ color: 'red' }}>{doorsStatus.disabledDoorsName}</b>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              {doorsStatus && doorsStatus.offlineDoorsName && (
                <>
                  <Box display='flex' flexDirection='row'>
                    <Box bgcolor='grey.300'>
                      <Box display='flex'>
                        <p>
                          <b className='acc-head' style={{ color: 'red' }}>
                            {t('DOORI018')}
                          </b>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                  <Box display='flex' flexDirection='row'>
                    <Box bgcolor='grey.300'>
                      <Box display='flex'>
                        <p>
                          <b style={{ color: 'red' }}>{doorsStatus.offlineDoorsName}</b>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
          {/* <form noValidate autoComplete="off">
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            style={{border:"1px solid #00000026",marginTop:"8px"}}
                        >
                            <Grid item xs={12} >
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        fullWidth
                                        error={error.password}
                                        name="password"
                                        label={t("DOORI017") + " *"}
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        type="password"
                                        value={state.password}
                                    />
                                </Box>

                            </Grid>


                        </Grid>
                    </form> */}
        </DialogContent>
        <DialogActions>
          {doorsStatus && doorsStatus.doorsName && (
            <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
              {props.title}
            </SubmitButton>
          )}
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
