import { Box, MenuItem, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import CommonUtil from '../../components/Util/CommonUtils';
import { FormControl, InputLabel, Select } from '@mui/material';

export default function EventGraph() {
  const { t } = useTranslation();
  const [series, setSeries] = useState([
    {
      name: t('COMMON087'),
      data: [],
    },
    {
      name: t('COMMON085'),
      data: [],
    },
    {
      name: t('alaram'),
      data: [],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    legend: {
      horizontalAlign: 'right',
      position: 'top',
    },
    xaxis: {
      type: 'time',
      categories: [],
    },
    tooltip: {
      x: {
        formatter: function(val) {
          return t('time') + ' : ' + val;
        },
      },
    },
    colors: ['#36C96D', '#F1AC24', '#F66669'],
  });

  const [timeRange, setTimeRange] = useState(2);

  useEffect(() => {
    var normal = [];
    var alram = [];
    var warning = [];
    var categories = [];
    axios.get('/api/v2.0/dashboard/events?hours=' + timeRange).then((res) => {
      if (res.data.code === 'CASI0001') {
        res.data.data.map((event) => {
          if (!CommonUtil.isEmpty(event)) {
            categories.push(event.time);
            normal.push(event.normal);
            alram.push(event.alram);
            warning.push(event.warning);
          }
        });
        setSeries([
          {
            name: t('COMMON087'),
            data: normal,
          },
          {
            name: t('COMMON085'),
            data: warning,
          },
          {
            name: t('alarm'),
            data: alram,
          },
        ]);
        setOptions({
          ...options,
          xaxis: {
            type: 'time',
            categories: categories,
          },
        });
      }
    });
  }, [timeRange]);

  return (
    <div id='chart'>
      <Box display='flex' flexDirection='row'>
        <Box flexGrow={1}>
          <Typography gutterBottom variant='h4' component='h2' color='secondary' style={{ margin: '18px' }}>
            {t('COMMON182')}
          </Typography>
        </Box>
        <Box p={1} width={'300px'}>
          <FormControl variant='outlined' size='small' fullWidth>
            <InputLabel id='demo-simple-select-outlined-label'>{t('COMMON207')}</InputLabel>
            <Select
              value={timeRange}
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              label='Time range'
              onChange={(e) => setTimeRange(e.target.value)}
            >
              <MenuItem value=''>
                <em>{t('none')}</em>
              </MenuItem>
              <MenuItem value={2}>{t('COMMON194')}</MenuItem>
              <MenuItem value={4}>{t('COMMON195')}</MenuItem>
              <MenuItem value={8}>{t('COMMON196')}</MenuItem>
              <MenuItem value={12}>{t('COMMON197')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <ReactApexChart options={options} series={series} type='area' height={350} />
    </div>
  );
}
