import axios from "axios";
import { toast } from 'react-toastify';
import { GET_ERRORS, GET_ACCAUXOUT_LIST, UPDATE_ACCAUXOUT,GET_AUXOUT_STATUS } from "./types";
import { JAVA_URL_AUXOUT,JAVA_URL_AUXOUT_OPERATIONS } from "./EndPoints";

export const getAccAuxOuts = (name,deviceAlias,query) => async dispatch => {
	const res = await axios.get(JAVA_URL_AUXOUT+'?name=' + name +'&deviceAlias=' + deviceAlias +'&page=' + query.page + '&size=' + query.pageSize);
	dispatch({
		type: GET_ACCAUXOUT_LIST,
		payload: res.data.data
	});
};

export const updateAccAuxOut = (accAuxOut) => async dispatch => {
	try {
		const res = await axios.put(JAVA_URL_AUXOUT, accAuxOut);
		toast.success("AuxOut updated successfully");
		dispatch({
			type: UPDATE_ACCAUXOUT,
			payload: res.data.data
		});

	} catch (err) {
		dispatch({
			type: GET_ERRORS,
			payload: err.response.data.data
		});
	}
};
export const remoteOpenControl = (ids,operationType,openInterval) => async dispatch => {
	
	const res = await axios.post(JAVA_URL_AUXOUT_OPERATIONS.replace("{ids}", ids) + "?operationType=" + operationType + "&openInterval=" + openInterval);
	toast.success("Remote updated successfully");
	try{
	  if(res.status === 200){
		  toast.success("Remote Open operation successful")
	  }
} catch (err) {
  toast.error("Something went wrong")
}
};

export const remoteNormallyOpenControl = (ids,operationType) => async dispatch => {
	const res = await axios.post(JAVA_URL_AUXOUT_OPERATIONS.replace("{ids}", ids) + "?operationType=" + operationType);
	toast.success("Remote updated successfully");
	try{
	  if(res.status === 200){
		  toast.success("Remote Open operation successful")
	  }
} catch (err) {
  toast.error("Something went wrong")
}
};
//openAuxOut
export const getAuxOutStatus = (ids,type) => async dispatch => {
	const res = await axios.get(JAVA_URL_AUXOUT+"/"+ ids + "?type=" + type);
	dispatch({
		type: GET_AUXOUT_STATUS,
		payload: res.data.data
	});
};