import { Box, Dialog, DialogActions, DialogTitle, Grid, IconButton, TextField, Tooltip, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Typography } from 'antd';
import CancelIcon from '@material-ui/icons/Cancel';
import { DialogContent } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRoles, getMemberList, personWithUserRole } from '../../actions/organization/PersonRoleActions';
import CommonUtil from '../../components/Util/CommonUtils';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
export default function FilterUserRole(props) {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    phone: '',
    roleId: '',
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [roleList, setRoleList] = React.useState([]);
  const dispatch = useDispatch();
  const [applicationId, setApplicationId] = useState('');

  useEffect(() => {
    if (!CommonUtil.isEmptyString(store.security)) {
      setApplicationId(store.security.user.client_id);
    }
  }, [store.security.user.client_id]);

  useEffect(() => {
    dispatch(
      getAllRoles(store.security.user.client_id, (data) => {
        setRoleList(data);
      })
    );
  }, []);

  const handleClose = () => {
    setState({
      firstName: '',
      lastName: '',
      phone: '',
      roleId: '',
    });
    setOpen(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const reset = () => {
    if (state.firstName.trim() === '' && state.lastName.trim() === '' && state.phone.trim() === '' && state.roleId.trim() === '') {
      toast.error(t('COMMON027'));
      return;
    }
    setState({
      firstName: '',
      lastName: '',
      phone: '',
      roleId: '',
    });
  };

  const handleSubmit = () => {
    if (state.firstName.trim() === '' && state.lastName.trim() === '' && state.phone.trim() === '' && state.roleId.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
    dispatch(personWithUserRole(state.firstName, state.lastName, state.phone, state.roleId, applicationId, 1, 10));
    setState({
      firstName: '',
      lastName: '',
      phone: '',
      roleId: '',
    });
    handleClose();
  };

  return (
    <div>
      <Tooltip title={t('COMMON006')}>
        <IconButton color='secondary' aria-label='Reset filter' onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Dialog maxWidth='sm' open={open} onClose={handleClose} className='filter-dialog'>
        <DialogTitle>
          <Box display='flex'>
            <Box flexGrow={1}>
              <Typography variant='h4' color='secondary'>
                {t('COMMON006')}
              </Typography>
            </Box>
            <CancelIcon onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    name='firstName'
                    label={t('USER0001')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.firstName}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    name='lastName'
                    label={t('USER0002')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.lastName}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    name='phone'
                    label={t('PERSON006')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.phone}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} hidden='true'>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('MEMBER004')}</InputLabel>
                    <Select label={t('MEMBER004')} name='roleId' onChange={handleChange} value={state.roleId} defaultValue={state.roleId}>
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {roleList ? (
                        roleList.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className='mr-4' style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleSubmit} color='secondary' variant='contained' className='m-2' size='small'>
            {t('COMMON024')}
          </Button>
          <Button onClick={reset} color='secondary' variant='contained' className='m-2' size='small'>
            {t('COMMON025')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
