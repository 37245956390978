import { Box, Grid, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getAddressTypes, deleteAddressTypes } from '../../actions/organization/AddressTypeApiCalls';
import AddAddressType from './AddAddressType';
import ConfirmModal from '../../components/ConfirmModal';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import FilterAddressType from './FilterAddressType';
import CommonUtil from '../../components/Util/CommonUtils';
import Pagination from '../../components/Pagination';
import { Tooltip } from '@material-ui/core';
import Breadcrumb from '../../components/Breadcrumb';
import { ReactComponent as Add } from '../../assets/icons/add.svg';
import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
// import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import CachedIcon from '@material-ui/icons/Cached';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { REACT_URI_ADDRESS_TYPE } from '../../actions/organization/EndPoints';

export default function AddressType() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const history = useHistory();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [state, setState] = React.useState({
    id: '',
    name: '',
    code: '',
    description: '',
  });

  useEffect(() => {
    loadAddressTypes(page, rowsPerPage);
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.addressType.addressTypeList)) {
      setTotalRecords(store.addressType.addressTypeList.totalCount);
      setPage(store.addressType.addressTypeList.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.addressType.addressTypeList.addressTypes );
  }, [store.addressType.addressTypeList]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(getAddressTypes(newPage + 1, rowsPerPage, true));
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    dispatch(getAddressTypes(1, pageSize, true));
  };

  const loadAddressTypes = (page, rowsPerPage) => {
    dispatch(getAddressTypes(page + 1, rowsPerPage, true));
    onSelectionChange([]);
  };

  const openAddAddressTypeDialog = () => {
    setUpdateObject(state);
    setOpenEditDialog(true);
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];
    data.map((record, index) => {
      idsToDelete[index] = record.id;
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteAddressTypes(idsToDelete, (data) => {
                  handleError(data);
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'LMSI6001':
        toast.success(data.message);
        loadAddressTypes(page, rowsPerPage);
        break;
      case 'LMSI6003':
        toast.success(data.message);
        loadAddressTypes(page, rowsPerPage);
        break;
      case 'LMSW0001':
        if (data.data.successCount !== 0) {
          toast.success(data.data.successCount + ' ' + t('COMMON048'));
          loadAddressTypes(page, rowsPerPage);
        }
        data = data.data.error;
        data.map(function(name, index) {
          if (data[index].code === 'LMSE0039') {
            toast.error(data[index].data.name + data[index].message);
          } else {
            toast.error(data[index].message + ' ' + data[index].data.name);
          }
        });
        break;
      default:
        toast.error(data.message);
        break;
    }
  };

  const breadcrumb = [{ path: '', name: t('ATYPE001') }];

  const columns = [
    {
      field: 'name',
      title: t('COMMON044'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'code', title: t('COMMON045'), editable: 'never' },
    { field: 'description', title: t('ATYPE009'), editable: 'never' },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: store.security.user.companyCode != 'zkteco' ? false : true,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage,
  };

  const actions = [
    {
      icon: () => <EditOutlined color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <DeleteOutline color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Tooltip title={t('COMMON009')} >
          <IconButton color='secondary' aria-label={t('COMMON009')} onClick={openAddAddressTypeDialog}>
            <Add style={{ height: 35, width: 35 }}  />
          </IconButton>
        </Tooltip>
      </Breadcrumb>
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => loadAddressTypes(0, rowsPerPage)}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1} flexGrow={1}>
        {/* hidden={store.security.user.companyCode != ''} */}
          <Tooltip title={t('COMMON004')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => deleteRecord(selectedData)}>
              <DeleteOutline/>
              {/* hidden={store.security.user.companyCode != ''}  */}
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1}>
          <FilterAddressType props={state} rowsPerPage={rowsPerPage} />
        </Box>
      </Toolbar>

      <DataTable
        columns={columns}
        data={data}
        actions={ actions}
        // store.security.user.companyCode != '' ? [] :
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <AddAddressType
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadAddressTypes(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
