import axios from 'axios';
import {
  GET_DASHBOARD_SITES_LIST,
  GET_DASHBOARD_DEVICE_COUNT,
  GET_DASHBOARD_EMPLOYEE_COUNT,
  GET_DASHBOARD_NOTIFICATION_LIST,
  GET_DASHBOARD_COUNT,
  GET_TEST_COUNT,
  GET_SITEDEVICE_COUNT,
  GET_SITEDOOR_COUNT,
  GET_SITE_EMPLOYEE_COUNT,
  GET_EVENT_COUNT,
  GET_SITE_DOOR_EVENT,
} from './types';
import {
  JAVA_URL_DASHBOARD_COUNT,
  JAVA_URL_DASHBOARD_EMP_COUNT,
  JAVA_URL_DEVICE_COUNT,
  JAVA_URL_SITES_LIST,
  JAVA_URL_TRANSACTIONS_LATEST_EVENTS,
  REACT_URL_TEST_COUNT,
  REACT_SITE_DEVICE_COUNT,
  REACT_SITE_DOOR_COUNT,
  REACT_SITE_EMPLOYEE_COUNT,
  REACT_SITE_EVENT_COUNT,
  REACT_SITE_DOOR_EVENT,
} from './EndPoints';

import CommonUtil from '../components/Util/CommonUtils';
import { JAVA_API_SITE_TYPE_V3 } from './organization/ApiEndPoints';
import { JAVA_API_EMPLOYEE } from './ApiEndPoints';

export const getSites = () => async (dispatch) => {
  const response = await axios.get(JAVA_API_SITE_TYPE_V3);
  dispatch({
    type: GET_DASHBOARD_SITES_LIST,
    payload: response.data.data,
  });
};

export const getdashboardcount = () => async (dispatch) => {
  const response = await axios.get(JAVA_URL_DASHBOARD_COUNT);

  dispatch({
    type: GET_DASHBOARD_COUNT,
    payload: CommonUtil.isEmpty(response.data.data) ? 0 : response.data.data,
  });
};

export const getdeviceCount = () => async (dispatch) => {
  const response = await axios.get(JAVA_URL_DEVICE_COUNT);

  dispatch({
    type: GET_DASHBOARD_DEVICE_COUNT,
    payload: CommonUtil.isEmpty(response.data.data) ? 0 : response.data.data,
  });
};

export const getemployeeCount = () => async (dispatch) => {
  const response = await axios.post(JAVA_API_EMPLOYEE);
  console.log('data====',response.data.data);
  dispatch({
    type: GET_DASHBOARD_EMPLOYEE_COUNT,
    payload: response.data.data,
  });
};

export const getNotificationData = () => async (dispatch) => {
  const response = await axios.get(JAVA_URL_TRANSACTIONS_LATEST_EVENTS);
  dispatch({
    type: GET_DASHBOARD_NOTIFICATION_LIST,
    payload: response.data.data.accTransactionDTOList,
  });
};

export const getSiteEventCount = (SiteID) => async (dispatch) => {
  const response = await axios.get(REACT_SITE_EVENT_COUNT + SiteID);
  dispatch({
    type: GET_EVENT_COUNT,
    payload: response.data,
  });
};

export const getSiteDeviceCount = (SiteID) => async (dispatch) => {
  const response = await axios.get(REACT_SITE_DEVICE_COUNT + SiteID);
  dispatch({
    type: GET_SITEDEVICE_COUNT,
    payload: response.data,
  });
};

export const getSiteDoorCount = (SiteID) => async (dispatch) => {
  const response = await axios.get(REACT_SITE_DOOR_COUNT + SiteID);
  dispatch({
    type: GET_SITEDOOR_COUNT,
    payload: response.data,
  });
};

export const getSiteEmployeeCount = (SiteID) => async (dispatch) => {
  const response = await axios.get(REACT_SITE_EMPLOYEE_COUNT + SiteID);
  dispatch({
    type: GET_SITE_EMPLOYEE_COUNT,
    payload: response.data,
  });
};

export const getDoorEvents = (SiteID, hr, doorId, page, pageSize, previousData, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v2.0/dashboard/door/events?isPageable=${true}&pageNumber=${page}&pageSize=${pageSize}&siteId=${SiteID}&hours=${hr}&doorId=${doorId}`);
    if (response.data.code === 'CASI0001') {
      if (hr === 24) {
        dispatch({
          type: 'SITE_EVENTS_TABLE_DATA',
          payload: response.data.data ? response.data.data : { event: [], currentPage: 0, totalCount: 0, totalPages: 0 },
        });
      } else {
        if (!CommonUtil.isEmpty(previousData) && !CommonUtil.isEmpty(response.data.data)) {
          var events = previousData.event.concat(response.data.data.event);
          response.data.data.event = events;
        }
        dispatch({
          type: 'SITE_EVENTS_DATA',
          payload: response.data.data ? response.data.data : { event: [], currentPage: 0, totalCount: 0, totalPages: 0 },
        });
      }
    } else {
      if (hr === 24) {
        dispatch({
          type: 'SITE_EVENTS_TABLE_DATA',
          payload: { event: [], currentPage: 0, totalCount: 0, totalPages: 0 },
        });
      } else {
        dispatch({
          type: 'SITE_EVENTS_DATA',
          payload: { event: [], currentPage: 0, totalCount: 0, totalPages: 0 },
        });
      }
    }
    onSuccess(response.data);
  } catch (error) {
    console.log(JSON.stringify(error));
  }
};

export const getDoorPathDetailsOnSite = (siteId, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get('/api/v2.0/dashboard/doorPathway?siteId=' + siteId);
    if (response.data.code === 'CASI0001') {
      onSuccess(response.data);
    }
  } catch (error) {
    console.log(JSON.stringify(error));
  }
};

export const setDoorIconColor = (doorId, colorCode, onSuccess) => async (dispatch) => {
  try {
    const res = await axios.put('/api/v2.0/door/color?colorCode=' + encodeURIComponent(colorCode) + '&doorId=' + doorId);
    if (res.data.code === 'CASI0001') {
      onSuccess(res.data);
    }
  } catch (error) {
    console.log(JSON.stringify(error));
  }
};

export const setRemarksOnTransactions = (data, onSuccess) => async (dispatch) => {
  try {
    const res = await axios.put('/api/v2.0/transaction', data);
    if (res.data.code === 'CASI0001') {
      onSuccess(res.data);
    }
  } catch (error) {
    console.log(JSON.stringify(error));
  }
};

export const getEmployeesTrace = (pin, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get('/api/v2.0/dashboard/trace/employee?personId=' + pin);
    if (response.data.code === 'CASI0001') {
      onSuccess(response.data);
    }
  } catch (error) {
    console.log(JSON.stringify(error));
  }
};
