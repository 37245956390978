import axios from "axios";
import { toast } from 'react-toastify';
import { GET_ERRORS, GET_TIMEPERIODS } from "./types";
import { JAVA_URL_TIME_PERIOD } from "./EndPoints";


export const getTimePeriods = (name,remark,query) => async dispatch => {
  const res = await axios.get(JAVA_URL_TIME_PERIOD+'?name=' + name +'&remark=' + remark +'&page=' + query.page + '&size=' + query.pageSize);
  dispatch({
    type: GET_TIMEPERIODS,
    payload: res.data.data
  });
  dispatch({
    type: GET_ERRORS,
    payload: {} 
  });
};

export const createNewTimeZone = (createTimeZone, history) => async dispatch => {
  try {
    // eslint-disable-next-line
    const res = await axios.post(JAVA_URL_TIME_PERIOD, createTimeZone);
    toast.success("acctimePeriod added successfully"); 
    history.push("/acc/time-zone")
 
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data
    });
  }
};
export const updateNewTimeZone = (UpdateTimeZone,query, history) => async dispatch => { 
  try {
    // eslint-disable-next-line
    const res = await axios.put(JAVA_URL_TIME_PERIOD, UpdateTimeZone);
    toast.success("acctimePeriod updated successfully"); 
    history.push("/acc/time-zone") 
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data
    });
  }
};
// export const deleteAccLevel = (levelIds, history) => async dispatch => {
//   const res = await axios.delete(REACT_URL_DELETE_ACCESS_LEVEL_BY_LEVELID + levelIds);
//   if (res.status == 200) {
//     toast.success("acclevel deleted successfully");
//     var name = '';
//     var timeZoneName = '';
//     var query = {
//       page: '',
//       pageSize: ''
//     } 
//   }  

// };