import { Box, Grid, Dialog, DialogTitle, MenuItem, TextField, InputAdornment, IconButton } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { setCommPwd } from '../../actions/DeviceOperationsApiCalls';
import { getDevices } from '../../actions/DeviceApiCalls';
import CommonUtil from '../../components/Util/CommonUtils';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export default function SetCommunicationPassword(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [popupState, setPopupState] = React.useState(props.popupState);
  const [selectedDevices, setSelectedDevice] = React.useState();
  const [state, setState] = React.useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = React.useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [values, setValues] = React.useState({
    showPassword:false,
    showCPassword:false
    });
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    setError({
      ...error,
      [event.target.name]: false,
    });
  };

  const loadDevices = () => {
    dispatch(getDevices('', '', '', '', 1, 1000));
  };

  const deviceOperations = () => {
    if (props.devices.length < 1) {
      toast.warning(t('DEVI0014'));
      handleClose();
      return;
    }

    if (props.devices.length > 1) {
      toast.warning(t('DEVI0039'));
      handleClose();
      return;
    }

    setOpen(true);

    var ids = '';
    const { devices } = props;
    // eslint-disable-next-line
    devices.map((device, index) => {
      if (index === devices.length - 1) ids = ids + device.id;
      else ids = ids + device.id + ',';
    });

    setSelectedDevice(ids);

    // confirmAlert({
    //     customUI: ({ onClose }) => {
    //         return (
    //             <ConfirmModal open={true} text={confirmMessage} onConfirm={() => { dispatch(performDeviceOperations(ids, operation)); onClose() }} onClose={() => onClose()} />
    //         );
    //     }
    // });
  };
  const handleClickShowPassword = () => {
    setValues({...values, newPassword:!values.newPassword})
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handleClickShowCPassword = () => {
    setValues({...values, confirmPassword:!values.confirmPassword})
  }
  const handleClose = () => {
    setOpen(false);
    props.onSuccess();
    popupState.close();
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.newPassword)) {
      setError({
        newPassword: true,
      });
      toast.error(t('DEVI0044'));
      return;
    } else if (CommonUtil.isEmptyString(state.confirmPassword)) {
      setError({
        confirmPassword: true,
      });
      toast.error(t('DEVI0045'));
      return;
    } else if (state.newPassword !== state.confirmPassword) {
      setError({
        confirmPassword: true,
      });
      toast.error(t('DEVI0051'));
      return;
    }

    dispatch(
      setCommPwd(selectedDevices, state.confirmPassword, () => {
        popupState.close();
        loadDevices();
        setState({ newPassword: '', confirmPassword: '' });
      })
    );
  };

  return (
    <>
      <MenuItem onClick={() => deviceOperations()}>{t('DEVI0049')}</MenuItem>

      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-filter-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('DEVI0049')}</Box>
            <CancelIcon onClick={handleClose} style={{ color: 'red', background: 'white', borderRadius: '50%' }} fontSize='small' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.newPassword}
                    name='newPassword'
                    label={t('DEVI0046') + ' *'}
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.newPassword}
                    type={values.newPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                            {values.newPassword ? <Visibility className='visibilityIcons' /> : <VisibilityOff className='visibilityIcons' />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.confirmPassword}
                    name='confirmPassword'
                    label={t('DEVI0047') + ' *'}
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.confirmPassword}
                    type={values.confirmPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton aria-label='toggle password visibility' onClick={handleClickShowCPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                            {values.confirmPassword ? <Visibility className='visibilityIcons' /> : <VisibilityOff className='visibilityIcons' />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
