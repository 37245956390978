import React, { forwardRef } from 'react';
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Search from '@material-ui/icons/Search';
import axios from "axios";
import { compose } from 'redux'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { JAVA_URL_DEPT_DETAILS } from '../../actions/EndPoints';
import { deleteAccLevelDepartment } from '../../actions/AccessLevelActions';
import { getDepartmentListByDepartmentId, getAccLevelDepartmentList, getDeptList } from '../../actions/DepartmentListActions';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Button } from '@material-ui/core';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { withStyles } from '@material-ui/core/styles';
import { TablePagination } from '@material-ui/core';
import AddDepartment from './AddDepartment';
/* eslint-disable import/no-unresolved, import/no-webpack-loader-syntax */
import vars from '!!sass-vars-to-js-loader!../../assets/core/_variables-mui.scss';

const ColorButton = withStyles((theme) => ({
	root: {
		backgroundColor: vars.danger,
		'&:hover': {
			backgroundColor: vars.dangerHover,
		},
	},
}))(Button);

class DepartmentDetailsList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			departmentsList: [],
			search: '',
			isChecked: false,
			selectedIds: [],
			deptList: [],
			query: {
				page: 0,
				pageSize: 5,
			},
			totalCount: 0,
			pageChanged: true,
		}; 
		this.handleInputChange = this.handleInputChange.bind(this);
		this.deleteSelectedIds = this.deleteSelectedIds.bind(this);

	}

	deleteSelectedIds() {
		let ids = '';
		let levelId = this.props.id;
		const { query } = this.state;
		this.state.selectedIds.map((row) => (ids += row.deptId + ','))
		ids = ids.replace(/,\s*$/, "");
		if (this.state.selectedIds.length > 0) {
			this.props.deleteAccLevelDepartment(levelId, ids);
			setTimeout(() => {
				axios.get(JAVA_URL_DEPT_DETAILS + '?page=' + query.page + '&size=' + query.pageSize)
					.then((response) => {
						this.setState({
							deptList: response.data.data?response.data.data.item : [], isLoading: false
						});
					}); 
				 
			}, 500)
		}
		else {
			//    toast.error("Please select AccDepartment"); 
		}
	}
	componentDidMount() {
		this.getDepartmentList();
	}

	componentDidUpdate(prevProps) {
		if (this.props.deptList!== undefined) {
			if (this.props.deptList !== prevProps.deptList) {
				this.setState({ deptList: this.props.deptList.item })
			}
		}
		if (this.props.deptList !== undefined) {
			if (this.props.deptList !== prevProps.deptList) {
				if (this.props.deptList.totalCount !== undefined) {
					this.setState({ totalCount: this.props.deptList.totalCount });
				} else {
					this.setState({ totalCount: 0 });
				}
			}
		}
	}

	getDepartmentList = (additionalState) => {
		const { query } = this.state;
		this.props.getDeptList(this.props.id, query).then(() => {
			this.setState({ ...additionalState });
		})


	}
	handleInputChange(event) {
		const search = event.target.value.toLowerCase();
		this.setState({ search }, () => this.filterList());
	}

	 

	render() {

		const { t } = this.props;
		 
		const tableIcons = {
			Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
			Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
			DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
			Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
			Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
			FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
			LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
			NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
			ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
			SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
			ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
			ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
		};

		const deptColumns = [

			{ field: 'deptName', title: t("tableHeaderCommonDeviceName") }
		];

		const { totalCount, query, pageChanged } = this.state;
		 

		return (
			<div className="acc-level-details-table">
				<Box display="flex" p={1} bgcolor="background.paper" justifyContent="flex-end">
					<Box p={1} flexGrow={1}>
						<p><b className="acc-head">{t("labelAccessLevelDeptTitle")}</b></p>
					</Box>
					<Box p={1}>
						{/* <Button
							color="secondary"
							size="small"
							startIcon={<Refresh className="refresh-btn" />}
							className="acc-list-action-btn">
							{t("buttonCommonRefresh")}
						</Button> */}
					</Box>
					<Box p={1}>
						<AddDepartment id={this.props.id} />
					</Box>
					<Box p={1}>
						<ColorButton
							className="button-common-danger"
							variant="contained"
							color="primary"
							size="small"
							startIcon={<RemoveCircleOutlineOutlinedIcon />}
							onClick={this.deleteSelectedIds} checked={this.state.isChecked}
						>
							{t("buttonCommonRemove")}
						</ColorButton>
					</Box>
				</Box>
				<MaterialTable
					columns={deptColumns}
					data={this.state.deptList ? this.state.deptList: []}
					icons={tableIcons}
					options={{
						minBodyHeight: '100%',
						actionsColumnIndex: -1,
						addRowPosition: 'first',
						showTitle: false,
						toolbarButtonAlignment: "left",
						toolbar: false,
						selection: true,
						pageSize: 5
					}}
					actions={[
						{
							tooltip: t("commonsDeleteWarningMsg"),
							icon: () => <DeleteIcon />,
							onClick: (event, data) => console.log('deleting' + data.length + ' rows')
						}
					]}
					onSelectionChange={(rows) => this.setState({ selectedIds: rows })}
					localization={{
						pagination: {
							// eslint-disable-next-line
							labelDisplayedRows: '{from}-{to}' + "  " + t("commonsTablePaginationOf") + "  " + '{count}',
							labelRowsSelect: t("commonsTablePaginationRow"),
							firstAriaLabel: t("firstPage"),
							firstTooltip: t("commonsTablePaginationFirstPage"),
							previousAriaLabel: t("previousPage"),
							previousTooltip: t("commonsTablePaginationPreviousPage"),
							nextAriaLabel: t("nextPage"),
							nextTooltip: t("commonsTablePaginationNextPage"),
							lastAriaLabel: t("lastPage"),
							lastTooltip: t("commonsTablePaginationLastPage"),
						},

						header: {
							actions: t("buttonCommonActions")
						},
						body: {
							emptyDataSourceMessage: t("tableCommonNoRecordDisplay"),
							editTooltip: t("commonsTableToolTipEdit"),
							deleteTooltip: t("commonsTableToolDelete"),
							filterRow: {
								filterTooltip: 'Filter'
							},
							editRow: {
								cancelTooltip: t("tableBodyCancelTooltip"),
								saveTooltip: t("tableBodySaveTooltip")

							}
						},
						toolbar: {
							searchPlaceholder: t("commonsTableToolBarSearch"),
							searchTooltip: t("commonsTableToolBarSearch"),
							// eslint-disable-next-line
							nRowsSelected: '{0}' + " " + t("commonsTablePaginationRow") + " " + t("selected")
						}
					}}
					components={{
						Pagination: (props) => (
						  <TablePagination {...props} count={totalCount} page={query.page} rowsPerPage={query.pageSize} />
						),
					  }}
					  onPageChange={(page) => {
						if (pageChanged) {
						  this.setState(
							{ query: { ...query, page: page }, pageChanged: false }, () => this.getDepartmentList({ pageChanged: true })
						  );
						}
					  }} 
					  onRowsPerPageChange={(pageSize) => {
						this.setState({ query: { ...query, pageSize } }, this.getDepartmentList);
					  }}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	departmentsList: state.DepartmentList.departmentList,
	deptList: state.DepartmentList.deptList
});

export default compose(withTranslation(), connect(mapStateToProps, { getDepartmentListByDepartmentId, getAccLevelDepartmentList, getDeptList, deleteAccLevelDepartment }))(DepartmentDetailsList);