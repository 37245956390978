import { Dialog, DialogTitle, Box, DialogActions, Button, DialogContent, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import { addLevelDoor, getLevelDoor, loadLevelDoors } from '../../actions/LevelApiCalls';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DataTable from '../../components/DataTable';
import { toast } from 'react-toastify';
import CommonUtil from '../../components/Util/CommonUtils';
import Headerbar from '../../components/HeaderBar';
import FilterLevelDoor from './FilterLevelDoor';
import CachedIcon from '@material-ui/icons/Cached';

const useStyles = makeStyles((theme) => ({
  deptTableStyle: {
    '& table.MuiTable-root': {
      '& thead.MuiTableHead-root': {
        position: 'relative !important',
        background: 'white',
      },
    },
  },
}));

export default function AddLevelDoor(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();
  const store = useSelector((state) => state);
  const [selectedData, onSelectionChange] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  //new changes
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // eslint-disable-next-line
  const [query, setQuery] = useState({
    page: 0,
    pageSize: 100,
    levelId: props.levelId,
    doorIdList: [],
    flag: false,
  });

  useEffect(() => {
    if (props.open) loadData(page, rowsPerPage);
    // eslint-disable-next-line
  }, [props.open]);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.level.selectLevelDoors)) {
      setTotalRecords(store.level.selectLevelDoors.totalCount);
      setPage(store.level.selectLevelDoors.curPage - 1);
      setData(store.level.selectLevelDoors.levelDoors);
    } else {
      setTotalRecords(0);
      setPage(0);
      setData([]);
    }
  }, [store.level.selectLevelDoors]);

  const loadData = (pageNo, pageSize) => {
    dispatch(loadLevelDoors(props.levelId, filter, true, pageNo + 1, pageSize));
  };

  const [filter, setFilter] = useState({
    alias: '',
    name: '',
  });

  const defaultFilter = {
    alias: '',
    name: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      alias: filterData.alias,
      name: filterData.doorName,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    if (props.open) loadData(0, rowsPerPage);
  }, [filter]);

  const handleClose = () => {
    resetFilter();
    setPage(0);
    props.onClose(false);
  };

  const handleSubmit = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var ids = [];
    // eslint-disable-next-line
    data.map((record, index) => {
      ids.push(record.id);
    });

    //var doorIdList = '';
    var payload = {
      doorIdList: ids,
    };

    dispatch(
      addLevelDoor(query.levelId, payload, (data) => {
        handleClose();
      })
    );
  };

  const columns = [
    {
      field: 'name',
      title: t('DOORI003'),
      headerStyle: {
        color: 'black',
      },
    },
    { field: 'deviceAlias', title: t('DEVI0002'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <AddToPhotosIcon color='secondary' />,
      tooltip: t('COMMON009'),
      position: 'row',
      onClick: (event, rowData) => {
        handleSubmit([rowData]);
      },
    },
  ];

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  const handleSelectedRecordsOnPageChange = () => {
    if (selectedData.length > 0) {
      setSelectedRecords(selectedData);
    }
  };

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    maxBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage,
    headerStyle: {
      position: 'sticky',
    },
  };

  return (
    <Dialog maxWidth='sm' fullWidth open={props.open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
      <DialogTitle id='form-dialog-title'>
        <Box display='flex'>
          <Box flexGrow={1}>{t('LEVEL003')}</Box>
          <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' className='custom-container-header' alignItems='center'>
          <Headerbar className='custom-container-header'>
            <Box>
              <FilterLevelDoor applyFilter={(data) => applyFilter(data)} />
            </Box>
            <Box>
              <Tooltip title={t('COMMON002')}>
                <IconButton color='secondary' size='small' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
                  <CachedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Headerbar>
        </Box>
        <div className={classes.deptTableStyle}>
          <DataTable
            columns={columns}
            actions={actions}
            data={data}
            options={options}
            onSelectedRoles={(rows) => onSelectionChange(rows)}
            count={totalRecords}
            page={page}
            onChangePage={(newPage) => handleChangePage(newPage)}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSubmit(selectedData)} color='primary' variant='contained' size='small'>
          {t('COMMON007')}
        </Button>
        <Button onClick={handleClose} color='primary' variant='contained' size='small'>
          {t('COMMON008')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
