//Device
export const JAVA_API_DEVICE = "/api/v2.0/device";
export const JAVA_API_DEVICE_OPERATIONS = "/api/v2.0/device_operations";

//Door
export const JAVA_API_DOOR = "/api/v2.0/door";
export const JAVA_API_DOOR_VERIFYMODE = "/api/v2.0/door/{deviceId}/verify_mode";

//Reader
export const JAVA_API_READER = "/api/v2.0/reader";

//Auxin
export const JAVA_API_AUXIN = "/api/v2.0/auxiliary_input";

//Auxout
export const JAVA_API_AUXOUT = "/api/v2.0/auxiliary_output";

//Timeseg
export const JAVA_API_TIMESEG = "/api/v2.0/timeseg";

//Level
export const JAVA_API_LEVEL = "/api/v2.0/access_level";
export const JAVA_API_LEVEL_DOOR = "/api/v2.0/access_level/{levelId}/door";
export const JAVA_API_LEVEL_PERSON = "/api/v2.0/access_level/{levelId}/person";
export const JAVA_API_LEVEL_DEPARTMENT = "/api/v2.0/access_level/{levelId}/department";

//Interlock
export const JAVA_API_INTERLOCK = "/api/v2.0/interlock";

//Anyipassback
export const JAVA_API_ANTIPASSBACK = "/api/v2.0/antipassback";

//Operation Log
export const JAVA_API_TRANSACTION = "/api/v2.0/transaction"
export const JAVA_API_EXCEPTION_TRANSACTION = "/api/v2.0/exceptiontransaction"
export const JAVA_API_EXPORT_TRANSACTION = "/api/v2.0/export/transactions"
export const JAVA_API_EXPORT_TODAY_TRANSACTION = "/api/v2.0/export/todaytransactions"
export const JAVA_API_EXPORT_EXCEPTION_TRANSACTION = "/api/v2.0/export/exceptiontransactions"

//Organization
export const JAVA_API_ORGANIZATION = 'http://192.168.11.13:9096/api/v2.0';

export const JAVA_API_EMPLOYEE = process.env.REACT_APP_ORG_SERVICE_URI+'/api/v2.0/employee';
export const JAVA_API_DELETE_EMPLOYEE = process.env.REACT_APP_ORG_SERVICE_URI+'/api/v2.0/employees';
export const JAVA_API_EMPLOYEE_SEARCH = process.env.REACT_APP_ORG_SERVICE_URI+'/api/v2.0/employee/search';

export const JAVA_API_DEPARTMENT = process.env.REACT_APP_ORG_SERVICE_URI+'/api/v2.0/department';
export const JAVA_API_DELETE_DEPARTMENT = process.env.REACT_APP_ORG_SERVICE_URI+'/api/v2.0/departments';
export const JAVA_API_DEPARTMENT_SEARCH = process.env.REACT_APP_ORG_SERVICE_URI+'/api/v2.0/department/search';

export const JAVA_API_DESIGNATION = process.env.REACT_APP_ORG_SERVICE_URI+'/api/v2.0/designation';
export const JAVA_API_DELETE_DESIGNATION = process.env.REACT_APP_ORG_SERVICE_URI+'/api/v2.0/designations';
export const JAVA_API_DESIGNATION_SEARCH = process.env.REACT_APP_ORG_SERVICE_URI+'/api/v2.0/designation/search';

export const JAVA_API_ADDRESSTYPE= process.env.REACT_APP_LOCATION_SERVICE_URI+'/api/v2.0/addressType';
export const JAVA_API_ADDRESSTYPE_SEARCH= process.env.REACT_APP_LOCATION_SERVICE_URI+'/api/v2.0/addressType/search';

export const JAVA_API_SITETYPE= process.env.REACT_APP_LOCATION_SERVICE_URI+'/api/v2.0/siteType';
export const JAVA_API_SITETYPE_SEARCH= process.env.REACT_APP_LOCATION_SERVICE_URI+'/api/v2.0/siteType/search';

export const JAVA_API_SITE= process.env.REACT_APP_LOCATION_SERVICE_URI+'/api/v2.0/site';
export const JAVA_API_SITE_SEARCH= process.env.REACT_APP_LOCATION_SERVICE_URI+'/api/v2.0/site/search';

export const JAVA_API_ZONE= process.env.REACT_APP_LOCATION_SERVICE_URI+'/api/v2.0/zone';
export const JAVA_API_ZONE_SEARCH= process.env.REACT_APP_LOCATION_SERVICE_URI+'/api/v2.0/zone/search';

export const JAVA_API_ACC_LEVEL_DEPT_DELETE='/api/v2.0/access_level/depts'

export const JAVA_API_GET_EMPLOYEE = process.env.REACT_APP_ORG_SERVICE_URI + '/api/v2.0/emp'

// organization_v3

export const JAVA_API_DEPARTMENT_V3 = '/web/department';
export const JAVA_API_DELETE_DEPARTMENT_V3 = '/web/departments/delete';
export const JAVA_API_DEPARTMENT_SEARCH_V3 = '/web/department/post';

export const JAVA_API_DESIGNATION_V3 = '/web/designation';
export const JAVA_API_DESIGNATION_SEARCH_V3 = '/web/designation/post';



