import { Grid } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import CommonUtil from '../../components/Util/CommonUtils';

export default function SignUpMobileInput(props) {
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState('');
  const [mobileNO, setMobileNo] = useState('');
  const [editMode, seteditMode] = useState(false);

  // useEffect(() => {
  //   if (!CommonUtil.isEmptyString(props.phone)) {
  //     if (props.phone.includes('-')) {
  //       setCountryCode(props.phone.split('-')[0]);
  //       setMobileNo(props.phone.split('-')[1]);
  //     } else {
  //       setMobileNo(props.phone);
  //     }
  //   } else {
  //     setMobileNo(props.phone);
  //   }
  // }, [props.phone, props.editMode]);

  const handleCountryCodeChange = (countryCode) => {
    props.onCounryCodeChange(countryCode);
  };

  const handleMobileNoChange = (mobileNo) => {
    var regex = /^[0-9]*$/;
    if (regex.test(mobileNo)) {
      props.onMobileNoChange(mobileNo);
    }
  };

  return (
    <Grid container xs={12}>
      <Grid item xs={11} lg={4}>
        <div style={{ padding: '1rem', paddingRight: '5px', paddingLeft: '0px' }}>
          <div
            style={{ position: 'absolute', left: '26px', zIndex: 2, top: '10px', backgroundColor: 'white', color: '00002D', fontSize: 9, padding: '0px 4px' }}
            disabled={!editMode}
          >
            {t('COMMON189')}
          </div>
          <PhoneInput
            countryCodeEditable={false}
            enableSearch='true'
            searchPlaceholder={t('search')}
            searchNotFound={t('COMMON165')}
            onChange={(phone) => handleCountryCodeChange(phone)}
            inputProps={{
              disabled: true,
            }}
            value={props.countryCode}
            country={CommonUtil.getDefaultCountryCode()}
          />
        </div>
      </Grid>
      <Grid item xs={12} lg={8}>
        <div style={{ padding: '0.2rem' }}>
          <TextField
            size='small'
            label={t('PERSON006')}
            variant='outlined'
            error={props.error}
            // placeholder={t('PERSON006')}
            helperText=''
            type='text'
            name='phone'
            autoComplete='off'
            fullWidth
            value={props.phone}
            onChange={(e) => handleMobileNoChange(e.target.value)}
          />
        </div>
      </Grid>
    </Grid>
  );
}
