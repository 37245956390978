import { Button, Grid } from '@material-ui/core';
import React, { Fragment, forwardRef } from 'react';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { REACT_URL_ADD_TIME_PERIOD, JAVA_URL_TIME_PERIOD, REACT_URL_UPDATE_TIME_PERIOD } from '../../actions/EndPoints'
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Link } from 'react-router-dom';
import { getTimePeriods } from '../../actions/TimePeriodActions'
import axios from "axios";
import FilterListIcon from '@material-ui/icons/FilterList';
import { TablePagination } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';

// import { ReactComponent as Refresh } from "../../assets/cloudaccess-svg/Refresh.svg";
import { ReactComponent as Delete } from "../../assets/cloudaccess-svg/Delete.svg";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
const styles = theme => ({
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 250
    }
  },
  formcontrol: {
    width: 150
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="dilog-filter-head" {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="dilog-form-closeButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
class TimePeriods extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      timePeriods: [],
      name: '',
      remark: '',
      selectedIds: [],
      companyId: '',
      query: {
        page: 0,
        pageSize: 5,
      },
      totalCount: 0,
      pageChanged: true,
      filter: false,
    }
    this.handleRowDelete = this.handleRowDelete.bind(this);
    this.deleteSelectedIds = this.deleteSelectedIds.bind(this);
    this.getTimePeriods = this.getTimePeriods.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }
  fetchData = (additionalState) => {
    const { query } = this.state;
    this.props.getTimePeriods(this.state.name, this.state.remark, query, this.state.history).then(() => {
      this.setState({ ...additionalState });
    })
  }
  componentDidUpdate(prevProps) {
    if (this.props.timePeriods !== undefined) {
      if (this.props.timePeriods !== prevProps.timePeriods) {
        this.setState({ timePeriods: this.props.timePeriods.item })
      }
    }
    if (this.props.timePeriods !== undefined) {
      if (this.props.timePeriods !== prevProps.timePeriods) {
        if (this.props.timePeriods.totalCount !== undefined) {
          this.setState({ totalCount: this.props.timePeriods.totalCount });
        } else {
          this.setState({ totalCount: 0 });
        } 
      }
    }
  }
  filterOpenDialog = () => {
    this.setState({ filter: true })
  }
  filterClose = () => {
    const { query } = this.state;
    let name = '';
    let remark = '';
    this.props.getTimePeriods(name, remark, query, this.state.history);
    this.setState({
      filter: false, name: '',
      remark: '',
    })
  }
  filterApply = () => {
    const { query } = this.state;
    this.props.getTimePeriods(this.state.name, this.state.remark, query, this.state.history)
      .then(() => {
        this.setState({ timePeriods: this.props.timePeriods });

      })

    this.setState({
      filter: false,
      name: '',
      deviceAlias: '',
    });
  }
  getTimePeriods() {
    this.props.getTimePeriods();
  }

  deleteSelectedIds() {
    let ids = '';
    this.state.selectedIds.map((row) => (ids += row.id + ','))
    new Promise((resolve) => {
      if (ids.length > 0)
        this.handleRowDelete(ids, resolve)
    })
  }

  async handleRowDelete(id, resolve) {
    
    
    await axios.delete(JAVA_URL_TIME_PERIOD + '?ids=' + id, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(() => {
      toast.success("acc timeperiod deleted successfully");
      this.fetchData();
    });
    resolve();
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() { 
    
    const { t } = this.props;
    // const timePeriods = this.state.timePeriods;
    const tableIcons = {
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
    };

    const columns = [
      { title: 'id', field: "id", hidden: true },
      { field: 'name', title: t('tableHeaderTimePeriodName') },
      { field: 'remark', title: t('tableHeaderTimePeriodRemarks') }
    ];

    const { totalCount, query, pageChanged } = this.state;


    return (
      <Fragment>
        <div>
          <div style={{ width: '100%' }}>
            <Dialog open={this.state.filter} onClose={this.filterClose} aria-labelledby="form-dialog-title" fullWidth={true}
              maxWidth={'xs'} >


              <DialogTitle id="form-dialog-title">Filter</DialogTitle>
              <DialogContent>
                <Grid item xs={12}>
                  <TextField className="text-fields"
                    name="name"
                    onChange={this.onChange}
                    label={"Time Zone Name"} type="text"


                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField className="text-fields"
                    name="remark"
                    onChange={this.onChange}
                    label={"Remarks"} type="text" 
                  />
                </Grid> 
              </DialogContent>
              <DialogActions>
                <Button onClick={this.filterApply} color="primary">
                  Apply
						</Button>
                <Button onClick={this.filterClose} color="primary">
                  Reset
						</Button>
              </DialogActions>
            </Dialog>
            <Box display="flex" p={1} bgcolor="background.paper">
              <Box p={1} flexGrow={1}>
                <p><b className="acc-head">{'Time Zone'}</b></p>
              </Box>
              {/* <Box p={1}>
                <Paper className="search-root">
                  <InputBase
                    className="search-input"
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    ref={this.input}
                    type="text" name="search" onChange={this.handleInputChange} checked={this.state.isChecked} />
                  <IconButton
                    type="submit"
                    className="search-iconButton"
                    aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Box> */}
              <Box p={1}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<FilterListIcon />}
                  onClick={this.filterOpenDialog}
                >
                  Filter
							</Button>
              </Box>
            </Box>
            <Box display="flex" p={1} bgcolor="background.paper">
              <Box p={1}>
                <Button
                  onClick={this.getTimePeriods}
                  color="secondary"
                  size="small"
                  startIcon={<Refresh />}
                  className="acc-list-action-btn">
                  {t('buttonCommonRefresh')}
                </Button>
              </Box>
              <Box p={1} flexGrow={1}>
                <Button
                  onClick={this.deleteSelectedIds}
                  color="secondary"
                  size="small"
                  startIcon={<Delete />}
                  className="acc-list-action-btn">
                  {t('buttonCommonDelete')}
                </Button>
              </Box>
              <Box p={1}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  component={Link}
                  to={{
                    pathname: REACT_URL_ADD_TIME_PERIOD,
                  }}
                  startIcon={<AddCircleOutlineRoundedIcon />}
                >
                  {t('buttonTimePeriodTitle')}
                </Button>
              </Box>
            </Box>
          </div>

          <MaterialTable
            columns={columns}
            data={this.state.timePeriods ? this.state.timePeriods : []}
            icons={tableIcons}
            options={{
              minBodyHeight: '100%',
              actionsColumnIndex: -1,
              addRowPosition: 'first',
              showTitle: false,
              toolbarButtonAlignment: "left",
              toolbar: false,
              selection: true,
              pageSize: 5
            }}
            onSelectionChange={(rows) => this.setState({ selectedIds: rows })}
            localization={{
              header: {
                actions: t("buttonCommonActions")
              }
            }}
            editable={{

              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  this.handleRowDelete(oldData.id, resolve)
                }),
            }}
            actions={[
              {
                icon: () =>
                  <IconButton aria-label="vehicles" component={Link} >
                    <EditIcon color="secondary" />
                  </IconButton>,
                tooltip: "Edit TimeZone",
                position: 'row',
                onClick: (event, rowData) => {

                  this.props.history.push(REACT_URL_UPDATE_TIME_PERIOD, rowData)
                  //this.handleChange(event, rowData);

                }
              }

            ]}
            
            components={{
              Pagination: (props) => (
                <TablePagination {...props} count={totalCount} page={query.page} rowsPerPage={query.pageSize} />
              ),
            }}
            onPageChange={(page) => {
              if (pageChanged) {
                this.setState(
                  { query: { ...query, page: page }, pageChanged: false }, () => this.fetchData({ pageChanged: true })
                );
              }
            }}

            onRowsPerPageChange={(pageSize) => {
              this.setState({ query: { ...query, pageSize } }, this.fetchData);
            }}

          />
        </div>
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  timePeriods: state.TimePeriod.timePeriodList
});

export default compose(withTranslation(), connect(mapStateToProps, { getTimePeriods }))(withRouter(TimePeriods));