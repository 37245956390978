import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PathWayDialog from './PathWayDialog';

export default function ResponsiveDialogPathWay(props) {
  const [open, setOpen] = React.useState(props.open);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog maxWidth='xl' fullWidth='xl' fullScreen={fullScreen} open={props.open} onClose={handleClose}  aria-labelledby='responsive-dialog-title'>
        <DialogContent style={{height:'850px'}}>
          <PathWayDialog toggleFullScreen={() => handleClose()} doorsPerPath={props.doorsPerPath} siteId={props.siteId}/>
        </DialogContent>
      </Dialog>
    </div>
  );
}
