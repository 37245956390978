import { DESIGNATION_LIST } from '../../actions/organization/types';

const initialState = {
  designationList: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DESIGNATION_LIST:
      return {
        ...state,
        designationList: action.payload,
      };

    default:
      return state;
  }
}
