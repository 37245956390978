import axios from 'axios';
import CommonUtil from '../../components/Util/CommonUtils';
import { JAVA_API_ADDRESS_FILTER_V2, JAVA_API_ADDRESS_UPDATE_V2, JAVA_API_ADDRESS_V2, JAVA_API_DELETE_SITE_V2, JAVA_API_SITE, JAVA_API_SITE_DELETE, JAVA_API_SITE_DELETE_V3, JAVA_API_SITE_FILTER_V3, JAVA_API_SITE_SEARCH, JAVA_API_SITE_UPDATE_V3, JAVA_API_SITE_V3 } from './ApiEndPoints';
import { SITE_LIST } from './types';

export const getSites = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_SITE_FILTER_V3 + 'pageNumber=' + pageNumber + '&pageSize=' + pageSize);
    if (res.status === 200 && res.data.code === 'LMSI6000') {
      if (isPageable) {
        dispatch({
          type: SITE_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: SITE_LIST,
          payload: [],
        });
      }
    }
  } catch {}
};
export const filterSite = (name, parentId, page, size,callBack) => async (dispatch) => {
  console.log(page)
  try {
    let query = '';
    if (name !== '') {
      query += '&name=' + name;
    }
    if (parentId !== '') {
      query += "&parentId=" + parentId;
    }
    const res = await axios.get(JAVA_API_ADDRESS_FILTER_V2 + 'pageNumber=' + page + '&pageSize=' + size + query);
    if (res.status === 200 && res.data.code === 'LMSI0000') {
      dispatch({
        type: SITE_LIST,
        payload: res.data.data,
      });
      callBack(res.data.data);
    } else {
      dispatch({
        type: SITE_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const deleteSites = (ids, callBack) => async (dispatch) => {
  try {
    // const deleteObj = {};
    // deleteObj.ids = [ids];
    // deleteObj.deleteChild = false;

    const res = await axios.delete(JAVA_API_DELETE_SITE_V2+'?ids='+ ids);
    callBack(res.data);
  } catch {}
};

export const updateSite = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === '' ? true : false;
    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_ADDRESS_V2, [payload]);
    } else {
      // if(CommonUtil.isEmptyString(payload.address)){
      //   res = await axios.put(JAVA_API_SITE_UPDATE_V3 + payload.id, payload);
      // }else{
      // payload.address.siteId = payload.id;
      res = await axios.put(JAVA_API_ADDRESS_UPDATE_V2  + payload.id, payload);
    // }
    }
    callBack(res.data);
  } catch {}
};

