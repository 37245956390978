import { TIMESEG_LIST } from "../actions/types";

const initialState = {
    timesegs:[]
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TIMESEG_LIST:
            return {
                ...state,
                timesegs: action.payload
            }
        default:
            return state;
    }
}
