import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import FilterListIcon from '@material-ui/icons/FilterList';
import { toast } from 'react-toastify';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { getTodayFilterTransactions } from '../../actions/ReportsApiCalls';
import { useDispatch } from 'react-redux';
import { Tooltip, IconButton } from '@material-ui/core';
import { Typography } from 'antd';

export default function TodayFilterTransactions(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line
  const [totalRecords, setTotalRecords] = React.useState(0);
  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const today = new Date(),
    // eslint-disable-next-line
    date = today.getFullYear() + '-' + '0' + (today.getMonth() + 1) + '-' + today.getDate();
  // eslint-disable-next-line
  const [query, setQuery] = React.useState({
    fromDate: date,
  });
  const [state, setState] = React.useState({
    personalId: '',
    deviceName: '',
    departmentNumber: '',
    departmentName: '',
    eventDescription: '',
    readerName: '',
    name: '',
    cardNumber: '',
    eventPointName: '',
    eventId: '',
  });

  const handleClose = () => {
    setState({
      personalId: '',
      deviceName: '',
      departmentNumber: '',
      departmentName: '',
      eventDescription: '',
      readerName: '',
      name: '',
      cardNumber: '',
      eventPointName: '',
      eventId: '',
    });
    setOpen(false);
  };

  const reset = () => {
    if (
      state.personalId.trim() === '' &&
      state.deviceName.trim() === '' &&
      state.departmentNumber.trim() === '' &&
      state.departmentName.trim() === '' &&
      state.eventDescription.trim() === '' &&
      state.readerName.trim() === '' &&
      state.name.trim() === '' &&
      state.cardNumber.trim() === '' &&
      state.eventPointName.trim() === '' &&
      state.eventId.trim() === ''
    ) {
      toast.error(t('COMMON027'));
      return;
    }
    setState({
      personalId: '',
      deviceName: '',
      departmentNumber: '',
      departmentName: '',
      eventDescription: '',
      readerName: '',
      name: '',
      cardNumber: '',
      eventPointName: '',
      eventId: '',
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (
      state.personalId.trim() === '' &&
      state.deviceName.trim() === '' &&
      state.departmentNumber.trim() === '' &&
      state.departmentName.trim() === '' &&
      state.eventDescription.trim() === '' &&
      state.readerName.trim() === '' &&
      state.name.trim() === '' &&
      state.cardNumber.trim() === '' &&
      state.eventPointName.trim() === '' &&
      state.eventId.trim() === ''
    ) {
      toast.error(t('COMMON015'));
      return;
    }
    props.applyFilter(state);
    setState({
      personalId: '',
      deviceName: '',
      departmentNumber: '',
      departmentName: '',
      eventDescription: '',
      readerName: '',
      name: '',
      cardNumber: '',
      eventPointName: '',
      eventId: '',
    });
    handleClose();
  };

  return (
    <div>
      <Tooltip title={t('COMMON006')}>
      <IconButton color='secondary' aria-label='Reset filter' onClick={() => setOpen(true)}>
        <FilterListIcon/>
        </IconButton>
      </Tooltip>
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} className='filter-dialog'>
        {/* <DialogTitle>
          <Box flexGrow={1} style={{ color: 'black', textAlign: 'left', fontSize: '20px', marginLeft: '18px' }}>
            {t('COMMON006')}
          </Box>
        </DialogTitle> */}
        <DialogTitle>
          <Box display='flex'>
            <Box flexGrow={1}>
              <Typography variant='h4' color='secondary'>
                {t('COMMON006')}
              </Typography>
            </Box>
            <CancelIcon onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container>
              <Grid item xs={6} sm={4}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth name='personalId' label={t('OPE017')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.personalId} />
                </Box>
              </Grid>

              <Grid item xs={6} sm={4}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth name='deviceName' label={t('OPE020')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.deviceName} />
                </Box>
              </Grid>
              {/* <Grid item xs={6} sm={4}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        fullWidth
                                        name="departmentNumber"
                                        label={t("OPE015")}
                                        helperText=""
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        value={state.departmentNumber}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        fullWidth
                                        name="departmentName"
                                        label={t("OPE006")}
                                        helperText=""
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        value={state.departmentName}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={6} sm={4}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        fullWidth
                                        name="eventDescription"
                                        label={t("OPE013")}
                                        helperText=""
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        value={state.eventDescription}
                                    />
                                </Box>
                            </Grid> */}
              <Grid item xs={6} sm={4}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth name='readerName' label={t('OPE014')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.readerName} />
                </Box>
              </Grid>
              {/* <Grid item xs={6} sm={4}>
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        label={t("OPE005")}
                                        helperText=""
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        value={state.name}
                                    />
                                </Box>
                            </Grid> */}

              <Grid item xs={6} sm={4}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth name='cardNumber' label={t('OPE004')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.cardNumber} />
                </Box>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    name='eventPointName'
                    label={t('OPE011')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.eventPointName}
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={6} sm={4}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth name='eventId' label={t('OPE003')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.eventId} />
                </Box>
              </Grid> */}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} variant='contained' size='small'>
            {t('COMMON024')}
          </SubmitButton>
          <CancelButton onClick={reset} variant='contained' size='small'>
            {t('COMMON025')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
