import { combineReducers } from 'redux';
import ThemeOptions from './ThemeOptions';
import TimePeriodReducer from './TimePeriodReducer';
import AccReaderReducer from './AccReaderReducer';
import AccAuxInReducer from './AccAuxInReducer';
import AccAuxOutReducer from './AccAuxOutReducer';
import AccDeviceReducer from './AccDeviceReducer';
import errorReducer from './errorReducer';
import RTransactionsReducer from './RTransactionsReducer';
import RExceptionTransactionsReducer from './RExceptionTransactionsReducer';
import RTransactionsForDayReducer from './RTransactionsForDayReducer';
import RLastKnownPositionReducer from './RLastKnownPositionReducer';
import AccLevelReducer from './AccLevelReducer';
import DoorListReducer from './DoorListReducer';
import EmployeeListReducer from './EmployeeListReducer';
import DepartmentListReducer from './DepartmentListReducer';
import InterLockListReducer from './InterLockListReducer';
import LinkageListReducer from './LinkageListReducer';
import AntiPassbookListReducer from './AntiPassbookListReducer';
import SiteDashboardReducer from './organization/SiteDashboardReducer';

import SelfRegistrationReducer from './SelfRegistrationReducer';
import DoorOperationReducer from './DoorOperationReducer';
import DeviceOperationReducer from './DeviceOperationReducer';
import DoorReducer from './DoorReducer';
import TimesegReducer from './TimesegReducer';
import ReaderReducer from './ReaderReducer';
import LevelReducer from './LevelReducer';
import AuxinReducer from './AuxinReducer';
import AuxoutReducer from './AuxoutReducer';
import InterlockReducer from './InterlockReducer';
import LinkageReducer from './LinkageReducer';
import LinkageDeviceReducer from './LinkageDeviceReducer';
import AntipassbackReducer from './AntipassbackReducer';
import securityReducer from '../oauth-client/securityReducer';

//----------Organization start-------------//

import DepartmentReducer from './organization/DepartmentReducer';
import DesignationReducer from './organization/DesignationReducer';
import AddressTypeReducer from './organization/AddressTypeReducer';
import SiteTypeReducer from './organization/SiteTypeReducer';
import SiteReducer from './organization/SiteReducer';
import ZoneReducer from './organization/ZoneReducer';
import PersonReducer from './organization/PersonReducer';
import AddressReducer from './organization/AddressReducer';
import LoaderReducer from './LoaderReducer';
import UserRoleReducer from './organization/UserRoleReducer';
import DashboardReducer from './DashboardReducer';


//--------Organization end--------------//

export default combineReducers({
  errors: errorReducer,
  ThemeOptions: ThemeOptions,
  TimePeriod: TimePeriodReducer,
  AccLevelList: AccLevelReducer,
  AccRedaerList: AccReaderReducer,
  AccAuxInList: AccAuxInReducer,
  AccAuxOutList: AccAuxOutReducer,
  AccDevice: AccDeviceReducer,
  RTransactions: RTransactionsReducer,
  RTrasactionsForDay: RTransactionsForDayReducer,
  RLastKnownPosition: RLastKnownPositionReducer,
  RExceptionTransactions: RExceptionTransactionsReducer,
  DoorList: DoorListReducer,
  EmployeeList: EmployeeListReducer,
  DepartmentList: DepartmentListReducer,
  InterLockList: InterLockListReducer,
  LinkageList: LinkageListReducer,
  AntiPassbookList: AntiPassbookListReducer,
  dashBoard: DashboardReducer,
  siteDashBoard: SiteDashboardReducer,
  selftRegister: SelfRegistrationReducer,
  doorOperationDetailsList: DoorOperationReducer,
  deviceSyncTimeList: DeviceOperationReducer,
  door: DoorReducer,
  timeseg: TimesegReducer,
  reader: ReaderReducer,
  level: LevelReducer,
  auxin: AuxinReducer,
  auxout: AuxoutReducer,
  interlock: InterlockReducer,
  linkage: LinkageReducer,
  linkageDevice: LinkageDeviceReducer,
  antipassback: AntipassbackReducer,
  security: securityReducer,
  loader: LoaderReducer,

  person: PersonReducer,
  address: AddressReducer,
  department: DepartmentReducer,
  designation: DesignationReducer,
  siteType: SiteTypeReducer,
  addressType: AddressTypeReducer,
  site: SiteReducer,
  zone: ZoneReducer,
  userRole: UserRoleReducer,
});
