import axios from 'axios';
import { JAVA_API_TIMESEG } from './ApiEndPoints';
import { toast } from 'react-toastify';
import { TIMESEG_ERROR, TIMESEG_LIST } from './types';
import { REACT_URL_TIMEPERIOD } from './EndPoints';

export const getTimesegs = (name, page, size) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.get(JAVA_API_TIMESEG + '/search' + '?name=' + name + '&remark=' + '' + '&page=' + page + '&size=' + size);
    if (res.status === 200 && res.data.code === 'CASI0001') {
      dispatch({
        type: TIMESEG_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: TIMESEG_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const addTimeseg = (payload, history) => async (dispatch) => {
  try {
    const res = await axios.post(JAVA_API_TIMESEG, payload);
    if (res.status === 200 && res.data.code === 'CASI0050') {
      toast.success(res.data.message);
      dispatch({
        type: TIMESEG_ERROR,
        payload: {},
      });
      history.push(REACT_URL_TIMEPERIOD);
    } else {
      toast.error(res.data.message);
      dispatch({
        type: TIMESEG_ERROR,
        payload: res.data,
      });
    }
  } catch (error) {}
};

export const updateTimeseg = (payload, history) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_TIMESEG, payload);
    if (res.status === 200 && res.data.code === 'CASI0051') {
      toast.success(res.data.message);
      dispatch({
        type: TIMESEG_ERROR,
        payload: {},
      });
      history.push(REACT_URL_TIMEPERIOD);
    } else {
      toast.error(res.data.message);
      dispatch({
        type: TIMESEG_ERROR,
        payload: res.data,
      });
    }
  } catch (error) {}
};

export const removeTimeperiod = (ids, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete(JAVA_API_TIMESEG + '?ids=' + ids);

    switch(res.data.code){
      case 'CASI0052':
        toast.success(res.data.message);
        callBack();
        break;
      case 'CASW0008':
        toast.warning(res.data.message);
        callBack();
        break;
      case 'CASW0003':
        toast.error(res.data.message);
        callBack();
      }
  } catch {}
};
