import { Grid } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import CommonUtil from '../../components/Util/CommonUtils';

export default function SignUpMobileInput(props) {
  const { t } = useTranslation();

  const handleCountryCodeChange = (countryCode) => {
    props.onCounryCodeChange(countryCode);
  };

  const handleMobileNoChange = (mobileNo) => {
    var regex = /^[0-9]*$/;
    if (regex.test(mobileNo)) {
      props.onMobileNoChange(mobileNo);
    }
  };

  return (
    <Grid container xs={12}>
      <Grid item xs={12} lg={4}>
        <div style={{ position: 'absolute', left: '37px', zIndex: 2, top: '-5px', backgroundColor: 'white', color: '00002D', fontSize: 9, padding: '0px 4px' }}>
          {t('COMMON189')}
        </div>
        <PhoneInput
          countryCodeEditable={false}
          enableSearch='true'
          searchPlaceholder={t('search')}
          searchNotFound={t('COMMON165')}
          onChange={(phone) => handleCountryCodeChange(phone)}
          inputProps={{
            disabled: true,
          }}
          value={props.countryCode}
          country={CommonUtil.getDefaultCountryCode()}
        />
      </Grid>
      <Grid item xs={12} lg={8} style={{ paddingLeft: '16px' }}>
        <TextField
          size='small'
          label={t('PERSON006')}
          variant='outlined'
          error={props.error}
          // placeholder={t('PERSON006')}
          helperText=''
          type='text'
          name='phone'
          autoComplete='off'
          fullWidth
          value={props.phone}
          onChange={(e) => handleMobileNoChange(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}
