import React from 'react';
import { Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { compose } from 'redux'
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import { Button  } from '@material-ui/core';
import { connect } from "react-redux";
import InputBase from '@material-ui/core/InputBase';

class EmailLinkage extends React.Component {

	constructor(props) {
		super(props);
		 
		this.state = {
			 
		}
    }
    render() {
        return (
            <div>
                  <Grid container spacing={1}>

                  <Grid item xs={12}>
                      Email Address
                </Grid>
                <Grid item xs={12} sm={6}>
                <Paper component="form" style={{height:"60px"}}>
                     
                    <InputBase
                         fullWidth
                        placeholder="ex: agastya@zkteco.in;kumar@zkteco.in"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        name="email"
                    />
                     
                     
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                
                </Grid>
                <Grid container  style={{marginTop:"50px"}}>
                <Grid item xs={4}>
                      
                </Grid>
                <Grid item xs={4}>
                <Button color="primary" onClick={this.handleDialogOK} variant="contained"  >
                                    OK
                                    </Button>
                                <Button color="primary" onClick={this.handleDialogClose} variant="contained"  style={{marginLeft:"20px"}}>
                                    Cancel
                            </Button>
                </Grid>
                <Grid item xs={4} style={{marginTop:"80px"}}>
                      
                </Grid>
                </Grid>
                  </Grid>
                 
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
	  
});

export default compose(withTranslation(), withStyles(  { withTheme: true }), connect(mapStateToProps))(EmailLinkage);