import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { getLevels } from '../../actions/LevelApiCalls';
import FilterListIcon from '@material-ui/icons/FilterList';
import { toast } from 'react-toastify';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { Tooltip, IconButton } from '@material-ui/core';

export default function FilterLevel(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    page: 0,
    pageSize: 1000,
    name: '',
    timeZoneName: '',
  });

  const handleClose = () => {
    setState({
      name: '',
      timeZoneName: '',
    });
    setOpen(false);
  };

  const reset = () => {
    if (state.name.trim() === '' && state.timeZoneName.trim() === '') {
      toast.error(t('COMMON027'));
      return;
    }
    setState({
      name: '',
      timeZoneName: '',
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const loadData = () => {
    dispatch(getLevels(state.name, state.timeZoneName, 1, props.rowsPerPage));
  };

  const handleSubmit = () => {
    if (state.name.trim() === '' && state.timeZoneName.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
    props.applyFilter(state);
    setState({
      name: '',
      timeZoneName: '',
    });
    handleClose();
  };

  return (
    <div>
      <Tooltip title={t('COMMON006')}>
      <IconButton color='secondary' aria-label='Reset filter' onClick={() => setOpen(true)}>
        <FilterListIcon  />
        </IconButton>
      </Tooltip>

      <Dialog maxWidth='sm' open={open} onClose={handleClose} className='filter-dialog'>
        <DialogTitle>
          <Box display='flex'>
            <Box flexGrow={1}>
              <Typography variant='h4' color='secondary'>
                {t('COMMON006')}
              </Typography>
            </Box>
            <CancelIcon onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth name='name' label={t('LEVEL006')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.name} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth name='timeZoneName' label={t('LEVEL007')} helperText='' variant='outlined' size='small' onChange={handleChange} value={state.timeZoneName} />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className='mr-4' style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleSubmit} color='secondary' variant='contained' className='m-2' size='small'>
            {t('COMMON024')}
          </Button>
          <Button onClick={reset} color='secondary' variant='contained' className='m-2' size='small'>
            {t('COMMON025')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
