import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import { updateAuxin } from '../../actions/AuxinApiCalls';
import CommonUtil from '../../components/Util/CommonUtils';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';

export default function EditAuxin(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const store = useSelector((state) => state);
    const [open, setOpen] = React.useState(props.open);
    const [selectedObject, setSelectedObject] = React.useState(props.selectedObject);
    const [state, setState] = React.useState({
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        remark: props.selectedObject.remark
    })

    const [error, setError] = React.useState({
        name: false
    })

    useEffect(() => {
        setOpen(props.open);
        setSelectedObject(props.selectedObject);
        setState({
            id: props.selectedObject.id,
            name: props.selectedObject.name,
            remark: props.selectedObject.remark
        })
    }, [props.open, props.selectedObject])

    useEffect(() => {
        if (store.errors && !CommonUtil.isEmpty(store.errors.auxinError)) {
            const { auxinError } = store.errors;
            switch(auxinError.code){
                default : setError({name: true});break;
            }
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.errors.auxinError])

    const handleClose = () => {
        props.onClose(false);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });

        setError({
            [name]: false
        })
    };

    const handleSubmit = () => {
        dispatch(updateAuxin(state, (data) => {
            handleClose();
        }))
    }


    return (
        <div>

            <Dialog maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className="custom-modal">
                <DialogTitle id="form-dialog-title">
                    <Box display="flex">
                        <Box flexGrow={1}>
                            {t("AUXIN002")}
                        </Box>
                        <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
                    </Box>
                </DialogTitle>
                <DialogContent >
                    <form noValidate autoComplete="off">
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={12} >
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <TextField
                                            fullWidth
                                            error={error.name}
                                            name="name"
                                            label={t("COMMON167")}
                                            helperText=""
                                            variant="outlined"
                                            size="small"
                                            defaultValue={selectedObject.name}
                                            onChange={handleChange}
                                        />
                                </Box>

                            </Grid>
                            <Grid item xs={12} sm={12} >
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                <TextField
                                    fullWidth
                                    name="remark"
                                    label={t("COMMON016")}
                                    helperText=""
                                    variant="outlined"
                                    size="small"
                                    defaultValue={selectedObject.remark}
                                    onChange={handleChange}
                                    multiline
                                    rows={4}
                                    />
                                </Box>

                            </Grid>
                            
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <SubmitButton onClick={handleSubmit} color="primary" variant="contained" size="small">
                        {t("COMMON007")}
                    </SubmitButton>
                    <CancelButton onClick={handleClose} color="primary" variant="contained" size="small">
                        {t("COMMON008")}
                    </CancelButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
