import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import biozlink from '../../assets/images/cloud_access.svg';
import cloudAccess from '../../assets/images/onboard/cloudaccess.png';
import './custom.css';
import ZKTecoLogo from '../../assets/images/onboard/ZKTeco_Gray-Green.png';
import { useTranslation } from 'react-i18next';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import { IconFlagUS, IconFlagES, IconFlagCN, IconFlagRU, IconFlagTH } from 'material-ui-flags';
import { MenuItem, MenuList, Popover, Tooltip } from '@material-ui/core';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import { ACCESS_SERVICE_URL } from '../../constants/RestConfig';
import japanLogo from '../../assets/images/flags/japan.svg';
import indonesia from '../../assets/images/flags/indonesia.svg';
import thailand from '../../assets/images/flags/thailand.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '90%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    paddingBottom: '90px',
  },
}));

function LoginLayout({ bgColor, color, width, height, fontFamily, ...props }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [company, setCompany] = useState(null);
  const [language, setLanguage] = useState(null);
  const [langIcon, setLangIcon] = useState(null);
  const [search, setSearch] = React.useState(null);
  // eslint-disable-next-line
  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const [selectedLang, setSelectedLang] = useState(null);
  //Language List
  const handleLanguageClick = (event) => {
    setLanguage(event.currentTarget);
    setLangIcon(!langIcon);
  };

  const handleLanguageClose = () => {
    setLanguage(null);
  };
  const open4 = Boolean(language);
  const id2 = open4 ? 'simple-popover' : undefined;

  const handleCloseLangMenu = (event) => {
    if (event[0] === undefined) {
      BrowserLanguage.getDefaultLanguage();
      setSearch(null);
    } else {
      BrowserLanguage.setLanguage(event);
      i18n.changeLanguage(event);
      setSearch(event);
      setSelectedLang(event);
    }
    setAnchorLanguage(null);
    window.location.reload(false);
  };
  return (
    <div className='background-image' fluid={true}>
      <Box component='div' display='flex' alignItems='center' justifyContent='flex-end' p={1}>
        <MenuList aria-describedby={id2} onClick={handleLanguageClick} style={{ color: '#263055' }}>
          <LanguageOutlinedIcon />
          {langIcon ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
        </MenuList>
        <Popover
          id={id2}
          open={open4}
          anchorEl={language}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleLanguageClose}
        >
          <MenuList>
            <MenuItem
              value={'en'}
              key={'en'}
              onClick={() => {
                handleCloseLangMenu('en');
              }}
              style={{ color: '#263055' }}
            >
              <IconFlagUS className='lang-flag' />
              &nbsp; {t('english')} &nbsp;
              {//eslint-disable-next-line
              selectedLang === 'en' ? <CheckOutlinedIcon /> : <></>}
            </MenuItem>
            <MenuItem
              value={'es'}
              key={'es'}
              onClick={() => {
                handleCloseLangMenu('es');
              }}
              style={{ color: '#263055' }}
            >
              <IconFlagES className='lang-flag' />
              &nbsp; {t('spanish')} &nbsp;
              {//eslint-disable-next-line
              selectedLang === 'es' ? <CheckOutlinedIcon /> : <></>}
            </MenuItem>
            <MenuItem
              value={'zh'}
              key={'zh'}
              onClick={() => {
                handleCloseLangMenu('zh');
              }}
              style={{ color: '#263055' }}
            >
              <IconFlagCN className='lang-flag' />
              &nbsp; {t('chinese')} &nbsp;
              {//eslint-disable-next-line
              selectedLang === 'zh' ? <CheckOutlinedIcon /> : <></>}
            </MenuItem>
            <MenuItem
              value={'ru'}
              key={'ru'}
              onClick={() => {
                handleCloseLangMenu('ru');
              }}
              style={{ color: '#263055' }}
            >
              <IconFlagRU class='lang-flag' style={{ width: '25px', height: '25px' }} />
              &nbsp; {t('COMMON201')} &nbsp;
              {//eslint-disable-next-line
              selectedLang === 'ru' ? <CheckOutlinedIcon /> : <></>}
            </MenuItem>

            <MenuItem
              value={'th'}
              key={'th'}
              onClick={() => {
                handleCloseLangMenu('th');
              }}
              style={{ color: '#263055' }}
            >
              <img src={thailand} style={{ height: '20px' }} />
              &nbsp; {t('COMMON202')} &nbsp;
              {//eslint-disable-next-line
              selectedLang === 'th' ? <CheckOutlinedIcon /> : <></>}
            </MenuItem>

            <MenuItem
              value={'ja'}
              key={'ja'}
              onClick={() => {
                handleCloseLangMenu('ja');
              }}
              style={{ color: '#263055' }}
            >
              <img src={japanLogo} style={{ height: '20px' }} />
              &nbsp; {t('COMMON203')} &nbsp;
              {//eslint-disable-next-line
              selectedLang === 'ja' ? <CheckOutlinedIcon /> : <></>}
            </MenuItem>

            <MenuItem
              value={'idn'}
              key={'idn'}
              onClick={() => {
                handleCloseLangMenu('idn');
              }}
              style={{ color: '#263055' }}
            >
              <img src={indonesia} style={{ width: '25px', height: '25px' }} />
              &nbsp; {t('COMMON204')} &nbsp;
              {//eslint-disable-next-line
              selectedLang === 'idn' ? <CheckOutlinedIcon /> : <></>}
            </MenuItem>
          </MenuList>
        </Popover>
      </Box>
      <Container component='main' maxWidth='md' className={classes.container}>
        <Grid container className='main_conatiner'>
          <Grid item sm={4} xs={12} className='paper_left'>
            <img src={biozlink} alt='logo' style={{ width: width }} />
            <img src={cloudAccess} alt='logo' className='left_img' />
          </Grid>
          <Grid item sm={8} xs={12} className='paper_right'>
            {props.children}
          </Grid>
        </Grid>
      </Container>

      {ACCESS_SERVICE_URL !== 'https://cloudaccess.zktecoiot.com' ? (
        <Grid container alignItems='center'>
          <Grid item xs>
            <Box className='footer'>
              <Typography variant='p' align='center' style={{ color: 'black' }}>
                Copyright © 2021 ZKTECO CO., LTD. All rights reserved.
                {/* <img src={ZKTecoLogo} className='zkteco-logo' alt='ZKTeco Logo' /> */}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems='center'>
          <Grid item xs>
            <Box className='footer' flexDirection='column'>
              <Box display='flex' justifyContent='center'>
                <Typography variant='p' align='center' style={{ color: 'black' }}>
                  {t('Copyright © 2021 ZKTECO CO., LTD. All rights reserved.')}
                  {/* <img src={ZKTecoLogo} className='zkteco-logo' alt='ZKTeco Logo' /> */}
                </Typography>
              </Box>

              <a href='https://beian.miit.gov.cn/' variant='p' align='center' style={{ color: 'black' }} target='_blank'>
                {t('粤IPC备17131293号')}
              </a>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
LoginLayout.propTypes = {
  bgColor: PropTypes.string,
  width: PropTypes.string,
};

LoginLayout.defaultProps = {
  width: '150px',
};

export default LoginLayout;
