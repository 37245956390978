import axios from 'axios';
import {
  JAVA_API_TRANSACTION,
  JAVA_API_EXCEPTION_TRANSACTION,
  JAVA_API_EXPORT_TODAY_TRANSACTION,
  JAVA_API_EXPORT_EXCEPTION_TRANSACTION,
  JAVA_API_EXPORT_TRANSACTION,
} from './ApiEndPoints';
import { TRANSACTION_LIST, TRANSACTION_TODAY_LIST, EXCEPTION_TRANSACTION_LIST } from './types';
import { toast } from 'react-toastify';

export const getTransactions = (page, size) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_TRANSACTION + '?page=' + page + '&size=' + size);
    if (res.status === 200 && res.data.code === 'CAAI0511') {
      dispatch({
        type: TRANSACTION_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: TRANSACTION_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const getFilterTransactions = (page, size, filter) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_TRANSACTION + '?page=' + page + '&size=' + size + buildQuery(filter));
    if (res.status === 200 && res.data.code === 'CAAI0511') {
      dispatch({
        type: TRANSACTION_LIST,
        payload: res.data.data,
      });
    } 
    else if(res.data.code === 'CASE0040'){
         toast.error(res.data.message);
    }
    else {
      dispatch({
        type: TRANSACTION_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const getTodayTransactions = (fromDate, page, size) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_TRANSACTION + '?fromDate=' + fromDate + '&page=' + page + '&size=' + size);
    if (res.status === 200 && res.data.code === 'CAAI0513') {
      dispatch({
        type: TRANSACTION_TODAY_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: TRANSACTION_TODAY_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const getTodayFilterTransactions = (fromDate, page, size, filter) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_TRANSACTION + '?fromDate=' + fromDate + '&page=' + page + '&size=' + size + buildQuery(filter));
    if (res.status === 200 && res.data.code === 'CAAI0513') {
      dispatch({
        type: TRANSACTION_TODAY_LIST,
        payload: res.data.data,
      });
    } else {
      console.log(222);
      dispatch({
        type: TRANSACTION_TODAY_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const getExceptionTransactions = (page, size) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_EXCEPTION_TRANSACTION + '?page=' + page + '&size=' + size);
    if (res.status === 200 && res.data.code === 'CAAI0512') {
      dispatch({
        type: EXCEPTION_TRANSACTION_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: EXCEPTION_TRANSACTION_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const getExceptionFilterTransactions = (page, size, filter) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_EXCEPTION_TRANSACTION + '?page=' + page + '&size=' + size + buildQuery(filter));
    if (res.status === 200 && res.data.code === 'CAAI0512') {
      dispatch({
        type: EXCEPTION_TRANSACTION_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: EXCEPTION_TRANSACTION_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const removeTransactions = (ids, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete(JAVA_API_TRANSACTION + '?ids=' + ids);
    if (res.status === 200 && res.data.code === 'CAAI0514') {
      toast.success(res.data.message);
      callBack();
    } else {
      toast.error(res.data.message);
    }
  } catch {}
};

function buildQuery(filter) {
  let query = '';
  if (filter.toDate && filter.fromDate) {
    query = query + `&toDate=${filter.toDate}&fromDate=${filter.fromDate}`;
  }

  if (filter.personalId) {
    query = query + `&personalId=${filter.personalId}`;
  }
  if (filter.deviceAlias) {
    query = query + `&deviceAlias=${filter.deviceAlias}`;
  }
  if (filter.deviceName) {
    query = query + `&deviceAlias=${filter.deviceName}`;
  }
  if (filter.deptCode) {
    query = query + `&deptCode=${filter.deptCode}`;
  }
  if (filter.deptName) {
    query = query + `&deptName=${filter.deptName}`;
  }
  if (filter.eventDescription) {
    query = query + `&eventDescription=${filter.eventDescription}`;
  }
  if (filter.readerName) {
    query = query + `&readerName=${filter.readerName}`;
  }
  if (filter.name) {
    query = query + `&name=${filter.name}`;
  }
  if (filter.cardNo) {
    query = query + `&cardNo=${filter.cardNo}`;
  }
  if (filter.cardNumber) {
    query = query + `&cardNo=${filter.cardNumber}`;
  }
  if (filter.eventPointName) {
    query = query + `&eventPointName=${filter.eventPointName}`;
  }
  if (filter.eventNo) {
    query = query + `&eventNo=${filter.eventNo}`;
  }
  if (filter.eventId) {
    query = query + `&eventNo=${filter.eventId}`;
  }
  return query;
}

export const handleAllTransactionExport = (success,failure,filter) => async (dispatch) => {
  try {
    axios({
      url: JAVA_API_EXPORT_TRANSACTION+'?fromDate='+filter.fromDate+'&toDate='+filter.toDate+'&personPin='+filter.personalId+'&deviceAlias='+filter.deviceAlias+'&readerName='+filter.readerName+'&cardNo='+filter.cardNo+'&eventPointName='+filter.eventPointName,
      method: 'get',
      params: {},
      responseType: 'blob',
    }).then(function(response) {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
      const aEle = document.createElement('a');
      const href = window.URL.createObjectURL(blob);
      aEle.href = href;
      aEle.download = 'AllTransaction.xlsx';
      document.body.appendChild(aEle);
      aEle.click();
      document.body.removeChild(aEle);
      window.URL.revokeObjectURL(href);
      toast.success(success);
    });
    
  } catch (err) {
    toast.error(failure);
  }
};

export const handleToDayTransactionExport = (fromDate,success,failure) => async (dispatch) => {
  try {
    axios({
      url: JAVA_API_EXPORT_TODAY_TRANSACTION + '?fromDate=' + fromDate,
      method: 'get',
      params: {},
      responseType: 'blob',
    }).then(function(response) {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
      const aEle = document.createElement('a');
      const href = window.URL.createObjectURL(blob);
      aEle.href = href;
      aEle.download = 'TodayTransactions.xlsx';
      document.body.appendChild(aEle);
      aEle.click();
      document.body.removeChild(aEle);
      window.URL.revokeObjectURL(href);
    toast.success(success);
    });

  } catch (err) {
    toast.error(failure);
  }
};
export const handleExceptionTransactionExport = (success,failure) => async (dispatch) => {
  try {
    axios({
      url: JAVA_API_EXPORT_EXCEPTION_TRANSACTION,
      method: 'get',
      params: {},
      responseType: 'blob',
    }).then(function(response) {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
      const aEle = document.createElement('a');
      const href = window.URL.createObjectURL(blob);
      aEle.href = href;
      aEle.download = 'ExceptionTransactions.xlsx';
      document.body.appendChild(aEle);
      aEle.click();
      document.body.removeChild(aEle);
      window.URL.revokeObjectURL(href);
      toast.success(success);
    });
    
  } catch (err) {
    toast.error(failure);
  }
};
