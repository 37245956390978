import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import CommonUtil from '../Util/CommonUtils';
import { Typography, Grid, Card, CardContent } from '@material-ui/core';
import site_Img from '../../assets/images/dashboard-icons/site.svg';
import device_Img from '../../assets/images/dashboard-icons/device.svg';
import door_Img from '../../assets/images/dashboard-icons/door.svg';
import employees_Img from '../../assets/images/dashboard-icons/employee.svg';
import events_Img from '../../assets/images/dashboard-icons/event.svg';
import SiteDashboardTiles from './SiteDashboardTiles';
import SiteDashboard from '../../pages/SiteDashboard/SiteDashboard';
import { getSiteEventCount } from '../../actions/DashBoardActions';
import { getSiteDeviceCount } from '../../actions/DashBoardActions';
import { getSiteDoorCount } from '../../actions/DashBoardActions';
import { getSiteEmployeeCount } from '../../actions/DashBoardActions';
import { REACT_URL_DEVICE_LIST, REACT_URL_DOOR, REACT_URL_SITE, REACT_URL_TRANSACTIONS } from '../../actions/EndPoints';
import { REACT_URI_PERSON } from '../../actions/organization/EndPoints';
import CommonUtil from '../../components/Util/CommonUtils';

function SiteDashboardCards(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const store = useSelector((state) => state);

  useEffect(() => {
    // alert(JSON.stringify(store.siteDashBoard))
    dispatch(getSiteEventCount(props.id));
    dispatch(getSiteDeviceCount(props.id));
    dispatch(getSiteDoorCount(props.id));
    dispatch(getSiteEmployeeCount(props.id));
  }, []);

  const onSelectSite = () => {
    history.push({ pathname: REACT_URL_SITE });
  };

  const onSelectDevice = () => {
    history.push({ pathname: REACT_URL_DEVICE_LIST });
  };

  const onSelectDoor = () => {
    history.push({ pathname: REACT_URL_DOOR });
  };

  const onSelectEmployee = () => {
    history.push({ pathname: REACT_URI_PERSON });
  };
  const onSelectEvent = () => {
    history.push({ pathname: REACT_URL_TRANSACTIONS });
  };

  return (
    <>
      <Grid container spacing={4}>
        {CommonUtil.isEmptyString(props.id) && (
          <SiteDashboardTiles minWidth={CommonUtil.isEmptyString(props.id) ? '20%' : '25%'}>
            <Card className='card mb-4' onClick={() => onSelectSite()}>
              <CardContent className='p-4'>
                <div className='align-box-row align-items-start'>
                  <div className='font-weight-bold'>
                    <Typography gutterBottom variant='h5' component='h2' color='textSecondary'>
                      {t('COMMON089')}
                    </Typography>
                    <span className='font-size-xxl mt-2' style={{ color: '#3D4977', fontSize: '30px' }}>
                      {CommonUtil.isEmptyString(store.site.siteList.totalCount)?0:store.site.siteList.totalCount}
                    </span>
                  </div>
                  <div className='ml-auto'>
                    <img alt='...' className='img-fluid' src={site_Img} />
                  </div>
                </div>
              </CardContent>
            </Card>
          </SiteDashboardTiles>
        )}
        <SiteDashboardTiles minWidth={CommonUtil.isEmptyString(props.id) ? '20%' : '25%'}>
          <Card className='card mb-4' onClick={() => onSelectDevice()}>
            <CardContent className='p-4'>
              <div className='align-box-row align-items-start'>
                <div className='font-weight-bold'>
                  <Typography gutterBottom variant='h5' component='h2' color='textSecondary'>
                    {t('COMMON090')}
                  </Typography>
                  <span className='font-size-xxl mt-2' style={{ color: '#3D4977', fontSize: '30px' }}>
                    {store.siteDashBoard.sitedeviceCount ? store.siteDashBoard.sitedeviceCount.data.deviceCount : 0}
                  </span>
                </div>
                <div className='ml-auto'>
                  <img alt='...' className='img-fluid' src={device_Img} />
                </div>
              </div>
            </CardContent>
          </Card>
        </SiteDashboardTiles>
        <SiteDashboardTiles minWidth={CommonUtil.isEmptyString(props.id) ? '20%' : '25%'}>
          <Card className='card mb-4' onClick={() => onSelectDoor()}>
            <CardContent className='p-4'>
              <div className='align-box-row align-items-start'>
                <div className='font-weight-bold'>
                  <Typography gutterBottom variant='h5' component='h2' color='textSecondary'>
                    {t('DOORI000')}
                  </Typography>
                  <span className='font-size-xxl mt-2' style={{ color: '#3D4977', fontSize: '30px' }}>
                    {store.siteDashBoard.sitedoorCount ? store.siteDashBoard.sitedoorCount.data.doorCount : 0}
                  </span>
                </div>
                <div className='ml-auto'>
                  <img alt='...' className='img-fluid' src={door_Img} />
                </div>
              </div>
            </CardContent>
          </Card>
        </SiteDashboardTiles>
        <SiteDashboardTiles minWidth={CommonUtil.isEmptyString(props.id) ? '20%' : '25%'}>
          <Card className='card mb-4' onClick={() => onSelectEmployee()}>
            <CardContent className='p-4'>
              <div className='align-box-row align-items-start'>
                <div className='font-weight-bold'>
                  <Typography gutterBottom variant='h5' component='h2' color='textSecondary'>
                    {t('COMMON091')}
                  </Typography>
                  <span className='font-size-xxl mt-2' style={{ color: '#3D4977', fontSize: '30px' }}>
                    {store.siteDashBoard.siteemployeeCount ? store.siteDashBoard.siteemployeeCount.data : 0}
                  </span>
                </div>
                <div className='ml-auto'>
                  <img alt='...' className='img-fluid' src={employees_Img} />
                </div>
              </div>
            </CardContent>
          </Card>
        </SiteDashboardTiles>
        <SiteDashboardTiles minWidth={CommonUtil.isEmptyString(props.id) ? '20%' : '25%'}>
          <Card className='card mb-4' onClick={() => onSelectEvent()}>
            <CardContent className='p-4'>
              <div className='align-box-row align-items-start'>
                <div className='font-weight-bold'>
                  <Typography gutterBottom variant='h5' component='h2' color='textSecondary'>
                    {t('OPE027')}
                  </Typography>
                  <span className='font-size-xxl mt-2' style={{ color: '#3D4977', fontSize: '30px' }}>
                    {store.siteDashBoard.siteeventCount ? store.siteDashBoard.siteeventCount.data.eventCount : 0}
                  </span>
                </div>
                <div className='ml-auto'>
                  <img alt='...' className='img-fluid' src={events_Img} />
                </div>
              </div>
            </CardContent>
          </Card>
        </SiteDashboardTiles>
      </Grid>
    </>
  );
}
export default SiteDashboardCards;
