import React from 'react';
import { Box, Button, Grid, IconButton, Tooltip, Typography, FormControl, InputLabel, MenuItem, Select, Menu } from '@mui/material';

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import SettingsIcon from '@material-ui/icons/Settings';
import { useTranslation } from 'react-i18next';
import SetTimeZone from './SetTimeZone';
import SetRegistrationDevice from './SetRegistrationDevice';
import SetInOutStatus from './SetInOutStatus';
import ModifyFingerPrintThreshold from './ModifyFingerPrintThreshold';
import { ReactComponent as Setup } from '../../assets/icons/SetupIcon.svg';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { JAVA_URL_DEVICE_ROLE } from '../../actions/EndPoints';

export default function DeviceSetupOperations(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleDevicePersonRole = () => {
    if (props.devices.length < 1) {
      toast.warning(t('DEVI0014'));
      return;
    }

    if (props.devices.length > 1) {
      toast.warning(t('DEVI0039'));
      return;
    }

    history.push({ pathname: JAVA_URL_DEVICE_ROLE, state: { ...props.devices[0] } });
  };

  return (
    <>
      <PopupState variant='popover' popupId='demo-popup-menu'>
        {(popupState) => (
          <React.Fragment>
            <Tooltip title={t('DEVI0036')}>
              <IconButton color='secondary' aria-label={t('DEVI0036')} {...bindTrigger(popupState)}>
                <Setup style={{ height: 30, width: 30 }} />
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)}>
              <SetTimeZone {...props} popupState={popupState} />
              <SetRegistrationDevice {...props} popupState={popupState} />
              <SetInOutStatus {...props} popupState={popupState} />
              <ModifyFingerPrintThreshold {...props} popupState={popupState} />
              <MenuItem
                onClick={() => {
                  handleDevicePersonRole();
                }}
              >
                {t('DEVI0075')}
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </>
  );
}
