import axios from "axios";
import { JAVA_API_EMPLOYEE, JAVA_API_EMPLOYEE_SEARCH, JAVA_API_DELETE_EMPLOYEE} from "./ApiEndPoints";
import { EMPLOYEE_LIST } from "../actions/types";
import { JAVA_API_GET_EMPLOYEE } from "./ApiEndPoints";
import { toast } from "react-toastify";
import { JAVA_API_FILTER_EMP_V2, JAVA_API_PERSON } from "./organization/ApiEndPoints";

// Add and Update Employee
export const updateEmployee = (payload, callBack) => async (dispatch) => {
    try {
      let isAddMode = payload.id === '' ? true : false;
      let res = '';
      if (isAddMode) {
        res = await axios.post(JAVA_API_EMPLOYEE, [payload]);
      } else {
        res = await axios.put(JAVA_API_EMPLOYEE + '/' + payload.id, payload);
      }
      callBack(res.data);
    } catch {}
};

// Get Employees
export const getEmployees = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
    try {
      const res = await axios.get(JAVA_API_EMPLOYEE + '?pageNumber=' + (pageNumber+1) + '&pageSize=' + pageSize + '&isPageable=' + isPageable);
      //const res = await axios.get('http://localhost:9000/api/v2.0/employee' + '?pageNumber=' + (pageNumber+1) + '&pageSize=' + pageSize + '&isPageable=' + isPageable);
      //if (res.status === 200 && res.data.code === 'CASI00214') {
      if (res.status === 200 && res.data.code === 'OMSI0000') {
        if (isPageable) {
          dispatch({
            type: EMPLOYEE_LIST,
            payload: res.data.data,
          });
        } else {
          callBack(res.data.data);
        }
      } else {
        if (isPageable) {
          dispatch({
            type: EMPLOYEE_LIST,
            payload: [],
          });
        }
      }
    } catch {};
};

export const employeeList = (callBack) => async (dispatch) => {
  try{
    const res = await axios.post(JAVA_API_FILTER_EMP_V2 + '?pageNumber=' + 1 + '&pageSize=' + 0);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
    callBack(res.data)
  }
  else {
    //toast.error(res.data.message)
  }

  }
  catch {}

};

// Remove Employee
export const deleteEmployee = (ids, callBack) => async (dispatch) => {
  try {
    const deleteObj = {};
    deleteObj.idsOrCodes = ids;
    deleteObj.deleteChild = false;

    const res = await axios.delete(JAVA_API_DELETE_EMPLOYEE, { data: deleteObj });
    callBack(res.data);
  } catch {}
};

// Filter Employee
export const getFilterEmployees = (pageNumber, pageSize, filter) => async dispatch => {
    try {
        const res = await axios.get(JAVA_API_EMPLOYEE_SEARCH + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + buildQuery(filter));
        if (res.status === 200 && res.data.code === "OMSI0000") {
            dispatch({
                type: EMPLOYEE_LIST,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: EMPLOYEE_LIST,
                payload: []
            });
        }
    } catch {

    }
};

function buildQuery(filter) {
	let query = '';
	if (filter.name) {
		query = `&name=${filter.name}`;
	}
	if (filter.code) {
		query = `&code=${filter.code}`;
	}
  if (filter.parentDepartment) {
		query = `&parentDepartment=${filter.parentDepartment}`;
	}
	return query;
}
