import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Box, Typography, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SubmitButton from '../../components/SubmitButton';
import CommonUtil from '../../components/Util/CommonUtils';
import { toast } from 'react-toastify';
import { getDepartments } from '../../actions/organization/DepartmentApiCalls';
import { getDesignations } from '../../actions/organization/DesignationApiCalls';
import { DialogActions } from '@material-ui/core';
import CancelButton from '../../components/CancelButton';
import { REACT_URI_PERSON } from '../../actions/organization/EndPoints';
import { updatePerson } from '../../actions/organization/PersonApiCalls';
import { useHistory } from 'react-router';
import { borderRadius } from '@material-ui/system';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FileBase64 from 'react-file-base64';
import defaultProfile from '../../assets/images/defaultProfile.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MobileInput from '../../components/MobileInput';
import {getSites} from '../../actions/organization/SiteApiCalls';


const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function EditPerson(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      marginTop: '16px',
    },
  }));
  const [countryCode, setCountryCode] = useState(
    CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : ''
  );
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [departments, setDepartments] = React.useState([]);
  const [designations, setDesignations] = React.useState([]);
  const[site,setSite] = React.useState([])
  const [error, setError] = React.useState({});
  let history = useHistory();
  const [profile, setProfile] = React.useState(defaultProfile);
  const [file, setFile] = React.useState({});


  const [state, setState] = React.useState({
    id: props.selectedObject.id,
    firstName: props.selectedObject.firstName,
    lastName: props.selectedObject.lastName,
    email: props.selectedObject.email,
    phone: props.selectedObject.phone,
    employeeCode: props.selectedObject.code,
    joinDate: props.selectedObject.joinDate,
    gender: props.selectedObject.gender,
    departmentId: props.selectedObject.departmentId,
    designationId: props.selectedObject.designationId,
    siteId:props.selectedObject.siteId,
    profilePhoto: props.selectedObject.profilePhoto,
    createUser: false,
  });

  useEffect(() => {
    if (!CommonUtil.isEmpty(props.selectedObject)) {
      if (!CommonUtil.isEmptyString(props.selectedObject.profilePhoto)) {
        setProfile('data:image/jpeg;base64,' + props.selectedObject.profilePhoto);
      }
    }
  }, [props.selectedObject]);
  useEffect(() => {
    if (!CommonUtil.isEmptyString(state.phone)) {
      if (state.phone.includes('-')) {
        setCountryCode(state.phone.split('-')[0]);
        setState({ ...state, phone: state.phone.split('-')[1] });
      }
    }
  }, [state.phone]);
  
  const handleClose = () => {
    history.push({
      pathname: '/acc/person',
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleProfile = (file) => {
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      let fileSize = file.size.split(' ')[0];
      let size = parseInt(fileSize, 10);
      if (size <= 2000) {
        setFile(file);
        setState({
          ...state,
          profilePhoto: file.base64.split(',')[1],
        });
        setProfile(file.base64);
      } else {
        //setFile('');
        // setProfile(state.profilePhoto);
        toast.error(t('REGISTRATIONFORM001'));
      }
    } else {
      // setFile('');
      // setProfile(state.profilePhoto);
      toast.error(t('REGISTRATIONFORM002'));
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = (event) => {

    var data = {};
    if (!CommonUtil.isEmptyString(state.phone)) {
      var phone = state.phone + countryCode.replace('+', '');
      if (phone.length < 8 || phone.length > 23) {
        setError({ ...error, phone: true });
        toast.error(t('COMPPROFSCREEN003'));
        return;
      } else {
        let finalcountryCode = CommonUtil.isEmptyString(countryCode) ? CommonUtil.getDefaultCallingCountryCode() : countryCode;
        data = { ...state, phone: CommonUtil.isEmptyString(state.phone) ? '' : '+' + finalcountryCode.replace('+', '') + '-' + state.phone };
      }
    } else {
      data = { ...state };
      setCountryCode(CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : '');
    }
    event.preventDefault();
    if (isFormValid(state)) {
      dispatch(
        updatePerson(data, (data) => {
          handleError(data);
        })
      );
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.firstName)) {
      setError({ firstName: true });
      toast.error(t('PERSON016'));
      return false;
    }
    if (!CommonUtil.isValidName(state.firstName)) {
      setError({ firstName: true });
      toast.error(t('PERSON017'));
      return false;
    }

    if (!CommonUtil.isValidName(state.lastName)) {
      setError({ lastName: true });
      toast.error(t('PERSON018'));
      return false;
    }

    // if (CommonUtil.isEmptyString(state.email) && CommonUtil.isEmptyString(state.phone)) {
    //   setError({ email: true });
    //   toast.error(t('PERSON021'));
    //   return false;
    // }

    if (!CommonUtil.isValidEmail(state.email)) {
      setError({ email: true });
      toast.error(t('PERSON022'));
      return false;
    }

    // if (!CommonUtil.isValidMobile(state.phone)) {
    //   setError({ phone: true });
    //   toast.error(t('PERSON023'));
    //   return false;
    // }

    if (CommonUtil.isEmptyString(state.employeeCode)) {
      setError({ employeeCode: true });
      toast.error(t('PERSON019'));
      return false;
    }

    if (!CommonUtil.isValidCode(state.employeeCode)) {
      setError({ employeeCode: true });
      toast.error(t('PERSON020'));
      return false;
    }

    if (CommonUtil.isEmptyString(state.gender)) {
      setError({ gender: true });
      toast.error(t('PERSON025'));
      return false;
    }
    return true;
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'OMSI0006':
        history.push(REACT_URI_PERSON);
        break;
      case 'OMSE0097':
        setError({ employeeCode: true });
        break;
      case 'OMSE0184':
        setError({ email: true });
        break;
      case 'OMSE0213':
        setError({ phone: true });
        break;
      case 'OMSE0214':
        setError({ phone: true });
        break;
      case 'OMSE0160':
        setError({ email: true });
        break;
      default:
        break;
    }

    if (data.code === 'OMSI0006' || data.code === 'OMSI0007') {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
    }
  };

  useEffect(() => {
    dispatch(
      getDepartments(0, 0, false, (data) => {
        setDepartments(data.department);
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getDesignations(0, 0, false, (data) => {
        setDesignations(data.designation);
      })
    );
  }, []);


  useEffect(()=>{
    dispatch(
      getSites(0,0,false,(data)=>{
        setSite(data.site)     
      })
    )
  },[])


  return (
    <>
      <form noValidate autoComplete='off'>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid container>
            <Grid container justify='center'>
              <Grid item xs={12}>
                <div className='d-flex align-items-center justify-content-center'>
                  <Avatar alt='' src={profile} className={classes.large} />
                </div>
              </Grid>
              {!props.selectedObject.disable && (
                <Grid item xs={12}>
                  <div className='d-flex align-items-center justify-content-center'>
                    <IconButton variant='contained' component='label' color='secondary'>
                      <PhotoCameraIcon />
                      <div style={{ display: 'none' }}>
                        <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
                      </div>
                    </IconButton>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} spacing={4}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                fullWidth
                required
                error={error.firstName}
                name='firstName'
                label={t('PERSON003')}
                variant='outlined'
                size='small'
                defaultValue={state.firstName}
                onChange={handleChange}
                inputProps={{
                  maxlength: 100,
                  disabled: props.selectedObject.disable,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                fullWidth
                error={error.lastName}
                name='lastName'
                label={t('PERSON004')}
                variant='outlined'
                size='small'
                defaultValue={state.lastName}
                onChange={handleChange}
                inputProps={{
                  maxlength: 25,
                  disabled: props.selectedObject.disable,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                fullWidth
                error={error.email}
                name='email'
                label={t('PERSON005')}
                variant='outlined'
                size='small'
                defaultValue={state.email}
                onChange={handleChange}
                inputProps={{
                  disabled: props.selectedObject.disable,
                }}
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
            <div style={{position:'absolute',left:'40px',zIndex:2,top:'9px',backgroundColor:'white',color:'black',fontSize:10,padding:'0px 4px'}} >{t('PERSON006')} *</div>
              <PhoneInput
                country={'in'}
                value={state.phone}
                countryCodeEditable={false}
                enableSearch='true'
                onChange={(phone) => setState({ ...state, phone: phone })}
                autoFocus={true}
                searchPlaceholder={t('search')}
                searchNotFound={t('COMMON165')}
              />
            </Box>
          </Grid> */}
          <MobileInput
            error={error.phone}
            countryCode={countryCode}
            phone={state.phone}
            editMode={props.selectedObject.disable ? false : true}
            onMobileNoChange={(phone) => {
              setError({ phone: false });
              setState({ ...state, phone: phone });
            }}
            onCounryCodeChange={(code) => setCountryCode(code)}
          />
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                autoComplete='off'
                fullWidth
                required
                error={error.employeeCode}
                name='employeeCode'
                label={t('PERSON008')}
                variant='outlined'
                size='small'
                defaultValue={state.employeeCode}
                onChange={handleChange}
                inputProps={{
                  maxlength: 20,
                  disabled: props.selectedObject.disable,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth required>
                <InputLabel>{t('PERSON007')}</InputLabel>
                <Select
                  fullWidth
                  error={error.gender}
                  name='gender'
                  label={t('PERSON007')}
                  variant='outlined'
                  size='small'
                  value={state.gender}
                  defaultValue={state.gender}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  inputProps={{
                    disabled: props.selectedObject.disable,
                  }}
                >
                  <MenuItem value={'M'}>{t('male')}</MenuItem>
                  <MenuItem value={'F'}>{t('female')}</MenuItem>
                  <MenuItem value={'O'}>{t('others')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>{t('PERSON010')}</InputLabel>
                <Select
                  fullWidth
                  error={error.departmentId}
                  name='departmentId'
                  label={t('PERSON010')}
                  variant='outlined'
                  size='small'
                  value={state.departmentId}
                  defaultValue={state.departmentId}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  inputProps={{
                    disabled: props.selectedObject.disable,
                  }}
                >
                  <MenuItem value='' selected>
                    <em>{t('none')}</em>
                  </MenuItem>
                  {departments ? (
                    departments.map((data, index) => (
                      <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                        <Typography variant='inherit' noWrap>
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>{t('PERSON011')}</InputLabel>
                <Select
                  fullWidth
                  error={error.designationId}
                  name='designationId'
                  label={t('PERSON011')}
                  variant='outlined'
                  size='small'
                  value={state.designationId}
                  defaultValue={state.designationId}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  inputProps={{
                    disabled: props.selectedObject.disable,
                  }}
                >
                  <MenuItem value='' selected>
                    <em>{t('none')}</em>
                  </MenuItem>
                  {designations ? (
                    designations.map((data, index) => (
                      <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                        <Typography variant='inherit' noWrap>
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            
              <FormControl variant='outlined' size='small' fullWidth style={{left:'17px', width:'96%'}}>
                <InputLabel>{t('SITE001')}</InputLabel>
                <Select
                  fullWidth
                 error={error.siteId}
                  name='siteId'
                  label={t('SITE001')}
                  variant='outlined'
                  size='small'
                  value={state.siteId}
                  defaultValue={state.siteId}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  inputProps={{
                    disabled: props.selectedObject.disable,
                  }}
                >
                  <MenuItem value='' selected>
                    <em>{t('none')}</em>
                  </MenuItem>
                  {site ? (
                    site.map((data, index) => (
                      <MenuItem key={index} value={data.id}>
                        <Typography variant='inherit' noWrap>
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
         
          </Grid>

       
        <Grid item xs={12} sm={6}>
          <DialogActions>
            <SubmitButton onClick={handleSubmit} style={{ display: props.selectedObject.disable ? 'none' : '' }} color='primary' variant='contained' size='small' className='m-2'>
              {t('COMMON035')}
            </SubmitButton>
            <CancelButton color='primary' onClick={handleClose} variant='contained' size='small' className='m-2'>
              {t('COMMON008')}
            </CancelButton>
          </DialogActions>
        </Grid>
        </Grid >
      </form>
    </>
  );
}
