import { Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import { REACT_URL_OPERATION_LOG } from '../../actions/EndPoints';
import TransactionTabs from './TransactionTabs';

export default function OperationLog() {

    const { t } = useTranslation();

    const breadcrumb = [
        //{ path: REACT_URL_OPERATION_LOG, name: t("OPE002") },
        { path: REACT_URL_OPERATION_LOG, name: t("OPE001") }
    ]
    
    return (
        <Grid>
            <Breadcrumb links={breadcrumb} />
            <TransactionTabs/>
        </Grid>
    )
} 