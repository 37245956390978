import { Box, Grid, Dialog, DialogTitle, TextField, Button, Tooltip, IconButton } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import CommonUtil from '../../components/Util/CommonUtils';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { JAVA_URL_DOOR_STATUS } from '../../actions/EndPoints';
import Axios from 'axios';
//import { handleRemoteOpen } from '../../actions/DoorOperationsActions';
import { handleRemoteOpen, handleDoorOperations } from '../../actions/DoorApiCalls';
import { ReactComponent as RemoteOpening } from '../../assets/icons/RemoteOpening.svg';

export default function RemoteOpenDoor(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [doorsStatus, setDoorsStatus] = React.useState(props.popupState);
  // eslint-disable-next-line
  const [selecteddoors, setSelecteddoor] = React.useState();
  const [selectedDevices, setSelectedDevices] = React.useState();
  const [state, setState] = React.useState({
    openInterval: '5',
  });
  const [error, setError] = React.useState({
    openInterval: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    setError({
      ...error,
      [event.target.name]: false,
    });
  };

  const doorOperations = () => {
    if (props.doors.length < 1) {
      toast.warning(t('DEVI0014'));
      handleClose();
      return;
    }

    setOpen(true);

    var ids = '';
    var devices = '';
    const { doors } = props;
    // eslint-disable-next-line
    doors.map((door, index) => {
      if (index === doors.length - 1) {
        ids = ids + door.id;
        devices = devices + door.deviceId;
      } else {
        devices = devices + door.deviceId + ',';
        ids = ids + door.id + ',';
      }
    });

    setSelecteddoor(ids);
    setSelectedDevices(devices);

    try {
      Axios.get(JAVA_URL_DOOR_STATUS.replace('{doorIds}', ids) + '?type=openDoor').then((res) => {
        setDoorsStatus(res.data.data);
      });
    } catch (error) {
      toast.error(t('COMMON028'));
      setOpen(false);
    }

    // confirmAlert({
    //     customUI: ({ onClose }) => {
    //         return (
    //             <ConfirmModal open={true} text={confirmMessage} onConfirm={() => { dispatch(performdoorOperations(ids, operation)); onClose() }} onClose={() => onClose()} />
    //         );
    //     }
    // });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.openInterval)) {
      setError({
        openInterval: true,
      });
      toast.error(t('DEVI0063'));
      return;
    } else if (state.openInterval < 0) {
      setError({ ...error, code: true });
      toast.error(t('DEVI0062'));
      return;
    } else if (state.openInterval > 254) {
      setError({
        openInterval: true,
      });
      toast.error(t('DEVI0062'));
      return;
    }

    dispatch(handleRemoteOpen(state.openInterval, 'openDoor', selecteddoors)).then((res) => {
      setOpen(false);
    });
  };

  return (
    <>
      {/* <MenuItem onClick={() => doorOperations()} >{t("DEVI0049")}</MenuItem> */}

      {/* <Button
                onClick={() => doorOperations()}
                color="secondary"
                size="small"
                startIcon={<OpenInNewIcon className="refresh-btn" />}
                className="acc-list-action-btn">
                {t("DOORI023")}
            </Button> */}

      <Tooltip title={t('DOORI023')}>
        <IconButton color='secondary' aria-label={t('DOORI023')} onClick={() => doorOperations()}>
          <RemoteOpening style={{ height: 30, width: 30 }} />
        </IconButton>
      </Tooltip>

      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-filter-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('DOORI023')}</Box>
            <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container justify='center' alignItems='center'>
            <Grid item xs={12}>
              <Box display='flex'>
                <p>
                  <b className='acc-head'>{t('COMMON166')}</b>
                </p>
              </Box>
              {doorsStatus && doorsStatus.doorsName && (
                <>
                  <Box display='flex' flexDirection='row'>
                    <Box bgcolor='grey.300'>
                      <Box display='flex'>
                        <p>
                          <b className='acc-head' style={{ color: 'green' }}>
                            {doorsStatus.doorsName}
                          </b>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              {doorsStatus && doorsStatus.disabledDoorsName && (
                <>
                  <Box display='flex' flexDirection='row'>
                    <Box bgcolor='grey.300'>
                      <Box display='flex'>
                        <p>
                          <b className='acc-head' style={{ color: 'red' }}>
                            {t('DOORI019')}
                          </b>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                  <Box display='flex' flexDirection='row'>
                    <Box bgcolor='grey.300'>
                      <Box display='flex'>
                        <p>
                          <b style={{ color: 'red' }}>{doorsStatus.disabledDoorsName}</b>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              {doorsStatus && doorsStatus.offlineDoorsName && (
                <>
                  <Box display='flex' flexDirection='row'>
                    <Box bgcolor='grey.300'>
                      <Box display='flex'>
                        <p>
                          <b className='acc-head' style={{ color: 'red' }}>
                            {t('DOORI018')}
                          </b>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                  <Box display='flex' flexDirection='row'>
                    <Box bgcolor='grey.300'>
                      <Box display='flex'>
                        <p>
                          <b style={{ color: 'red' }}>{doorsStatus.offlineDoorsName}</b>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center' style={{ border: '1px solid #00000026', marginTop: '8px' }}>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.openInterval}
                    name='openInterval'
                    label={t('DOORI022') + ' *'}
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    value={state.openInterval}
                    defaultValue={state.openInterval}
                    maxWidth={3}
                    type='number'
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {doorsStatus && doorsStatus.doorsName && (
            <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
              {t('DOORI023')}
            </SubmitButton>
          )}
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
