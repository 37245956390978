import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getDoors } from '../../actions/DoorApiCalls';
import EditIcon from '@material-ui/icons/Edit';
import { REACT_URL_DOOR } from '../../actions/EndPoints';
import Headerbar from '../../components/HeaderBar';
import EditDoor from '../door/EditDoor';
import FilterDoor from './FilterDoor';
import CancelAlarmDialog from './CancelAlarmDialog';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmModal from '../../components/ConfirmModal';
import { handleEnableDoor, handleDisableDoor } from '../../actions/DoorOperationsActions';
import { toast } from 'react-toastify';
import RemoteOpenDoor from './RemoteOpenDoor';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import DoorOperations from './DoorOperations';
import CommonUtil from '../../components/Util/CommonUtils';
import { ReactComponent as RemoteClose } from '../../assets/icons/RemoteClose.svg';
import { ReactComponent as CancelAlarm } from '../../assets/icons/CancelAlarm.svg';
import { ReactComponent as Enable } from '../../assets/icons/Enable.svg';
import { ReactComponent as Disable } from '../../assets/icons/Disable.svg';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CachedIcon from '@material-ui/icons/Cached';

export default function Door() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [doors, setDoors] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateDoor, setUpdateDoor] = useState({});

  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: 5,
    sn: '',
    doorName: '',
  });

  // useEffect(() => {
  //   loadDoors();
  //   // eslint-disable-next-line
  // }, []);

  const [filter, setFilter] = useState({
    sn: '',
    doorName: '',
  });

  const defaultFilter = {
    sn: '',
    doorName: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      sn: filterData.sn,
      doorName: filterData.doorName,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadDoors(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    onSelectionChange([]);
    if (!CommonUtil.isEmpty(store.door.doors)) {
      setTotalRecords(store.door.doors.totalConunt);
      setPage(store.door.doors.curPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setDoors(store.door.doors.doors);
    // eslint-disable-next-line
  }, [store.door.doors]);

  const loadDoors = (page, rowsPerPage) => {
    dispatch(getDoors(filter.sn, filter.doorName, page + 1, rowsPerPage));
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadDoors(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadDoors(0, pageSize);
  };

  const breadcrumb = [
    //{ path: REACT_URL_DOOR, name: t('DEVI0013') },
    { path: REACT_URL_DOOR, name: t('DOORI001') },
  ];

  const columns = [
    {
      field: 'name',
      title: t('COMMON014'),
      tooltip: t('DOORI003'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    // { field: 'zoneName', title: t("zoneName"), editable: 'never' },
    { field: 'deviceAlias', title: t('DEVI0001'), tooltip: t('DEVI0002'), editable: 'never' },
    { field: 'deviceSn', title: t('COMMON055'), tooltip: t('DEVI0003'), editable: 'never' },
    { field: 'doorNo', title: t('COMMON060'), tooltip: t('DOORI002'), editable: 'never' },
    {
      field: 'enabled',
      title: t('DEVI0018'),
      editable: 'never',
      render: (rowData) =>
        rowData.enabled ? <DoneIcon style={{ color: 'green' }} className='refresh-btn' /> : <NotInterestedIcon style={{ color: 'red' }} className='refresh-btn' />,
    },
    { field: 'activeTimeSegName', title: t('timePeriodTitle'), tooltip: t('DOORI004'), editable: 'never', hide: true },
    { field: 'verifyModeName', title: t('DOORI005'), tooltip: t('DOORI030'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <EditOutlinedIcon color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateDoor(rowData);
        setOpenEditDialog(true);
      },
    },
  ];

  const handleDoorEnableDisable = (operation) => {
    if (selectedData.length < 1) {
      toast.warning(t('COMMON029'));
      return;
    }

    var ids = [];
    // eslint-disable-next-line
    selectedData.map((door, index) => {
      ids.push(door.id);
    });

    const accDoorListDTO = {
      doorListID: ids,
    };
    const DOORI031=t('DOORI031');
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={operation === 'enable' ? t('DOORI020') : t('DOORI021')}
            onConfirm={() => {
              if (operation === 'enable') {
                dispatch(handleEnableDoor(accDoorListDTO,DOORI031)).then((res) => {
                  onClose();
                  loadDoors(page, rowsPerPage);
                });
              } else {
                dispatch(handleDisableDoor(accDoorListDTO,DOORI031)).then((res) => {
                  onClose();
                  loadDoors(page, rowsPerPage);
                });
              }
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage,
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb} />
      {/* <Headerbar title={t('DOORI001')} /> */}
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon style={{ height: 30, width: 30 }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1}>
          <RemoteOpenDoor doors={selectedData} />
        </Box>
        <Box p={1}>
          <CancelAlarmDialog icon={<RemoteClose style={{ height: 30, width: 30 }} />} doors={selectedData} operation={'closeDoor'} title={t('DOORI024')} />
        </Box>
        <Box p={1}>
          <Tooltip title={t('DEVI0018')}>
            <IconButton color='secondary' aria-label={t('DEVI0018')} onClick={() => handleDoorEnableDisable('enable')}>
              <Enable style={{ height: 30, width: 30 }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1}>
          <Tooltip title={t('DEVI0019')}>
            <IconButton color='secondary' aria-label={t('DEVI0019')} onClick={() => handleDoorEnableDisable('disable')}>
              <Disable style={{ height: 30, width: 30 }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1}>
          <CancelAlarmDialog icon={<CancelAlarm style={{ height: 30, width: 30 }} />} doors={selectedData} operation={'cancelAlarm'} title={t('DOORI016')} />
        </Box>
        <Box p={1} flexGrow={1}>
          <DoorOperations doors={selectedData} />
        </Box>
        <Grid container justify='flex-end' style={{ padding: '12px' }}>
          <FilterDoor page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Grid>
      </Toolbar>

      <DataTable
        columns={columns}
        data={doors}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <EditDoor
          door={updateDoor}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadDoors(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
