import {Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { JAVA_URL_TIME_ZONE_LIST } from '../../actions/EndPoints';
import DeviceOperationDialog from './DeviceOperationDialog';
import { useDispatch } from 'react-redux';
import { setTimeZones } from '../../actions/DeviceOperationsApiCalls';

export default function SetTimeZone(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [timeZoneDialog, setTimeZoneDialog] = useState(false);
  const [timezones, setTimezones] = React.useState([]);
  // eslint-disable-next-line
  const [popupState, setPopupState] = React.useState(props.popupState);
  const [selectedDevices, setSelectedDevice] = React.useState();
  const [selectedTimezone, setSelectedTimeZone] = React.useState({});
  const [error, setError] = React.useState(false);

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  useEffect(() => {
    if(!props.devices.length<=0){
      setSelectedTimeZone({
        timeZone :props.devices[0].timeZone ,
      });
    }else{
      setSelectedTimeZone({
        timeZone :'',
      });
    }
    
  }, []);

  useEffect(() => {
    loadTimeZones();
  }, []);
  
  const loadTimeZones = () => {
    Axios.get(JAVA_URL_TIME_ZONE_LIST).then((response) => {
      setTimezones(response.data.data);
    });
  };

  const handleChange = (event) => {
  const name = event.target.name;
    setSelectedTimeZone({
      ...selectedTimezone,
      [name]:event.target.value,
    });
    setError(false);
  };

  const deviceOperations = () => {
    if (props.devices.length < 1) {
      toast.warning(t('DEVI0014'));
      handleClose();
      return;
    }

    setTimeZoneDialog(true);

    var ids = '';
    const { devices } = props;
    // eslint-disable-next-line
    devices.map((device, index) => {
      if (index === devices.length - 1) ids = ids + device.id;
      else ids = ids + device.id + ',';
    });

    setSelectedDevice(ids);

    // confirmAlert({
    //     customUI: ({ onClose }) => {
    //         return (
    //             <ConfirmModal open={true} text={confirmMessage} onConfirm={() => { dispatch(performDeviceOperations(ids, operation)); onClose() }} onClose={() => onClose()} />
    //         );
    //     }
    // });
  };

  const handleClose = () => {
    props.onSuccess();
    popupState.close();
    setTimeZoneDialog(false);
  };

  const handleSubmit = () => {
    if (selectedTimezone === '') {
      setError(true);
      toast.error(t('DEVI0042'));
      return;
    }
    dispatch(setTimeZones(selectedDevices, encodeURI(selectedTimezone.timeZone), () => handleClose()));
  };

  return (
    <>
      <MenuItem onClick={() => deviceOperations()}>{t('DEVI0032')}</MenuItem>

      {timeZoneDialog && (
        <DeviceOperationDialog devices={props.devices} header={t('DEVI0032')} open={timeZoneDialog} onClose={() => handleClose()} handleSubmit={() => handleSubmit()}>
          <p>
            <b className='acc-head'>{t('DEVI0038')}</b>
          </p>

          <Box display='flex' justifyContent='center' m={1} p={1}>
            <FormControl variant='outlined' size='small' fullWidth>
              <InputLabel>{t('DEVI0024') + ' *'}</InputLabel>
              <Select error={error} label={t('DEVI0024') + ' *'} name='timeZone' onChange={handleChange} value={selectedTimezone.timeZone} MenuProps={MenuProps}> 
                <MenuItem value='' selected>
                  <em>None</em>
                </MenuItem>
                {Object.entries(timezones).map(([key, val], index) => (
                  <MenuItem key={index} value={val} style={{ maxWidth: '400px' }}>
                    <Typography variant='inherit' noWrap>
                      {key}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DeviceOperationDialog>
      )}
    </>
  );
}
