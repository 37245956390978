import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import PersonIcon from '@material-ui/icons/Person';
import biozlink from '../../assets/images/onboard/access-cloud.png';
import cloudAccess from '../../assets/images/onboard/cloudaccess.png';
import background from '../../assets/images/onboard/background_image.png';
import passwordicon from '../../assets/images/onboard/password_icon.png';
import bio from '../../assets/images/onboard/bio.png';
import email from '../../assets/images/onboard/email_icon.png';
import './custom.css';
import CommonUtil from '../../components/Util/CommonUtils';
import { useHistory, withRouter } from 'react-router-dom';
import setJWTToken from '../../oauth-client/setJWTToken';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getAccessToken, redirectLogin } from '../../oauth-client/OauthClientActions';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { sendResetPwdLink } from '../../actions/ProfileApiCalls';
import LoginLayout from './LoginLayout';
import { forgotPassword } from '../../oauth-client/Onboard';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  label: {
    color: '#999',
    fontSize: '12px',
  },
  labelActive: {
    color: '#4C6A9F',
    fontSize: '12px',
  },
  email_icon: {
    height: '10%',
    width: '15%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
}));

function ForgotPasword(props) {
  const classes = useStyles();
  const [email, setemail] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (prop) => (event) => {
    setemail(event.target.value);
  };

  const history = useHistory();
  const handleSubmit = (event) => {
    if(CommonUtil.isEmptyString(email)){
      toast.error(t('COMMON168'));
    }
    dispatch(
      forgotPassword(
        email,
        (successData) => {
          setSuccess(true);
        },
        (error) => {
          setError(true);
        }
      )
    );
    event.preventDefault();
  };
  const { t } = useTranslation();
  const query = new URLSearchParams(props.location.search);

  return !CommonUtil.isEmptyString(query.get('code')) ? (
    <></>
  ) : (
    <LoginLayout>
      <Grid container spacing={5} alignItems='center'>
        <Grid item xs>
          <Typography align='center'>
            <img src={passwordicon} alt='logo' />
          </Typography>
          <Typography className='signin_title' color='textPrimary' align='center'>
            {success ? t('COMMON141') : t('COMMON137')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} align='center'>
        <Grid item xs>
          {success ? (
            <Typography variant='h5' className={classes.labelActive}>
              {t('COMMON141')}
            </Typography>
          ) : (
            <Typography variant='h5' className={classes.label}>
              {t('COMMON143')}
            </Typography>
          )}
        </Grid>
      </Grid>
      {!success ? (
        <form className={classes.form} noValidate onSubmit={handleSubmit} autoComplete='off'>
          <Grid container alignItems='center'>
            <Grid item>
              <PersonIcon className='login_icons' />
            </Grid>
            <Grid item xs>
              <TextField
                error={error}
                className='login_input'
                size='small'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label={t('COMMON042')}
                autoComplete='off'
                autoFocus
                name='email'
                type='text'
                value={email}
                onChange={handleChange('email')}
              />
            </Grid>
          </Grid>

          <Grid container spacing={5} alignItems='center'>
            <Grid item></Grid>
            <Grid item xs>
              <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
                {t('COMMON144')}
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <></>
      )}
      <Grid container alignItems='center'>
        <Grid item xs></Grid>
        <Grid item alignItems='flex-end'>
          <Button color='primary' className='forgot_password' href='/acc/login' size={'small'}>
            {t('COMMON145')}
          </Button>
        </Grid>
      </Grid>
    </LoginLayout>
  );
}
export default withRouter(ForgotPasword);
