import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CancelIcon } from '../../assets/icons/Cancel.svg';
import CommonUtil from '../../components/Util/CommonUtils';
import { getTimesegs } from '../../actions/TimesegApiCalls';
import { addLevel, updateLevel } from '../../actions/LevelApiCalls';
import CancelButton from '../../components/CancelButton';
import SubmitButton from '../../components/SubmitButton';
import { JAVA_URL_SITE_LIST } from '../../actions/EndPoints';
import axios from 'axios';
import { Box, Grid, Typography } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';

export default function AddLevel(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [open, setOpen] = React.useState(props.open);
  // eslint-disable-next-line
  const [data, setData] = React.useState();
  const [timesegs, setTimesegs] = React.useState([]);
  const [sites, setSites] = React.useState([]);
  const [state, setState] = React.useState({
    id: props.level.id,
    name: props.level.name,
    timeSegLevelDTO: props.level.timeSegLevelDTO,
    siteId: props.level.siteId,
  });

  const [error, setError] = React.useState({
    name: false,
    timeSegLevelDTO: false,
    siteId: false,
  });

  

  useEffect(() => {
    setOpen(props.open);
    setData(props.level);
    setState({
      id: props.level.id,
      name: props.level.name,
      timeSegLevelDTO: props.level.timeSegLevelDTO,
      siteId: props.level.siteId,
    });
  }, [props.open, props.level]);

  useEffect(() => {
    dispatch(getTimesegs('', '0', '1000'));
    axios.get(JAVA_URL_SITE_LIST + '?isPageable=false').then((response) => {
      if (response.data.code === 'LMSI0000') setSites(response.data.data.site);
    });
    // eslint-disable-next-line
  }, []);

  

  useEffect(() => {
    setTimesegs(store.timeseg.timesegs.timeSeg);
  }, [store.timeseg.timesegs]);

  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.levelError)) {
      const { levelError } = store.errors;
      switch (levelError.code) {
        case 'CASE0030':
          setError({ name: true });
          break;
        case 'CASE0024':
          setError({ timeSegLevelDTO: true });
          break;
        case 'CASE0025':
          setError({ siteId: true });
          break;
        case 'CASE0101':
          setError({ name: true });
          break;
        case 'CASE0042':
            setError({ name: true });
            break;
        default:
      }
    }
    // eslint-disable-next-line
  }, [store.errors.levelError]);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };
  const isValid=(data)=>{
    if(CommonUtil.isEmpty(data.name)){
      setError({name:true});
      toast.error(t('LEVEL019'));
      return false;
    }
    if(CommonUtil.isEmpty(data.timeSegLevelDTO)){
      setError({timeSegLevelDTO:true});
      toast.error(t('TIMEP028'));
      return false;
    }
    return true;
  };
  const handleSubmit = () => {

    if (CommonUtil.isEmptyString(state.id)) {
      if(isValid(state)){
        dispatch(
          addLevel(state, (data) => {
            handleClose();
          })
        );
      }
      
    } else {
      if(isValid(state)){
      dispatch(
        updateLevel(state, (data) => {
          handleClose();
        })
      );
      }
    }
  };
  
  
  return (
    <div>
      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{CommonUtil.isEmptyString(state.id) ? t('LEVEL002') : t('LEVEL018')}</Box>
            <CancelIcon onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.name}
                    name='name'
                    label={t('COMMON014') + ' *'}
                    helperText=''
                    variant='outlined'
                    size='small'
                    defaultValue={state.name}
                    value={state.name}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('TIMEP001') + ' *'}</InputLabel>
                    <Select
                      labelId='demo-simple-select-outlined-label'
                      id='demo-simple-select-outlined'
                      label={t('TIMEP001') + ' *'}
                      error={error.timeSegLevelDTO}
                      name='timeSegLevelDTO'
                      onChange={handleChange}
                      value={state.timeSegLevelDTO}
                      defaultValue={state.timeSegLevelDTO}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {timesegs ? (
                        timesegs.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('COMMON022')}</InputLabel>
                    <Select
                      labelId='demo-simple-select-outlined-label'
                      id='demo-simple-select-outlined'
                      label={t('COMMON022') + ' *'}
                      error={error.siteId}
                      name='siteId'
                      onChange={handleChange}
                      value={state.siteId}
                      defaultValue={state.siteId}
                    >
                      <MenuItem value='' selected>
                        <em>{t('none')}</em>
                      </MenuItem>
                      {sites ? (
                        sites.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '100%' }}>
                            {data.name}
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
