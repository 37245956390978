import { Box, Fab, Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useHistory, useLocation } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_URL_DEVICE_LIST } from '../../actions/EndPoints';
import Headerbar from '../../components/HeaderBar';
import CustomDefaultDataTable from '../../components/CustomDefaultDataTable';
import CachedIcon from '@material-ui/icons/Cached';
import SiteZoneList from '../../components/device-management/SiteZoneList';
import axios from 'axios';
import CommonUtil from '../../components/Util/CommonUtils';
import SetDevicePersonRole from './SetDevicePersonRole';
import DataTable from '../../components/DataTable';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmModal from '../../components/ConfirmModal';

export default function DevicePersonRole(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const location = useLocation();
  const [selDevice, setSelDevice] = useState({});
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  useEffect(() => {
    if (!CommonUtil.isEmpty(location.state)) loadData(page, rowsPerPage);
  }, []);

  const handleClose = () => {
    setOpen(!open);
    loadData(page, rowsPerPage);
  };

  const loadData = async (pageNumber, pageSize) => {
    pageNumber = pageNumber + 1;
    await axios.get('/api/v2.0/device/getDevicePersonRoles?size=' + pageSize + '&page=' + pageNumber + '&sn=' + location.state.sn)
      .then((response) => {
        console.info(response)
        if (response.data.code === 'CASI0001') {
          setTotalRecords(response.data.data.totalCount);
          setPage(response.data.data.currentPage - 1);
          setData(response.data.data.employees);
        }
        if(response.data===''){
          setData([]);
          setTotalRecords('');
          setPage('');
        }
      });
  };

  const breadcrumb = [
    { path: REACT_URL_DEVICE_LIST, name: t('DEVI0013') },
    { path: REACT_URL_DEVICE_LIST, name: t('DEVI0075') },
    { path: REACT_URL_DEVICE_LIST + localStorage.getItem('companyCode'), name: location.state.sn },
  ];

  const columns = [
    { field: 'prsonFirstName', title: t('COMMON020') },
    { field: 'prsonLastName', title: t('COMMON094') },
    { field: 'prsonPin', title: t('EMP010') },
    { field: 'deviceRole', title: t('MEMBER004'), render: (p) => (p.deviceRole == 14 ? 'Administrator' : 'Enroller') },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: true,
    selection: false,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    pageSize: 10,
  };

  const deletePerson = (person) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              const payload = {
                deviceId: location.state.id,

                sn: location.state.sn,

                personIds: [person.prsonId],

                deviceRole: '0',
              };

              axios.post('/api/v2.0/device/setDeviceRole', payload).then((response) => {
                if (response.data.code === 'CASI7035') {
                  toast.success('Person deleted successfully');
                  loadData(page, rowsPerPage);
                }
                if(response.data.code==='CASW0005'){
                  toast.error(response.data.message);
                }
              });

              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const actions = [
    {
      icon: () => <DeleteOutlineIcon color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deletePerson(rowData);
      },
    },
  ];

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(page, pageSize);
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Tooltip title={t('COMMON009')}>
          <Fab color='secondary' size='small' aria-label={t('COMMON009')} onClick={() => setOpen(!open)}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Breadcrumb>
      <Headerbar title={t('DEVI0075')}></Headerbar>
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => loadData(page, rowsPerPage)}>
              <CachedIcon style={{ height: 30, width: 30 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
      <DataTable
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
        columns={columns}
        data={data}
        actions={actions}
        options={options}
        onSelectedRoles={(rows) => alert(rows)}
      />
      <SetDevicePersonRole open={open} data={location.state} onClose={() => handleClose()} />
    </Grid>
  );
}
