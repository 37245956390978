import { Box, Button, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import DataTable from '../../components/DataTable'
import { useTranslation } from 'react-i18next';
import { ReactComponent as Refresh } from "../../assets/cloudaccess-svg/Refresh.svg";
import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getInterlocks, removeInterlock } from '../../actions/InterlockApiCalls';
import EditIcon from '@material-ui/icons/Edit';
import { REACT_URL_INTERLOCK } from '../../actions/EndPoints';
import Headerbar from '../../components/HeaderBar';
import FilterInterlock from './FilterInterlock';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmModal from '../../components/ConfirmModal';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { ReactComponent as Delete } from "../../assets/cloudaccess-svg/Delete.svg";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddInterlock from './AddInterlock';
import SubmitButton from '../../components/SubmitButton';

export default function Interlock() {

	const dispatch = useDispatch();
	const store = useSelector((state) => state)
	const { t } = useTranslation();
	const [interlocks, setInterlocks] = useState([]);
	const[ selectedInterlocks, onSelectionChange] = useState([]);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	 const [updateObject, setUpdateObject] = useState({});
	const [query, setQuery] = React.useState({
		page: 0,
		pageSize: 5,
		deviceName: ''
	})
	

	useEffect(() => {
		loadData();
		// eslint-disable-next-line
	}, [])
	

	useEffect(() => {
		setInterlocks(store.interlock.interlocks.interlocks);
	}, [store.interlock.interlocks])

	const loadData = () => {
		dispatch(getInterlocks(query.deviceName, query.page, query.pageSize));
	}
	
	

	const deleteInterlock = (data) => {
		if (data.length < 1) {
			toast.warning(t("INTERLOCKI003"));
			return;
		}
		var ids = '';
		// eslint-disable-next-line
		data.map((interlock, index) => {
			if (index === data.length - 1)
				ids = ids + interlock.id;
			else
				ids = ids + interlock.id + ",";
		})
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<ConfirmModal open={true} text={t("INTERLOCKI0015")} onConfirm={() => { dispatch(removeInterlock(ids, loadData)); onClose() }} onClose={() => onClose()} />
				);
			}
		});
	};

	const breadcrumb = [
		{ path: REACT_URL_INTERLOCK, name: t("LEVEL001") },
		{ path: REACT_URL_INTERLOCK, name: t("Interlock001") }
	]

	const columns = [
		{ field: 'deviceName', title: t("DEVI0002"), editable: 'never' },
		{ field: 'interlockRule', title: ("InterlockRule"), editable: 'never' },
	];
	
	 const deleteRecord = (data) => {
        if (data.length < 1) {
            toast.warning(t("COMMON019"));
            return;
        }
        var ids = '';
		// eslint-disable-next-line
        data.map((record, index) => {
            if (index === data.length - 1)
                ids = ids + record.id;
            else
                ids = ids + record.id + ",";
        })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmModal open={true} text={t("COMMON018")} onConfirm={() => { dispatch(removeInterlock(ids)); onClose() }} onClose={() => onClose()} />
                );
            }
        });
    };

	const actions = [
		{
			icon: () => <EditIcon color="secondary" />,
			tooltip: t("COMMON005"),
			position: 'row',
			onClick: (event, rowData) => {
                setUpdateObject(rowData);
                setOpenEditDialog(true)
            }
		},
		{
			icon: () => <DeleteOutlineIcon color="secondary" />,
			tooltip: t("COMMON004"),
			position: 'row',
			onClick: (event, rowData) => {
                deleteRecord([rowData])
            }
		}

	]
	
	// eslint-disable-next-line
	const [state, setState] = React.useState({
        id: '',
       interlockRule: '',
	   deviceId: ''
        
    })
	
	const openAddInterlockDialog = () => {
        setUpdateObject(state);
        setOpenEditDialog(true)
    }

	// eslint-disable-next-line
	const onPageChange = (page) => {
		setQuery({
			...query,
			page: page
		})
		loadData();
	}

	// eslint-disable-next-line
	const onPageSizeChange = (pageSize) => {
		setQuery({
			...query,
			page: 0,
			pageSize: pageSize
		})
		loadData();
	}

	return (
		<Grid>
			<Breadcrumb links={breadcrumb} />
			<Headerbar title={t("INTERLOCKI004")}>
				<Box paddingRight={1}>
					<SubmitButton
						variant="contained"
						color="primary"
						size="small"
						onClick={() => openAddInterlockDialog()}
						startIcon={<AddCircleOutlineRoundedIcon />}
					>
						{t("COMMON009")}
					</SubmitButton>
				</Box>
			</Headerbar>
			<Toolbar>
				<Box p={1} >
					<Button
						onClick={() => loadData()}
						color="secondary"
						size="small"
						startIcon={<Refresh className="refresh-btn" />}
						className="acc-list-action-btn">
						{t("COMMON002")}
					</Button>
				</Box>
				<Box p={1} flexGrow={1}>
					<Button
						onClick={() => deleteInterlock(selectedInterlocks)}
						color="secondary"
						size="small"
						startIcon={<Delete className="delete-btn" />}
						className="acc-list-action-btn">
						{t("COMMON004")}
					</Button>
				</Box>
				

				<Box p={1}>
					<FilterInterlock />
				</Box>
			</Toolbar>
			<DataTable
				columns={columns}
				data={interlocks}
				actions={actions}
				onSelectedRoles={(rows) => onSelectionChange(rows)} />

				{openEditDialog && <AddInterlock interlock={updateObject} open={openEditDialog} onClose={() => { setOpenEditDialog(false); loadData() }} />}

		</Grid>
	)
}