import { GET_ACCAUXIN_LIST, UPDATE_ACCAUXIN } from "../actions/types";

const initialState = {
	accAuxInList: [],
	updateAccAuxIn: []
};

export default function (state = initialState, action) {
	switch (action.type) {

		case GET_ACCAUXIN_LIST:
			return {
				...state,
				accAuxInList: action.payload
			};
		case UPDATE_ACCAUXIN:
			return {
				...state,
				updateAccAuxIn: action.payload
			};

		default:
			return state;
	}
}