import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';

class TimeLine  extends Component {
	constructor(props){
		super(props)
		this.state = {
      
    }
	}
  render() {
    
    return (
		
      <div className="root">
        <Grid 
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start">
			<Grid item xs={12}>
        <NavLink to={this.props.menuPath || "/acc/dashboard/"} style={{ textDecoration: 'none', color: 'unset' }} >
          <HomeIcon color="primary" fontSize="small"/> 
          <span style={{paddingLeft:"10px",fontSize:"15px",color:"#858585"}}>&#8250;</span>
          <label className="timeLine" style={{paddingLeft:"5px",color:"#858585"}}>
            {this.props.menu || 'Dashboard' }
          </label>
          <span style={{paddingLeft:"10px",fontSize:"15px",color:"#858585"}}>&#8250;</span>
          <label className="timeLine" style={{paddingLeft:"5px",color:"#858585"}}>
            {this.props.page }
          </label>
        </NavLink >
			</Grid>
		</Grid>
       
      </div>
    );
  }
}

export default TimeLine;