import React, { Fragment } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {REACT_URL_TIME_PERIOD} from '../../actions/EndPoints'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Card, CardContent, Grid } from '@material-ui/core';
import {compose} from 'redux'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import {createNewTimeZone} from '../../actions/TimePeriodActions'
import TimeLine from '../../utils/TimeLine';
import { Link } from 'react-router-dom';
import Snackbar from'@material-ui/core/Snackbar';
import Alert from'@material-ui/lab/Alert';
import { Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 250
    }
  },
  formcontrol: {
    margin: theme.spacing(1),
    width: 250
  },
});

class AddTimePeriod extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleChange = this.handleChange.bind(this);
     
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleDialogOK = this.handleDialogOK.bind(this);
    this.state = {
      errormessages: '',
      formIsValid: true,
      dialogText: '',
      isDialogOpen: false,
      isChecked: false,
      response: false,
       name: '' ,
       remark: '', 
       companyId:'', 

       selectCheckBox: false,  
       errors: {}, 

        sundayStart1: '',

        sundayEnd1: '',

        sundayStart2: '',

        sundayEnd2: '',

        sundayStart3: '',

        sundayEnd3: '',

        mondayStart1: '',
      
        mondayEnd1: '',

        mondayStart2: '',

        mondayEnd2: '',

        mondayStart3: '',

        mondayEnd3: '',

        tuesdayStart1: '',

        tuesdayEnd1: '',

        tuesdayStart2: '',

        tuesdayEnd2: '',

        tuesdayStart3: '',

        tuesdayEnd3: '',

        wednesdayStart1: '',

        wednesdayEnd1: '',

        wednesdayStart2: '',

        wednesdayEnd2: '',

        wednesdayStart3: '',

        wednesdayEnd3: '',

        thursdayStart1: '',

        thursdayEnd1: '',

        thursdayStart2: '',

        thursdayEnd2: '',

        thursdayStart3: '',

        thursdayEnd3: '',

        fridayStart1: '',

        fridayEnd1: '',

        fridayStart2: '',

        fridayEnd2: '',

        fridayStart3: '',

        fridayEnd3: '',

        saturdayStart1: '',

        saturdayEnd1: '',

        saturdayStart2: '',

        saturdayEnd2: '',

      saturdayStart3: '',

      saturdayEnd3: ''

    }
  }


  onChange(e) {
    
      this.setState({errors:{}})
      
      if(!this.state.selectCheckBox){
         
        if(typeof e != 'undefined'){
      this.setState({ [e.target.name]: e.target.value });
      }
    }
      else if(this.state.selectCheckBox && typeof e == 'undefined'){
  
      this.setState({   
        sundayStart1:this.state.mondayStart1,

        sundayEnd1: this.state.mondayEnd1,
 
        sundayStart2: this.state.mondayStart2,
 
        sundayEnd2: this.state.mondayEnd2,
 
        sundayStart3: this.state.mondayStart3,
 
        sundayEnd3: this.state.mondayEnd3,
 
        mondayStart1: this.state.mondayStart1,
      
        mondayEnd1: this.state.mondayEnd1,
 
        mondayStart2: this.state.mondayStart2,
 
        mondayEnd2: this.state.mondayEnd2,
 
        mondayStart3: this.state.mondayStart3,
 
        mondayEnd3: this.state.mondayEnd3,
 
        tuesdayStart1: this.state.mondayStart1,
 
        tuesdayEnd1: this.state.mondayEnd1,
 
        tuesdayStart2: this.state.mondayStart2,
 
        tuesdayEnd2: this.state.mondayEnd2,
 
        tuesdayStart3: this.state.mondayStart3,
 
        tuesdayEnd3: this.state.mondayEnd3,
 
        wednesdayStart1: this.state.mondayStart1,
 
        wednesdayEnd1: this.state.mondayEnd1,
 
        wednesdayStart2: this.state.mondayStart2,
 
        wednesdayEnd2: this.state.mondayEnd2,
 
        wednesdayStart3: this.state.mondayStart3,
 
        wednesdayEnd3: this.state.mondayEnd3,
 
        thursdayStart1: this.state.mondayStart1,
 
        thursdayEnd1: this.state.mondayEnd1,
 
        thursdayStart2: this.state.mondayStart2,
 
        thursdayEnd2: this.state.mondayEnd2,
 
        thursdayStart3: this.state.mondayStart3,
 
        thursdayEnd3: this.state.mondayEnd3,
 
        fridayStart1: this.state.mondayStart1,
 
        fridayEnd1: this.state.mondayEnd1,
 
        fridayStart2: this.state.mondayStart2,
 
        fridayEnd2: this.state.mondayEnd2,
 
        fridayStart3: this.state.mondayStart3,
 
        fridayEnd3: this.state.mondayEnd3,
 
        saturdayStart1: this.state.mondayStart1,
 
        saturdayEnd1: this.state.mondayEnd1,
 
        saturdayStart2: this.state.mondayStart2,
 
        saturdayEnd2: this.state.mondayEnd2,
 
        saturdayStart3: this.state.mondayStart3,
 
        saturdayEnd3: this.state.mondayEnd3
        
       
           
       
      }
      );
     
   
}
else {
   
  this.setState({

      selectCheckBox: false,

     sundayStart1:'',

     sundayEnd1: '',

     sundayStart2: "",

     sundayEnd2: "",

     sundayStart3: "",

     sundayEnd3: "",

    mondayStart1: this.state.mondayStart1,
  
    mondayEnd1: this.state.mondayEnd1,

    mondayStart2: this.state.mondayStart2,

    mondayEnd2: this.state.mondayEnd2,

    mondayStart3: this.state.mondayStart3,

    mondayEnd3: this.state.mondayEnd3,

    tuesdayStart1: "",

    tuesdayEnd1: "",

    tuesdayStart2: "",

    tuesdayEnd2: "",

    tuesdayStart3: "",

    tuesdayEnd3: "",

    wednesdayStart1: " ",

    wednesdayEnd1: " ",

    wednesdayStart2: "",

    wednesdayEnd2: "",

    wednesdayStart3: "",

    wednesdayEnd3: "",

    thursdayStart1: "",

    thursdayEnd1: "",

    thursdayStart2: "",

    thursdayEnd2: "",

    thursdayStart3: "",

    thursdayEnd3: "",

    fridayStart1: "",

    fridayEnd1: "",

    fridayStart2: "",

    fridayEnd2: "",

    fridayStart3: "",

    fridayEnd3: "",

    saturdayStart1: "",

    saturdayEnd1: "",

    saturdayStart2: "",

    saturdayEnd2: "",

    saturdayStart3: "",

    saturdayEnd3: ""

  })

}
  }
  componentWillReceiveProps(nextProps) {
      if (nextProps.errors) {
        this.setState({ errors: nextProps.errors });
      }
    }
  onSubmit(e) {
    e.preventDefault();
    
    var mon=this.handleValidation(this.state.mondayStart1,this.state.mondayStart2,this.state.mondayStart3,this.state.mondayEnd1,this.state.mondayEnd2,this.state.mondayEnd3,"Monday");
   
    if(mon)
    {
    var tues=this.handleValidation(this.state.tuesdayStart1,this.state.tuesdayStart2,this.state.tuesdayStart3,this.state.tuesdayEnd1,this.state.tuesdayEnd2,this.state.tuesdayEnd3,"tuesday");
    }
    if(tues)
    {
      var wed=this.handleValidation(this.state.wednesdayStart1,this.state.wednesdayStart2,this.state.wednesdayStart3,this.state.wednesdayEnd1,this.state.wednesdayEnd2,this.state.wednesdayEnd3,"wednesday");
    }
    if(wed)
    {
    var thu=this.handleValidation(this.state.thursdayStart1,this.state.thursdayStart2,this.state.thursdayStart3,this.state.thursdayEnd1,this.state.thursdayEnd2,this.state.thursdayEnd3,"thursday");
    }
    if(thu)
    {
    var fri=this.handleValidation(this.state.fridayStart1,this.state.fridayStart2,this.state.fridayStart3,this.state.fridayEnd1,this.state.fridayEnd2,this.state.fridayEnd3,"friday");
    }
    if(fri)
    {
    var sat=this.handleValidation(this.state.saturdayStart1,this.state.saturdayStart2,this.state.saturdayStart3,this.state.saturdayEnd1,this.state.saturdayEnd2,this.state.saturdayEnd3,"saturday");
    }
    if(fri)
    {
    var sun= this.handleValidation(this.state.sundayStart1,this.state.sundayStart2,this.state.sundayStart3,this.state.sundayEnd1,this.state.sundayEnd2,this.state.sundayEnd3,"sunday");
    console.log(sun);
    }
     
		const createTimeZone = {

       name: this.state.name ,

       remark: this.state.remark,
       
       companyId: localStorage.getItem('companyId'),

       sundayStart1: this.state.sundayStart1,

       sundayEnd1: this.state.sundayEnd1,

       sundayStart2: this.state.sundayStart2,

       sundayEnd2: this.state.sundayEnd2,

       sundayStart3: this.state.sundayStart3,

       sundayEnd3: this.state.sundayEnd3,

       mondayStart1: this.state.mondayStart1,
     
       mondayEnd1: this.state.mondayEnd1,

       mondayStart2: this.state.mondayStart2,

       mondayEnd2: this.state.mondayEnd2,

       mondayStart3: this.state.mondayStart3,

       mondayEnd3: this.state.mondayEnd3,

       tuesdayStart1: this.state.tuesdayStart1,

       tuesdayEnd1: this.state.tuesdayEnd1,

       tuesdayStart2: this.state.tuesdayStart2,

       tuesdayEnd2: this.state.tuesdayEnd2,

       tuesdayStart3: this.state.tuesdayStart3,

       tuesdayEnd3: this.state.tuesdayEnd3,

       wednesdayStart1: this.state.wednesdayStart1,

       wednesdayEnd1: this.state.wednesdayEnd1,

       wednesdayStart2: this.state.wednesdayStart2,

       wednesdayEnd2: this.state.wednesdayEnd2,

       wednesdayStart3: this.state.wednesdayStart3,

       wednesdayEnd3: this.state.wednesdayEnd3,

       thursdayStart1: this.state.thursdayStart1,

       thursdayEnd1: this.state.thursdayEnd1,

       thursdayStart2: this.state.thursdayStart2,

       thursdayEnd2: this.state.thursdayEnd2,

       thursdayStart3: this.state.thursdayStart3,

       thursdayEnd3: this.state.thursdayEnd3,

       fridayStart1: this.state.fridayStart1,

       fridayEnd1: this.state.fridayEnd1,

       fridayStart2: this.state.fridayStart2,

       fridayEnd2: this.state.fridayEnd2,

       fridayStart3: this.state.fridayStart3,

       fridayEnd3: this.state.fridayEnd3,

       saturdayStart1: this.state.saturdayStart1,

       saturdayEnd1: this.state.saturdayEnd1,

       saturdayStart2: this.state.saturdayStart2,

       saturdayEnd2: this.state.saturdayEnd2,

       saturdayStart3: this.state.saturdayStart3,

       saturdayEnd3: this.state.saturdayEnd3



		};
  
    
            if(mon===true&&tues===true&&wed===true&&thu===true&&fri===true&&sat===true){  
             this.props.createNewTimeZone(createTimeZone,this.props.history);
            }else{
             
            }
    
  }
  
   
  handleValidation = (s1,s2,s3,e1,e2,e3,day) => {

          //alert("from handleValidation "+s1 )       

            //let fields = this.state.fields;
            //let errors = '';
            let formIsValid = true;

            //alert("test "+s1 !== "00:00")
           const { t } = useTranslation();   
        if(s1 !== "" ){  
            if(!this.timeValidation(s1,e1)){
            formIsValid = false;
            //alert("Monday interval 1 start time must be smaller and not equal to End time of Monday interval 1 ");
            let errors= day+t('TIMEP014')+day+t('TIMEP015');
            
            this.setState({errormessages:errors,formIsValid:false})
            return formIsValid;
           }
        }

        if(s2 !== ""){
          if(!this.timeValidation(e1,s2)){
          formIsValid = false;
            //alert("Start time of Monday interval 2 must be greather and not equal to End time of Monday interval 1");
            let errors=t('TIMEP016')+day+t('TIMEP017')+day+t('TIMEP015');
            this.setState({errormessages:errors,formIsValid:false})
            return formIsValid;
          } 
                  
          if(!this.timeValidation(s2,e2)) {
            formIsValid = false;
            //alert("Start time of Monday interval 2 must  be smaller and not equal to End time of Monday interval 2 ");
           let  errors=t('TIMEP016')+day+t('TIMEP017')+day+t('TIMEP019');
            this.setState({errormessages:errors,formIsValid:false})
            return formIsValid;
          }
        }
          if((s3 !== "")){
            if(!this.timeValidation(e2,s3)){
          formIsValid = false;
            //alert("Start time of Monday interval 3 must be greather and not equal to End time of Monday interval 2");
           let errors=t('TIMEP016')+day+t('TIMEP020')+day+t('TIMEP019');
            this.setState({errormessages:errors,formIsValid:false})
            return formIsValid;
          } 

          if(!this.timeValidation(s3,e3)){
            formIsValid = false;
            //alert("Start time of Monday interval 3 must  be smaller and not equal to End time of Monday interval 3 ");
           let errors=t('TIMEP016')+day+t('TIMEP021')+day+t('TIMEP022');
            this.setState({errormessages:errors,formIsValid:false})
            return formIsValid;
          }
        }
                          
        //this.setState({errors: errors});
        return formIsValid; 
}
timeValidation = (startTime,endTime) => {

  var startTime_hr = "";
  var startTime_min = "";
  var endTime_hr = "";
  var endTime_min = "";

     
      startTime_hr = startTime.split(":")[0];
      startTime_min = startTime.split(":")[1];
      endTime_hr = endTime.split(":")[0];
      endTime_min = endTime.split(":")[1];
         
    if((endTime_hr < startTime_hr) ) {
        return false;
    }
    if(endTime_hr === startTime_hr){
      if(endTime_min <= startTime_min ){
          return false;
      }
        return true;
    }
    
     return true;
   
 }  

 getTimeTotal = (time1,time2,time3) => {
 
  var time1_hr = "";
  var time1_min = "";
  var time2_hr = "";
  var time2_min = "";
  var time3_hr = "";
  var time3_min = "";
  var total_hrtime = "";
  var total_mintime = "";
 
      time1_hr = time1.split(":")[0];
      time1_min = time1.split(":")[1];
      time2_hr = time2.split(":")[0];
      time2_min = time2.split(":")[1];
      time3_hr = time3.split(":")[0];
      time3_min = time3.split(":")[1];

      
      total_hrtime = 1* time1_hr + 1*time2_hr + 1*time3_hr;

      total_mintime = 1* time1_min + 1*time2_min + 1*time3_min;
     
      if(total_mintime>=60)
      {
          total_mintime = total_mintime - 60;
          total_hrtime = total_hrtime + 1;
      }

      if(total_hrtime>=24)
      {
          total_hrtime = total_hrtime -24 ;
                  if(total_hrtime<10)
      total_hrtime = "0"+total_hrtime;

      }
              return (total_hrtime+":"+total_mintime); 
    }
  handleSuccess(){
   // alert("Success")
  }
/*
  handleDialogSave() {
    console.log('Clicked Save');
    this.setState({
      isDialogOpen: false
    });
  }
*/
  handleDialogOK() {
    console.log('Clicked OK');
    this.setState({
      isDialogOpen: false
    });
  }

  handleDialogClose() {
    console.log('Clicked Close');
    this.setState({
      isDialogOpen: false,errors:{}
    });
  }

  handleChange(e) {
    const target = e.target;
    const value = target.checked;

    this.setState({
      isChecked: value,
      isDialogOpen: true
    }, () => { console.log('Open Dialog') });
  }
  onCloseAlert=() =>{
		this.setState({ errormessages: false  });
	}
  checkBoxChange=(e)=>{
     if(!this.state.selectCheckBox){
      
    this.setState({selectCheckBox: true},()=>{this.onChange()})
     } 
     if(this.state.selectCheckBox) {
     
      //this.setState({selectCheckBox: false},()=>{this.onChange()})
      this.onChange(e); 
       
     
     }
    //this.onChange(e);
  //    this.setState({selectCheckBox: true},()=>{
      
  //     alert(this.state.mondayStart1)
  //  this.setState({
  // tuesdayStart1:this.state.mondayStart1

  // })



     
     
  }
  render() {
     
    const { t, classes } = this.props;
    
    const rows = [ "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    return (

      <Fragment>
              <Grid 
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start">
                  <Grid item xs={12}>
                    <TimeLine menu="Access control" page="Time period" menuPath={REACT_URL_TIME_PERIOD} pagePath=""/>
                  </Grid>
              </Grid>
              <Card className="card-box mb-4 device-list-card device-list-container">
                      <div className="card-header py-3  device-list-actions">
                          <div className="card-header--title font-size-lg">
                              {t('timePeriodTitle')}
                          </div>
                      
                      </div>
                      
                      <CardContent className="device-list-card">
                        <div>

                        {/* <Button variant="contained" color="primary" size="small" startIcon={<AddCircleOutlineRoundedIcon />} onClick={this.handleChange} checked={this.state.isChecked}>
                        {t('buttonTimePeriodTitle')}
                        </Button> */}

                        {/* <Dialog
                            className="dialog-timeperiod"
                            open={this.state.isDialogOpen}
                            onClose={this.handleDialogClose}
                            fullWidth={true}
                            maxWidth = {'md'}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >

                          <DialogTitle id="customized-dialog-title" onClose={this.handleDialogClose}>{"Add New Time Period"}</DialogTitle> */}

                          {/* <DialogContent className="dialog-content-timeperiod"> */}
                          <form onSubmit={this.onSubmit} noValidate autoComplete="off" className="form-timeperiod">
                            <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper" >
                                <Box p={1} bgcolor="grey.300">
                                    <TextField  margin="dense" name="name" label= {t('timeZoneName')} type="text"  
                                                        onChange={this.onChange}
                                                        error={this.state.errors.name ? true : false} 
		                                                  	helperText={this.state.errors.name}
                                                        />
                                </Box>
                                <Box p={1} bgcolor="grey.300">
                                    <TextField  margin="dense" name="remark" label={t('tableHeaderTimePeriodRemarks')} type="text" 
                                                        onChange={this.onChange}
                                                        error={this.state.errors.remark ? true : false} 
		                                                  	helperText={this.state.errors.remark}
                                                        />
                                </Box>
                            </Box>
                            


                          <TableContainer component={Paper} className="table-timeperiod-container" >
                            <Table aria-label="simple table" size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="table-timeperiod-border table-timeperiod-header" rowSpan={2}>{t('tableHeaderTimeOrDay')} </TableCell>
                                        <TableCell className="table-timeperiod-border table-timeperiod-header" align="center" colSpan={2}>{t('tableHeaderIntervalOne')}</TableCell>
                                        <TableCell className="table-timeperiod-border table-timeperiod-header" align="center" colSpan={2}>{t('tableHeaderIntervalTwo')}</TableCell>
                                        <TableCell className="table-timeperiod-border table-timeperiod-header" align="center" colSpan={2}>{t('tableHeaderIntervalthree')}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="table-timeperiod-border table-timeperiod-header" align="right">{t('tableHeaderStartTime')} </TableCell>
                                        <TableCell className="table-timeperiod-border table-timeperiod-header" align="right">{t('tableHeaderEndTime')} </TableCell>
                                        <TableCell className="table-timeperiod-border table-timeperiod-header" align="right">{t('tableHeaderStartTime')}</TableCell>
                                        <TableCell className="table-timeperiod-border table-timeperiod-header" align="right">{t('tableHeaderEndTime')}</TableCell>
                                        <TableCell className="table-timeperiod-border table-timeperiod-header" align="right">{t('tableHeaderStartTime')}</TableCell>
                                        <TableCell className="table-timeperiod-border table-timeperiod-header" align="right">{t('tableHeaderEndTime')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    
                                        {rows.map((row) => (
                                            <TableRow key={row}>
                                                <TableCell className="table-timeperiod-border table-timeperiod-header" align="center">{row}</TableCell>
                                                <TableCell className="table-timeperiod-border" align="center">
                                                    <TextField
                                                        name={row+"Start1"}
                                                        type="time"
                                                        //defaultValue="00:00"
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                        shrink: true,
                                                        }}
                                                        inputProps={{
                                                        step: 300, // 5 min
                                                        }}
                                                        onChange={this.onChange}
                                                        value={this.state[row+"Start1"]}
                                                    />
                                                </TableCell>
                                                <TableCell className="table-timeperiod-border" align="center">
                                                    <TextField
                                                        name={row+"End1"}
                                                        type="time"
                                                        //defaultValue="00:00"
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                        shrink: true,
                                                        }}
                                                        ampm = "true"
                                                        inputProps={{
                                                        step: 300, // 5 min
                                                        }}
                                                        onChange={this.onChange}
                                                        value={this.state[row+"End1"]}
                                                    />
                                                </TableCell>
                                                <TableCell className="table-timeperiod-border" align="center">
                                                    <TextField
                                                        name={row+"Start2"}
                                                        type="time"
                                                        //defaultValue="00:00"
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                        shrink: true,
                                                        }}
                                                        inputProps={{
                                                        step: 300, // 5 min
                                                        }}
                                                        onChange={this.onChange}
                                                        value={this.state[row+"Start2"]}
                                                    />
                                                </TableCell>
                                                <TableCell className="table-timeperiod-border" align="center">
                                                    <TextField
                                                        name={row+"End2"}
                                                        type="time"
                                                        //defaultValue="00:00"
                                                        className={classes.textField}
                                                        value={this.state[row+"End2"]}
                                                        InputLabelProps={{
                                                        shrink: true,
                                                        }}
                                                        inputProps={{
                                                        step: 300, // 5 min
                                                        }}
                                                        onChange={this.onChange}
                                                    />
                                                </TableCell>
                                                <TableCell className="table-timeperiod-border" align="center">
                                                    <TextField
                                                        name={row+"Start3"}
                                                        value={this.state[row+"Start3"]}
                                                        type="time"
                                                        //defaultValue="00:00"
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                        shrink: true,
                                                        }}
                                                        inputProps={{
                                                        step: 300, // 5 min
                                                        }}
                                                        onChange={this.onChange}
                                                    />
                                                </TableCell>
                                                <TableCell className="table-timeperiod-border" align="center">
                                                    <TextField
                                                        name={row+"End3"}
                                                        value={this.state[row+"End3"]}
                                                        type="time"
                                                        //defaultValue="00:00"
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                        shrink: true,
                                                        }}
                                                        inputProps={{
                                                        step: 300, // 5 min
                                                        }}
                                                        onChange={this.onChange}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        
                                    
                                    
                                </TableBody>
                            </Table>
                            </TableContainer>
                            <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper" >
                            <Box p={1} alignContent="flex-end" style={{color:"black"}}>
                              <Checkbox checked={this.state.selectCheckBox} onChange={this.checkBoxChange} ></Checkbox>Copy Manday Settings to other weekdays
                            </Box>
                                 
                            </Box>
                            <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper" >
                                <Box p={1} bgcolor="grey.300">
                                  <Button className="btn-default" color="primary" type="submit"   variant="contained">
                                  {t('tableBodySaveTooltip')}  
                                  </Button>
                                </Box>
                                <Box p={1} bgcolor="grey.300">
                                  <Button color="primary" onClick={this.handleDialogClose} component={Link}  variant="contained"  to={{
                       pathname: "/acc/time-zone",
                      }}>
                                    {t('cancel')}
                                  </Button>
                                </Box>
                            </Box>

                            



                          </form>                                 
                          {/* </DialogContent>
                        </Dialog> */}
                        <Grid item xs={12} sm={2}>
						{(this.state.errormessages) && (
							<Snackbar anchorOrigin={{ vertical: "Top", horizontal: "center" }} open={this.state.errormessages} autoHideDuration={5000} onClose={this.onCloseAlert}>
							<Alert onClose={this.onCloseAlert} severity="error">
								{this.state.errormessages}
								</Alert>
						</Snackbar>
						)}
					</Grid>
                        </div>
                      </CardContent>
                </Card>

            </Fragment>
            
      
    );
  }
}


const mapStateToProps = (state) => ({
  isSuccess: state.TimePeriod.isSuccess,
  errors: state.errors
});

//export default AccDeviceList;

export default compose(withTranslation(),connect(mapStateToProps,{createNewTimeZone}),withStyles(styles, { withTheme: true }))(AddTimePeriod);
