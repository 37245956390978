import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import biozlink from '../../assets/images/onboard//access-cloud.png';
import cloudAccess from '../../assets/images/onboard//cloudaccess.png';
import EmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import background from '../../assets/images/onboard/background_image.png';
import Link from '@material-ui/core/Link';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import CommonUtil from '../../components/Util/CommonUtils';
import setJWTToken from '../../oauth-client/setJWTToken';
import jwt_decode from 'jwt-decode';
import { SET_CURRENT_USER } from '../../actions/types';
import { REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import { useDispatch } from 'react-redux';
import LoginLayout from './LoginLayout';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

function CreateCompany() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const countries = [
    {
      label: 'India',
      value: '91',
    },
    {
      label: 'United States',
      value: '1',
    },
    {
      label: 'China',
      value: '86',
    },
    {
      label: 'Bangladesh',
      value: '880',
    },
    {
      label: 'South Africa',
      value: '27',
    },
    {
      label: 'Indonesia',
      value: '62',
    },
    {
      label: 'Australia',
      value: '61',
    },
    {
      label: 'Brazil',
      value: '55',
    },
    {
      label: 'France',
      value: '33',
    },
    {
      label: 'Germany',
      value: '49',
    },
    {
      label: 'Italy',
      value: '39',
    },
    {
      label: 'Japan',
      value: '81',
    },
  ];

  const [values, setValues] = useState({
    name: '',
    code: '',
    registrationNumber: '',
    email: '',
    phone: '',
    status: '',
    country: '',
  });

  const [error, setError] = useState({
    name: false,
    code: false,
    registrationNumber: false,
    email: false,
    phone: false,
    status: false,
    country: false,
  });

  const handleChange = (event) => {
    // setValues({ ...values, [event.target.name]: event.target.value });
    // setError({ [event.target.name]: false });

    const name = event.target.name;
    if (name === 'phone') {
      if (!CommonUtil.isEmptyString(event.target.value) && event.target.value.match(/^[0-9 ]+$/)) {
        setValues({
          ...values,
          [name]: event.target.value,
        });
      } else if (CommonUtil.isEmptyString(event.target.value)) {
        setValues({
          ...values,
          [name]: event.target.value,
        });
      }
    } else {
      setValues({
        ...values,
        [name]: event.target.value,
      });
    }
    setError({
      ...error,
      [name]: false,
    });
  };

  const history = useHistory();

  useEffect(() => {
    setJWTToken(localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (CommonUtil.isEmptyString(values.country)) {
      setError({ ...error, country: true });
      toast.error('Country is mandatory');
      return;
    }

    if (CommonUtil.isEmptyString(values.name)) {
      setError({ ...error, name: true });
      toast.error('Company name is mandatory');
      return;
    }
    // if (values.name.length > 50) {
    //   setError({ ...error, name: true });
    //   toast.error('Invalid Company Name');
    //   return;
    // }
    if (CommonUtil.isEmptyString(values.code)) {
      setError({ ...error, code: true });
      toast.error('Company Code is mandatory');
      return;
    }
    if (!values.code.match(/^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/)) {
      setError({ ...error, code: true });
      toast.error('Invalid Company Code');
      return;
    }
    if (!CommonUtil.isEmptyString(values.phone)) {
      if (values.phone.length < 8 || values.phone.length > 15) {
        setError({ ...error, phone: true });
        toast.error('Phone nuber must be between 8 to 15 digits');
        return;
      }
    }
    if (!CommonUtil.isEmptyString(values.email) && !values.email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)) {
      setError({ ...error, email: true });
      toast.error('Invalid Email');
      return;
    }

    // if (CommonUtil.isEmptyString(values.phone)) {
    //   setError({ ...error, phone: true });
    //   toast.error('Phone Number is mandatory');
    //   return;
    // }

    axios
      .post(`/api/v2.0/company`, values)
      .then(function(res) {
        if (res.data.code === 'UASI0006') {
          toast.success(res.data.message);
          VerifyCompany(values.code);
        } else {
          switch (res.data.code) {
            case 'UASE0011':
              setError({ ...error, code: true });
              break;
            case 'UASE0006':
              setError({ ...error, name: true });
              break;
            case 'UASE0012':
              setError({ ...error, registrationNumber: true });
              break;
            default:
              setError({ ...error });
          }
          toast.error(res.data.message);
        }
      })
      .catch(function(error) {
        console.log(error);
        alert('Something went wrong');
      });
  };

  const VerifyCompany = (code) => {
    const payload = {
      companyCode: code,
      companyId: '',
    };
    axios
      .put(`/api/v2.0/company`, payload)
      .then(function(res) {
        if (res.data.code === 'UASI0011') {
          setJWTToken('Bearer ' + res.data.data.access_token);
          localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, 'Bearer ' + res.data.data.access_token);
          localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, 'Bearer ' + res.data.data.refresh_token);
          const decoded = jwt_decode(res.data.data.access_token);
          localStorage.setItem('companyCode', code);
          dispatch({
            type: SET_CURRENT_USER,
            payload: decoded,
          });
          history.push({
            pathname: REACT_URL_DASHBOARD,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
    //history.push('/createcompany');
  };
  return (
    <LoginLayout>
      <Grid container spacing={5} alignItems='center'>
        <Grid item></Grid>
        <Grid item xs>
          <Typography className='signin_title' variant='h5' color='textPrimary' align='center'>
            Create Company
          </Typography>
        </Grid>
      </Grid>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <BusinessIcon className='login_icons' />
          </Grid>

          <Grid item xs>
            <FormControl variant='outlined' size='small' fullWidth>
              <InputLabel>{t('COMMON122') + ' *'}</InputLabel>
              <Select
                fullWidth
                className='m-0 mb-2 text-left'
                helperText=''
                label={t('COMMON122') + ' *'}
                name='country'
                value={values.country}
                onChange={handleChange}
                error={error.country}
              >
                <MenuItem value=''>None</MenuItem>
                {countries ? (
                  countries.map((country, index) => (
                    <MenuItem key={index} value={country.label}>
                      <Typography variant='inherit' noWrap>
                        {country.label}
                      </Typography>
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <BusinessIcon className='login_icons' />
          </Grid>

          <Grid item xs>
            <TextField
              error={error.name}
              className='login_input'
              size='small'
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='companyName'
              label='Company Name'
              autoComplete='off'
              name='name'
              type='text'
              value={values.name}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <BusinessIcon className='login_icons' />
          </Grid>
          <Grid item xs>
            <TextField
              error={error.code}
              className='login_input'
              size='small'
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='companyCode'
              label='Company Code'
              autoComplete='off'
              name='code'
              type='text'
              value={values.code}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <ApartmentIcon className='login_icons' />
          </Grid>
          <Grid item xs>
            <TextField
              error={error.registrationNumber}
              className='login_input'
              size='small'
              variant='outlined'
              margin='normal'
              fullWidth
              id='registerNumber'
              label='Company Registration Number'
              autoComplete='off'
              name='registrationNumber'
              type='text'
              value={values.registrationNumber}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <EmailIcon className='login_icons' />
          </Grid>
          <Grid item xs>
            <TextField
              error={error.email}
              className='login_input'
              size='small'
              variant='outlined'
              margin='normal'
              fullWidth
              id='email'
              label='Email Address'
              autoComplete='off'
              name='email'
              type='email'
              value={values.email}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <LocalPhoneIcon className='login_icons' />
          </Grid>
          <Grid item xs>
            <TextField
              error={error.phone}
              className='login_input'
              size='small'
              variant='outlined'
              margin='normal'
              fullWidth
              id='phoneNumber'
              label='Phone Number'
              autoComplete='off'
              name='phone'
              value={values.phone}
              onChange={handleChange}
              inputProps={{ min: 0 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5} alignItems='center'>
          <Grid item></Grid>
          <Grid item xs>
            <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
              CREATE
            </Button>
          </Grid>
        </Grid>
        <Grid container alignItems='center'>
          <Grid item xs></Grid>
          <Grid item alignItems='flex-end'>
            <Link href='/acc/login' className='forgot_password'>
              {' Back to login'}
            </Link>
          </Grid>
        </Grid>
      </form>
    </LoginLayout>
  );
}
export default withRouter(CreateCompany);
