import { Box, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DeviceOperationDialog from './DeviceOperationDialog';
import { useDispatch } from 'react-redux';
import { getDevices, getSyncType, syncData } from '../../actions/DeviceApiCalls';
import { useHistory } from 'react-router-dom';

export default function SyncData(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  // eslint-disable-next-line
  const [popupState, setPopupState] = React.useState(props.popupState);
  const [selectedDevices, setSelectedDevice] = React.useState();
  //const [selectedTimezone, setSelectedTimeZone] = React.useState("")
  const [syncTypes, setSyncTypes] = React.useState([]);
  const [types, setTypes] = React.useState();
  const history = useHistory();

  useEffect(() => {
    loadTypes();
    // eslint-disable-next-line
  }, []);

  const loadTypes = () => {
    dispatch(getSyncType(selectedDevices, setTypes));
  };

  const deviceOperations = () => {
    if (props.devices.length < 1) {
      toast.warning(t('DEVI0014'));
      handleClose();
      return;
    }

    setOpenDialog(true);

    var ids = '';
    const { devices } = props;
    // eslint-disable-next-line
    devices.map((device, index) => {
      if (index === devices.length - 1) ids = ids + device.id;
      else ids = ids + device.id + ',';
    });

    setSelectedDevice(ids);

    // confirmAlert({
    //     customUI: ({ onClose }) => {
    //         return (
    //             <ConfirmModal open={true} text={confirmMessage} onConfirm={() => { dispatch(performDeviceOperations(ids, operation)); onClose() }} onClose={() => onClose()} />
    //         );
    //     }
    // });
  };
  const loadDevices = () => {
    dispatch(getDevices('', '', '', '', 1, 1000));
  };
  const handleClose = () => {
    popupState.close();
    setOpenDialog(false);
    loadDevices();
  };

  const handleSubmit = () => {
    if (syncTypes.length < 1) {
      toast.error(t('DEVI0057'));
      return;
    }
    const syncTypesArr = [];
    syncTypes.map((syncTypesObj, index) => {
      syncTypesArr.push(syncTypesObj.value);
    })

    dispatch(syncData(selectedDevices, syncTypesArr, () => handleClose()));
  };
  const handlechecked = (e) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      const typedata = [];
      let tempUser = types.map((item) => {
        typedata.push(item)
        return { ...item, isChecked: checked };
      });
      setTypes(tempUser);
      setSyncTypes(typedata);
    } else {
      let tempUser = types.map((item) =>
        item.name === name ? { ...item, isChecked: checked } : item,
      );
      types.map((item) => {
        if (item.name === name) {
          const arrofobj = [...syncTypes, item]
          setSyncTypes(arrofobj);
           return { ...item, isChecked: checked };
        }
      });
      setTypes(tempUser);
    }
  };
  return (
    <>
      <MenuItem onClick={() => deviceOperations()}>{t('DEVI0020')}</MenuItem>

      {openDialog && (
        <DeviceOperationDialog devices={props.devices} header={t('DEVI0020')} open={openDialog} onClose={(e) => handleClose(e, '')} handleSubmit={() => handleSubmit()}>
          <Box display='flex' justifyContent='flex-start' paddingTop={1}>
            <p>
              <b className='acc-head'>{t('DEVI0020')}</b>
            </p>
          </Box>
          <Grid item xs={12}>
            <FormControlLabel
              style={{ margin: 0 }}
              control={<Checkbox
                onChange={handlechecked}
                name="allSelect"
                checked={!types.some((item) => item?.isChecked !== true)} />}
              label={t('optionSelectAll')}
            />
          </Grid>
          <Box display='flex' justifyContent='flex-start'>
            <FormGroup row>
              <Grid container justifyContent='flex-start'>
                {types.map((item, index) => (
                  <Grid item key={item.title} style={{ fontSize: 12 }}>
                    <Checkbox
                      name={item.name}
                      checked={(item)?.isChecked || false}
                      onChange={handlechecked}
                    />
                    {item.name}
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </Box>
        </DeviceOperationDialog>
      )}
    </>
  );
}
