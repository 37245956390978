import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box,  Grid,  Typography,FormControl,InputLabel,MenuItem,Select} from '@mui/material';
import CancelIcon from '@material-ui/icons/Cancel';
import CommonUtil from '../../components/Util/CommonUtils';
import { getDevicesForInterlock } from '../../actions/DeviceApiCalls';
import { addInterlock, getInterlockRules, updateInterlock,resetInterlockRule } from '../../actions/InterlockApiCalls';
import CancelButton from '../../components/CancelButton';
import SubmitButton from '../../components/SubmitButton'; 

export default function AddInterlock(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const store = useSelector((state) => state);
    const [open, setOpen] = React.useState(props.open);
    //const [data, setData] = React.useState();
    const [data, setData] = React.useState();
    const [devices, setDevices] = React.useState([]);
    const [interlocks, setInterlockRules] = React.useState([]);
    const [state, setState] = React.useState({
		id: "",
        deviceId: "",
        interlockRule: ""
    })

    const [error, setError] = React.useState({
        id: false,
        deviceId: false,
        interlockRule: false
    })

	useEffect(() => {
        loadData();
        dispatch(resetInterlockRule())
        // eslint-disable-next-line
    }, [])

	const loadData = () => {
		dispatch(getInterlockRules());
	}

    useEffect(() => {
        setOpen(props.open);
        setData(props.interlock);
        setState({
            id: props.interlock.id,
            deviceId: props.interlock.deviceId,
            interlockRule: props.interlock.interlockRule
        })
        // eslint-disable-next-line
    }, [props.open, props.interlock])

    useEffect(() => {
        dispatch(getDevicesForInterlock('', "0", "1000"))
        // eslint-disable-next-line
    }, [])
		
	 useEffect(() => {
		 if(state.deviceId !== undefined && state.deviceId !=="" ){
			dispatch(getInterlockRules(state.deviceId))
		}
		else{
			setInterlockRules([])
		}
        // eslint-disable-next-line
    }, [state.deviceId])

    useEffect(() => {
        setDevices(store.interlock.interlockDevices)
    }, [store.interlock.interlockDevices])

	useEffect(() => {
        setInterlockRules(store.interlock.interlockDeviceRule)
    }, [store.interlock.interlockDeviceRule])

    useEffect(() => {
        // eslint-disable-next-line
        if (store.errors && !CommonUtil.isEmpty(store.errors.interlockError)) {
            const { interlockError } = store.errors;
            switch(interlockError.code){
                case "CASE0011" : setError({deviceId: true});break;
                case "CASE0044" : setError({interlockRule: true});break;
                default:
            }
        }
        // eslint-disable-next-line
    }, [store.errors.interlockError])

    const handleClose = () => {
        props.onClose(false);
    };

	const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });

        setError({
            [name]: false
        })
    };
	
	
	
    const handleSubmit = () => {
        if(CommonUtil.isEmptyString(state.id)){
            dispatch(addInterlock(state, (data) => {
                handleClose();
            }))
        } else {
            dispatch(updateInterlock(state, (data) => {
                handleClose();
            }))
        }
    }
	
    // eslint-disable-next-line
	const getRulesData = () => {
		dispatch(getInterlockRules(store.interlock.interlockDevices.id))
	}

    return (
        <div>

            <Dialog maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className="custom-modal">
                <DialogTitle id="form-dialog-title">
                    <Box display="flex">
                        <Box flexGrow={1}>
                            {CommonUtil.isEmptyString(state.id) ? t("Interlock002") : t("INTERLOCK005")}
                        </Box>
                        <CancelIcon onClick={handleClose} style={{ color: "red", background: "white", borderRadius: "50%" }} fontSize="small" />
                    </Box>
                </DialogTitle>
                <DialogContent >
                    <form noValidate autoComplete="off">
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
							style={{minWidth:"300px"}}
                        >
                            <Grid item xs={12} >
                                <Box display="flex" justifyContent="center" m={1} p={1} style={{minWidth:"300px"}}>

                                    <FormControl variant="outlined" size="small" fullWidth>
                                        <InputLabel>{t("DEVI0002")}</InputLabel>
                                        <Select
											disabled={!CommonUtil.isEmptyString(state.id)}
                                            label={t("DEVI0002")}
                                            error={error.deviceId}
                                            name="deviceId"
                                            onChange={handleChange}
                                            value={state.deviceId}
                                            defaultValue={state.deviceId}
                                        >
                                            <MenuItem value="" selected>
                                                <em>{t('none')}</em>
                                            </MenuItem>
                                            {
                                                devices ? devices.map((data, index) =>
                                                    <MenuItem key={index} value={data.id}  >
                                                        <Typography variant="inherit" noWrap>{data.name}</Typography>
                                                    </MenuItem>
                                                ) : <></>
                                            }

                                        </Select>
                                    </FormControl>

                                </Box>

                            </Grid>
                            <Grid item xs={12} >
                                <Box display="flex" justifyContent="center" m={1} p={1}>

                                    <FormControl variant="outlined" size="small" fullWidth>
                                        <InputLabel>{t("InterlockRule")}</InputLabel>
                                        <Select
                                            label={t("InterlockRule")}
                                            error={error.InterlockRule}
                                            name="interlockRule"
                                            onChange={handleChange}
                                            value={state.interlockRule}
                                            defaultValue={state.interlockRule}
                                        >
                                            <MenuItem value="" selected>
                                                <em>None</em>
                                            </MenuItem>
                                            {
                                                interlocks ? interlocks.map((data, index) =>
                                                    <MenuItem key={index} value={data.value}  >
                                                        <Typography variant="inherit" noWrap>{data.text}</Typography>
                                                    </MenuItem>
                                                ) : <></>
                                            }

                                        </Select>
                                    </FormControl>

                                </Box>

                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <SubmitButton onClick={handleSubmit} color="primary" variant="contained" size="small">
                        {t("COMMON007")}
                    </SubmitButton>
                    <CancelButton onClick={handleClose} color="primary" variant="contained" size="small">
                        {t("COMMON008")}
                    </CancelButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
