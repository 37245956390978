import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import Headerbar from '../../components/HeaderBar';
import { useTranslation } from 'react-i18next';
import DataTable from '../../components/DataTable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import { getLevelPerson, loadLevelPerson, removeLevelPerson } from '../../actions/LevelApiCalls';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import AddLevelPerson from '../../pages/level/AddLevelPerson';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import CommonUtil from '../../components/Util/CommonUtils';
import CachedIcon from '@material-ui/icons/Cached';
import FilterLevelPerson from './FilterLevelPerson';

export default function PersonDetails(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [selectedData, onSelectionChange] = useState([]);
  const [open, setOpenDialog] = useState(false);
  const [data, setData] = useState([]);

  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // useEffect(() => {
  //   if (!open) {
  //     loadData(0, rowsPerPage);
  //   }
  // }, [open]);

  useEffect(() => {
    onSelectionChange([]);
    if (!CommonUtil.isEmpty(store.level.levelPersons)) {
      setTotalRecords(store.level.levelPersons.totalCount);
      setPage(store.level.levelPersons.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.level.levelPersons.employee);
    // eslint-disable-next-line
  }, [store.level.levelPersons]);

  useEffect(() => {
    setData(store.level.levelPersons.employee);
  }, [store.level.levelPersons]);

  const loadData = (page, rowsPerPage) => {
    dispatch(loadLevelPerson(props.levelId, filter, false, page + 1, rowsPerPage));
  };

  const [filter, setFilter] = useState({
    firstName: '',
    lastName: '',
    employeeCode: '',
    email: '',
    phone: '',
  });

  const defaultFilter = {
    firstName: '',
    lastName: '',
    employeeCode: '',
    email: '',
    phone: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      employeeCode: filterData.employeeCode,
      email: filterData.email,
      phone: filterData.phone,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setPage(0);
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadData(0, rowsPerPage);
  }, [filter]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadData(0, pageSize);
  };

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '30vh',
    initialPage: 1,
    pageSize: rowsPerPage,
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var ids = '';
    var employeeIds = '';
    // eslint-disable-next-line
    data.map((record, index) => {
      if (index === data.length - 1) {
        ids = ids + record.id;
        employeeIds = employeeIds + record.employeeCode;
      } else {
        ids = ids + record.id + ',';
        employeeIds = employeeIds + record.employeeCode + ',';
      }
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(removeLevelPerson(props.levelId, employeeIds, ids, () => loadData(page, rowsPerPage)));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const columns = [
    {
      field: 'firstName',
      title: t('COMMON020'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'lastName', title: t('COMMON021'), editable: 'never' },
    { field: 'employeeCode', title: t('COMMON045'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <DeleteOutlineIcon color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  return (
    <div>
      <Box display='flex' className='custom-container-header' alignItems='center'>
        <Headerbar title={t('LEVEL011')} className='custom-container-header'>
          <Box paddingRight={1}>
          <Tooltip title={t('COMMON009')}>
            <IconButton size='small' aria-label='delete' onClick={() => setOpenDialog(true)}>
              <LibraryAddIcon color='secondary' />
            </IconButton>
            </Tooltip>
          </Box>
          <Box paddingRight={1}>
          <Tooltip title={t('COMMON004')}>
            <IconButton aria-label='delete' size='small' onClick={() => deleteRecord(selectedData)}>
              <DeleteOutlineIcon color='secondary' />
            </IconButton>
            </Tooltip>
          </Box>
          <Box paddingRight={1}>
            <FilterLevelPerson applyFilter={(data) => applyFilter(data)} />
          </Box>
          <Box paddingRight={1}>
            <Tooltip title={t('COMMON002')}>
              <IconButton color='secondary' aria-label={t('COMMON009')} size='small' onClick={resetFilter}>
                <CachedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Headerbar>
      </Box>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
      <AddLevelPerson existingItems={data} open={open} levelId={props.levelId} onClose={() => setOpenDialog(false)} onSubmit={() => loadData(0, rowsPerPage)} />
    </div>
  );
}
