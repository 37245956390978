import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import setJWTToken from '../../oauth-client/setJWTToken';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { REACT_LOGOUT } from '../../actions/EndPoints';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Avatar, Box, Menu, Button, List, ListItem, Divider, IconButton, Grid, Card, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import MoreIcon from '@material-ui/icons/MoreVert';
import profileImage from '../../assets/images/avatars/avatar5.jpg';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { makeStyles } from '@material-ui/core/styles';
import DraftsIcon from '@material-ui/icons/Drafts';
import { useHistory } from 'react-router';
import { logout } from '../../oauth-client/OauthClientActions';
import { getUser } from '../../actions/ProfileApiCalls';
import CommonUtil from '../../components/Util/CommonUtils';
import UserProfileBox from '../../components/cloudaccessdashboard/UserProfileBox';
import { useTranslation } from 'react-i18next';
import defaultProfile from '../../assets/images/defaultProfile.png';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  icon_color: {
    color: '#3D4977',
  },
}));

export default function UserProfileCard() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [companyList, setCompanyList] = React.useState([]);
  const user = useSelector((state) => state.security.user);
  const store = useSelector((state) => state.security);
  const [userData, setUserData] = useState({});
  const [profile, setProfile] = useState(defaultProfile);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const zlink=localStorage.getItem('redirectFrom');
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userProfile = () => {
    history.push({
      pathname: '/acc/user-profile',
    });
    setAnchorEl(null);
  };

  const companyProfile = () => {
    history.push({
      pathname: '/acc/company-profile',
    });
    setAnchorEl(null);
  };

  const dashboard = () => {
    history.push({
      pathname: '/acc/dashboard',
    });
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!CommonUtil.isEmpty(store) && !CommonUtil.isEmpty(store.userData)) {
      setUserData({ ...store.userData });
      if (!CommonUtil.isEmptyString(store.userData.profilePhoto)) {
        setProfile('data:image/jpeg;base64,' + store.userData.profilePhoto);
      }
    } else {
      dispatch(getUser(user.id));
    }
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store)) {
      setUserData({ ...store.userData });
    }

    if (!CommonUtil.isEmptyString(store.userData.profilePhoto)) {
      setProfile('data:image/jpeg;base64,' + store.userData.profilePhoto);
    }
  }, [store]);

  return (
    <Fragment>
      <Button color='inherit' onClick={handleClick} className='text-capitalize align-items-center'>
        <Box>
          {/* <AccountCircleIcon /> */}
          <Avatar alt='' src={profile}/>
        </Box>
        <div className='d-xl-block pl-2'>
          <div className='font-weight-bold pl-0'>{userData.firstName}</div>
        </div>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        <Card className='card-box p-3' style={{ boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 0%)' }}>
          {/* <div className="w-150 px-3 ">
            <span className=" d-block font-weight-bold font-size-lg" style={{ color: '#3D4977', }}>User Profile</span>
          </div> */}
          <div className='d-flex align-items-center py-1 '>
            <div className='avatar-icon-wrapper rounded-circle mr-3'>
              <Avatar alt='...' className='img-fluid' src={profile} />
            </div>
            <div className='w-150'>
              <Typography className='d-block font-weight-bold font-size-md' variant='body' color='secondary'>
                {userData.firstName + ' ' + (CommonUtil.isEmptyString(userData.lastName) ? '' : userData.lastName)}
              </Typography>
              {!zlink&&(
             <>
              <Tooltip title={user.companyName}>
              <Typography className='d-block' variant='body' style={{ color: '#9EA4BB' }}>
                {CommonUtil.handleTextOverflow(user.companyName)}
              </Typography>
              </Tooltip>
              </>   
              )}
              <Typography variant='body' color='textSecondary'>
                {' '}
                <DraftsIcon style={{ color: '#9EA4BB' }} /> &nbsp;{userData.email}
              </Typography>
            </div>
          </div>
          {!zlink&&(
             <>
          <Divider />
          <ListItem button className='px-2' onClick={() => dashboard()}>
            <div className='nav-link-icon py-1'>
              <HomeIcon className={classes.icon_color} />
            </div>
            <Typography variant='body' color='secondary'>
              {t('COMMON127')}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem button className='px-2' onClick={() => userProfile()}>
            <div className='nav-link-icon py-1'>
              <AccountBoxIcon className={classes.icon_color} />
            </div>
            <Typography variant='body' color='secondary'>
              {t('COMMON128')}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem button className='px-2' onClick={() => companyProfile()}>
            <div className='nav-link-icon py-1'>
              <PersonIcon className={classes.icon_color} />
            </div>
            <Typography variant='body' color='secondary'>
              {t('COMMON125')}
            </Typography>
          </ListItem>
          <Divider className='px-2 mb-4' />
          <Button
            variant='contained'
            color='primary'
            fullWidth
            size='large'
            style={{ borderRadius: '5px' }}
            onClick={() => {
              handleClose();
              dispatch(logout());
            }}
          >
            {t('logout')}
          </Button>
          </>   
        )}
        </Card>
      </Menu>

      <UserProfileBox />
    </Fragment>
  );
}
