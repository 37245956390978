//export const PERSONNEL_MANAGEMENT_SERVICE_URL= "http://192.168.11.13:9096"
//export const LOCATION_SERVICE_URL= "http://192.168.11.13:9098"
//export const ACCESS_SERVICE_URL= "http://192.168.11.13:9099"
//export const SDMS_SERVICE_URL = "http://192.168.11.13:8097"
//export const ACCESS_SERVICE_URL= "https://cloudaccessdev.minervaiot.com"
//export const PERSONNEL_MANAGEMENT_SERVICE_URL= "https://organizationdev.minervaiot.com"
//export const LOCATION_SERVICE_URL= "https://locationdev.minervaiot.com"
//export const SDMS_SERVICE_URL = "https://companyCode.sdmsdevi.minervaiot.com"

export const PERSONNEL_MANAGEMENT_SERVICE_URL = process.env.REACT_APP_ORG_SERVICE_URI;
export const LOCATION_SERVICE_URL = process.env.REACT_APP_LOCATION_SERVICE_URI;
export const ACCESS_SERVICE_URL = process.env.REACT_APP_ACCESS_SERVICE_URI;
export const SDMS_SERVICE_URL = process.env.REACT_APP_SDMS_SERVICE_URI;
//export const SDMS_SERVICE_URL= "http://192.168.20.103:8097"

