import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import TodayTransactions from "./TodayTransactions";
import ExceptionTransactions from "./ExceptionTransactions";
import Transactions from "./Transactions";
import { Grid } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
}

export default function TransactionTabs() {
  const [value, setValue] = React.useState(0);
   const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
        <Grid>
            <AppBar position="static" color="transparent">
                <Tabs value={value} onChange={handleChange} >
                <Tab label={t('COMMON075')} />
                <Tab label={t('COMMON076')} />
                <Tab label={t('COMMON077')} />
                </Tabs>
            </AppBar>
            {value === 0 &&
				        <TabPanel value={value} index={value}>
                  <Transactions />
                </TabPanel>
            }
            {value === 1 &&
					      <TabPanel  value={value} index={value}>
                    <TodayTransactions />
                </TabPanel>
			      }
            {value === 2 &&
					      <TabPanel  value={value} index={value}>
                    <ExceptionTransactions />
                </TabPanel>
			      }
        </Grid>
    </div>
  );
}
