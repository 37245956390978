import React, { useState, useEffect, useLayoutEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow, useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import { MAP_STYLES } from './mapStyles';
// import logo from '../../assets/icons/gps.png'
// import logo from '../../assets/images/dashboard-icons/marker.gif';
import { useHistory, withRouter } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import ApartmentIcon from '@material-ui/icons/Apartment';
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import { REACT_URL_SITE } from '../../actions/EndPoints';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import { filterSite } from '../../actions/organization/SiteApiCalls';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmModal from '../../components/ConfirmModal';
import CommonUtil from '../Util/CommonUtils';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { REACT_URL_DEVICE_LIST, REACT_URL_SITE_DASHBOARD } from '../../actions/EndPoints';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

const lib = ['places'];
let language = BrowserLanguage.getDefaultLanguage();

function GoogleMapDialog(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [center, setCenter] = useState({ lat: 36.32852408225397, lng: -38.72770055138199 });
  const [zoom, setZoom] = useState(3);
  const [selected, setSelected] = useState('');
  const [tempSelected, setTempSelected] = useState('');
  const [finallocation, Setfinallocation] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [map, setMap] = useState(null);
  const [sites, setSites] = useState([]);

  const onSelectHover = async (item) => {
    if (CommonUtil.isEmptyString(selectedSite) || selectedSite !== item.id) {
      setSelectedSite(item.id);
      let URL = `/api/v2.0/dashboard_count?siteId=${item.id}`;
      await axios.get(URL).then((response) => {
        let data = { location: { lat: parseFloat(response.data.data.latitude), lng: parseFloat(response.data.data.longitude) }, CardValue: response.data.data };
        setSelected(data);
        setTempSelected(data);
      });
    } else {
      setSelected(tempSelected);
    }
  };

  const DisplayRecord = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={JSON.stringify(data)}
            onConfirm={() => {
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };
  const onSelectMarker = (item) => {
    // alert(JSON.stringify(item))
    history.push({ pathname: REACT_URL_SITE_DASHBOARD, state: { selected: selected.CardValue, siteId: item.id } });
  };
  const [searchBox, setSearchBox] = useState(null);

  const onPlacesChanged = () => console.log(searchBox.getPlaces());
  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };
  useEffect(() => {
    if (store.site.siteList.site !== undefined) {
      let storedata = store.site.siteList.site;
      filterlocation(storedata);
    }
  }, [store.site.siteList]);

  const filterlocation = (storedata) => {
    let RequiredData = [];
    storedata.map((item) => RequiredData.push({ address: item.address, id: item.id }));
    let result = [];
    RequiredData.map((item) =>
      item.address.latitude ? result.push({ location: { lat: parseFloat(item.address.latitude), lng: parseFloat(item.address.longitude) }, id: item.id }) : ''
    );
    if (result.length > 0) {
      setCenter(result[result.length - 1].location);
      Setfinallocation(result);
    }
  };

  const centerSite = (site) => {
    if (!CommonUtil.isEmpty(site.address.latitude)) {
      setCenter({ lat: parseFloat(site.address.latitude), lng: parseFloat(site.address.longitude) });
      setZoom(16);
    } else {
      setZoom(3);
    }
  };

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    // setCurrentPosition({ lat, lng })
  };

  const exampleMapStyles = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#E2E4EB',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#FFFFFF',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ];

  const image = {
    url: 'https://img.icons8.com/color/50/000000/place-marker--v2.png',
    // size: google.maps.Size(20, 32),
    // origin: google.maps.Point(0, 0),
    // anchor: google.maps.Point(0, 32),
  };

  const handleZoomChange = () => {
    if (map) {
      if (map.getZoom() < 2) {
        map.setZoom(2);
      }
    }
  };

  useEffect(() => {
    const close = document.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];

    // Add a Click Event Listener to the button
    close.addEventListener('click', () => {
      setZoom(3);
    });
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.site.siteList)) {
      var siteList = [];
      store.site.siteList.site.map((site) => {
        if (!CommonUtil.isEmpty(site.address) && (site.address.latitude !== '0' || site.address.longitude !== '0')) {
          siteList.push(site);
        }
      });
      setSites(siteList);
    }
  }, [store.site.siteList]);

  return (
    <>
      {/* <LoadScript id='script-loader' googleMapsApiKey='AIzaSyAzPgExNy5JvZAyHer0H01gBEdARUCPh6w' language={language} libraries={lib}> */}
      <GoogleMap
        onLoad={(map) => {
          setMap(map);
        }}
        options={{
          styles: exampleMapStyles,
          fullscreenControl: false,
        }}
        mapContainerStyle={{
          height: '80vh',
          width: '100%',
        }}
        zoom={zoom}
        center={center}
        onZoomChanged={() => handleZoomChange()}
      >
        <Box display='flex' flexDirection='row-reverse' p={1} m={1} bgcolor='red'>
          <Box p={1} bgcolor='grey.300'>
            <Button variant='contained' color='default' size='medium' startIcon={<FullscreenExitIcon />} onClick={() => props.toggleFullScreen(false)}></Button>
          </Box>
        </Box>

        {finallocation.map((item) => {
          return (
            <Marker
              key={item.id}
              position={item.location}
              onMouseOver={() => onSelectHover(item)}
              onMouseOut={() => setSelected({})}
              onClick={() => onSelectMarker(item)}
              // icon={{ url: logo }}
              icon={image}
              onDragEnd={(e) => onMarkerDragEnd(e)}
              draggable={false}
            ></Marker>
          );
        })}

        {selected.location && (
          <InfoWindow
            options={{ maxWidth: 200, padding: 50 }}
            position={selected.location}
            clickable={true}
            visible={true}
            onCloseClick={() => setSelected({})}
            options={{ pixelOffset: { width: 0, height: -50 } }}
          >
            <div>
              <Typography gutterBottom>
                {' '}
                <ApartmentIcon color='disabled' />
                &nbsp; {t('COMMON184')} : <span style={{ color: 'green' }}>{selected.CardValue.siteName} </span>
              </Typography>
              <Typography gutterBottom>
                {' '}
                <PhonelinkSetupIcon color='disabled' />
                &nbsp; {t('COMMON185')} <span style={{ color: 'green' }}>({selected.CardValue.deviceCount}) </span>
              </Typography>
              <Typography gutterBottom>
                {' '}
                <MeetingRoomOutlinedIcon color='disabled' />
                &nbsp; {t('COMMON186')} <span style={{ color: 'green' }}>({selected.CardValue.doorCount}) </span>
              </Typography>
              <Typography gutterBottom>
                {' '}
                <PersonOutlineOutlinedIcon color='disabled' />
                &nbsp; {t('COMMON187')} <span style={{ color: 'green' }}>({selected.CardValue.employeeCount})</span>
              </Typography>
              <Typography gutterBottom>
                {' '}
                <EventOutlinedIcon color='disabled' />
                &nbsp; {t('COMMON188')} <span style={{ color: 'green' }}>({selected.CardValue.eventCount})</span>
              </Typography>
              <Typography gutterBottom>
                {' '}
                <LocationOnOutlinedIcon color='disabled' />
                &nbsp; {t('COMMON189')} :{' '}
                <span style={{ color: 'green' }}>
                  {selected.CardValue.country} - {selected.CardValue.cityName}{' '}
                </span>
              </Typography>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      {/* </LoadScript> */}
    </>
  );
}
export default GoogleMapDialog;
