import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import { useHistory } from 'react-router-dom';
import BrowserLanguage from '../components/Util/BrowserLanguage';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Geocode from 'react-geocode';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { IconButton } from '@mui/material';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import CommonUtil from './Util/CommonUtils';
import { toast } from 'react-toastify';
import { timeZoneData } from '../pages/Site/AddressFormData';
var timeZone = localStorage.locationInfo ? JSON.parse(localStorage.locationInfo) : {};

const lib = ['places'];
let language = BrowserLanguage.getDefaultLanguage();

function AddressFromMap(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [center, setCenter] = useState({ lat: CommonUtil.getLocationDetails('latitude'), lng: CommonUtil.getLocationDetails('longitude') });
  const [zoom, setZoom] = useState(10);
  const [selected, setSelected] = useState('');
  const [tempSelected, setTempSelected] = useState('');
  const [finallocation, Setfinallocation] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [map, setMap] = useState(null);

  const [searchBox, setSearchBox] = useState(null);

  Geocode.setApiKey('AIzaSyAzPgExNy5JvZAyHer0H01gBEdARUCPh6w');
  Geocode.setLanguage('en');

  const getAddress = (lat, lng) => {
    var location = {
      lat: '',
      lng: '',
      addressLine1: '',
      city: '',
      state: '',
      country: '',
      pincode: '',
      timeZone: '',
    };
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        location.lat = lat;
        location.lng = lng;

        const address = response.results[0].formatted_address;
        const addressObject = address.split(',');

        if (addressObject.length < 2) {
          toast.warning('Address details not found');
          return;
        }

        if (addressObject.length > 0) {
          if (addressObject.length > 3) {
            var temp = addressObject[addressObject.length - 2].trim();
            var state = temp.split(' ');
            if (state.length > 1) {
              location.state = state[0];
              location.pincode = state[state.length - 1];
            } else {
              location.state = addressObject[addressObject.length - 2].trim();
            }
            location.city = addressObject[addressObject.length - 3];
            location.country = addressObject[addressObject.length - 1];
            var tempAddress = '';
            addressObject.map((a, index) => {
              if (index < addressObject.length - 3) {
                tempAddress = tempAddress + ' ' + a;
              }
            });
            location.addressLine1 = tempAddress;
          } else if (addressObject.length > 2) {
            location.city = addressObject[addressObject.length - 3];
            var temp = addressObject[addressObject.length - 2].trim();
            var state = temp.split(' ');
            if (state.length > 1) {
              location.state = state[0];
              location.pincode = state[state.length - 1];
            } else {
              location.state = addressObject[addressObject.length - 2].trim();
            }
            location.country = addressObject[addressObject.length - 1];
          } else if (addressObject.length > 2) {
            var temp = addressObject[addressObject.length - 2].trim();
            var state = temp.split(' ');
            if (state.length > 1) {
              location.state = state[0];
              location.pincode = state[state.length - 1];
            } else {
              location.state = addressObject[addressObject.length - 2].trim();
            }
            location.country = addressObject[addressObject.length - 1];
          } else {
            location.country = addressObject[addressObject.length - 1];
          }
        }
        timeZoneData.map((item) => {
          const timezonevalue = item.name.slice(4, 7) + item.name.slice(8, 10);
          if (timeZone.utc_offset === timezonevalue) {
            location.timeZone = item.id;
          }
        });

        props.onSelectLocation(location);
        handleClose();
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleZoomChange = () => {
    if (map) {
      if (map.getZoom() < 2) {
        map.setZoom(2);
      }
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onPlacesChanged = () => {
    var data = searchBox.getPlaces();

    try {
      var location = {
        lat: '',
        lng: '',
        addressLine1: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        timeZone: '',
      };

      if (!CommonUtil.isEmpty(data[0].address_components)) {
        data[0].address_components.map((e) => {
          console.log(e);
          switch (e.types[0]) {
            case 'postal_code':
              location.pincode = e.long_name;
              break;
            case 'country':
              location.country = e.long_name;
              break;
            case 'administrative_area_level_1':
              location.state = e.long_name;
              break;
            case 'administrative_area_level_2':
              location.city = e.long_name;
              break;
            case 'locality':
              if (CommonUtil.isEmptyString(location.city)) {
                location.city = e.long_name;
              }
              break;
            case 'locality':
              location.addressLine1 = e.long_name;
              break;
          }
        });
      }
      Geocode.fromLatLng(data[0].geometry.location.lat(), data[0].geometry.location.lng()).then(
        (response) => {
          location.lat = data[0].geometry.location.lat();
          location.lng = data[0].geometry.location.lng();
          const address = response.results[0].formatted_address;
          const addressObject = address.split(',');

          if (addressObject.length < 2) {
            return;
          }

          if (addressObject.length > 0) {
            if (addressObject.length > 3) {
              var tempAddress = '';
              addressObject.map((a, index) => {
                if (index < addressObject.length - 3) {
                  tempAddress = tempAddress + ' ' + a;
                }
              });
              location.addressLine1 = tempAddress;
            }
          }
          timeZoneData.map((item) => {
            const timezonevalue = item.name.slice(4, 7) + item.name.slice(8, 10);
            if (timeZone.utc_offset === timezonevalue) {
              location.timeZone = item.id;
            }
          });
          props.onSelectLocation(location);
          handleClose();
        },
        (error) => {
          console.error(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };
  const options = {
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: 10,
    },
  };
  return (
    <div>
      <MyLocationIcon onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
        <DialogContent>
          <LoadScript id='script-loader' googleMapsApiKey='AIzaSyAzPgExNy5JvZAyHer0H01gBEdARUCPh6w' language={language === 'idn' ? 'id' : language} libraries={lib}>
            <GoogleMap
              onClick={(e) => {
                getAddress(e.latLng.lat(), e.latLng.lng());
                console.log(e);
              }}
              onLoad={(map) => {
                setMap(map);
              }}
              mapContainerStyle={{
                height: '500px',
                width: '100%',
              }}
              zoom={zoom}
              center={center}
              onZoomChanged={() => handleZoomChange()}
              options={options}
            >
              <StandaloneSearchBox style={{ zIndex: '999' }} onPlacesChanged={onPlacesChanged} onLoad={onSBLoad}>
                <input
                  type='text'
                  placeholder={t('COMMON183')}
                  style={{
                    boxSizing: 'border-box',
                    border: `1px solid transparent`,
                    width: `270px`,
                    height: `40px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    margin: 'center',
                    textOverflow: `ellipses`,
                    position: 'absolute',
                    top: '10px',
                    marginLeft: '68%',
                  }}
                />
              </StandaloneSearchBox>
            </GoogleMap>
          </LoadScript>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default AddressFromMap;
