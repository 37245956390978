import { Box, Grid, Dialog, DialogTitle, MenuItem, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { modifyFingerPrintThreshold, modifyFingerPrintThresholdGet } from '../../actions/DeviceOperationsApiCalls';
import { getDevices } from '../../actions/DeviceApiCalls';
import CommonUtil from '../../components/Util/CommonUtils';

export default function ModifyFingerPrintThreshold(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [popupState, setPopupState] = React.useState(props.popupState);
  const [selectedDevices, setSelectedDevice] = React.useState();
  const [param, setParam] = React.useState();
  const [error, setError] = React.useState(false);

  const handleChange = (event) => {
    setParam(event.target.value);
    setError(false);
  };


  const loadDevices = () => {
    dispatch(getDevices('', '', '', '', 1, 1000));
  };
  const deviceOperations = () => {

    if (props.devices.length < 1) {
      toast.warning(t('DEVI0014'));
      handleClose();
      return;
    }

    if (props.devices.length > 1) {
      toast.warning(t('DEVI0039'));
      handleClose();
      return;
    }

    setOpen(true);

    var ids = '';
    const { devices } = props;

    // eslint-disable-next-line
    devices.map((device, index) => {
      if (index === devices.length - 1) ids = ids + device.id;
      else ids = ids + device.id + ',';
    });

    setSelectedDevice(ids);

    // confirmAlert({
    //     customUI: ({ onClose }) => {
    //         return (
    //             <ConfirmModal open={true} text={confirmMessage} onConfirm={() => { dispatch(performDeviceOperations(ids, operation)); onClose() }} onClose={() => onClose()} />
    //         );
    //     }
    // });
  };
  const handleClose = () => {
    setOpen(false);
    props.onSuccess();
    popupState.close();
  };
  useEffect(() => {
    if(!CommonUtil.isEmpty(props.devices)){
    dispatch(
      modifyFingerPrintThresholdGet(props.devices[0].id, (data) => {
        setParam(data);
      })
    )
  }
  }, []);

  const handleSubmit = () => {
    if (CommonUtil.isEmpty(param)) {
      setError(true);
      toast.error(t('DEVI0071'));
      return;
    }
    if (param < 35 || param > 70) {
      setError(true);
      toast.error(t('DEVI0041'));
      return;
    }

    dispatch(
      modifyFingerPrintThreshold(selectedDevices, param, () => {
        popupState.close();
        loadDevices();
        setParam(CommonUtil.isEmpty(param) ? 35 : param);
      })
    );
  };

  return (
    <>
      <MenuItem onClick={() => deviceOperations()}>{t('DEVI0035')}</MenuItem>

      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-filter-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('DEVI0035')}</Box>
            <CancelIcon onClick={handleClose} style={{ color: 'red', background: 'white', borderRadius: '50%' }} fontSize='small' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error}
                    name='param'
                    value={param}
                    label={t('DEVI0040') + ' *'}
                    helperText={t('DEVI0041')}
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    type='number'
                    InputProps={{ inputProps: { min: 35, max: 70 } }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </>
  );
}