import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import EditIcon from '@material-ui/icons/Edit';
import { createAccDevice } from "../../actions/DeviceActions";
import { compose } from 'redux'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

const styles = theme => ({
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 250
    }
  },
  formcontrol: {
    margin: theme.spacing(1),
    width: 250
  },
});


const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="dilog-form-header" {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="dilog-form-closeButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class AddNewDevice extends React.Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDialogSave = this.handleDialogSave.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleDialogOK = this.handleDialogOK.bind(this);
    this.state = {
      dialogText: '',
      isDialogOpen: false,
      isChecked: false,
      companyId: '1',
      id: '',
      deviceName: '',
      ipAddress: '',
      alias: '',
      acpanelType: '',
      commType: '',
      enabled: '',
      isRegistrationDevice: '',
      machineType: '',
      sn: '',
      fwVersion: '',
      commPort: '',
      comPwd: ''
    }
  }

  componentDidMount() {
    const { id, commPort, comPwd, deviceName, ipAddress, alias, acpanelType, commType, enabled, isRegistrationDevice, machineType, sn, fwVersion } = this.props.data
    this.setState({
      'id': id,
      'deviceName': deviceName,
      'ipAddress': ipAddress,
      'alias': alias,
      'acpanelType': acpanelType,
      'commType': commType,
      'enabled': enabled,
      'isRegistrationDevice': isRegistrationDevice,
      'machineType': machineType,
      'sn': sn,
      'fwVersion': fwVersion,
      'commPort': commPort,
      'comPwd': comPwd

    })
  }

  componentDidUpdate(prevProps) {
    console.log(" data " + JSON.stringify(this.props.data))

    if (this.props.errors !== prevProps.errors) {
      const { id, commPort, comPwd, deviceName, ipAddress, alias, acpanelType, commType, enabled, isRegistrationDevice, machineType, sn, fwVersion } = this.props.data
      this.setState({
        'id': id,
        'deviceName': deviceName,
        'ipAddress': ipAddress,
        'alias': alias,
        'acpanelType': acpanelType,
        'commType': commType,
        'enabled': enabled,
        'isRegistrationDevice': isRegistrationDevice,
        'machineType': machineType,
        'sn': sn,
        'fwVersion': fwVersion,
        'commPort': commPort,
        'comPwd': comPwd

      })
    }

  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
     
    e.preventDefault();
    const device = this.state;
    const newDevice = {
      id: device.id,
      companyId: device.companyId,
      deviceName: device.deviceName,
      ipAddress: device.ipAddress,
      alias: device.alias,
      acpanelType: device.acpanelType,
      commType: device.commType,
      enabled: device.enabled,
      isRegistrationDevice: device.isRegistrationDevice,
      machineType: device.machineType,
      sn: device.sn,
      fwVersion: device.fwVersion,
      commPort: device.commPort,
      comPwd: device.comPwd
    }
    this.props.createAccDevice(newDevice, this.props.source);
  }

  handleDialogSave() {
    console.log('Clicked Save');
    this.setState({
      isDialogOpen: false
    });
  }

  handleDialogOK() {
    console.log('Clicked OK');
    this.setState({
      isDialogOpen: false
    });
  }

  handleDialogClose() {
    console.log('Clicked Close');
    this.setState({
      isDialogOpen: false
    });
  }

  handleChange(e) {
    const target = e.target;
    const value = target.checked;

    this.setState({
      isChecked: value,
      isDialogOpen: true
    }, () => { console.log('Open Dialog') });
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div>
        {/*  */}

        {this.props.type === 'add' && (
          <Button variant="contained" color="primary" size="small" startIcon={<AddCircleOutlineRoundedIcon />} onClick={this.handleChange} checked={this.state.isChecked}>
           {t("buttonAddNewDevice")}
          </Button>
        )}

        {this.props.type === 'edit' && (
          <IconButton color="primary"
            onClick={this.handleChange} checked={this.state.isChecked}
          >
            <EditIcon />0
          </IconButton>
        )}



        <Dialog
          open={this.state.isDialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

          <DialogTitle id="customized-dialog-title" onClose={this.handleDialogClose}>{"Add New Device"}</DialogTitle>

          <DialogContent>

            <form className={classes.form} noValidate onSubmit={this.onSubmit} autoComplete="off">
              <div>
                <TextField
                  //error = {this.props.errors.deviceName ? true : false}
                  helperText={this.props.errors.deviceName}
                  autoFocus
                  onChange={this.onChange}
                  name="deviceName"
                  margin="dense"
                  id="name"
                  label="Device Name"
                  type="text"
                  fullWidth
                  value={this.state.deviceName} />
                <TextField onChange={this.onChange} name="ipAddress" margin="dense" label="IP Address" type="text" fullWidth value={this.state.ipAddress} />
              </div>
              <div>
                <TextField
                  name="commPort"
                  value={this.state.commPort}
                  onChange={this.onChange}
                  margin="dense" id="name" label="Communication Port" type="text" fullWidth />
                <TextField
                  name="comPwd"
                  value={this.state.comPwd}
                  onChange={this.onChange}
                  margin="dense" id="name" label="Communication Password" type="text" fullWidth />
              </div>
              <DialogActions className="dilog-form-buttons">
                <Button className="btn-default" color="primary" type="submit" variant="contained">
                  Save and New
                    </Button>
                <Button color="primary" onClick={this.handleDialogOK} variant="contained">
                  OK
                    </Button>
                <Button color="primary" onClick={this.handleDialogClose} variant="contained">
                  Cancel
                    </Button>
              </DialogActions>
            </form>



          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  accDevice: state.AccDevice.accDevice
});

export default compose(withTranslation(), connect(mapStateToProps, { createAccDevice }), withStyles(styles, { withTheme: true }))(AddNewDevice);