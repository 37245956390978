import { Box, Grid, Dialog, DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { setInOutState } from '../../actions/DeviceOperationsApiCalls';
import { getDevices, getDeviceReaderState } from '../../actions/DeviceApiCalls';
import CommonUtil from '../../components/Util/CommonUtils';
import axios from 'axios';
import { JAVA_API_DEVICE } from '../../actions/ApiEndPoints';

export default function SetInOutState(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [popupState, setPopupState] = React.useState(props.popupState);
  const [selectedDevices, setSelectedDevice] = React.useState();
  const [param, setParam] = React.useState();
  const [id, setId] = React.useState('');
  const [error, setError] = React.useState(false);
  useEffect(() => {
    if (id !== '') {
      axios.get(JAVA_API_DEVICE + '/' + 'readerState' + '/' + id).then((response) => {
        setParam(response.data.data.Reader1IOState);
      });
    }
  }, [id]);

  const handleChange = (event) => {
    setParam(event.target.value);
  };

  const loadDevices = () => {
    dispatch(getDevices('', '', '', '', 1, 1000));
  };

  const deviceOperations = () => {
    if (props.devices.length < 1) {
      toast.warning(t('DEVI0014'));
      handleClose();
      return;
    }

    if (props.devices.length > 1) {
      toast.warning(t('DEVI0039'));
      handleClose();
      return;
    }

    console.log(JSON.stringify(props.devices[0].id));
    setId(props.devices[0].id);

    setOpen(true);

    var ids = '';
    const { devices } = props;
    // eslint-disable-next-line
    devices.map((device, index) => {
      if (index === devices.length - 1) ids = ids + device.id;
      else ids = ids + device.id + ',';
    });

    setSelectedDevice(ids);

    // confirmAlert({
    //     customUI: ({ onClose }) => {
    //         return (
    //             <ConfirmModal open={true} text={confirmMessage} onConfirm={() => { dispatch(performDeviceOperations(ids, operation)); onClose() }} onClose={() => onClose()} />
    //         );
    //     }
    // });
  };

  const handleClose = () => {
    setOpen(false);
    props.onSuccess();
    popupState.close();
  };

  const handleSubmit = () => {
    if (param === undefined || param === '') {
      setError(true);
      toast.error(t('DEVI0043'));
      return;
    }
    dispatch(
      setInOutState(selectedDevices, param, () => {
        popupState.close();
        loadDevices();
      })
    );
  };

  return (
    <>
      <MenuItem onClick={() => deviceOperations()}>{t('DEVI0034')}</MenuItem>

      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-filter-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('DEVI0034')}</Box>
            <CancelIcon onClick={handleClose} style={{ color: 'red', background: 'white', borderRadius: '50%' }} fontSize='small' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('DEVI0034') + ' *'}</InputLabel>
                    <Select native error={error} label={t('DEVI0034') + ' *'} name='param' onChange={handleChange} value={param ? param : ''}>
                      {/* <MenuItem value='0'>
                        <em>{t('COMMON175')}</em>
                      </MenuItem>
                      <MenuItem value='1'>
                        <em>{t('COMMON176')}</em>
                      </MenuItem> */}
                      <option value='0' >{t("COMMON175")}</option>
                      <option value='1' >{t("COMMON176")}</option>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
