import { LEVEL_DEPT_LIST, LEVEL_DEPT_SELECT_LIST, LEVEL_DOOR_LIST, LEVEL_DOOR_SELECT_LIST, LEVEL_LIST, LEVEL_PERSON_LIST, LEVEL_PERSON_SELECT_LIST } from "../actions/types";

const initialState = {
    levelDoors: [],
    selectLevelDoors: [],
    levelPersons: [],
    selectLevelPersons: [],
    levelDepartments: [],
    selectLevelDepartments: [],
    levels: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LEVEL_LIST:
            return {
                ...state, 
                levels: action.payload
            }
        case LEVEL_DOOR_LIST:
            return {
                ...state,
                levelDoors: action.payload
            }
        case LEVEL_DOOR_SELECT_LIST:
            return {
                ...state,
                selectLevelDoors: action.payload
            }
        case LEVEL_PERSON_LIST:
            return {
                ...state,
                levelPersons: action.payload
            }
        case LEVEL_PERSON_SELECT_LIST:
            return {
                ...state,
                selectLevelPersons: action.payload
            }
        case LEVEL_DEPT_LIST:
            return {
                ...state,
                levelDepartments: action.payload
            }
        case LEVEL_DEPT_SELECT_LIST:
            return {
                ...state,
                selectLevelDepartments: action.payload
            }
        default:
            return state;
    }
}
