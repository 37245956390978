import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getDevices, removeDevice } from '../../actions/DeviceApiCalls';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditDevice from './EditDevice';
import ConfirmModal from '../../components/ConfirmModal';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import DeviceControlOperations from './DeviceControlOperations';
import { REACT_URL_DEVICE_ADD, REACT_URL_DEVICE_LIST } from '../../actions/EndPoints';
import FilterDevice from './FilterDevice';
import Headerbar from '../../components/HeaderBar';
import DeviceSetupOperations from './DeviceSetupOperations';
import DeviceCommunicationOperations from './DeviceCommunicationOperations';
import Pagination from '../../components/Pagination';
import CommonUtil from '../../components/Util/CommonUtils';
import { ReactComponent as Add } from '../../assets/icons/add.svg';
import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
import {} from '@material-ui/core';
import { Box, Button, Grid, IconButton, Tooltip, Typography, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import DeviceOperationDialog from './DeviceOperationDialog';
import DeviceViewAndGetOperations from './DeviceViewAndGetOperations';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Fab } from '@material-ui/core';

export default function Device() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [devices, setDevices] = useState(store.AccDevice.accDeviceList);
  const [selectedDevices, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateDevice, setUpdateDevice] = useState({});
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  //const [query, setQuery] = React.useState({
  const [query] = React.useState({
    page: 0,
    pageSize: 10,
    sn: '',
    deviceName: '',
    ipAddress: '',
    alias: '',
  });

  // useEffect(() => {
  //   loadDevices();
  // }, []);

  const [filter, setFilter] = useState({
    sn: '',
    deviceName: '',
    ipAddress: '',
    alias: '',
  });

  const defaultFilter = {
    sn: '',
    deviceName: '',
    ipAddress: '',
    alias: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      sn: filterData.sn,
      deviceName: filterData.deviceName,
      ipAddress: filterData.ipAddress,
      alias: filterData.alias,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadDevices(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    onSelectionChange([]);
    if (!CommonUtil.isEmpty(store.AccDevice.accDeviceList)) {
      setTotalRecords(store.AccDevice.accDeviceList.totalConunt);
      setPage(store.AccDevice.accDeviceList.curPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setDevices(store.AccDevice.accDeviceList.devices);
    // eslint-disable-next-line
  }, [store.AccDevice.accDeviceList]);

  const loadDevices = (page, rowsPerPage) => {
    dispatch(getDevices(filter.alias, filter.sn, filter.deviceName, filter.ipAddress, page + 1, rowsPerPage));
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  //   dispatch(getDevices(query.alias, query.sn, query.deviceName, query.ipAddress, newPage, rowsPerPage));
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  //   dispatch(getDevices(query.alias, query.sn, query.deviceName, query.ipAddress, 0, parseInt(event.target.value, 10)));
  // };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadDevices(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadDevices(0, pageSize);
  };

  const deleteDevice = (data) => {
    if (data.length < 1) {
      toast.warning(t('DEVI0014'));
      return;
    }
    var ids = '';
    // eslint-disable-next-line
    data.map((device, index) => {
      if (index === data.length - 1) ids = ids + device.id;
      else ids = ids + device.id + ',';
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('DEVI0015')}
            onConfirm={() => {
              dispatch(removeDevice(ids, () => loadDevices(page, rowsPerPage)));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const breadcrumb = [{ path: REACT_URL_DEVICE_LIST, name: t('DEVI0001') }];

  const columns = [
    { field: 'alias', title: t('COMMON014'), tooltip: t('DEVI0002') },
    { field: 'sn', title: t('COMMON055'), tooltip: t('DEVI0003') },
    { field: 'ipAddress', title: t('COMMON056'), tooltip: t('DEVI0004') },
    { field: 'deviceName', title: t('COMMON057'), tooltip: t('DEVI0005') },
    { field: 'isRegistrationDevice', title: t('COMMON058'), tooltip: t('DEVI0006') },
    { field: 'fwVersion', title: t('COMMON059'), tooltip: t('DEVI0007') },
    {
      field: 'deviceStatus',
      title: t('COMMON001'),
      tooltip: t('COMMON001'), 
      render: (rowData) => <span style={{ fontWeight: 'bold', color: rowData.deviceStatus === 'Online' ? 'green' : 'red' }}>{rowData.deviceStatus}</span>,
    },
  ];

  const actions = [
    {
      icon: () => <EditOutlinedIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateDevice(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <DeleteOutlineIcon style={{ color: '#3d4977' }} />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteDevice([rowData]);
      },
    },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage,
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        {(store.security.user.client_id === '38c42726ab234fcda88ee9dc418637a0' || totalRecords < 10) && (
          <Tooltip title={t('COMMON009')}>
            <Fab
              color='secondary'
              size='small'
              aria-label={t('COMMON009')}
              component={Link}
              to={{
                pathname: REACT_URL_DEVICE_ADD + localStorage.getItem('companyCode'),
                page: 'add',
              }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        )}
      </Breadcrumb>

      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon style={{ color: '#3d4977', height: 28, width: 28 }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1}>
          <Tooltip title={t('COMMON004')}>
            <IconButton color='secondary' aria-label={t('COMMON004')} onClick={() => deleteDevice(selectedDevices)}>
              <DeleteOutlineIcon style={{ color: '#3d4977', height: 28, width: 28 }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1}>
          <DeviceControlOperations devices={selectedDevices} onSuccess={() => loadDevices(page, rowsPerPage)} />
        </Box>
        <Box p={1}>
          <DeviceSetupOperations devices={selectedDevices} onSuccess={() => loadDevices(page, rowsPerPage)} />
        </Box>
        <Box p={1}>
          <DeviceViewAndGetOperations devices={selectedDevices} onSuccess={() => loadDevices(page, rowsPerPage)} />
        </Box>
        <Box p={1} flexGrow={1}>
          <DeviceCommunicationOperations devices={selectedDevices} onSuccess={() => loadDevices(page, rowsPerPage)} />
        </Box>
        <Box p={1}>
          <FilterDevice page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={devices}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
      {/* <Pagination
        count={totalRecords}
        page={page}
        onChangePage={(event, newPage) => handleChangePage(event, newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
      /> */}
      {openEditDialog && (
        <EditDevice
          device={updateDevice}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadDevices(page, rowsPerPage);
          }}
        />
      )}
      {/* <DeviceOperationDialog open={true} devices={devices ? devices : []} /> */}
    </Grid>
  );
}
