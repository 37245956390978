import { Box, Grid, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartments, deleteDepartment } from '../../actions/organization/DepartmentApiCalls';
import AddDepartment from './AddDepartment';
import ConfirmModal from '../../components/ConfirmModal';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import FilterDepartment from './FilterDepartment';
import Pagination from '../../components/Pagination';
import CommonUtil from '../../components/Util/CommonUtils';
import { Tooltip, Fab } from '@material-ui/core';
import Breadcrumb from '../../components/Breadcrumb';
// import { ReactComponent as Add } from '../../assets/icons/add.svg';
// import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
// import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
// import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export default function Department() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});

  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [state, setState] = React.useState({
    id: '',
    name: '',
    code: '',
    parentDepartment: '',
    description: '',
  });

  useEffect((props) => {
    loadDepartments(page, rowsPerPage);
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.department.departmentList)) {
      setTotalRecords(store.department.departmentList.totalCount);
      setPage(store.department.departmentList.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.department.departmentList.department);
  }, [store.department.departmentList]);

  const loadDepartments = (page, rowsPerPage) => {
    dispatch(getDepartments(page + 1, rowsPerPage, true));
    onSelectionChange([]);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(getDepartments(newPage + 1, rowsPerPage, true));
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    dispatch(getDepartments(1, pageSize, true));
  };

  const openAddDepartmentDialog = () => {
    setUpdateObject(state);
    setOpenEditDialog(true);
  };

  const deleteRecord = (data) => {
    const Msg = ({ closeToast, toastProps }) => (
      <div>
        Lorem ipsum dolor
        <br />
        Lorem ipsum dolor
        <br />
      </div>
    );

    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];
    data.map((record, index) => {
      idsToDelete[index] = record.id;
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                
                deleteDepartment(idsToDelete, (data) => {

                  handlError(data, idsToDelete);
                })
              );

              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handlError = (data, idsToDelete) => {
    if (data.code == 'OMSI0073') {
      toast.success(data.message);
      loadDepartments(page, rowsPerPage);
    } 
    
    else if (data.code == 'CASE00641' && idsToDelete.length >= 1) {
      if (data.data.data.error.length === idsToDelete.length) {
        toast.error(
          <div>
            {t('DEPT018')}
            <br />
            {/* {1 + '. ' + t('DEPT014')}
            <br />
            {2 + '. ' + t('DEPT015')} */}
            <br />
          </div>
        );
        loadDepartments(page, rowsPerPage);
      } else {
        toast.warning(
          <div>
            {t('DEPT018')}
            <br />
            {1 + '. ' + t('DEPT014')}
            <br />
            {2 + '. ' + t('DEPT015')}
            <br />
          </div>
        );
        loadDepartments(page, rowsPerPage);
      }
    } else {
      toast.error(data.data.data.error[0].message);
      loadDepartments(page, rowsPerPage);
    }
  };

  const findDepartmentById=(parentId)=>{
    var parentname;
      data.map((dept)=>{
        if(dept.id===parentId){
           parentname = dept.name; 
        }
      });
      return parentname;
  }

  const breadcrumb = [{ path: '', name: t('DEPT001') }];
  const columns = [
    {
      field: 'name',
      title: t('COMMON044'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'code', title: t('COMMON045'), editable: 'never' },
    {
      render: (client) => {
        return CommonUtil.isEmptyString(client.parentId) ? '' : findDepartmentById(client.parentId);
       },
      title: t('COMMON050'),
      editable: 'never',
      
    },
    { 
     field: 'description',
     title: t('DEPT009'),
     editable: 'never',
     cellStyle: {
      minWidth: 200,
      maxWidth: 200,
    },
    },
  ];

  const options = { actionsColumnIndex: -1, search: false, selection: false, toolbar: false, paging: true, minBodyHeight: '40vh', initialPage: 1, pageSize: rowsPerPage,rowStyle: {
  overflowWrap: 'break-word'}};
  const actions = [
    {
      icon: () => <EditOutlinedIcon color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <DeleteOutlineIcon color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Tooltip title={t('COMMON009')}>
          <Fab color='secondary' size='small' aria-label={t('COMMON009')} onClick={openAddDepartmentDialog}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Breadcrumb>

      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => loadDepartments(0, rowsPerPage)}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {/* <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON004')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => deleteRecord(selectedData)}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box> */}
        <Box p={1}>
          <FilterDepartment props={state} rowsPerPage={rowsPerPage} />
        </Box>
      </Toolbar>

      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <AddDepartment
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadDepartments(page, rowsPerPage);
          }}
        />
      )}
    </Grid>
  );
}
