import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from './theme';
import { LeftSidebar } from './layout-blueprints';
import AccDeviceList from './components/device-management/AccDeviceList';
import AddNewDevice from './components/device-management/AddNewDevice';
import EditTimePeriod from './components/access-control/EditTimePeriod';
import TimeZone from './components/access-control/TimeZone';
import AddTimePeriod from './components/access-control/AddTimePeriod';
import AddDoor from './components/access-control/AddDoor';
import AccLevelDetails from './components/access-control/AccLevelDetails';
import {
  REACT_URL_DASHBOARD,
  REACT_URL_DOOR,
  REACT_URL_ADD_DOOR,
  REACT_URL_DEVICE_ADD,
  REACT_URL_DEVICE_LIST,
  REACT_URL_OPERATION_LOG,
  REACT_URL_READER_LIST,
  REACT_URL_ACCESS_LEVEL,
  REACT_URL_INTER_LOCK,
  REACT_URL_LINKAGE,
  REACT_URL_ANTIPASSBOOK,
  REACT_VERIFY_COMPANY,
  REACT_LOGOUT,
  REACT_LOGIN,
  REACT_URL_UPDATE_TIME_PERIOD,
  REACT_URL_TIMEPERIOD,
  REACT_URL_TIMEPERIOD_ADD,
  REACT_URL_LEVEL_DETAILS,
  REACT_URL_AUXIN,
  REACT_URL_AUXOUT,
  REACT_URL_PERSON_UPDATE,
} from './actions/EndPoints';
import AccDoorList from './components/device-management/AccDoorList';
import AccdeviceData from './components/device-management/AccdeviceData';
import AuxInput from './components/device-management/AuxInput';
import AuxOutput from './components/device-management/AuxOutput';
import AccLevel from './components/access-control/AccLevel';
//import Interlock from './components/access-control/Interlock';
//import AntiPassbook from './components/access-control/AntiPassbook';
import Linkage from './components/access-control/Linkage';
import Dashboard from './components/dashboard/dashboard';
import LoginForm from './pages/onboard/LoginForm';
import jwt_decode from 'jwt-decode';
import setJWTToken from './oauth-client/setJWTToken';
import { SET_ACCESS_TOKEN, SET_CURRENT_USER, SET_REFRESH_TOKEN } from './actions/types';
import { logout } from './oauth-client/OauthClientActions';
import store from './config/configureStore';
import VerifyCompany from './pages/onboard/VerifyCompany';
import Logout from './oauth-client/Logout';
import AddLinkage from './components/access-control/AddLinkage';
import CreateCompany from './pages/onboard/CreateCompany';
import EditLinkage from './components/access-control/EditLinkage';
import Device from './pages/device/Device';
import SearchDevice from './pages/device/SearchDevice';
import Door from './pages/door/Door';
import Reader from './pages/Reader/Reader';
import CreateTimePeriod from './pages/TimePeriod/CreateTimePeriod';
import TimePeriod from './pages/TimePeriod/TimePeriod';
import LevelDetails from './pages/level/LevelDetails';
import Level from './pages/level/Level';
import Auxin from './pages/Auxin/Auxin';
import Auxout from './pages/Auxout/Auxout';
import Interlock from './pages/Interlock/Interlock';
import Antipassback from './pages/Antipassback/Antipassback';
import OperationLog from './pages/OperationgLog/OperationLog';
import UserProfileScreen from './pages/profile/UserProfileScreen';
import CompanyProfileScreen from './pages/profile/CompanyProfileScreen';
import ForgotPasword from './pages/onboard/ForgotPasword';
import ResetPassword from './pages/onboard/ResetPassword';
import TodayTransactions from './pages/OperationgLog/TodayTransactions';
import ExceptionTransactions from './pages/OperationgLog/ExceptionTransactions';
import Transactions from './pages/OperationgLog/Transactions';
import axios from 'axios';
import UpdatePersons from './pages/Person/UpdatePerosns';

import {
  REACT_URI_LANDING,
  REACT_URI_CREATE_COMPANY,
  REACT_URI_SWITCH_COMPANY,
  REACT_URI_REGISTER_USER,
  REACT_URI_FORGOT_PASSWORD,
  REACT_URI_DASHBOARD,
  REACT_URI_PERSON,
  REACT_URI_DEPARTMENT,
  REACT_URI_DESIGNATION,
  REACT_URI_ADDRESS_TYPE,
  REACT_URI_SITE_TYPE,
  REACT_URI_SITE,
  REACT_URI_ZONE,
  REACT_URI_APPLICATIONS,
  REACT_URI_APPLICATIONS_PERSON,
} from './actions/organization/EndPoints';
import { REACT_URL_TRANSACTIONS, REACT_URL_TRANSACTIONSFORDAY, REACT_URL_EXCEPTIONTRANSACTIONS, REACT_URL_SITE_DASHBOARD } from './actions/EndPoints';
import Person from './pages/Person/Person';
import Department from './pages/Department/Department';
import Designation from './pages/Designation/Designation';
import AddressType from './pages/AddressType/AddressType';
import SiteType from './pages/SiteType/SiteType';
import SiteDashboard from './pages/SiteDashboard/SiteDashboard';
import Site from './pages/Site/Site';
import Zone from './pages/Zone/Zone';
import Applications from './pages/Membership/Applications';
import Membership from './pages/Membership/Membership';
import SecuredRoute from './oauth-client/SecureRoute';
import CommonResultPage from './pages/onboard/CommonResultPage';
import UpdatePerson from './pages/Person/UpdatePerson';
import RegisterUser from './pages/onboard/RegisterUser';
import ActivateAccount from './pages/onboard/ActivateAccount';
import { hideLoader, showLoader } from './actions/LoaderActions';
import CreateCompanyForm from './pages/onboard/CreateCompanyForm';
import RegisterCompanyForm from './pages/onboard/RegisterCompanyForm';
import Demo from './components/Demo';
import RegisterSite from './pages/Site/RegisterSite';
import AddressFromMap from './components/AddressFromMap';
import CustomInput from './components/CustomInput';
import Zlink from './pages/onboard/Zlink';
import SetAdministratorEnroller from './pages/device/SetAdministratorEnroller';
import { JAVA_URL_DEVICE_ROLE } from './actions/EndPoints';

const jwtToken = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);

if (jwtToken) {
  try {
    setJWTToken(jwtToken);
    const decoded_jwtToken = jwt_decode(jwtToken);
    store.dispatch({
      type: SET_CURRENT_USER,
      payload: decoded_jwtToken,
    });

    store.dispatch({
      type: SET_REFRESH_TOKEN,
      payload: localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN),
    });
    store.dispatch({
      type: SET_ACCESS_TOKEN,
      payload: localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
    });

    const currentTime = Date.now() / 1000;
    if (decoded_jwtToken.exp < currentTime) {
      store.dispatch(logout());
      window.location.href = '/';
    }
  } catch (error) {
    localStorage.removeItem('companyCode');
    localStorage.removeItem('companyName');
    localStorage.removeItem('companyId');
    localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
    localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN);
    window.location.href = '/';
  }
} else if (localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN)) {
  setJWTToken(localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN));
} else {
  localStorage.removeItem('companyCode');
  localStorage.removeItem('companyName');
  localStorage.removeItem('companyId');
  localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
  localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN);
}

axios.interceptors.request.use(
  (config) => {
    store.dispatch(showLoader());
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (config) => {
    store.dispatch(hideLoader());
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const Routes = () => {
  return (
    <ThemeProvider theme={MuiTheme}>
      <Switch>
        <Redirect exact from='/' to={REACT_LOGIN} />
        <Route path={REACT_LOGIN} component={LoginForm} />

        <Route path={REACT_VERIFY_COMPANY} component={VerifyCompany} />
        <Route exact path='/acc/create-company' component={CreateCompanyForm} />

        <Route exact path='/acc/register-company' component={RegisterCompanyForm} />
        <Route path={REACT_LOGOUT} component={Logout} />

        <Route path='/acc/forgot-password' component={ForgotPasword} />
        <Route path='/acc/reset-password' component={ResetPassword} />
        <Route path='/acc/result-page' component={CommonResultPage} />
        <Route path='/acc/sign-up' component={RegisterUser} />
        <Route path='/acc/activate-account' component={ActivateAccount} />
        <Route path='/demo' component={Demo} />
        <Route path='/acc/zlink' component={Zlink} />

        <LeftSidebar>
          {/* Organization Components start*/}

          <SecuredRoute exact path={REACT_URI_PERSON} component={Person} />
          <SecuredRoute path={REACT_URL_PERSON_UPDATE} component={UpdatePerson} />
          <SecuredRoute exact path={REACT_URI_DEPARTMENT} component={Department} />
          <SecuredRoute exact path={REACT_URI_DESIGNATION} component={Designation} />
          {/* <SecuredRoute exact path={REACT_URI_APPLICATIONS} component={Applications} /> */}
          <SecuredRoute exact path={REACT_URI_APPLICATIONS} component={Membership} />
          <SecuredRoute exact path={REACT_URI_ADDRESS_TYPE} component={AddressType} />
          <SecuredRoute exact path={REACT_URI_SITE_TYPE} component={SiteType} />
          <SecuredRoute exact path={REACT_URI_SITE} component={Site} />
          <SecuredRoute exact path={'/acc/site-form'} component={RegisterSite} />
          <SecuredRoute exact path={REACT_URI_ZONE} component={Zone} />
          <SecuredRoute exact path={'/acc/edit-person'} component={UpdatePersons} />

          {/* Organization Components end*/}

          <Route path={REACT_URL_DASHBOARD} component={Dashboard} />
          <Route path={'/acc/v1/device'} component={AccDeviceList} />
          <Route path={'/acc/v1/timeperiod'} component={TimeZone} />
          <Route path={'/acc/v1/timeperiod-add'} component={AddTimePeriod} />
          <Route path={REACT_URL_UPDATE_TIME_PERIOD} component={EditTimePeriod} />
          <Route path={REACT_URL_ADD_DOOR} component={AddDoor} />
          <Route path={REACT_URL_OPERATION_LOG} component={OperationLog} />
          <Route path={'/acc/v1/device-add'} component={AddNewDevice} />
          <Route path={'/acc/v1/auxin'} component={AuxInput} />
          <Route path={'/acc/v2/auxout'} component={AuxOutput} />
          <Route path={'/acc/v1/level'} component={AccLevel} />
          <Route path={'REACT_URL_ACCESS_LEVEL_DETAILS'} component={AccLevelDetails} />
          <Route path={REACT_URL_INTER_LOCK} component={Interlock} />
          <Route path={REACT_URL_LINKAGE} component={Linkage} />
          <Route path={REACT_URL_ANTIPASSBOOK} component={Antipassback} />
          <Route path={'/acc/v1/door'} component={AccDoorList} />
          <Route path='/acc/accdevicedata' component={AccdeviceData} />
          <Route path='/acc/addlinkage' component={AddLinkage} />
          <Route path='/acc/editLinkage' component={EditLinkage} />
          <Route path={REACT_URL_DEVICE_LIST} component={Device} />
          <Route path={REACT_URL_DEVICE_ADD + ':code'} component={SearchDevice} />
          <Route path={REACT_URL_DOOR} component={Door} />
          <Route path={REACT_URL_READER_LIST} component={Reader} />
          <Route path={REACT_URL_TIMEPERIOD} component={TimePeriod} />
          <Route path={REACT_URL_TIMEPERIOD_ADD} component={CreateTimePeriod} />
          <Route path={REACT_URL_ACCESS_LEVEL} component={Level} />
          <Route path={REACT_URL_LEVEL_DETAILS} component={LevelDetails} />
          <Route path={REACT_URL_AUXIN} component={Auxin} />
          <Route path={REACT_URL_AUXOUT} component={Auxout} />
          <Route path={'/acc/v2/interlock'} component={Interlock} />
          <Route path={'/acc/v2/antipassback'} component={Antipassback} />
          <Route path={'/acc/company-profile'} component={CompanyProfileScreen} />
          <Route path={'/acc/user-profile'} component={UserProfileScreen} />
          <Route path={REACT_URL_TRANSACTIONS} component={Transactions} />
          <Route path={REACT_URL_TRANSACTIONSFORDAY} component={TodayTransactions} />
          <Route path={REACT_URL_EXCEPTIONTRANSACTIONS} component={ExceptionTransactions} />
          <Route path={REACT_URL_SITE_DASHBOARD} component={SiteDashboard} />

          <Route path={JAVA_URL_DEVICE_ROLE} component={SetAdministratorEnroller} />
        </LeftSidebar>
      </Switch>
    </ThemeProvider>
  );
};

export default Routes;
