import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';

import Breadcrumb from '../../components/Breadcrumb';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getTimesegs, removeTimeperiod } from '../../actions/TimesegApiCalls';
import EditIcon from '@material-ui/icons/Edit';
import { REACT_URL_TIMEPERIOD, REACT_URL_TIMEPERIOD_ADD } from '../../actions/EndPoints';
import Headerbar from '../../components/HeaderBar';
import FilterTimePeriod from './FilterTimePeriod';
import { useHistory } from 'react-router-dom';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { confirmAlert } from 'react-confirm-alert';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ConfirmModal from '../../components/ConfirmModal';
import { toast } from 'react-toastify';
import SubmitButton from '../../components/SubmitButton';
import CommonUtil from '../../components/Util/CommonUtils';
import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
import { Box, Button, Grid, IconButton, Tooltip, Fab } from '@material-ui/core';
import { ReactComponent as Add } from '../../assets/icons/add.svg';
import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export default function TimePeriod() {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  // eslint-disable-next-line
  const [openEditDialog, setOpenEditDialog] = useState(false);
  // eslint-disable-next-line
  const [updateObject, setUpdateObject] = useState({});

  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: 1000,
    name: '',
  });

  const [filter, setFilter] = useState({
    name: '',
  });

  const defaultFilter = {
    name: '',
  };

  const applyFilter = (filterData) => {
    const query = {
      name: filterData.name,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setFilter({
      ...defaultFilter,
    });
  };

  useEffect(() => {
    loadTimePeriods(0, rowsPerPage);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    onSelectionChange([]);
    if (!CommonUtil.isEmpty(store.timeseg.timesegs)) {
      setTotalRecords(store.timeseg.timesegs.totalConunt);
      setPage(store.timeseg.timesegs.curPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.timeseg.timesegs.timeSegs);
    // eslint-disable-next-line
  }, [store.timeseg.timesegs]);

  useEffect(() => {
    setData(store.timeseg.timesegs.timeSeg);
  }, [store.timeseg.timesegs]);

  const loadTimePeriods = (page, rowsPerPage) => {
    dispatch(getTimesegs(filter.name, page + 1, rowsPerPage));
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadTimePeriods(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    loadTimePeriods(0, pageSize);
  };

  const options = {
    actionsColumnIndex: -1,
    search: false,
    selection: true,
    toolbar: false,
    paging: true,
    minBodyHeight: '40vh',
    initialPage: 1,
    pageSize: rowsPerPage
  };

  const breadcrumb = [
    //{ path: REACT_URL_TIMEPERIOD, name: t("COMMON017") },
    { path: REACT_URL_TIMEPERIOD, name: t('TIMEP001') },
  ];

  const columns = [
    {
      field: 'name',
      title: t('TIMEP013'),
      tooltip: t('tableHeaderTimePeriodName'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'remark', title: t('COMMON016'), editable: 'never' },
  ];

  const actions = [
    {
      icon: () => <EditOutlinedIcon  color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        openEditPage(rowData);
      },
    },
    {
      icon: () => <DeleteOutlineIcon color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  const openEditPage = (data) => {
    history.push({
      pathname: REACT_URL_TIMEPERIOD_ADD,
      state: { mode: 'Edit', data: data },
    });
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var ids = '';
    // eslint-disable-next-line
    data.map((record, index) => {
      if (index === data.length - 1) ids = ids + record.id;
      else ids = ids + record.id + ',';
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(removeTimeperiod(ids, () => loadTimePeriods(page, rowsPerPage)));
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  // eslint-disable-next-line
  const onPageChange = (page) => {
    setQuery({
      ...query,
      page: page,
    });
    loadTimePeriods();
  };

  // eslint-disable-next-line
  const onPageSizeChange = (pageSize) => {
    setQuery({
      ...query,
      page: 0,
      pageSize: pageSize,
    });
    loadTimePeriods();
  };

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Tooltip title={t('COMMON009')}>
          <Fab
          color='secondary' size='small' 
            aria-label={t('COMMON009')}
            onClick={() => {
              history.push({
                pathname: REACT_URL_TIMEPERIOD_ADD,
                state: { mode: 'Add' },
              });
            }}
          >
            <AddIcon/>
          </Fab>
        </Tooltip>
      </Breadcrumb>
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => resetFilter()}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON004')}>
            <IconButton color='secondary' aria-label={t('COMMON002')} onClick={() => deleteRecord(selectedData)}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Box p={1}>
          <FilterTimePeriod page={page} rowsPerPage={rowsPerPage} applyFilter={(data) => applyFilter(data)} />
        </Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
    </Grid>
  );
}
