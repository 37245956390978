import axios from "axios";
import { JAVA_API_DOOR } from "./ApiEndPoints";
import { toast } from 'react-toastify';
import { DOOR_ERRORS, DOOR_LIST, DOOR_VERIFYMODES } from "./types";
import {JAVA_URL_DOOR_OPERATIONSS} from "./EndPoints";
import { useTranslation } from 'react-i18next';

export const getDoors = (sn, doorName, page, size) => async dispatch => {
    try {
        const res = await axios.get(JAVA_API_DOOR + "?sn=" + sn + "&doorName=" + doorName + "&page=" + page + "&size="+size);
        if (res.status === 200 && res.data.code === "CASI0001") {
            dispatch({
                type: DOOR_LIST,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: DOOR_LIST,
                payload: []
            });
        }
    } catch {

    }

};

export const updateDoor = (payload, callBack) => async dispatch => {
    try {
        const res = await axios.put(JAVA_API_DOOR, payload);
        if (res.status === 200 && res.data.code === "CASI0008") {
            toast.success(res.data.message);
            callBack(res.data);
            dispatch({
                type: DOOR_ERRORS,
                payload: {}
            });
        } else {
            toast.error(res.data.message);
            dispatch({
                type: DOOR_ERRORS,
                payload: res.data
            });
        }
    } catch {

    }
}



export const getVerifyModes = (deviceId) => async dispatch => {
    try {
    
        const res = await axios.get(JAVA_API_DOOR+"/"+deviceId+"/verify_mode");
        if (res.status === 200 && res.data.code === "CASI0011") {
            dispatch({
                type: DOOR_VERIFYMODES,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: DOOR_VERIFYMODES,
                payload: []
            });
        }
        
    } catch {

    }

};

export const handleRemoteOpen = (openInterval,operationType,ids) => async dispatch => {
	const res = await axios.post(JAVA_URL_DOOR_OPERATIONSS.replace("{ids}", ids)+"?operationType="+operationType+"&openInterval="+openInterval); 
		// if(res.data.code === "DOOROP01"){
            if(res.data.code === "CASI0007"){
			//toast.success("Remote Open operation successful")
			toast.success(res.data.message)
		}
	else{
		toast.error("Something went wrong")
	}
	 
};

export const handleDoorOperations = (openInterval,operationType,ids) => async dispatch => {
	const res = await axios.post(JAVA_URL_DOOR_OPERATIONSS.replace("{ids}", ids)+"?operationType="+operationType+"&openInterval="+openInterval); 
	try{
		if(res.data.data.code === 200){
			toast.success("Door operation successful")
		}
	}catch(err){
		toast.error("Something went wrong")
	}
	 
};

