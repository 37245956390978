import axios from 'axios';
import { SITE_TYPE_LIST } from './types';
import {JAVA_API_SITE_TYPE_SEARCH_V3, JAVA_API_SITE_TYPE_V3 } from './ApiEndPoints';
import { JAVA_API_SITE_TYPE_SEARCH } from './ApiEndPoints';

export const getSiteTypes = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.get(JAVA_API_SITE_TYPE_SEARCH_V3 + 'pageNumber=' + pageNumber + '&pageSize=' + pageSize);
    console.log(res.data.data)
    if (res.status === 200 && res.data.code === 'LMSI6000') {
      if(isPageable) {
        dispatch({
          type: SITE_TYPE_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
      } else {
        dispatch({
          type: SITE_TYPE_LIST,
          payload: [],
        });
      } 
  } catch {}
};

export const deleteSiteTypes = (ids, callBack) => async (dispatch) => {
  try {
    const deleteObj = {};
    deleteObj.idsOrCodes = ids;
    deleteObj.deleteChild = false;

    const res = await axios.delete(JAVA_API_SITE_TYPE_V3 + "/" + ids + "/delete");
    callBack(res.data);
  } catch {}
};

export const updateSiteType = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === '' ? true : false;

    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_SITE_TYPE_V3, payload);
    } else {
      res = await axios.put(JAVA_API_SITE_TYPE_V3 + '/' + payload.id + "/update", payload);
    }
    callBack(res.data);
  } catch {}
};

export const filterSiteType = (name, code, page, size) => async (dispatch) => {
  try {
    let query = '';
    if (name !== '') {
      query += '&name=' + name;
    }
    if (code !== '') {
      query += '&code=' + code;
    }
    const res = await axios.get(JAVA_API_SITE_TYPE_SEARCH_V3 + 'pageNumber=' + page + '&pageSize=' + size + query);
    if (res.status === 200 && res.data.code === 'LMSI6000') {
      dispatch({
        type: SITE_TYPE_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: SITE_TYPE_LIST,
        payload: [],
      });
    }
  } catch {}
};
