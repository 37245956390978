import React from 'react';
import { Grid } from "@material-ui/core";
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from '@material-ui/core/DialogActions';
import { TreeSelect } from 'antd';
import axios from "axios";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import MaterialTable from "material-table";
import { getNorulesForDevId } from '../../actions/LinkageListActions';
import { getAccDevices } from "../../actions/DeviceActions";
import { InputLabel} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { JAVA_URL_LINKAGE_TRIGGER } from '../../actions/EndPoints';

const styles = theme => ({
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 250
    }
  },
  formcontrol: {
    width: 150
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="dilog-filter-head" {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="dilog-form-closeButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
class LinkageTrigger extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isDialogOpen: false,
      isSelectDeviceOpen: false,
      selectedId: '',
      selectedIds: [],
      inputPoint: [],
      inputData: [],
      interLocks: [],
      devices: [],
      isSelect: true,
      deviceId: '',
      deviceName: '',
      triggerEventsData: [],
      triggerData: [],
      eventNames: [],
      names: [],
      name: '',
      singleEventSelectData: [],
      selectedArray: [],

      value: ["1"],


    }
    this.handleDialogOK = this.handleDialogOK.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleDialogOK() {

    this.setState({
      isDialogOpen: false,
      isChecked: false
    });
  }
  componentDidUpdate(prevProps) {


    if (this.props.accDevices !== prevProps.accDevices) {
      this.setState({ devices: this.props.accDevices })
    }

  }
  componentDidMount() {
    this.getInterLockList();
    this.setState({ devices: this.props.accDevices });
    this.sendData();


  }


  getInterLockList() {
    //  this.props.getAccDevices();
    //this.setState({interLocks: this.props.accDevices})
  }


  addTrigger = () => {

    var deviceId = this.state.deviceId
    if (deviceId)
    //var obj = {};
    axios.get(JAVA_URL_LINKAGE_TRIGGER+"?deviceId=" + deviceId + "&accLinkageId=").then((response) => {
      console.log("abdfkafkbasdfkasbdfkbasdkgb" + JSON.stringify(response.data))

      this.setState({
        triggerEventsData: response.data.data,
      });

    });

    if (deviceId)

      this.setState({ isDialogOpen: true })

  }

  sendData = (deviceId, triggerIds, name) => {

    this.props.parentCallback(deviceId, triggerIds, name);
  }

  selectDevice = (e) => {
    // axios.get("/api/v1.0/accDeviceDoorCount/device").then((response) => {  

    //     if(response.data.data)
    //     {
    //       this.setState({isSelectDeviceOpen: true} )
    //     }
    //     });

    this.setState({ isSelectDeviceOpen: true })

  }
  handleDialogClose = () => {
    console.log('Clicked Close');
    this.setState({
      isDialogOpen: false, isSelectDeviceOpen: false

    });
  }


  onTreeDropdownChange = value => {


    //var deviceNameObj = {};
    //var deviceNamesArr = [];

    this.setState({ eventNames: value })
  };
  handleChange(e, rowData) {

    this.setState({ deviceId: rowData.id, deviceName: rowData.deviceName, isSelectDeviceOpen: false })
  }
  handleTreeSelet = () => {

    var Arr = [];
    // eslint-disable-next-line
    this.state.triggerData.map((id) => {
      Arr.push(id.value)
    },
    )

    this.setState({ names: this.state.triggerData }, () => {
      this.sendData(this.state.deviceId, Arr.join(","), this.state.name)
    }

    )
    setTimeout(() => {
      this.setState({ isDialogOpen: false })
    });
  }
  onChange = (value) => {

    this.setState({ eventNames: value })

    this.setState({ value });
  };
  onDevIdChange = (e) => {

  }
  onNameChange = (event) => {
    this.setState({ name: event.target.value });
  }
  getValuesAndLAbels = (node, label) => {



    var deviceNamesArr = [];
    if (Array.isArray(label.children)) {
      this.setState({
        triggerData: label.children
      });
      // eslint-disable-next-line
      label.children.map((ids) => {
        deviceNamesArr.push(ids.value)
      })

    }
    else {

      //deviceNamesArr.push(label)
      this.setState({
        triggerData: this.state.triggerData.concat(label)
      });
    }

    setTimeout(() => {
      this.setState({})

    }, 1000);


  }
  render() {


   // const { SHOW_PARENT } = TreeSelect;
    const tableIcons = {
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),

      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
    };
    return (
      <div>
        <div>
          <Dialog

            open={this.state.isDialogOpen}
            onClose={this.handleDialogClose}
            fullWidth={true}
            maxWidth={'xs'}

          >

            <DialogTitle id="customized-dialog-title" onClose={this.handleDialogClose}>{"AddTrigger"}</DialogTitle>

            <DialogContent>
              <Grid item xs={12}>
                <div className="field-container field-container-tree">
                  <InputLabel>
                    {"Department Details"}
                  </InputLabel>
                  <div className="field-label field-label-tree">
                    <TreeSelect
                      style={{ width: '100%' }}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      treeData={this.state.triggerEventsData}
                      placeholder="Select Parent"
                      treeDefaultExpandAll={false}

                      onChange={this.onChange}
                      treeCheckable={true}
                      // value={this.state.value}

                      multiple={true}
                      maxTagCount={2}
                      maxTagPlaceholder="..."
                      //onSelect={(node) => this.setState({arr:node},)}
                      onSelect={(node, label) => { this.getValuesAndLAbels(node, label) }}
                    />
                  </div>

                </div>
              </Grid>
              <DialogActions className="dilog-form-adddoor-action">
                <Button color="primary" onClick={this.handleTreeSelet} variant="contained">
                  OK
                  </Button>
                <Button color="primary" onClick={this.handleDialogClose} variant="contained">
                  Cancel
                  </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </div>
        <div>

          <Dialog
            className="dialog-timeperiod"
            open={this.state.isSelectDeviceOpen}
            onClose={this.handleDialogClose}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >

            <DialogTitle onClose={this.handleDialogClose}>{"Select Device"}</DialogTitle>

            <DialogContent>



              <MaterialTable
                columns={[
                  { title: 'deviceName', field: 'deviceName' },
                  { title: 'Serial Number', field: 'sn' },


                ]}
                data={this.props.deviceData ? this.props.deviceData.item : []}
                icons={tableIcons}
                options={{
                  toolbar: false,
                  //	selection: true,
                  showTitle: false,

                }}
                actions={[
                  {
                    icon: () => <AddIcon style={{ backgroundColor: "#cebfbf" }} />,
                    tooltip: "Edit device",
                    position: 'row',
                    onClick: (event, rowData) => {
                      this.handleChange(event, rowData);

                    }
                  }

                ]}
                // onSelectionChange={(rows) => this.setState({selectedIds:rows})}
                localization={{


                  header: {
                    actions: "Action"
                  },
                  body: {
                    emptyDataSourceMessage: "tableCommonNoRecordDisplay",
                    editTooltip: "commonsTableToolTipEdit",
                    deleteTooltip: "commonsTableToolDelete",
                    filterRow: {
                      filterTooltip: 'Filter'
                    },
                    editRow: {
                      cancelTooltip: "tableBodyCancelTooltip",
                      saveTooltip: "tableBodySaveTooltip",
                      deleteText: "tableBodyDeleteRowWarningMsg"
                    }
                  },
                  toolbar: {
                    searchPlaceholder: "commonsTableToolBarSearch",
                    searchTooltip: "commonsTableToolBarSearch",
                    // eslint-disable-next-line
                    nRowsSelected: '{0}' + " " + "commonsTablePaginationRow" + " " + "selected"
                  }
                }}

              />

              <DialogActions className="dilog-form-adddoor-action">
                <Button color="primary" onClick={this.handleDialogOK} variant="contained">
                  OK
                  </Button>
                <Button color="primary" onClick={this.handleDialogClose} variant="contained">
                  Cancel
                  </Button>
              </DialogActions>

            </DialogContent>
          </Dialog>

        </div>
        <Box display="flex" flexDirection="row" >
          <Box paddingRight={1} paddingBottom={1}>
            <TextField id="outlined-basic" label="Linkage Name" name="name" value={this.state.name} onChange={this.onNameChange} size="small" variant="outlined" style={{ paddingLeft: "3px" }} />
          </Box>
          <Box paddingRight={1} paddingBottom={1}>
            <TextField id="outlined-basic" label="Select Device" name="deviceName" value={this.state.deviceName} size="small" variant="outlined" onClick={this.selectDevice} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" width={"100%"}>

          <Box width={"100%"} marginRight = {1}>
            <div className="acc-level-details-table">
              <div className="acc-level-details">
                <Box display="flex"  bgcolor="background.paper" justifyContent="flex-end">
                  <Box p={1} paddingBottom={0} flexGrow={1}>
                    <p><b className="acc-head">{"LinkageTrigger Actions"}</b></p>
                  </Box>
                  <Box p={1} paddingBottom={0}>
                    <Button
                      className="button-common-primary"
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<AddCircleOutlineRoundedIcon />}
                      onClick={this.addTrigger}
                    >
                      {"ADD TRIGGER"}
                    </Button>
                  </Box>
                </Box>
              </div>
              <MaterialTable
                style={{ maxHeight: "300px", minHeight: "300px",borderRadius:'0px' }}
                autoHeight={true}
                icons={tableIcons}
                columns={[
                  { field: 'title' },


                ]}
                data={this.state.names}
                onPageChange={(event, rowData) => {
                  console.log(event.target, rowData);
                  console.log(
                    "Row Selection State Before: " + rowData.tableData.checked
                  );
                  rowData.tableData.checked = !rowData.tableData.checked;
                  console.log(
                    "Row Section State After: " + rowData.tableData.checked
                  );
                }}
                options={{
                  minBodyHeight: '100%',
                  actionsColumnIndex: -1,
                  addRowPosition: 'first',
                  showTitle: false,
                  toolbarButtonAlignment: "left",
                  toolbar: false,
                  selection: true,
                  pageSize: 5,
                  paging: false,

                  selectionProps: rowData => ({
                    checked: true,
                  })
                }}

              />
            </div>
          </Box>
          <Box width={"100%"} >
            <div className="acc-level-details-table">
              <div className="acc-level-details">
                <Box display="flex"  bgcolor="background.paper" justifyContent="flex-end">
                  <Box p={1} flexGrow={1} paddingBottom={0}>
                    <p><b className="acc-head">{"Input Point"}</b></p>
                  </Box>
                </Box>
              </div>
              <MaterialTable
                style={{ maxHeight: "300px", minHeight: "300px",borderRadius:'0px' }}
                autoHeight={true}
                icons={tableIcons}
                columns={[
                  { field: 'text' },


                ]}
                data={this.props.auxInData.inputArray}
                options={{
                  minBodyHeight: '100%',
                  actionsColumnIndex: -1,
                  addRowPosition: 'first',
                  showTitle: false,
                  toolbarButtonAlignment: "left",
                  toolbar: false,
                  selection: true,
                  pageSize: 5,
                  paging: false
                }}
              />
            </div>
          </Box>
        </Box>

      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  accDevices: state.AccDevice.accDeviceList,
});

export default compose(withTranslation(), connect(mapStateToProps, { getAccDevices, getNorulesForDevId }))(LinkageTrigger);
