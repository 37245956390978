import { GET_ACCESS_LEVEL_LIST,GET_ACCESS_LEVEL_LIST_ID, SUCCESS,GET_ACCRESPONSE } from "../actions/types";

const initialState = {
	accLevelList: [],
	accLevelListByLevelId: [],
    accResponse: false
};

export default function (state = initialState, action) {
	switch (action.type) {

		case GET_ACCESS_LEVEL_LIST:
			return {
				...state,
				accLevelList: action.payload
			};
	   case GET_ACCESS_LEVEL_LIST_ID:
			return {
				...state,
				accLevelListByLevelId: action.payload
			};
			case GET_ACCRESPONSE:
				return {
					...state,
					response: action.payload
				};
		case SUCCESS:
            return {
                ...state,
                isSuccess: true
            }; 
		default:
			return state;
	}
}