import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import biozlink from '../../assets/images/onboard/access-cloud.png';
import cloudAccess from '../../assets/images/onboard/cloudaccess.png';
import background from '../../assets/images/onboard/background_image.png';
import './custom.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DomainIcon from '@material-ui/icons/Domain';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import setJWTToken from '../../oauth-client/setJWTToken';
import { GET_COMPANIES, REFRESH_TOKEN_TIMER, SET_ACCESS_TOKEN, SET_CURRENT_USER, SET_REFRESH_TOKEN } from '../../actions/types';
import { REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import CommonUtil from '../../components/Util/CommonUtils';
import LoginLayout from './LoginLayout';
import { listCompanies, switchCompany } from '../../oauth-client/Onboard';

const companycode = [
  {
    value: '0',
    code: 'ABC012',
  },
  {
    value: '1',
    code: 'ABC013',
  },
  {
    value: '2',
    code: 'ABC014',
  },
  {
    value: '3',
    code: 'ABC015',
  },
  {
    value: '4',
    code: 'ABC016',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

function VerifyCompany() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [Selecteddata, setSelectedData] = useState('');

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setCompany] = useState('');
  const store = useSelector((state) => state);
  const jwtToken = localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN);

  useEffect(() => {
    if (store.security.validToken && !CommonUtil.isEmptyString(store.security.AccJwtToken)) {
      history.push('/acc/dashboard');
    }

    if (!jwtToken) history.push('/acc/login');
    else loadCompanies();
  }, []);

  const loadCompanies = () => {
    setJWTToken(localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN));
    dispatch(
      listCompanies(
        (successData) => {
          setCompanies(CommonUtil.isEmpty(successData.data) ? [] : successData.data.company);
        },
        (error) => {}
      )
    );

    // Axios.get(`/api/v2.0/company?pageNumber=0&pageSize=100`)
    //   .then(function(res) {
    //     if (res.data.code === 'UASI0000') {
    //       setCompanies(CommonUtil.isEmpty(res.data.data) ? [] : res.data.data.company);
    //       dispatch({
    //         type: GET_COMPANIES,
    //         payload: CommonUtil.isEmpty(res.data.data) ? [] : res.data.data.company,
    //       });
    //     }
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
  };

  const returnLogin = () => {
    localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN);
    setJWTToken(false);
  };

  const VerifyCompany = () => {
    const payload = {
      companyCode: selectedCompany,
      companyId: '',
    };
    let config = {
      headers: {
        Authorization: localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN),
      },
    };

    dispatch(
      switchCompany(
        payload,
        (successData) => {
          history.push({
            pathname: REACT_URL_DASHBOARD,
          });
        },
        (error) => {
          toast.error(error.message);
        }
      )
    );

    // Axios.put(`/api/v2.0/company`, payload, config)
    //   .then(function(res) {
    //     if (res.data.code === 'UASI0011') {
    //       localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN);
    //       setJWTToken('Bearer ' + res.data.data.access_token);
    //       localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, 'Bearer ' + res.data.data.access_token);
    //       localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, 'Bearer ' + res.data.data.refresh_token);
    //       const decoded = jwt_decode(res.data.data.access_token);
    //       localStorage.setItem('companyCode', selectedCompany);
    //       var currentSeconds = Math.floor(Date.now() / 1000);
    //       var secondsLeft = decoded.exp - currentSeconds;
    //       var timer = Math.floor(secondsLeft / 60) - 5;
    //       timer = timer * 60 * 1000;
    //       console.log('Set refresh token timer', timer);
    //       console.log('Time left to call refresh token', secondsLeft / 60);

    //       dispatch({
    //         type: SET_CURRENT_USER,
    //         payload: decoded,
    //       });
    //       dispatch({
    //         type: REFRESH_TOKEN_TIMER,
    //         payload: timer,
    //       });
    //       dispatch({
    //         type: SET_REFRESH_TOKEN,
    //         payload: res.data.refresh_token,
    //       });
    //       dispatch({
    //         type: SET_ACCESS_TOKEN,
    //         payload: res.data.access_token,
    //       });

    //       history.push({
    //         pathname: REACT_URL_DASHBOARD,
    //       });
    //     } else {
    //       toast.error(res.data.message);
    //     }
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
    //history.push('/createcompany');
  };
  return (
    <LoginLayout>
      <Grid container spacing={5} alignItems='center'>
        <Grid item></Grid>
        <Grid item xs>
          <Typography className='signin_title' variant='h5' color='textPrimary' align='center'>
            {t('COMMON146')}
          </Typography>
        </Grid>
      </Grid>
      <form className={classes.form} noValidate>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <DomainIcon className='login_icons' />
          </Grid>
          <Grid item xs>
            <Autocomplete
              id='company_code'
              openText={t('COMMON169')}
              closeText={t('COMMON170')}
              noOptionsText={t('COMMON214')}
              options={companies}
              getOptionLabel={(option) => option.code}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {
                  setCompany(newValue.code);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} className='login_input' variant='outlined' fullWidth label={t('COMMON147')} autoFocus onChange={(e) => setCompany(e.target.value)} />
              )}
            />
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={5} alignItems='center'>
        <Grid item></Grid>
        <Grid item xs style={{ marginLeft: '16px' }}>
          <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit} onClick={VerifyCompany}>
            {t('COMMON148')}
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems='center'>
        <Grid item xs></Grid>
        <Grid item alignItems='flex-end'>
          <Button color='primary' className='forgot_password' href='/acc/login'>
           {t('COMMON145')}
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={5} alignItems='center'>
        <Grid item></Grid>
        <Grid item xs>
          <Box mt={6} align='center'>
            <Typography variant='body2' color='textPrimary' align='center' style={{ fontSize: '12px' }}>
             {t('COMMON149')}
            </Typography>
            <Link href='/acc/create-company' style={{ lineHeight: '30px', color: '#1090E5', fontSize: '13px' }}>
              {t('COMMON150')}
            </Link>
          </Box>
        </Grid>
      </Grid>
    </LoginLayout>
  );
}
export default VerifyCompany;
