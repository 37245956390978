import { Grid, Hidden } from '@material-ui/core';
import React from 'react';

export default function SiteDashboardTiles(props) {
  return (
    <>
      <Hidden lgUp>
        <Grid style={{ minWidth: '100%' }} item xl={1} lg={2} md={1} sm={1} xs={1}>
          {props.children}
        </Grid>
      </Hidden>
      <Hidden mdDown>
        <Grid style={{ minWidth: props.minWidth }} item xl={1} lg={2} md={1} sm={1} xs={1}>
          {props.children}
        </Grid>
      </Hidden>
    </>
  );
}
