import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import {
  REACT_URL_DOOR,
  REACT_URL_DEVICE_LIST,
  REACT_URL_ACCESS_LEVEL,
  REACT_URL_TIMEPERIOD,
  REACT_URL_TRANSACTIONS,
  REACT_URL_TRANSACTIONSFORDAY,
  REACT_URL_EXCEPTIONTRANSACTIONS,
  REACT_URL_DASHBOARD,
} from '../../actions/EndPoints';

import {
  REACT_URI_PERSON,
  REACT_URI_SITE,
} from '../../actions/organization/EndPoints';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { ReactComponent as Dashboard_Icon } from '../../assets/images/dashboard-icons/dashboard_icon.svg';
import { ReactComponent as Site_Icon } from '../../assets/images/dashboard-icons/site_icon.svg';
import { ReactComponent as Device_Icon } from '../../assets/images/dashboard-icons/device_icon.svg';
import { ReactComponent as Access_Icon } from '../../assets/images/dashboard-icons/access_icon.svg';
import { ReactComponent as Report_Icon } from '../../assets/images/dashboard-icons/report_icon.svg';
import { ReactComponent as Organization_Icon } from '../../assets/images/dashboard-icons/organization_icon.svg';
import { ReactComponent as AccessControl } from '../../assets/cloudaccess-svg/AccessControl.svg';
import { ReactComponent as Reports } from '../../assets/cloudaccess-svg/Reports.svg';
import { ReactComponent as SiteManagement } from '../../assets/cloudaccess-svg/SiteManagement.svg';
import { ReactComponent as PersonalManagement } from '../../assets/cloudaccess-svg/Personnel Icon.svg';
import React from 'react';
var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  AccessControlIcon: AccessControl,
  ReportsIcon: Reports,
  SiteManagementIcon: SiteManagement,
  PersonalManagementIcon: PersonalManagement,
  BusinessIcon: DashboardTwoToneIcon,
  EqualizerIcon: EqualizerIcon,
};

let language = BrowserLanguage.getDefaultLanguage();

const dataEn = [
  {
    id: '0',
    name: 'Dashboard',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
     {
      id: '1',
      name: 'Organization',
      Icon: Organization_Icon,
      items: [
        {
          id: '6',
          name: 'Person',
          link: `${REACT_URI_PERSON}`,
        },

      ],
    },
    {
      id: '2',
      name: 'Site Management',
      Icon: Site_Icon,
      items: [
        {
          id: '12',
          name: 'Site',
          link: `${REACT_URI_SITE}`,
        },
      ],
    },
    {
      id: '3',
      name: 'Device Management',
      Icon: Device_Icon,
      items: [
        {
          id: '13',
          name: 'Device',
          link: `${REACT_URL_DEVICE_LIST}`,
        },
        {
          id: '14',
          name: 'Doors',
          link: `${REACT_URL_DOOR}`,
        },
      ],
    },
  {
    id: '4',
    name: 'Access Control',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: 'Time Period',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: 'Access Level',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: 'Reports',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: 'Transactions',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: 'Transactions for day',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: 'Exception Transactions',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];

const dataEs = [
  {
    id: '0',
    name: 'Tablero',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
     {
      id: '1',
      name: 'Organización',
      Icon: Organization_Icon,
      items: [
        {
          id: '6',
          name: 'Persona',
          link: `${REACT_URI_PERSON}`,
        },
      ],
    },
     {
      id: '2',
      name: 'Manejo de sitio',
      Icon: Site_Icon,
      items: [
        {
          id: '12',
          name: 'Sitio',
          link: `${REACT_URI_SITE}`,
        },
      ],
    },
   {
      id: '3',
      name: 'Gestión de dispositivos',
      Icon: Device_Icon,
      items: [
        {
          id: '13',
          name: 'Dispositivo',
          link: `${REACT_URL_DEVICE_LIST}`,
        },
        {
          id: '14',
          name: 'Puerta',
          link: `${REACT_URL_DOOR}`,
        },
      ],
    },
  {
    id: '4',
    name: 'Control de acceso',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: 'Periodo de tiempo',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: 'Niveles de acceso',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: 'Informes',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: 'Actas',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: 'Transacciones por día',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: 'Transacciones de excepción',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];

const dataZh = [
  {
    id: '0',
    name: '仪表盘',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
   {
      id: '1',
      name: '组织',
      Icon: Organization_Icon,
      items: [
        {
          id: '6',
          name: '人员',
          link: `${REACT_URI_PERSON}`,
        },
      ],
    },
   {
      id: '2',
      name: 'Site管理',
      Icon: Site_Icon,
      items: [
        {
          id: '12',
          name: 'Site',
          link: `${REACT_URI_SITE}`,
        },
      ],
    },
   {
      id: '3',
      name: '设备管理',
      Icon: Device_Icon,
      items: [
        {
          id: '13',
          name: '设备',
          link: `${REACT_URL_DEVICE_LIST}`,
        },
        {
          id: '14',
          name: '门',
          link: `${REACT_URL_DOOR}`,
        },
      ],
    },
  {
    id: '4',
    name: '门禁控制',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: '时间段',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: '门禁权限组',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: '报表',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: '交易',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: '交易日',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: '异常交易',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];
const dataRu = [
  {
    id: '0',
    name: 'Приборная доска',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
   {
      id: '1',
      name: 'Организация',
      Icon: Organization_Icon,
      items: [
        {
          id: '6',
          name: 'Человек',
          link: `${REACT_URI_PERSON}`,
        },
      ],
    },
   {
      id: '2',
      name: 'Управление сайтом',
      Icon: Site_Icon,
      items: [
        {
          id: '12',
          name: 'Сайт',
          link: `${REACT_URI_SITE}`,
        },
      ],
    },
   {
      id: '3',
      name: 'Управление устройствами',
      Icon: Device_Icon,
      items: [
        {
          id: '13',
          name: 'Устройство',
          link: `${REACT_URL_DEVICE_LIST}`,
        },
        {
          id: '14',
          name: 'Двери',
          link: `${REACT_URL_DOOR}`,
        },
      ],
    },
  {
    id: '4',
    name: 'Контроль доступа',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: 'Временной период',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: 'Уровень доступа',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: 'отчеты',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: 'Транзакции',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: 'Сделки за день',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: 'Транзакции-исключения',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];

const dataId = [
  {
    id: '0',
    name: 'Dasbor',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
  {
      id: '1',
      name: 'Organisasi',
      Icon: Organization_Icon,
      items: [
        {
          id: '6',
          name: 'Orang',
          link: `${REACT_URI_PERSON}`,
        },
      ],
    },
  {
      id: '2',
      name: 'Manajemen situs',
      Icon: Site_Icon,
      items: [
        {
          id: '12',
          name: 'Lokasi',
          link: `${REACT_URI_SITE}`,
        },
      ],
    },
   {
      id: '3',
      name: 'Manajemen perangkat',
      Icon: Device_Icon,
      items: [
        {
          id: '13',
          name: 'Perangkat',
          link: `${REACT_URL_DEVICE_LIST}`,
        },
        {
          id: '14',
          name: 'pintu',
          link: `${REACT_URL_DOOR}`,
        },
      ],
    },
  {
    id: '4',
    name: 'Kontrol akses',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: 'Jangka waktu',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: 'Tingkat Akses',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: 'Laporan',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: 'Transaksi',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: 'Transaksi hari ini',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: 'Transaksi Pengecualian',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];

const dataTh = [
  {
    id: '0',
    name: 'แผงควบคุม',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
   {
      id: '1',
      name: 'องค์กร',
      Icon: Organization_Icon,
      items: [
        {
          id: '6',
          name: 'บุคคล',
          link: `${REACT_URI_PERSON}`,
        },
      ],
    },
   {
      id: '2',
      name: 'การจัดการเว็บไซต์',
      Icon: Site_Icon,
      items: [
        {
          id: '12',
          name: 'งาน',
          link: `${REACT_URI_SITE}`,
        },
      ],
    },
   {
      id: '3',
      name: 'การจัดการอุปกรณ์',
      Icon: Device_Icon,
      items: [
        {
          id: '13',
          name: 'อุปกรณ์',
          link: `${REACT_URL_DEVICE_LIST}`,
        },
        {
          id: '14',
          name: 'ประตู',
          link: `${REACT_URL_DOOR}`,
        },
      ],
    },
  {
    id: '4',
    name: 'การควบคุมการเข้าถึง',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: 'ระยะเวลา',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: 'ระดับการเข้าถึง',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: 'รายงาน',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: 'ธุรกรรม',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: 'ธุรกรรมรายวัน',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: 'ธุรกรรมข้อยกเว้น',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];

const dataJa = [
  {
    id: '0',
    name: 'ダッシュボード',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },
   {
      id: '1',
      name: '組織',
      Icon: Organization_Icon,
      items: [
        {
          id: '6',
          name: '人',
          link: `${REACT_URI_PERSON}`,
        },
      ],
    },
   {
      id: '2',
      name: 'サイト管理',
      Icon: Site_Icon,
      items: [
        {
          id: '12',
          name: 'サイト',
          link: `${REACT_URI_SITE}`,
        },
      ],
    },
   {
      id: '3',
      name: '端末管理',
      Icon: Device_Icon,
      items: [
        {
          id: '13',
          name: '端末',
          link: `${REACT_URL_DEVICE_LIST}`,
        },
        {
          id: '14',
          name: 'ドア',
          link: `${REACT_URL_DOOR}`,
        },
      ],
    },
  {
    id: '4',
    name: 'アクセス制御',
    Icon: Access_Icon,
    items: [
      {
        id: '18',
        name: '期間',
        link: `${REACT_URL_TIMEPERIOD}`,
      },
      {
        id: '19',
        name: 'アクセスレベル',
        link: `${REACT_URL_ACCESS_LEVEL}`,
      },
    ],
  },
  {
    id: '5',
    name: 'レポート',
    Icon: Report_Icon,
    items: [
      {
        id: '20',
        name: 'トランザクション',
        link: `${REACT_URL_TRANSACTIONS}`,
      },
      {
        id: '21',
        name: '日のトランザクション',
        link: `${REACT_URL_TRANSACTIONSFORDAY}`,
      },
      {
        id: '22',
        name: '例外トランザクション',
        link: `${REACT_URL_EXCEPTIONTRANSACTIONS}`,
      },
    ],
  },
];

export default language === 'en'
  ? dataEn
  : language === 'es'
    ? dataEs
    : language === 'zh'
      ? dataZh
      : language === 'ru'
        ? dataRu
        : language === 'idn'
          ? dataId
          : language === 'th'
            ? dataTh
            : dataJa;
