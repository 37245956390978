import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_ERRORS, GET_ACCESS_LEVEL_LIST, GET_ACCESS_LEVEL_LIST_ID, GET_ACCRESPONSE, GET_ACCESS_EMPLOYEE_LIST, GET_ACCESS_DEPT_LIST, GET_PERSON_PRIMARY_LIST } from './types';
import {
  JAVA_URL_ACCESS_LEVEL,
  JAVA_URL_ACCESS_LEVEL_DOOR,
  JAVA_URL_ACCESS_LEVEL_PERSON,
  JAVA_URL_ACCESS_LEVEL_ADD_PERSON,
  JAVA_URL_ACCESS_LEVEL_DEPARTMENT,
  JAVA_URL_ACCESS_LEVEL_DEPARTMENT_PAGE,
  JAVA_URL_PERSON_PRIMARY_DETAILS,
  JAVA_URL_ACCESS_LEVEL_PERSON_OPT,
  JAVA_URL_ACCESS_LEVEL_PERSON_OPT_GET,
} from './EndPoints';

export const getAccessLevels = (name, timeZoneName, query) => async (dispatch) => {
  // eslint-disable-next-line
  const res = await axios.get(JAVA_URL_ACCESS_LEVEL + '?name=' + name + '&timeZoneName=' + timeZoneName + '&page=' + query.page + '&size=' + query.pageSize);
  if (res.status === 200) {
    dispatch({
      type: GET_ACCESS_LEVEL_LIST,
      payload: res.data.data,
    });
  }
};

export const createAccLevel = (newAccLevel) => async (dispatch) => {
  try {
    const res = await axios.post(JAVA_URL_ACCESS_LEVEL, newAccLevel);
    if (res.status === 200) {
      toast.success('added acclevel successfully');
      var name = '';
      var timeZoneName = '';
      var query = {
        page: 0,
        pageSize: 5,
      };

      dispatch(getAccessLevels(name, timeZoneName, query));
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data,
    });
  }
};
export const addDoorLevelId = (levelId, doorIdListDTO) => async (dispatch) => {
  try {
    const res = await axios.post(JAVA_URL_ACCESS_LEVEL_DOOR.replace('{levelId}', levelId), doorIdListDTO);
    if (res.status === 201) {
      toast.success('added door successfully');
      var query = {
        page: '',
        pageSize: '',
      };
      dispatch(getAccessLevelDoorsByLevelId(levelId, true, query));
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data,
    });
  }
};
export const addPersonLevelId = (personIdListDTO) => async (dispatch) => {
  try {
    const res = await axios.post(JAVA_URL_ACCESS_LEVEL_ADD_PERSON, personIdListDTO);
    //console.log(res);
    toast.success('person added successfully');
    var query = {
      page: 0,
      pageSize: 5,
    };
    const response = await axios.get(JAVA_URL_ACCESS_LEVEL_PERSON.replace('{levelId}', personIdListDTO.levelId) + '?page=' + query.page + '&size=' + query.pageSize);
    dispatch({
      type: GET_ACCESS_EMPLOYEE_LIST,
      payload: response.data.data,
    });
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data,
    });
  }
};
export const adddepartmentDetailsToAccLevel = (deptListDTO) => async (dispatch) => {
  try {
    const res = await axios.post(JAVA_URL_ACCESS_LEVEL_ADD_PERSON, deptListDTO);
    //console.log(res);
    toast.success('dept added successfully');
    const response = await axios.get(JAVA_URL_ACCESS_LEVEL_DEPARTMENT_PAGE);
    dispatch({
      type: GET_ACCESS_DEPT_LIST,
      payload: response.data.data,
    });
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data,
    });
  }
};
export const updateAccLevel = (updateDeviceList, query) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_URL_ACCESS_LEVEL, updateDeviceList);
    if (res.status === 200) {
      toast.success('acclevel updated successfully');
      var name = '';
      var timeZoneName = '';
      dispatch(getAccessLevels(name, timeZoneName, query));
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data,
    });
  }
};
export const getAccessLevelDoorsByLevelId = (levelId, flag, query) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_URL_ACCESS_LEVEL_DOOR.replace('{levelId}', levelId) + '?flag=' + flag + '&page=' + query.page + '&size=' + query.pageSize);
    dispatch({
      type: GET_ACCESS_LEVEL_LIST_ID,
      payload: res.data.data ? res.data.data : [],
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data,
    });
  }
};
export const deleteAccLevel = (levelIds, history) => async (dispatch) => {
  const res = await axios.delete(JAVA_URL_ACCESS_LEVEL + levelIds);
  if (res.status === 200) {
    toast.success('acclevel deleted successfully');
    var name = '';
    var timeZoneName = '';
    var query = {
      page: '',
      pageSize: '',
    };
    dispatch(getAccessLevels(name, timeZoneName, query));
    dispatch({
      type: GET_ACCRESPONSE,
      payload: true,
    });
  } else {
    dispatch({
      type: GET_ACCRESPONSE,
      payload: false,
    });
  }
};
export const deleteAccLevelDoor = (levelId, doorIdListDTO) => async (dispatch) => {
  const res = await axios.put(JAVA_URL_ACCESS_LEVEL_DOOR.replace('{levelId}', levelId), doorIdListDTO);
  toast.success('door removed successfully');
  if (res.status === 200) {
    var query = {
      page: 0,
      pageSize: 5,
    };
    dispatch(getAccessLevelDoorsByLevelId(levelId, true, query));

    dispatch({
      type: GET_ACCRESPONSE,
      payload: true,
    });
  } else {
    dispatch({
      type: GET_ACCRESPONSE,
      payload: false,
    });
  }
};
export const deleteAccLevelPerson = (levelId, admsDTO) => async (dispatch) => {
  const res = await axios.put(JAVA_URL_ACCESS_LEVEL_PERSON.replace('{levelId}', levelId), admsDTO);
  if (res.status === 200) {
    toast.success('person deleted successfully');
    var query = {
      page: 0,
      pageSize: 5,
    };
    const response = await axios.get(JAVA_URL_ACCESS_LEVEL_PERSON.replace('{levelId}', levelId) + '?page=' + query.page + '&size=' + query.pageSize);
    dispatch({
      type: GET_ACCESS_EMPLOYEE_LIST,
      payload: response.data.data,
    });
    const res = await axios.get(JAVA_URL_PERSON_PRIMARY_DETAILS);
    dispatch({
      type: GET_PERSON_PRIMARY_LIST,
      payload: res.data.data,
    });
    dispatch({
      type: GET_ACCRESPONSE,
      payload: true,
    });
  } else {
    dispatch({
      type: GET_ACCRESPONSE,
      payload: false,
    });
  }
};
export const deleteAccLevelDepartment = (levelId, ids) => async (dispatch) => {
  const res = await axios.delete(JAVA_URL_ACCESS_LEVEL_DEPARTMENT.replace('{levelId}', levelId).replace('{ids}', ids));
  if (res.status === 200) {
    toast.success('department deleted successfully');
    // var query = {
    //   page: 0,
    //   pageSize: 5,
    // }

    dispatch({
      type: GET_ACCRESPONSE,
      payload: true,
    });
  } else {
    dispatch({
      type: GET_ACCRESPONSE,
      payload: false,
    });
  }
};

export const addPersonAccessControls = (empId,empcode,payload) => async (dispatch) => {
    const res = await axios.post(JAVA_URL_ACCESS_LEVEL_PERSON_OPT+"?employeeId="+empId+"&empCode="+empcode, payload);
  };

