import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import biozlink from '../../assets/images/onboard//access-cloud.png';
import cloudAccess from '../../assets/images/onboard//cloudaccess.png';
import EmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import background from '../../assets/images/onboard/background_image.png';
import Link from '@material-ui/core/Link';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import CommonUtil from '../../components/Util/CommonUtils';
import setJWTToken from '../../oauth-client/setJWTToken';
import jwt_decode from 'jwt-decode';
import { SET_CURRENT_USER } from '../../actions/types';
import { REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import { useDispatch } from 'react-redux';
import LoginLayout from './LoginLayout';
import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment, FormControlLabel } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { registerUser } from '../../oauth-client/Onboard';
import MobileInput from './SignUpMobileInput';
import RightSlidebar from './RightSlidebar';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

function RegisterUser() {
  const [countryCode, setCountryCode] = useState(
    CommonUtil.getLocationDetails('country_calling_code') ? CommonUtil.getLocationDetails('country_calling_code').replace('+', '') : ''
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [values, setValues] = useState({
    showPassword: false,
    showCPassword: false,
  });
  const [TermesCheck, setTermesCheck] = React.useState({
    UserAgreement: false,
    PrivacyPolicy: false,
  });
  const [showLeftSlidbar, setshowLeftSlidbar] = React.useState(false)
  const [AggrementType, setAggrementType] = React.useState('UserAgreement')

  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    cPassword: '',
    verified: true,
  });

  const [error, setError] = React.useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    password: false,
    cPassword: false,
  });

  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    // if(name==='phone'){
    //   setState({...state,name:})
    // }
    setState({ ...state, [event.target.name]: event.target.value });
    setError({ [event.target.name]: false });

    // const name = event.target.name;
    // if (name === 'phone') {
    //   if (!CommonUtil.isEmptyString(event.target.value) && event.target.value.match(/^[0-9 ]+$/)) {
    //     setState({
    //       ...state,
    //       [name]:countryCode+'-'+ event.target.value,
    //     });
    //   } else if (CommonUtil.isEmptyString(event.target.value)) {
    //     setState({
    //       ...state,
    //       [name]: event.target.value,
    //     });
    //   }
    // } else {
    //   setState({
    //     ...state,
    //     [name]: event.target.value,
    //   });
    // }
    // setError({
    //   ...error,
    //   [name]: false,
    // });
  };

  const history = useHistory();

  const handleSubmit = (event) => {

    event.preventDefault();

    if (CommonUtil.isEmptyString(state.firstName)) {
      setError({ ...error, firstName: true });
      toast.error(t('COMMON153'));
      return;
    }


    if (CommonUtil.isEmptyString(state.email)) {
      setError({ ...error, email: true });
      toast.error(t('COMMON154'));
      return;
    }

    if (!CommonUtil.isValidEmail(state.email)) {
      setError({ ...error, email: true });
      toast.error(t('COMMON155'));
      return;
    }

    if (CommonUtil.isEmptyString(state.password)) {
      setError({ ...error, password: true });
      toast.error(t('COMMON157'));
      return;
    }

    if (!state.password.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/)) {
      setError({ ...error, password: true });
      toast.error(t('COMMON158'));
      return;
    }

    if (CommonUtil.isEmptyString(state.cPassword)) {
      setError({ ...error, cPassword: true });
      toast.error(t('COMMON159'));
      return;
    }

    if (state.password !== state.cPassword) {
      setError({ cPassword: true });
      toast.error(t('COMMON160'));
      return false;
    }
    if (TermesCheck.UserAgreement === false) {
      toast.error(t('COMMON286'));
      return false;
    }
    if (TermesCheck.PrivacyPolicy === false) {
      toast.error(t('COMMON287'));
      return false;
    }
    let userObj = {};
    userObj.firstName = state.firstName;
    userObj.lastName = state.lastName;
    userObj.email = state.email;
    var phone = state.phone + countryCode.replace('+', '');
    if (state.phone > 0) {
      if (phone.length < 8 || phone.length > 23) {
        setError({ ...error, phone: true });
        toast.error(t('COMPPROFSCREEN003'));
        return;
      } else {
        userObj.phone = '+' + countryCode.replace('+', '') + '-' + state.phone;
      }
    }

    // userObj.phone = CommonUtil.isEmptyString(state.phone) ? '' : countryCode + '-' + state.phone;
    userObj.password = state.password;
    userObj.confirmPassword = state.cPassword;
    userObj.verified = true;
    dispatch(
      registerUser(
        userObj,
        (successData) => {
          toast.success(successData.message);
          history.push({
            pathname: '/acc/result-page',
            state: { message: t('COMMON161'), success: true, redirectLogin: true },
          });
        },
        (error) => {
          switch (error.code) {
            case 'UASE0020':
              setError({ ...error, email: true });
              break;
            case 'UASE0001':
              setError({ ...error, email: true });
              break;
            default:
              setError({ ...error });

           
          }
          toast.error(error.message);
        }
      )
    );

    // axios
    //   .post(`/api/v2.0/user`, state)
    //   .then(function(res) {
    //     if (res.data.code === 'UASI0001') {
    //       toast.success(res.data.message);
    //       history.push({
    //         pathname: '/acc/result-page',
    //         state: { message: 'Thanks for signing up. We are happy to have you on board', success: true, redirectLogin: true },
    //       });
    //     } else {
    //       switch (res.data.code) {
    //         case 'UASE0020':
    //           setError({ ...error, email: true });
    //           if (!CommonUtil.isEmptyString(state.phone)) {
    //             setError({ ...error, email: true, phone: true });
    //           }
    //           break;
    //         case 'UASE0001':
    //           setError({ ...error, email: true });
    //           break;
    //         default:
    //           setError({ ...error });
    //       }
    //       toast.error(res.data.message);
    //     }
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //     alert('Something went wrong');
    //   });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowCPassword = () => {
    setValues({ ...values, showCPassword: !values.showCPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleCheck = (event) => {
    setTermesCheck({
      ...TermesCheck,
      [event.target.name]: event.target.checked,
    });
  }
  const handlePrivacyPolicy = () => {
    setshowLeftSlidbar(true)
    setAggrementType(t('Privacy Policy'))
  }
  const handleUserAgreement = () => {
    setshowLeftSlidbar(true)
  setAggrementType('User Agreement')

  }
  const handleClose = (event, data) => {
    if (event === ('agree')) {
      let RemoveSpace =
        data.replace(/ /g, "");
      setTermesCheck({
        ...TermesCheck,
        [RemoveSpace]: true,
      });
    }
    else if(event === 'disagree'){
      let RemoveSpace =
        data.replace(/ /g, "");
      setTermesCheck({
        ...TermesCheck,
        [RemoveSpace]: false,
      });
    }
    setshowLeftSlidbar(false)
  }
  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handlePaste = (event) => {
    event.preventDefault();
  };
  return (
    <LoginLayout >
      <Grid container spacing={5} alignItems='center'>
        <Grid item></Grid>
        <Grid item xs>
          <Typography className='signin_title' variant='h5' color='textPrimary' align='center'>
            {t('COMMON140')}
          </Typography>
        </Grid>
      </Grid>
      <form className={classes.form} style={{opacity:showLeftSlidbar?0.6:1}} onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item className='RegisterUserGrid'>
            <BusinessIcon className='login_icons' />
          </Grid>
          <Grid item xs className='RegisterUserGrid'>
            <TextField
              error={error.firstName}
              inputProps ={{
                maxlength :100
              }}
              className='login_input'
              size='small'
              variant='outlined'
              margin='normal'
              fullWidth
              label={t('USER0001') + '*'}
              name='firstName'
              type='text'
              value={state.firstName}
              onChange={handleChange}
              autoComplete='off'
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
          <Grid item className='RegisterUserGrid'>
            <BusinessIcon className='login_icons' />
          </Grid>
          <Grid item xs className='RegisterUserGrid'>
            <TextField
              error={error.lastName}
              className='login_input'
              size='small'
              variant='outlined'
              margin='normal'
              fullWidth
              label={t('USER0002')}
              name='lastName'
              type='text'
              value={state.lastName}
              onChange={handleChange}
              autoComplete='off'
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
          <Grid item className='RegisterUserGrid'>
            <EmailIcon className='login_icons' />
          </Grid>
          <Grid item xs className='RegisterUserGrid'>
            <TextField
              error={error.email}
              className='login_input'
              size='small'
              variant='outlined'
              margin='normal'
              fullWidth
              label={t('COMMON042') + '*'}
              name='email'
              type='text'
              value={state.email}
              onChange={handleChange}
              autoComplete='off'
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
          <Grid item className='RegisterUserGrid'>
            <LocalPhoneIcon className='login_icons' />
          </Grid>
          <Grid item xs className='RegisterUserGrid'>
            {/* <div style={{position:'absolute',left:'30px',zIndex:2,top:'2px',backgroundColor:'white',color:'black',fontSize:10,padding:'0px 4px'}} >{t('COMMON043')}</div>
            <PhoneInput
            enableSearch='true'
             country={'in'}
              value={state.phone}
               onChange={(phone) => setState({ ...state, phone: phone })}
               searchPlaceholder={t('search')}
               searchNotFound={t('COMMON165')}
             /> */}
            <MobileInput
              error={error.phone}
              countryCode={countryCode}
              phone={state.phone}
              editMode={true}
              onMobileNoChange={(phone) => {
                setError({ phone: false });
                setState({ ...state, phone: phone });
              }}
              onCounryCodeChange={(code) => setCountryCode(code)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
          <Grid item className='RegisterUserGrid'>
            <BusinessIcon className='login_icons' />
          </Grid>
          <Grid item xs className='RegisterUserGrid'>
            <TextField
              error={error.password}
              autoComplete='off'
              className='login_input'
              size='small'
              variant='outlined'
              margin='normal'
              fullWidth
              label={t('USER0003') + ' *'}
              name='password'
              type={values.showPassword ? 'text' : 'password'}
              value={state.password}
              onChange={handleChange}
              inputProps={{
                onCopy: handleCopy,
                onPaste: handlePaste,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                      {values.showPassword ? <Visibility className='visibilityIcons' /> : <VisibilityOff className='visibilityIcons' />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
          <Grid item className='RegisterUserGrid'>
            <BusinessIcon className='login_icons' />
          </Grid>
          <Grid item xs className='RegisterUserGrid'>
            <TextField
              error={error.cPassword}
              autoComplete='off'
              className='login_input'
              size='small'
              variant='outlined'
              margin='normal'
              fullWidth
              label={t('USER0004') + ' *'}
              name='cPassword'
              value={state.cPassword}
              onChange={handleChange}
              type={values.showCPassword ? 'text' : 'password'}
              inputProps={{
                onCopy: handleCopy,
                onPaste: handlePaste,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={handleClickShowCPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                      {values.showCPassword ? <Visibility className='visibilityIcons' /> : <VisibilityOff className='visibilityIcons' />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs style={{ marginLeft: '48px' }}>

          <Checkbox style={{ color: '#1090E5' }} checked={TermesCheck.UserAgreement} onChange={handleCheck} name="UserAgreement" />
          <span  >{t('COMMON226')} <span style={{ color: '#1090E5',cursor: 'pointer' }} onClick={handleUserAgreement}>{t('COMMON227')}</span></span>
        </Grid>

        <Grid item xs style={{ marginLeft: '48px' }}>
          <Checkbox style={{ color: '#1090E5' }} checked={TermesCheck.PrivacyPolicy} onChange={handleCheck} name="PrivacyPolicy" />
          <span>{t('COMMON226')}  <span style={{ color: '#1090E5',cursor: 'pointer' }} onClick={handlePrivacyPolicy} >{t('COMMON228')}</span> </span>
        </Grid>

        <Grid container spacing={5} alignItems='center'>
          <Grid item></Grid>
          <Grid item xs>
            <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
              {t('COMMON140')}
            </Button>
          </Grid>
        </Grid>
        <Grid container alignItems='center'>
          <Grid item xs></Grid>
          <Grid item>
            <Button onClick={history.goBack} style={{ color: '#1090E5', fontSize: '13px' }}>
              {t('COMMON040')}
            </Button>
          </Grid>
        </Grid>
      </form>
      {showLeftSlidbar ? <RightSlidebar AggrementType={AggrementType} open={showLeftSlidbar} close={(event, data) => handleClose(event, data)}  /> : <></>}
    </LoginLayout>
  );
}
export default withRouter(RegisterUser);
