import { Box, Grid, Dialog, DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import { setRegistrationDevice } from '../../actions/DeviceOperationsApiCalls';
import { getDevices } from '../../actions/DeviceApiCalls';

export default function SetRegistrationDevice(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [popupState, setPopupState] = React.useState(props.popupState);
  const [selectedDevices, setSelectedDevice] = React.useState();
  const [isRegDevice, setIsRegDevice] = React.useState();
  const [error, setError] = React.useState(false);

  const handleChange = (event) => {
    setIsRegDevice(event.target.value === 'true' ? true : false);
    setError(false);
  };

  const loadDevices = () => {
    dispatch(getDevices('', '', '', '', 1, 1000));
  };

  const deviceOperations = () => {
    if (props.devices.length < 1) {
      toast.warning(t('DEVI0014'));
      handleClose();
      return;
    }

    if (props.devices.length > 1) {
      toast.warning(t('DEVI0039'));
      handleClose();
      return;
    }

    setOpen(true);

    var ids = '';
    const { devices } = props;
    setIsRegDevice(JSON.stringify(devices[0].isRegistrationDevice));
    // eslint-disable-next-line
    devices.map((device, index) => {
      if (index === devices.length - 1) ids = ids + device.id;
      else ids = ids + device.id + ',';
    });

    setSelectedDevice(ids);

    // confirmAlert({
    //     customUI: ({ onClose }) => {
    //         return (
    //             <ConfirmModal open={true} text={confirmMessage} onConfirm={() => { dispatch(performDeviceOperations(ids, operation)); onClose() }} onClose={() => onClose()} />
    //         );
    //     }
    // });
  };

  const handleClose = () => {
    setOpen(false);
    props.onSuccess();
    popupState.close();
  };

  const handleSubmit = () => {
    if (isRegDevice === undefined || isRegDevice === '') {
      setError(true);
      return;
    }
    dispatch(
      setRegistrationDevice(selectedDevices, isRegDevice, () => {
        popupState.close();
        loadDevices();
      })
    );
  };

  return (
    <>
      <MenuItem onClick={() => deviceOperations()}>{t('DEVI0033')}</MenuItem>

      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-filter-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('DEVI0033')}</Box>
            <CancelIcon onClick={handleClose} style={{ color: 'red', background: 'white', borderRadius: '50%' }} fontSize='small' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('DEVI0033') + ' *'}</InputLabel>
                    <Select native error={error} label={t('DEVI0033') + ' *'} name='isRegDevice' onChange={handleChange} value={isRegDevice}>
                      <option value="true">{t("yes")}</option>
                      <option value="false">{t("no")}</option>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
