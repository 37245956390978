import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';

export default function CustomPopover(props) {
  return (
    <PopupState variant='popover' popupId='demoPopover'>
      {(popupState) => (
        <div>
          <div {...bindHover(popupState)}> {props.children}</div>
          <HoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {props.title}
          </HoverPopover>
        </div>
      )}
    </PopupState>
  );
}
