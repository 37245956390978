import React from "react";
import { Grid, Card } from "@material-ui/core";
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import { REACT_URL_ACCESS_LEVEL } from '../../actions/EndPoints'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next';
import DoorDetailsList from "./DoorDetailsList";
import AccLevelPersonTab from "./AccLevelPersonTab";

class AccLevelDetails extends React.Component {

	constructor(props) {
		super(props)
		this.state = {

		}
	}
	render() {
		const { t } = this.props;
	// console.log("sjhdfjasfbksbf,sdbfg"+this.props.location.state)
		return (

			<div className="root">
				<Grid
					container
					direction="row"
					justify="flex-start"
					alignItems="flex-start">
					<Grid item xs={12}>
						<NavLink to={REACT_URL_ACCESS_LEVEL} style={{ textDecoration: 'none', color: 'unset' }} >
							<HomeIcon color="primary" fontSize="small" />
							<span style={{ paddingLeft: "10px", fontSize: "15px", color: "#858585" }}>&#8250;</span>
							<label className="timeLine" style={{ paddingLeft: "5px", color: "#858585" }}>
								{this.props.path || 'Access Control'}
							</label>
							<span style={{ paddingLeft: "10px", fontSize: "15px", color: "#858585" }}>&#8250;</span>
							<label className="timeLine" style={{ paddingLeft: "5px", color: "#858585" }}>
								{t("commonAccessLevelTitle")}
					   </label>
						</NavLink >
					</Grid>
				</Grid>
				<div>
					<Card className="card-box mb-4-spacing overflow-visible">
						<div className="">
                            <DoorDetailsList id={this.props.location.state}/>
						</div>
                        <div className="">
                            <AccLevelPersonTab id={this.props.location.state}/>
						</div>
					</Card>
				</div>

			</div>
		);
	}
}

export default compose(withTranslation())(AccLevelDetails);